/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList, listVar} from './InputLayout'
import {CardUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow} from '../../../../helper/TableInput'
import {
  NullProof,
  RouterQueryParams,
  createFormObjectList,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import FormInput from '../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import slugify from 'slugify'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'

const onChange = (e: any) => {
  const namelist = createFormObjectList(listVar, [], 'name')
  const resultData = e?._data?.get
  let totalBiaya = 0
  let totalWaktu = 0
  for (const l of namelist) {
    totalBiaya += Number(resultData[`detail_${l}_1_anggaran_biaya_1`])
    totalWaktu += Number(resultData[`detail_${l}_1_anggaran_waktu_1`])
  }
  e?.resetForm({
    values: {...resultData, total_biaya: totalBiaya, total_waktu: totalWaktu},
  })
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td style={{minWidth: '50px'}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'jenis_penugasan'})}
                  </td>
                  <td style={{minWidth: '300px'}}>
                    {NullProof({input: l, params: 'sumber_penugasan'})}
                  </td>
                  <td style={{minWidth: '300px'}}>
                    {NullProof({input: l, params: 'nama_kegiatan'})}
                  </td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  console.log(listVar)
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Auditor'}}>
            <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'disusun_oleh.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'NIP'}}>
              {NullProof({input: data, params: 'disusun_oleh.nip'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jenis Pekerjaan yang harus dilakukan'}}>
            <div className='row'>
              <div className='col-4 text-center'>Tanggal</div>
              <div className='col-4 text-center'>Anggaran Waktu</div>
              <div className='col-4 text-center'>Anggaran Biaya</div>
            </div>
          </TableInputDetailColumn>
          {listVar.map((li, ii) => {
            const varname = slugify(
              `${li.name ? `${li.name}_` : ''}${
                li.group && li.group !== li.number
                  ? `${li.group.toLowerCase()}${li.number ? '' : '_'}`
                  : ''
              }${li.number ? `${li.number.toLowerCase()}_` : ''}${li.title.substring(0, 50)}`,
              {
                replacement: '_',
                lower: true,
              }
            )
            const listData = NullProof({
              input: NullProof({input: data, params: 'detail'})[0],
              params: varname,
            })
            console.log(varname, listData)
            return (
              <Fragment key={ii}>
                {li.isLabel ? (
                  <TableInputDetailColumn
                    hideDivider={ii < listVar.length - 1 && li.number === listVar[ii + 1].group}
                    title={{
                      value: `${li.number}. ${li.title}`,
                    }}
                  ></TableInputDetailColumn>
                ) : (
                  <TableInputDetailColumn
                    hideDivider={
                      li.group !== li.number &&
                      ii < listVar.length - 1 &&
                      li.group === listVar[ii + 1].group
                    }
                    title={{
                      value: `${
                        li.group !== li.number
                          ? li.number
                            ? `${li.number}. `
                            : ''
                          : li.group
                          ? `${li.group}. `
                          : ''
                      }${li.title}`,
                    }}
                  >
                    <div className='row'>
                      <div className='col-4 text-center'>
                        {NullProof({input: listData[0], params: 'tanggal', type: 'date'})}
                      </div>
                      <div className='col-4 text-center'>
                        {NullProof({
                          input: listData[0],
                          params: 'anggaran_biaya',
                          type: 'currency',
                        })}
                      </div>
                      <div className='col-4 text-center'>
                        {NullProof({input: listData[0], params: 'anggaran_waktu'})}
                      </div>
                    </div>
                  </TableInputDetailColumn>
                )}
              </Fragment>
            )
          })}
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper, PdfWrapper}
