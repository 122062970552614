import {PageDataContent} from '../../../helper/Model'
import {TableInputDataProps} from '../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Data Menu',
  path: '/pengaturan/data_menu',
  api: '/account/menu',
}

const BaseBreadcrumb = [
  {
    title: 'Pengaturan',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: 'Data Menu',
    path: '/pengaturan/data_menu',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Nama',
    },
    {
      title: 'Route',
    },
    {
      title: 'Route SEO',
    },
  ],
  link: {
    tambah: {
      id: 'tambah',
      protect: 'tambah',
      title: 'Tambah',
      route: 'tambah',
    },
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'id',
        protect: 'ubah/:id',
        title: 'Ubah',
        route: 'ubah/:id',
      },
      {id: 'id', protect: ':id/menu', title: 'Sub Menu', route: 'menu/:id'},
      {id: 'id', protect: ':id/action', title: 'Action', route: 'action/:id'},
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: '',
      },
    ],
  },
  route: {
    url: InfoData.api,
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  submenu: {
    page: {
      title: `Data Sub Menu`,
      breadcrumb: {
        title: `Data Sub Menu`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  action: {
    page: {
      title: `${InfoData.title} Action`,
      breadcrumb: {
        title: `${InfoData.title} Action`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah_submenu: {
    page: {
      title: `Tambah Data Sub Menu`,
      breadcrumb: {
        title: `Tambah Data Sub Menu`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah_action: {
    page: {
      title: `Tambah ${InfoData.title} Action`,
      breadcrumb: {
        title: `Tambah ${InfoData.title} Action`,
        data: [
          ...BaseBreadcrumb,
          ...MainBreadcrumb,
          {
            title: 'Data Action',
            path: '/pengaturan/data_menu/action/:id',
            isSeparator: false,
            isActive: true,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah_submenu: {
    page: {
      title: `Ubah Sub Menu`,
      breadcrumb: {
        title: `Ubah Sub Menu`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah_action: {
    page: {
      title: `Ubah Data Action`,
      breadcrumb: {
        title: `Ubah Data Action`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
