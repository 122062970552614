import * as Yup from 'yup'
import {FormInputProps} from '../FormInput'
import {NullProof} from '../AppFunction'

const filterInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'search',
    type: 'input',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Cari',
          placeholder: 'Cari',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
]

interface InputOptionsColumnProps {
  title: string
  id: string
  type: 'text' | 'currency' | 'date' | 'map'
}

const titleList: any = {
  search: 'Cari',
  status: 'Status',
  status_simple: 'Status',
  um_uf: 'UM/VF',
  checkbox: 'Checkbox',
  pegawai_id: 'Auditor',
  wilayah: 'Wilayah',
}

interface InputLayoutBuilderProps {
  template: 'search' | 'status' | 'pegawai_id' | 'checkbox' | 'status_simple' | 'um_uf' | 'wilayah'
  name?: string
  title?: string
  className?: string
  options?: {
    column?: InputOptionsColumnProps[]
  }
}

const inputLayoutBuilder = ({
  input,
  filter,
}: {
  input: InputLayoutBuilderProps[]
  filter?: FormInputProps[]
}) => {
  let inputLayout: FormInputProps[] = []
  for (const l of input) {
    const apiList: any = {
      pegawai_id: '/kepegawaian/data-tenaga-audit',
    }
    const columnList: any = {
      pegawai_id: [
        {id: 'nama', title: 'Nama', type: 'text'},
        {id: 'nip', title: 'NIP', type: 'text'},
      ],
    }
    const listDataList: any = {
      status: [
        {
          title: 'Disetujui',
          value: 'Disetujui',
        },
        {
          title: 'Ditolak',
          value: 'Ditolak',
        },
        {
          title: 'Menunggu',
          value: 'Menunggu',
        },
      ],
      status_simple: [
        {
          title: 'Pending',
          value: 'Pending',
        },
        {
          title: 'Proses',
          value: 'Proses',
        },
        {
          title: 'Success',
          value: 'Success',
        },
      ],
      um_uf: [
        {
          title: 'UM',
          value: 'UM',
        },
        {
          title: 'VF',
          value: 'VF',
        },
      ],
      wilayah: [
        {
          title: 'Wilayah 1',
          value: 'wilayah1',
        },
        {
          title: 'Wilayah 2',
          value: 'wilayah2',
        },
        {
          title: 'Wilayah 3',
          value: 'wilayah3',
        },
        {
          title: 'Wilayah 4',
          value: 'wilayah4',
        },
      ],
    }
    const datalistOption: any = {
      api: apiList[l?.template],
      query: 'orderby=asc',
      params:
        NullProof({
          input: l.options?.column ? l.options : columnList,
          params: l.options?.column ? 'column' : l?.template,
          isMap: true,
        })
          ?.map((l1: any, i1: number) => {
            return l1.id
          })
          ?.join(',') || '',
      result: (
        <>
          {NullProof({
            input: l.options?.column ? l.options : columnList,
            params: l.options?.column ? 'column' : l?.template,
            isMap: true,
          })?.map((l1: any, i1: number) => (
            <div key={i1}>
              <span className='fw-bold'>{l1.title} : </span>
              <span>${l1.id}</span>
            </div>
          ))}
        </>
      ),
      id: 'id',
      type: 'table',
      table: {
        data: NullProof({
          input: l.options?.column ? l.options : columnList,
          params: l.options?.column ? 'column' : l?.template,
          isMap: true,
        }),
        protect: '',
        props: {
          filter: filter || [],
          route: {
            url: apiList[l?.template],
          },
          headerData: [
            {title: 'No.', props: {className: 'min-w-50px'}},
            ...NullProof({
              input: l.options?.column ? l.options : columnList,
              params: l.options?.column ? 'column' : l?.template,
              isMap: true,
            })?.map((l1: any, i1: number) => {
              return {title: l1.title}
            }),
          ],
        },
      },
    }
    const template: FormInputProps = {
      className: l?.className ? l.className : 'col-12',
      name: l?.name || l.template,
      type: Object.keys(listDataList).includes(l.template) ? 'select' : 'input',
      value: '',
      validator: Object.keys(apiList).includes(l.template) ? Yup.number() : Yup.string(),
      listData: Object.keys(listDataList).includes(l.template) ? listDataList[l.template] : [],
      options: {
        input: {
          props: {
            type: l?.template === 'checkbox' ? 'checkbox' : 'text',
            title: l?.template === 'checkbox' ? '' : l?.title ? l.title : titleList[l.template],
            placeholder: l?.title ? l.title : titleList[l.template],
          },
        },
      },
    }
    if (Object.keys(apiList).includes(l.template)) {
      inputLayout.push({
        ...template,
        options: {
          ...template.options,
          datalist: datalistOption,
        },
      })
    } else {
      inputLayout.push({...template})
    }
  }
  return inputLayout
}

export default inputLayoutBuilder
