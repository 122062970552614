import React from 'react'

interface PaginationProps {
  disabled?: boolean
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
  maxButtons: number
}

const Pagination: React.FC<PaginationProps> = ({
  disabled = false,
  currentPage,
  totalPages,
  onPageChange,
  maxButtons,
}) => {
  const rangeStart = Math.max(1, currentPage - Math.floor(maxButtons / 2))
  const rangeEnd = Math.min(totalPages, rangeStart + maxButtons - 1)
  const pageNumbers = Array.from(
    {length: rangeEnd - rangeStart + 1},
    (_, index) => rangeStart + index
  )

  return (
    <nav aria-label='Page navigation'>
      <ul className='pagination row g-4'>
        <div className='col-6 order-2 order-md-1 col-md-auto p-0'>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button
              type='button'
              className='page-link w-100'
              onClick={() => onPageChange(currentPage - 1)}
              disabled={disabled || currentPage === 1}
            >
              Previous
            </button>
          </li>
        </div>
        <div className='col-12 order-1 col-md-auto d-flex gap-2 p-0 px-md-4 justify-content-center'>
          {rangeStart > 1 && (
            <li className='page-item'>
              <button
                disabled={disabled}
                type='button'
                className='page-link'
                onClick={() => onPageChange(1)}
              >
                1
              </button>
            </li>
          )}
          {rangeStart > 2 && (
            <li className='page-item disabled'>
              <span className='page-link'>...</span>
            </li>
          )}
          {pageNumbers.map((pageNumber) => (
            <li
              key={pageNumber}
              className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
            >
              <button
                disabled={disabled}
                type='button'
                className='page-link'
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))}
          {rangeEnd < totalPages - 1 && (
            <li className='page-item disabled'>
              <span className='page-link'>...</span>
            </li>
          )}
          {rangeEnd < totalPages && (
            <li className='page-item'>
              <button
                disabled={disabled}
                type='button'
                className='page-link'
                onClick={() => onPageChange(totalPages)}
              >
                {totalPages}
              </button>
            </li>
          )}
        </div>
        <div className='col-6 order-3 col-md-auto p-0'>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button
              type='button'
              className='page-link w-100'
              onClick={() => onPageChange(currentPage + 1)}
              disabled={disabled || currentPage === totalPages}
            >
              Next
            </button>
          </li>
        </div>
      </ul>
    </nav>
  )
}

export default Pagination
