/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../../helper/AppFunction'
import FormInput, {BaseInputOnChangeProps} from '../../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../helper/TableInputDetail'
import Modal from '../../../../../helper/Modal'
import Accordion from '../../../../../helper/Accordion'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Kode Klasifikasi' className='min-w-150px'>
                    {NullProof({input: l, params: 'kode_klasifikasi'})}
                  </TableColumn>
                  <TableColumn label='Berkas' className='min-w-150px'>
                    {NullProof({input: l, params: 'berkas'})}
                  </TableColumn>
                  <TableColumn label='Kurun Waktu' className='min-w-150px'>
                    {NullProof({input: l, params: 'kurun_waktu'})}
                  </TableColumn>
                  <TableColumn label='JUmlah Item' className='min-w-150px'>
                    {NullProof({input: l, params: 'jumlah_item'})}
                  </TableColumn>
                  <TableColumn label='Akhir Retensi Aktif' className='min-w-150px'>
                    {NullProof({input: l, params: 'akhir_retensi_aktif', type: 'date'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={(e: BaseInputOnChangeProps) => {
            const {
              _apiData: {get: fetched},
              _data: {get: data},
            } = e
            const result: object = data || {}

            const {
              retensi_aktif,
              retensi_inaktif,
              penyusutan_akhir_nama: penyusutan_akhir,
            } = fetched?.klasifikasi_id_detail || {}
            if (fetched?.klasifikasi_id_detail) {
              e.resetForm({values: {...result, retensi_aktif, retensi_inaktif, penyusutan_akhir}})
            }
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <Modal
        id='modal_statusubah'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            isFeedback: true,
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              // console.log(e)
              setModal((p: any) => ({...p, statusUbah: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Berkas'}}>
              {NullProof({input: data, params: 'berkas'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Peminjaman'}}>
              {NullProof({input: data, params: 'tanggal_peminjaman', type: 'date'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Pengembalian'}}>
              {NullProof({input: data, params: 'tanggal_pengembalian', type: 'date'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kurun Waktu'}}>
              {NullProof({input: data, params: 'kurun_waktu'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Jumlah Item'}}>
              {NullProof({input: data, params: 'jumlah_item'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Akhir Retensi Inaktif'}}>
              {NullProof({input: data, params: 'akhir_retensi_aktif', type: 'date'})}
            </TableInputDetailColumn>

            <div className='my-4 d-flex flex-column gap-2'>
              <Accordion
                title='Klasifikasi Berkas'
                enabled
                props={{body: {className: 'p-4 border-bottom'}}}
              >
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      hideDivider
                      isResponsive='desktop'
                      title={{value: 'Nama'}}
                    >
                      {NullProof({input: data, params: 'klasifikasi.nama'})}
                    </TableInputDetailColumn>
                  </div>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      hideDivider
                      isResponsive='desktop'
                      title={{value: 'Tanggal Dibuat'}}
                    >
                      {NullProof({input: data, params: 'klasifikasi.createdAt', type: 'date'})}
                    </TableInputDetailColumn>
                  </div>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      hideDivider
                      isResponsive='desktop'
                      title={{value: 'Kode'}}
                    >
                      {NullProof({input: data, params: 'klasifikasi.kode'})}
                    </TableInputDetailColumn>
                  </div>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      hideDivider
                      isResponsive='desktop'
                      title={{value: 'Terkahir Diubah'}}
                    >
                      {NullProof({input: data, params: 'klasifikasi.updatedAt', type: 'date'})}
                    </TableInputDetailColumn>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      hideDivider
                      isResponsive='desktop'
                      title={{value: 'Retensi Aktif'}}
                    >
                      {NullProof({input: data, params: 'klasifikasi.retensi_aktif'})}
                    </TableInputDetailColumn>
                  </div>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      hideDivider
                      isResponsive='desktop'
                      title={{value: 'Retensi Inaktif'}}
                    >
                      {NullProof({input: data, params: 'klasifikasi.retensi_inaktif'})}
                    </TableInputDetailColumn>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      hideDivider
                      isResponsive='desktop'
                      title={{value: 'Penyusutan Akhir'}}
                    >
                      {NullProof({input: data, params: 'klasifikasi.penyusutan_akhir.nama'})}
                    </TableInputDetailColumn>
                  </div>
                </div>
              </Accordion>
            </div>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
