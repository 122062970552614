import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../../helper/FormInput'

const componentUraianTargetBulanan: FormInputProps[] = [
  {
    className: 'col-12 md-col-6',
    name: 'uraian',
    type: 'input',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'uraian',
          placeholder: 'uraian',
        },
      },
    },
  },
  {
    className: 'col-12 md-col-6',
    name: 'presentase',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'rincian',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Komitmen Kerja Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Rincian',
          placeholder: 'Rincian',
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'komitmen_kerja_id',
    type: 'input',
    validator: Yup.number(),
    hide: true,
    options: {
      input: {
        props: {
          title: 'Komitmen Kerja',
          placeholder: 'Komitmen Kerja',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'indikator',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Indikator Keberhasilan Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Indikator Keberhasilan',
          placeholder: 'Indikator Keberhasilan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'target',
    type: 'select',
    validator: Yup.string(),
    listData: [
      {title: 'Februari', value: 'Februari'},
      {title: 'Maret', value: 'Maret'},
      {title: 'April', value: 'April'},
      {title: 'Mei', value: 'Mei'},
      {title: 'Juni', value: 'Juni'},
      {title: 'Juli', value: 'Juli'},
      {title: 'Agustus', value: 'Agustus'},
      {title: 'September', value: 'September'},
      {title: 'Oktober', value: 'Oktober'},
    ],
    options: {
      input: {
        props: {
          title: 'Target',
          placeholder: 'Target',
        },
      },
    },
  },
]

const formInputListUraianTargetBulanan: FormInputProps[] = [
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Target Februari',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_februari',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_februari',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'analisa_februari',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Analisa',
          placeholder: 'Analisa',
          rows: 3,
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Target Maret',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_maret',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_maret',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'analisa_maret',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Analisa',
          placeholder: 'Analisa',
          rows: 3,
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Target April',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_april',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_april',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'analisa_april',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Analisa',
          placeholder: 'Analisa',
          rows: 3,
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Target Mei',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_mei',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_mei',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'analisa_mei',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Analisa',
          placeholder: 'Analisa',
          rows: 3,
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Target Juni',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_juni',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'analisa_juni',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Analisa',
          placeholder: 'Analisa',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_juni',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Target Juli',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_juli',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_juli',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'analisa_juli',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Analisa',
          placeholder: 'Analisa',
          rows: 3,
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Target Agsustus',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_agustus',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_agustus',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'analisa_agustus',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Analisa',
          placeholder: 'Analisa',
          rows: 3,
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Target September',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_september',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_september',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'analisa_september',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Analisa',
          placeholder: 'Analisa',
          rows: 3,
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Target Oktober',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_oktober',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_oktober',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'analisa_oktober',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Analisa',
          placeholder: 'Analisa',
          rows: 3,
        },
      },
    },
  },
]

const formInputListUraianImplementasiBulanan: FormInputProps[] = [
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Implementasi Februari',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_februari',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_februari',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Implementasi Maret',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_maret',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_maret',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Implementasi April',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_april',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_april',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Implementasi Mei',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_mei',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_mei',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Implementasi Juni',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_juni',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_juni',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Implementasi Juli',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_juli',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_juli',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Implementasi Agsustus',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_agustus',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_agustus',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Implementasi September',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_september',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_september',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Uraian Implementasi Oktober',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_oktober',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'presentase_oktober',
    type: 'input',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Presentase (%)',
          placeholder: 'Presentase (%)',
          type: 'number',
        },
      },
    },
  },
]

export {formInputList, formInputListUraianTargetBulanan, formInputListUraianImplementasiBulanan}
