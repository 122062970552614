import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotifState {
  data: any;
}

const initialState: NotifState = {
  data: {
    ultah: false
  },
};

const notifSlice = createSlice({
  name: 'notif',
  initialState,
  reducers: {
    post: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    clear: (state) => {
      state.data = {}
    }
  },
});

export const { post, clear } = notifSlice.actions;
export default notifSlice.reducer;
