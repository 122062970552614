import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {PdfPageViewer} from '../../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {AvatarUi, CardUi, TabsUi, TimelineUi} from '../../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../../helper/AppFunction'
import FormInput from '../../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../helper/TableInputDetail'
import {ProtectComponentRoles} from '../../../../../helper/UserRolesValidation'
import Modal from '../../../../../helper/Modal'
import {
  TableDaftarVerifikator,
  TablePenandaTanganan,
  TableRiwayatPerubahanMetadata,
  TableHistoriNaskah,
  TableInputNaskahDinas,
  TableInputLampiran,
  TableInputTujuan,
  TableInputVerifikator,
  TableInputPenandatanganan,
  TableInputStatusKirim,
} from './TableWrapper'
import Accordion from '../../../../../helper/Accordion'

const dummyData = [
  {
    id: 1,
    tanggal_naskah: '2024-01-18T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    hal: 'coba tanda tangan konvensional',
    asal_naskah: 'Inspektorat Wilayah IV',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
    nomor_referensi: 'Tidak ada',
    isi: 'mencoba testing aplikasi tanda tangan konvensional',
    jenis_naskah: 'Nota Dinas',
    sifat_naskah: 'Biasa',
    klasifikasi: 'PS.00 - Pengawasan Administrasi Umum',
    tipe_tandatangan: 'TTE',
    status_berkas: 'Belum diberkaskan',
    tanggal_diterima: '2024-01-18T11:02:59Z',
    tanggal_dibaca: '2024-01-18T11:02:59Z',
    tanggal_dibaca_setelah: '2024-01-18T11:02:59Z',
    pegawai: {
      nama: 'Kastolan - Plt. Sekretaris Inspektorat Jenderal',
      jabatan: 'Sekretariat Inspektorat Jenderal Kementerian Agama - Kementerian Agama',
      foto: 'files/avatar/avatar_12122023204007_nemeka.jpg',
    },
    penerima: {
      utama: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
      tebusan: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
    },
  },
  {
    id: 2,
    tanggal_naskah: '2024-01-18T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    hal: 'coba tanda tangan konvensional',
    asal_naskah: 'Inspektorat Wilayah IV',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
  },
  {
    id: 3,
    tanggal_naskah: '2024-01-18T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    hal: 'coba tanda tangan konvensional',
    asal_naskah: 'Inspektorat Wilayah IV',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
  },
]

const MainWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          isDummy
          staticData={dummyData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Tanggal Naskah' className='min-w-150px'>
                    {NullProof({input: l, params: 'tanggal_naskah'})}
                  </TableColumn>
                  <TableColumn label='Nomor Naskah' className='min-w-150px'>
                    {NullProof({input: l, params: 'nomor_naskah'})}
                  </TableColumn>
                  <TableColumn label='Hal' className='min-w-150px'>
                    {NullProof({input: l, params: 'hal'})}
                  </TableColumn>
                  <TableColumn label='Asal' className='min-w-150px'>
                    {NullProof({input: l, params: 'asal_naskah'})}
                  </TableColumn>
                  <TableColumn label='tingkat Urgensi' className='min-w-150px'>
                    {NullProof({input: l, params: 'tingkat_urgensi'})}
                  </TableColumn>
                  <TableColumn label='Status Verifikator' className='min-w-150px'>
                    {NullProof({input: l, params: 'status_verifikator'})}
                  </TableColumn>
                  <TableColumn label='Status Penandatanganan' className='min-w-150px'>
                    {NullProof({input: l, params: 'status_penandatanganan'})}
                  </TableColumn>
                  <TableColumn label='Status Kirim' className='min-w-150px'>
                    {NullProof({input: l, params: 'Status_kirim'})}
                  </TableColumn>
                  <TableColumn label='Status Berkas' className='min-w-150px'>
                    {NullProof({input: l, params: 'status_berkas'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>(dummyData[0])
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <Modal
        id='modal_statusubah'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            isFeedback: true,
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              // console.log(e)
              setModal((p: any) => ({...p, statusUbah: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail
            isDummy
            onSuccess={(val: any) => setData(val)}
            {...TableInputDetailData}
          >
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Naskah Masuk Dari', props: {className: 'mb-2 fs-5'}}}
            >
              <div className='d-flex gap-4 align-items-center'>
                <div>
                  <AvatarUi
                    name={NullProof({input: data, params: 'pegawai.nama'})}
                    url={NullProof({input: data, params: 'pegawai.foto'})}
                  />
                </div>
                <div>
                  <div className='fw-bold fs-5'>
                    {NullProof({input: data, params: 'pegawai.nama'})}
                  </div>
                  <div>{NullProof({input: data, params: 'pegawai.jabatan'})}</div>
                </div>
              </div>
            </TableInputDetailColumn>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Nomor Referensi'}}
                >
                  {NullProof({input: data, params: 'nomor_referensi'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  isResponsive='desktop'
                  hideDivider
                  title={{value: 'Nomor Naskah'}}
                >
                  {NullProof({input: data, params: 'nomor_naskah'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  isResponsive='desktop'
                  hideDivider
                  title={{value: 'Tanggal Naskah'}}
                >
                  {NullProof({input: data, params: 'tanggal_naskah', type: 'date'})}
                </TableInputDetailColumn>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Hal'}}>
                  {NullProof({input: data, params: 'hal'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Isi'}}>
                  {NullProof({input: data, params: 'isi'})}
                </TableInputDetailColumn>
              </div>
            </div>
            <div className='my-4 d-flex flex-column gap-2'></div>
            <Accordion
              title='Detail Naskah'
              enabled
              props={{body: {className: 'p-4 border-bottom'}}}
            >
              <div className='row'>
                <div className='row col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Jenis Naskah'}}
                  >
                    {NullProof({input: data, params: 'jenis_naskah'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Sifat Naskah'}}
                  >
                    {NullProof({input: data, params: 'sifat_naskah'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Tingkat Urgensi'}}
                  >
                    {NullProof({input: data, params: 'tingkat_urgensi'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Klasifikasi'}}
                  >
                    {NullProof({input: data, params: 'klasifikasi'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Tipe Tandatangan'}}
                  >
                    {NullProof({input: data, params: 'tipe_tandatangan'})}
                  </TableInputDetailColumn>
                </div>
                <div className='row col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Berkas'}}
                  >
                    {NullProof({input: data, params: 'status_berkas'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-2'}}
                    hideDivider
                    title={{value: 'Diregistrasikan Pada'}}
                  >
                    {NullProof({input: data, params: 'tanggal_regis_pada', type: 'date'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-2'}}
                    hideDivider
                    title={{value: 'Diregistrasikan Oleh'}}
                  >
                    {NullProof({input: data, params: 'diregis_oleh'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-2'}}
                    hideDivider
                    title={{value: 'Dikirim Melalui'}}
                  >
                    {NullProof({input: data, params: 'dikirimkan_melalui'})}
                  </TableInputDetailColumn>
                </div>
              </div>
            </Accordion>
            <Accordion title='Penerima' enabled props={{body: {className: 'p-4 border-bottom'}}}>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'mb-4'}}
                    hideDivider
                    title={{value: 'Utama:'}}
                  ></TableInputDetailColumn>
                  {NullProof({input: data, params: 'penerima.utama', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableInputDetailColumn
                          props={{className: 'row mb-2'}}
                          hideDivider
                          title={{value: NullProof({input: l, params: 'pegawai.nama'})}}
                        >
                          {NullProof({input: l, params: 'pegawai.jabatan'})}
                        </TableInputDetailColumn>
                      </Fragment>
                    )
                  )}
                </div>
                <div className='col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'mb-4'}}
                    hideDivider
                    title={{value: 'Tembusan:'}}
                  ></TableInputDetailColumn>
                  {NullProof({input: data, params: 'penerima.tebusan', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableInputDetailColumn
                          props={{className: 'row mb-2'}}
                          hideDivider
                          title={{value: NullProof({input: l, params: 'pegawai.nama'})}}
                        >
                          {NullProof({input: l, params: 'pegawai.jabatan'})}
                        </TableInputDetailColumn>
                      </Fragment>
                    )
                  )}
                </div>
              </div>
            </Accordion>
          </TableInputDetail>
        </CardUi>
        <PdfPageViewer
          props={{className: 'my-6'}}
          options={{hideBreadcrumb: true, hideBack: true}}
          route={RouterQueryParams(`${InfoData.api}/:id`, query)}
          PageData={{
            breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
            title: '',
          }}
          PdfLayout={PdfLayout}
          data={{}}
        />
        <CardUi title='Lampiran' props={{className: 'my-6'}}>
          Belum ada lampiran
        </CardUi>
        <CardUi title='Daftar Verifikator' props={{className: 'my-6'}}>
          <TableDaftarVerifikator />
        </CardUi>
        <CardUi title='Daftar Penandatanganan' props={{className: 'my-6'}}>
          <TablePenandaTanganan />
        </CardUi>
        <TabsUi
          title='Daftar Riwayat Perubahan Metadata'
          tabs={[
            {
              id: 'naskah_dinas',
              title: 'Naskah Dinas',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputNaskahDinas}
                  title={'Naskah Dinas'}
                />
              ),
            },
            {
              id: 'lampiran',
              title: 'Lampiran',
              children: (
                <TableRiwayatPerubahanMetadata dataInput={TableInputLampiran} title={'Lampiran'} />
              ),
            },
            {
              id: 'tujuan',
              title: 'Tujuan',
              children: (
                <TableRiwayatPerubahanMetadata dataInput={TableInputTujuan} title={'Tujuan'} />
              ),
            },
            {
              id: 'verifikator',
              title: 'Verifikator',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputVerifikator}
                  title={'Verifikator'}
                />
              ),
            },
            {
              id: 'penandatanganan',
              title: 'Penandatanganan',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputPenandatanganan}
                  title={'Penandatanganan'}
                />
              ),
            },
            {
              id: 'status_kirim',
              title: 'Status Kirim',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputStatusKirim}
                  title={'Status Kirim'}
                />
              ),
            },
          ]}
        />
        <CardUi title='History Naskah' props={{className: 'my-6'}}>
          <TableHistoriNaskah />
        </CardUi>
        <CardUi title='Linimasa'>
          {NullProof({input: data, params: 'linimasa', isMap: true})?.map((l: any, i: number) => (
            <Fragment key={i}>
              <TimelineUi
                isEnd={i + 1 === NullProof({input: data, params: 'linimasa', isMap: true})?.length}
                title={NullProof({input: l, params: 'nama'})}
                date={NullProof({
                  input: l,
                  params: 'tanggal',
                  type: 'date',
                  option: {date: {format: 'dd.MM.yy'}},
                })}
              >
                <div>{NullProof({input: l, params: 'pegawai.nama'})}</div>
                <div>{NullProof({input: l, params: 'pegawai.jabatan'})}</div>
              </TimelineUi>
            </Fragment>
          ))}
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper}
