import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {CardUi} from '../../../../../helper/AppUi'
import {TableAction, TableColumn, TableInput, TableRow} from '../../../../../helper/TableInput'
import {
  ConvertCurrency,
  NullProof,
  numberlistPagination,
  RouterQueryParams,
} from '../../../../../helper/AppFunction'
import {formatDate} from '../../../../../helper/DateFns'
import FormInput from '../../../../../helper/FormInput'
import {formInputList} from './InputLayout'
import {useParams} from 'react-router-dom'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../helper/TableInputDetail'
import { PdfPageViewer } from '../../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import ExcelLayout from './ExcelLayout'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({
            input: data,
            params: 'data',
            isMap: true,
          }).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Satuan Kerja' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'satker.nama_satker'})}
                  </TableColumn>
                  <TableColumn label='Penandatangan Komitmen' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'penandatangan_komitmen.nama'})}
                  </TableColumn>
                  <TableColumn label='PIC Satker' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'pic_satker.nama'})}
                  </TableColumn>
                  <TableColumn label='PIC Irjen' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'pic_irjen.nama'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  let InitPdfLayout = PdfLayout
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={InitPdfLayout}
        // justPreview={true}
        ExcelLayout={ExcelLayout}
        options={{
          isRedux: query?.id === 'pilihan',
        }}
      />
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})

  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Satuan Kerja'}}>
            {NullProof({input: data, params: 'satker.nama_satker'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Penandatangan Komitmen'}}>
            {NullProof({input: data, params: 'penandatangan_komitmen.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jabatan'}}>
            {NullProof({input: data, params: 'penandatangan_komitmen.jabatan.jabatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'No HP'}}>
            {NullProof({input: data, params: 'penandatangan_komitmen.no_hp'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Target Waktu Penyelesaian'}}>
            {NullProof({input: data, params: 'target_waktu',  type: 'date'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Petugas Pemantau'}}>
            {NullProof({input: data, params:'petugas_pemantau.nama'})}
          </TableInputDetailColumn>
          {NullProof({input: data, params: 'komitmen_kerja', isMap: true})?.map(
            (ll: any, ii: number) => (
              <Fragment key={ii}>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: `${ii + 1}. Komitmen Kerja`}}
                  props={{
                    className: 'mt-1',
                  }}
                >
                  <TableInputDetailColumn
                    hideDivider
                    useGuide
                    isResponsive='desktop'
                    title={{value: 'Kode'}}
                  >
                    {NullProof({input: ll, params: 'kode'})}
                    <TableInputDetailColumn
                      hideDivider
                      isResponsive='desktop'
                      title={{value: 'Program/Kegiatan'}}
                    >
                      {NullProof({input: ll, params: 'kegiatan'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      useSub
                      hideDivider
                      isResponsive='desktop'
                      title={{value: ''}}
                    >
                      {NullProof({input: ll, params: 'indikator', isMap: true})?.map(
                        (lll: any, iii: number) => (
                          <Fragment key={iii}>
                            <TableInputDetailColumn
                              hideDivider
                              isResponsive='desktop'
                              title={{value: `${iii + 1}. Indikator`}}
                              props={{
                                className: 'mt-1',
                              }}
                            >
                              <TableInputDetailColumn
                                useGuide
                                hideDivider
                                isResponsive='desktop'
                                title={{value: 'Indikator'}}
                              >
                                {NullProof({input: lll, params: 'indikator'})}
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Target'}}
                                >
                                  {NullProof({input: lll, params: 'target'})}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Februari'}}
                                >
                                  {NullProof({
                                    input: lll,
                                    params: 'uraian_target_bulanan.uraian_februari',
                                  })}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Presentase'}}
                                >
                                  {lll.uraian_target_bulanan?.presentase_febru
                                    ? NullProof({
                                        input: lll,
                                        params: 'uraian_target_bulanan.presentase_febru',
                                      })
                                    : 0}
                                  %
                                </TableInputDetailColumn>

                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Maret'}}
                                >
                                  {NullProof({
                                    input: lll,
                                    params: 'uraian_target_bulanan.uraian_maret',
                                  })}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Presentase'}}
                                >
                                  {lll.uraian_target_bulanan?.presentase_maret
                                    ? NullProof({
                                        input: lll,
                                        params: 'uraian_target_bulanan.presentase_maret',
                                      })
                                    : 0}
                                  %
                                </TableInputDetailColumn>

                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'April'}}
                                >
                                  {NullProof({
                                    input: lll,
                                    params: 'uraian_target_bulanan.uraian_april',
                                  })}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Presentase'}}
                                >
                                  {lll.uraian_target_bulanan?.presentase_april
                                    ? NullProof({
                                        input: lll,
                                        params: 'uraian_target_bulanan.presentase_april',
                                      })
                                    : 0}
                                  %
                                </TableInputDetailColumn>

                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Mei'}}
                                >
                                  {NullProof({
                                    input: lll,
                                    params: 'uraian_target_bulanan.uraian_mei',
                                  })}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Presentase'}}
                                >
                                  {lll.uraian_target_bulanan?.presentase_mei
                                    ? NullProof({
                                        input: lll,
                                        params: 'uraian_target_bulanan.presentase_mei',
                                      })
                                    : 0}
                                  %
                                </TableInputDetailColumn>

                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Juni'}}
                                >
                                  {NullProof({
                                    input: lll,
                                    params: 'uraian_target_bulanan.uraian_juni',
                                  })}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Presentase'}}
                                >
                                  {lll.uraian_target_bulanan?.presentase_juni
                                    ? NullProof({
                                        input: lll,
                                        params: 'uraian_target_bulanan.presentase_juni',
                                      })
                                    : 0}
                                  %
                                </TableInputDetailColumn>

                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Juli'}}
                                >
                                  {NullProof({
                                    input: lll,
                                    params: 'uraian_target_bulanan.uraian_juli',
                                  })}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Presentase'}}
                                >
                                  {lll.uraian_target_bulanan?.presentase_juli
                                    ? NullProof({
                                        input: lll,
                                        params: 'uraian_target_bulanan.presentase_juli',
                                      })
                                    : 0}
                                  %
                                </TableInputDetailColumn>

                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Agustus'}}
                                >
                                  {NullProof({
                                    input: lll,
                                    params: 'uraian_target_bulanan.uraian_agustus',
                                  })}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Presentase'}}
                                >
                                  {lll.uraian_target_bulanan?.presentase_agust
                                    ? NullProof({
                                        input: lll,
                                        params: 'uraian_target_bulanan.presentase_agust',
                                      })
                                    : 0}
                                  %
                                </TableInputDetailColumn>

                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'September'}}
                                >
                                  {NullProof({
                                    input: lll,
                                    params: 'uraian_target_bulanan.uraian_september',
                                  })}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Presentase'}}
                                >
                                  {lll.uraian_target_bulanan?.presentase_septe
                                    ? NullProof({
                                        input: lll,
                                        params: 'uraian_target_bulanan.presentase_septe',
                                      })
                                    : 0}
                                  %
                                </TableInputDetailColumn>

                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Oktober'}}
                                >
                                  {NullProof({
                                    input: lll,
                                    params: 'uraian_target_bulanan.uraian_oktober',
                                  })}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn
                                  hideDivider
                                  isResponsive='desktop'
                                  title={{value: 'Presentase'}}
                                >
                                  {lll.uraian_target_bulanan?.presentase_oktob
                                    ? NullProof({
                                        input: lll,
                                        params: 'uraian_target_bulanan.presentase_oktob',
                                      })
                                    : 0}
                                  %
                                </TableInputDetailColumn>

                              </TableInputDetailColumn>
                            </TableInputDetailColumn>
                          </Fragment>
                        )
                      )}
                    </TableInputDetailColumn>
                  </TableInputDetailColumn>
                </TableInputDetailColumn>
              </Fragment>
            )
          )}
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper, PdfWrapper}
