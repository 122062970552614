/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList, formInputStatusEvalap} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {CardUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import {
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import {formatDate} from '../../../../helper/DateFns'
import FormInput, {BaseInputOnChangeProps} from '../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {ProtectComponent} from '../../../../helper/UserRolesValidation'
import Modal from '../../../../helper/Modal'

const onChange = (e: BaseInputOnChangeProps) => {
  if (e.type === 'onChange') {
    const {getObject: dataObj, get: data} = e._data
    const result = {...data}
    // recalculate total_pengeluaran
    const total_pengeluaran = NullProof({
      input: dataObj,
      params: 'list_pengeluaran',
      isMap: true,
    })?.reduce((a: number, b: any) => a + (b.jumlah || 0), 0)

    e.resetForm({values: {...result, total_pengeluaran}})
  }
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Surat Tugas' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                  </TableColumn>
                  <TableColumn label='Pegawai' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </TableColumn>
                  <TableColumn label='Nominal Pengeluaran' props={{style: {minWidth: '200px'}}}>
                    {ConvertCurrency(
                      NullProof({
                        input: l,
                        params: 'total_pengeluaran',
                        isLabel: false,
                      }) || 0
                    )}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    bendahara: false,
    evalap: false,
  })
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <Modal
        id='modal_status_evalap'
        isShow={modal?.evalap}
        onClose={() => {
          setModal((p: any) => ({...p, evalap: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatusEvalap}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-evalap`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, evalap: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Pegawai'}}>
              {NullProof({input: data, params: 'pegawai.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Pembuat Komitmen'}}>
              {NullProof({input: data, params: 'pembuat_komitmen.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nomor Surat'}}>
              {NullProof({input: data, params: 'no_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Surat Tugas'}}>
              {NullProof({input: data, params: 'surat_tugas.nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal'}}>
              {NullProof({input: data, params: 'format_tanggal'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Daftar Pengeluaran'}}
            >
              {NullProof({input: data, params: 'list_pengeluaran', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn
                      isResponsive='desktop'
                      title={{value: `${i + 1}. Pengeluaran`}}
                    >
                      <TableInputDetailColumn hideDivider title={{value: 'Uraian'}}>
                        {NullProof({input: l, params: 'uraian'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Jumlah'}}>
                        {ConvertCurrency(
                          NullProof({input: l, params: 'jumlah', isLabel: false}) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Total Pengeluaran'}} hideDivider>
              {ConvertCurrency(
                NullProof({input: data, params: 'total_pengeluaran', isLabel: false}) || 0
              )}
            </TableInputDetailColumn>

            {/* give some space */}
            <div style={{height: '25px'}} />

            <TableInputDetailColumn title={{value: 'Status evalap'}}>
              <div className='d-flex align-items-center gap-2'>
                <div>{NullProof({input: data, params: 'status_evalap'})}</div>
                <div>
                  <ProtectComponent id='ubah_status_evalap/:id' route={InfoData.path}>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal((p: any) => ({...p, evalap: true}))
                      }}
                    >
                      Ubah Status
                    </button>
                  </ProtectComponent>
                </div>
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'evalap'}}>
              {NullProof({input: data, params: 'evalap.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan evalap'}}>
              {NullProof({input: data, params: 'catatan_evalap'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  evalap'}}>
              {NullProof({
                input: data,
                params: 'status_evalap_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper}
