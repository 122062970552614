import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfLayoutDataProps,
  PdfStyle,
  PdfTableColumnBuilder,
} from '../../../helper/ReactPdf'
import {ConvertCurrency, NullProof} from '../../../helper/AppFunction'
import {formatDate} from '../../../helper/DateFns'

const HeaderContent: FC<{data: any}> = ({data}) => {
  const _data = {
    data: data,
  }
  console.log(_data)
  return (
    <>
      <PdfTableColumnBuilder
        borderBottom
        style={{textTransform: 'uppercase', fontWeight: 'semibold'}}
      >
        <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            No.
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SP2D
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SPM
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Akun
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nilai SPM
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tanggal SP2D
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            BLN
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nilai SP2D
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Ket
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nominatif
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Cek
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Saldo SPM
          </Text>
        </PdfTableColumnBuilder>
        {NullProof({input: _data, params: 'data', isMap: true})?.map((l: any, i: number) => (
          <Fragment key={i}>
            <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
              <Text
                style={{
                  padding: '10px 20px',
                  textAlign: 'center',
                }}
              >
                {NullProof({input: l, params: 'tanggal'})}
              </Text>
            </PdfTableColumnBuilder>
          </Fragment>
        ))}
      </PdfTableColumnBuilder>
    </>
  )
}

const PdfLayoutSemua: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  let detailList: {tanggal: string; saldo: number}[] = []
  console.log(data.data)

  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{width: '100%'}}>
          <PdfTableColumnBuilder direction='column' border>
            {/* Header */}
            <HeaderContent data={detailList} />
            {/* Looping Here */}
            {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {i + 1}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'sp2d'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'spm'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'akun'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(
                        NullProof({input: l, params: 'nilai_spm', isLabel: false}) || 0
                      )}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal_sp2d'}),
                        dateFormat: 'dd/MM/yyyy',
                      })}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'bln'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(
                        NullProof({input: l, params: 'nilai_sp2d', isLabel: false}) || 0
                      )}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'ket'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(
                        NullProof({input: l, params: 'nominatif', isLabel: false}) || 0
                      )}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'cek', isLabel: false}) === 'true'
                        ? 'Iya'
                        : NullProof({input: l, params: 'cek', isLabel: false}) === 'false'
                        ? 'Tidak'
                        : '-'}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(
                        NullProof({input: l, params: 'saldo_spm', isLabel: false}) || 0
                      )}
                    </Text>
                  </PdfTableColumnBuilder>
                  {NullProof({input: l, params: 'detail', isMap: true})?.map(
                    (ls: any, is: number) => {
                      let tanggal = formatDate({
                        date: NullProof({input: ls, params: 'tanggal', isLabel: false}),
                        dateFormat: 'ddMMyyyy',
                      })
                      let saldo = Number(NullProof({input: ls, params: 'saldo', isLabel: false}))
                      const list = detailList.findIndex((fl) => fl.tanggal === tanggal)
                      if (list > -1) {
                        detailList[list].saldo += saldo
                      } else {
                        detailList.push({
                          saldo: saldo,
                          tanggal: tanggal,
                        })
                      }
                      return (
                        <Fragment key={is}>
                          <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                            <Text
                              style={{
                                padding: '10px 20px',
                                textAlign: 'center',
                              }}
                            >
                              {ConvertCurrency(
                                NullProof({input: ls, params: 'saldo', isLabel: false}) || 0
                              )}
                            </Text>
                          </PdfTableColumnBuilder>
                        </Fragment>
                      )
                    }
                  )}
                </PdfTableColumnBuilder>
              </Fragment>
            ))}
          </PdfTableColumnBuilder>
        </View>
      </Page>
    </Document>
  )
}

export {PdfLayoutSemua}
