import {FC, Fragment} from 'react'
import {Document, Page, View} from '@react-pdf/renderer'
import {
  FontConfig,
  PdfLayoutDataProps,
  PdfStyle,
  PdfTabCol,
} from '../../../../../helper/ReactPdf'
import {NullProof} from '../../../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  data.data.pegawai = [
    {nip: '1231231', nama: 'Satu', tanggal: '2024-01-18T11:02:59Z', harga: '100000'},
    {nip: '1231232', nama: 'Dua', tanggal: '2024-01-18T11:02:59Z', harga: '200000'},
    {nip: '1231233', nama: 'Tiga', tanggal: '2024-01-18T11:02:59Z', harga: '300000'},
    {nip: '1231234', nama: 'Empat', tanggal: '2024-01-18T11:02:59Z', harga: '400000'},
    {nip: '1231235', nama: 'Lima', tanggal: '2024-01-18T11:02:59Z', harga: '500000'},
  ]
  return (
    <Document title='Dokumen'>
      <Page wrap={false} style={{...PdfStyle.page, fontFamily: 'Arial'}}>
        <PdfTabCol style={{view: {height: '', fontWeight: 'bold'}}}>
          <PdfTabCol type='text'>Satu</PdfTabCol>
          <PdfTabCol type='text'>Dua</PdfTabCol>
          <PdfTabCol type='text'>Tiga</PdfTabCol>
          <PdfTabCol type='text'>Empat</PdfTabCol>
        </PdfTabCol>
        <PdfTabCol style={{view: {height: '', fontWeight: 'bold'}}}>
          <PdfTabCol style={{view: {fontWeight: 'bold'}}}>
            <PdfTabCol type='text'>Satu</PdfTabCol>
            <PdfTabCol type='text'>Dua</PdfTabCol>
            <PdfTabCol type='text'>Tiga</PdfTabCol>
            <PdfTabCol type='text'>Empat</PdfTabCol>
          </PdfTabCol>
          <PdfTabCol style={{view: {fontWeight: 'bold'}}}>
            <PdfTabCol type='text'>Satu</PdfTabCol>
            <PdfTabCol type='text'>Dua</PdfTabCol>
            <PdfTabCol type='text'>Tiga</PdfTabCol>
            <PdfTabCol type='text'>Empat</PdfTabCol>
          </PdfTabCol>
        </PdfTabCol>
        <PdfTabCol direction='column' style={{view: {height: '', fontWeight: 'bold'}}}>
          <PdfTabCol style={{view: {fontWeight: 'bold'}}}>
            <PdfTabCol type='text'>Satu</PdfTabCol>
            <PdfTabCol type='text'>Dua</PdfTabCol>
            <PdfTabCol type='text'>Tiga</PdfTabCol>
            <PdfTabCol type='text'>Empat</PdfTabCol>
          </PdfTabCol>
          <PdfTabCol style={{view: {fontWeight: 'bold'}}}>
            <PdfTabCol type='text'>Satu</PdfTabCol>
            <PdfTabCol type='text'>Dua</PdfTabCol>
            <PdfTabCol type='text'>Tiga</PdfTabCol>
            <PdfTabCol type='text'>Empat</PdfTabCol>
          </PdfTabCol>
        </PdfTabCol>
        <View>
          <PdfTabCol center border={{}} type='text'>
            Tabel Pegawai
          </PdfTabCol>
          <PdfTabCol direction='column' style={{view: {height: ''}}}>
            <PdfTabCol border={{}} style={{view: {fontWeight: 'bold'}}}>
              <PdfTabCol border={{right: 1}} type='text'>
                NIP
              </PdfTabCol>
              <PdfTabCol border={{right: 1}} type='text'>
                Nama
              </PdfTabCol>
              <PdfTabCol border={{right: 1}} type='text'>
                Tanggal
              </PdfTabCol>
              <PdfTabCol border={{}} type='text'>
                Harga
              </PdfTabCol>
            </PdfTabCol>
            {NullProof({input: data.data, params: 'pegawai', isMap: true})?.map(
              (l: any, i: number) => (
                <Fragment key={i}>
                  <PdfTabCol border={{top: 1}}>
                    <PdfTabCol border={{right: 1}} type='text'>
                      {NullProof({input: l, params: 'nip'})}
                    </PdfTabCol>
                    <PdfTabCol border={{right: 1}} type='text'>
                      {NullProof({input: l, params: 'nama'})}
                    </PdfTabCol>
                    <PdfTabCol border={{right: 1}} type='text'>
                      {NullProof({input: l, params: 'tanggal', type: 'date'})}
                    </PdfTabCol>
                    <PdfTabCol border={{}} type='text'>
                      {NullProof({input: l, params: 'harga', type: 'currency'})}
                    </PdfTabCol>
                  </PdfTabCol>
                </Fragment>
              )
            )}
          </PdfTabCol>
        </View>
      </Page>
    </Document>
  )
}

export default PdfLayout
