import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfFontBuilder,
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfSignatureBuilder,
  PdfStyle,
  PdfStylePallete,
  PdfTabCol,
} from '../../../helper/ReactPdf'
import {formatDate} from '../../../helper/DateFns'
import {AlphabetIndex, ConvertCurrency, NullProof, ReactSrc} from '../../../helper/AppFunction'

const API_URL = process.env.REACT_APP_API_URL

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'

  const listSkor: any[] = [
    {title: 'X1', name: 'cek_1'},
    {title: 'X2', name: 'cek_2'},
    {title: 'X3', name: 'cek_3'},
    {title: 'X4', name: 'cek_4'},
    {title: 'X5', name: 'cek_5'},
  ]

  return (
    <Document title='Dokumen'>
      <Page size={{width: '1500'}} style={{...PdfStyle.page, fontFamily: 'Arial', padding: '20pt'}}>
        <View style={{fontWeight: 'bold', textAlign: 'center'}}>
          <Text
            style={{
              textTransform: 'uppercase',
              ...PdfFontBuilder({template: 'title', size: 'large'}),
            }}
          >
            Rencana Pengawasan Menengah 5 Tahunan
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              ...PdfFontBuilder({template: 'title', size: 'large'}),
            }}
          >
            {`Tahun ${NullProof({input: data.data, params: 'periode_start'})} s.d Tahun ${NullProof(
              {input: data.data, params: 'periode_end'}
            )}`}
          </Text>
        </View>
        <PdfTabCol direction='column'>
          {/* Header */}
          <PdfTabCol style={{view: {height: '100pt'}}} border={{}}>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '5%'}}}>
              No
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '20%'}}}>
              Auditi
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              Tanggal LHP Terakhir
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              Risiko
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              Frek. Peng.
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              Jenis Peng.
            </PdfTabCol>
            <PdfTabCol direction='column' border={{}} style={{view: {width: '35%'}}}>
              <PdfTabCol center type='text' border={{bottom: 1}} style={{view: {height: '60pt'}}}>
                Tahun
              </PdfTabCol>
              <PdfTabCol center border={{}}>
                {listSkor.map((ls, is) => (
                  <Fragment key={is}>
                    <PdfTabCol
                      type='text'
                      center
                      noWrap
                      border={is + 1 !== listSkor.length ? {right: 1} : {}}
                    >
                      {NullProof({input: ls, params: 'title'})}
                    </PdfTabCol>
                  </Fragment>
                ))}
              </PdfTabCol>
            </PdfTabCol>
          </PdfTabCol>
          {/* Data */}
          {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <PdfTabCol style={{view: {height: '80pt'}}} border={{top: 1}}>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '5%'}}}
                  >
                    {i + 1}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '20%'}}}
                  >
                    {NullProof({input: data.data, params: 'satker.nama_satker'})}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '10%'}}}
                  >
                    {NullProof({input: data.data, params: 'tgl_lha_terakhir', type: 'date'})}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '10%'}}}
                  >
                    {NullProof({input: data.data, params: 'variable_here'})}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '10%'}}}
                  >
                    {NullProof({input: data.data, params: 'frekuensi_audit'})}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '10%'}}}
                  >
                    {NullProof({input: data.data, params: 'jenis_audit'})}
                  </PdfTabCol>
                  <PdfTabCol direction='column' border={{}} style={{view: {width: '35%'}}}>
                    <PdfTabCol center border={{}}>
                      {listSkor.map((ls, is) => (
                        <Fragment key={is}>
                          <PdfTabCol
                            type='text'
                            center
                            border={is + 1 !== listSkor.length ? {right: 1} : {}}
                          >
                            {NullProof({input: l, params: ls.name, isLabel: false}) ? 'V' : 'X'}
                          </PdfTabCol>
                        </Fragment>
                      ))}
                    </PdfTabCol>
                  </PdfTabCol>
                </PdfTabCol>
              </Fragment>
            )
          })}
        </PdfTabCol>
        <PdfSignatureBuilder
          data={[
            {
              line1: `${NullProof({
                input: data.data,
                params: 'lokasi',
              })}, ${NullProof({input: data.data, params: 'createdAt', type: 'date'})}`,
              line2: 'Inspektur Jenderal,',
              typeSignature: 'string',
              signature: '$',
              align: 'center',
              name: NullProof({
                input: data.data,
                params: 'ketua_data.nama',
                label: 'Inspektur Jenderal',
              }),
            },
          ]}
          options={{
            align: 'flex-end',
            data: {align: 'flex-end'},
          }}
          style={{marginBottom: '100pt'}}
        />
      </Page>
    </Document>
  )
}

export default PdfLayout
