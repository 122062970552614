import Component from '../../helper/i18n/en'
import Menu from './menu'
import Pages from './page'
import Sidebar from './sidebar'
import Global from './global'

const i18nEnAllMessages = {
  component: Component,
  sidebar: Sidebar,
  page: Pages,
  menu: Menu,
  global: Global,
}

export default i18nEnAllMessages
