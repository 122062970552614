import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputUraian: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'detail',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Detail is required'),
    options: {
      textarea: {
        props: {
          title: 'Detail',
          rows: 3,
          placeholder: 'Detail',
        },
      },
    },
  },
]

const formInputPengeluaran: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'uraian',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Detail',
          placeholder: 'Detail',
        },
      },
      datalist: {
        id: 'detail',
        api: '/eval_pelaporan/template-pengeluaran',
        params: 'detail',
        result: `$detail`,
        type: 'table',
        table: {
          protect: '/evaluasi_pelaporan/verifikasi_perjalanan_dinas/form_dpr',
          data: [
            {
              id: 'detail',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/eval_pelaporan/template-pengeluaran',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Detail',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: formInputUraian,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: formInputUraian,
                },
                {
                  id: 'id',
                  protect: 'delete',
                  title: 'Hapus',
                  route: '',
                  type: 'feedback',
                  input: formInputUraian,
                },
              ],
            },
          },
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
  {
    className: 'col-6',
    name: 'tanggal_mulai',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Mulai is required'),
    options: {
      input: {
        props: {
          title: 'Tanggal Mulai',
          placeholder: 'Tanggal Mulai',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-6',
    name: 'tanggal_selesai',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Selesai is required'),
    options: {
      input: {
        props: {
          title: 'Tanggal Selesai',
          placeholder: 'Tanggal Selesai',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'jumlah',
    type: 'input',
    value: '',
    validator: Yup.string().required('Jumlah wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah',
          placeholder: 'Jumlah',
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'byuser=true',
        params: 'nomor_surat,nama_kegiatan',
        result: '$nomor_surat | $nama_kegiatan',
        id: 'id',
      },
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'tanggal',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Tanggal is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Tanggal',
  //         placeholder: 'Tanggal',
  //         type: 'date',
  //       },
  //     },
  //   },
  // },
  {
    className: 'col-12',
    name: 'list_pengeluaran',
    type: 'component',
    value: '',
    validator: Yup.string().required('Pengeluaran wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Pengeluaran',
          placeholder: 'Pengeluaran',
        },
      },
    },
    component: formInputPengeluaran,
  },
  {
    className: 'col-9 d-flex flex-column justify-content-center text-end',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Nominal Pengeluaran',
          placeholder: 'Harga',
        },
      },
      label: {
        name: 'Nominal Pengeluaran',
        className: 'fs-6',
      },
    },
  },
  {
    className: 'col-md-3',
    name: 'total_pengeluaran',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: '',
          placeholder: 'Harga',
        },
      },
      currency: {
        props: {
          readOnly: true,
        },
      },
    },
  },
]

const formInputStatusEvalap: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_evalap',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_evalap',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
]

export {formInputList, formInputStatusEvalap}
