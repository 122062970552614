import {FormInputProps} from '../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../helper/template/FormInputTemplate'
import {
  formInputList as _formInputListSpd,
} from '../form-surat-perjalanan-dinas/InputLayout'
import * as Yup from 'yup'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_mulai',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Mulai',
          type: 'date',
          placeholder: 'Tanggal Mulai',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_selesai',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Selesai',
          type: 'date',
          placeholder: 'Tanggal Selesai',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kepada',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Anggota Tim',
          placeholder: 'Anggota Tim',
        },
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'pegawai_id',
        type: 'datalist',
        value: '',
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Nama Pegawai',
              placeholder: 'Nama Pegawai',
            },
          },
          datalist: {
            api: '/kepegawaian/data-tenaga-audit',
            query: 'orderby=asc',
            params: 'nama,nip',
            result: (
              <>
                <div>
                  <span className='fw-bold'>Nama : </span>
                  <span>$nama</span>
                </div>
                <div>
                  <span className='fw-bold'>NIP : </span>
                  <span>$nip</span>
                </div>
              </>
            ),
            id: 'id',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'nip', type: 'text'},
                {id: 'level_jabatan', type: 'text'},
                {id: 'satker_4', type: 'text'},
              ],
              protect: '',
              props: {
                filter: [
                  ...inputLayoutBuilder({
                    input: [{template: 'checkbox', name: 'irjen', title: 'Pegawai Itjen'}],
                  }),
                ],
                route: {
                  url: '/kepegawaian/data-tenaga-audit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'NIP'},
                  {title: 'Level Jabatan'},
                  {title: 'Satker'},
                ],
              },
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'jabatan',
        type: 'datalist',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Jabatan',
              placeholder: 'Jabatan',
            },
          },
          datalist: {
            api: '',
            params: 'title',
            result: '$title',
            id: 'value',
            data: [
              {
                title: 'Penanggung Jawab',
                value: 'Penanggung Jawab',
              },
              {
                title: 'Pengendali Teknis',
                value: 'Pengendali Teknis',
              },
              {
                title: 'Ketua Tim',
                value: 'Ketua Tim',
              },
              {
                title: 'Anggota',
                value: 'Anggota',
              },
              {
                title: 'Pengendali Mutu',
                value: 'Pengendali Mutu',
              },
              {
                title: 'Ketua Sub Tim',
                value: 'Ketua Sub Tim',
              },
              {
                title: 'Anggota Sub Tim',
                value: 'Anggota Sub Tim',
              },
            ],
          },
        },
      },
      {
        className: 'col-12',
        name: 'hari',
        type: 'input',
        value: '0',
        validator: Yup.number().required('Mohon untuk diisi.').min(0, 'Minimal 0 Hari'),
        options: {
          input: {
            props: {
              title: 'Hari',
              placeholder: 'Hari',
              type: 'number',
              min: 0,
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'urutan',
        type: 'input',
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Urutan',
              placeholder: 'Urutan',
              type: 'number',
              min: 1,
            },
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Tujuan',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'jenis_tujuan',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Sekjen & Direktorat',
        value: 'Sekjen & Direktorat',
      },
      {
        title: 'Wilayah',
        value: 'Wilayah',
      },
      {
        title: 'Lainnya',
        value: 'Lainnya',
      },
    ],
    validator: Yup.string().required('Jenis Tujuan wajib diisi'),
    options: {
      select: {
        props: {
          title: 'Jenis Tujuan',
          placeholder: 'Jenis Tujuan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'provinsi',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Provinsi is required'),
    options: {
      input: {
        props: {
          title: 'Provinsi',
          placeholder: 'Provinsi',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kota',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kota is required'),
    options: {
      input: {
        props: {
          title: 'Kota',
          placeholder: 'Kota',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-kota',
        query: 'provinsi=$provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
      watchInput: {
        id: 'provinsi',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'satker_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Unit is required'),
    options: {
      input: {
        props: {
          title: 'Unit',
          placeholder: 'Unit',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker-unit',
        query: 'provinsi=$provinsi&kota=$kota&jenis_tujuan=$jenis_tujuan',
        params: 'kode,nama_satker',
        result: '$kode - $nama_satker',
        id: 'kode',
      },
      watchInput: {
        id: 'provinsi,kota,jenis_tujuan',
      },
    },
  },
]

const formInputListAnggota: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Pegawai is required'),
    options: {
      input: {
        props: {
          title: 'Pegawai',
          placeholder: 'Pegawai',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        params: 'nama,nip',
        result: '$nama | $nip',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'jabatan',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Penanggung Jawab',
        value: 'Penanggung Jawab',
      },
      {
        title: 'Pengendali Teknis',
        value: 'Pengendali Teknis',
      },
      {
        title: 'Ketua Tim',
        value: 'Ketua Tim',
      },
      {
        title: 'Anggota',
        value: 'Anggota',
      },
    ],
    validator: Yup.string().required('Jabatan is Required'),
    options: {
      input: {
        props: {
          title: 'Jabatan',
          placeholder: 'Jabatan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'hari',
    type: 'input',
    value: '',
    validator: Yup.number().required('Hari wajib diisi.').min(0, 'Minimal 0 Hari'),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Hari',
          placeholder: 'Hari',
          type: 'number',
          min: 0,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'hotel',
    type: 'input',
    value: '',
    validator: Yup.boolean(),
    options: {
      input: {
        props: {
          title: 'Hotel',
          placeholder: 'Centang jika menginap dihotel',
          type: 'checkbox',
          min: 0,
        },
      },
    },
  },
]

const formInputListTujuan: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'provinsi',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Provinsi is required'),
    options: {
      input: {
        props: {
          title: 'Provinsi',
          placeholder: 'Provinsi',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
    },
  },
  {
    className: 'col-12',
    name: 'kota',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kota is required'),
    options: {
      input: {
        props: {
          title: 'Kota',
          placeholder: 'Kota',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-kota',
        query: 'provinsi=$provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
      watchInput: {
        id: 'provinsi',
      },
    },
  },
  {
    className: 'col-12',
    name: 'satker_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Unit is required'),
    options: {
      input: {
        props: {
          title: 'Unit',
          placeholder: 'Unit',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker2',
        query: 'provinsi=$provinsi&kota=$kota',
        params: 'singkatan,nama_lengkap',
        result: '$singkatan - $nama_lengkap',
        id: 'kode',
      },
      watchInput: {
        id: 'provinsi,kota',
      },
    },
  },
]

const formInputListSpd: FormInputProps[] = [..._formInputListSpd]

const formInputStatus2: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_2',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_2',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'passphrase',
    type: 'input',
    value: '',
    validator: Yup.string().required('Passphrase is required'),
    options: {
      input: {
        props: {
          title: 'Passphrase',
          placeholder: 'Passphrase',
          type: 'password',
        },
      },
    },
  },
]

const formInputStatusIrwil: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
]

const formInputStatusppk: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_ppk',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_ppk',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'passphrase',
    type: 'input',
    value: '',
    validator: Yup.string().required('Passphrase is required'),
    options: {
      input: {
        props: {
          title: 'Passphrase',
          placeholder: 'Passphrase',
          type: 'password',
        },
      },
    },
  },
]

const formInputStatus: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status',
    type: 'input',
    value: 'Dibatalkan',
    disabled: true,
    validator: Yup.string().required('Status is required'),
    options: {
      select: {
        props: {
          title: 'Pembatalan Surat Tugas',
          placeholder: 'Pembatalan Surat Tugas',
        },
      },
    },
  },
]

export {
  formInputList,
  formInputListAnggota,
  formInputListSpd,
  formInputListTujuan,
  formInputStatus2,
  formInputStatusIrwil,
  formInputStatusppk,
  formInputStatus,
}
