import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        params: 'nomor_surat,nama_kegiatan',
        result: '$nomor_surat | $nama_kegiatan',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'temuan_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Temuan is required'),
    options: {
      input: {
        props: {
          title: 'Temuan',
          placeholder: 'Temuan',
        },
      },
      datalist: {
        api: '/pengawasan_internal/form-temuan',
        query: 'surat_tugas_id=$surat_tugas_id',
        params: 'kondisi,rekomendasi',
        result: 'Kondisi: $kondisi | Rekomendasi: $rekomendasi',
        id: 'id',
      },
      watchInput: {
        id: 'surat_tugas_id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian_tanggapan',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Uraian Tanggapan is required'),
    options: {
      textarea: {
        props: {
          title: 'Uraian Tanggapan',
          placeholder: 'Uraian Tanggapan',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-6',
    name: 'bukti_tanggapan',
    type: 'input',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Bukti Tanggapan (dokumen)',
          placeholder: 'Bukti Tanggapan (dokumen)',
          type: 'file',
          accept: '.pdf, .jpg, .jpeg, .png',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
      },
    },
  },
]

export {formInputList}
