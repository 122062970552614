import {
  MainWrapper,
  PdfWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
} from '../../../../../../../pages/pengawasan-internal/pelaksanaan-penugasan-assurans/audit/pelaksanaan-audit/form-pka/MainWrapper'
import {DataRoutesProps} from '../../../../../../UserRolesValidation'
import {KkaRoutes} from './Kka'

const FormPkaRoutes: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'pdf/:id',
    elements: <PdfWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'kka/:id',
    elements: <></>,
    subroute: KkaRoutes,
  },
]

export {FormPkaRoutes}
