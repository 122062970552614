import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'kategori',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kategori is required'),
    options: {
      input: {
        props: {
          title: 'Kategori',
          placeholder: 'Kategori',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'sub_kategori',
    type: 'input',
    value: '',
    validator: Yup.string().required('Sub Kategori is required'),
    options: {
      input: {
        props: {
          title: 'Sub Kategori',
          placeholder: 'Sub Kategori',
        },
      },
    },
  },
]

export {formInputList}
