import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../helper/template/FormInputTemplate'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    // disabled: true,
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'pengusul=true',
        params: 'nomor_surat,nama_kegiatan,tanggal_mulai,tanggal_selesai',
        result: `$nomor_surat | $nama_kegiatan | $tanggal_mulai - $tanggal_selesai`,
        id: 'id',
      },
    },
  },
  {
    className: 'col-md-12',
    name: 'file_laporan',
    type: 'input',
    value: '',
    validator: Yup.string().required('File Laporan wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'File Laporan',
          placeholder: 'File Laporan',
          accept: '.pdf, .jpg, .jpeg, .png',
          type: 'file',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'keterangan',
    type: 'textarea',
    validator: Yup.string().required('Keterangan mohon untuk diisi.'),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Keterangan',
          placeholder: 'Keterangan',
          rows: 3,
        },
      },
    },
  },
]

export {formInputList}
