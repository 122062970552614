import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {CardUi, ModalButtonUi} from '../../../../helper/AppUi'
import {TableAction, TableColumn, TableInput, TableRow} from '../../../../helper/TableInput'
import {
  ConvertCurrency,
  NullProof,
  numberlistPagination,
  RouterQueryParams,
} from '../../../../helper/AppFunction'
import {formatDate} from '../../../../helper/DateFns'
import FormInput from '../../../../helper/FormInput'
import {formInputList} from './InputLayout'
import {useParams} from 'react-router-dom'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {ReactPdfViewer} from '../../../../helper/ReactPdf'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({
            input: data,
            params: 'data',
            isMap: true,
          }).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td style={{minWidth: '50px'}}>
                    {numberlistPagination({
                      n: i,
                      p: data?.page,
                      t: data?.size,
                    })}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({
                      input: l,
                      params: 'surat_tugas.nomor_surat',
                    })}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({
                      input: l,
                      params: 'pegawai.nama',
                    })}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {formatDate({
                      date: NullProof({
                        input: l,
                        params: 'createdAt',
                      }),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                    <br />
                    {formatDate({
                      date: NullProof({
                        input: l,
                        params: 'createdAt',
                      }),
                      dateFormat: 'HH:mm:ss',
                    })}
                  </td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})

  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Surat Tugas'}}>
            {NullProof({
              input: data,
              params: 'surat_tugas.nomor_surat',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Pegawai'}}>
            {NullProof({
              input: data,
              params: 'pegawai.nama',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: `File laporan`}}>
            {NullProof({input: data, params: 'file_laporan', isLabel: false}) ? (
              <ModalButtonUi
                options={{
                  modal: {
                    id: `modal_file_laporan`,
                    title: `File laporan`,
                  },
                  button: {title: 'Lihat Dokumen'},
                }}
              >
                <ReactPdfViewer
                  url={`/${NullProof({
                    input: data,
                    params: 'file_laporan',
                    isLabel: false,
                  })}`}
                />
              </ModalButtonUi>
            ) : (
              '_'
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Status Tindak Lanjut'}}>
            {NullProof({
              input: data,
              params: 'tindak_lanjut',
              type: 'html',
            })}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper}
