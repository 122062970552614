const i18nGlobalAllMessages = {
  HELLO: 'Halo',
  SEARCH: 'Cari',
  ADD: 'Tambah',
  DELETE: 'Hapus',
  EDIT: 'Ubah',
  CREATE: 'Buat',
  DATA: 'Data',
  SHOW: 'Lihat',
  PAGE: 'Halaman',
  NO_DATA: 'Belum ada data',
  NO_DATA_DESCRIPTION: 'Masih belum ada data',
  SEARCH_EMPTY: 'Data yang anda cari tidak ditemukan',
  SEARCH_SHOWING: 'Menampilkan {data} data per halaman',
  SEARCH_INFO: 'Pencarian {data} ditemukan total {total} data',
}

export default i18nGlobalAllMessages
