import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfFontBuilder,
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfSignatureBuilder,
  PdfStyle,
  PdfStylePallete,
  PdfTabCol,
} from '../../../helper/ReactPdf'
import {formatDate} from '../../../helper/DateFns'
import {AlphabetIndex, ConvertCurrency, NullProof, ReactSrc} from '../../../helper/AppFunction'

const API_URL = process.env.REACT_APP_API_URL

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'

  const listSkor: any[] = [
    {title: 'Skor Pengawasan', name: 'skor_pengawasan', fee: 15},
    {title: 'SDM Auditi', name: 'sdm_auditi', fee: 5},
    {title: 'Perubahan Personil Kunci', name: 'perubahan_personil', fee: 5},
    {title: 'Jenis dan Tipologi Satker', name: 'jenis_tipologi', fee: 10},
    {title: 'Anggaran', name: 'anggaran', fee: 10},
    {title: 'Temuan dan Tindak Lanjut', name: 'temuan_tindak_lanjut', fee: 20},
    {title: 'Tahun Terakhir Audit', name: 'tahun_terakhir_audit', fee: 20},
    {title: 'Jarak Audit', name: 'jarak_audit', fee: 10},
    {title: 'Sistem Informasi', name: 'sistem_informasi', fee: 5},
  ]

  return (
    <Document title='Dokumen'>
      <Page size={{width: '1500'}} style={{...PdfStyle.page, fontFamily: 'Arial', padding: '20pt'}}>
        <View style={{fontWeight: 'bold', textAlign: 'center'}}>
          <Text
            style={{
              textTransform: 'uppercase',
              ...PdfFontBuilder({template: 'title', size: 'large'}),
            }}
          >
            Risiko Pengawasan
          </Text>
          <Text style={{...PdfFontBuilder({template: 'title', size: 'large'})}}>
            Per Tahun Pengawasan {NullProof({input: data.data, params: 'tahun'})}
          </Text>
        </View>
        <PdfTabCol direction='column'>
          {/* Header */}
          <PdfTabCol style={{view: {height: '130pt'}}}>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '5%'}}}>
              No
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '20%'}}}>
              Nama Satker Auditi
            </PdfTabCol>
            <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '65%'}}}>
              <PdfTabCol center type='text' border={{bottom: 1}} style={{view: {height: '60pt'}}}>
                Nilai Skor
              </PdfTabCol>
              <PdfTabCol center border={{}}>
                {listSkor.map((ls, is) => (
                  <Fragment key={is}>
                    <PdfTabCol
                      type='text'
                      center
                      noWrap
                      border={is + 1 !== listSkor.length ? {right: 1} : {}}
                    >
                      {NullProof({input: ls, params: 'title'})}
                    </PdfTabCol>
                  </Fragment>
                ))}
              </PdfTabCol>
            </PdfTabCol>
            <PdfTabCol
              center
              type='text'
              border={{}}
              style={{view: {width: '10%', padding: '10pt 10pt', textAlign: 'center'}}}
            >
              Total Skor (rata-rata)
            </PdfTabCol>
          </PdfTabCol>
          {/* Data */}
          {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <PdfTabCol style={{view: {height: '80pt'}}}>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '5%'}}}
                  >
                    {i + 1}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '20%'}}}
                  >
                    {NullProof({input: data.data, params: 'variable_here'})}
                  </PdfTabCol>
                  <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '65%'}}}>
                    <PdfTabCol center border={{}}>
                      {listSkor.map((ls, is) => (
                        <Fragment key={is}>
                          <PdfTabCol
                            type='text'
                            center
                            border={is + 1 !== listSkor.length ? {right: 1} : {}}
                          >
                            {Math.round(
                              (NullProof({input: l, params: ls.name, isLabel: false}) || 0) *
                                (ls.fee / 100)
                            )}
                          </PdfTabCol>
                        </Fragment>
                      ))}
                    </PdfTabCol>
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    type='text'
                    border={{}}
                    style={{view: {width: '10%', padding: '10pt 10pt', textAlign: 'center'}}}
                  >
                    {NullProof({input: l, params: 'total'})}
                  </PdfTabCol>
                </PdfTabCol>
              </Fragment>
            )
          })}
        </PdfTabCol>
      </Page>
    </Document>
  )
}

export default PdfLayout
