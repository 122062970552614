import {DataRoutesProps} from '../../UserRolesValidation'
import {MainWrapper} from '../../../pages/dashboard/irjen/DashboardIrjen'

const PenyusunanPerarturan: DataRoutesProps = {
  title: 'Penyusunan Peraturan',
  icon: 'element-10',
  route: 'penyusunan_peraturan',
  elements: <></>,
  subroute: [
    {
      title: 'Penelaahan hukum',
      icon: 'element-11',
      route: 'penelahaan-hukum',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penyusunan konsep peraturan',
      icon: 'element-11',
      route: 'penyusunan-konsep-peraturan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pengusulan rangangan peraturan (KMA/PMA)',
      icon: 'element-11',
      route: 'pengusulan-rangrangan-peraturan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penetapan pengesahan peraturan (Kep. Irjen)',
      icon: 'element-11',
      route: 'penetapan-pengesahan-peraturan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pendokumentasian peraturan',
      icon: 'element-11',
      route: 'pendokumentasian-peraturan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pelaksanaan pengkajian dan evaluasi pelaksanaan peraturan',
      icon: 'element-11',
      route: 'pelaksanaan-pengkajian-eval',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default PenyusunanPerarturan
