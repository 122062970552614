/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../../../helper/AppFunction'
import {PdfPageViewer} from '../../../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import ExcelLayout from './ExcelLayout'
import {CardUi} from '../../../../../../helper/AppUi'
import {TableAction, TableInput, TableRow} from '../../../../../../helper/TableInput'
import FormInput from '../../../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../../helper/TableInputDetail'
import {formatDate} from '../../../../../../helper/DateFns'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td style={{minWidth: '50px'}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </td>
                  <td style={{minWidth: '200px'}}>{NullProof({input: l, params: 'pka.no_pka'})}</td>
                  <td style={{minWidth: '200px'}}>{NullProof({input: l, params: 'pka.judul'})}</td>
                  <td style={{minWidth: '200px'}}>{NullProof({input: l, params: 'fungsi'})}</td>
                  <td style={{minWidth: '300px'}}>{NullProof({input: l, params: 'subfungsi'})}</td>
                  <td style={{minWidth: '300px'}}>{NullProof({input: l, params: 'sasaran'})}</td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data)}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
        ExcelLayout={ExcelLayout}
      />
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.detail?.page.breadcrumb.data)}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Kode'}}>
            {NullProof({input: data, params: 'kode'})}
          </TableInputDetailColumn>
        </TableInputDetail>
        <TableInputDetailColumn title={{value: 'Nomor KKA'}}>
          {NullProof({input: data, params: 'no_kka'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Ref PKA'}}>
          {NullProof({input: data, params: 'ref_pka'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Sasaran'}}>
          {NullProof({input: data, params: 'sasaran'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Periode'}}>
          {NullProof({input: data, params: 'periode'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Fungsi'}}>
          {NullProof({input: data, params: 'fungsi'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Sub Fungsi'}}>
          {NullProof({input: data, params: 'subfungsi'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Komponen'}}>
          {NullProof({input: data, params: 'komponen'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Bobot'}}>
          {NullProof({input: data, params: 'bobot'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn
          isResponsive='desktop'
          title={{value: 'Pengukuran Audit', props: {className: 'my-2'}}}
        >
          {NullProof({input: data, params: 'pengukuran_audit', isMap: true})?.map(
            (l: any, i: number) => (
              <div className='ms-4 my-2' key={i}>
                <TableInputDetailColumn title={{value: 'Uraian'}}>
                  {NullProof({input: l, params: 'uraian'})}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Bobot'}}>
                  {NullProof({input: l, params: 'bobot'})}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Nilai'}}>
                  {NullProof({input: l, params: 'nilai'})}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Hasil'}}>
                  {NullProof({input: l, params: 'hasil'})}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Penjelasan / Langkah Kerja'}}>
                  {NullProof({input: l, params: 'penjelasan_langkah_kerja'})}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Dokumen Sumber'}}>
                  {NullProof({input: l, params: 'dokumen_sumber'})}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Kondisi Positif'}}>
                  {NullProof({input: l, params: 'kondisi_positif'})}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Kondisi Negatif'}}>
                  {NullProof({input: l, params: 'kondisi_negatif'})}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Sebab'}}>
                  {NullProof({input: l, params: 'sebab'})}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'Akibat'}}>
                  {NullProof({input: l, params: 'akibat'})}
                </TableInputDetailColumn>
              </div>
            )
          )}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Jumlah Bobot'}}>
          {NullProof({input: data, params: 'jumlah_bobot'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Hasil'}}>
          {NullProof({input: data, params: 'hasil'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Tanggal Paraf'}}>
          {formatDate({
            date: NullProof({input: data, params: 'tanggal_paraf', isLabel: false}),
            dateFormat: 'dd MMMM yyyy',
          })}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Tanggal Reviu'}}>
          {formatDate({
            date: NullProof({input: data, params: 'tanggal_review', isLabel: false}),
            dateFormat: 'dd MMMM yyyy',
          })}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Tanggal Disetujui'}}>
          {formatDate({
            date: NullProof({input: data, params: 'tanggal_disetujui', isLabel: false}),
            dateFormat: 'dd MMMM yyyy',
          })}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Disusun Oleh'}}>
          {NullProof({input: data, params: 'disusun_oleh'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Direviu Oleh'}}>
          {NullProof({input: data, params: 'direviu_oleh'})}
        </TableInputDetailColumn>
        <TableInputDetailColumn title={{value: 'Disetujui Oleh'}}>
          {NullProof({input: data, params: 'disetujui_oleh'})}
        </TableInputDetailColumn>
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper, PdfWrapper}
