import {
    MainWrapper,
    PdfWrapper,
    DetailWrapper,
  } from '../../../../pages/evaluasi-pelaporan/verifikasi-perjalanan-dinas/export-bukt-perjalanan/MainWrapper'
  import {DataRoutesProps} from '../../../UserRolesValidation'
  
  const MainRoutes: DataRoutesProps[] = [
    {
      icon: '',
      title: '',
      route: '*',
      elements: <MainWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'pdf/:id',
      elements: <PdfWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'detail/:id',
      elements: <DetailWrapper />,
      subroute: [],
    },
  ]
  
  export {MainRoutes}
  