import * as Yup from 'yup'
import { FormInputProps } from '../../../../helper/FormInput'

const inputListPenomoran: FormInputProps[] = [
    {
        className: 'col-12',
        name: 'nama',
        type: 'input',
        value: '',
        validator: Yup.string().required('Text is required'),
        options: {
            input: {
                props: {
                    title: 'Text',
                    placeholder: '',
                },
            },
        },
    },
]

export { inputListPenomoran }
