import {FC} from 'react'
import {Document, Page, View, Text, Image} from '@react-pdf/renderer'
import {
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfStyle,
  PdfStylePallete,
} from '../../../../helper/ReactPdf'
import {formatDate} from '../../../../helper/DateFns'
import {ConvertCurrency, NullProof, currencyToWords} from '../../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  data.perusahaan = {
    ...data.perusahaan,
    nama: 'KEMENTERIAN AGAMA R.I.\nINSPEKTORAT JENDERAL',
    alamat: 'Jl. RS. Fatmawati No.33A Cipete PO. BOX 3867',
    telepon: '021-75916038, 7691849',
    fax: '021-7692112',
    website: 'www.itjen.kemenag.go.id',
  }

  const DataTujuan = () => {
    let result: any = [data.data.lokasi]
    for (const l of NullProof({input: data.data, params: 'surat_tugas.tujuan', isMap: true})) {
      const title: string = `${l.kota}`
      result.push(title)
    }
    return result.join(' - ')
  }

  const HeaderApp = (props: {data: any}) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          src={props.data?.perusahaan?.logo}
          style={{
            width: '80px',
            height: '90px',
            objectFit: 'contain',
            marginRight: '20px',
          }}
        />
        <View style={{width: '100%', flexDirection: 'row'}}>
          <View
            style={{
              width: '60%',
              textAlign: 'center',
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                fontSize: PdfStylePallete.typography.title.small,
              }}
            >
              {props.data?.perusahaan?.nama}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: PdfStylePallete.typography.label.medium,
              }}
            >
              {props.data?.perusahaan?.alamat}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: PdfStylePallete.typography.label.medium,
              }}
            >
              Telepon {props.data?.perusahaan?.telepon} Fax {props.data?.perusahaan?.fax}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: PdfStylePallete.typography.label.medium,
              }}
            >
              Jakarta 12420
            </Text>
          </View>
          <View
            style={{
              width: '40%',
              fontSize: '10px',
              paddingLeft: '10px',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginVertical: '2px',
              }}
            >
              <Text style={{fontWeight: 700}}>TANDA PENGELUARAN</Text>
              <Text style={{fontWeight: 700}}>3</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: '2px',
              }}
            >
              <View style={{flexDirection: 'row', width: '50%'}}>
                <Text style={{width: '90%'}}>Mata Anggaran</Text>
                <Text style={{}}>:</Text>
              </View>
              <Text style={{fontWeight: 700, borderBottom: '1px dashed black', width: '50%'}}>
                {NullProof({input: data.data, params: 'surat_tugas.mak'})}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: '2px',
              }}
            >
              <View style={{flexDirection: 'row', width: '50%'}}>
                <Text style={{width: '90%'}}>Dibayarkan tgl</Text>
                <Text style={{}}>:</Text>
              </View>
              <Text style={{fontWeight: 700, borderBottom: '1px dashed black', width: '50%'}}>
                {formatDate({
                  date:
                    NullProof({
                      input: data.data,
                      params: 'tanggal_pembayaran',
                      isLabel: false,
                    }) || '',
                  dateFormat: 'dd MMMM yyyy',
                }) || '(Tanggal)'}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: '2px',
              }}
            >
              <View style={{flexDirection: 'row', width: '50%'}}>
                <Text style={{width: '90%'}}>Pembukuan No</Text>
                <Text style={{}}>:</Text>
              </View>
              <Text style={{fontWeight: 700, borderBottom: '1px dashed black', width: '50%'}}>
                {NullProof({input: data.data, params: 'pembukuan_no'})}
              </Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  const CoreKwitansi: FC<{isEnd?: boolean}> = ({isEnd}) => {
    return (
      <View>
        <PdfHeaderApp data={data} customChild={<HeaderApp data={data} />} />
        <View
          style={{
            height: '2px',
            backgroundColor: '#000000',
          }}
        ></View>
        <View
          style={{
            marginHorizontal: 'auto',
            width: '95%',
            fontFamily: 'Arial',
            justifyContent: 'center',
            letterSpacing: '0.5px',
            fontSize: PdfStylePallete.typography.label.medium,
          }}
        >
          <Text
            style={{
              width: '100%',
              textAlign: 'center',
              marginVertical: '5px',
              fontSize: PdfStylePallete.typography.title.medium,
              fontWeight: 700,
              textTransform: 'uppercase',
              textDecoration: 'underline',
            }}
          >
            Kwitansi
          </Text>
          <View
            style={{
              marginVertical: '5px',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '22%',
                }}
              >
                Sudah diterima
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <Text
                style={{
                  width: '100%',
                  textTransform: 'uppercase',
                }}
              >
                {NullProof({input: data.data, params: 'diterima', isLabel: false}) ||
                  'Kuasa Pengguna Anggaran / Pembuat Komitmen'}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '22%',
                }}
              >
                Dari
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <Text
                style={{
                  width: '100%',
                  textTransform: 'uppercase',
                }}
              >
                {NullProof({input: data.data, params: 'spd.anggota.pegawai.instansi.nama'})}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '22%',
                }}
              >
                Uang{'\n'}sebesar
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{paddingRight: '20px'}}>
                  {ConvertCurrency(
                    parseInt(
                      NullProof({input: data.data, params: 'jumlah_biaya', isLabel: false})
                    ) || 0
                  )}
                </Text>
                <Text style={{width: '80%'}}>
                  {currencyToWords(
                    parseInt(
                      NullProof({input: data.data, params: 'jumlah_biaya', isLabel: false})
                    ) || 0
                  )}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '22%',
                }}
              >
                Untuk{'\n'}pembayaran
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    paddingRight: '2px',
                  }}
                >
                  <Text>{NullProof({input: data.data, params: 'untuk_pembayaran'})}</Text>
                  <View style={{border: '1px solid black'}}></View>
                  <Text>kekurangan biaya perjalanan dinas</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '22%',
                }}
              ></Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              ></Text>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Text>
                  KEMENTERIAN Agama RI tgl{' '}
                  {formatDate({
                    date:
                      NullProof({
                        input: data.data,
                        params: 'surat_tugas.tanggal',
                        isLabel: false,
                      }) || '',
                    dateFormat: 'dd MMMM yyyy',
                  }) || '(Tanggal)'}
                </Text>
                <Text>No. {NullProof({input: data.data, params: 'spd.nomor_surat'})}</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginVertical: '10px',
            }}
          >
            <Text>Untuk perjalanan dinas dari</Text>
            <Text>{DataTujuan()}</Text>
            <Text>
              Pada{' '}
              {formatDate({
                date:
                  NullProof({
                    input: data.data,
                    params: 'surat_tugas.tanggal_mulai',
                    isLabel: false,
                  }) || '',
                dateFormat: 'dd MMMM yyyy',
              }) || '(Tanggal)'}{' '}
              s/d{' '}
              {formatDate({
                date:
                  NullProof({
                    input: data.data,
                    params: 'surat_tugas.tanggal_selesai',
                    isLabel: false,
                  }) || '',
                dateFormat: 'dd MMMM yyyy',
              }) || '(Tanggal)'}
            </Text>
          </View>
          <View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '22%',
                }}
              >
                Jumlah
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <Text
                style={{
                  width: '100%',
                }}
              >
                {ConvertCurrency(
                  parseInt(
                    NullProof({input: data.data, params: 'jumlah_biaya', isLabel: false}) || 0
                  )
                )}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginTop: '30px',
              paddingBottom: '50px',
              flexDirection: 'row',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <View
              style={{
                width: '30%',
              }}
            >
              <Text>Bendahara Pengeluaran</Text>
              <View
                style={{
                  ...PdfStylePallete.signature,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>*</Text>
                {/* <ReactSrc
                  data={NullProof({
                    input: data.data,
                    params: 'bendahara.signature',
                    isLabel: false,
                  })}
                >
                  <Image
                    src={`${API_URL}/${NullProof({
                      input: data.data,
                      params: 'bendahara.signature',
                      isLabel: false,
                    })}`}
                    style={{...PdfStylePallete.signature}}
                  ></Image>
                </ReactSrc> */}
              </View>
              <View
                style={{
                  marginTop: '10px',
                }}
              >
                <Text>
                  {NullProof({input: data.data, params: 'bendahara.nama', label: 'Nama'})}
                  {`\n`}
                </Text>
                <Text>
                  NIP: {NullProof({input: data.data, params: 'bendahara.nama', label: 'NIP'})}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                textAlign: 'center',
                position: 'absolute',
                top: '30px',
              }}
            >
              <View
                style={{
                  margin: 'auto',
                  width: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text>
                  Mengetahui:{'\n'}A.n. Kuasa Pengguna Anggaran{'\n'}Pejabat Pembuat Komitmen
                </Text>
                <View
                  style={{
                    ...PdfStylePallete.signature,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>$</Text>
                  {/* <ReactSrc
                    data={NullProof({
                      input: data.data,
                      params: 'bendahara.signature',
                      isLabel: false,
                    })}
                  >
                    <Image
                      src={`${API_URL}/${NullProof({
                        input: data.data,
                        params: 'bendahara.signature',
                        isLabel: false,
                      })}`}
                      style={{...PdfStylePallete.signature}}
                    ></Image>
                  </ReactSrc> */}
                </View>
                <View
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <Text>
                    {NullProof({input: data.data, params: 'bendahara.nama', label: 'Nama'})}
                    {`\n`}
                  </Text>
                  <Text>
                    NIP: {NullProof({input: data.data, params: 'bendahara.nip', label: 'NIP'})}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '30%',
              }}
            >
              <View>
                <View style={{flexDirection: 'row', width: '100%'}}>
                  <Text>{data.data?.lokasi},</Text>
                  <Text
                    style={{
                      flex: 1,
                      marginLeft: '2px',
                      // borderBottom: '1px dashed black',
                      width: '100%',
                    }}
                  >
                    {formatDate({
                      date:
                        NullProof({
                          input: data.data,
                          params: 'surat_tugas.tanggal',
                          isLabel: false,
                        }) || '',
                      dateFormat: 'dd MMMM yyyy',
                    }) || '(Tanggal)'}
                  </Text>
                </View>
                <Text>Yang bertugas,</Text>
                <View
                  style={{
                    ...PdfStylePallete.signature,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>^</Text>
                  {/* <ReactSrc
                    data={NullProof({
                      input: data.data,
                      params: 'spd.anggota.pegawai.signature',
                      isLabel: false,
                    })}
                  >
                    <Image
                      src={`${API_URL}/${NullProof({
                        input: data.data,
                        params: 'spd.anggota.pegawai.signature',
                        isLabel: false,
                      })}`}
                      style={{...PdfStylePallete.signature}}
                    ></Image>
                  </ReactSrc> */}
                </View>
              </View>
              <View
                style={{
                  marginTop: '10px',
                }}
              >
                <Text>
                  {NullProof({input: data.data, params: 'spd.anggota.pegawai.nama', label: 'Nama'})}
                  {`\n`}
                </Text>
                <Text>
                  NIP:{' '}
                  {NullProof({input: data.data, params: 'spd.anggota.pegawai.nip', label: 'NIP'})}
                </Text>
              </View>
            </View>
          </View>
          <View style={{textAlign: 'center', marginTop: '5px'}}>
            <Text>
              {NullProof({
                input: data.data,
                params: 'surat_tugas.nama_kegiatan',
                label: 'Nama Kegiatan',
              })}
            </Text>
          </View>
        </View>
        {!isEnd && <View style={{borderBottom: '1px dashed black'}}></View>}
        <View style={{marginBottom: '100pt'}}></View>
      </View>
    )
  }

  return (
    <Document title='Dokumen'>
      <Page wrap={false} style={{...PdfStyle.page, fontFamily: 'Arial'}}>
        <CoreKwitansi />
        {/* <CoreKwitansi /> */}
      </Page>
    </Document>
  )
}

export default PdfLayout
