interface GeomapDataProps {
  name: string
  location: [number, number]
  geodata?: any
}

const GeomapData: GeomapDataProps[] = [
  {name: 'Aceh', location: [4.6951, 96.7494]},
  {name: 'Sumatera Utara', location: [3.5952, 98.6722]},
  {name: 'Sumatera Barat', location: [-0.9537, 100.351]},
  {name: 'Riau', location: [0.5333, 101.45]},
  {name: 'Kepulauan Riau', location: [3.9456, 108.1429]},
  {name: 'Jambi', location: [-1.6102, 103.6132]},
  {name: 'Bengkulu', location: [-3.7914, 102.2655]},
  {name: 'Sumatera Selatan', location: [-3.3194, 103.9144]},
  {name: 'Kepulauan Bangka Belitung', location: [-2.7411, 106.4406]},
  {name: 'Lampung', location: [-4.5586, 105.4068]},
  {name: 'Banten', location: [-6.4058, 106.064]},
  {name: 'DKI Jakarta', location: [-6.2088, 106.8456]},
  {name: 'Jawa Barat', location: [-6.9204, 107.6049]},
  {name: 'Jawa Tengah', location: [-7.15, 110.1403]},
  {name: 'DI Yogyakarta', location: [-7.7956, 110.3695]},
  {name: 'Jawa Timur', location: [-7.5361, 112.2384]},
  {name: 'Bali', location: [-8.4095, 115.1889]},
  {name: 'Nusa Tenggara Barat', location: [-8.6529, 117.3611]},
  {name: 'Nusa Tenggara Timur', location: [-8.6574, 121.0794]},
  {name: 'Kalimantan Barat', location: [-0.0225, 109.3304]},
  {name: 'Kalimantan Tengah', location: [-1.6815, 113.3824]},
  {name: 'Kalimantan Selatan', location: [-3.0926, 115.2838]},
  {name: 'Kalimantan Timur', location: [1.5524, 116.117]},
  {name: 'Kalimantan Utara', location: [3.3293, 117.5828]},
  {name: 'Sulawesi Utara', location: [1.5431, 124.8978]},
  {name: 'Gorontalo', location: [0.6999, 122.4467]},
  {name: 'Sulawesi Tengah', location: [-1.43, 121.4456]},
  {name: 'Sulawesi Barat', location: [-2.8416, 119.5687]},
  {name: 'Sulawesi Selatan', location: [-4.1383, 119.5797]},
  {name: 'Sulawesi Tenggara', location: [-4.1449, 122.1746]},
  {name: 'Maluku', location: [-3.2385, 130.1453]},
  {name: 'Maluku Utara', location: [1.5363, 127.9266]},
  {name: 'Papua Barat', location: [-1.3361, 133.1747]},
  {name: 'Papua', location: [-4.2699, 138.0804]},
]

export default GeomapData
