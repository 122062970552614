import {DataRoutesProps} from '../../UserRolesValidation'

const PengelolaanBarang: DataRoutesProps = {
  title: 'Pengelolaan Barang Milik Negara',
  icon: 'element-10',
  route: 'pengelolaan_barang',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Penyusunan Rencana Kebutuhan BMN',
      icon: 'element-10',
      route: 'penyusunan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penggunaan BMN',
      icon: 'element-10',
      route: 'penggunaan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penatausahaan BMN',
      icon: 'element-10',
      route: 'penatausahaan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Pemanfaatan BMN',
      icon: 'element-10',
      route: 'pemanfaatan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Pemindahtanganan BMN',
      icon: 'element-10',
      route: 'pemindahantanganan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Pemusnahan / Penghapusan BMN',
      icon: 'element-10',
      route: 'penghapusan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Pelaporan BMN',
      icon: 'element-10',
      route: 'pelaporan',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default PengelolaanBarang
