import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'kode',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode Program is required'),
    options: {
      input: {
        props: {
          title: 'Kode Program',
          placeholder: 'Kode Program',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kegiatan_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kegiatan is required'),
    options: {
      input: {
        props: {
          title: 'Kegiatan',
          placeholder: 'Kegiatan',
        },
      },
      datalist: {
        api: '/kepegawaian/data-kegiatan',
        params: 'nama',
        result: '$nama',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Program is required'),
    options: {
      input: {
        props: {
          title: 'Nama Program',
          placeholder: 'Nama Program',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'keterangan',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Keterangan is required'),
    options: {
      textarea: {
        props: {
          title: 'Keterangan',
          placeholder: 'Keterangan',
          rows: 3,
        },
      },
    },
  },
]

export {formInputList}
