/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useNavigate, useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {AvatarUi, CardUi, DropdownUi, ListdataUi, TabsUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../helper/AppFunction'
import FormInput, {
  BaseInputOnChangeProps,
  onBeforeSubmitProps,
  onLoadProps as onLoadPropsForm,
}  from '../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {ProtectComponentRoles} from '../../../../helper/UserRolesValidation'
import Modal from '../../../../helper/Modal'
import {
  TableBalas,
  TableDisposisi,
  TableHistoriNaskah,
  LampiranViewer,
  ButtonKirimNaskah,
} from './TableWrapper'
import Accordion from '../../../../helper/Accordion'
import {formatDate} from '../../../../helper/DateFns'
import ReactIcon from '../../../../helper/ReactIcon'
import { formInputList as formInputNaskahKeluar } from '../naskah-keluar/InputLayout'
import { ReactPdfViewer } from '../../../../helper/ReactPdf'
import {getData} from '../../../../../app/helper/FormAxios'
import {useAuth} from '../../../../../app/modules/auth'

const onChange = async (e: BaseInputOnChangeProps) => {
  const data = e._data.get
  const dataObj = e._data.getObject
  const result = {...data}
  // const {currentUser, logout, auth} = useAuth()

  if(e.type == 'onLoad') {
    let nomorAgenda
    const query = await getData('', '/tata_usaha/nomor_agenda', {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${auth?.data.api_token}`
      },
    })

    if(query?.status == true){
      nomorAgenda = query?.data
    }
    e.resetForm({values: {...result, nomor_agenda: nomorAgenda}})
  }
}

const MainWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Nomor Agenda' className='min-w-150px'>
                    {NullProof({input: l, params: 'no_agenda'})}
                  </TableColumn>
                  <TableColumn label='Tanggal Naskah' className='min-w-150px'>
                    {formatDate({
                      date: NullProof({input: l, params: 'createdAt'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableColumn label='Nomor Naskah' className='min-w-150px'>
                    {NullProof({input: l, params: 'nomor_naskah'})}
                  </TableColumn>
                  <TableColumn label='Hal' className='min-w-250px'>
                    {NullProof({input: l, params: 'hal'})}
                  </TableColumn>
                  <TableColumn label='Asal Naskah' className='min-w-250px'>
                    {NullProof({input: l, params: 'instansi_pengirim'})}
                  </TableColumn>
                  <TableColumn label='Tingkat Urgensi' className='min-w-250px'>
                    {NullProof({input: l.TingkatUrgensi, params: 'text'})}
                  </TableColumn>
                  <TableColumn label='Status Kirim' className='min-w-250px'>
                    {NullProof({input: l.TingkatUrgensi, params: 'status_kirim'}) ? "Sudah" : "Belum"}
                  </TableColumn>
                  <TableColumn label='Status Tindak Lanjut' className='min-w-250px'>
                    {NullProof({input: l, params: 'status_tindak_lanjut'}) ? 'Sudah' : 'Belum'}
                  </TableColumn>
                  <TableColumn label='Status Baca' className='min-w-250px'>
                    {NullProof({input: l, params: 'status_baca'}) ? 'Sudah' : 'Belum'}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

const DetailWrapper: FC = () => {
  const navigate = useNavigate()
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [modal_saya_balas, setModalSayaBalas] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <Modal
        id='modal_saya_balas'
        isShow={modal_saya_balas?.status}
        onClose={() => {
          setModalSayaBalas({status: false})
        }}
        title='Balas Naskah'
      >
        <FormInput
          input={formInputNaskahKeluar}
          route={{
            url: RouterQueryParams(`${InfoData.api_saya_balas}`, query),
            type: 'post',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModalSayaBalas({status: false})
            },
            data: {
              nomor_ref_id: modal_saya_balas?.data,
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_statusubah'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            isFeedback: true,
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, statusUbah: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        {data.pegawai_status ? (
          <CardUi
            props={{
              className: `text-white ${
                data.status_kirim !== false ? 'bg-success' : 'bg-secondary'
              } mb-5`,
            }}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <ReactIcon
                icon={`${data.status_kirim !== false ? 'RiCheckLine' : 'RiQuestionMark'}`}
                props={{style: {fontSize: '2.5rem', fontWeight: 'bold'}}}
              />
              <div className='d-flex flex-column text-end'>
                <div className='fs-4 fw-bold'>
                  Naskah {data.status_kirim !== false ? 'Telah' : 'Belum'} Dikirim
                </div>
                <div>
                  {data.status_kirim !== false
                    ? `Pada ${NullProof({input: data, params: 'tanggal_dikirim'})}`
                    : 'pastikan Meta Data dan Korespondensi naskah ini sudah sesuai'}
                </div>
              </div>
            </div>
          </CardUi>
        ) : null}

        <CardUi
          title={PageData.detail?.page.breadcrumb.title}
          toolbars={data.status_kirim == true ?
          <div className='fs-6'>
          <DropdownUi
              title='Tindak Lanjut'
              icon='RiCornerDownLeftLine'
              submenu={[
                {
                  id: 'saya_balas',
                  title: 'Saya Balas',
                  isFeedback: true,
                },
                {
                  id: 'dibalas',
                  title: 'Dibalaskan Oleh',
                  isFeedback: true,
                },
                {
                  id: 'disposisi',
                  title: 'Disposisi / Koordinasi / Saran',
                  isFeedback: true,
                },
              ]}
              onFeedback={(e: any) => {
                if (e?.props?.id === 'disposisi') {
                  navigate(RouterQueryParams(`./disposisi`, query))
                }else if(e?.props?.id === 'saya_balas'){
                  setModalSayaBalas({status: true, data: data.nomor_ref})
                }
                console.log(e)
              }}
            />
          </div> : <ButtonKirimNaskah data={data}
          onRefresh={() => {setKey((p) => p + 1)}}
          /> }
        >
          <TableInputDetail
            // isDummy
            onSuccess={(val: any) => setData(val)}
            {...TableInputDetailData}
          >
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Naskah Masuk Dari', props: {className: 'mb-2 fs-5'}}}
            >
              <div className='d-flex gap-4 align-items-center'>
                <div>
                  <AvatarUi
                    name={NullProof({input: data, params: 'nama_pengirim'})}
                    url={NullProof({input: data, params: 'nama_pengirim'})}
                  />
                </div>
                <div>
                  <div className='fw-bold fs-5'>
                    {NullProof({input: data, params: 'nama_pengirim'})}
                  </div>
                  <div>{NullProof({input: data, params: 'jabatan_pengirim'})}</div>
                </div>
              </div>
            </TableInputDetailColumn>
            <div className='row'>
              {/* <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Nomor Referensi'}}
                >
                  {NullProof({input: data, params: 'nomor_ref'})}
                </TableInputDetailColumn>
              </div> */}
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  isResponsive='desktop'
                  hideDivider
                  title={{value: 'Nomor Naskah'}}
                >
                  {NullProof({input: data, params: 'nomor_naskah'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  isResponsive='desktop'
                  hideDivider
                  title={{value: 'Tanggal Naskah'}}
                >
                  {NullProof({input: data, params: 'tanggal_naskah', type: 'date'})}
                </TableInputDetailColumn>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Hal'}}>
                  {NullProof({input: data, params: 'hal'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Isi'}}>
                  {NullProof({input: data, params: 'isi'})}
                </TableInputDetailColumn>
              </div>
            </div>
            <div className='my-4 d-flex flex-column gap-2'></div>
            <Accordion
              title='Detail Naskah'
              enabled
              props={{body: {className: 'p-4 border-bottom'}}}
            >
              <div className='row'>
                <div className='row col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Jenis Naskah'}}
                  >
                    {NullProof({input: data, params: 'JenisNaskah.text'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Sifat Naskah'}}
                  >
                    {NullProof({input: data, params: 'SifatNaskah.text'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Tingkat Urgensi'}}
                  >
                    {NullProof({input: data, params: 'TingkatUrgensi.text'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Klasifikasi'}}
                  >
                    {NullProof({input: data, params: 'klasifikasi'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Tipe Tandatangan'}}
                  >
                    KONVENSIONAL
                  </TableInputDetailColumn>
                </div>
                <div className='row col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Berkas'}}
                  >
                    {NullProof({input: data, params: 'status_berkas'}) ? 'Sudah' : 'Belum'}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Diterima Pada'}}
                  >
                    {NullProof({input: data, params: 'tanggal_diterima', type: 'date'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Diregistrasikan pada'}}
                  >
                    {NullProof({input: data, params: 'tanggal_naskah', type: 'date'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Diregistrasikan oleh'}}
                  >
                    {NullProof({input: data, params: 'pegawai.nama'})}
                  </TableInputDetailColumn>
                </div>
              </div>
            </Accordion>
            <Accordion title='Penerima' enabled props={{body: {className: 'p-4 border-bottom'}}}>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'mb-4'}}
                    hideDivider
                    title={{value: 'Utama:'}}
                  ></TableInputDetailColumn>
                  {NullProof({input: data, params: 'utama', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableInputDetailColumn
                          props={{className: 'row mb-2'}}
                          hideDivider
                          title={{value: NullProof({input: l, params: 'pegawai_id.nama'})}}
                        ></TableInputDetailColumn>
                      </Fragment>
                    )
                  )}
                </div>
                <div className='col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'mb-4'}}
                    hideDivider
                    title={{value: 'Tembusan:'}}
                  ></TableInputDetailColumn>
                  {NullProof({input: data, params: 'tembusan', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableInputDetailColumn
                          props={{className: 'row mb-2'}}
                          hideDivider
                          title={{value: NullProof({input: l, params: 'pegawai_id.nama'})}}
                        ></TableInputDetailColumn>
                      </Fragment>
                    )
                  )}
                </div>
              </div>
            </Accordion>
          </TableInputDetail>
        </CardUi>

        <CardUi props={{className: 'my-6'}}>
          <ReactPdfViewer
            url={`${
              NullProof({input: data, params: 'file', isLabel: false})
                ? `/${NullProof({input: data, params: 'file', isLabel: false})}`
                : ''
            }`}
          />
        </CardUi>
        {/* <PdfPageViewer
          props={{className: 'my-6'}}
          options={{hideBreadcrumb: true, hideBack: true}}
          route={RouterQueryParams(`${InfoData.api}/:id`, query)}
          PageData={{
            breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
            title: '',
          }}
          PdfLayout={PdfLayout}
          data={{}}
        /> */}
        <CardUi title='Lampiran' props={{className: 'my-6'}}>
          {NullProof({input: data, params: 'lampiran', isMap: true})?.length > 0 ? (
            <ListdataUi
              id='lampiran'
              title='Lampiran'
              data={NullProof({input: data, params: 'lampiran', isMap: true})}
            />
          ) : (
            'Tidak ada lampiran'
          )}
        </CardUi>
        <TabsUi
          tabs={[
            {
              id: 'disposisi',
              title: 'Disposisi',
              children: (
                <TableDisposisi
                  onFeedback={() => {
                    setKey((p) => (p += 1))
                  }}
                />
              ),
            },
            {
              id: 'balas',
              title: 'Balas',
              children: (
                <TableBalas
                  onFeedback={() => {
                    setKey((p) => (p += 1))
                  }}
                />
              ),
            },
          ]}
        />
        <CardUi title='History Naskah' props={{className: 'my-6'}}>
          <TableHistoriNaskah />
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper}
