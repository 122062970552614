import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfLayoutDataProps,
  PdfStyle,
  PdfTableColumnBuilder,
} from '../../../helper/ReactPdf'
import {ConvertCurrency, NullProof} from '../../../helper/AppFunction'
import {formatDate} from '../../../helper/DateFns'

const HeaderContent: FC = () => {
  return (
    <>
      <PdfTableColumnBuilder
        borderBottom
        style={{textTransform: 'uppercase', fontWeight: 'semibold'}}
      >
        <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            No.
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            NIP
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nama
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Perjadin
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Provinsi
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SPD
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Transfer
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Saldo
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tanggal
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Potong UM
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Potong Verifikasi
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Keterangan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Bagian
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SPM
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            MAK
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Cek
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Cek Billing
          </Text>
        </PdfTableColumnBuilder>
      </PdfTableColumnBuilder>
    </>
  )
}

const PdfLayoutSemua: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  console.log(data.data)

  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{width: '100%'}}>
          <PdfTableColumnBuilder direction='column' border>
            {/* Header */}
            <HeaderContent />
            {/* Looping Here */}
            {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {i + 1}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'pegawai.nip'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'pegawai.nama'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'perjadin'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'provinsi'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'spd'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(NullProof({input: l, params: 'jumlah'}) || 0)}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(NullProof({input: l, params: 'transfer'}) || 0)}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(NullProof({input: l, params: 'saldo'}) || 0)}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal'}),
                        dateFormat: 'dd/MM/yyyy',
                      })}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal_potong_um'}),
                        dateFormat: 'dd/MM/yyyy',
                      })}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal_potong_verif'}),
                        dateFormat: 'dd/MM/yyyy',
                      })}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'keterangan'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'bagian'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'spm'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'mak'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'cek'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'cek_billing'})}
                    </Text>
                  </PdfTableColumnBuilder>
                </PdfTableColumnBuilder>
              </Fragment>
            ))}
          </PdfTableColumnBuilder>
        </View>
      </Page>
    </Document>
  )
}

export {PdfLayoutSemua}
