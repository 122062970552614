import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'
import {getListYear} from '../../../../helper/DateFns'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Auditi',
          placeholder: 'Nama Auditi',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_kartu',
    type: 'input',
    validator: Yup.string().required('Nomor Kartu Penugasan mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Kartu Penugasan',
          placeholder: 'Nomor Kartu Penugasan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_surat_tugas_audit',
    type: 'input',
    validator: Yup.string().required('Nomor Surat Tugas Audit mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Surat Tugas Audit',
          placeholder: 'Nomor Surat Tugas Audit',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'upkat_id',
    type: 'datalist',
    validator: Yup.number().required('Usulan Program Kerja Audit Tahunan mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Usulan Program Kerja Audit Tahunan',
          placeholder: 'Usulan Program Kerja Audit Tahunan',
        },
      },
      datalist: {
        api: '/perencanaan_anggaran/rencana-audit-tahunan',
        params: 'tahun,tgl_mulai,tgl_selesai',
        filter: 'formatDate',
        result: (
          <>
            <div>
              <span className='fw-bold'>Tahun : </span>
              <span>$tahun</span>
            </div>
            <div>
              <span className='fw-bold'>Tanggal : </span>
              <span>formatDate=$tgl_mulai - formatDate=$tgl_selesai</span>
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_file',
    type: 'input',
    validator: Yup.string().required('No. File Permanen mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'No. File Permanen',
          placeholder: 'No. File Permanen',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'rencana_audit_no',
    type: 'input',
    validator: Yup.string().required('Rencana Audit Nomor mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Rencana Audit Nomor',
          placeholder: 'Rencana Audit Nomor',
        },
      },
    },
  },
  {
    className: 'col',
    name: 'audit_terakhir_tahun',
    type: 'select',
    listData: getListYear(),
    validator: Yup.number().required('Audit Terakhir Tahun mohon untuk diisi.'),
    options: {
      select: {
        props: {
          title: 'Audit Terakhir Tahun',
          placeholder: 'Audit Terakhir Tahun',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'alamat_no_telp',
    type: 'textarea',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      textarea: {
        props: {
          title: 'Alamat dan Nomor Telepon',
          placeholder: 'Alamat dan Nomor Telepon',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tujuan_audit',
    type: 'input',
    validator: Yup.string().required('Tujuan Audit mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tujuan Audit',
          placeholder: 'Tujuan Audit',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: '',
        labelType: 'line',
      },
    },
  },
  {
    className: 'col-12',
    name: 'ditetapkan_oleh_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Ditetapkan Oleh',
          placeholder: 'Ditetapkan Oleh',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'ditetapkan_di',
    type: 'input',
    validator: Yup.string().required('Ditetapkan Di mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Ditetapkan Di',
          placeholder: 'Ditetapkan Di',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'ditetapkan_tgl',
    type: 'input',
    validator: Yup.string().required('Ditetapkan Tanggal mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Ditetapkan Tanggal',
          placeholder: 'Ditetapkan Tanggal',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: '',
        labelType: 'line',
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Rencana Pelaksanaan',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tgl_mulai',
    type: 'input',
    validator: Yup.string().required('Tanggal Mulai mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Mulai',
          placeholder: 'Tanggal Mulai',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tgl_selesai',
    type: 'input',
    validator: Yup.string().required('Tanggal Selesai mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Selesai',
          placeholder: 'Tanggal Selesai',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: '',
        labelType: 'line',
      },
    },
  },
  {
    className: 'col-12',
    name: 'anggaran_dianjurkan',
    type: 'input',
    validator: Yup.number().required('Anggaran Yang Dianjurkan mohon untuk diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Anggaran Yang Dianjurkan',
          placeholder: 'Anggaran Yang Dianjurkan',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'anggaran_disetujui',
    type: 'input',
    validator: Yup.number().required('Anggaran Yang Disetujui mohon untuk diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Anggaran Yang Disetujui',
          placeholder: 'Anggaran Yang Disetujui',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan',
    type: 'textarea',
    validator: Yup.string().required(
      'Catatan Penting Dari Pengendali Teknis Pengendali Mutu mohon untuk diisi.'
    ),
    options: {
      textarea: {
        props: {
          title: 'Catatan Penting Dari Pengendali Teknis Pengendali Mutu',
          placeholder: 'Catatan Penting Dari Pengendali Teknis Pengendali Mutu',
          rows: 3,
        },
      },
    },
  },
]

export {formInputList}
