import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../../helper/AppFunction'
import {
  TableAction,
  TableInput,
  TableInputDataProps,
  TableRow,
} from '../../../../../helper/TableInput'
import Modal from '../../../../../helper/Modal'
import {ReactPdfViewer} from '../../../../../helper/ReactPdf'

const dummyDataDisposisi = [
  {
    tanggal: '2024-01-18T11:02:59Z',
    batas_waktu: '2024-01-18T11:02:59Z',
    koordinasi: 'koordinasinya',
    instruksi: 'instruksinya',
    status: 'Belum dibaca',
    dilakukan_oleh: {
      nama: 'Miftahul Huda',
      jabatan: 'Auditor Muda Inspektorat Wilayah III',
    },
    kepada: {
      nama: 'Miftahul Huda',
      jabatan: 'Auditor Muda Inspektorat Wilayah III',
    },
  },
]

const dummyDataBalas = [
  {
    tanggal: '2024-01-18T11:02:59Z',
    dilakukan_oleh: {
      nama: 'Miftahul Huda',
      jabatan: 'Auditor Muda Inspektorat Wilayah III',
    },
    kepada: {
      nama: 'Miftahul Huda',
      jabatan: 'Auditor Muda Inspektorat Wilayah III',
    },
  },
]

const dummyDataHistoryNaskah = [
  {
    tanggal: '2024-01-18T11:02:59Z',
    pengirim: 'Kastolan',
    pegawai: {
      nama: 'Kastolan - Plt. Sekretaris Inspektorat Jenderal',
      jabatan: 'Sekretariat Inspektorat Jenderal Kementerian Agama - Kementerian Agama',
      foto: 'files/avatar/avatar_12122023204007_nemeka.jpg',
    },
    jenis_naskah: 'Nota Dinas',
    hal: 'coba tanda tangan konvensional',
    penerima: {
      utama: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
      tebusan: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
    },
  },
]

const RouteList = {
  disposisi: '/tata_usaha/disposisi_naskah',
  balas: '/tata_usaha/pengelolaan_naskah_dinas/naskah_masuk',
  histori_naskah: '/tata_usaha/disposisi_naskah',
}

export const TableInputDataDisposisi: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Dilakukan Oleh',
    },
    {
      title: 'Kepada',
    },
    {
      title: 'Pesan Disposisi / Koordinasi',
    },
    {
      title: 'Instruksi / Pesan Tambahan',
    },
    {
      title: 'Lampiran',
    },
    {
      title: 'Tanggal Disposisi',
    },
    {
      title: 'Batas Waktu',
    },
    {
      title: 'Status',
    },
  ],
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: `${RouteList.disposisi}/:id/get_disposisi`,
    // query: 'byuser=true',
  },
}

export const TableInputDataBalas: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Dilakukan Oleh',
    },
    {
      title: 'Kepada',
    },
    {
      title: 'Tanggal Balas',
    },
  ],
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: RouteList.balas,
    // query: 'byuser=true',
  },
}

export const TableInputDataHistoryNaskah: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Tanggal',
    },
    {
      title: 'Pengirim',
    },
    {
      title: 'Asal',
    },
    {
      title: 'Tujuan',
    },
    {
      title: 'Jenis Naskah',
    },
    {
      title: 'Hal',
    },
    {
      title: 'File',
    },
    {
      title: 'Lampiran',
    },
  ],
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: `${RouteList.histori_naskah}/:id/histori_naskah`,
    // query: 'byuser=true',
  },
}

const TableDisposisi: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataDisposisi

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      // isDummy
      // staticData={dummyDataDisposisi}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pembuat_id.nama'})}</td>
              <td className='min-w-150px'>
                <div>
                  {NullProof({input: l, params: 'kepada_id', isMap: true})?.map(
                    (l1: any, i1: number) => (
                      <Fragment key={i1}>
                        <div className='d-flex gap-2'>
                          <div>{`${NullProof({
                            input: l1,
                            params: 'status_baca',
                          })} - ${NullProof({
                            input: l1,
                            params: 'nama',
                          })}`}</div>
                        </div>
                      </Fragment>
                    )
                  )}
                </div>
              </td>
              {/* <td className='min-w-250px'>{NullProof({input: l, params: 'koordinasi'})}</td> */}
              <td className='min-w-250px'>
                {' '}
                {l.pesan.map((value: string, index: number) => (
                  <div key={index}>{value}</div>
                ))}
              </td>
              <td className='min-w-350px'>{NullProof({input: l, params: 'intruksi'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'lampiran_fix'})}</td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'createdAt', type: 'date'})}
              </td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'batas_waktu', type: 'date'})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'status'})}</td>
              <TableAction {...tableDataBuilder} />
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const TableBalas: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataBalas

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      isDummy
      staticData={dummyDataDisposisi}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'dilakukan_oleh.nama'})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'kepada.nama'})}</td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'tanggal', type: 'date'})}
              </td>
              <TableAction {...tableDataBuilder} />
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const TableHistoriNaskah: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataHistoryNaskah

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      // isDummy
      // staticData={dummyDataHistoryNaskah}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder} key={i}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'createdAt', type: 'date'})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pengirim'})}</td>
              <td className='min-w-250px'>{NullProof({input: l, params: 'asal'})}</td>
              <td className='min-w-350px'>
                <div className='mb-2'>
                  <div className='fw-bold mb-2'>Utama</div>
                  <div>
                    {NullProof({input: l, params: 'tujuan', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div className='d-flex gap-2'>
                            <div className='min-w-100px'>
                              {NullProof({input: l1, params: 'status_baca'})}
                            </div>
                            <div>{`${NullProof({
                              input: l1,
                              params: 'pegawai_id.nama',
                            })} - ${NullProof({
                              input: l1,
                              params: 'kepada',
                            })}`}</div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='fw-bold mb-2'>Tembusan</div>
                  <div>
                    {NullProof({input: l, params: 'tembusan', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div className='d-flex gap-2'>
                            <div className='min-w-100px'>
                              {NullProof({input: l1, params: 'status_baca'})}
                            </div>
                            <div>{`${NullProof({
                              input: l1,
                              params: 'pegawai_id.nama',
                            })} - ${NullProof({
                              input: l1,
                              params: 'kepada',
                            })}`}</div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
              </td>
              <td className='min-w-150px'>
                {typeof NullProof({input: l, params: 'jenis_naskah'}) === 'object' ? (
                  <>{NullProof({input: l, params: 'jenis_naskah.text'})}</>
                ) : (
                  NullProof({input: l, params: 'jenis_naskah'})
                )}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'hal'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'file'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'lampiran_fix'})}</td>
              <TableAction {...tableDataBuilder} />
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const LampiranViewer: FC<{data: any}> = ({data}) => {
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  return (
    <>
      <Modal
        id='modal_lampiran'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Lampiran'
      >
        <ReactPdfViewer url={`/${data}`} />
      </Modal>
      <button
        type='button'
        className='btn btn-sm btn-primary'
        onClick={() => {
          setModal((p: any) => ({...p, statusUbah: true}))
        }}
      >
        Lihat Lampiran
      </button>
    </>
  )
}

export {TableDisposisi, TableBalas, TableHistoriNaskah, LampiranViewer}
