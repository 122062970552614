/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import {inputList} from './InputLayout'
import {PdfPageViewer} from '../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import FormInput from '../../../helper/FormInput'
import {CardUi} from '../../../helper/AppUi'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../helper/AppFunction'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../helper/TableInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import ExcelLayout from './ExcelLayout'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='No.'
                    className='min-w-100px'
                  >
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Tahun'
                    className='min-w-150px'
                  >
                    {NullProof({input: l, params: 'tahun'})}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Judul'
                    className='min-w-150px'
                  >
                    {NullProof({input: l, params: 'nama'})}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Sektor'
                    className='min-w-300px'
                  >
                    {NullProof({input: l, params: 'list_data', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div>
                            {i1 + 1}. {NullProof({input: l1, params: 'judul'})}
                          </div>
                        </Fragment>
                      )
                    )}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={inputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={inputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
        ExcelLayout={ExcelLayout}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tahun'}}>
            {NullProof({input: data, params: 'tahun'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nama'}}>
            {NullProof({input: data, params: 'nama'})}
          </TableInputDetailColumn>
          {NullProof({input: data, params: 'list_data', isMap: true})?.map((l: any, i: number) => (
            <Fragment key={i}>
              <TableInputDetailColumn isResponsive='desktop' hideDivider>
                <TableInputDetailColumn hideDivider title={{value: `${i + 1}. Sektor`}}>
                  {NullProof({input: l, params: 'judul'})}
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  isResponsive='desktop'
                  hideDivider
                  props={{
                    className: 'mt-1',
                  }}
                >
                  {NullProof({input: l, params: 'sektor', isMap: true})?.map(
                    (l1: any, i1: number) => (
                      <Fragment key={i1}>
                        <TableInputDetailColumn
                          useGuide
                          isResponsive='desktop'
                          hideDivider
                          props={{className: 'ms-2'}}
                        >
                          <TableInputDetailColumn hideDivider title={{value: `${i1 + 1}. Tema`}}>
                            {NullProof({input: l1, params: 'judul'})}
                          </TableInputDetailColumn>
                          <TableInputDetailColumn
                            useGuide
                            isResponsive='desktop'
                            hideDivider
                            props={{
                              className: 'mt-1',
                            }}
                          >
                            {NullProof({input: l1, params: 'topik', isMap: true})?.map(
                              (l2: any, i2: number) => (
                                <Fragment key={i2}>
                                  <TableInputDetailColumn
                                    isResponsive='desktop'
                                    hideDivider
                                    props={{className: 'ms-2'}}
                                  >
                                    <TableInputDetailColumn
                                      hideDivider
                                      title={{value: `${i2 + 1}. Topik`}}
                                    >
                                      {NullProof({input: l2, params: 'judul'})}
                                    </TableInputDetailColumn>
                                    <TableInputDetailColumn
                                      useGuide
                                      isResponsive='desktop'
                                      hideDivider
                                      props={{
                                        className: 'mt-1',
                                      }}
                                    >
                                      {NullProof({
                                        input: l2,
                                        params: 'pegawasan',
                                        isMap: true,
                                      })?.map((l3: any, i3: number) => (
                                        <Fragment key={i3}>
                                          <TableInputDetailColumn
                                            isResponsive='desktop'
                                            hideDivider
                                            props={{className: 'ms-2'}}
                                          >
                                            <TableInputDetailColumn
                                              hideDivider
                                              title={{
                                                value: `${i3 + 1}. Informasi Hasil Pengawasan`,
                                              }}
                                            >
                                              {NullProof({input: l3, params: 'judul'})}
                                            </TableInputDetailColumn>
                                            <TableInputDetailColumn
                                              isResponsive='desktop'
                                              hideDivider
                                              props={{
                                                className: 'mt-1',
                                              }}
                                            >
                                              {NullProof({
                                                input: l3,
                                                params: 'jenis_pengawasan',
                                                isMap: true,
                                              })?.map((l4: any, i4: number) => (
                                                <Fragment key={i4}>
                                                  <TableInputDetailColumn
                                                    isResponsive='desktop'
                                                    hideDivider
                                                    props={{className: 'ms-2'}}
                                                  >
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Uraian Jenis Pengawasan`,
                                                      }}
                                                    >
                                                      {NullProof({input: l4, params: 'judul'})}
                                                    </TableInputDetailColumn>
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Ir 1.`,
                                                      }}
                                                    >
                                                      {NullProof({input: l4, params: 'ir_1'})}
                                                    </TableInputDetailColumn>
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Ir 2.`,
                                                      }}
                                                    >
                                                      {NullProof({input: l4, params: 'ir_2'})}
                                                    </TableInputDetailColumn>
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Ir 3.`,
                                                      }}
                                                    >
                                                      {NullProof({input: l4, params: 'ir_3'})}
                                                    </TableInputDetailColumn>
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Ir 4.`,
                                                      }}
                                                    >
                                                      {NullProof({input: l4, params: 'ir_4'})}
                                                    </TableInputDetailColumn>
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Irvest`,
                                                      }}
                                                    >
                                                      {NullProof({input: l4, params: 'irvest'})}
                                                    </TableInputDetailColumn>
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Sek`,
                                                      }}
                                                    >
                                                      {NullProof({input: l4, params: 'sek'})}
                                                    </TableInputDetailColumn>
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Triwulan 1`,
                                                      }}
                                                    >
                                                      {NullProof({
                                                        input: l4,
                                                        params: 'triwulan_1',
                                                      })
                                                        ? 'Ya'
                                                        : 'Tidak'}
                                                    </TableInputDetailColumn>
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Triwulan 2`,
                                                      }}
                                                    >
                                                      {NullProof({
                                                        input: l4,
                                                        params: 'triwulan_2',
                                                      })
                                                        ? 'Ya'
                                                        : 'Tidak'}
                                                    </TableInputDetailColumn>
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Triwulan 3`,
                                                      }}
                                                    >
                                                      {NullProof({
                                                        input: l4,
                                                        params: 'triwulan_3',
                                                      })
                                                        ? 'Ya'
                                                        : 'Tidak'}
                                                    </TableInputDetailColumn>
                                                    <TableInputDetailColumn
                                                      title={{
                                                        value: `Triwulan 4`,
                                                      }}
                                                    >
                                                      {NullProof({
                                                        input: l4,
                                                        params: 'triwulan_4',
                                                      })
                                                        ? 'Ya'
                                                        : 'Tidak'}
                                                    </TableInputDetailColumn>
                                                  </TableInputDetailColumn>
                                                </Fragment>
                                              ))}
                                            </TableInputDetailColumn>
                                          </TableInputDetailColumn>
                                        </Fragment>
                                      ))}
                                    </TableInputDetailColumn>
                                  </TableInputDetailColumn>
                                </Fragment>
                              )
                            )}
                          </TableInputDetailColumn>
                        </TableInputDetailColumn>
                      </Fragment>
                    )
                  )}
                </TableInputDetailColumn>
              </TableInputDetailColumn>
            </Fragment>
          ))}
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper}
