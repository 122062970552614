import {DataRoutesProps} from "../../../UserRolesValidation";
import PelaporanPengawasanHaji from "./pelaporan-pengawasan-haji/PelaporanPengawasanHaji";
import EvaluasiPaktaIntegritas from "./evaluasi-pakta-integritas/EvaluasiPaktaIntegritas";
import { PelaporanST } from "./PelaporanST";

const PelaporanPengawasanInternal: DataRoutesProps = {
    title: 'Pelaporan Pengawasan Internal',
    icon: 'element-10',
    route: 'pelaporan_pengawasan_internal',
    elements: <></>,
    subroute: [
        {
            isPage: true,
            title: 'Pelaporan ST',
            icon: 'element-10',
            route: 'pelaporan_st',
            elements: <></>,
            subroute: PelaporanST
        },
        {...PelaporanPengawasanHaji},
        {...EvaluasiPaktaIntegritas}
    ]
}

export default PelaporanPengawasanInternal