/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2'
import {useDispatch, useSelector} from 'react-redux'
import {post} from '../../../redux/authSlice'
import {post as postNotif} from '../../../redux/notifSlice'
import ReactIcon from '../../../helper/ReactIcon'
import {NullProof, generateCaptcha} from '../../../helper/AppFunction'
import {formatDate} from '../../../helper/DateFns'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    .min(3, 'Minimal 3 Huruf / Angka')
    .max(50, 'Maksimal 50 Huruf / Angka')
    .required('Email / NIP untuk diisi.'),
  password: Yup.string()
    .min(3, 'Minimum 3 Huruf / Angka')
    .max(50, 'Maximum 50 symbols')
    .required('Password untuk diisi.'),
  captcha: Yup.number().required('Captcha untuk diisi.'),
})
const initialValues = {
  email: '',
  password: '',
  captcha: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [captchaData, setCaptchaData] = useState<any>(generateCaptcha())
  const {saveAuth, setCurrentUser, currentUser} = useAuth()
  const notifData = useSelector((state: any) => state.notif.value)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      let json:any
      try {
        if (parseInt(values.captcha) === parseInt(captchaData.sum)) {
          const {data: auth} = await login(values.email, values.password)
          json = {...auth}
          if (json?.status) {
            delete json?.data?.password
            saveAuth(auth)
            const {data: user} = await getUserByToken(auth.data.api_token)
            setCurrentUser(json.data)
            try {
              const tglLahir = NullProof({input: json, params: 'data.pegawai.tanggal_lahir'})
              const tglSkrg = new Date().toUTCString()
              if (
                formatDate({date: tglLahir, dateFormat: 'dd MMMM'}) ===
                formatDate({date: tglSkrg, dateFormat: 'dd MMMM'})
              ) {
                dispatch(postNotif({...notifData, ultah: true}))
              }
            } catch (e) {}
            dispatch(post(json))
          } else {
            throw json?.message || 'Login not valid!'
          }
        } else {
          throw 'Captcha not valid!'
        }
      } catch (error) {
        console.log(error)
        // console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
        showAlert(parseInt(values.captcha) !== parseInt(captchaData.sum), json?.message)
      }
      setCaptchaData(generateCaptcha())
    },
  })

  const showAlert = (captchaErr: boolean = false, message?: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Gagal Login',
      text: captchaErr
        ? 'Captcha tidak sama. Silahkan coba lagi.'
        : message || 'Email atau password salah. Silahkan coba lagi.',
    })
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Selamat Datang</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Sistem Informasi Pengawasan Internal <br></br> Kementerian Agama RI
        </div>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email / NIP</label>
        <input
          placeholder='Email / NIP'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email ? (
          <div className='fv-plugins-message-container px-2 my-2'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        ) : (
          <div className='px-2 my-2'>Email / NIP</div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <div className='input-group mb-5'>
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {/* <button
            type='button'
            className='input-group-text btn btn-transparent px-4'
            onClick={() => {
              setShowPassword(!showPassword)
            }}
          >
            <ReactIcon
              icon={showPassword ? 'RiEyeLine' : 'RiEyeCloseLine'}
              props={{className: 'fs-2'}}
            />
          </button> */}
        </div>
        <div className='form-check d-flex align-items-center gap-2 mb-5'>
          <input
            id={`check-password`}
            type='checkbox'
            className='form-check-input form-check-input-lg cursor-pointer h-25px w-25px'
            checked={showPassword || false}
            onChange={(val) => {
              setShowPassword(val.target.checked)
            }}
          />
          <label
            className='form-check-label text-dark cursor-pointer user-select-none'
            htmlFor={`check-password`}
          >
            Lihat Password
          </label>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Captcha</label>
        <div className='input-group'>
          <span className='input-group-text btn btn-transparent px-4'>{`${captchaData.num1} + ${captchaData.num2} =`}</span>
          <input
            autoComplete='off'
            {...formik.getFieldProps('captcha')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.captcha && formik.errors.captcha,
              },
              {
                'is-valid': formik.touched.captcha && !formik.errors.captcha,
              }
            )}
          />
        </div>
        {formik.touched.captcha && formik.errors.captcha && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.captcha}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex justify-content-end mb-4'>
        <Link to={'/auth/forgot-password'}>
          <button type='button' className='btn btn-link'>
            Lupa Password?
          </button>
        </Link>
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Masuk</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
