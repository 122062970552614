import {DataRoutesProps} from '../../UserRolesValidation'
import NaskahKeluarRoute from './NaskahKeluar'
import NaskahMasukRoute from './NaskahMasuk'
import NaskahBerkasRoute from './berkas/Berkas'
import TandaTanganNaskahRoute from './TandaTanganNaskah'
import VerifikasiNaskahRoute from './VerifikasiNaskah'
import DaftarDisposisiRoute from './DaftarDisposisi'
import LogNaskahRoute from './log-naskah/LogNaskah'
import NaskahPengaturanRoute from './pengaturan/Pengaturan'
import {TemplateNaskahRoute} from './template-naskah/templateNaskah'
import AgendaNaskahRoute from './AgendaNaskah'
import KlasifikasiBerkasRoute from './KlasifikasiBerkas'
import TembusanRoute from './Tembusan'

const MainRoutes: DataRoutesProps = {
  title: 'Tata Usaha',
  icon: 'element-10',
  route: 'tata_usaha',
  elements: <></>,
  subroute: [
    {
      title: 'Pengelolaan Naskah Dinas',
      icon: 'element-10',
      route: 'pengelolaan_naskah_dinas',
      elements: <></>,
      subroute: [
        {
          isPage: true,
          title: 'Tanda Tangan Naskah',
          icon: 'element-10',
          route: 'tanda_tangan_naskah',
          elements: <></>,
          subroute: TandaTanganNaskahRoute,
        },
        {
          isPage: true,
          title: 'Template Naskah',
          icon: 'element-10',
          route: 'template_naskah',
          elements: <></>,
          subroute: TemplateNaskahRoute,
        },
        {
          isPage: true,
          title: 'Verifikasi Naskah',
          icon: 'element-10',
          route: 'verifikasi_naskah',
          elements: <></>,
          subroute: VerifikasiNaskahRoute,
        },
        {
          isPage: true,
          title: 'Naskah Masuk',
          icon: 'element-10',
          route: 'naskah_masuk',
          elements: <></>,
          subroute: NaskahMasukRoute,
        },
        {
          isPage: true,
          title: 'Naskah Keluar',
          icon: 'element-10',
          route: 'naskah_keluar',
          elements: <></>,
          subroute: NaskahKeluarRoute,
        },
        // {
        //   isPage: true,
        //   title: 'Tembusan',
        //   icon: 'element-10',
        //   route: 'tembusan',
        //   elements: <></>,
        //   subroute: TembusanRoute,
        // },
        {
          isPage: true,
          title: 'Daftar Disposisi',
          icon: 'element-10',
          route: 'daftar_disposisi',
          elements: <></>,
          subroute: DaftarDisposisiRoute,
        },
        {...LogNaskahRoute},
        // {
        //   isPage: true,
        //   title: 'Log Disposisi',
        //   icon: 'element-10',
        //   route: 'log_disposisi',
        //   elements: <></>,
        //   subroute: [],
        // },
        {
          isPage: true,
          title: 'Naskah Tembusan',
          icon: 'element-10',
          route: 'naskah_tembusan',
          elements: <></>,
          subroute: TembusanRoute,
        },
        {...NaskahBerkasRoute},
        {
          isPage: true,
          title: 'Klasifikasi Berkas',
          icon: 'element-10',
          route: 'klasifikasi_berkas',
          subroute: KlasifikasiBerkasRoute,
        },
        {
          isPage: true,
          title: 'Agenda Naskah',
          icon: 'element-10',
          route: 'agenda_naskah',
          elements: <></>,
          subroute: AgendaNaskahRoute,
        },
        {...NaskahPengaturanRoute},
      ],
    },
    {
      title: 'Pengelolaan Arsip',
      icon: 'element-10',
      route: 'pengelolaan_arsip',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pelayanan Perpustakaan',
      icon: 'element-10',
      route: 'pelayanan_perpus',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pengadaan Barang & Jasa dan Pemeliharaan BMN',
      icon: 'element-10',
      route: 'pengadaan_barang_jasa_pemeliharaan_bmn',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penyusunan Data dan Informasi Pengawasan',
      icon: 'element-10',
      route: 'penyusunan_data_informasi_pengawasan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pelaksanaan Protokoler, Pengamanan dan Kesehatan',
      icon: 'element-10',
      route: 'pelaksanaan_protokoler_pengamanan_dan_kesehatan',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default MainRoutes
