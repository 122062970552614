import {DataRoutesProps} from '../../UserRolesValidation'
import {
  MainWrapper,
  DetailWrapper,
  TambahWrapper,
  UbahWrapper,
} from '../../../pages/tata-usaha/pengelolaan-naskah-dinas/agenda-naskah/MainWrapper'

const AgendaNaskahRoute: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
//   {
//     icon: '',
//     title: '',
//     route: 'pdf/:id',
//     elements: <PdfWrapper />,
//     subroute: [],
//   },
]

export default AgendaNaskahRoute
