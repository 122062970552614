/* eslint-disable array-callback-return */
import {FC} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {PdfLayoutDataProps, PdfStyle, PdfStylePallete} from '../../../../../../helper/ReactPdf'
import {NullProof} from '../../../../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  const colors = {yellow: '#ffff00', purple: '#604a7b'}
  const styles: {table: {header: any; content: any}} = {
    table: {
      header: {
        ...PdfStylePallete.table.col,
        borderStyle: 'solid',
        height: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        color: colors.yellow,
        backgroundColor: colors.purple,
        paddingVertical: '3px',
      },
      content: {
        ...PdfStylePallete.table.col,
        borderStyle: 'solid',
        height: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        paddingVertical: '3px',
      },
    },
  }

  const Label = ({title, params}: {title: string; params: string}) => {
    return (
      <View style={{flexDirection: 'row', marginBottom: '3px'}}>
        <Text style={{width: '25%'}}>{title}</Text>
        <Text style={{width: '75%'}}>: {NullProof({input: data.data, params})}</Text>
      </View>
    )
  }

  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28, 1200]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{paddingVertical: '15px'}}>
          <Text style={{fontWeight: 'bold'}}>Inspektorat Jenderal Kementerian Agama RI</Text>
        </View>
        <View style={{paddingVertical: '15px'}}>
          <Text
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              color: colors.yellow,
              backgroundColor: colors.purple,
              fontSize: PdfStylePallete.typography.title.small,
            }}
          >
            KERTAS KERJA AUDIT
          </Text>
        </View>
        <View
          style={{
            paddingVertical: '15px',
            flexDirection: 'row',
            fontSize: PdfStylePallete.typography.title.small,
          }}
        >
          <View style={{width: '50%'}}>
            <Label title='Nama Auditi' params='nama' />
            <Label title='Sasaran Auditi' params='sasaran' />
            <Label title='Periode Auditi' params='periode' />
            <Label title='Fungsi' params='fungsi' />
            <Label title='Sub Fungsi' params='subfungsi' />
            <Label title='Komponen/Sub' params='komponen' />
            <Label title='Bobot' params='bobot' />
          </View>
          <View style={{width: '50%'}}>
            <Label title='No. KKA' params='no_kka' />
            <Label title='Ref. PKA No' params='ref_pka' />
            <Label title='Disusun Oleh' params='disusun_oleh' />
            <Label title='Tanggal/Paraf' params='tanggal_paraf' />
            <Label title='Direviu Oleh' params='direviu_oleh' />
            <Label title='Tanggal/Paraf' params='tanggal_review' />
            <Label title='Disetujui Oleh' params='disetujui_oleh' />
            <Label title='Tanggal/Paraf' params='tanggal_disetujui' />
          </View>
        </View>
        <View style={{paddingVertical: '15px'}}>
          <Text style={{fontWeight: 'bold', textAlign: 'center'}}>
            PENGUKURAN KINERJA TUGAS DAN FUNGSI
          </Text>
        </View>
        <View
          style={{
            ...PdfStylePallete.table.style,
            borderStyle: 'solid',
            fontSize: PdfStylePallete.typography.label.mini,
          }}
        >
          <View style={{flexDirection: 'row', fontWeight: 'bold'}}>
            <View style={{...styles.table.header, width: '3%'}}>
              <Text>No</Text>
            </View>
            <View style={{...styles.table.header, width: '22%'}}>
              <Text style={{paddingVertical: '5px'}}>Aspek Penilaian</Text>
              <View style={{flexDirection: 'row'}}>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '80%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                  }}
                >
                  <Text>Uraian</Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '20%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                    borderRight: 0,
                  }}
                >
                  <Text>Bobot</Text>
                  <Text>(a)</Text>
                </View>
              </View>
            </View>
            <View style={{...styles.table.header, width: '10%'}}>
              <Text style={{paddingVertical: '5px'}}>Capaian Kinerja</Text>
              <View style={{flexDirection: 'row'}}>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '50%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                  }}
                >
                  <Text>Nilai</Text>
                  <Text>(b)</Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '50%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                    borderRight: 0,
                  }}
                >
                  <Text>Hasil</Text>
                  <Text>(a x b)</Text>
                </View>
              </View>
            </View>
            <View style={{...styles.table.header, width: '15%'}}>
              <Text>Penjelasan / Langkah Kerja</Text>
            </View>
            <View style={{...styles.table.header, width: '10%'}}>
              <Text>Dokumen Sumber</Text>
            </View>
            <View style={{...styles.table.header, width: '10%'}}>
              <Text>Kondisi Positif</Text>
            </View>
            <View style={{...styles.table.header, width: '10%'}}>
              <Text>Kondisi (Negatif)</Text>
              <Text>(Untuk selain kode CH)</Text>
            </View>
            <View style={{...styles.table.header, width: '10%'}}>
              <Text>Akibat</Text>
              <Text>(Untuk selain kode CH)</Text>
            </View>
            <View style={{...styles.table.header, width: '10%'}}>
              <Text>Sebab</Text>
              <Text>(Untuk selain kode CH)</Text>
            </View>
          </View>
          {NullProof({input: data.data, params: 'pengukuran_audit', isMap: true})?.map(
            (pengukuran: any, index: number) => (
              <View key={index} style={{flexDirection: 'row'}}>
                <View style={{...styles.table.content, width: '3%'}}>
                  <Text>{index + 1}.</Text>
                </View>
                <View style={{...styles.table.content, width: '17.3%'}}>
                  <Text>{NullProof({input: pengukuran, params: 'uraian'})}</Text>
                </View>
                <View style={{...styles.table.content, width: '4.7%'}}>
                  <Text>{NullProof({input: pengukuran, params: 'bobot'})}</Text>
                </View>
                <View style={{...styles.table.content, width: '5%'}}>
                  <Text>{NullProof({input: pengukuran, params: 'nilai'})}</Text>
                </View>
                <View style={{...styles.table.content, width: '5%'}}>
                  <Text>{NullProof({input: pengukuran, params: 'hasil'})}</Text>
                </View>
                <View style={{...styles.table.content, width: '15%'}}>
                  <Text>{NullProof({input: pengukuran, params: 'penjelasan_langkah_kerja'})}</Text>
                </View>
                <View style={{...styles.table.content, width: '10%'}}>
                  <Text>{NullProof({input: pengukuran, params: 'dokumen_sumber'})}</Text>
                </View>
                <View style={{...styles.table.content, width: '10%'}}>
                  <Text>{NullProof({input: pengukuran, params: 'kondisi_positif'})}</Text>
                </View>
                <View style={{...styles.table.content, width: '10%'}}>
                  <Text>{NullProof({input: pengukuran, params: 'kondisi_negatif'})}</Text>
                </View>
                <View style={{...styles.table.content, width: '10%'}}>
                  <Text>{NullProof({input: pengukuran, params: 'akibat'})}</Text>
                </View>
                <View style={{...styles.table.content, width: '10%'}}>
                  <Text>{NullProof({input: pengukuran, params: 'sebab'})}</Text>
                </View>
              </View>
            )
          )}
          <View style={{flexDirection: 'row'}}>
            <View style={{...styles.table.content, width: '3%'}}></View>
            <View style={{...styles.table.content, width: '17.3%'}}>
              <Text style={{fontWeight: 'bold'}}>JUMLAH</Text>
            </View>
            <View style={{...styles.table.content, width: '4.7%'}}>
              <Text>{NullProof({input: data.data, params: 'jumlah_bobot'})}</Text>
            </View>
            <View style={{...styles.table.content, width: '5%'}}></View>
            <View style={{...styles.table.content, width: '5%'}}>
              <Text>{NullProof({input: data.data, params: 'hasil'})}</Text>
            </View>
            <View style={{...styles.table.content, width: '15%'}}></View>
            <View style={{...styles.table.content, width: '10%'}}></View>
            <View style={{...styles.table.content, width: '10%'}}></View>
            <View style={{...styles.table.content, width: '10%'}}></View>
            <View style={{...styles.table.content, width: '10%'}}></View>
            <View style={{...styles.table.content, width: '10%'}}></View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default PdfLayout
