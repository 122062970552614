import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'peta_audit_apip_id',
    type: 'datalist',
    validator: Yup.number().required('Peta Audit mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Peta Audit',
          placeholder: 'Peta Audit',
        },
      },
      datalist: {
        api: '/perencanaan_anggaran/peta-apip',
        params: 'nama,tahun',
        result: (
          <>
            <div>
              <b>Nama: </b>$nama
            </div>
            <div>
              <b>Tahun: </b>$tahun
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Nilai Skor',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'skor_pengawasan',
    type: 'input',
    validator: Yup.number().required('Skor Pengawasan mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Skor Pengawasan',
          placeholder: 'Skor Pengawasan',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'sdm_auditi',
    type: 'input',
    validator: Yup.number().required('SDM Auditi mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'SDM Auditi',
          placeholder: 'SDM Auditi',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'perubahan_personil',
    type: 'input',
    validator: Yup.number().required('Perubahan Personil Kunci mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Perubahan Personil Kunci',
          placeholder: 'Perubahan Personil Kunci',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'jenis_tipologi',
    type: 'input',
    validator: Yup.number().required('Jenis Tipologi Satker mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jenis Tipologi Satker',
          placeholder: 'Jenis Tipologi Satker',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'anggaran',
    type: 'input',
    validator: Yup.number().required('Anggaran mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Anggaran',
          placeholder: 'Anggaran',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'temuan_tindak_lanjut',
    type: 'input',
    validator: Yup.number().required('Temuan Tindak Lanjut mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Temuan Tindak Lanjut',
          placeholder: 'Temuan Tindak Lanjut',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'tahun_terakhir_audit',
    type: 'input',
    validator: Yup.number().required('Tahun Terakhir Audit mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tahun Terakhir Audit',
          placeholder: 'Tahun Terakhir Audit',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'jarak_audit',
    type: 'input',
    validator: Yup.number().required('Jarak Audit mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jarak Audit',
          placeholder: 'Jarak Audit',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'sistem_informasi',
    type: 'input',
    validator: Yup.number().required('Sistem Informasi mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Sistem Informasi',
          placeholder: 'Sistem Informasi',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8 d-flex flex-column justify-content-center text-end',
    name: '',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Total Skor',
        labelType: 'label',
        className: 'fs-6',
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'total',
    type: 'input',
    validator: Yup.number().required('Total Skor mohon untuk diisi.'),
    disabled: true,
    options: {
      input: {
        props: {
          placeholder: 'Total Skor',
          type: 'number',
        },
      },
    },
  },
]

export {formInputList}
