import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface PageState {
  data: any
}

const initialState: PageState = {
  data: {},
}

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    post: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    clear: (state) => {
      state.data = {}
    },
  },
})

export const {post, clear} = pageSlice.actions
export default pageSlice.reducer
