import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const inputList: FormInputProps[] = [
  {
    className: 'col-3',
    name: 'kode',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode is required'),
    options: {
      input: {
        props: {
          title: 'Kode',
          placeholder: '',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'input',
    value: '',
    validator: Yup.string().required('Text is required'),
    options: {
      input: {
        props: {
          title: 'Text',
          placeholder: '',
        },
      },
    },
  },
]

export {inputList}
