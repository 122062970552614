import React, {FC} from 'react'
import {IconBaseProps, IconContext, IconType} from 'react-icons'
import * as Icons from 'react-icons/ri'

export const ReactIconList = Object.keys(Icons)

const ReactIcon: FC<{icon?: keyof typeof Icons | string; props?: IconBaseProps}> = ({
  icon = 'RiArrowRightSLine',
  props,
}) => {
  let result = <></>
  try {
    //@ts-ignore
    result = React.createElement(Icons[icon], {...props})
  } catch (_) {
    result = React.createElement(Icons['RiLayout2Line'], {...props})
  }
  return result
}

export default ReactIcon
