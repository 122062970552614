import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'
import {createFormObjectList, listVarProps} from '../../../../../../helper/AppFunction'

export const listVar: listVarProps[] = [
  {
    number: '1',
    name: 'ppl',
    title: 'Konsep LHA',
  },
  {
    number: '2',
    name: 'ppl',
    title: 'Koreksi Konsep LHA',
  },
  {
    number: '3',
    name: 'ppl',
    title: 'Paraf hasil koreksian LHA',
  },
  {
    number: '4',
    name: 'ppl',
    title: 'Koreksi konsep LHA',
  },
  {
    number: '5',
    name: 'ppl',
    title: 'Penandatanganan LHA',
  },
  {
    number: '6',
    name: 'ppl',
    title: 'Penomoran LHA',
  },
  {
    number: '7',
    name: 'ppl',
    title: 'Penggandaan, Pendokumentasian dan Pendistribusian LHA',
  },
]

const formInputKomponenList: FormInputProps[] = [
  {
    className: 'col-12 col-md-4',
    name: 'tanggal',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        dateOption: 'datetime',
        props: {
          title: 'Tanggal',
          placeholder: 'Tanggal',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Auditor',
          placeholder: 'Nama Auditor',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
]

const inputList: FormInputProps[] = [
  {
    className: 'col-12 col-md-8',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'byuser=true',
        params: 'nomor_surat,nama_kegiatan',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nomor Surat : </span>
              <span>$nomor_surat</span>
            </div>
            <div>
              <span className='fw-bold'>Nama Kegiatan : </span>
              <span>$nama_kegiatan</span>
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'tanggal_kartu',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Kartu',
          type: 'date',
          placeholder: 'Tanggal Kartu',
        },
      },
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'no_pkat',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'No PKAT',
  //         placeholder: 'No PKAT',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'rmp',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'RMP',
  //         placeholder: 'RMP',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'rml',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'RML',
  //         placeholder: 'RML',
  //       },
  //     },
  //   },
  // },
  {
    className: 'col-12',
    name: 'komponen',
    type: 'component',
    value: 1,
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      component: {
        isObject: true,
      },
    },
    component: createFormObjectList(listVar, formInputKomponenList),
  },
]

export {inputList}
