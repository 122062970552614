/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../helper/AppFunction'
import FormInput from '../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Tujuan' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'tujuan', option: {text: {limit: 300}}})}
                  </TableColumn>
                  <TableColumn label='Sasaran' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'sasaran', option: {text: {limit: 300}}})}
                  </TableColumn>
                  <TableColumn label='Strategi' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'strategi', option: {text: {limit: 300}}})}
                  </TableColumn>
                  <TableColumn label='Penanggung Jawab' props={{style: {minWidth: '300px'}}}>
                    {NullProof({
                      input: l,
                      params: 'penanggung_jawab',
                      option: {text: {limit: 300}},
                    })}
                  </TableColumn>
                  <TableColumn label='Misi' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'misi', option: {text: {limit: 300}}})}
                  </TableColumn>
                  <TableColumn label='Keterangan' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'keterangan', option: {text: {limit: 300}}})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tujuan'}}>
            {NullProof({input: data, params: 'tujuan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Sasaran'}}>
            {NullProof({input: data, params: 'sasaran'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Strategi'}}>
            {NullProof({input: data, params: 'strategi'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Penanggung Jawab'}}>
            {NullProof({input: data, params: 'penanggung_jawab'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Misi'}}>
            {NullProof({input: data, params: 'misi'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Keterangan'}}>
            {NullProof({input: data, params: 'keterangan'})}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
