import {
    MainWrapper,
    TambahWrapper,
    DetailWrapper,
    PdfWrapper,
    UbahWrapper,
    ImportWrapper,
  } from '../../../../../pages/keuangan/ls-bendahara/transfer/um_sukses/MainWrapper'
  import { DataRoutesProps } from '../../../../UserRolesValidation'
  
  const TransfersSuccess: DataRoutesProps[] = [
    {
      icon: '',
      title: '',
      route: '*',
      elements: <MainWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'tambah',
      elements: <TambahWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'ubah/:id',
      elements: <UbahWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'pdf/:id',
      elements: <PdfWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'detail/:id',
      elements: <DetailWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'import',
      elements: <ImportWrapper />,
      subroute: [],
    },
  ]
  
  export default TransfersSuccess
  