import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  // {
  //   className: 'col-12',
  //   name: 'provinsi',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.string().required('Tujuan Provinsi is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Tujuan Provinsi',
  //         placeholder: 'Tujuan Provinsi',
  //       },
  //     },
  //     datalist: {
  //       api: '/pengawasan_internal/data-provinsi',
  //       params: 'nama',
  //       result: '$nama',
  //       id: 'nama',
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'kota',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.string().required('Tujuan Kota is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Tujuan Kota',
  //         placeholder: 'Tujuan Kota',
  //       },
  //     },
  //     datalist: {
  //       api: '/pengawasan_internal/data-kota',
  //       query: 'provinsi=$provinsi',
  //       params: 'nama',
  //       result: '$nama',
  //       id: 'nama',
  //     },
  //     watchInput: {
  //       id: 'provinsi',
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'singkatan_satker',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Singkatan Satker is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Singkatan Satker',
  //         placeholder: 'Singkatan Satker',
  //       },
  //     },
  //   },
  // },
  {
    className: 'col-12',
    name: 'kode',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode Satker is required'),
    options: {
      input: {
        props: {
          title: 'Kode Satker',
          placeholder: 'Kode Satker',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kode_atasan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode Atasan is required'),
    options: {
      input: {
        props: {
          title: 'Kode Atasan',
          placeholder: 'Kode Atasan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama_satker',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Satker is required'),
    options: {
      input: {
        props: {
          title: 'Nama Satker',
          placeholder: 'Nama Satker',
        },
      },
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'alamat',
  //   type: 'textarea',
  //   value: '',
  //   validator: Yup.string().required('Alamat is required'),
  //   options: {
  //     textarea: {
  //       props: {
  //         title: 'Alamat',
  //         placeholder: 'Alamat',
  //         rows: 3,
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'kodepos',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Kode Pos is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Kode Pos',
  //         placeholder: 'Kode Pos',
  //         type: 'number',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'area',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Kode Area is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Kode Area',
  //         placeholder: 'Kode Area',
  //         type: 'number',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'telp',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('No Telp is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'No Telp',
  //         placeholder: 'No Telp',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'fax',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Fax is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Fax',
  //         placeholder: 'Fax',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'type',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Type is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Type',
  //         placeholder: 'Type',
  //       },
  //     },
  //   },
  // },
]

export {formInputList}
