/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList, formInputListAction, formInputListSubmenu} from './InputLayout'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
  objectListSelector,
} from '../../../helper/AppFunction'
import {TableAction, TableColumn, TableInput, TableRow} from '../../../helper/TableInput'
import {CardUi} from '../../../helper/AppUi'
import FormInput from '../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import ReactIcon from '../../../helper/ReactIcon'

const onChange = (e: any) => {
  const data = e?._data?.get
  const result = {
    ...data,
    route: `/${data?.route_seo?.replaceAll(' ', '_')?.toLowerCase()}`,
    heading: data.name,
  }
  e?.resetForm({values: result})
}

const MainWrapper: FC<{type?: 'menu' | 'action' | 'all'}> = ({type = 'all'}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  if (TableInputData.link?.action) {
    if (type === 'action') {
      TableInputData.link.action[0] = {
        id: 'id',
        protect: 'detail/$id/action/:id',
        title: 'Detail',
        route: 'detail/$id/action/:id',
      }
      TableInputData.link.action[2].hide = true
      TableInputData.link.action[3].hide = true
    } else {
      TableInputData.link.action[0] = {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      }
      TableInputData.link.action[2].hide = false
      TableInputData.link.action[3].hide = false
    }
    if (type === 'action') {
      TableInputData.link.action[1] = {
        id: 'id',
        protect: 'ubah/$id/action/:id',
        title: 'Ubah',
        route: 'ubah/$id/action/:id',
      }
    } else if (type === 'menu') {
      TableInputData.link.action[1] = {
        id: 'id',
        protect: 'ubah/menu/:id',
        title: 'Ubah',
        route: 'ubah/menu/:id',
      }
    } else {
      TableInputData.link.action[1] = {
        id: 'id',
        protect: 'ubah/:id',
        title: 'Ubah',
        route: 'ubah/:id',
      }
    }
  }

  const deleteAction: any = objectListSelector({
    data: TableInputData.link?.action || [],
    name: 'protect',
    search: 'delete',
  })
  if (type === 'action') {
    deleteAction.route = ''
  } else {
    deleteAction.route = InfoData.api
  }
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(
          type === 'action'
            ? PageData.action.page.breadcrumb.data
            : type === 'all'
            ? PageData.semua.page.breadcrumb.data
            : PageData.submenu.page.breadcrumb.data
        )}
      >
        {type === 'action'
          ? PageData.action.page.breadcrumb.title
          : type === 'all'
          ? PageData.semua.page.breadcrumb.title
          : PageData.submenu.page.breadcrumb.title}
      </PageTitle>
      <CardUi
        title={
          type === 'action'
            ? PageData.action.page.breadcrumb.title
            : type === 'all'
            ? PageData.semua.page.breadcrumb.title
            : PageData.submenu.page.breadcrumb.title
        }
      >
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          protect={InfoData.path}
          {...TableInputData}
          headerData={
            type === 'action'
              ? [
                  {
                    title: 'No',
                    props: {
                      className: 'mw-100px',
                    },
                  },
                  {
                    title: 'Nama',
                  },
                  {
                    title: 'Route',
                  },
                  {
                    title: 'Route SEO',
                  },
                ]
              : [
                  {
                    title: 'No',
                    props: {
                      className: 'mw-100px',
                    },
                  },
                  {
                    title: 'Icon',
                    props: {
                      className: 'max-w-50px',
                    },
                  },
                  {
                    title: 'Nama',
                  },
                  {
                    title: 'Heading',
                  },
                  {
                    title: 'Route',
                  },
                  {
                    title: 'Route SEO',
                  },
                ]
          }
          route={{
            url: query['id']
              ? `${InfoData.api}/${query['id']}/${type === 'action' ? 'action' : 'submenu'}`
              : InfoData.api,
            // query: PageData.semua.route.query,
          }}
          link={{
            action: TableInputData.link?.action,
            tambah:
              type === 'action'
                ? {
                    id: 'tambah',
                    protect: 'tambah',
                    title: 'Tambah',
                    route: 'tambah/action',
                  }
                : {
                    id: 'tambah',
                    protect: 'tambah',
                    title: 'Tambah',
                    route: 'tambah',
                  },
          }}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  {type === 'action' ? (
                    <>
                      <TableColumn label='Nama' className='min-w-150px'>
                        {NullProof({input: l, params: 'name'})}
                      </TableColumn>
                      <TableColumn label='Route' className='min-w-200px'>
                        {NullProof({input: l, params: 'route'})}
                      </TableColumn>
                      <TableColumn label='Route SEO' className='min-w-200px'>
                        {NullProof({input: l, params: 'route_seo'})}
                      </TableColumn>
                    </>
                  ) : (
                    <>
                      <TableColumn label='Icon' className='min-w-50px'>
                        <ReactIcon
                          icon={
                            NullProof({input: l, params: 'bootstrapIcon', isLabel: false}) ||
                            'RiLayout2Line'
                          }
                          props={{
                            className: 'fs-2',
                          }}
                        />
                      </TableColumn>
                      <TableColumn label='Nama' className='min-w-150px'>
                        {NullProof({input: l, params: 'name'})}
                      </TableColumn>
                      <TableColumn label='Heading' className='min-w-150px'>
                        {NullProof({input: l, params: 'heading'})}
                      </TableColumn>
                      <TableColumn label='Route' className='min-w-200px'>
                        {NullProof({input: l, params: 'route'})}
                      </TableColumn>
                      <TableColumn label='Route SEO' className='min-w-200px'>
                        {NullProof({input: l, params: 'route_seo'})}
                      </TableColumn>
                    </>
                  )}
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC<{type?: 'menu' | 'action' | 'all'}> = ({type = 'all'}) => {
  const query = useParams()
  if (query['id'] && type === 'menu') {
    formInputListSubmenu[0].disabled = true
    formInputListSubmenu[0].value = query['id']
  } else if (query['id'] && type === 'action') {
    formInputListAction[0].disabled = true
    formInputListAction[0].value = query['id']
  }
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(
          type === 'all'
            ? PageData.ubah.page.breadcrumb.data
            : type === 'menu'
            ? PageData.ubah_submenu.page.breadcrumb.data
            : PageData.ubah_action.page.breadcrumb.data
        )}
      >
        {type === 'all'
          ? PageData.ubah.page.breadcrumb.title
          : type === 'menu'
          ? PageData.ubah_submenu.page.breadcrumb.title
          : PageData.ubah_action.page.breadcrumb.title}
      </PageTitle>
      <CardUi
        title={
          type === 'all'
            ? PageData.ubah.page.breadcrumb.title
            : type === 'menu'
            ? PageData.ubah_submenu.page.breadcrumb.title
            : PageData.ubah_action.page.breadcrumb.title
        }
      >
        <FormInput
          input={
            type === 'all'
              ? formInputList
              : type === 'menu'
              ? formInputListSubmenu
              : formInputListAction
          }
          route={{
            url: RouterQueryParams(
              type === 'menu' || type === 'all'
                ? `${InfoData.api}/:id`
                : `${InfoData.api}/:id/action/:subid`,
              query
            ),
            type: 'put',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC<{type?: 'menu' | 'action' | 'all'}> = ({type = 'all'}) => {
  const query = useParams()
  if (query['id'] && type === 'menu') {
    formInputListSubmenu[0].disabled = true
    formInputListSubmenu[0].value = query['id']
  } else if (query['id'] && type === 'action') {
    formInputListAction[0].disabled = true
    formInputListAction[0].value = query['id']
  }
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(
          type === 'all'
            ? PageData.tambah.page.breadcrumb.data
            : type === 'menu'
            ? PageData.tambah_submenu.page.breadcrumb.data
            : PageData.tambah_action.page.breadcrumb.data
        )}
      >
        {type === 'all'
          ? PageData.tambah.page.breadcrumb.title
          : type === 'menu'
          ? PageData.tambah_submenu.page.breadcrumb.title
          : PageData.tambah_action.page.breadcrumb.title}
      </PageTitle>
      <CardUi
        title={
          type === 'all'
            ? PageData.tambah.page.breadcrumb.title
            : type === 'menu'
            ? PageData.tambah_submenu.page.breadcrumb.title
            : PageData.tambah_action.page.breadcrumb.title
        }
      >
        <FormInput
          input={
            type === 'all'
              ? formInputList
              : type === 'menu'
              ? formInputListSubmenu
              : formInputListAction
          }
          route={{
            url: RouterQueryParams(
              type === 'menu' || type === 'all' ? InfoData.api : `${InfoData.api}/:id/action`,
              query
            ),
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}
const DetailWrapper: FC<{type: 'action' | 'menu'}> = ({type}) => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail
          onSuccess={(val: any) => setData(val)}
          {...TableInputDetailData}
          route={{
            url:
              type === 'menu'
                ? TableInputDetailData.route.url
                : `${TableInputDetailData.route.url}/action/:subid`,
          }}
        >
          <TableInputDetailColumn title={{value: 'Nama'}}>
            {NullProof({input: data, params: 'name'})}
          </TableInputDetailColumn>
          {type === 'menu' && (
            <TableInputDetailColumn title={{value: 'Heading'}}>
              {NullProof({input: data, params: 'heading'})}
            </TableInputDetailColumn>
          )}
          <TableInputDetailColumn title={{value: 'Route'}}>
            {NullProof({input: data, params: 'route'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Route SEO'}}>
            {NullProof({input: data, params: 'route_seo'})}
          </TableInputDetailColumn>
          {type === 'menu' && (
            <TableInputDetailColumn title={{value: 'Icon'}}>
              {NullProof({input: data, params: 'bootstrapIcon', isLabel: false}) && (
                <ReactIcon
                  props={{className: 'fs-2 me-2'}}
                  icon={NullProof({input: data, params: 'bootstrapIcon', isLabel: false})}
                />
              )}
              {NullProof({input: data, params: 'bootstrapIcon'})}
            </TableInputDetailColumn>
          )}
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
