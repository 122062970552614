import {DefaultPdfLayoutData} from '../../../../helper/ReactPdf'

const PdfData = {
  ...DefaultPdfLayoutData,
  image: {},
  perusahaan: {
    logo: '/media/logokma.png',
    nama: 'KEMENTERIAN AGAMA R.I.\nINSPEKTORAT JENDERAL',
    alamat: 'Jl. RS Fatmawati No.33A Cipete PO.BOX 3867',
    telepon: '021-75916038, 7691849',
    fax: '021-7692112',
    website: 'www.itjen.kemenag.go.id',
  },
  data: {
    header: {
      lembarke: 1,
      kode: '4.b',
      nomor: '000843-C/SPD/2023',
    },
    nomor: '1480/IJ/Set.IJ/05/2023',
    tanggal: '9 Maret 2023',
    pegawai: [
      {
        jabatan: 'Anggota Tim',
        pegawai: {
          nama: 'Ahmad Syauqi',
          nip: '19820323211011010',
          pangkat: 'Penata / III/c',
          jabatan: 'Analisis Kebijakan Muda',
        },
      },
      {
        jabatan: 'Anggota Tim',
        pegawai: {
          nama: 'Ahmad Syauqi',
          nip: '19820323211011010',
          pangkat: 'Penata / III/c',
          jabatan: 'Analisis Kebijakan Muda',
        },
      },
      {
        jabatan: 'Anggota Tim',
        pegawai: {
          nama: 'Ahmad Syauqi',
          nip: '19820323211011010',
          pangkat: 'Penata / III/c',
          jabatan: 'Analisis Kebijakan Muda',
        },
      },
      {
        jabatan: 'Anggota Tim',
        pegawai: {
          nama: 'Ahmad Syauqi',
          nip: '19820323211011010',
          pangkat: 'Penata / III/c',
          jabatan: 'Analisis Kebijakan Muda',
        },
      },
    ],
    tingkat: 'E',
    maksud:
      'Melaksanakan verifikasi dan validasi Pengaduan Masyarakat (Dumas) di lingkungan Kantor KEMENTERIAN Agama Kabupaten Bandung Barat, Provinsi Jawa Barat',
    angkutan: 'BUS/KA/Pesawat',
    tujuan: [{detail: 'Kota Bandung - Ngamprah'}, {detail: 'Kota Bandung - Ngamprah'}],
    instansi: 'Inspektorat Jenderal KEMENTERIAN Agama',
    mataanggaran: '524111',
    perjalanandinas: {
      berangkat: '18 Juli 2023',
      pulang: '20 Juli 2023',
    },
  },
}

export default PdfData
