import {
	MainWrapper,
	TambahWrapper,
	UbahWrapper,
	DetailWrapper,
} from '../../../../pages/tata-usaha/pengelolaan-naskah-dinas/berkas/daftar-berkas-aktif/MainWrapper'
import { DataRoutesProps } from '../../../UserRolesValidation'

const MainRoutes: DataRoutesProps[] = [
	{
		icon: '',
		title: '',
		route: '*',
		elements: <MainWrapper/>,
		subroute: [],
	},
	{
		icon: '',
		title: '',
		route: 'tambah',
		elements: <TambahWrapper/>,
		subroute: [],
	},
	{
		icon: '',
		title: '',
		route: 'ubah/:id',
		elements: <UbahWrapper/>,
		subroute: [],
	},
	{
		icon: '',
		title: '',
		route: 'detail/:id',
		elements: <DetailWrapper/>,
		subroute: [],
	},
]

export { MainRoutes }
  