/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {inputList} from './InputLayout'
import FormInput from '../../../../../../helper/FormInput'
import {CardUi} from '../../../../../../helper/AppUi'
import {
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../../../helper/AppFunction'
import {TableAction, TableColumn, TableInput, TableRow} from '../../../../../../helper/TableInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../../helper/TableInputDetail'
import {formatDate} from '../../../../../../helper/DateFns'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td style={{minWidth: '50px'}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                  </td>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Tujuan'
                    className='min-w-400px'
                  >
                    {NullProof({input: l, params: 'surat_tugas.tujuan', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div>
                            {`${i1 + 1}. `}
                            <b>Wilayah:</b>{' '}
                            {`${NullProof({
                              input: l1,
                              params: 'provinsi',
                            })} - ${NullProof({
                              input: l1,
                              params: 'kota',
                            })}`}
                          </div>
                          <div>
                            <b>Satuan Kerja:</b>{' '}
                            {`${NullProof({
                              input: l1,
                              params: 'satker.singkatan_satker',
                            })} - ${NullProof({
                              input: l1,
                              params: 'satker.nama_satker',
                            })}`}
                          </div>
                        </Fragment>
                      )
                    )}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Tanggal Pelakanaan'
                    className='min-w-150px'
                  >
                    {formatDate({
                      date: NullProof({input: l, params: 'tanggal_pelaksanaan'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={inputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={inputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Nomor Surat Tugas'}}>
            {NullProof({input: data, params: 'surat_tugas.nomor_surat'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal Pelaksanaan'}}>
            {formatDate({
              date: NullProof({input: data, params: 'tanggal_pelaksanaan'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn isResponsive='desktop' hideDivider title={{value: 'Auditi'}}>
            {NullProof({input: data, params: 'surat_tugas.tujuan', isMap: true})?.map(
              (l: any, i: number) => (
                <Fragment key={i}>
                  <TableInputDetailColumn isResponsive='desktop'>
                    <TableInputDetailColumn hideDivider title={{value: 'Provinsi'}}>
                      {NullProof({input: l, params: 'provinsi'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn hideDivider title={{value: 'Kota'}}>
                      {NullProof({input: l, params: 'kota'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn hideDivider title={{value: 'Satuan Kerja'}}>
                      {NullProof({input: l, params: 'satker.nama_satker'})}
                    </TableInputDetailColumn>
                  </TableInputDetailColumn>
                </Fragment>
              )
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn isResponsive='desktop' hideDivider title={{value: 'Komponen'}}>
            {NullProof({input: data, params: 'komponen', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <TableInputDetailColumn isResponsive='desktop'>
                  <TableInputDetailColumn hideDivider title={{value: 'Index KKA'}}>
                    {NullProof({input: l, params: 'index_kka'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Komentar'}}>
                    {NullProof({input: l, params: 'komentar'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Penyelesaian'}}>
                    {NullProof({input: l, params: 'penyelesaian'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Persetujuan'}}>
                    {NullProof({input: l, params: 'persetujuan'})}
                  </TableInputDetailColumn>
                </TableInputDetailColumn>
              </Fragment>
            ))}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
