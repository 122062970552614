import {PageDataContent} from '../../../../helper/Model'
import {TableInputDataProps} from '../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Export Bukti Perjalanan',
  path: '/evaluasi_pelaporan/verifikasi_perjalanan_dinas/export_bukti_perjalanan',
  api: '/eval_pelaporan/form-bukti-perjalanan-export',
}

const BaseBreadcrumb = [
  {
    title: 'Evaluasi Pelaporan',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Verifikasi Perjalanan Dinas',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Surat Tugas',
    },
    {
      title: 'kegiatan',
    },
    {
      title: 'jumlah bukti perjalanan',
    },
    {
      title: 'Dibuat',
    },
  ],
  link: {
    export: [
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      {
        id: 'id',
        protect: 'pdf/:id',
        title: 'Lihat PDF',
        route: 'pdf/:id',
      },
    ],
  },
  route: {
    url: InfoData.api,
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `Detail ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
