import {FC} from 'react'
import {formInputList} from './InputLayout'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {CardUi} from '../../../../../helper/AppUi'
import PageData, {InfoData} from './PageData'
import FormInput from '../../../../../helper/FormInput'
import {BreadcrumbQueryParams} from '../../../../../helper/AppFunction'
import {useParams} from 'react-router-dom'

const TambahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data)}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true
          }}
        />
      </CardUi>
    </>
  )
}

export {TambahWrapper}
