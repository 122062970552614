// import {PageDataContent} from '../Model'
import {PageDataContent} from '../../../../../helper/Model'
import {TableInputDataProps} from '../../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Disposisi / Koordinasi / Saran',
  path: '/tata_usaha/pengelolaan_naskah_dinas/naskah_masuk/detail/:id',
  api: '/tata_usaha/naskah_masuk/:id/disposisi',
}

const BaseBreadcrumb = [
  {
    title: 'Tata Usaha',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Pengelolaan Naskah Dinas',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: 'Naskah Masuk',
    path: '/tata_usaha/pengelolaan_naskah_dinas/naskah_masuk',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Detail',
    path: '/tata_usaha/pengelolaan_naskah_dinas/naskah_masuk/detail/:id',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Nama',
    },
    {
      title: 'Username',
    },
    {
      title: 'Email',
    },
  ],
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'lihat',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      //   icon: 'RiEyeLine',
      //   type: 'feedback',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: InfoData.api,
    // query: 'byuser=true',
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
