import {
    MainWrapper,
    TambahWrapper,
    UbahWrapper,
    DetailWrapper,
  } from '../../../../../pages/pengawasan-internal/persiapan-pelaksanaan-pengawasan-internal/data-tambahan/jabatan/MainWrapper'
  import {DataRoutesProps} from '../../../../UserRolesValidation'
  
  const JabatanRoutes: DataRoutesProps[] = [
    {
      icon: '',
      title: '',
      route: '*',
      elements: <MainWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'tambah',
      elements: <TambahWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'ubah/:id',
      elements: <UbahWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'detail/:id',
      elements: <DetailWrapper />,
      subroute: [],
    },
  ]
  
  export {JabatanRoutes}
  