// src/Tiptap.jsx
import Placeholder from '@tiptap/extension-placeholder'
import {EditorProvider, FloatingMenu, BubbleMenu, useEditor, EditorContent} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import {FC, useEffect, useState} from 'react'
import ReactIcon from './ReactIcon'
import Underline from '@tiptap/extension-underline'

interface ReactTiptapProps {
  value: string
  style?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  options?: {
    props?: React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >
    placeholder?: string
  }
  onChange?: (data: {html: string; text: string; json: any}) => void
}

const ReactTiptap: FC<ReactTiptapProps> = ({value, style, options, onChange}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        paragraph: {},
      }),
      Underline,
      Placeholder.configure({
        placeholder: options?.props?.placeholder || options?.props?.title || 'Placeholder...',
      }),
    ],
    content: value,
    onUpdate: ({editor}) => {
      const result = {
        json: editor.getJSON(),
        html: editor.getHTML().replaceAll('<p>', '').replaceAll('</p>', ''),
        // html: editor.getHTML(),
        text: editor.getText(),
      }
      // console.log(result.html)
      onChange && onChange(result)
    },
  })

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // editor?.commands.enter()
      editor?.chain().focus().insertContent({
        type: 'paragraph',
        content: [],
      }).run();
    }
  };

  // useEffect(() => {
  //   editor?.commands.setContent(value)
  // }, [value])
  return (
    <div className='form-control form-control-lg px-6 py-4'>
      <EditorContent editor={editor} style={{...style}} onKeyDown={handleEnterKeyPress}>
        <div className='d-flex gap-2 mb-4'>
          <button
            type='button'
            onClick={() => editor?.chain().focus().toggleBold().run()}
            disabled={!editor?.can().chain().focus().toggleBold().run()}
            className={`btn btn-icon fs-2 p-0 ${
              editor?.isActive('bold') ? 'btn-primary' : 'border'
            }`}
            style={{
              width: '40px',
              height: '40px',
            }}
          >
            <ReactIcon icon='RiBold' />
          </button>
          <button
            type='button'
            onClick={() => editor?.chain().focus().toggleItalic().run()}
            disabled={!editor?.can().chain().focus().toggleItalic().run()}
            className={`btn btn-icon fs-2 p-0 ${
              editor?.isActive('italic') ? 'btn-primary' : 'border'
            }`}
            style={{
              width: '40px',
              height: '40px',
            }}
          >
            <ReactIcon icon='RiItalic' />
          </button>
          <button
            type='button'
            onClick={() => editor?.chain().focus().toggleUnderline().run()}
            disabled={!editor?.can().chain().focus().toggleUnderline().run()}
            className={`btn btn-icon fs-2 p-0 ${
              editor?.isActive('underline') ? 'btn-primary' : 'border'
            }`}
            style={{
              width: '40px',
              height: '40px',
            }}
          >
            <ReactIcon icon='RiUnderline' />
          </button>
          {/* <button 
            type='button'
            onClick={() => editor?.chain().focus().setHorizontalRule().run()}
            style={{
              width: '40px',
              height: '40px',
            }}
          >
            -
          </button>
          <button
          type='button'
        onClick={() => editor?.chain().focus().toggleOrderedList().run()}
        className={editor?.isActive('orderedList') ? 'is-active' : ''}
      >
        ordered list
      </button> */}
        </div>
        {/* <FloatingMenu>This is the floating menu</FloatingMenu> */}
        {/* <BubbleMenu>This is the bubble menu</BubbleMenu> */}
      </EditorContent>
    </div>
  )
}

export default ReactTiptap
