const InputLayout = {
  "INPUT.LABEL.BASIC_INPUT": 'Basic Input',
  "INPUT.LABEL.BASIC_INPUT.DESCRIPTION": 'Ini Deskripsi',
  "INPUT.LABEL.BASIC_INPUT.ONE": 'One',
  "INPUT.LABEL.BASIC_INPUT.TWO": 'Two',
  "INPUT.LABEL.BASIC_INPUT.THREE": 'Three',
  "INPUT.NAME": 'Name',
  "INPUT.EMAIL": 'Email',
  "INPUT.DESCRIPTION": 'Description',
  "INPUT.CATEGORY": 'Category',
  "INPUT.YEAR": 'Year',
  "INPUT.PRICE": 'Price',
  "INPUT.DAYS": 'Day',
  "INPUT.CHOICE.FOOD": 'Food',
  "INPUT.CHOICE.DRINK": 'Drink',
  "INPUT.CHOICE.OTHER": 'Other',
  "INPUT.ACTIVE": 'Active',
  "INPUT.ATTACHMENT": 'Attachment',
  "INPUT.MULTI_ATTACHMENT": 'Multi Attachment',
  "INPUT.PROVINCE": 'Province',
  "INPUT.CITY": 'City',
  "INPUT.UNIT": 'Unit',
  "INPUT.LABEL.RANGE_TIME": 'Range Time',
  "INPUT.START_DATE": 'Start Date',
  "INPUT.END_DATE": 'End Date',
  "INPUT.TOTAL_DATE": 'Total Date',
  "INPUT.LABEL.CALCULATION": 'Calculation',
  "INPUT.AMOUNT_ITEM": 'Amount',
  "INPUT.PRICE_ITEM": 'Price',
  "INPUT.TOTAL_ITEM": 'Total Item',
  "INPUT.LINKMEMBER": 'Link Member',
  "INPUT.CUSTOMLOAD": 'Custom Load',
}

const TableMain = {
  "TABLE.HEADER.NAME": 'Name',
  "TABLE.HEADER.USERNAME": 'Username',
  "TABLE.HEADER.EMAIL": 'Email',
  "TABLE.HEADER.ACTION": 'Action',
  "TABLE.ACTION.DETAIL": 'Detail',
  "TABLE.ACTION.EDIT": 'Edit',
  "TABLE.ACTION.VIEW_PDF": 'View PDF',
  "TABLE.ACTION.DELETE": 'Delete',
}

const i18nPageAllMessages = {
  "TITLE": 'Sample',
  "BREADCRUMB.SAMPLE": 'Sample',
  ...InputLayout,
  ...TableMain,
}

export default i18nPageAllMessages