import {formatDistanceToNow, format, differenceInDays, parse} from 'date-fns'
import {id} from 'date-fns/locale'
import {FC} from 'react'

type DateFnsProps = {
  date: string
  suffix?: boolean
  targetDate?: string
  dateFormat: 'now' | string
}

const parseDate = (data: string) => {
  return new Date(data)
}

const formatDate = (data: DateFnsProps) => {
  let result: any = ''
  try {
    result =
      data.date == 'now'
        ? format(new Date(), data.dateFormat, {locale: id})
        : data.dateFormat == 'now'
        ? formatDistanceToNow(parseDate(data.date), {
            locale: id,
            addSuffix: data.suffix,
          })
        : data.dateFormat == 'range'
        ? differenceInDays(parseDate(data.targetDate || ''), parseDate(data.date))
        : format(parseDate(data.date), data.dateFormat, {locale: id})
  } catch (_) {}
  return result
}

const DateFNS: FC<DateFnsProps> = ({date, dateFormat, targetDate = '', suffix = true}) => {
  return <div>{formatDate({date, dateFormat, targetDate, suffix})}</div>
}

const getListYear = (
  sort: 'asc' | 'desc' = 'desc',
  start: number = new Date().getFullYear()-1,
  end: number = new Date().getFullYear() + 3,
) => {
  let result = []
  for (let index = start; index <= end; index++) {
    result.push({
      title: index.toString(),
      value: index,
    })
  }
  if (sort === 'desc') {
    result.reverse()
  }
  return result
}

export {DateFNS, formatDate, getListYear}
