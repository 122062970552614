import {FC, Fragment} from 'react'
import {Document, Page, View, Text, Svg, Path} from '@react-pdf/renderer'
import {FontConfig, PdfLayoutDataProps, PdfStyle, PdfStylePallete} from '../../../helper/ReactPdf'
import {AlphabetIndex, NullProof} from '../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  const themeColor = {
    sektor: '#16af57',
    tema: '#febe30',
    poin: '#d79829',
  }
  type ToogleValueProps = '' | 'Koordinator' | 'Kontributor'
  const IconToogle = (val: ToogleValueProps) => {
    if (val === '') {
      return ' '
    } else if (val === 'Kontributor') {
      return (
        <Svg
          style={{width: '15px', height: '15px'}}
          width='100%'
          height='100%'
          viewBox='0 0 327 283'
        >
          <Path d='M163.505 0L0 283H327L163.505 0Z' fill={themeColor.sektor} />
        </Svg>
      )
    } else if (val === 'Koordinator') {
      return (
        <Svg
          style={{width: '15px', height: '15px'}}
          width='100%'
          height='100%'
          viewBox='0 0 284 284'
        >
          <Path
            d='M142 284C220.424 284 284 220.424 284 142C284 63.5756 220.424 0 142 0C63.5756 0 0 63.5756 0 142C0 220.424 63.5756 284 142 284Z'
            fill={themeColor.sektor}
          />
        </Svg>
      )
    }
  }

  const DataColumn: FC<{
    isColumnFull?: boolean
    isBottomBorder?: boolean
    isBottomBorderCol2?: boolean
    column1?: string
    column2?: string
    column3?: string
    column4?: string
    tr1?: ToogleValueProps
    tr2?: ToogleValueProps
    tr3?: ToogleValueProps
    tr4?: ToogleValueProps
    sek?: ToogleValueProps
    ir1?: ToogleValueProps
    ir2?: ToogleValueProps
    ir3?: ToogleValueProps
    ir4?: ToogleValueProps
    irvest?: ToogleValueProps
    style?: any
  }> = ({
    isColumnFull = false,
    isBottomBorder = true,
    isBottomBorderCol2 = true,
    column1,
    column2,
    column3,
    column4,
    ir1 = '',
    ir2 = '',
    ir3 = '',
    ir4 = '',
    irvest = '',
    sek = '',
    tr1 = '',
    tr2 = '',
    tr3 = '',
    tr4 = '',
    style = {},
  }) => {
    return (
      <View style={{flexDirection: 'row', ...style}}>
        <View
          style={{
            ...PdfStylePallete.table.col,
            borderStyle: 'solid',
            borderBottomWidth: isBottomBorder ? 1 : 0,
            width: isColumnFull ? '95%' : '30%',
            height: '100%',
            textAlign: 'left',
            justifyContent: 'center',
          }}
        >
          <Text>{column1}</Text>
        </View>
        {!isColumnFull && (
          <>
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                borderBottomWidth: isBottomBorder || isBottomBorderCol2 ? 1 : 0,
                width: '30%',
                textAlign: 'left',
                justifyContent: 'center',
              }}
            >
              <Text>{column2}</Text>
            </View>
            {/* <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                // borderBottomWidth: isBottomBorder ? 1 : 0,
                width: '15%',
                textAlign: 'left',
                justifyContent: 'center',
              }}
            >
              <Text>{column3}</Text>
            </View> */}
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                // borderBottomWidth: isBottomBorder ? 1 : 0,
                width: '20%',
                textAlign: 'left',
                justifyContent: 'center',
              }}
            >
              <Text>{column4}</Text>
            </View>
          </>
        )}
        <View
          style={{
            ...PdfStylePallete.table.col,
            padding: 0,
            borderStyle: 'solid',
            // borderBottomWidth: isBottomBorder ? 1 : 0,
            width: '20%',
            textAlign: 'center',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              width: '25%',
              borderWidth: 1,
              borderStyle: 'solid',
              borderTop: 0,
              borderLeft: 0,
              borderBottom: 0,
              backgroundColor: tr1 === 'Koordinator' ? 'yellow' : tr1 === 'Kontributor' ? 'yellow' : '',
            }}
          >
            {/* {Triwulan(tr1)} */}
          </View>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              width: '25%',
              borderWidth: 1,
              borderStyle: 'solid',
              borderTop: 0,
              borderLeft: 0,
              borderBottom: 0,
              backgroundColor: tr2 === 'Koordinator' ? 'yellow' : tr2 === 'Kontributor' ? 'yellow' : '',
            }}
          >
            {/* <View>{IconToogle(tr2)}</View> */}
          </View>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              width: '25%',
              borderWidth: 1,
              borderStyle: 'solid',
              borderTop: 0,
              borderLeft: 0,
              borderBottom: 0,
              backgroundColor: tr3 === 'Koordinator' ? 'yellow' : tr3 === 'Kontributor' ? 'yellow' : '',
            }}
          >
            {/* <View>{IconToogle(tr3)}</View> */}
          </View>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              width: '25%',
              borderWidth: 1,
              borderStyle: 'solid',
              borderTop: 0,
              borderLeft: 0,
              borderBottom: 0,
              borderRight: 0,
              backgroundColor: tr4 === 'Koordinator' ? 'yellow' : tr4 === 'Kontributor' ? 'yellow' : '',
            }}
          >
            {/* <View>{IconToogle(tr4)}</View> */}
          </View>
        </View>
        <View
          style={{
            ...PdfStylePallete.table.col,
            padding: 0,
            borderStyle: 'solid',
            // borderBottomWidth: isBottomBorder ? 1 : 0,
            width: '25%',
            textAlign: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              width: '25%',
              borderWidth: 2,
              borderStyle: 'solid',
              borderTop: 0,
              borderLeft: 0,
              borderBottom: 0,
            }}
          >
            <View>{IconToogle(sek)}</View>
          </View>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              width: '25%',
              borderWidth: 1,
              borderStyle: 'solid',
              borderTop: 0,
              borderLeft: 0,
              borderBottom: 0,
            }}
          >
            <View>{IconToogle(ir1)}</View>
          </View>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              width: '25%',
              borderWidth: 1,
              borderStyle: 'solid',
              borderTop: 0,
              borderLeft: 0,
              borderBottom: 0,
            }}
          >
            <View>{IconToogle(ir2)}</View>
          </View>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              width: '25%',
              borderWidth: 1,
              borderStyle: 'solid',
              borderTop: 0,
              borderLeft: 0,
              borderBottom: 0,
            }}
          >
            <View>{IconToogle(ir3)}</View>
          </View>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              width: '25%',
              borderWidth: 1,
              borderStyle: 'solid',
              borderTop: 0,
              borderLeft: 0,
              borderBottom: 0,
            }}
          >
            <View>{IconToogle(ir4)}</View>
          </View>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
              width: '25%',
              borderWidth: 1,
              borderStyle: 'solid',
              borderTop: 0,
              borderLeft: 0,
              borderBottom: 0,
              borderRight: 0,
            }}
          >
            <View>{IconToogle(irvest)}</View>
          </View>
        </View>
      </View>
    )
  }
  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28, 1200]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{flexDirection: 'row'}}>
          <View
            style={{
              paddingVertical: '15px',
              width: '50%',
            }}
          >
            <Text
              style={{
                width: '100%',
                textAlign: 'left',
                fontFamily: 'Arial',
                fontSize: PdfStylePallete.typography.title.medium,
                textTransform: 'uppercase',
                fontWeight: 700,
              }}
            >
              Program Prioritas Pengawasan Internal (P3I)
            </Text>
            <Text
              style={{
                width: '100%',
                textAlign: 'left',
                fontFamily: 'Arial',
                fontSize: PdfStylePallete.typography.title.small,
              }}
            >
              {NullProof({input: data.data, params: 'nama'})}
            </Text>
          </View>
          <View
            style={{
              paddingVertical: '15px',
              flexDirection: 'row',
              width: '50%',
            }}
          >
            <View
              style={{
                width: '100%',
                textAlign: 'left',
                fontFamily: 'Arial',
                fontSize: PdfStylePallete.typography.title.small,
              }}
            >
              <View style={{flexDirection: 'row', gap: '5px', alignItems: 'center'}}>
                <View
                  style={{width: '30px', height: '15px', backgroundColor: themeColor.sektor}}
                ></View>
                <Text>Sektor</Text>
              </View>
              <View style={{flexDirection: 'row', gap: '5px', alignItems: 'center'}}>
                <View
                  style={{width: '30px', height: '15px', backgroundColor: themeColor.tema}}
                ></View>
                <Text>Tema</Text>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                textAlign: 'left',
                fontFamily: 'Arial',
                fontSize: PdfStylePallete.typography.title.small,
              }}
            >
              <View style={{flexDirection: 'row', gap: '5px', alignItems: 'center'}}>
                {IconToogle('Koordinator')}
                <Text>Koordinator</Text>
              </View>
              <View style={{flexDirection: 'row', gap: '5px', alignItems: 'center'}}>
                {IconToogle('Kontributor')}
                <Text>Kontributor</Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            ...PdfStylePallete.table.style,
            marginTop: '-5px',
            borderStyle: 'solid',
            fontFamily: 'Arial',
            fontSize: PdfStylePallete.typography.label.medium,
          }}
        >
          <View style={{flexDirection: 'row', backgroundColor: '#548fd2', fontWeight: 'bold'}}>
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '30%',
                height: '100%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>Sektor/Tema/Topik</Text>
            </View>
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '30%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>Informasi Hasil Pengawasan (Insilwas)</Text>
            </View>
            {/* <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '15%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>Jenis Pengawasan</Text>
            </View> */}
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '20%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>Uraian Jenis Pengawasan</Text>
            </View>
            <View
              style={{
                ...PdfStylePallete.table.col,
                padding: 0,
                borderStyle: 'solid',
                width: '20%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  paddingVertical: '5px',
                }}
              >
                Rencana Penyampaian{'\n'}Hasil Pengawasan
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '25%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                  }}
                >
                  <Text>Tr I</Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '25%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                  }}
                >
                  <Text>Tr II</Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '25%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                  }}
                >
                  <Text>Tr III</Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '25%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                    borderRight: 0,
                  }}
                >
                  <Text>Tr IV</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                ...PdfStylePallete.table.col,
                padding: 0,
                borderStyle: 'solid',
                width: '25%',
                textAlign: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text
                style={{
                  paddingVertical: '5px',
                  marginTop: '10px',
                }}
              >
                Unit Pelaksana
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '25%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                  }}
                >
                  <Text>Sek</Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '25%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                  }}
                >
                  <Text>Ir I</Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '25%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                  }}
                >
                  <Text>Ir II</Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '25%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                  }}
                >
                  <Text>Ir III</Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '25%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                  }}
                >
                  <Text>Ir IV</Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    width: '25%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeft: 0,
                    borderBottom: 0,
                    borderRight: 0,
                  }}
                >
                  <Text>Irvest</Text>
                </View>
              </View>
            </View>
          </View>
          {NullProof({input: data.data, params: 'list_data', isMap: true})?.map(
            (l: any, i: number) => (
              <Fragment key={i}>
                <DataColumn
                  isBottomBorder={false}
                  isColumnFull
                  style={{
                    backgroundColor: themeColor.sektor,
                  }}
                  column1={`${AlphabetIndex(i, true)} ${NullProof({input: l, params: 'judul'})}`}
                />
                {NullProof({input: l, params: 'sektor', isMap: true})?.map(
                  (lsektor: any, isektor: number) => (
                    <Fragment key={isektor}>
                      <DataColumn
                        isColumnFull
                        style={{
                          backgroundColor: themeColor.tema,
                        }}
                        column1={`${isektor + 1}. ${NullProof({input: lsektor, params: 'judul'})}`}
                      />
                      {NullProof({input: lsektor, params: 'topik', isMap: true})?.map(
                        (ltopik: any, itopik: number) => (
                          <Fragment key={itopik}>
                            {NullProof({input: ltopik, params: 'pegawasan', isMap: true})?.map(
                              (lpegawasan: any, ipegawasan: number) => (
                                <Fragment key={ipegawasan}>
                                  {NullProof({
                                    input: lpegawasan,
                                    params: 'jenis_pengawasan',
                                    isMap: true,
                                  })?.map((ljenis: any, ijenis: number) => (
                                    <Fragment key={ijenis}>
                                      <DataColumn
                                        isBottomBorder={
                                          NullProof({
                                            input: lpegawasan,
                                            params: 'jenis_pengawasan',
                                            isMap: true,
                                          })?.length ===
                                            ijenis + 1 &&
                                          NullProof({
                                            input: ltopik,
                                            params: 'pegawasan',
                                            isMap: true,
                                          })?.length ===
                                            ipegawasan + 1
                                            ? true
                                            : false
                                        }
                                        isBottomBorderCol2={
                                          NullProof({
                                            input: lpegawasan,
                                            params: 'jenis_pengawasan',
                                            isMap: true,
                                          })?.length ===
                                          ijenis + 1
                                            ? true
                                            : false
                                        }
                                        column1={
                                          ijenis === 0 && ipegawasan === 0
                                            ? `${AlphabetIndex(itopik)}. ${NullProof({
                                                input: ltopik,
                                                params: 'judul',
                                              })}`
                                            : ''
                                        }
                                        column2={
                                          ijenis === 0
                                            ? `${ijenis + (ipegawasan + 1)}). ${NullProof({
                                                input: lpegawasan,
                                                params: 'judul',
                                              })}`
                                            : ''
                                        }
                                        column3={NullProof({input: ljenis, params: 'jenis'})}
                                        column4={NullProof({input: ljenis, params: 'judul'})}
                                        tr1={
                                          NullProof({
                                            input: ljenis,
                                            params: 'triwulan_1',
                                          })
                                            ? 'Koordinator'
                                            : ''
                                        }
                                        tr2={
                                          NullProof({
                                            input: ljenis,
                                            params: 'triwulan_2',
                                          })
                                            ? 'Koordinator'
                                            : ''
                                        }
                                        tr3={
                                          NullProof({
                                            input: ljenis,
                                            params: 'triwulan_3',
                                          })
                                            ? 'Koordinator'
                                            : ''
                                        }
                                        tr4={
                                          NullProof({
                                            input: ljenis,
                                            params: 'triwulan_4',
                                          })
                                            ? 'Koordinator'
                                            : ''
                                        }
                                        sek={
                                          NullProof({
                                            input: ljenis,
                                            params: 'sek',
                                            isLabel: false,
                                          }) || ''
                                        }
                                        ir1={
                                          NullProof({
                                            input: ljenis,
                                            params: 'ir_1',
                                            isLabel: false,
                                          }) || ''
                                        }
                                        ir2={
                                          NullProof({
                                            input: ljenis,
                                            params: 'ir_2',
                                            isLabel: false,
                                          }) || ''
                                        }
                                        ir3={
                                          NullProof({
                                            input: ljenis,
                                            params: 'ir_3',
                                            isLabel: false,
                                          }) || ''
                                        }
                                        ir4={
                                          NullProof({
                                            input: ljenis,
                                            params: 'ir_4',
                                            isLabel: false,
                                          }) || ''
                                        }
                                        irvest={
                                          NullProof({
                                            input: ljenis,
                                            params: 'irvest',
                                            isLabel: false,
                                          }) || ''
                                        }
                                      />
                                    </Fragment>
                                  ))}
                                </Fragment>
                              )
                            )}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )
                )}
              </Fragment>
            )
          )}
        </View>
      </Page>
    </Document>
  )
}

export default PdfLayout
