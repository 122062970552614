import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'kode',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode Kegiatan is required'),
    options: {
      input: {
        props: {
          title: 'Kode Kegiatan',
          placeholder: 'Kode Kegiatan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'instansi_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Instansi is required'),
    options: {
      input: {
        props: {
          title: 'Instansi',
          placeholder: 'Instansi',
        },
      },
      datalist: {
        api: '/kepegawaian/data-instansi',
        params: 'nama',
        result: '$nama',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Kegiatan is required'),
    options: {
      input: {
        props: {
          title: 'Nama Kegiatan',
          placeholder: 'Nama Kegiatan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'keterangan',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Keterangan is required'),
    options: {
      textarea: {
        props: {
          title: 'Keterangan',
          placeholder: 'Keterangan',
          rows: 3,
        },
      },
    },
  },
]

export {formInputList}
