const i18nMenuAllMessages = {
  "LANGUAGE": 'Bahasa',
  "THEME.LIGHT": "Terang",
  "THEME.DARK": "Gelap",
  "THEME.SYSTEM": "Sistem",
  "NOTIFICATION": "Notifikasi",
  "MENU_PROFILE.PROFILE": "Profil",
  "MENU_PROFILE.USER_GUIDE": "Panduan Penggunaan",
  "MENU_PROFILE.EDIT_PROFILE": "Ubah Profil",
  "MENU_PROFILE.LOGOUT": "Keluar",
}

export default i18nMenuAllMessages