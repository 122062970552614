import { BorderStyle, CellStyle, Workbook, createWorkbook } from '../../../helper/ReactExcelJs'
import { PdfLayoutDataProps } from '../../../helper/ReactPdf'
import {ConvertCurrency, NullProof} from '../../../helper/AppFunction'

const WorksheetRinci = (wb: Workbook, data: any) => {
  // More Info: https://www.npmjs.com/package/exceljs
  // Init ExcelJS
  console.log('data', data?.data);
  const dataExcel = data?.data
  let cellNumber: number = 1
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({title: 'Judulnya'})

  // Init Template Theme
  const themeColor = {
    ungu: '60497A', // Format Color: HEX without #
    kuning: 'ffff00',
    hitam: '000000',
    biru: '0000ff',
    merah: 'ff0000',
  }
  type themeStyleProps = {
    style: CellStyle
    border: BorderStyle
  }
  const themeStyle: themeStyleProps = {
    style: {
      backgroundColor: themeColor.ungu,
      color: themeColor.kuning,
      fontConfig: {
        wrap: true,
        alignment: {
          h: 'center',
        },
      },
    },
    border: {
      color: themeColor.kuning,
    },
  }

  type themeStyleCurrencyProps = {
    style: {
      backgroundColor?: string;
      color?: string;
      fontConfig?: {
        name?: string;
        size?: number;
        style?: 'normal' | 'italic' | 'bold';
        underline?: boolean;
        strike?: boolean;
        alignment?: {
          v?: 'top' | 'middle' | 'bottom' | 'justify';
          h?: 'left' | 'center' | 'right' | 'justify';
        };
        rotation?: number;
        wrap?: boolean;
      };
    };
  };
  
  const themeStyleCurrency = (color: string = themeColor['hitam']): themeStyleCurrencyProps => ({
    style: {
      color,
      fontConfig: {
        wrap: true,
        alignment: {
          h: 'right',
        },
      },
    },
  });

  // const rowCell: FC<{id: string, isMerged: boolean, value: any}> = ({id, isMerged = false, value}) => {
  //   return cell({id: id, options: {isMerged: isMerged}}).value = value
  // }

  // Init Column Width
  const columnWidths: any = {
    A: 5,
    B: 5,
    C: 5,
    D: 10,
    E: 10,
    F: 10,
    G: 20,
    H: 30,
    I: 5,
    J: 5,
    K: 10,
    L: 25,
    M: 30,
  }
  setupWidth(columnWidths)
  
  // Start Making ExcelJS
  dataExcel?.map((item: any, i: number) => {
    // cell({id: `A${cellNumber}:B${cellNumber}`, options: {isMerged: true}}).value = NullProof({input: item, params: 'kode_program'}) 
    // cell({id: `C${cellNumber}:E${cellNumber}`, options: {isMerged: true}}).value = NullProof({input: item, params: 'nama_program'}) 
    // cell({id: `H${cellNumber}`, ...themeStyleCurrency()}).value = ConvertCurrency(item?.alokasi_dana)
    // cellNumber += 1
    NullProof({input: item, params: 'detail'})?.map((detail: any, i: number) => {
      cell({id: `A${cellNumber}:B${cellNumber}`, options: {isMerged: true}, style:{color:themeColor['biru']}}).value = NullProof({input: detail, params: 'kode_program'}) 
      cell({id: `C${cellNumber}:E${cellNumber}`, options: {isMerged: true}, style:{color:themeColor['biru']}}).value = NullProof({input: detail, params: 'nama_program'}) 
      cell({id: `H${cellNumber}`,...themeStyleCurrency(themeColor['biru'])}).value = ConvertCurrency(detail?.jumlah_harga)
      cellNumber += 1
      cell({id: `A${cellNumber}:B${cellNumber}`, options: {isMerged: true}, style:{color:themeColor['merah']}}).value = NullProof({input: detail, params: 'kode_komponen'}) 
      cell({id: `C${cellNumber}:E${cellNumber}`, options: {isMerged: true}, style:{color:themeColor['merah']}}).value = NullProof({input: detail, params: 'nama_komponen'}) 
      cell({id: `H${cellNumber}`, ...themeStyleCurrency(themeColor['merah'])}).value = ConvertCurrency(detail?.jumlah_harga)
      cellNumber += 1
      NullProof({input: detail, params: 'program'})?.map((program: any, i: number) => {
        cell({id: `C${cellNumber}:E${cellNumber}`, options: {isMerged: true}}).value = `Lokasi: ${NullProof({input: program, params: 'lokasi'})} `
        cellNumber += 1
        cell({id: `A${cellNumber}:B${cellNumber}`, options: {isMerged: true}, style:{fontConfig:{style:'bold'}}}).value = NullProof({input: program, params: 'kode'}) 
        cell({id: `C${cellNumber}:E${cellNumber}`, options: {isMerged: true}, style:{fontConfig:{style:'bold'}}}).value = NullProof({input: program, params: 'nama'})
        cell({id: `H${cellNumber}`, style:{fontConfig:{alignment:{h:'right'},style:'bold'}}}).value = ConvertCurrency(program?.jumlah_harga) 
        cellNumber += 1
        NullProof({input: program, params: 'kegiatan'})?.map((kegiatan: any, i: number) => {
          cell({id: `A${cellNumber}:B${cellNumber}`, options: {isMerged: true}, style:{fontConfig:{style:'bold'}}}).value = NullProof({input: kegiatan, params: 'kode'}) 
          cell({id: `C${cellNumber}:E${cellNumber}`, options: {isMerged: true}, style:{fontConfig:{style:'bold'}}}).value = NullProof({input: kegiatan, params: 'nama'})
          cell({id: `H${cellNumber}`, style:{fontConfig:{alignment:{h:'right'},style:'bold'}}}).value = ConvertCurrency(kegiatan?.jumlah_harga) 
          cell({id: `I${cellNumber}`, style:{fontConfig:{alignment:{h: 'center', v:'middle'}, style:'bold'}}}).value = 'U'
          cellNumber += 1
          NullProof({input: kegiatan, params: 'subkegiatan'})?.map((subkegiatan: any, i: number) => {
            cell({id: `A${cellNumber}:B${cellNumber}`, options: {isMerged: true}}).value = NullProof({input: subkegiatan, params: 'kode'}) 
            cell({id: `C${cellNumber}:E${cellNumber}`, options: {isMerged: true}}).value = NullProof({input: subkegiatan, params: 'nama'})
            cell({id: `H${cellNumber}`, ...themeStyleCurrency()}).value = ConvertCurrency(subkegiatan?.jumlah_harga) 
            cellNumber += 1
            NullProof({input: subkegiatan, params: 'daftarkegiatan'})?.map((daftarkegiatan: any, i: number) => {
              cell({id: `A${cellNumber}:B${cellNumber}`, options: {isMerged: true}}).value = NullProof({input: daftarkegiatan, params: 'kode'}) 
              cell({id: `C${cellNumber}:E${cellNumber}`, options: {isMerged: true}, style:{fontConfig:{underline:true}}}).value = NullProof({input: daftarkegiatan, params: 'nama'})
              cell({id: `H${cellNumber}`, ...themeStyleCurrency()}).value = ConvertCurrency(daftarkegiatan?.jumlah_harga) 
              cell({id: `J${cellNumber}`, style:{fontConfig:{alignment:{h: 'center', v:'middle'}}}}).value = 'RM'
              cellNumber += 1
              cell({id: `C${cellNumber}:E${cellNumber}`, options: {isMerged: true}}).value = NullProof({input: daftarkegiatan, params: 'ketengan'}) 
              cellNumber += 1
              NullProof({input: daftarkegiatan, params: 'infokegiatan'})?.map((infokegiatan: any, i: number) => {
                cell({id: `C${cellNumber}`}).value = '>'
                  cell({id: `D${cellNumber}:E${cellNumber}`, options: {isMerged: true}}).value = NullProof({input: infokegiatan, params: 'nama'})
                  cell({id: `H${cellNumber}`, ...themeStyleCurrency()}).value = ConvertCurrency(infokegiatan?.jumlah_harga) 
                cellNumber += 1
                NullProof({input: infokegiatan, params: 'detailkegiatan'})?.map((detailkegiatan: any, i: number) => {
                  cell({id: `C${cellNumber}`}).value = '-'
                  cell({id: `D${cellNumber}:E${cellNumber}`, options: {isMerged: true}}).value = NullProof({input: detailkegiatan, params: 'nama'})
                  cell({id: `F${cellNumber}`, options: {isMerged: true}}).value = `${NullProof({input: detailkegiatan, params: 'volume'})} ${NullProof({input: detailkegiatan, params: 'volume_jenis'})}`
                  cell({id: `G${cellNumber}`, ...themeStyleCurrency()}).value = ConvertCurrency(detailkegiatan?.harga) 
                  cell({id: `H${cellNumber}`, ...themeStyleCurrency()}).value = ConvertCurrency(detailkegiatan?.jumlah_harga) 
                  cellNumber += 1
                })
              })
            })
          })
        })
      })
    })
  })
}

const ExcelLayout = (data: PdfLayoutDataProps) => {
  const downloadExcel = async () => {
    const wb = createWorkbook()
    WorksheetRinci(wb, data)
    wb.download()
  }
  return downloadExcel
}

export default ExcelLayout
