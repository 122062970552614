import {FC, useEffect, useRef, useState} from 'react'
import {Accordion as BSAccordion, AccordionProps as BSAccordionProps} from 'react-bootstrap'
import {WithChildren} from '../../_metronic/helpers'
import {AccordionBodyProps} from 'react-bootstrap/esm/AccordionBody'

type AccordionProps = {
  enabled?: boolean
  error?: boolean
  title: string
  accordionProps?: BSAccordionProps
  props?: {
    body?: AccordionBodyProps
  }
}

const Accordion: FC<AccordionProps & WithChildren> = ({
  title,
  enabled = false,
  error = false,
  accordionProps,
  props,
  children,
}) => {
  const [firstLoad, setFirstLoad] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  return (
    <>
      {enabled ? (
        <BSAccordion {...accordionProps}>
          <BSAccordion.Button
            onClick={() => {
              setFirstLoad(true)
            }}
            className={`rounded border-opacity-10 border border-dark ${
              error ? 'border border-danger' : ''
            }`}
          >
            <div className={`fs-4 fw-bold ${error ? 'text-danger' : ''}`}>
              <div>{title}</div>
              {error && <div className='fs-7 fw-normal'>Beberapa data masih kosong</div>}
            </div>
          </BSAccordion.Button>
          <BSAccordion.Body className='px-0 pb-0 border-bottom' {...props?.body}>
            {firstLoad && children}
          </BSAccordion.Body>
        </BSAccordion>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default Accordion
