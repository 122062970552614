import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'
import {getListYear} from '../../../../../../helper/DateFns'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'pka_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('PKA Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'PKA',
          placeholder: 'PKA',
        },
      },
      datalist: {
        api: '/pengawasan_internal/program-kerja-audit',
        id: 'id',
        params: 'no_pka,judul',
        result: '<b>No. PKA:</b> $no_pka</br><b>Judul:</b> $judul</br>',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode',
          placeholder: 'Kode',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_kka',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nomor KKA Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor KKA',
          placeholder: 'Nomor KKA',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'ref_pka',
    type: 'input',
    value: '',
    validator: Yup.string().required('Ref PKA Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Ref PKA',
          placeholder: 'Ref PKA',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {label: {name: '', labelType: 'line'}},
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_paraf',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Paraf Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Paraf',
          placeholder: 'Tanggal Paraf',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_review',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Review Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Review',
          placeholder: 'Tanggal Review',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_disetujui',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Disetujui Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Disetujui',
          placeholder: 'Tanggal Disetujui',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'periode',
    type: 'select',
    value: '',
    listData: getListYear(),
    validator: Yup.string().required('Periode Mohon untuk diisi.'),
    options: {
      select: {
        props: {
          title: 'Periode',
          placeholder: 'Periode',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'disusun_oleh',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Disusun Oleh Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Disusun Oleh',
          placeholder: 'Disusun Oleh',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        id: 'id',
        params: 'nip,nama',
        result: `<b>NIP: </b>$nip</br><b>Nama: </b>$nama`,
      },
    },
  },
  {
    className: 'col-12',
    name: 'direviu_oleh',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Direviu Oleh Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Direviu Oleh',
          placeholder: 'Direviu Oleh',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        id: 'id',
        params: 'nip,nama',
        result: `<b>NIP: </b>$nip</br><b>Nama: </b>$nama`,
      },
    },
  },
  {
    className: 'col-12',
    name: 'disetujui_oleh',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Disetujui Oleh Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Disetujui Oleh',
          placeholder: 'Disetujui Oleh',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        id: 'id',
        params: 'nip,nama',
        result: `<b>NIP: </b>$nip</br><b>Nama: </b>$nama`,
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {label: {name: '', labelType: 'line'}},
  },
  {
    className: 'col-12',
    name: 'sasaran',
    type: 'input',
    value: '',
    validator: Yup.string().required('Sasaran Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Sasaran',
          placeholder: 'Sasaran',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'fungsi',
    type: 'select',
    value: '',
    listData: [
      {title: 'PERENCANAAN', value: 'FUNGSI PERENCANAAN'},
      {title: 'PELAKSANAAN', value: 'FUNGSI PELAKSANAAN'},
      {title: 'EVALUASI DAN PELAPORAN', value: 'FUNGSI EVALUASI DAN PELAPORAN'},
      {
        title: 'CAPAIAN SASARAN HASIL (OUTCOME)',
        value: 'FUNGSI CAPAIAN SASARAN HASIL (OUTCOME)',
      },
    ],
    validator: Yup.string().required('Fungsi Mohon untuk diisi.'),
    options: {
      select: {
        props: {
          title: 'Fungsi',
          placeholder: 'Fungsi',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'subfungsi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Sub Fungsi Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Sub Fungsi',
          placeholder: 'Sub Fungsi',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'komponen',
    type: 'input',
    value: '',
    validator: Yup.string().required('Komponen Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Komponen',
          placeholder: 'Komponen',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'bobot',
    type: 'input',
    value: '',
    validator: Yup.string().required('Bobot Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Bobot',
          placeholder: 'Bobot',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'pengukuran_audit',
    type: 'component',
    value: '',
    validator: Yup.string().required('Pengukuran Audit Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Pengukuran Audit',
          placeholder: 'Pengukuran Audit',
        },
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'uraian',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Uraian Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Uraian',
              placeholder: 'Uraian',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'bobot',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Bobot Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Bobot',
              placeholder: 'Bobot',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'nilai',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Nilai Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Nilai',
              placeholder: 'Nilai',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'hasil',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Hasil Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Hasil',
              placeholder: 'Hasil',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'penjelasan_langkah_kerja',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Penjelasan / Langkah Kerja Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Penjelasan / Langkah Kerja',
              placeholder: 'Penjelasan / Langkah Kerja',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'dokumen_sumber',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Dokumen Sumber Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Dokumen Sumber',
              placeholder: 'Dokumen Sumber',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'kondisi_positif',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Kondisi Positif Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Kondisi Positif',
              placeholder: 'Kondisi Positif',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'kondisi_negatif',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Kondisi Negatif Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Kondisi Negatif',
              placeholder: 'Kondisi Negatif',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'sebab',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Sebab Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Sebab',
              placeholder: 'Sebab',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'akibat',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Akibat Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Akibat',
              placeholder: 'Akibat',
            },
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'jumlah_bobot',
    type: 'input',
    value: '',
    validator: Yup.string().required('Jumlah Bobot Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jumlah Bobot',
          placeholder: 'Jumlah Bobot',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'hasil',
    type: 'input',
    value: '',
    validator: Yup.string().required('Hasil Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Hasil',
          placeholder: 'Hasil',
        },
      },
    },
  },
]

export {formInputList}
