/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../../../../helper/AppUi'
import FormInput from '../../../../../../helper/FormInput'
import {
  AlphabetIndex,
  NullProof,
  RomanIndex,
  RouterQueryParams,
} from '../../../../../../helper/AppFunction'
import {TableAction, TableInput, TableRow} from '../../../../../../helper/TableInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../../helper/TableInputDetail'
import {PdfPageViewer} from '../../../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import ExcelLayout from './ExcelLayout'
import {InfoData} from '../../../../../kebijakan-teknis/form-app/PageData'

const MainPage: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td className='min-w-150px'>{i + 1}</td>
                  <td className='min-w-150px'>{NullProof({input: l, params: 'tahun'})}</td>
                  <td className='min-w-300px'>{NullProof({input: l, params: 'judul'})}</td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const MainWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <MainPage />
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={`${InfoData.api}/${query.id}`}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
        ExcelLayout={ExcelLayout}
      />
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})

  const DynamicSubMenu = (i1?: number, i2?: number, i3?: number, i4?: number, i5?: number) => {
    let result = ''
    if (i1 || i1 === 0) result += RomanIndex(i1)
    if (i2 || i2 === 0) result += '.' + AlphabetIndex(i2, true)
    if (i3 || i3 === 0) result += '.' + (i3 + 1)
    if (i4 || i4 === 0) result += '.' + AlphabetIndex(i4, false)
    if (i5 || i5 === 0) result += '.' + (i5 + 1) + ')'
    return result + ' '
  }

  const ColumnPoint = ({input}: {input: any}) => {
    const columns = [
      'Rencana Dikerjakan',
      'Realisasi Dikerjakan',
      'Rencana Waktu',
      'Realisasi Waktu',
      'Nomor KKA',
      'Keterangan',
    ]
    return (
      <>
        {columns.map((column, index) => (
          <TableInputDetailColumn key={index} hideDivider={index < 5} title={{value: column}}>
            {NullProof({input, params: column.toLowerCase().replace(/\s/g, '_')}) || '-'}
          </TableInputDetailColumn>
        ))}
      </>
    )
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tahun'}}>
            {NullProof({input: data, params: 'tahun'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nomor PKA'}}>
            {NullProof({input: data, params: 'no_pka'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Judul'}}>
            {NullProof({input: data, params: 'judul'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nama Auditi'}}>
            {NullProof({input: data.auditi, params: 'nama_satker'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tujuan'}}>
            {NullProof({input: data, params: 'tujuan', isMap: true})?.map((l: any, i: number) => (
              <div key={i}>{`${i + 1}. ` + NullProof({input: l, params: 'detail'})}</div>
            ))}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Ruang Lingkup'}}>
            {NullProof({input: data, params: 'ruang_lingkup', isMap: true})?.map(
              (l: any, i: number) => (
                <div key={i}>{`${i + 1}. ` + NullProof({input: l, params: 'detail'})}</div>
              )
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Pokok Pokok Audit'}}>
            {NullProof({input: data, params: 'pokok_audit', isMap: true})?.map(
              (l: any, i: number) => (
                <div key={i}>{`${i + 1}. ` + NullProof({input: l, params: 'detail'})}</div>
              )
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Langkah - Langkah Audit'}} />
          {NullProof({input: data, params: 'langkah_audit', isMap: true})?.map(
            (l: any, i: number) => (
              <Fragment key={i}>
                <TableInputDetailColumn hideDivider>
                  <TableInputDetailColumn
                    props={{className: 'ps-0 pb-2'}}
                    hideDivider
                    title={{
                      value: `${DynamicSubMenu(i)}${NullProof({input: l, params: 'nama_langkah'})}`,
                    }}
                  />
                  <TableInputDetailColumn hideDivider title={{value: 'Sasaran'}}>
                    {NullProof({input: l, params: 'sasaran'})}
                  </TableInputDetailColumn>
                  <ColumnPoint input={l} />
                  {l.prosedur_audit.length !== 0 && (
                    <TableInputDetailColumn useGuide hideDivider>
                      {NullProof({input: l, params: 'prosedur_audit', isMap: true})?.map(
                        (lProsedur: any, iProsedur: number) => (
                          <Fragment key={iProsedur}>
                            <TableInputDetailColumn
                              props={{className: 'ps-0 pb-2'}}
                              hideDivider
                              title={{
                                value: `${DynamicSubMenu(i, iProsedur)}${NullProof({
                                  input: lProsedur,
                                  params: 'nama_prosedur',
                                })}`,
                              }}
                            />
                            <ColumnPoint input={lProsedur} />
                            {lProsedur.sub1_prosedur_audit.length !== 0 && (
                              <TableInputDetailColumn useGuide hideDivider>
                                {NullProof({
                                  input: lProsedur,
                                  params: 'sub1_prosedur_audit',
                                  isMap: true,
                                })?.map((lSub1: any, iSub1: number) => (
                                  <Fragment key={iSub1}>
                                    <TableInputDetailColumn
                                      props={{className: 'ps-0 pb-2'}}
                                      hideDivider
                                      title={{
                                        value: `${DynamicSubMenu(i, iProsedur, iSub1)}${NullProof({
                                          input: lSub1,
                                          params: 'nama_prosedur',
                                        })}`,
                                      }}
                                    />
                                    <ColumnPoint input={lSub1} />
                                    {lSub1.sub2_prosedur_audit.length !== 0 && (
                                      <TableInputDetailColumn useGuide hideDivider>
                                        {NullProof({
                                          input: lSub1,
                                          params: 'sub2_prosedur_audit',
                                          isMap: true,
                                        })?.map((lSub2: any, iSub2: number) => (
                                          <Fragment key={iSub2}>
                                            <TableInputDetailColumn
                                              props={{className: 'ps-0 pb-2'}}
                                              hideDivider
                                              title={{
                                                value: `${DynamicSubMenu(
                                                  i,
                                                  iProsedur,
                                                  iSub1,
                                                  iSub2
                                                )}${NullProof({
                                                  input: lSub2,
                                                  params: 'nama_prosedur',
                                                })}`,
                                              }}
                                            />
                                            <ColumnPoint input={lSub2} />
                                            {lSub2.sub3_prosedur_audit.length !== 0 && (
                                              <TableInputDetailColumn useGuide hideDivider>
                                                {NullProof({
                                                  input: lSub2,
                                                  params: 'sub3_prosedur_audit',
                                                  isMap: true,
                                                })?.map((lSub3: any, iSub3: number) => (
                                                  <Fragment key={iSub3}>
                                                    <TableInputDetailColumn
                                                      props={{className: 'ps-0 pb-2'}}
                                                      hideDivider
                                                      title={{
                                                        value: `${DynamicSubMenu(
                                                          i,
                                                          iProsedur,
                                                          iSub1,
                                                          iSub2,
                                                          iSub3
                                                        )}${NullProof({
                                                          input: lSub3,
                                                          params: 'nama_prosedur',
                                                        })}`,
                                                      }}
                                                    />
                                                    <ColumnPoint input={lSub3} />
                                                  </Fragment>
                                                ))}
                                              </TableInputDetailColumn>
                                            )}
                                          </Fragment>
                                        ))}
                                      </TableInputDetailColumn>
                                    )}
                                  </Fragment>
                                ))}
                              </TableInputDetailColumn>
                            )}
                          </Fragment>
                        )
                      )}
                    </TableInputDetailColumn>
                  )}
                </TableInputDetailColumn>
              </Fragment>
            )
          )}
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper, PdfWrapper}
