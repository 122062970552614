const i18nMenuAllMessages = {
  "LANGUAGE": 'Language',
  "THEME.LIGHT": "Light",
  "THEME.DARK": "Dark",
  "THEME.SYSTEM": "System",
  "NOTIFICATION": "Notification",
  "MENU_PROFILE.PROFILE": "Profile",
  "MENU_PROFILE.USER_GUIDE": "User Guide",
  "MENU_PROFILE.EDIT_PROFILE": "Edit Profil",
  "MENU_PROFILE.LOGOUT": "Logout",
}

export default i18nMenuAllMessages