import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'
import {getListYear} from '../../../helper/DateFns'

const formInputStatus: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'usulan_id',
    type: 'datalist',
    validator: Yup.number().required('Usulan Program Kerja Audit Tahunan mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Usulan Program Kerja Audit Tahunan',
          placeholder: 'Usulan Program Kerja Audit Tahunan',
        },
      },
      datalist: {
        api: '/perencanaan_anggaran/rencana-audit-tahunan',
        params: 'tahun',
        filter: 'formatDate',
        result: (
          <>
            <div>
              <b>Tahun: </b>$tahun
            </div>
            {/* <div>
              <b>Periode Audit 5 Tahun: </b>$periode_start - $periode_end
            </div>
            <div>
              <b>Tanggal LHA Terakhir: </b>formatDate=$tgl_lha_terakhir
            </div> */}
          </>
        ),
        id: 'id',
      },
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'rencana_audit_jangka_menengah_id',
  //   type: 'datalist',
  //   validator: Yup.number().required('Rencana Audit Jangka Menengah mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Rencana Audit Jangka Menengah',
  //         placeholder: 'Rencana Audit Jangka Menengah',
  //       },
  //     },
  //     datalist: {
  //       api: '/perencanaan_anggaran/rencana-audit-menengah',
  //       params: 'periode_start,periode_end,tgl_lha_terakhir',
  //       filter: 'formatDate',
  //       result: (
  //         <>
  //           <div>
  //             <b>Periode Audit 5 Tahun: </b>$periode_start - $periode_end
  //           </div>
  //           <div>
  //             <b>Tanggal LHA Terakhir: </b>formatDate=$tgl_lha_terakhir
  //           </div>
  //         </>
  //       ),
  //       id: 'id',
  //     },
  //   },
  // },
  // {
  //   className: 'col',
  //   name: 'tahun',
  //   type: 'select',
  //   listData: getListYear(),
  //   validator: Yup.number().required('Tahun mohon untuk diisi.'),
  //   options: {
  //     select: {
  //       props: {
  //         title: 'Tahun',
  //         placeholder: 'Tahun',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: '',
  //   type: 'label',
  //   validator: Yup.string(),
  //   options: {
  //     label: {
  //       name: 'Tanggal',
  //       labelType: 'both',
  //     },
  //   },
  // },
  // {
  //   className: 'col',
  //   name: 'tgl_mulai',
  //   type: 'input',
  //   validator: Yup.string().required('Dari Tanggal mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Dari Tanggal',
  //         placeholder: 'Dari Tanggal',
  //         type: 'date',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-auto pt-8 mt-6 d-flex align-items-center justify-content-center',
  //   name: '',
  //   type: 'label',
  //   validator: Yup.string(),
  //   options: {
  //     label: {
  //       name: 's/d',
  //       labelType: 'label',
  //     },
  //   },
  // },
  // {
  //   className: 'col',
  //   name: 'tgl_selesai',
  //   type: 'input',
  //   validator: Yup.string().required('Sampai Tanggal mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Sampai Tanggal',
  //         placeholder: 'Sampai Tanggal',
  //         type: 'date',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: '',
  //   type: 'label',
  //   validator: Yup.string(),
  //   options: {
  //     label: {
  //       name: 'Periode Audit 5 Tahun',
  //       labelType: 'line',
  //     },
  //   },
  // },
  // {
  //   className: 'col-12 col-md-6',
  //   name: 'biaya',
  //   type: 'input',
  //   validator: Yup.string().required('Biaya mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       isCurrency: true,
  //       props: {
  //         title: 'Biaya',
  //         placeholder: 'Biaya',
  //         type: 'number',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12 col-md-6',
  //   name: 'lha',
  //   type: 'input',
  //   validator: Yup.string().required('LHA mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'LHA',
  //         placeholder: 'LHA',
  //         type: 'number',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'keterangan',
  //   type: 'textarea',
  //   validator: Yup.string().required('Keterangan mohon untuk diisi.'),
  //   options: {
  //     textarea: {
  //       props: {
  //         title: 'Keterangan',
  //         placeholder: 'Keterangan',
  //         rows: 3,
  //       },
  //     },
  //   },
  // },
  {
    className: 'col-12',
    name: 'unit_auditori',
    type: 'input',
    validator: Yup.string().required('Unit Auditori mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Unit Auditori',
          placeholder: 'Unit Auditori',
        },
      },
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'status',
  //   type: 'select',
  //   value: '',
  //   listData: [
  //     {
  //       title: 'Disetujui',
  //       value: '1',
  //     },
  //     {
  //       title: 'Ditolak',
  //       value: '0',
  //     },
  //   ],
  //   validator: Yup.string(),
  //   options: {
  //     select: {
  //       props: {
  //         title: 'Status',
  //         placeholder: 'Status',
  //       },
  //     },
  //   },
  // },
]

export {formInputList, formInputStatus}
