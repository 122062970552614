import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {InfoData, PageData, TableInputData, TableInputDetailData} from './PageData'
import {
  BreadcrumbQueryParams,
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {CardUi, Divider} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import FormInput from '../../../../helper/FormInput'
import {formInputImportList, formInputList} from './InputLayout'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'

const onChange = (e: any) => {
  const data = e?._data.get
  const dataObject = e?._data.getObject
  let result: any = {...data}
  let totalJumlahHarga: number = 0
  NullProof({input: dataObject, params: 'program', isMap: true}).map((ll: any, ii: number) => {
    const n_program = `program`
    let programJumlahHarga: number = 0
    NullProof({input: ll, params: 'kegiatan', isMap: true}).map((lll: any, iii: number) => {
      const n_kegiatan = `${n_program}_kegiatan_${ii + 1}`
      let kegiatanJumlahHarga: number = 0
      NullProof({input: lll, params: 'subkegiatan', isMap: true}).map((llll: any, iiii: number) => {
        const n_subkegiatan = `${n_kegiatan}_subkegiatan_${iii + 1}`
        let subKegiatanJumlahHarga: number = 0
        NullProof({input: llll, params: 'daftarkegiatan', isMap: true}).map(
          (lllll: any, iiiii: number) => {
            const n_daftarkegiatan = `${n_subkegiatan}_daftarkegiatan_${iiii + 1}`
            let daftarKegiatanJumlahHarga: number = 0
            NullProof({input: lllll, params: 'infokegiatan', isMap: true}).map(
              (llllll: any, iiiiii: number) => {
                const n_infokegiatan = `${n_daftarkegiatan}_infokegiatan_${iiiii + 1}`
                let infoKegiatanJumlahHarga: number = 0
                NullProof({input: llllll, params: 'detailkegiatan', isMap: true}).map(
                  (lllllll: any, iiiiiii: number) => {
                    const n_detailkegiatan = `${n_infokegiatan}_detailkegiatan_${iiiiii + 1}`
                    const harga = Number(lllllll[`harga`])
                    const volume = Number(lllllll['volume'])
                    const jumlahHarga = harga * volume
                    infoKegiatanJumlahHarga += jumlahHarga
                    result = {
                      ...result,
                      [`${n_detailkegiatan}_jumlah_harga_${iiiiiii + 1}`]: jumlahHarga,
                    }
                  }
                )
                daftarKegiatanJumlahHarga += infoKegiatanJumlahHarga
                result = {
                  ...result,
                  [`${n_infokegiatan}_jumlah_harga_${iiiiii + 1}`]: infoKegiatanJumlahHarga,
                }
              }
            )
            subKegiatanJumlahHarga += daftarKegiatanJumlahHarga
            result = {
              ...result,
              [`${n_daftarkegiatan}_jumlah_harga_${iiiii + 1}`]: daftarKegiatanJumlahHarga,
            }
          }
        )
        kegiatanJumlahHarga += subKegiatanJumlahHarga
        result = {
          ...result,
          [`${n_subkegiatan}_jumlah_harga_${iiii + 1}`]: subKegiatanJumlahHarga,
        }
      })
      programJumlahHarga += kegiatanJumlahHarga
      result = {
        ...result,
        [`${n_kegiatan}_jumlah_harga_${iii + 1}`]: kegiatanJumlahHarga,
      }
    })
    totalJumlahHarga += programJumlahHarga
    result = {
      ...result,
      [`${n_program}_jumlah_harga_${ii + 1}`]: programJumlahHarga,
    }
  })
  result = {
    ...result,
    [`jumlah_harga`]: totalJumlahHarga,
  }
  e?.resetForm({values: result})
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data)}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Kegiatan' props={{style: {width: '400px'}}}>
                    {`${NullProof({input: l, params: 'kode_kegiatan'})} - ${NullProof({
                      input: l,
                      params: 'nama_kegiatan',
                    })}`}
                  </TableColumn>
                  <TableColumn label='Komponen' props={{style: {width: '400px'}}}>
                    {`${NullProof({input: l, params: 'kode_komponen'})} - ${NullProof({
                      input: l,
                      params: 'nama_komponen',
                    })}`}
                  </TableColumn>
                  <TableColumn label='Jumlah Biaya' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'jumlah_harga', type: 'currency'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data)}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:subid`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.detail?.page.breadcrumb.data)}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Nama Kegiatan'}}>
            {NullProof({input: data, params: 'nama_kegiatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kode Kegiatan'}}>
            {NullProof({input: data, params: 'kode_kegiatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nama Komponen'}}>
            {NullProof({input: data, params: 'nama_komponen'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kode Komponen'}}>
            {NullProof({input: data, params: 'kode_komponen'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jumlah Biaya'}}>
            {NullProof({input: data, params: 'jumlah_harga', type: 'currency'})}
          </TableInputDetailColumn>
          {NullProof({input: data, params: 'program', isMap: true})?.map(
            (program: any, i: number) => {
              return (
                <Fragment key={i}>
                  <TableInputDetailColumn hideDivider title={{value: `Program ${i + 1}`}} />
                  <div className='my-5' key={i}>
                    <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
                      {NullProof({input: program, params: 'nama'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn hideDivider title={{value: 'Lokasi'}}>
                      {NullProof({input: program, params: 'lokasi'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn hideDivider title={{value: 'Kode'}}>
                      {NullProof({input: program, params: 'kode'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn hideDivider title={{value: 'Jumlah Biaya'}}>
                      {NullProof({
                        input: program,
                        params: 'jumlah_harga',
                        type: 'currency',
                      })}
                    </TableInputDetailColumn>
                  </div>
                  {program?.kegiatan.map((kegiatan: any, i: number) => {
                    return (
                      <Fragment key={i}>
                        <TableInputDetailColumn hideDivider title={{value: `Kegiatan ${i + 1}`}} />
                        <div className='my-5' key={i}>
                          <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
                            {NullProof({input: kegiatan, params: 'nama'})}
                          </TableInputDetailColumn>
                          <TableInputDetailColumn hideDivider title={{value: 'Kode'}}>
                            {NullProof({input: kegiatan, params: 'kode'})}
                          </TableInputDetailColumn>
                          <TableInputDetailColumn hideDivider title={{value: 'Jumlah Biaya'}}>
                            {NullProof({
                              input: kegiatan,
                              params: 'jumlah_harga',
                              type: 'currency',
                            })}
                          </TableInputDetailColumn>
                        </div>
                        {kegiatan?.subkegiatan.map((subkegiatan: any, i: number) => {
                          return (
                            <Fragment key={i}>
                              <TableInputDetailColumn
                                hideDivider
                                title={{value: `Sub Kegiatan ${i + 1}`}}
                              />
                              <div className='my-5' key={i}>
                                <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
                                  {NullProof({input: subkegiatan, params: 'nama'})}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn hideDivider title={{value: 'Kode'}}>
                                  {NullProof({input: subkegiatan, params: 'kode'})}
                                </TableInputDetailColumn>
                                <TableInputDetailColumn hideDivider title={{value: 'Jumlah Biaya'}}>
                                  {NullProof({
                                    input: subkegiatan,
                                    params: 'jumlah_harga',
                                    type: 'currency',
                                  })}
                                </TableInputDetailColumn>
                              </div>
                              {subkegiatan?.daftarkegiatan.map((daftarkegiatan: any, i: number) => {
                                return (
                                  <Fragment key={i}>
                                    <TableInputDetailColumn
                                      hideDivider
                                      title={{value: `Daftar Kegiatan ${i + 1}`}}
                                    />
                                    <div className='my-5' key={i}>
                                      <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
                                        {NullProof({
                                          input: daftarkegiatan,
                                          params: 'nama',
                                        })}
                                      </TableInputDetailColumn>
                                      <TableInputDetailColumn
                                        hideDivider
                                        title={{value: 'Keterangan'}}
                                      >
                                        {NullProof({
                                          input: daftarkegiatan,
                                          params: 'keterangan',
                                        })}
                                      </TableInputDetailColumn>
                                      <TableInputDetailColumn hideDivider title={{value: 'Kode'}}>
                                        {NullProof({
                                          input: daftarkegiatan,
                                          params: 'kode',
                                        })}
                                      </TableInputDetailColumn>
                                      <TableInputDetailColumn
                                        hideDivider
                                        title={{value: 'Jumlah Biaya'}}
                                      >
                                        {NullProof({
                                          input: daftarkegiatan,
                                          params: 'jumlah_harga',
                                          type: 'currency',
                                        })}
                                      </TableInputDetailColumn>
                                    </div>
                                    {daftarkegiatan?.infokegiatan.map(
                                      (infokegiatan: any, i: number) => {
                                        return (
                                          <Fragment key={i}>
                                            <TableInputDetailColumn
                                              hideDivider
                                              title={{value: `Info Kegiatan ${i + 1}`}}
                                            />
                                            <div className='my-5' key={i}>
                                              <TableInputDetailColumn
                                                hideDivider
                                                title={{value: 'Nama'}}
                                              >
                                                {NullProof({
                                                  input: infokegiatan,
                                                  params: 'nama',
                                                })}
                                              </TableInputDetailColumn>
                                              <TableInputDetailColumn
                                                hideDivider
                                                title={{value: 'Jumlah Biaya'}}
                                              >
                                                {NullProof({
                                                  input: infokegiatan,
                                                  params: 'jumlah_harga',
                                                  type: 'currency',
                                                })}
                                              </TableInputDetailColumn>
                                            </div>
                                            {infokegiatan?.detailkegiatan.map(
                                              (detailkegiatan: any, i: number) => {
                                                return (
                                                  <Fragment key={i}>
                                                    <TableInputDetailColumn
                                                      hideDivider
                                                      title={{
                                                        value: `Detail Kegiatan ${i + 1}`,
                                                      }}
                                                    />
                                                    <div className='my-5' key={i}>
                                                      <TableInputDetailColumn
                                                        hideDivider
                                                        title={{value: 'Nama'}}
                                                      >
                                                        {NullProof({
                                                          input: detailkegiatan,
                                                          params: 'nama',
                                                        })}
                                                      </TableInputDetailColumn>
                                                      <TableInputDetailColumn
                                                        hideDivider
                                                        title={{value: 'Volume'}}
                                                      >
                                                        {NullProof({
                                                          input: detailkegiatan,
                                                          params: 'volume',
                                                        })}{' '}
                                                        {NullProof({
                                                          input: detailkegiatan,
                                                          params: 'volume_jenis',
                                                        })}
                                                      </TableInputDetailColumn>
                                                      <TableInputDetailColumn
                                                        hideDivider
                                                        title={{value: 'Harga Satuan'}}
                                                      >
                                                        {NullProof({
                                                          input: detailkegiatan,
                                                          params: 'harga',
                                                          type: 'currency',
                                                        })}
                                                      </TableInputDetailColumn>
                                                      <TableInputDetailColumn
                                                        hideDivider
                                                        title={{value: 'Jumlah Biaya'}}
                                                      >
                                                        {NullProof({
                                                          input: detailkegiatan,
                                                          params: 'jumlah_harga',
                                                          type: 'currency',
                                                        })}
                                                      </TableInputDetailColumn>
                                                    </div>
                                                  </Fragment>
                                                )
                                              }
                                            )}
                                          </Fragment>
                                        )
                                      }
                                    )}
                                  </Fragment>
                                )
                              })}
                            </Fragment>
                          )
                        })}
                      </Fragment>
                    )
                  })}
                </Fragment>
              )
            }
          )}
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper}
