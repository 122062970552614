/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow} from '../../../../helper/TableInput'
import {
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import FormInput from '../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {formatDate} from '../../../../helper/DateFns'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td style={{minWidth: '50px'}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </td>
                  <td style={{minWidth: '300px'}}>
                    <div>
                      <span className='fw-bold'>Nomor Kartu: </span>
                      <span>{NullProof({input: l, params: 'no_kartu'})}</span>
                    </div>
                    <div>
                      <span className='fw-bold'>Nomor Surat Tugas Audit: </span>
                      <span>{NullProof({input: l, params: 'no_surat_tugas_audit'})}</span>
                    </div>
                    <div>
                      <span className='fw-bold'>Nomor File: </span>
                      <span>{NullProof({input: l, params: 'no_file'})}</span>
                    </div>
                    <div>
                      <span className='fw-bold'>Nomor Rencana Audit: </span>
                      <span>{NullProof({input: l, params: 'rencana_audit_no'})}</span>
                    </div>
                  </td>
                  <td style={{minWidth: '300px'}}>
                    <div>
                      <span className='fw-bold'>Nama: </span>
                      <span>{NullProof({input: l, params: 'pegawai.nama'})}</span>
                    </div>
                    <div>
                      <span className='fw-bold'>NIP: </span>
                      <span>{NullProof({input: l, params: 'pegawai.nip'})}</span>
                    </div>
                  </td>
                  <td style={{minWidth: '300px'}}>
                    <div>
                      <span className='fw-bold'>Tahun Audit Terakhir: </span>
                      <div>{NullProof({input: l, params: 'audit_terakhir_tahun'})}</div>
                    </div>
                    <div>
                      <span className='fw-bold'>Tanggal Mulai/Selesai: </span>
                      <div>{`${formatDate({
                        date: NullProof({input: l, params: 'tgl_mulai'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}/${formatDate({
                        date: NullProof({input: l, params: 'tgl_selesai'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}`}</div>
                    </div>
                  </td>
                  <td style={{minWidth: '300px'}}>
                    {NullProof({input: l, params: 'tujuan_audit'})}
                  </td>
                  <td style={{minWidth: '300px'}}>
                    {NullProof({input: l, params: 'alamat_no_telp'})}
                  </td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Auditor'}}>
            <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'pegawai.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'NIP'}}>
              {NullProof({input: data, params: 'pegawai.nip'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nomor Kartu Penugasan'}}>
            {NullProof({input: data, params: 'no_kartu'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nomor Surat Tugas Audit'}}>
            {NullProof({input: data, params: 'no_surat_tugas_audit'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'No. File Permanen'}}>
            {NullProof({input: data, params: 'no_file'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nomor Rencana Audit'}}>
            {NullProof({input: data, params: 'rencana_audit_no'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tahun Terakhir Audit'}}>
            {NullProof({input: data, params: 'audit_terakhir_tahun'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal'}}>
            <TableInputDetailColumn hideDivider title={{value: 'Mulai'}}>
              {formatDate({
                date: NullProof({input: data, params: 'tgl_mulai'}),
                dateFormat: 'dd MMMM yyyy',
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Selesai'}}>
              {formatDate({
                date: NullProof({input: data, params: 'tgl_selesai'}),
                dateFormat: 'dd MMMM yyyy',
              })}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Alamat Dan Nomor Telepon'}}>
            {NullProof({input: data, params: 'alamat_no_telp'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tujuan Audit'}}>
            {NullProof({input: data, params: 'tujuan_audit'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Ditetapkan Oleh'}}>
            <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'ditetapkan_oleh.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'NIP'}}>
              {NullProof({input: data, params: 'ditetapkan_oleh.nip'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Ditetapkan Di'}}>
            {NullProof({input: data, params: 'ditetapkan_di'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Ditetapkan Tanggal'}}>
            {formatDate({
              date: NullProof({input: data, params: 'ditetapkan_tgl'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Rencana Pelaksanaan', props: {className: 'mb-4'}}}
          >
            <TableInputDetailColumn title={{value: 'Tanggal'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Mulai'}}>
                {formatDate({
                  date: NullProof({input: data, params: 'tgl_mulai'}),
                  dateFormat: 'dd MMMM yyyy',
                })}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Selesai'}}>
                {formatDate({
                  date: NullProof({input: data, params: 'tgl_selesai'}),
                  dateFormat: 'dd MMMM yyyy',
                })}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Anggaran Yang Dianjurkan'}}>
              {ConvertCurrency(NullProof({input: data, params: 'anggaran_dianjurkan'}))}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Anggaran Yang Disetujui'}}>
              {ConvertCurrency(NullProof({input: data, params: 'anggaran_disetujui'}))}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{value: 'Catatan Penting Dari Pengendali Teknis Pengendali Mutu'}}
            >
              {NullProof({input: data, params: 'catatan'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
