import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {InfoData, PageData, TableInputData, TableInputDetailData} from './PageData'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
} from '../../../helper/AppFunction'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CardUi} from '../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../helper/TableInput'
import FormInput from '../../../helper/FormInput'
import {formInputList} from './InputLayout'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import {formatDate} from '../../../helper/DateFns'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='Jenis Naskah' className='min-w-150px'>
                    {NullProof({input: l, params: 'JenisNaskah.text'})}
                  </TableColumn>
                  <TableColumn label='Kombinasi' className='min-w-150px'>
                    {NullProof({input: l, params: 'kombinasi_variabel'})}
                  </TableColumn>
                  <TableColumn label='Penulisan Bulan' className='min-w-150px'>
                    {NullProof({input: l, params: 'JenisPenulisanBulan.nama'})}
                  </TableColumn>
                  <TableColumn label='Jenis Penomoran' className='min-w-150px'>
                    {NullProof({input: l, params: 'JenisPenomoran.nama'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tanggal dibuat'}}>
            {formatDate({
              date: NullProof({input: data, params: 'createdAt'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nomor Dimulai'}}>
            {NullProof({input: data, params: 'nomor_dimulai'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kombinasi Variabel'}}>
            {NullProof({input: data, params: 'kombinasi_variabel'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jenis Naskah'}}>
            {NullProof({input: data, params: 'JenisNaskah.text'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jenis Penomoran'}}>
            {NullProof({input: data, params: 'JenisPenomoran.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jenis Penulisan Bulan'}}>
            {NullProof({input: data, params: 'JenisPenulisanBulan.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Variabel 1'}}>
            {NullProof({input: data, params: 'variabel_1'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Variabel 2'}}>
            {NullProof({input: data, params: 'variabel_2'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Variabel 3'}}>
            {NullProof({input: data, params: 'variabel_3'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Variabel 4'}}>
            {NullProof({input: data, params: 'variabel_4'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Variabel 4'}}>
            {NullProof({input: data, params: 'variabel_4'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Variabel 5'}}>
            {NullProof({input: data, params: 'variabel_5'})}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper}
