const InputLayout = {
  "INPUT.LABEL.BASIC_INPUT": 'Basic Input',
  "INPUT.LABEL.BASIC_INPUT.DESCRIPTION": 'Ini Deskripsi',
  "INPUT.LABEL.BASIC_INPUT.ONE": 'Satu',
  "INPUT.LABEL.BASIC_INPUT.TWO": 'Dua',
  "INPUT.LABEL.BASIC_INPUT.THREE": 'Tiga',
  "INPUT.NAME": 'Nama',
  "INPUT.EMAIL": 'Email',
  "INPUT.DESCRIPTION": 'Deskripsi',
  "INPUT.CATEGORY": 'Kategori',
  "INPUT.YEAR": 'Tahun',
  "INPUT.PRICE": 'Harga',
  "INPUT.DAYS": 'Hari',
  "INPUT.CHOICE.FOOD": 'Makanan',
  "INPUT.CHOICE.DRINK": 'Minuman',
  "INPUT.CHOICE.OTHER": 'Lainnya',
  "INPUT.ACTIVE": 'Aktif',
  "INPUT.ATTACHMENT": 'Lampiran',
  "INPUT.MULTI_ATTACHMENT": 'Multi Lampiran',
  "INPUT.PROVINCE": 'Provinsi',
  "INPUT.CITY": 'Kota',
  "INPUT.UNIT": 'Unit',
  "INPUT.LABEL.RANGE_TIME": 'Jangka Waktu',
  "INPUT.START_DATE": 'Tanggal Mulai',
  "INPUT.END_DATE": 'Tanggal Selesai',
  "INPUT.TOTAL_DATE": 'Total',
  "INPUT.LABEL.CALCULATION": 'Kalkulasi',
  "INPUT.AMOUNT_ITEM": 'Jumlah',
  "INPUT.PRICE_ITEM": 'Harga',
  "INPUT.TOTAL_ITEM": 'Total',
  "INPUT.LINKMEMBER": 'Link Member',
  "INPUT.CUSTOMLOAD": 'Custom Load',
}

const TableMain = {
  "TABLE.HEADER.NAME": 'Nama',
  "TABLE.HEADER.USERNAME": 'Pengguna',
  "TABLE.HEADER.EMAIL": 'Email',
  "TABLE.HEADER.ACTION": 'Tindakan',
  "TABLE.ACTION.DETAIL": 'Detail',
  "TABLE.ACTION.EDIT": 'Ubah',
  "TABLE.ACTION.VIEW_PDF": 'Lihat PDF',
  "TABLE.ACTION.DELETE": 'Hapus',
}

const i18nPageAllMessages = {
  "TITLE": 'Sampel',
  "BREADCRUMB.SAMPLE": 'Sampel',
  ...InputLayout,
  ...TableMain
}

export default i18nPageAllMessages