const i18nSidebarAllMessages = {
  "DASHBOARD": 'Dasbor',
  "ORGANIZATION": 'Organisasi',
  "SAMPLE": 'Sampel',
  "SUB_SAMPLE": 'Sub Sampel',
  "SUPER_SAMPLE": 'Super Sampel',
  "SETTING": "Pengaturan",
  "DATA_MENU": "Data Menu",
  "DATA_ROLES": "Data Peran",
  "DATA_USERS": "Data Pengguna",
  "LABEL.OPTION": "Opsi"
}

export default i18nSidebarAllMessages