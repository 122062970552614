import {
  DetailWrapper,
  PdfWrapper,
  MainWrapper,
  TambahWrapper,
  UbahWrapper,
} from '../../../../pages/pengawasan-internal/penyusunan-rencana-progja-tingkat-tim-audit/alokasi-anggaran/MainWrapper'
import {DataRoutesProps} from '../../../UserRolesValidation'

const AlokasiAnggaranRoutes: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'pdf/:id',
    elements: <PdfWrapper />,
    subroute: [],
  },
]

export {AlokasiAnggaranRoutes}
