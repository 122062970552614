import {ProfilHeader, ProfilWrapper} from '../../../pages/profil/MainWrapper'
import {MainWrapper as EditProfilWrapper} from '../../../pages/profil/edit/MainWrapper'
import {ProfilKeuanganWrapper} from '../../../pages/profil/keuangan/MainWrapper'
import {ProfilTugasWrapper} from '../../../pages/profil/tugas/MainWrapper'
import {DataRoutesProps} from '../../UserRolesValidation'
import SuratTugas from '../akun/tugas/SuratTugas'

const Tugas: DataRoutesProps[] = [
  {
    title: 'Surat Tugas',
    icon: '',
    subroute: [],
    route: '*',
    elements: <ProfilTugasWrapper />,
  },
]

const MainRoutes: DataRoutesProps = {
  onlyRoute: true,
  hasHeader: true,
  title: 'Profil',
  icon: 'element-10',
  route: 'profil',
  elements: <ProfilHeader />,
  subroute: [
    {
      title: '',
      icon: '',
      subroute: [],
      route: 'info',
      elements: <ProfilWrapper />,
    },
    {
      title: '',
      icon: '',
      subroute: [],
      route: 'tugas',
      elements: <ProfilTugasWrapper />,
    },
    {
      title: '',
      icon: '',
      subroute: SuratTugas,
      route: 'surat_tugas',
      elements: <></>,
    },
    {
      title: '',
      icon: '',
      subroute: [],
      route: 'keuangan',
      elements: <ProfilKeuanganWrapper />,
    },
    {
      title: '',
      icon: '',
      subroute: [],
      route: 'edit',
      elements: <EditProfilWrapper />,
    },
  ],
}

export default MainRoutes
