import {DataRoutesProps} from '../../../../UserRolesValidation'
import {
  MainWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
  UraianTargeBulanan,
  UraianImplementasiBulanan,
} from '../../../../../pages/pengawasan-internal/pelaporan-pengawasan-internal/implementasi-pakta-integritas/form-evaluasi/komitmen-kerja/indikator/MainWrapper'

const Indikator: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:indikator_id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:indikator_id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'uraian-target-bulanan/:indikator_id',
    elements: <UraianTargeBulanan />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'uraian-implementasi-bulanan/:indikator_id',
    elements: <UraianImplementasiBulanan />,
    subroute: [],
  },
]

export {Indikator}
