import {DataRoutesProps} from '../../UserRolesValidation'

const PengelolaanKehumasan: DataRoutesProps = {
  title: 'Pengelolaan Kehumasan',
  icon: 'element-10',
  route: 'pengelolaan_kehumasan',
  elements: <></>,
  subroute: [
    {
      title: 'Pendokumentasian Kegiatan',
      icon: 'element-10',
      route: 'pendokumentasi_kegiatan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pelaksanaan Publikasi',
      icon: 'element-10',
      route: 'pelaksanaan_publikasi',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pelaksanaan Keterbukaan Informasi Publik',
      icon: 'element-10',
      route: 'pelaksanaan_keterbukaan_informasi_publik',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penyiapan Bahan Koordinasi',
      icon: 'element-10',
      route: 'penyiapan_bahan_koordinasi',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default PengelolaanKehumasan
