/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, requestPassword} from '../core/_requests'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2'
import {useDispatch, useSelector} from 'react-redux'
import {post} from '../../../redux/authSlice'
import ReactIcon from '../../../helper/ReactIcon'
import {generateCaptcha} from '../../../helper/AppFunction'

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  cpassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  captcha: Yup.number().required('Captcha is required'),
})
const initialValues = {
  password: '',
  cpassword: '',
  captcha: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function ResetPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authData = useSelector((state: any) => state.auth.forgotPw)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showCPassword, setShowCPassword] = useState<boolean>(false)
  const [captchaData, setCaptchaData] = useState<any>(generateCaptcha())
  const {saveAuth, setCurrentUser, currentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        if (parseInt(values.captcha) === parseInt(captchaData.sum)) {
          requestPassword({email: authData?.email, password: values.password})
              .then(({data: {result}}) => {
                setLoading(false)
                Swal.fire({
                  icon: 'success',
                  title: 'Berhasil mengubah password, silahkan untuk login kembali',
                })
                navigate('/auth', {replace: true})
              })
        } else {
          throw 'Captcha not valid!'
        }
      } catch (error) {
        showAlert(parseInt(values.captcha) !== parseInt(captchaData.sum))
      }
      setLoading(false)
      setCaptchaData(generateCaptcha())
    },
  })

  const showAlert = (captchaErr: boolean = false) => {
    Swal.fire({
      icon: 'error',
      title: 'Gagal Login',
      text: captchaErr
        ? 'Captcha tidak sama. Silahkan coba lagi.'
        : 'Email atau password salah. Silahkan coba lagi.',
    })
  }

  useEffect(() => {
    console.log('load!')
    if (authData?.email) {
    } else if (!authData?.email) {
      navigate('/auth', {replace: true})
    }
    return () => {}
  }, [authData])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Silahkan ubah password anda</div>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <div className='input-group mb-5'>
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {/* <button
            type='button'
            className='input-group-text btn btn-transparent px-4'
            onClick={() => {
              setShowPassword(!showPassword)
            }}
          >
            <ReactIcon
              icon={showPassword ? 'RiEyeLine' : 'RiEyeCloseLine'}
              props={{className: 'fs-2'}}
            />
          </button> */}
        </div>
        <div className='form-check d-flex align-items-center gap-2 mb-5'>
          <input
            id={`check-password`}
            type='checkbox'
            className='form-check-input form-check-input-lg cursor-pointer h-25px w-25px'
            checked={showPassword || false}
            onChange={(val) => {
              setShowPassword(val.target.checked)
            }}
          />
          <label
            className='form-check-label text-dark cursor-pointer user-select-none'
            htmlFor={`check-password`}
          >
            Lihat Password
          </label>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Konfimasi Password</label>
        <div className='input-group mb-5'>
          <input
            type={showCPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('cpassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid':
                  formik.touched.cpassword &&
                  formik.errors.cpassword &&
                  formik.getFieldProps('password').value !==
                    formik.getFieldProps('cpassword').value,
              },
              {
                'is-valid':
                  formik.touched.cpassword &&
                  !formik.errors.cpassword &&
                  formik.getFieldProps('password').value ===
                    formik.getFieldProps('cpassword').value,
              }
            )}
          />
          {/* <button
            type='button'
            className='input-group-text btn btn-transparent px-4'
            onClick={() => {
              setShowCPassword(!showCPassword)
            }}
          >
            <ReactIcon
              icon={showCPassword ? 'RiEyeLine' : 'RiEyeCloseLine'}
              props={{className: 'fs-2'}}
            />
          </button> */}
        </div>
        <div className='form-check d-flex align-items-center gap-2 mb-5'>
          <input
            id={`check-cpassword`}
            type='checkbox'
            className='form-check-input form-check-input-lg cursor-pointer h-25px w-25px'
            checked={showCPassword || false}
            onChange={(val) => {
              setShowCPassword(val.target.checked)
            }}
          />
          <label
            className='form-check-label text-dark cursor-pointer user-select-none'
            htmlFor={`check-cpassword`}
          >
            Lihat Password
          </label>
        </div>
        {((formik.touched.cpassword && formik.errors.cpassword) ||
          formik.getFieldProps('password').value !== formik.getFieldProps('cpassword').value) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                {formik.getFieldProps('password').value !== formik.getFieldProps('cpassword').value
                  ? 'Kedua password tidak sama'
                  : formik.errors.cpassword}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-6'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Captcha</label>
        <div className='input-group'>
          <span className='input-group-text btn btn-transparent px-4'>{`${captchaData.num1} + ${captchaData.num2} =`}</span>
          <input
            autoComplete='off'
            {...formik.getFieldProps('captcha')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.captcha && formik.errors.captcha,
              },
              {
                'is-valid': formik.touched.captcha && !formik.errors.captcha,
              }
            )}
          />
        </div>
        {formik.touched.captcha && formik.errors.captcha && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.captcha}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={
            formik.isSubmitting ||
            !formik.isValid ||
            formik.getFieldProps('password').value !== formik.getFieldProps('cpassword').value
          }
        >
          {!loading && <span className='indicator-label'>Ubah Password</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
