import {FC} from 'react'
import {WithChildren} from '../../_metronic/helpers'

interface ReactLoadingProps {
  error?: boolean
  loading?: boolean
  title?: string
  hideTitle?: boolean
  loadingProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

const ReactLoading: FC<ReactLoadingProps & WithChildren> = ({
  error = false,
  loading = true,
  hideTitle = false,
  title = 'Memuat Data...',
  children,
  loadingProps,
}) => {
  return (
    <>
      {loading || error ? (
        <div
          className='p-4 fs-6 d-flex flex-column justify-content-center align-items-center'
          {...loadingProps}
        >
          <div className='fs-3 fw-bold'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
          {!hideTitle && <div>{title}</div>}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default ReactLoading
