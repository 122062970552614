import {FC, Fragment} from 'react'
import {Document, Page, View, Text, Image} from '@react-pdf/renderer'
import {
  PdfHeaderApp,
  PdfSignatureBuilder,
  PdfStyle,
  PdfStylePallete,
} from '../../../../helper/ReactPdf'
import {
  AlphabetIndex,
  ConvertCurrency,
  NullProof,
  currencyToWords,
  capitalizeEachWord,
} from '../../../../helper/AppFunction'
import {formatDate} from '../../../../helper/DateFns'
import {PdfTableColumnBuilder} from '../../../../helper/ReactPdf'
import { count, log } from 'console'

export type PdfLayoutDataProps = {
  image: any
  perusahaan: {
    logo: string
    nama: string
    alamat: string
    telepon: string
    fax: string
    website: string
  }
  data: any
}

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  let jumlahTotalDibayar = 0
  let jumlahTotalPengeluaran = 0
  let JumlahTotalSelisih = jumlahTotalDibayar - jumlahTotalPengeluaran
  const filterProvinsi = (provinsi: string) => {
    if (provinsi.includes('DKI' || 'D.I')) {
      const words = provinsi.split(' ')
      words[1] = capitalizeEachWord(words[1])
      return words.join(' ')
    }else{
      return capitalizeEachWord(provinsi)
    }
  }
  const formatSelisihKurang = (input: string) => {
    const match = input.match(/\d[\d,.]*/);
    if (match) {
      const numberPart = match[0]; 
      const formattedNumber = new Intl.NumberFormat('id-ID').format(Number(numberPart.replace(/[,.]/g, '')));
      return input.replace(numberPart, `(${formattedNumber})`);
    }
    return input;
  }
  const total = (data: any, param: string) => {
        const uang_muka = data ? data.reduce((a: any, b: any) => a + Number(b[param]), 0) || 0 : 0
        return Number(uang_muka) 
  }
  const countTotal = (data: any, param: string) => {
      if(data){
        const uang_muka = data.reduce((a: any, b: any) => a + Number(b[param]), 0) || 0
        return uang_muka <= 0 ? null : ConvertCurrency(uang_muka)
      }
  }
  const countSelisih = (dibayar:{data: any, param: string}, pengeluaran: number) => {
    const totalDibayar = dibayar.data ? dibayar.data.reduce((a: any, b: any) => a + b[dibayar.param], 0) || 0 : 0
    const totalPengeluaran = Number(pengeluaran) || 0
    const selisih = totalDibayar - totalPengeluaran
    return selisih < 0 ? formatSelisihKurang(ConvertCurrency(Math.abs(selisih))) : ConvertCurrency(selisih)
  }
  const cekIsUangNull = (uang: any) => {
    return uang ? Number(uang) : 0
  }
  const Header = () => {
    return (
      <>
        <View
          style={{
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: PdfStylePallete.typography.label.medium,
            }}
          >
            VERIFIKASI RINCIAN RIIL BIAYA PERJALANAN DINAS
          </Text>
          <Text style={{fontSize: PdfStylePallete.typography.label.small}}>
            Nama Kegiatan : {NullProof({input: data.data, params: 'nama_kegiatan'})}
          </Text>
        </View>
        <Text style={{textAlign: 'left'}}>Nomor : {NullProof({input:data.data, params:'nomor_surat'})}</Text>
      </>
    )
  }

  const TableHeader = () => {
    const ColumnTable = (props: {title: string; width: string}) => {
      return (
        <Text
          style={{
            ...PdfStylePallete.table.col,
            borderStyle: 'solid',
            width: props.width,
            textAlign: 'center',
            fontWeight: 600,
          }}
        >
          {props.title}
        </Text>
      )
    }
    return (
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <ColumnTable title='No' width='5%' />
        <ColumnTable title='NAMA' width='15%' />
        <ColumnTable title='TUJUAN' width='10%' />
        <ColumnTable title='JENIS PENGELUARAN' width='22%' />
        <ColumnTable title='DIBAYAR SEMULA' width='13%' />
        <ColumnTable title='PENGELUARAN RILL' width='15%' />
        <ColumnTable title='SELISIH LEBIH/(KURANG)' width='15%' />
        <ColumnTable title='KET' width='5%' />
      </View>
    )
  }

  const TableContent = ({
    child,
    custom,
    isBorder = true,
    isJumlah = false,
  }: {
    child?: any
    custom?: any
    isBorder?: boolean
    isJumlah?: boolean
  }) => {
    return (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          borderBottom: isBorder ? '1px solid black' : 'none',
        }}
      >
        {custom ? (
          custom
        ) : (
          <>
            <PdfTableColumnBuilder style={{flexDirection: 'row', alignItems:'center' ,width: '33.90%', borderRight: '1px solid black'}}>
              <Text style={{ fontWeight: isJumlah ? 600 : 'normal' }}>{child.jenis}</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder style={{flexDirection: 'row', alignItems:'center' ,width: '20.10%', borderRight: '1px solid black'}}>
              <Text style={{ textAlign:'right', width:'100%'}}>{child.dibayar}</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder style={{flexDirection: 'row', alignItems:'center' ,width: '23%', borderRight: '1px solid black'}}>
              <Text style={{ textAlign:'right', width:'100%'}}>{child.pengeluaran}</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder style={{flexDirection: 'row', alignItems:'center' ,width: '23%'}}>
              <Text style={{ textAlign:'right', width:'100%'}}>{child.selisih}</Text>
            </PdfTableColumnBuilder>
          </>
        )}
      </View>
    )
  }

  return (
    <Document title='Dokumen'>
      <Page
        size={{width: '900'}}
        style={{...PdfStyle.page, gap: 0, paddingVertical: '40px', fontFamily: 'Times New Roman'}}
      >
        <Header />
        <View
          style={{
            marginHorizontal: 'auto',
            width: '100%',
            fontFamily: 'Times New Roman',
            justifyContent: 'center',
            letterSpacing: '0.5px',
            fontSize: PdfStylePallete.typography.label.small,
            border: '1px solid black',
          }}
        >
          {/* Table Header */}
          <TableHeader />
          {/* Table Content */}
          {NullProof({input: data.data, params: 'bukti_perjalanan', isMap: true}).map(
            (item: any, i: number) => {
              const uangTransportKantor = item.transportasi_data ? item.transportasi_data.filter((l:any) => l.dibayar_oleh === 'Kantor'): []
              const totalDibayar = total(item.transfer, 'uang_harian')+total(item.transfer, 'hotel')+total(item.transfer, 'uang_representatif')+total(item.transfer, 'transport')+total(uangTransportKantor, 'total')+total(item.transfer, 'bst_kantor')+total(item.transfer, 'bst_lokasi')
              const totalPengeluraran = cekIsUangNull(item.uang_harian_total)+total(item.uang_penginapan_data, 'total')+cekIsUangNull(item.uang_representatif_total)+total(item.transportasi_data, 'total')+cekIsUangNull(item.bst_kantor)+cekIsUangNull(item.bst_lokasi)+total(item.kwitansi_lain_lain_list, 'total')
              const selisih = totalDibayar - totalPengeluraran
              console.log(total(item.transfer, 'uang_harian'), 'uang harian');
              console.log(total(item.transfer, 'hotel'), 'hotel');
              console.log(total(item.transfer, 'uang_representatif'), 'uang representatif');
              console.log(total(item.transfer, 'transport'), 'transport');
              console.log(total(uangTransportKantor, 'total'), 'transport kantor');
              console.log(total(item.transfer, 'bst_kantor'), 'bst kantor');
              console.log(total(item.transfer, 'bst_lokasi'), 'bst lokasi');
              console.log('totalDibayar', totalDibayar);
              jumlahTotalDibayar += totalDibayar
              jumlahTotalPengeluaran += totalPengeluraran
              JumlahTotalSelisih = jumlahTotalDibayar - jumlahTotalPengeluaran
              return (
                <View
                  key={i}
                  style={{
                    flexDirection: 'row',
                    borderBottom: '1px solid black',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '5%',
                      borderRight: '1px solid black',
                    }}
                  >
                    <Text>{i + 1}</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      gap: '5px',
                      width: '15%',
                      borderRight: '1px solid black',
                    }}
                  >
                    <Text>{capitalizeEachWord(NullProof({input:item, params:'pegawai.nama'}))}</Text>
                    <Text>HP = {NullProof({input:item, params:'uang_harian_hari'})}</Text>
                    <Text>Verifikator = {NullProof({input:item, params:'verifikator.nip'})}</Text>
                    <Text>Tgl Realisasi = {NullProof({input: item, params: 'tanggal_realisasi_data', isMap: true})?.map((l: any, index: number) => {
                        return `${index === 0 ? ' ' : index === item.tanggal_realisasi_data.length - 1 ? `${index !== 1 ? ',' : ''} dan `: ', '}${NullProof({input: l, params: 'tanggal_mulai', type: 'date'})} s/d ${NullProof({input: l, params: 'tanggal_selesai', type: 'date'})}`
                      })}</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '10%',
                      borderRight: '1px solid black',
                      textAlign: 'center',
                    }}
                  >
                    {NullProof({input: data.data, params: 'tujuan', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        {l.tipe_tujuan !== 'Luar negeri' ? 
                        <>
                          <Text>
                            ({filterProvinsi(NullProof({input: l, params: 'provinsi', label: 'provinsi'}))})
                          </Text>
                          <Text>
                            {/* {NullProof({
                                input: l,
                                params: 'satker.nama_satker',
                                label: 'satker',
                              })}
                            {', '} */}
                            {/* {!l.kota2 && l.satker_2.nama_satker && (
                              <Text>
                                {NullProof({
                                    input: l,
                                    params: 'satker_2.nama_satker',
                                    label: 'satker2',
                                  })}
                                {', '}
                              </Text>
                            )} */}
                            {/* {!l.kota3 && l.satker_3.nama_satker && (
                              <Text>
                                {NullProof({
                                    input: l,
                                    params: 'satker_3.nama_satker',
                                    label: 'satker3',
                                  })}
                                {', '}
                              </Text>
                            )} */}
                            {capitalizeEachWord(
                              NullProof({input: l, params: 'kota', label: 'kota'})?.toLowerCase()
                            )}
                          </Text>
                          {l.kota2 && NullProof({input: l, params: 'kota2'}) !== '' && (
                            <Text>
                              {l.kota3 ? ', ' : ' dan '}
                              {/* {NullProof({
                                  input: l,
                                  params: 'satker_2.nama_satker',
                                  label: 'satker2',
                                })}
                              {', '} */}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'kota2',
                                  label: 'kota2',
                                })?.toLowerCase()
                              )}{' '}
                            </Text>
                          )}
                          {l.kota3 && NullProof({input: l, params: 'kota3'}) !== '' && (
                            <Text>
                              dan{' '}
                              {/* {NullProof({
                                  input: l,
                                  params: 'satker_3.nama_satker',
                                  label: 'satker3',
                                })}
                              {', '} */}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'kota3',
                                  label: 'kota3',
                                })?.toLowerCase()
                              )}
                            </Text>
                          )}
                        </>
                        : 
                        <View
                          style={{
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Text>
                            {capitalizeEachWord(NullProof({input: l, params: 'kota', label: 'kota'}))}
                            {', Negara'}
                            {capitalizeEachWord(NullProof({input: l, params: 'negara', label: 'negara'}))}
                          </Text>
                        </View>
                      }
                      </Fragment>
                    )
                  )}
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '65%',
                      borderRight: '1px solid black',
                    }}
                  >
                    <TableContent child={{
                      jenis: 'Uang Harian', 
                      dibayar: countTotal(item.transfer, 'uang_harian'), 
                      pengeluaran: item.uang_harian_total ? NullProof({input:item, params:'uang_harian_total', type:'currency'}) : null,
                      selisih: countSelisih({data: item.transfer, param: 'uang_harian'}, item.uang_harian_total) 
                      }}
                    />
                    <TableContent child={{
                      jenis: 'Hotel', 
                      dibayar: countTotal(item.transfer, 'hotel'), 
                      pengeluaran: countTotal(item.uang_penginapan_data, 'total'),
                      selisih: countSelisih({data: item.transfer, param: 'hotel'},  total(item.uang_penginapan_data, 'total'))
                      }} 
                    />
                    <TableContent child={{
                      jenis: 'Uang Representatif', 
                      dibayar: countTotal(item.transfer, 'uang_representatif'),
                      pengeluaran: item.uang_representatif_total ? NullProof({input:item, params:'uang_representatif_total', type:'currency'}) : null,
                      selisih: countSelisih({data: item.transfer, param: 'uang_representatif'}, item.uang_representatif_total)
                      }} 
                    />
                    <TableContent
                      custom={
                        <PdfTableColumnBuilder>
                          <Text style={{fontWeight: 600}}>Transport</Text>
                        </PdfTableColumnBuilder>
                      }
                    />
                    {total(item.transfer, 'transport') > 0 &&
                      <TableContent child={{
                        jenis: 'Uang Transport',
                        dibayar: countTotal(item.transfer, 'transport'),
                        // pengeluaran: countTotal(uangTransport, 'total'),
                        // selisih: countSelisih({data: item.transfer, param: 'transport'}, total(uangTransport, 'total'))
                        }} />
                    }
                    {NullProof({input: item, params: 'transportasi_data', isMap: true})?.map((l: any, index: number) => {
                      const jenis = ['PP', 'DKI'].some((x) => x === l.jenis) ?  (NullProof({input: l, params: 'jenis'})) : capitalizeEachWord(NullProof({input: l, params: 'jenis'}))
                      const isDibayarKantor = l.dibayar_oleh === 'Kantor' ? '(dibayar semula)' : ''
                      // const transport_dibayar_kantor = l.dibayar_oleh === 'Kantor' ? countTotal(item.transfer, 'transport_dibayar_kantor') : null
                      return (
                        <Fragment key={index}>
                          <TableContent 
                            child={{
                              jenis: `${capitalizeEachWord(NullProof({input: l, params:'asal'}))} - ${capitalizeEachWord(NullProof({input:l, params: 'tujuan'}))} (${jenis}) ${isDibayarKantor}`, 
                              // dibayar: transport_dibayar_kantor 
                              dibayar: l.dibayar_oleh === 'Kantor' ?  NullProof({input:l, params:'total', type:'currency'}) : null, 
                              pengeluaran: NullProof({input:l, params:'total', type:'currency'})}} 
                          />
                        </Fragment>
                      )
                    })}
                    <TableContent child={{
                      jenis: 'B/S/T-kantor (PP)', 
                      dibayar: countTotal(item.transfer, 'bst_kantor'),
                      pengeluaran: item.bst_kantor ? NullProof({input:item, params: 'bst_kantor', type:'currency'}) : null, 
                      selisih: countSelisih({data: item.transfer, param: 'bst_kantor'}, item.bst_kantor)
                      }} 
                    />
                    <TableContent child={{
                      jenis: 'B/S/T-Lokasi (PP)', 
                      pengeluaran: item.bst_lokasi ? NullProof({input:item, params: 'bst_lokasi', type:'currency'}):null, 
                      selisih: countSelisih({data: item.transfer, param: 'bst_lokasi'}, item.bst_lokasi)
                      }} 
                    />
                    {NullProof({input: item, params: 'kwitansi_lain_lain_list', isMap: true})?.map((ll: any, ii: number) => {
                      return(
                        <Fragment key={ii}>
                          <TableContent child={{jenis: capitalizeEachWord(NullProof({input:ll, params:'deskripsi'})), pengeluaran: ConvertCurrency(NullProof({input:ll, params:'total'}))}} />
                        </Fragment>
                      )
                    })}
                    <TableContent 
                      child={{
                        jenis: 'Jumlah', 
                        dibayar:ConvertCurrency(totalDibayar),
                        pengeluaran: ConvertCurrency(totalPengeluraran),
                        selisih: selisih < 0 ? formatSelisihKurang(ConvertCurrency(Math.abs(selisih))) : ConvertCurrency(selisih)
                      }} 
                      isJumlah={true} 
                      isBorder={false}
                    />
                  </View>
                  <View style={{display: 'flex', flexDirection: 'column', width: '5%'}}></View>
                </View>
              )
            }
          )}
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{ 
                width: '52%',
                flexDirection: 'row',
                alignItems: 'center',
                borderRight: '1px solid black',
               }}
            >
              <Text 
                style={{
                  fontWeight: 600, 
                  textTransform: 'uppercase', 
                  fontSize: PdfStylePallete.typography.label.medium
                }}
              >
                Jumlah Total
              </Text>
            </View>
            <View
              style={{ 
                width: '13%',
                textAlign: 'right',
                borderRight: '1px solid black',
               }}
            >
              <Text>{ConvertCurrency(jumlahTotalDibayar)}</Text>
            </View>
            <View
              style={{ 
                width: '15%',
                textAlign: 'right',
                borderRight: '1px solid black',
               }}
            >
              <Text>{ConvertCurrency(jumlahTotalPengeluaran)}</Text>
            </View>
            <View
              style={{ 
                width: '15%',
                textAlign: 'right',
                borderRight: '1px solid black',
               }}
            >
              <Text>{(JumlahTotalSelisih) < 0 ? formatSelisihKurang(ConvertCurrency(Math.abs(JumlahTotalSelisih))) : ConvertCurrency(JumlahTotalSelisih)}</Text>
            </View>
          </View>
        </View>
        <View>
               <View 
                style={{ 
                  flexDirection: 'column',
                  marginLeft: '65%'
                 }}
               >
                <Text>Jakarta, {formatDate({date: new Date().toString(), dateFormat:'dd MMMM yyyy'})}</Text>
                <Text style={{marginBottom: '50px'}}>Pejabat Pembuat Komitmen</Text>
                <Text>Anggun Wibisono</Text>
                <Text>NIP. 197905182008011010</Text>
               </View>
        </View>
      </Page>
    </Document>
  )
}

export {PdfLayout}
