import {FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {DataRoutesProps, RoutesMenuBuilder, hasAccessRoutes} from '../helper/UserRolesValidation'
import {Routes as DataRoutes} from '../helper/routes/index'
import {useSelector} from 'react-redux'
import {useAuth} from '../modules/auth'

const whitelistRoutes = [
  'profil/info',
  'profil/tugas',
  'profil/keuangan',
  'profil/edit',
  'pengawasan/persiapan/form_usulan/detail/:id/anggota-pdf',
]

const RoutesMenuWrapper: FC<{data: DataRoutesProps[]; parent?: string}> = ({data, parent = ''}) => {
  const {auth} = useAuth()
  const roleId = auth?.data?.role_id
  const routeData = useSelector((state: any) => state.route.value)
  let children: any = []
  data.map((l, i) => {
    if (l.subroute.length > 0) {
      const base = l.hasHeader ? (
        <Route
          key={i}
          path={`${l.route}/*`}
          element={
            <>
              {l.elements}
              <RoutesMenuWrapper data={l.subroute} parent={`${parent}/${l.route}`} />
            </>
          }
        />
      ) : (
        <Route
          key={i}
          path={`${l.route}/*`}
          element={<RoutesMenuWrapper data={l.subroute} parent={`${parent}/${l.route}`} />}
        />
      )
      const searchPattern = `${
        parent ? `${parent}/${l.route}`.substring(1).replaceAll('/*', '') : l.route
      }`
      children.push(base)
    } else {
      const base = <Route key={i} path={l.route} element={l.elements} />
      const searchPattern = `${parent}/${l.route}`.substring(1).replaceAll('/*', '')
      if (
        searchPattern !== '#' &&
        hasAccessRoutes(
          [...routeData?.route, ...whitelistRoutes],
          searchPattern,
          roleId === 1,
          true
        )
      ) {
        children.push(base)
      }
    }
  })
  return <Routes>{children}</Routes>
}

const PrivateRoutes = () => {
  const data = RoutesMenuBuilder(DataRoutes)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/*' element={<RoutesMenuWrapper data={data} />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
