/* eslint-disable array-callback-return */
import {AlphabetIndex, NullProof} from '../../../helper/AppFunction'
import {PdfLayoutDataProps} from '../../../helper/ReactPdf'
import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

const ExcelLayout = (data: PdfLayoutDataProps) => {
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet 1')

    const columnWidths: any = {
      A: 30,
      B: 30,
      C: 30,
      D: 30,
      E: 15,
      F: 15,
      G: 15,
      H: 15,
      I: 15,
      J: 15,
      K: 15,
      L: 15,
      M: 15,
      N: 15,
    }

    for (const columnLetter in columnWidths) {
      if (columnWidths.hasOwnProperty(columnLetter)) {
        worksheet.getColumn(columnLetter).width = columnWidths[columnLetter]
      }
    }

    worksheet.mergeCells('A1:D1')
    worksheet.mergeCells('A2:D2')

    const borderStyle: Partial<ExcelJS.Borders> = {
      top: {style: 'thin', color: {argb: '000000'}},
      left: {style: 'thin', color: {argb: '000000'}},
      bottom: {style: 'thin', color: {argb: '000000'}},
      right: {style: 'thin', color: {argb: '000000'}},
    }

    const headerStyle: Partial<ExcelJS.Style> = {
      alignment: {horizontal: 'center', vertical: 'middle', wrapText: true},
      fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: '548FD2'}},
      font: {bold: true},
      border: {...borderStyle},
    }

    const symbolStyle: Partial<ExcelJS.Style> = {
      border: {...borderStyle},
      alignment: {vertical: 'middle', horizontal: 'center'},
      font: {color: {argb: 'D79829'}, size: 14},
    }

    const sektorStyle: Partial<ExcelJS.Style> = {
      fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FEBE30'}},
      border: {...borderStyle},
      alignment: {wrapText: true, vertical: 'top'},
    }

    const listDataStyle: Partial<ExcelJS.Style> = {
      fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: '16AF57'}},
      border: {...borderStyle},
      alignment: {wrapText: true, vertical: 'top'},
    }

    const titleStyle: Partial<ExcelJS.Style> = {
      font: {bold: true, size: 14},
      alignment: {vertical: 'middle'},
    }

    const title = worksheet.getCell('A1:D1')
    title.value = 'AGENDA PENGAWASAN PRIORITAS (APP)'
    title.style = titleStyle

    const name = worksheet.getCell('A2:D2')
    name.value = NullProof({input: data.data, params: 'nama'})
    name.style = titleStyle

    worksheet.getCell('E1').style = {
      fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: '16AF57'}},
    }
    worksheet.getCell('F1').value = 'Sektor'
    worksheet.getCell('E2').style = {
      fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FEBE30'}},
    }
    worksheet.getCell('F2').value = 'Tema'

    worksheet.getCell('G1').value = '⬤'
    worksheet.getCell('G1').style = {
      alignment: {horizontal: 'right'},
      font: {color: {argb: 'D79829'}, size: 14},
    }
    worksheet.getCell('H1').value = 'Koordinator'
    worksheet.getCell('G2').value = '▲'
    worksheet.getCell('G2').style = {
      alignment: {horizontal: 'right'},
      font: {color: {argb: 'D79829'}, size: 14},
    }
    worksheet.getCell('H2').value = 'Kontributor'

    const sektor = worksheet.getCell('A4:A5')
    sektor.value = '\nSektor/Tema/Topik\n'
    sektor.style = headerStyle
    worksheet.mergeCells('A4:A5')

    const informasi = worksheet.getCell('B4')
    informasi.value = 'Informasi Hasil Pengawasan (Insilwas)'
    informasi.style = headerStyle
    worksheet.mergeCells('B4:B5')

    const pengawasan = worksheet.getCell('C4:C5')
    pengawasan.value = '\nJenis Pengawasan\n'
    pengawasan.style = headerStyle
    worksheet.mergeCells('C4:C5')

    const uraian = worksheet.getCell('D4:D5')
    uraian.value = '\nJenis Uraian Pengawasan\n'
    uraian.style = headerStyle
    worksheet.mergeCells('D4:D5')

    const rencana = worksheet.getCell('E4:H4')
    rencana.value = '\nRencana Penyampaian Hasil Pengawasan\n'
    rencana.style = headerStyle
    worksheet.mergeCells('E4:H4')

    const tr1 = worksheet.getCell('E5')
    tr1.value = 'Tr I'
    tr1.style = headerStyle

    const tr2 = worksheet.getCell('F5')
    tr2.value = 'Tr II'
    tr2.style = headerStyle

    const tr3 = worksheet.getCell('G5')
    tr3.value = 'Tr III'
    tr3.style = headerStyle

    const tr4 = worksheet.getCell('H5')
    tr4.value = 'Tr IV'
    tr4.style = headerStyle

    const unit = worksheet.getCell('I4:N4')
    unit.value = 'Unit Pelaksana'
    unit.style = headerStyle
    worksheet.mergeCells('I4:N4')

    const sek = worksheet.getCell('I5')
    sek.value = 'Sek'
    sek.style = headerStyle

    const ir1 = worksheet.getCell('J5')
    ir1.value = 'Ir I'
    ir1.style = headerStyle

    const ir2 = worksheet.getCell('K5')
    ir2.value = 'Ir II'
    ir2.style = headerStyle

    const ir3 = worksheet.getCell('L5')
    ir3.value = 'Ir III'
    ir3.style = headerStyle

    const ir4 = worksheet.getCell('M5')
    ir4.value = 'Ir IV'
    ir4.style = headerStyle

    const ir5 = worksheet.getCell('N5')
    ir5.value = 'Ir-vest'
    ir5.style = headerStyle

    let rowIndex = 5

    await data.data.list_data.map(async (l: any, i: number) => {
      rowIndex++
      worksheet.getCell(`A${rowIndex}:D${rowIndex}`).value = `${AlphabetIndex(i, true)} ${l.judul}`
      worksheet.getCell(`A${rowIndex}:D${rowIndex}`).style = listDataStyle
      worksheet.mergeCells(`A${rowIndex}:D${rowIndex}`)
      worksheet.getCell(`E${rowIndex}`).style = listDataStyle
      worksheet.getCell(`F${rowIndex}`).style = listDataStyle
      worksheet.getCell(`G${rowIndex}`).style = listDataStyle
      worksheet.getCell(`H${rowIndex}`).style = listDataStyle
      worksheet.getCell(`I${rowIndex}`).style = listDataStyle
      worksheet.getCell(`J${rowIndex}`).style = listDataStyle
      worksheet.getCell(`K${rowIndex}`).style = listDataStyle
      worksheet.getCell(`L${rowIndex}`).style = listDataStyle
      worksheet.getCell(`M${rowIndex}`).style = listDataStyle
      worksheet.getCell(`N${rowIndex}`).style = listDataStyle
      await l.sektor.map(async (lsektor: any, isektor: number) => {
        rowIndex++
        worksheet.getCell(`A${rowIndex}:D${rowIndex}`).value = `${isektor + 1}. ${lsektor.judul}`
        worksheet.getCell(`A${rowIndex}:D${rowIndex}`).style = sektorStyle
        worksheet.mergeCells(`A${rowIndex}:D${rowIndex}`)
        worksheet.getCell(`E${rowIndex}`).style = sektorStyle
        worksheet.getCell(`F${rowIndex}`).style = sektorStyle
        worksheet.getCell(`G${rowIndex}`).style = sektorStyle
        worksheet.getCell(`H${rowIndex}`).style = sektorStyle
        worksheet.getCell(`I${rowIndex}`).style = sektorStyle
        worksheet.getCell(`J${rowIndex}`).style = sektorStyle
        worksheet.getCell(`K${rowIndex}`).style = sektorStyle
        worksheet.getCell(`L${rowIndex}`).style = sektorStyle
        worksheet.getCell(`M${rowIndex}`).style = sektorStyle
        worksheet.getCell(`N${rowIndex}`).style = sektorStyle
        await lsektor.topik.map(async (ltopik: any, itopik: number) => {
          rowIndex++
          const topik = worksheet.getCell(`A${rowIndex}`)
          topik.value = `\n${AlphabetIndex(itopik)}. ${ltopik.judul}\n`
          topik.style = {
            border: {...borderStyle},
            alignment: {wrapText: true, vertical: 'top'},
          }
          await ltopik.pegawasan.map(async (lpegawasan: any, ipegawasan: number) => {
            if (ipegawasan !== 0) rowIndex++
            const pengawasan = worksheet.getCell(`B${rowIndex}`)
            pengawasan.value = `\n${ipegawasan + 1}). ${lpegawasan.judul}\n`
            pengawasan.style = {
              border: {...borderStyle},
              alignment: {wrapText: true, vertical: 'top'},
            }
            if (lpegawasan.jenis_pengawasan.length === 0) {
              worksheet.getCell(`C${rowIndex}`).style = {border: {...borderStyle}}
              worksheet.getCell(`D${rowIndex}`).style = {border: {...borderStyle}}
              worksheet.getCell(`E${rowIndex}`).style = {border: {...borderStyle}}
              worksheet.getCell(`F${rowIndex}`).style = {border: {...borderStyle}}
              worksheet.getCell(`G${rowIndex}`).style = {border: {...borderStyle}}
              worksheet.getCell(`H${rowIndex}`).style = {border: {...borderStyle}}
              worksheet.getCell(`I${rowIndex}`).style = {border: {...borderStyle}}
              worksheet.getCell(`K${rowIndex}`).style = {border: {...borderStyle}}
              worksheet.getCell(`L${rowIndex}`).style = {border: {...borderStyle}}
              worksheet.getCell(`M${rowIndex}`).style = {border: {...borderStyle}}
              worksheet.getCell(`N${rowIndex}`).style = {border: {...borderStyle}}
            }
            await lpegawasan.jenis_pengawasan.map((ljenis: any, ijenis: number) => {
              const jenis = worksheet.getCell(`C${rowIndex}`)
              jenis.style = {
                border: {...borderStyle},
                alignment: {wrapText: true, vertical: 'top'},
              }
              jenis.value = ljenis.jenis ? `\n${ljenis.jenis}\n` : '\n-\n'
              const uraian = worksheet.getCell(`D${rowIndex}`)
              uraian.style = {
                border: {...borderStyle},
                alignment: {wrapText: true, vertical: 'top'},
              }
              uraian.value = ljenis.judul ? `\n${ljenis.judul}\n` : '\n-\n'
              worksheet.getCell(`E${rowIndex}`).value = ljenis.triwulan_1 ? '⬤' : ''
              worksheet.getCell(`E${rowIndex}`).style = symbolStyle
              worksheet.getCell(`F${rowIndex}`).value = ljenis.triwulan_2 ? '⬤' : ''
              worksheet.getCell(`F${rowIndex}`).style = symbolStyle
              worksheet.getCell(`G${rowIndex}`).value = ljenis.triwulan_3 ? '⬤' : ''
              worksheet.getCell(`G${rowIndex}`).style = symbolStyle
              worksheet.getCell(`H${rowIndex}`).value = ljenis.triwulan_4 ? '⬤' : ''
              worksheet.getCell(`H${rowIndex}`).style = symbolStyle
              worksheet.getCell(`I${rowIndex}`).value =
                ljenis.sek === 'Koordinator' ? '⬤' : '▲' || ''
              worksheet.getCell(`I${rowIndex}`).style = symbolStyle
              worksheet.getCell(`J${rowIndex}`).value =
                ljenis.ir_1 === 'Koordinator' ? '⬤' : '▲' || ''
              worksheet.getCell(`J${rowIndex}`).style = symbolStyle
              worksheet.getCell(`K${rowIndex}`).value =
                ljenis.ir_2 === 'Koordinator' ? '⬤' : '▲' || ''
              worksheet.getCell(`K${rowIndex}`).style = symbolStyle
              worksheet.getCell(`L${rowIndex}`).value =
                ljenis.ir_3 === 'Koordinator' ? '⬤' : '▲' || ''
              worksheet.getCell(`L${rowIndex}`).style = symbolStyle
              worksheet.getCell(`M${rowIndex}`).value =
                ljenis.ir_4 === 'Koordinator' ? '⬤' : '▲' || ''
              worksheet.getCell(`M${rowIndex}`).style = symbolStyle
              worksheet.getCell(`N${rowIndex}`).value =
                ljenis.irvest === 'Koordinator' ? '⬤' : '▲' || ''
              worksheet.getCell(`N${rowIndex}`).style = symbolStyle
              if (ijenis !== lpegawasan.jenis_pengawasan.length - 1) rowIndex++
            })
          })
        })
      })
    })

    rowIndex = 5

    await data.data.list_data.map(async (l: any) => {
      rowIndex++
      await l.sektor.map(async (lsektor: any) => {
        rowIndex++
        await lsektor.topik.map(async (ltopik: any) => {
          rowIndex++
          let rowMerge = 0
          ltopik.pegawasan.map((item: any) => {
            rowMerge += item.jenis_pengawasan.length > 0 ? item.jenis_pengawasan.length : 1
          })
          if (rowMerge > 1) {
            worksheet.mergeCells(`A${rowIndex}:A${rowIndex + rowMerge - 1}`)
          }
          await ltopik.pegawasan.map(async (lpegawasan: any, ipegawasan: number) => {
            if (ipegawasan !== 0) rowIndex++
            if (lpegawasan.jenis_pengawasan.length > 1)
              worksheet.mergeCells(
                `B${rowIndex}:B${rowIndex + lpegawasan.jenis_pengawasan.length - 1}`
              )
            await lpegawasan.jenis_pengawasan.map((ljenis: any, ijenis: number) => {
              if (ijenis !== lpegawasan.jenis_pengawasan.length - 1) rowIndex++
            })
          })
        })
      })
    })

    const buffer = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buffer]), 'document.xlsx')
  }
  return downloadExcel
}

export default ExcelLayout
