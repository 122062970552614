import {DataRoutesProps} from '../../../UserRolesValidation'
import {FormTanggapanRoutes} from './audit/pelaksanaan-audit/FormTanggapan'
import {FormTemuanRoutes} from './audit/pelaksanaan-audit/FormTemuan'
import {FormTindakLanjutRoutes} from './audit/pelaksanaan-audit/FormTindakLanjut'
import {FormPkaRoutes} from './audit/pelaksanaan-audit/form-pka/FormPka'
import {FormKkaRoutes} from './audit/pelaksanaan-audit/FormKka'
import {PengendalianPenyusunanLaporanRoutes} from './audit/pelaporan-audit/PengendalianPenyusunanLaporan'
import {ReviuKonsepLaporanRoutes} from './audit/pelaporan-audit/ReviuKonsepLaporan'
import {ReviuSupervisiRoutes} from './audit/supervisi/ReviuSupervisi'
import {FormLaporanHarianRoutes} from './audit/pelaksanaan-audit/FormLaporanHarian'
import {RekapitulasiLaporanRoutes} from './audit/pelaksanaan-audit/RekapitulasiLaporan'
import BeritaAcaraNotulensi from './audit/pelaksanaan-audit/berita-acara-notulensi/BeritaAcaraNotulensi'
import {DaftarPengujianRoutes} from './audit/pelaporan-audit/DaftarPengujian'
import {KonsepLaporanHasilPengawasanRoutingSlipRoutes} from './audit/pelaporan-audit/KonsepLaporanHasilPengawasanRoutingSlip'

const PersiapanAudit: DataRoutesProps = {
  title: 'Persiapan Audit',
  icon: 'element-10',
  route: 'persiapan',
  elements: <></>,
  subroute: [
    {
      title: 'Rencana Audit',
      icon: 'element-10',
      route: 'rencana_audit',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Perencanaan Penugasan Audit',
      icon: 'element-10',
      route: 'perencanaan_petugas',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Anggaran Biaya',
      icon: 'element-10',
      route: 'anggaran_biaya',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Rekapitulasi Anggaran Biaya',
      icon: 'element-10',
      route: 'rekapitulasi_anggaran',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Bon Peminjaman Berkas',
      icon: 'element-10',
      route: 'bon_peminjaman_berkas',
      elements: <></>,
      subroute: [],
    },
  ],
}

const SupervisiAudit: DataRoutesProps = {
  title: 'Supervisi Audit',
  icon: 'element-10',
  route: 'supervisi',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Lembar Reviu Supervisi',
      icon: 'element-10',
      route: 'reviu_supervisi',
      elements: <></>,
      subroute: ReviuSupervisiRoutes,
    },
  ],
}

const PelaksanaanAudit: DataRoutesProps = {
  title: 'Pelaksanaan Audit',
  icon: 'element-10',
  route: 'pelaksanaan',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Form PKA',
      icon: 'element-10',
      route: 'form_pka',
      elements: <></>,
      subroute: FormPkaRoutes,
    },
    {
      isPage: true,
      title: 'Form KKA',
      icon: 'element-10',
      route: 'form_kka',
      elements: <></>,
      subroute: FormKkaRoutes,
    },
    {
      isPage: true,
      title: 'Form Temuan',
      icon: 'element-10',
      route: 'form_temuan',
      elements: <></>,
      subroute: FormTemuanRoutes,
    },
    {
      isPage: true,
      title: 'Form Tindak Lanjut',
      icon: 'element-10',
      route: 'form_tindak_lanjut',
      elements: <></>,
      subroute: FormTindakLanjutRoutes,
    },
    {
      isPage: true,
      title: 'Form Tanggapan',
      icon: 'element-10',
      route: 'form_tanggapan',
      elements: <></>,
      subroute: FormTanggapanRoutes,
    },
    {
      isPage: true,
      title: 'Form Laporan Harian',
      icon: 'element-10',
      route: 'form_laporan_harian',
      elements: <></>,
      subroute: FormLaporanHarianRoutes,
    },
    {
      isPage: true,
      title: 'Rekapitulasi Laporan',
      icon: 'element-10',
      route: 'rekapitulasi_laporan',
      elements: <></>,
      subroute: RekapitulasiLaporanRoutes,
    },
    {
      title: 'Berita Acara Notulensi',
      icon: 'element-10',
      route: 'berita_acara_notulensi',
      elements: <></>,
      subroute: BeritaAcaraNotulensi,
    },
    // {
    //   isPage: true,
    //   title: 'Laporan Mingguan Pengujian & Evaluasi',
    //   icon: 'element-10',
    //   route: 'laporan_mingguan_penguji',
    //   elements: <></>,
    //   subroute: [],
    // },
    // {
    //   isPage: true,
    //   title: 'Penyelesaian Pengujian & Evaluasi',
    //   icon: 'element-10',
    //   route: 'penyelesaian_penguji',
    //   elements: <></>,
    //   subroute: [],
    // },
  ],
}

const PelaporanAudit: DataRoutesProps = {
  title: 'Pelaporan Audit',
  icon: 'element-10',
  route: 'pelaporan',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Pengendalian Penyusunan Laporan',
      icon: 'element-10',
      route: 'pengendalian_penyusunan_laporan',
      elements: <></>,
      subroute: PengendalianPenyusunanLaporanRoutes,
    },
    {
      isPage: true,
      title: 'Reviu Konsep Laporan',
      icon: 'element-10',
      route: 'reviu_konsep_laporan',
      elements: <></>,
      subroute: ReviuKonsepLaporanRoutes,
    },
    {
      isPage: true,
      title: 'Check List Penyelesaian Laporan',
      icon: 'element-10',
      route: 'penyelesaian_laporan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Daftar Pengujian',
      icon: 'element-10',
      route: 'daftar_pengujian',
      elements: <></>,
      subroute: DaftarPengujianRoutes,
    },
    {
      isPage: true,
      title: 'Konsep Pelaporan Hasil Pengawasan (Routing Slip)',
      icon: 'element-10',
      route: 'routing_slip',
      elements: <></>,
      subroute: KonsepLaporanHasilPengawasanRoutingSlipRoutes,
    },
  ],
}

const PelaksanaanPenugasanAssurans: DataRoutesProps = {
  title: 'Pelaksanaan Penugasan Assurans',
  icon: 'element-10',
  route: 'pelaksanaan_penugasan_assurans',
  elements: <></>,
  subroute: [
    {
      title: 'Audit',
      icon: 'element-10',
      route: 'audit',
      elements: <></>,
      subroute: [
        {...PersiapanAudit},
        {...PelaksanaanAudit},
        {...SupervisiAudit},
        {...PelaporanAudit},
        // {...DataTambahan},
      ],
    },
    {
      isPage: true,
      title: 'Reviu PIPK',
      icon: 'element-10',
      route: 'reviu_pipk',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Reviu LK',
      icon: 'element-10',
      route: 'reviu_lk',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Reviu RKBMN',
      icon: 'element-10',
      route: 'reviu_rkbmn',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Reviu RKAKL Pagu Indikatif',
      icon: 'element-10',
      route: 'reviu_rkakl',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Reviu RKAKL Pagu Alokasi',
      icon: 'element-10',
      route: 'reviu_rkakl',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Reviu RKAKL Pagu Anggaran',
      icon: 'element-10',
      route: 'reviu_rkakl',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Reviu LKJ',
      icon: 'element-10',
      route: 'reviu_lkj',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Reviu Sakip',
      icon: 'element-10',
      route: 'reviu_sakip',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Tim Penilai Internal ZI, WBK, WBBM',
      icon: 'element-10',
      route: 'tim_penaiil_internal_zi_wbk_wbbm',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Reviu PMPRB',
      icon: 'element-10',
      route: 'reviu_pmprb',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penjaminan Kualitas SPIP Terintegrasi',
      icon: 'element-10',
      route: 'penjaminan_kualitas_spip_terintegrasi',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Reviu Revisi Anggaran',
      icon: 'element-10',
      route: 'reviu_revisi_anggaran',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Reviu PAPBJ',
      icon: 'element-10',
      route: 'reviu_papbj',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default PelaksanaanPenugasanAssurans
