import * as Yup from 'yup'
import {FormInputProps} from "../../../../../helper/FormInput";
import inputLayoutBuilder from '../../../../../helper/template/FormInputTemplate';

const formInputList: FormInputProps[] = [
    {
        className: 'col-12',
        name: 'tanggal_pemantauan',
        type: 'input',
        validator: Yup.string().required('Tanggal Pemantauan mohon untuk diisi.'),
        options: {
            input: {
                props: {
                    title: 'Tanggal Pemantauan',
                    placeholder: 'Tanggal Pemantauan',
                    type: 'date'
                }
            }
        }
    },
    {
        className: 'col-12',
        name: 'hasil_pemantauan',
        type: 'textarea',
        validator: Yup.string().required('Hasil Pemantauan mohon untuk diisi.'),
        options: {
            textarea: {
                useTextEditor: true,
                props: {
                    title: 'Hasil Pemantauan',
                    placeholder: 'Hasil Pemantauan',
                    rows: 3
                }
            }
        }
    },
    {
        className: 'col-12',
        name: 'saran_itjen',
        type: 'textarea',
        validator: Yup.string().required('Saran Itjen mohon untuk diisi.'),
        options: {
            textarea: {
                useTextEditor: true,
                props: {
                    title: 'Saran Itjen',
                    placeholder: 'Saran Itjen',
                    rows: 3
                }
            }
        }
    },
    {
      className: 'col-12',
      name: 'tindak_lanjut',
      type: 'textarea',
      validator: Yup.string().required('Status Tindak Lanjut mohon untuk diisi.'),
      options: {
          textarea: {
              useTextEditor: true,
              props: {
                  title: 'Status Tindak Lanjut',
                  placeholder: 'Status Tindak Lanjut',
                  rows: 3
              }
          }
      }
  },
    {
        className: 'col-12',
        name: 'pemantau',
        type: 'component',
        value: '',
        validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
        options: {
            input: {
                props: {
                    title: 'List Pemantau',
                    placeholder: 'List Pemantau'
                }
            }
        },
        component: [
          {
            className: 'col-12',
            name: 'pegawai_id',
            type: 'datalist',
            value: '',
            validator: Yup.number().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Nama Pegawai',
                  placeholder: 'Nama Pegawai',
                },
              },
              datalist: {
                api: '/kepegawaian/data-tenaga-audit',
                query: 'orderby=asc',
                params: 'nama,nip',
                result: (
                  <>
                    <div>
                      <span className='fw-bold'>Nama : </span>
                      <span>$nama</span>
                    </div>
                    <div>
                      <span className='fw-bold'>NIP : </span>
                      <span>$nip</span>
                    </div>
                  </>
                ),
                id: 'id',
                type: 'table',
                table: {
                  data: [
                    {id: 'nama', type: 'text'},
                    {id: 'nip', type: 'text'},
                    {id: 'level_jabatan', type: 'text'},
                    {id: 'satker_4', type: 'text'},
                    {id: 'totalSt', type: 'text'},
                    {id: 'jumlahST', type: 'text'},
                    {id: 'jumlahUsulan', type: 'text'},
                  ],
                  protect: '',
                  props: {
                    filter: [
                      ...inputLayoutBuilder({
                        input: [{template: 'checkbox', name: 'irjen', title: 'Pegawai Itjen'}],
                      }),
                    ],
                    route: {
                      url: '/kepegawaian/data-tenaga-audit',
                    },
                    headerData: [
                      {title: 'No.', props: {className: 'min-w-50px'}},
                      {title: 'Nama'},
                      {title: 'NIP'},
                      {title: 'Level Jabatan'},
                      {title: 'Satker'},
                      {title: 'Jumlah ST'},
                      {title: 'Jumlah ST (hari)'},
                      {title: 'Jumlah Usulan'},
                    ],
                    link: {
                      action: [
                        {
                          id: 'detail',
                          protect: 'st_pegawai',
                          title: 'Detail List ST Terbit',
                          route: 'detail/:id',
                          type: 'feedback',
                          // input: inputListKategori
                        },
                        {
                          id: 'detail',
                          protect: 'usulan_pegawai',
                          title: 'Detail List Usulan',
                          route: 'detail/:id',
                          type: 'feedback',
                          // input: inputListKategori
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
        ]
    }
]

export {formInputList}
