/* eslint-disable array-callback-return */
import {PdfLayoutDataProps} from '../../../../../../helper/ReactPdf'
import {
  BorderStyle,
  CellStyle,
  Workbook,
  createCellStyle,
  createWorkbook,
} from '../../../../../../helper/ReactExcelJs'
import {NullProof} from '../../../../../../helper/AppFunction'
import {formatDate} from '../../../../../../helper/DateFns'

const WorksheetRinci = (wb: Workbook, data: any) => {
  const themeColor = {
    ungu: '60497A',
    kuning: 'ffff00',
  }
  type themeStyleProps = {
    style: CellStyle
    border: BorderStyle
  }
  const themeStyle: themeStyleProps = {
    style: {
      backgroundColor: themeColor.ungu,
      color: themeColor.kuning,
      fontConfig: {
        wrap: true,
        alignment: {
          h: 'center',
        },
      },
    },
    border: {
      color: themeColor.kuning,
    },
  }
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({title: 'KKA RINCI'})

  const columnWidths: any = {
    A: 5,
    B: 15,
    C: 5,
    D: 10,
    E: 10,
    F: 10,
    G: 30,
    H: 20,
    I: 30,
    J: 30,
    K: 5,
    L: 25,
    M: 30,
  }

  setupWidth(columnWidths)
  const listDataKiri = [
    {
      title: 'Nama Auditi',
      value: NullProof({input: data.data, params: 'nama'}),
    },
    {
      title: 'Sasaran Auditi',
      value: NullProof({input: data.data, params: 'sasaran'}),
    },
    {
      title: 'Periode Auditi',
      value: NullProof({input: data.data, params: 'periode'}),
    },
    {
      title: 'Fungsi',
      value: NullProof({input: data.data, params: 'fungsi'}),
    },
    {
      title: 'Sub Fungsi',
      value: NullProof({input: data.data, params: 'subfungsi'}),
    },
    {
      title: 'Komponen/Sub',
      value: NullProof({input: data.data, params: 'komponen'}),
    },
    {
      title: 'Bobot',
      value: NullProof({input: data.data, params: 'bobot'}),
    },
  ]
  const listDataKanan = [
    {
      title: 'No. KKA',
      value: NullProof({input: data.data, params: 'no_kka'}),
    },
    {
      title: 'Ref. PKA No',
      value: NullProof({input: data.data, params: 'ref_pka'}),
    },
    {
      title: 'Disusun Oleh',
      value: NullProof({input: data.data, params: 'disusun_oleh'}),
    },
    {
      title: 'Tanggal/Paraf',
      value: formatDate({
        date: NullProof({input: data.data, params: 'tanggal_paraf'}),
        dateFormat: 'dd MMMM yyyy',
      }),
    },
    {
      title: 'Direviu Oleh',
      value: NullProof({input: data.data, params: 'direviu_oleh'}),
    },
    {
      title: 'Tanggal/Paraf',
      value: formatDate({
        date: NullProof({input: data.data, params: 'tanggal_review'}),
        dateFormat: 'dd MMMM yyyy',
      }),
    },
    {
      title: 'Disetujui Oleh',
      value: NullProof({input: data.data, params: 'disetujui_oleh'}),
    },
    {
      title: 'Tanggal/Paraf',
      value: formatDate({
        date: NullProof({input: data.data, params: 'tanggal_disetujui'}),
        dateFormat: 'dd MMMM yyyy',
      }),
    },
  ]
  //
  const judulCell = cell({id: 'A2:I2'})
  judulCell.value = 'Inspektorat Jenderal Kementrian Agama RI'
  const kkaCell = cell({id: 'A3:M3', options: {isMerged: true}})
  kkaCell.value = 'Kertas Kerja Audit'.toUpperCase()
  kkaCell.style = createCellStyle({
    backgroundColor: themeColor.ungu,
    color: themeColor.kuning,
    fontConfig: {alignment: {h: 'center'}, style: 'bold'},
  })
  listDataKiri.forEach((e, i) => {
    const cellNumber = 5 + i
    const targetCell = cell({id: `A${cellNumber}:B${cellNumber}`, options: {isMerged: true}})
    targetCell.value = e.title
    targetCell.style = createCellStyle({})
    cell({id: `C${cellNumber}`}).value = ':'
    cell({id: `D${cellNumber}`}).value = e.value
    cell({id: `C${cellNumber}`}).style = createCellStyle({})
    cell({id: `D${cellNumber}`}).style = createCellStyle({})
  })
  listDataKanan.forEach((e, i) => {
    const cellNumber = 5 + i
    const targetCell = cell({id: `J${cellNumber}`})
    targetCell.value = e.title
    targetCell.style = createCellStyle({})
    cell({id: `K${cellNumber}`}).value = ':'
    cell({id: `L${cellNumber}`}).value = e.value
    cell({id: `K${cellNumber}`}).style = createCellStyle({})
    cell({id: `L${cellNumber}`}).style = createCellStyle({})
  })
  const judulKkaCell = cell({id: 'A14:M14', options: {isMerged: true}})
  judulKkaCell.value = 'Pengukuran Kinerja Tugas Dan Fungsi'.toUpperCase()
  judulKkaCell.style = createCellStyle({
    fontConfig: {size: 16, style: 'bold', alignment: {h: 'center'}},
  })
  const deskripsiKkaCell = cell({id: 'A15:M15', options: {isMerged: true}})
  deskripsiKkaCell.style = createCellStyle({
    fontConfig: {alignment: {h: 'center'}},
  })
  //
  // Pengukuran Kinerja Tugas Dan Fungsi
  // Header
  cell({id: 'A16:A17', options: {isMerged: true}, value: 'No', ...themeStyle})
  cell({id: 'B16:D16', options: {isMerged: true}, value: 'Aspek Penilaian', ...themeStyle})
  cell({id: 'B17:C17', options: {isMerged: true}, value: 'Uraian', ...themeStyle})
  cell({id: 'D17', options: {isMerged: true}, value: 'Bobot\n(a)', ...themeStyle})
  cell({id: 'E16:F16', options: {isMerged: true}, value: 'Capaian Kinerja', ...themeStyle})
  cell({id: 'E17', options: {isMerged: true}, value: 'Nilai\n(b)', ...themeStyle})
  cell({id: 'F17', options: {isMerged: true}, value: 'Hasil\n(a x b)', ...themeStyle})
  cell({
    id: 'G16:G17',
    options: {isMerged: true},
    value: 'Penjelasan / Langkah Kerja',
    ...themeStyle,
  })
  cell({
    id: 'H16:H17',
    options: {isMerged: true},
    value: 'Dokumen Sumber',
    ...themeStyle,
  })
  cell({
    id: 'I16:I17',
    options: {isMerged: true},
    value: 'Kondisi Positif',
    ...themeStyle,
  })
  cell({
    id: 'J16:J17',
    options: {isMerged: true},
    value: 'Kondisi (Negatif) (Untuk selain kode CH)',
    ...themeStyle,
  })
  cell({
    id: 'K16:L17',
    options: {isMerged: true},
    value: 'Akibat (Untuk selain kode CH)',
    ...themeStyle,
  })
  cell({
    id: 'M16:M17',
    options: {isMerged: true},
    value: 'Sebab (Untuk selain kode CH)',
    ...themeStyle,
  })
  // Data Looping
}

const ExcelLayout = (data: PdfLayoutDataProps) => {
  const downloadExcel = async () => {
    const wb = createWorkbook()
    WorksheetRinci(wb, data)
    wb.download()
  }
  return downloadExcel
}

export default ExcelLayout
