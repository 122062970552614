import {
  MainWrapper,
  // PdfWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
} from '../../../../../../pages/pengawasan-internal/pelaksanaan-penugasan-assurans/audit/pelaksanaan-audit/form-tindak-lanjut/MainWrapper'
import {DataRoutesProps} from '../../../../../UserRolesValidation'

const FormTindakLanjutRoutes: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  // {
  //   icon: '',
  //   title: '',
  //   route: 'pdf/:id',
  //   elements: <PdfWrapper />,
  //   subroute: [],
  // },
]

export {FormTindakLanjutRoutes}
