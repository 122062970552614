import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../helper/AppFunction'
import {TableAction, TableInput, TableInputDataProps, TableRow} from '../../../../helper/TableInput'
import Modal from '../../../../helper/Modal'
import {ReactPdfViewer} from '../../../../helper/ReactPdf'
import {TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import ReactIcon from '../../../../helper/ReactIcon'
import {postData, putData} from '../../../../helper/FormAxios'
import Swal from 'sweetalert2'
import {getData} from '../../../../helper/FormAxios'

const API_URL = process.env.REACT_APP_API_URL
const dummyDataDisposisi = [
  {
    tanggal: '2024-01-18T11:02:59Z',
    batas_waktu: '2024-01-18T11:02:59Z',
    koordinasi: 'koordinasinya',
    instruksi: 'instruksinya',
    status: 'Belum dibaca',
    dilakukan_oleh: {
      nama: 'Miftahul Huda',
      jabatan: 'Auditor Muda Inspektorat Wilayah III',
    },
    kepada: {
      nama: 'Miftahul Huda',
      jabatan: 'Auditor Muda Inspektorat Wilayah III',
    },
  },
]

const RouteList = {
  disposisi: '/tata_usaha/naskah_masuk',
  balas: '/tata_usaha/disposisi_naskah',
  histori_naskah: '/tata_usaha/histori_naskah_masuk',
}

export const TableInputDataDisposisi: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Dilakukan Oleh',
    },
    {
      title: 'Kepada',
    },
    {
      title: 'Pesan Disposisi / Koordinasi',
    },
    {
      title: 'Instruksi / Pesan Tambahan',
    },
    {
      title: 'Lampiran',
    },
    {
      title: 'Tanggal Disposisi',
    },
    {
      title: 'Batas Waktu',
    },
    {
      title: 'Status',
    },
  ],
  route: {
    url: `${RouteList.disposisi}/:id/get_disposisi`,
  },
}

export const TableInputDataBalas: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Dilakukan Oleh',
    },
    {
      title: 'Kepada',
    },
    {
      title: 'Tanggal Balas',
    },
  ],
  link: {
    export: [],
    action: [
      {
        id: 'id',
        protect: 'delete',
        title: 'Batalkan Disposisi',
        route: '',
      },
      {
        id: 'id',
        protect: 'selesai',
        title: 'Selesaikan Disposisi',
        route: '',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: `${RouteList.balas}/:id/balasan`,
    // query: 'byuser=true',
  },
}

export const TableInputDataHistoryNaskah: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Tanggal',
    },
    {
      title: 'Pengirim',
    },
    {
      title: 'Asal',
    },
    {
      title: 'Tujuan',
    },
    {
      title: 'Jenis Naskah',
    },
    {
      title: 'Hal',
    },
    {
      title: 'File',
    },
    {
      title: 'Lampiran',
    },
  ],
  route: {
    url: `${RouteList.histori_naskah}/:id`,
    // query: 'byuser=true',
  },
}

const TableDisposisi: FC<{onFeedback?: any}> = ({onFeedback}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataDisposisi

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      onFeedback={async (e) => {
        if (e?.protect == 'selesai') {
          let disposisiNaskah = await getData(
            '',
            RouterQueryParams('/tata_usaha/disposisi_naskah', query)
          )
          if (disposisiNaskah?.status) {
            await putData(
              e.data,
              RouterQueryParams(`/tata_usaha/disposisi_naskah/${e.data.id.toString()}`, query)
            )
            onFeedback()
          }
        }
      }}
      // isDummy
      // staticData={dummyDataDisposisi}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pembuat_id.nama'})}</td>
              <td className='min-w-150px'>
                <div>
                  {NullProof({input: l, params: 'kepada_id', isMap: true})?.map(
                    (l1: any, i1: number) => (
                      <Fragment key={i1}>
                        <div className='d-flex gap-2'>
                          <div>
                            {NullProof({input: l1, params: 'status_baca'}) === 1 ? (
                              <div
                                className='rounded-circle'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  minWidth: '20px',
                                  backgroundColor: '#2ecc71',
                                }}
                              ></div>
                            ) : (
                              <div
                                className='rounded-circle'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  minWidth: '20px',
                                  backgroundColor: '#e74c3c',
                                }}
                              ></div>
                            )}
                            {NullProof({
                              input: l1,
                              params: 'nama',
                            })}
                          </div>
                        </div>
                      </Fragment>
                    )
                  )}
                </div>
              </td>
              {/* <td className='min-w-250px'>{NullProof({input: l, params: 'koordinasi'})}</td> */}
              <td className='min-w-250px'>
                {' '}
                {l.pesan.map((value: string, index: number) => (
                  <div key={index}>{value}</div>
                ))}
              </td>
              <td className='min-w-350px'>{NullProof({input: l, params: 'intruksi'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'fix_lampiran'})}</td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'createdAt', type: 'date'})}
              </td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'batas_waktu', type: 'date'})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'status'})}</td>
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const TableBalas: FC<{onFeedback?: any}> = ({onFeedback}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataBalas

  return (
    <TableInput
      isModal={true}
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      onFeedback={async (e) => {
        if (e?.protect == 'selesai') {
          let disposisiNaskah = await getData(
            '',
            RouterQueryParams('/tata_usaha/disposisi_naskah', query)
          )
          if (disposisiNaskah?.status) {
            await putData(
              e.data,
              RouterQueryParams(`/tata_usaha/disposisi_naskah/${e.data.id.toString()}`, query)
            )
            onFeedback()
          }
        }
      }}
      // isDummy
      // staticData={dummyDataDisposisi}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pegawai.nama'})}</td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'DisposisiNaskah.kepada_id', isMap: true})?.map(
                  (l1: any, i1: number) => (
                    <Fragment key={i1}>
                      <div className='d-flex gap-2'>
                        <div>
                          {NullProof({
                            input: l1,
                            params: 'pegawai_id.nama',
                          })}
                        </div>
                      </div>
                    </Fragment>
                  )
                )}
              </td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'createdAt', type: 'date'})}
              </td>
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const TableHistoriNaskah: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataHistoryNaskah
  console.log(data)

  return (
    <TableInput
      isModal={true}
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'createdAt', type: 'date'})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pengirim'})}</td>
              <td className='min-w-250px'>{NullProof({input: l, params: 'asal'})}</td>
              <td className='min-w-350px'>
                <div className='mb-2'>
                  <div className='fw-bold mb-2'>Utama</div>
                  <div>
                    {NullProof({input: l, params: 'tujuan', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div className='d-flex gap-2'>
                            <div className='min-w-100px'>
                              {NullProof({input: l1, params: 'status_baca'}) === 1 ? (
                                <div
                                  className='rounded-circle'
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    minWidth: '20px',
                                    backgroundColor: '#2ecc71',
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className='rounded-circle'
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    minWidth: '20px',
                                    backgroundColor: '#e74c3c',
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className='pt-3'>{`${NullProof({
                              input: l1,
                              params: 'pegawai_id.nama',
                            })}`}</div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='fw-bold mb-2'>Tembusan</div>
                  <div>
                    {NullProof({input: l, params: 'tembusan', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div className='d-flex gap-2'>
                            <div className='min-w-100px'>
                              {NullProof({input: l1, params: 'status_baca'}) === 1 ? (
                                <div
                                  className='rounded-circle'
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    minWidth: '20px',
                                    backgroundColor: '#2ecc71',
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className='rounded-circle'
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    minWidth: '20px',
                                    backgroundColor: '#e74c3c',
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className='pt-3'>{`${NullProof({
                              input: l1,
                              params: 'pegawai_id.nama',
                            })}`}</div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
              </td>
              <td className='min-w-150px'>
                {typeof NullProof({input: l, params: 'jenis_naskah'}) === 'object' ? (
                  <>{NullProof({input: l, params: 'jenis_naskah.text'})}</>
                ) : (
                  NullProof({input: l, params: 'jenis_naskah'})
                )}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'hal'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'file'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'fix_lampiran'})}</td>
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const ButtonKirimNaskah: FC<{data: any; onRefresh?: any}> = ({data, onRefresh}) => {
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const query = useParams()
  const TableInputData = TableInputDataDisposisi
  const kirimNaskah = async () => {
    try {
      const result: any = await postData({}, `/tata_usaha/naskah_masuk/${query.id}/kirim`)
      if (result?.status === true) {
        setModal((p: any) => ({...p, statusUbah: false}))
        onRefresh && onRefresh()
        Swal.fire({
          icon: 'success',
          title: 'Naskah berhasil dikirim.',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Mohon cek kembali data anda.',
        })
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Mohon cek kembali data anda.',
      })
    }
  }

  return (
    <>
      <Modal
        id='modal_kirim_naskah'
        isShow={modal.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Kirim Naskah'
      >
        <div>
          Anda akan mengirim naskah dengan nomor{' '}
          <span className='fw-bold'>{NullProof({input: data, params: 'nomor_naskah'})}</span> yang
          akan diterima oleh:
          <TableInputDetailColumn isResponsive='desktop' title={{value: 'Jenis Tujuan'}}>
            UTAMA
          </TableInputDetailColumn>
          <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Kepada'}}>
            {NullProof({input: data, params: 'tembusan', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>{NullProof({input: l, params: 'kepada'})}</Fragment>
            ))}
          </TableInputDetailColumn>
        </div>
        <div className='d-flex flex-row-reverse gap-2 mt-10'>
          <button
            className='btn btn-primary d-flex gap-1 align-items-center'
            onClick={() => {
              kirimNaskah()
            }}
          >
            {'Kirim'}
          </button>
          <button
            className='btn btn-light text-primary border border-primary d-flex gap-1 align-items-center'
            onClick={() => {
              setModal((p: any) => ({...p, statusUbah: false}))
            }}
          >
            {'Batal'}
          </button>
        </div>
      </Modal>
      <div className=' d-flex flex-row-reverse mb-3'>
        <button
          className='btn btn-primary d-flex gap-1 align-items-center'
          onClick={() => {
            setModal({
              statusUbah: true,
            })
          }}
        >
          <ReactIcon icon='RiSendPlaneLine' props={{className: 'fs-3'}} />
          {'Kirim Naskah'}
        </button>
      </div>
    </>
  )
}

const LampiranViewer: FC<{data: any}> = ({data}) => {
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  return (
    <>
      <Modal
        id='modal_lampiran'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Lampiran'
      >
        <ReactPdfViewer url={`/${data}`} />
      </Modal>
      <button
        type='button'
        className='btn btn-sm btn-primary'
        onClick={() => {
          setModal((p: any) => ({...p, statusUbah: true}))
        }}
      >
        Lihat Lampiran
      </button>
    </>
  )
}

export {TableDisposisi, TableBalas, TableHistoriNaskah, LampiranViewer, ButtonKirimNaskah}
