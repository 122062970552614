/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, Fragment, useState, ReactNode} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {getData} from '../../../helper/FormAxios'
import {NullProof} from '../../../helper/AppFunction'
import {formatDate} from '../../../helper/DateFns'

const KomponenLoadData: FC<{
  route: string
  query?: string
  onSuccess?: any
  children?: ReactNode
}> = ({route, query = 'size=5&byuser=true', onSuccess, children}) => {
  const [data, setData] = useState([])
  const [refresh, setRefresh] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const loadData = async () => {
    try {
      const result: any = await getData(query, route)
      if (result?.status) {
        setData(result?.data)
        onSuccess && onSuccess(result?.data)
      } else {
        setError(true)
      }
    } catch (error) {
      setError(true)
    }
    setLoading(false)
  }
  const onReload = () => {
    loadData()
    setRefresh(false)
  }
  useEffect(() => {
    if (route && refresh) {
      onReload()
    }
  }, [route, refresh])
  return (
    <>
      {loading ? (
        <>
          <div className='d-flex align-items-center justify-content-between gap-2'>
            <div>Loading...</div>
          </div>
        </>
      ) : (
        <>
          {error ? (
            <div className='d-flex align-items-center justify-content-between gap-2'>
              <div>Gagal memuat data.</div>
              <button className='btn btn-sm btn-primary' onClick={() => setRefresh(true)}>
                Muat Ulang
              </button>
            </div>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </>
  )
}

const MainPage: FC = () => {
  const dummyData = {
    surattugas: [
      {
        id: 1,
        title: 'Surat Tugas #1',
        tanggal: '17 Juni 2023',
      },
      {
        id: 2,
        title: 'Surat Tugas #2',
        tanggal: '17 Juni 2023',
      },
      {
        id: 3,
        title: 'Surat Tugas #3',
        tanggal: '17 Juni 2023',
      },
      {
        id: 4,
        title: 'Surat Tugas #4',
        tanggal: '17 Juni 2023',
      },
      {
        id: 5,
        title: 'Surat Tugas #5',
        tanggal: '17 Juni 2023',
      },
    ],
    perjalanandinas: [
      {
        id: 1,
        title: 'Perjalanan Dinas #1',
        tanggal: '17 Juni 2023',
      },
      {
        id: 2,
        title: 'Perjalanan Dinas #2',
        tanggal: '17 Juni 2023',
      },
      {
        id: 3,
        title: 'Perjalanan Dinas #3',
        tanggal: '17 Juni 2023',
      },
      {
        id: 4,
        title: 'Perjalanan Dinas #4',
        tanggal: '17 Juni 2023',
      },
      {
        id: 5,
        title: 'Perjalanan Dinas #5',
        tanggal: '17 Juni 2023',
      },
    ],
    laporan: [
      {
        id: 1,
        title: 'Laporan #1',
        tanggal: '17 Juni 2023',
      },
      {
        id: 2,
        title: 'Laporan #2',
        tanggal: '17 Juni 2023',
      },
      {
        id: 3,
        title: 'Laporan #3',
        tanggal: '17 Juni 2023',
      },
      {
        id: 4,
        title: 'Laporan #4',
        tanggal: '17 Juni 2023',
      },
      {
        id: 5,
        title: 'Laporan #5',
        tanggal: '17 Juni 2023',
      },
    ],
  }
  const [dataSuratTugas, setDataSuratTugas] = useState<any[]>([])
  const [dataPerjadin, setDataPerjadin] = useState<any[]>([])
  const routeList = {
    surat_tugas: '/pengawasan_internal/surat-tugas',
    perjadin: '/eval_pelaporan/form-bukti-perjalanan',
  }
  const ListUi: FC<{data: {title: string; id: number; link: string; tanggal: string}}> = ({
    data,
  }) => {
    return (
      <div className='form-control form-control-solid d-flex align-items-center justify-content-between'>
        <div>
          <div className='text-truncate text-dark fw-bold'>{data.title}</div>
          <div className='fs-7'>{data.tanggal}</div>
        </div>
        <Link to={`${data.link}/${data.id}`} className='btn btn-sm btn-light text-dark'>
          Lihat
        </Link>
      </div>
    )
  }
  return (
    <>
      <div className='row g-5'>
        <div className='col-12'>
          <div className={`card card-flush h-100`}>
            <div className='card-header py-5'>
              <div className='card-title d-flex flex-row justify-content-between w-100'></div>
              <div className='card-body px-0 w-100'>
                <div>
                  <div className='mb-4 fs-5 fw-bold'>
                    Surat Tugas dan Surat Perjalanan Dinas (SPD)
                  </div>
                  <div
                    className='d-flex flex-column'
                    style={{
                      gap: '5px',
                    }}
                  >
                    <KomponenLoadData
                      route={routeList.surat_tugas}
                      onSuccess={(e: any) => setDataSuratTugas(e)}
                    >
                      {dataSuratTugas?.map((l, i) => (
                        <Fragment key={i}>
                          <div className='form-control form-control-solid d-flex align-items-center justify-content-between'>
                            <div>
                              <div className='text-truncate text-dark fw-bold'>
                                {NullProof({input: l, params: 'nomor_surat'})}
                              </div>
                              <div className='fs-7'>
                                {formatDate({
                                  date: NullProof({input: l, params: 'tanggal_mulai'}),
                                  dateFormat: 'dd MMMM yyyy',
                                })}{' '}
                                -
                                {formatDate({
                                  date: NullProof({input: l, params: 'tanggal_selesai'}),
                                  dateFormat: 'dd MMMM yyyy',
                                })}
                              </div>
                            </div>
                            <Link
                              to={`${'/pengawasan/persiapan/form_st/pdf'}/${NullProof({
                                input: l,
                                params: 'id',
                              })}`}
                              className='btn btn-sm btn-light text-dark'
                            >
                              Lihat
                            </Link>
                          </div>
                          {/* <ListUi data={{...l, link: '/pengawasan/persiapan/form_st'}} /> */}
                        </Fragment>
                      ))}
                    </KomponenLoadData>

                    <div className='d-flex justify-content-end'>
                      <Link
                        to={'/pengawasan/persiapan/form_st?filter=byuser-true'}
                        className='btn btn-sm btn-primary mt-4'
                      >
                        Lihat Semua
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='mb-4 fs-5 fw-bold'>Verifikasi Perjalanan Dinas</div>
                  <div
                    className='d-flex flex-column'
                    style={{
                      gap: '5px',
                    }}
                  >
                    <KomponenLoadData
                      route={routeList.perjadin}
                      onSuccess={(e: any) => setDataPerjadin(e)}
                    >
                      {dataPerjadin?.map((l, i) => (
                        <Fragment key={i}>
                          <div className='form-control form-control-solid d-flex align-items-center justify-content-between'>
                            <div>
                              <div className='text-truncate text-dark fw-bold'>
                                {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                              </div>
                              <div className='fs-7'>
                                {formatDate({
                                  date: NullProof({input: l, params: 'surat_tugas.tanggal_mulai'}),
                                  dateFormat: 'dd MMMM yyyy',
                                })}{' '}
                                -
                                {formatDate({
                                  date: NullProof({
                                    input: l,
                                    params: 'surat_tugas.tanggal_selesai',
                                  }),
                                  dateFormat: 'dd MMMM yyyy',
                                })}
                              </div>
                            </div>
                            <div className='d-flex align-items-center gap-2'>
                              <Link
                                to={`${'/evaluasi_pelaporan/verifikasi_perjalanan_dinas/form_bukti_perjalanan/ubah'}/${NullProof(
                                  {
                                    input: l,
                                    params: 'id',
                                  }
                                )}`}
                                className='btn btn-sm btn-light text-dark'
                              >
                                Ubah
                              </Link>
                              <Link
                                to={`${'/evaluasi_pelaporan/verifikasi_perjalanan_dinas/form_bukti_perjalanan/detail'}/${NullProof(
                                  {
                                    input: l,
                                    params: 'id',
                                  }
                                )}`}
                                className='btn btn-sm btn-light text-dark'
                              >
                                Lihat
                              </Link>
                            </div>
                          </div>
                          {/* <ListUi data={{...l, link: '/pengawasan/persiapan/form_st'}} /> */}
                        </Fragment>
                      ))}
                    </KomponenLoadData>
                    <div className='d-flex justify-content-end'>
                      <Link
                        to={
                          '/evaluasi_pelaporan/verifikasi_perjalanan_dinas/form_bukti_perjalanan?filter=byuser-true'
                        }
                        className='btn btn-sm btn-primary mt-4'
                      >
                        Lihat Semua
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='mb-4 fs-5 fw-bold'>Daftar Penyelesaian Laporan</div>
                  <div
                    className='d-flex flex-column'
                    style={{
                      gap: '5px',
                    }}
                  >
                    {dummyData.laporan.map((l, i) => (
                      <Fragment key={i}>
                        <ListUi data={{...l, link: '/profil/daftar_penyelesaian_laporan'}} />
                      </Fragment>
                    ))}
                    <div className='d-flex justify-content-end'>
                      <Link
                        to={'/profil/daftar_penyelesaian_laporan'}
                        className='btn btn-sm btn-primary mt-4'
                      >
                        Lihat Semua
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ProfilTugasWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <MainPage />
    </>
  )
}

export {ProfilTugasWrapper}
