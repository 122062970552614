/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink} from '../../../_metronic/layout/core'
import {Link, useLocation} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {NullProof, getApiPath, getRandomColor, letterAvatar} from '../../helper/AppFunction'
import Modal from '../../helper/Modal'

const MainPage: FC = () => {
  const {auth} = useAuth()
  const dataUser = auth?.data
  const dummyData = {
    pangkat: `${NullProof({input: dataUser, params: 'pegawai.pangkat.pangkat'})} - ${NullProof({
      input: dataUser,
      params: 'pegawai.pangkat.golongan',
    })}`,
    jabatan: NullProof({input: dataUser, params: 'pegawai.jabatan.jabatan'}),
    absensi: '-',
    cuti: '-',
    pensiun: '-',
    izin: '-',
    lencana: '-',
    kredit: '-',
  }
  // const loadDataProfile = async () => {
  //   const dataProfile = await getData('', '/account/profile')
  //   console.log(dataProfile)
  // }
  // useEffect(() => {
  //   loadDataProfile()
  // }, [])
  return (
    <>
      <div className='row g-5'>
        <div className='col-12'>
          <div className={`card card-flush h-100`}>
            <div className='card-header py-5'>
              <div className='card-title d-flex flex-row justify-content-between w-100'></div>
              <div className='card-body px-0 w-100'>
                <div className='d-flex flex-column' style={{gap: '10px'}}>
                  <div className='d-flex flex-column flex-md-row align-items-md-center'>
                    <div className='fs-4 text-gray-500 w-100 w-md-25 mb-2 mb-md-0'>Pangkat</div>
                    <div className='fs-6 w-100 form-control form-control-solid'>
                      {dummyData.pangkat}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row align-items-md-center'>
                    <div className='fs-4 text-gray-500 w-100 w-md-25 mb-2 mb-md-0'>Jabatan</div>
                    <div className='fs-6 w-100 form-control form-control-solid'>
                      {dummyData.jabatan}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row align-items-md-center'>
                    <div className='fs-4 text-gray-500 w-100 w-md-25 mb-2 mb-md-0'>Absensi</div>
                    <div className='fs-6 w-100 form-control form-control-solid'>
                      {dummyData.absensi}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row align-items-md-center'>
                    <div className='fs-4 text-gray-500 w-100 w-md-25 mb-2 mb-md-0'>Cuti</div>
                    <div className='fs-6 w-100 form-control form-control-solid'>
                      {dummyData.cuti}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row align-items-md-center'>
                    <div className='fs-4 text-gray-500 w-100 w-md-25 mb-2 mb-md-0'>Pensiun</div>
                    <div className='fs-6 w-100 form-control form-control-solid'>
                      {dummyData.pensiun}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row align-items-md-center'>
                    <div className='fs-4 text-gray-500 w-100 w-md-25 mb-2 mb-md-0'>Izin</div>
                    <div className='fs-6 w-100 form-control form-control-solid'>
                      {dummyData.izin}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row align-items-md-center'>
                    <div className='fs-4 text-gray-500 w-100 w-md-25 mb-2 mb-md-0'>
                      Satya Lencana
                    </div>
                    <div className='fs-6 w-100 form-control form-control-solid'>
                      {dummyData.lencana}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row align-items-md-center'>
                    <div className='fs-4 text-gray-500 w-100 w-md-25 mb-2 mb-md-0'>
                      Angka Kredit
                    </div>
                    <div className='fs-6 w-100 form-control form-control-solid'>
                      {dummyData.kredit}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Profil',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PageData: any = {
  '/profil/info': {
    title: 'Informasi',
    breadcrumb: Breadcrumbs,
  },
  '/profil/tugas': {
    title: 'Penugasan',
    breadcrumb: Breadcrumbs,
  },
  '/profil/keuangan': {
    title: 'Keuangan',
    breadcrumb: Breadcrumbs,
  },
}

const ProfilHeader: FC = () => {
  const {auth} = useAuth()
  const dataUser = auth?.data
  const location = useLocation()
  const nameLetter = letterAvatar(
    NullProof({input: dataUser, params: 'pegawai.nama', isLabel: false}) || ''
  )
  const [modalFoto, setModalFoto] = useState<boolean>(false)
  let letterPhoto: any = {
    name: nameLetter,
    backgroundColor: getRandomColor(nameLetter).backgroundColor,
    color: getRandomColor(nameLetter).textColor,
  }
  return (
    <>
      <Modal
        id={`foto-profil`}
        title={'Foto Profil'}
        isShow={modalFoto}
        onClose={() => {
          setModalFoto(false)
        }}
      >
        <img
          className='ratio ratio-1x1'
          src={`${getApiPath()}/${NullProof({input: dataUser, params: 'img_profile'})}`}
          alt={'Foto Profil'}
        />
      </Modal>
      <div className={`card card-flush h-100 mb-4 mt-n10`}>
        <div className='card-header pb-0 py-5'>
          <div className='card-body px-0 pb-0 w-100'>
            <div
              className='d-flex flex-column flex-md-row pb-4'
              style={{
                gap: '10px',
              }}
            >
              <div
                className='cursor-pointer overflow-hidden min-w-60px min-h-60px w-60px h-60px rounded-circle fs-2 fw-bold text-uppercase d-flex justify-content-center align-items-center position-relative'
                style={{
                  backgroundColor: letterPhoto.backgroundColor,
                  color: letterPhoto.color,
                }}
                onClick={() => setModalFoto(true)}
              >
                {NullProof({input: dataUser, params: 'img_profile', isLabel: false}) ? (
                  <img
                    className='w-100 h-100 object-cover'
                    src={`${getApiPath()}/${NullProof({input: dataUser, params: 'img_profile'})}`}
                  />
                ) : (
                  <>{letterPhoto.name}</>
                )}
              </div>
              <div className='w-100'>
                <div className='fs-2 fw-bold text-capitalize'>
                  {NullProof({input: dataUser, params: 'pegawai.nama'})?.toLowerCase()}
                </div>
                <div className='fs-4 text-gray-400'>
                  {NullProof({input: dataUser, params: 'pegawai.email'})}
                </div>
              </div>
            </div>
            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/profil/info' && 'active')
                    }
                    to='/profil/info'
                  >
                    Informasi
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/profil/edit' && 'active')
                    }
                    to='/profil/edit'
                  >
                    Edit Profil
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/profil/tugas' && 'active')
                    }
                    to='/profil/tugas'
                  >
                    Penugasan
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/profil/keuangan' && 'active')
                    }
                    to='/profil/keuangan'
                  >
                    Keuangan
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ProfilWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <MainPage />
    </>
  )
}

export {ProfilWrapper, ProfilHeader}
