/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Fragment, useState } from 'react'
import { PageTitle } from '../../../../../../../_metronic/layout/core'
import { useParams } from 'react-router-dom'
import PageData, {
	InfoData,
	TableInputData,
} from '../PageData'
import { PdfPageViewer } from '../../../../../../helper/PdfPageViewer'
import PdfLayout from '../PdfLayout'
import { CardUi } from '../../../../../../helper/AppUi'
import { TableAction, TableInput, TableRow, TableColumn } from '../../../../../../helper/TableInput'
import { NullProof, RouterQueryParams } from '../../../../../../helper/AppFunction'
import { formatDate } from '../../../../../../helper/DateFns'

import UbahWrapper from './UbahWrapper'
import TambahWrapper from './TambahWrapper'
import DetailWrapper from './DetailWrapper'

const Index: FC = () => {
	const [data, setData] = useState<any>([])
	const [modal, setModal] = useState<any>([])
	const modalConfig = {
		data: modal,
		post: setModal,
	}

	return (
		<>
			<PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
				{PageData.semua.page.breadcrumb.title}
			</PageTitle>

			<CardUi title={PageData.semua.page.title}>
				<TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
					{NullProof({ input: data, params: 'data', isMap: true }).map((l: any, i: number) => {
						const tableDataBuilder = {
							modal: modalConfig,
							input: { data: l, index: i },
							action: TableInputData.link?.action || [],
						}

						return (
							<Fragment key={i}>
								<TableRow {...tableDataBuilder}>
									<TableColumn label="No." className="min-w-100px">
										{i + 1 + ((NullProof({ input: data, params: 'page' }) || 1) - 1) * 10}
									</TableColumn>
									<TableColumn label="Nama" className="min-w-150px">
										{NullProof({ input: l, params: 'nama' })}
									</TableColumn>
									<TableColumn label="Tanggal Pembuatan" className="min-w-150px">
										{formatDate({
											date: NullProof({ input: l, params: 'createdAt' }),
											dateFormat: 'dd MMMM yyyy',
										})}
									</TableColumn>
									<TableAction {...tableDataBuilder} />
								</TableRow>
							</Fragment>
						)
					})}
				</TableInput>
			</CardUi>
		</>
	)
}

const PdfWrapper: FC = () => {
	const query = useParams()

	return (
		<>
			<PdfPageViewer
				route={RouterQueryParams(`${InfoData.api}/:id`, query)}
				PageData={{
					breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
					title: PageData.pdf?.page.breadcrumb.title || '',
				}}
				PdfLayout={PdfLayout}
			/>
		</>
	)
}

export {
	Index as default,
	DetailWrapper,
	TambahWrapper,
	UbahWrapper,
	PdfWrapper,
}
