import Dashboard from "./page/dashboard"
import Organization from "./page/organization"
import Sample from "./page/sample"

const i18nAllPagesAllMessages = {
  dashboard: Dashboard,
  organization: Organization,
  sample: Sample,
}

export default i18nAllPagesAllMessages