import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const GET_VERIFY_CODE_URL = `${API_URL}/auth/get_code`
export const VERIFY_CODE_URL = `${API_URL}/auth/verify_code`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  },
  {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Accept": "application/json",
      // "Content-Security-Policy": "connect-src https://siapi.kemenag.go.id"
    },
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(input: {email: string; password: string}) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email: input.email,
    password: input.password,
  })
}

export function requestVerifCode(email: string) {
  return axios.post<{result: boolean}>(GET_VERIFY_CODE_URL, {
    email,
  })
}

export function checkVerifCode(email: string, kode: string) {
  return axios.post<{result: boolean}>(VERIFY_CODE_URL, {
    email,
    kode,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
