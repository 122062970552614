import {FC, Fragment, useState} from 'react'
import {TableAction, TableInput, TableInputDataProps, TableRow} from '../../../../helper/TableInput'
import {useParams} from 'react-router-dom'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../helper/AppFunction'
import {TableInputDataHistoryNaskah} from './PageData'
import Modal from '../../../../helper/Modal'
import {
  TableInputDetail,
  TableInputDetailColumn,
  TableInputDetailDataProps,
} from '../../../../helper/TableInputDetail'
import {ReactPdfViewer} from '../../../../helper/ReactPdf'
import {formatDate} from '../../../../helper/DateFns'

const dummyDaftarVerifikator = [
  {
    urutan: 1,
    pegawai: {
      nama: 'Miftahul Huda',
      jabatan: 'Auditor Muda Inspektorat Wilayah III',
      unit_kerja:
        'Bagian Pengelolaan Hasil Pengawasan, Sistem Informasi, dan Pengaduan Masyarakat Sekretariat Inspektorat Jenderal Kementerian Agama - Kementerian Agama',
    },
    catatan: '',
    status: 'Disetujui',
  },
]

const dummyDaftarPenandatanganan = [
  {
    pegawai: {
      nama: 'Miftahul Huda',
      jabatan: 'Auditor Muda Inspektorat Wilayah III',
      unit_kerja:
        'Bagian Pengelolaan Hasil Pengawasan, Sistem Informasi, dan Pengaduan Masyarakat Sekretariat Inspektorat Jenderal Kementerian Agama - Kementerian Agama',
    },
    catatan: '',
    status: 'Disetujui',
  },
]

const dummyDaftarRiwayatPerubahanMeta = [
  {
    id: 13,
    peristiwa: 'Pembaruan',
    tanggal_dilakukan_pada: '2024-01-18T11:02:59Z',
  },
  {
    id: 1,
    peristiwa: 'Pembuatan',
    tanggal_dilakukan_pada: '2024-01-18T11:02:59Z',
  },
]

const RouteList = {
  daftar_verifikator: '/tata_usaha/naskah_keluar/:id/status-naskah',
  daftar_penandatanganan: '/tata_usaha/naskah_keluar/:id/status-naskah',
  naskah_dinas: '/tata_usaha/naskah_keluar/:id/riwayat-naskah',
  lampiran: '/tata_usaha/naskah_keluar/:id/riwayat-naskah',
  tujuan: '/tata_usaha/naskah_keluar/:id/riwayat-naskah',
  verifikator: '/tata_usaha/naskah_keluar/:id/riwayat-naskah',
  penandatanganan: '/tata_usaha/naskah_keluar/:id/riwayat-naskah',
  status_kirim: '/tata_usaha/naskah_keluar/:id/riwayat-naskah',
}

const TableInputDaftarVerifikator: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Urutan',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Pengguna',
    },
    {
      title: 'Instansi / Urutan Kerja',
    },
    {
      title: 'Catatan',
    },
    {
      title: 'Status',
    },
  ],
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: RouteList.daftar_verifikator,
    query: 'type=verifikasi',
    // query: 'byuser=true',
  },
}

const TableInputDaftarPenandatangan: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Pengguna',
    },
    {
      title: 'Instansi / Urutan Kerja',
    },
    {
      title: 'Catatan',
    },
    {
      title: 'Status',
    },
  ],
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: RouteList.daftar_penandatanganan,
    query: 'type=penandatangan',
    // query: 'byuser=true',
  },
}

const TableInputDaftarRiwayatPerubahanData = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Peristiwa',
    },
    {
      title: 'Dilakukan Pada',
    },
  ],
}

const TableInputNaskahDinas: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.naskah_dinas,
    query: 'type=create',
  },
}

const TableInputLampiran: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.lampiran,
    query: 'type=lampiran',
  },
}

const TableInputTujuan: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.tujuan,
    query: 'type=tujuan',
  },
}

const TableInputVerifikator: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.verifikator,
    query: 'type=verifikasi',
  },
}

const TableInputPenandatanganan: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.penandatanganan,
    query: 'type=penandatangan',
  },
}

const TableInputStatusKirim: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.status_kirim,
    query: 'type=status_kirim',
  },
}

const TableDaftarVerifikator: FC<{dataInput: any}> = ({dataInput}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDaftarVerifikator
  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      isDummy
      staticData={dataInput !== '_' ? dataInput : dummyDaftarVerifikator}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'urutan'})}</td>
              <td className='min-w-150px'>{`${NullProof({
                input: l,
                params: 'pegawai.nama',
              })} - ${NullProof({input: l, params: 'pegawai.tampil_jabatan'})}`}</td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'pegawai.satuan_kerja'})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'catatan'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'status'})}</td>
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const TableDaftarPenandatanganan: FC<{dataInput: any}> = ({dataInput}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDaftarPenandatangan

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      isDummy
      staticData={dataInput !== '_' ? dataInput : dummyDaftarPenandatanganan}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>{`${NullProof({
                input: l,
                params: 'pegawai.nama',
              })} - ${NullProof({input: l, params: 'pegawai.tampil_jabatan'})}`}</td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'pegawai.satuan_kerja'})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'catatan'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'status'})}</td>
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const ComponentModalHistory: FC<{
  route: string
  id: number
  isShow: boolean
  setModal: any
}> = ({route, id, isShow, setModal}) => {
  const [dataHistory, setDataHistoryl] = useState<any>([])

  return (
    <Modal
      id='modal_detailhistory'
      isShow={isShow}
      onClose={() => {
        setModal((p: any) => ({...p, statusUbah: false}))
      }}
      title='Detail History'
    >
      <TableInputDetail
        onSuccess={(val: any) => setDataHistoryl(val)}
        route={{
          url: `${route}/${id}`,
        }}
      >
        <div className='row g-0'>
          <div className='row'>
            {NullProof({input: dataHistory, params: 'detail', isMap: true})?.map(
              (l: any, i: number) => (
                <Fragment key={i}>
                  <TableInputDetailColumn
                    props={{className: 'col-12 col-md-4 gy-1'}}
                    hideDivider
                    title={{value: 'Atribut'}}
                  >
                    {NullProof({input: l, params: 'atribut'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'col-12 col-md-4 gy-1'}}
                    hideDivider
                    title={{value: 'Isian Lama'}}
                  >
                    {NullProof({input: l, params: 'sebelum'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'col-12 col-md-4 gy-1'}}
                    hideDivider
                    title={{value: 'Isian Baru'}}
                  >
                    {NullProof({input: l, params: 'sesudah'})}
                  </TableInputDetailColumn>
                </Fragment>
              )
            )}
          </div>
        </div>
      </TableInputDetail>
    </Modal>
  )
}

const TableRiwayatPerubahanMetadata: FC<{dataInput: any; title: string}> = ({dataInput, title}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [detailId, setDetailId] = useState<any>(0)
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = dataInput

  return (
    <>
      <ComponentModalHistory
        route={TableInputData.route.url}
        id={detailId}
        isShow={modal.statusUbah}
        setModal={setModal}
      />
      <div className='fs-4 fw-bold mb-4'>{title}</div>
      <TableInput
        modal={modalConfig}
        onSuccess={(val: any) => setData(val)}
        {...TableInputData}
        onFeedback={(e) => {
          if (e?.id === 'lihat') {
            setDetailId(e?.data?.id)
            setModal((p: any) => ({...p, statusUbah: true}))
          }
        }}
        // isDummy
        // staticData={dummyDaftarRiwayatPerubahanMeta}
      >
        {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
          const tableDataBuilder = {
            modal: modalConfig,
            input: {data: l, index: i},
            action: TableInputData.link?.action || [],
          }
          return (
            <Fragment key={i}>
              <TableRow {...tableDataBuilder}>
                <td className='w-100px'>
                  {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                </td>
                <td className='min-w-150px'>{NullProof({input: l, params: 'event'})}</td>
                <td className='min-w-150px'>
                  {formatDate({
                    date: NullProof({input: l, params: 'createdAt', isLabel: false}) || '',
                    dateFormat: 'dd MMMM yyyy HH:mm:ss',
                  })}
                </td>
              </TableRow>
            </Fragment>
          )
        })}
      </TableInput>
    </>
  )
}

const TableNaskahDinas: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputNaskahDinas

  return (
    <div>
      <div className='fs-4 fw-bold mb-4'>Naskah Dinas</div>
      <TableInput
        modal={modalConfig}
        onSuccess={(val: any) => setData(val)}
        {...TableInputData}
        isDummy
        staticData={dummyDaftarRiwayatPerubahanMeta}
      >
        {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
          const tableDataBuilder = {
            modal: modalConfig,
            input: {data: l, index: i},
            action: TableInputData.link?.action || [],
          }
          return (
            <Fragment key={i}>
              <TableRow {...tableDataBuilder}>
                <td className='w-100px'>
                  {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                </td>
                <td className='min-w-150px'>{NullProof({input: l, params: 'peristiwa'})}</td>
                <td className='min-w-150px'>
                  {NullProof({input: l, params: 'tanggal_dilakukan_pada', type: 'date'})}
                </td>
                <TableAction {...tableDataBuilder} />
              </TableRow>
            </Fragment>
          )
        })}
      </TableInput>
    </div>
  )
}

const TableLampiran: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputLampiran

  return (
    <div>
      <div className='fs-4 fw-bold mb-4'>Lampiran</div>
      <TableInput
        modal={modalConfig}
        onSuccess={(val: any) => setData(val)}
        {...TableInputData}
        isDummy
        staticData={dummyDaftarRiwayatPerubahanMeta}
      >
        {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
          const tableDataBuilder = {
            modal: modalConfig,
            input: {data: l, index: i},
            action: TableInputData.link?.action || [],
          }
          return (
            <Fragment key={i}>
              <TableRow {...tableDataBuilder}>
                <td className='w-100px'>
                  {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                </td>
                <td className='min-w-150px'>{NullProof({input: l, params: 'peristiwa'})}</td>
                <td className='min-w-150px'>
                  {NullProof({input: l, params: 'tanggal_dilakukan_pada', type: 'date'})}
                </td>
                <TableAction {...tableDataBuilder} />
              </TableRow>
            </Fragment>
          )
        })}
      </TableInput>
    </div>
  )
}

const TableTujuan: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputTujuan

  return (
    <div>
      <div className='fs-4 fw-bold mb-4'>Tujuan</div>
      <TableInput
        modal={modalConfig}
        onSuccess={(val: any) => setData(val)}
        {...TableInputData}
        isDummy
        staticData={dummyDaftarRiwayatPerubahanMeta}
      >
        {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
          const tableDataBuilder = {
            modal: modalConfig,
            input: {data: l, index: i},
            action: TableInputData.link?.action || [],
          }
          return (
            <Fragment key={i}>
              <TableRow {...tableDataBuilder}>
                <td className='w-100px'>
                  {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                </td>
                <td className='min-w-150px'>{NullProof({input: l, params: 'peristiwa'})}</td>
                <td className='min-w-150px'>
                  {NullProof({input: l, params: 'tanggal_dilakukan_pada', type: 'date'})}
                </td>
                <TableAction {...tableDataBuilder} />
              </TableRow>
            </Fragment>
          )
        })}
      </TableInput>
    </div>
  )
}

const TableVerifikator: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputVerifikator

  return (
    <div>
      <div className='fs-4 fw-bold mb-4'>Verifikator</div>
      <TableInput
        modal={modalConfig}
        onSuccess={(val: any) => setData(val)}
        {...TableInputData}
        isDummy
        staticData={dummyDaftarRiwayatPerubahanMeta}
      >
        {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
          const tableDataBuilder = {
            modal: modalConfig,
            input: {data: l, index: i},
            action: TableInputData.link?.action || [],
          }
          return (
            <Fragment key={i}>
              <TableRow {...tableDataBuilder}>
                <td className='w-100px'>
                  {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                </td>
                <td className='min-w-150px'>{NullProof({input: l, params: 'peristiwa'})}</td>
                <td className='min-w-150px'>
                  {NullProof({input: l, params: 'tanggal_dilakukan_pada', type: 'date'})}
                </td>
                <TableAction {...tableDataBuilder} />
              </TableRow>
            </Fragment>
          )
        })}
      </TableInput>
    </div>
  )
}

const TablePenandatanganan: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputPenandatanganan

  return (
    <div>
      <div className='fs-4 fw-bold mb-4'>Penandatanganan</div>
      <TableInput
        modal={modalConfig}
        onSuccess={(val: any) => setData(val)}
        {...TableInputData}
        isDummy
        staticData={dummyDaftarRiwayatPerubahanMeta}
      >
        {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
          const tableDataBuilder = {
            modal: modalConfig,
            input: {data: l, index: i},
            action: TableInputData.link?.action || [],
          }
          return (
            <Fragment key={i}>
              <TableRow {...tableDataBuilder}>
                <td className='w-100px'>
                  {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                </td>
                <td className='min-w-150px'>{NullProof({input: l, params: 'peristiwa'})}</td>
                <td className='min-w-150px'>
                  {NullProof({input: l, params: 'tanggal_dilakukan_pada', type: 'date'})}
                </td>
                <TableAction {...tableDataBuilder} />
              </TableRow>
            </Fragment>
          )
        })}
      </TableInput>
    </div>
  )
}

const TableStatusKirim: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputStatusKirim

  return (
    <div>
      <div className='fs-4 fw-bold mb-4'>Status Kirim</div>
      <TableInput
        modal={modalConfig}
        onSuccess={(val: any) => setData(val)}
        {...TableInputData}
        isDummy
        staticData={dummyDaftarRiwayatPerubahanMeta}
      >
        {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
          const tableDataBuilder = {
            modal: modalConfig,
            input: {data: l, index: i},
            action: TableInputData.link?.action || [],
          }
          return (
            <Fragment key={i}>
              <TableRow {...tableDataBuilder}>
                <td className='w-100px'>
                  {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                </td>
                <td className='min-w-150px'>{NullProof({input: l, params: 'peristiwa'})}</td>
                <td className='min-w-150px'>
                  {NullProof({input: l, params: 'tanggal_dilakukan_pada', type: 'date'})}
                </td>
                <TableAction {...tableDataBuilder} />
              </TableRow>
            </Fragment>
          )
        })}
      </TableInput>
    </div>
  )
}

const LampiranViewer: FC<{data: any}> = ({data}) => {
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  return (
    <>
      <Modal
        id='modal_lampiran'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Lampiran'
      >
        <ReactPdfViewer url={`/${data}`} />
      </Modal>
      <button
        type='button'
        className='btn btn-sm btn-primary'
        onClick={() => {
          setModal((p: any) => ({...p, statusUbah: true}))
        }}
      >
        Lihat Lampiran
      </button>
    </>
  )
}

export {
  TableDaftarVerifikator,
  TableDaftarPenandatanganan,
  TableRiwayatPerubahanMetadata,
  TableNaskahDinas,
  TableLampiran,
  TableTujuan,
  TableVerifikator,
  TablePenandatanganan,
  TableStatusKirim,
  TableInputNaskahDinas,
  TableInputLampiran,
  TableInputTujuan,
  TableInputVerifikator,
  TableInputPenandatanganan,
  TableInputStatusKirim,
  LampiranViewer,
}
