import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'
import {createFormObjectList, listVarProps} from '../../../../helper/AppFunction'

export const listVar: listVarProps[] = [
  {
    isLabel: true,
    number: 'A',
    name: '',
    title: 'Pekerjaan Persiapan',
  },
  {
    number: '1',
    group: 'A',
    name: 'persiapan',
    title: 'Penyusunan Jadwal Waktu Audit',
  },
  {
    number: '2',
    group: 'A',
    name: 'persiapan',
    title: 'Pembagian tugas atas program kerja audit tim',
  },
  {
    number: '3',
    group: 'A',
    name: 'persiapan',
    title: 'Melakukan Desk Audit',
    desc: (
      <div>
        Meliputi:
        <ol type='a'>
          <li>pengumpulan data dan profil Auditi serta menginventarisir permasalahannya.</li>
          <li>
            menelaah KKA, LHA, dan rekomendasi yang dilaksanakan sebelumnya baik oleh Itjen Kemenag
            maupun dari instansi pengawasan ekternal
          </li>
          <li>penelaah peraturan perundang-undangan</li>
        </ol>
      </div>
    ),
  },
  {
    number: '4',
    group: 'A',
    name: 'persiapan',
    title: 'Persiapan instrumen yang diperlukan',
  },
  {
    isLabel: true,
    number: 'B',
    name: '',
    title: 'Pelaksanaan Audit',
  },
  {
    number: '1',
    group: 'B',
    name: 'pelaksanaan',
    title: 'Meminta dokumen kepada auditi',
  },
  {
    number: '2',
    group: 'B',
    name: 'pelaksanaan',
    title: 'Analisis data operasi / kegiatan organisasi',
  },
  {
    number: '3',
    group: 'B',
    name: 'pelaksanaan',
    title: 'Pengujian dan Evaluasi Operasi / Kegiatan Organisasi',
  },
  {
    number: '4',
    group: 'B',
    name: 'pelaksanaan',
    title: 'Menyusun Daftar Temuan',
  },
  {
    number: '5',
    group: 'B',
    name: 'pelaksanaan',
    title: 'Mengembangkan Temuan',
  },
]

const formInputTableList: FormInputProps[] = [
  {
    className: 'col-12 col-md-4',
    name: 'tanggal',
    type: 'input',
    validator: Yup.string().required('Tanggal mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal',
          placeholder: 'Tanggal',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'anggaran_waktu',
    type: 'input',
    validator: Yup.string().required('Anggaran Waktu mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Anggaran Waktu',
          placeholder: 'Anggaran Waktu',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'anggaran_biaya',
    type: 'input',
    validator: Yup.string().required('Anggaran Biaya mohon untuk diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Anggaran Biaya',
          placeholder: 'Anggaran Biaya',
          type: 'number',
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'peta_audit_apip_id',
    type: 'datalist',
    validator: Yup.number().required('Peta APIP mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Peta APIP',
          placeholder: 'Peta APIP',
        },
      },
      datalist: {
        api: '/perencanaan_anggaran/peta-apip',
        params: 'nama,tahun',
        result: (
          <>
            <div>
              <b>Nama: </b>$nama
            </div>
            <div>
              <b>Tahun: </b>$tahun
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'disusun_oleh_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Disusun Oleh',
          placeholder: 'Disusun Oleh',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'disetujui_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Disetujui Oleh',
          placeholder: 'Disetujui Oleh',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'sasaran_audit',
    type: 'input',
    validator: Yup.string().required('Sasaran Audit mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Sasaran Audit',
          placeholder: 'Sasaran Audit',
        },
      },
    },
  },
  {
    className: 'col-12 px-0',
    name: 'detail',
    type: 'component',
    validator: Yup.string().required('Detail mohon untuk diisi.'),
    value: 1,
    options: {
      component: {
        isObject: true,
      },
    },
    component: createFormObjectList(listVar, formInputTableList),
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: '',
        labelType: 'line',
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'total_biaya',
    type: 'input',
    validator: Yup.number().required('Total Biaya mohon untuk diisi.'),
    disabled: true,
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Total Biaya',
          placeholder: 'Total Biaya',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'total_waktu',
    type: 'input',
    validator: Yup.number().required('Total Waktu mohon untuk diisi.'),
    disabled: true,
    options: {
      input: {
        props: {
          title: 'Total Waktu',
          placeholder: 'Total Waktu',
        },
      },
    },
  },
]

export {formInputList}
