import {PageDataContent} from '../../../helper/Model'
import {TableInputDataProps} from '../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Data Roles',
  path: '/pengaturan/data_roles',
  api: '/account/roles',
  setupmenu: '/account/roles/permission',
}

const BaseBreadcrumb = [
  {
    title: 'Pengaturan',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: 'Data Roles',
    path: '/pengaturan/data_roles',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'w-50px',
      },
    },
    {
      title: 'Nama',
    },
  ],
  link: {
    tambah: {
      id: 'tambah',
      protect: 'tambah',
      title: 'Tambah',
      route: 'tambah',
    },
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'id',
        protect: 'ubah/:id',
        title: 'Ubah',
        route: 'ubah/:id',
      },
      
      {
        id: 'id',
        protect: ':id/setup_menu',
        title: 'Setup Menu',
        route: 'setup_menu/:id',
      },
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: '',
      },
      {
        id: 'id',
        protect: 'duplicate',
        title: 'Duplicate',
        route: `${InfoData.api}/duplicate`,
      },
    ],
  },
  route: {
    url: InfoData.api,
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  setupmenu: {
    page: {
      title: `Setup Menu`,
      breadcrumb: {
        title: `Setup Menu`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
