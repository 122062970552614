import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputRencanaMitigasi: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'risk_analisis',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Analisis & Mitigasi',
          placeholder: 'Analisis & Mitigasi',
        },
      },
      datalist: {
        api: '/kebijakan_teknis/identifikasi/:id/analisis',
        params: 'code',
        result: '$code',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Nama is required'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
      datalist: {
        api: '',
        apiInputData: {
          input: 'risk_analisis',
          params: 'risk_threadment_deskripsi',
        },
        params: 'deskripsi',
        result: '$deskripsi',
        id: 'deskripsi',
      },
      watchInput: {
        id: 'risk_analisis',
      },
    },
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Waktu Pelaksanaan Mitigasi & Realisasi Mitigasi',
          placeholder: 'Harga',
        },
      },
      label: {
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-4',
    name: 'januari',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Januari',
          placeholder: 'Januari',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'februari',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Februari',
          placeholder: 'Februari',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'maret',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Maret',
          placeholder: 'Maret',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'april',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'April',
          placeholder: 'April',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'mei',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Mei',
          placeholder: 'Mei',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'juni',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Juni',
          placeholder: 'Juni',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'juli',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Juli',
          placeholder: 'Juli',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'agustus',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Agustus',
          placeholder: 'Agustus',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'september',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'September',
          placeholder: 'September',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'oktober',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Oktober',
          placeholder: 'Oktober',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'november',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'November',
          placeholder: 'November',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'desember',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Rencana',
        value: 'Rencana',
      },
      {
        title: 'Pelaksanaan',
        value: 'Pelaksanaan',
      },
    ],
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Desember',
          placeholder: 'Desember',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'evidence',
    type: 'input',
    value: '',
    validator: Yup.string().required('Evidence wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Evidence',
          placeholder: 'Evidence',
        },
      },
    },
  },
]

const formInputRiskEvent: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'risk_identifikasi',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Risk Identifikasi',
          placeholder: 'Risk Identifikasi',
        },
      },
      datalist: {
        api: '/kebijakan_teknis/risk-register/:id/identifikasi',
        params: 'tujuan,proses_bisnis',
        result: '$tujuan - $proses_bisnis',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'risk_event',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Unit is required'),
    options: {
      input: {
        props: {
          title: 'Unit',
          placeholder: 'Unit',
        },
      },
      datalist: {
        api: '',
        apiInputData: {
          input: 'risk_identifikasi',
          params: 'risk_category_list',
        },
        params: 'event',
        result: '$event',
        id: 'event',
      },
      watchInput: {
        id: 'risk_identifikasi',
      },
    },
  },
  {
    className: 'col-12',
    name: 'rencana_mitigasi',
    type: 'component',
    value: 0,
    validator: Yup.number(),
    // validator: Yup.number().min(1, 'Sektor wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Rencana Mitigasi',
          placeholder: 'Rencana Mitigasi',
        },
      },
      component: {
        useAccordion: true,
      },
    },
    component: formInputRencanaMitigasi,
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'risk_register_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Unit is required'),
    options: {
      input: {
        props: {
          title: 'Unit',
          placeholder: 'Unit',
        },
      },
      datalist: {
        api: '/kebijakan_teknis/risk-register',
        params: 'departemen',
        result: '$departemen',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'rencana_mitigasi',
    type: 'component',
    value: 0,
    validator: Yup.number(),
    // validator: Yup.number().min(1, 'Sektor wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Rencana Event',
          placeholder: 'Rencana Event',
        },
      },
      component: {
        useAccordion: true,
      },
    },
    component: formInputRiskEvent,
  },
]

export {formInputList}
