import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfFontBuilder,
  PdfLayoutDataProps,
  PdfStyle,
  PdfTabCol,
} from '../../../helper/ReactPdf'
import {NullProof} from '../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'

  const listSkor: any[] = [
    {
      title: 'Laporan Hasil Pengawasan dan Pengaduan Masyarakat',
      name: 'laporan_pengaduan',
      fee: 70,
    },
    {title: 'Skor rata rata risiko pada Inspektorat Wilayah', name: 'skor_wilayah', fee: 30},
  ]
  return (
    <Document title='Dokumen'>
      <Page size={{width: '800'}} style={{...PdfStyle.page, fontFamily: 'Arial', padding: '20pt'}}>
        <View style={{fontWeight: 'bold', textAlign: 'center'}}>
          <Text
            style={{
              textTransform: 'uppercase',
              ...PdfFontBuilder({template: 'title', size: 'large'}),
            }}
          >
            Risiko Pengawasan Audit
          </Text>
          <Text style={{...PdfFontBuilder({template: 'title', size: 'large'})}}>
            Per Tahun Pengawasan {NullProof({input: data.data, params: 'tahun'})}
          </Text>
        </View>
        <PdfTabCol direction='column'>
          {/* Header */}
          <PdfTabCol style={{view: {height: '100pt'}}}>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '5%'}}}>
              No
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '40%'}}}>
              Nama Satker
            </PdfTabCol>
            <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '45%'}}}>
              <PdfTabCol center type='text' border={{bottom: 1}}>
                Nilai Skor
              </PdfTabCol>
              <PdfTabCol center border={{}}>
                {listSkor.map((ls, is) => (
                  <Fragment key={is}>
                    <PdfTabCol
                      type='text'
                      center
                      noWrap
                      border={is + 1 !== listSkor.length ? {right: 1} : {}}
                    >
                      {NullProof({input: ls, params: 'title'})}
                    </PdfTabCol>
                  </Fragment>
                ))}
              </PdfTabCol>
            </PdfTabCol>
            <PdfTabCol
              center
              type='text'
              border={{}}
              style={{view: {width: '10%', padding: '10pt 10pt', textAlign: 'center'}}}
            >
              Total Skor (rata-rata)
            </PdfTabCol>
          </PdfTabCol>
          {/* Data */}
          {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <PdfTabCol style={{view: {height: '80pt'}}}>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '5%'}}}
                  >
                    {i + 1}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '40%'}}}
                  >
                    {NullProof({input: data.data, params: 'variable_here'})}
                  </PdfTabCol>
                  <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '45%'}}}>
                    <PdfTabCol center border={{}}>
                      {listSkor.map((ls, is) => (
                        <Fragment key={is}>
                          <PdfTabCol
                            type='text'
                            center
                            border={is + 1 !== listSkor.length ? {right: 1} : {}}
                          >
                            {Math.round(
                              (NullProof({input: l, params: ls.name, isLabel: false}) || 0) *
                                (ls.fee / 100)
                            )}
                          </PdfTabCol>
                        </Fragment>
                      ))}
                    </PdfTabCol>
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    type='text'
                    border={{}}
                    style={{view: {width: '10%', padding: '10pt 10pt', textAlign: 'center'}}}
                  >
                    {NullProof({input: l, params: 'total'})}
                  </PdfTabCol>
                </PdfTabCol>
              </Fragment>
            )
          })}
        </PdfTabCol>
      </Page>
    </Document>
  )
}

export default PdfLayout
