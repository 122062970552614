/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {NullProof, RouterQueryParams} from '../../../../../../helper/AppFunction'
import {CardUi} from '../../../../../../helper/AppUi'
import FormInput from '../../../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../../helper/TableInputDetail'
import {TableAction, TableInput, TableRow} from '../../../../../../helper/TableInput'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td className='min-w-150px'>{i + 1}</td>
                  <td className='min-w-200px'>
                    {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                  </td>
                  <td className='min-w-300px'>
                    {NullProof({input: l, params: 'kondisi', limitChar: 100})}
                  </td>
                  <td className='min-w-300px'>
                    {NullProof({input: l, params: 'tanggapan_auditi', limitChar: 100})}
                  </td>
                  <td className='min-w-300px'>
                    {NullProof({input: l, params: 'rekomendasi', limitChar: 100})}
                  </td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Surat Tugas'}}>
            {NullProof({input: data, params: 'surat_tugas.nomor_surat'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Kondisi', props: {className: 'mb-4'}}}
          >
            {NullProof({input: data, params: 'kondisi'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Kriteria', props: {className: 'mb-4'}}}
          >
            {NullProof({input: data, params: 'kriteria'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Sebab', props: {className: 'mb-4'}}}
          >
            {NullProof({input: data, params: 'sebab'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Akibat', props: {className: 'mb-4'}}}
          >
            {NullProof({input: data, params: 'akibat'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Tanggapan Audit', props: {className: 'mb-4'}}}
          >
            {NullProof({input: data, params: 'tanggapan_auditi'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Rekomendasi', props: {className: 'mb-4'}}}
          >
            {NullProof({input: data, params: 'rekomendasi'})}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
