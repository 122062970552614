import {PageDataContent} from '../../../../../helper/Model'
import {TableInputDataProps} from '../../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Transfer',
  path: '/pengelolaan_keuangan/ls_bendahara/transfer/transfer_um_success',
  api: '/keuangan/transfer',
}

const BaseBreadcrumb = [
  {
    title: 'Pengelolaan Keuangan',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'LS Bendahara',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Pelaku Perjadin',
    },
    {
      title: 'No. ST',
    },
    {
      title: 'No. SPD',
    },
    {
      title: 'Tanggal Transfer',
    },
    {
      title: 'No Rek. Debet',
    },
    {
      title: 'Nama Rek. Debet',
    },
    {
      title: 'No Rek. Tujuan',
    },
    {
      title: 'Nama Rek. Tujuan',
    },
    {
      title: 'UM/VF',
    },
    {
      title: 'Jumlah',
    },
    {
      title: 'Status',
    },
  ],
  link: {
    tambah: {
      id: 'tambah',
      protect: 'tambah',
      title: 'Tambah',
      route: 'tambah',
    },
    submenu: [
      {
        id: 'import',
        protect: 'import',
        title: 'Import Data Excel',
        route: 'import',
      },
    ],
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'id',
        protect: 'ubah/:id',
        title: 'Ubah',
        route: 'ubah/:id',
      },
      // {
      //   id: 'perjadin_id',
      //   protect: 'pdf/:perjadin_id',
      //   title: 'PDF SPD Rampung',
      //   route:
      //     '/evaluasi_pelaporan/verifikasi_perjalanan_dinas/form_bukti_perjalanan/detail/:perjadin_id',
      // },
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: '',
      },
    ],
  },
  route: {
    url: InfoData.api,
    query: 'status=success&um_uf=um',
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

export const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}
