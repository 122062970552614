/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import { PageTitle } from '../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {PdfPageViewer} from '../../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {CardUi} from '../../../../../helper/AppUi'
import {TableAction, TableInput, TableColumn, TableRow, onLoadProps as onLoadPropsTable} from '../../../../../helper/TableInput'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../../helper/AppFunction'
import FormInput, {
  onLoadProps as onLoadPropsForm,
} from '../../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../helper/TableInputDetail'
import {ProtectComponentRoles} from '../../../../../helper/UserRolesValidation'
import Modal from '../../../../../helper/Modal'
import ExcelLayout from './ExcelLayout'

const MainWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data)}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          onFeedback={(e) => {
            if (e?.id === 'lihat') {
              console.log(e)
            }
          }}
          options={{
            checkbox: [
              {
                title: 'Lihat PDF',
                name: 'lihat-pdf',
                icon: 'RiFilePdfLine',
                type: 'link',
                url: `${InfoData.path}/pdf/pilihan`,
                api: '',
              },
              {
                title: 'Lihat Excel',
                name: 'lihat-excel',
                icon: 'RiFileExcelLine',
                type: 'link',
                url: `${InfoData.path}/pdf/pilihan`,
                api: '',
              },
            ],
          }}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn
                      modeResponsive={TableInputData.modeResponsive}
                      label='No'
                      className='min-w-100px'
                    >
                      {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                    </TableColumn>
                    <TableColumn
                      modeResponsive={TableInputData.modeResponsive}
                      label='Pengguna Anggaran'
                      className='min-w-150px'
                    >
                      {NullProof({input: l, params: 'pengguna_anggaran.nama'})}
                    </TableColumn>
                    <TableColumn
                      modeResponsive={TableInputData.modeResponsive}
                      label='Jenis Perjalanan Dinas'
                      className='min-w-150px'
                    >
                      {NullProof({input: l, params: 'jenis'})}
                    </TableColumn>
                    <TableColumn
                      modeResponsive={TableInputData.modeResponsive}
                      label='No Surat Tugas'
                      className='min-w-150px'
                    >
                      {NullProof({input: l, params: 'nomor_surat'})}
                    </TableColumn>
                    <TableColumn
                      modeResponsive={TableInputData.modeResponsive}
                      label='Satker Tujuan'
                      className='min-w-200px'
                    >
                      {NullProof({input: l, params: 'tujuan', isMap: true})?.map((item: any, index: number) => (
                        <Fragment key={index}>
                          <div>
                            {`${index + 1}. `}
                            <b>Wilayah:</b>{' '}
                            {`${NullProof({
                              input: item,
                              params: 'provinsi',
                            })} - ${NullProof({
                              input: item,
                              params: 'kota',
                            })}`}
                          </div>
                          <div>
                            <b>Satuan Kerja:</b>{' '}
                            <br/>
                            {(item.satker?.kode || item.satker?.nama) && 
                              <>
                                <span>
                                  {`${NullProof({
                                    input: item,
                                    params: 'satker.kode',
                                  })} - ${NullProof({
                                    input: item,
                                    params: 'satker.nama_satker',
                                  })}`}
                                </span>
                                <br/>
                              </>
                            }
                            {(item.satker_2?.kode || item.satker_2?.nama) && 
                              <>
                                <span>
                                  {`${NullProof({
                                    input: item,
                                    params: 'satker_2.kode',
                                  })} - ${NullProof({
                                    input: item,
                                    params: 'satker_2.nama_satker',
                                  })}`}
                                </span>
                                <br/>
                              </>
                            }
                            {(item.satker_3?.kode || item.satker_3?.nama) && 
                              <>
                                <span>
                                  {`${NullProof({
                                    input: item,
                                    params: 'satker_3.kode',
                                  })} - ${NullProof({
                                    input: item,
                                    params: 'satker_3.nama_satker',
                                  })}`}
                                </span>
                                <br/>
                              </>
                            }
                          </div>
                        </Fragment>
                      ))}
                    </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  let InitPdfLayout = PdfLayout
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={InitPdfLayout}
        // justPreview={true}
        ExcelLayout={ExcelLayout}
        options={{
          isRedux: query?.id === 'pilihan',
        }}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <Modal
        id='modal_statusubah'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            isFeedback: true,
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              // console.log(e)
              setModal((p: any) => ({...p, statusUbah: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'fullname'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Username'}}>
              {NullProof({input: data, params: 'username'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Email'}}>
              {NullProof({input: data, params: 'email'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status'}}>
              <div className='d-flex align-items-center gap-2'>
                <div>{NullProof({input: data, params: 'status'})}</div>
                <div>
                  <ProtectComponentRoles id={'superadmin'}>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal((p: any) => ({...p, statusUbah: true}))
                      }}
                    >
                      Ubah Status
                    </button>
                  </ProtectComponentRoles>
                </div>
              </div>
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, PdfWrapper, DetailWrapper}
