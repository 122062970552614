import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'

const componentKoitmenKerja: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'kode',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode',
          placeholder: 'Kode',
        },
      },
    },
  },
  {
    name: 'kegiatan',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kegiatan',
          placeholder: 'Kegiatan',
        },
      },
    },
  },
  {
    name: 'indikator_keberhasilan',
    type: 'component',
    className: 'col-12',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Indikator Keberhasilan',
          placeholder: 'Indikator Keberhasilan',
        },
      },
    },
    component: [
      {
        name: 'indikator',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Indikator',
              placeholder: 'Indikator',
            },
          },
        },
      },
      {
        name: 'target_bulan',
        type: 'select',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        listData: [
            {title: 'Januari', value: 'Januari'},
            {title: 'Februari', value: 'Februari'},
            {title: 'Maret', value: 'Maret'},
            {title: 'April', value: 'April'},
            {title: 'Mei', value: 'Mei'},
            {title: 'Juni', value: 'Juni'},
            {title: 'Juli', value: 'Juli'},
            {title: 'Agustus', value: 'Agustus'},
            {title: 'September', value: 'September'},
            {title: 'Oktober', value: 'Oktober'},
            ],
        options: {
          input: {
            props: {
              title: 'target',
              placeholder: 'target',
            },
          },
        },
      },
      {
        name: 'target_bulan',
        type: 'select',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        listData: [
            {title: 'Januari', value: 'Januari'},
            {title: 'Februari', value: 'Februari'},
            {title: 'Maret', value: 'Maret'},
            {title: 'April', value: 'April'},
            {title: 'Mei', value: 'Mei'},
            {title: 'Juni', value: 'Juni'},
            {title: 'Juli', value: 'Juli'},
            {title: 'Agustus', value: 'Agustus'},
            {title: 'September', value: 'September'},
            {title: 'Oktober', value: 'Oktober'},
            ],
        options: {
          input: {
            props: {
              title: 'target',
              placeholder: 'target',
            },
          },
        },
      },
    ],
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'satker_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Satuan Kerja is required'),
    options: {
      input: {
        props: {
          title: 'Satuan Kerja',
          placeholder: 'Satuan Kerja',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker-unit',
        // useOnLoad: true,
        query: 'orderby=asc',
        params: 'kode,nama_satker',
        result: '$kode - $nama_satker',
        id: 'kode',
        type: 'table',
        table: {
          data: [
            {id: 'kode', type: 'text'},
            {id: 'nama_satker', type: 'text'},
            // {id: 'countST', type: 'text'},
            // {id: 'countUsulan', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-satker-unit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Kode'},
              {title: 'Nama Satker'},
              //   {title: 'Jumlah ST Terbit'},
              //   {title: 'Jumlah Usulan'},
            ],
            // link: {
            //   tambah: {
            //     id: 'tambah',
            //     protect: 'tambah',
            //     route: '',
            //     title: 'Tambah',
            //     type: 'feedback',
            //     input: inputListSatker,
            //   },
            //   action: [
            //     {
            //       id: 'ubah',
            //       protect: 'ubah/:id',
            //       title: 'Ubah',
            //       route: 'ubah/:id',
            //       type: 'feedback',
            //       input: inputListSatker,
            //     },
            //     {
            //       id: 'detail',
            //       protect: 'st_satker',
            //       title: 'Detail List ST Terbit',
            //       route: 'detail/:id',
            //       type: 'feedback',
            //     },
            //     {
            //       id: 'detail',
            //       protect: 'usulan_satker',
            //       title: 'Detail List Usulan',
            //       route: 'detail/:id',
            //       type: 'feedback',
            //     },
            //   ],
            // },
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'penandatangan_komitmen_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Penandatangan Komitmen',
          placeholder: 'Penandatangan Komitmen',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
            {id: 'level_jabatan', type: 'text'},
            {id: 'satker_4', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
              {title: 'Level Jabatan'},
              {title: 'Satker'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'target_waktu',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Target Waktu',
          placeholder: 'Target Waktu',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'petugas_pemantau_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Petugas Pemantau',
          placeholder: 'Petugas Pemantau',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
            {id: 'level_jabatan', type: 'text'},
            {id: 'satker_4', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
              {title: 'Level Jabatan'},
              {title: 'Satker'},
            ],
          },
        },
      },
    },
  },
//   {
//     name: 'komitmen_kerja',
//     type: 'component',
//     className: 'col-12',
//     validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
//     options: {
//       input: {
//         props: {
//           title: 'Komitmen Kerja',
//           placeholder: 'Komitmen Kerja',
//         },
//       },
//     },
//     component: componentKoitmenKerja,
//   },
  {
    className: 'col-12',
    name: 'pic_satker_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'PIC Teknis Satuan Kerja',
          placeholder: 'PIC Teknis Satuan Kerja',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
            {id: 'level_jabatan', type: 'text'},
            {id: 'satker_4', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
              {title: 'Level Jabatan'},
              {title: 'Satker'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'pic_irjen_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'PIC Teknis Inspektorat Jenderal',
          placeholder: 'PIC Teknis Inspektorat Jenderal',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
            {id: 'level_jabatan', type: 'text'},
            {id: 'satker_4', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
              {title: 'Level Jabatan'},
              {title: 'Satker'},
            ],
          },
        },
      },
    },
  },
]

export {formInputList}
