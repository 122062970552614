import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../../helper/template/FormInputTemplate'
import {getListYear} from '../../../../../helper/DateFns'
import {inputList as inputJenisNaskah} from '../../klasifikasi-naskah/InputLayout'

const filterFormInputList: FormInputProps[] = [
  ...inputLayoutBuilder({
    input: [{template: 'search'}, {template: 'status'}],
  }),
]

const formInputList: FormInputProps[] = [
  {
    name: 'label',
    type: 'label',
    className: 'col-12',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Identitas Pengirim Naskah',
      },
    },
  },
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Pegawai',
          placeholder: 'Nama Pegawai',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
            {id: 'level_jabatan', type: 'text'},
            {id: 'satker_4', type: 'text'},
          ],
          protect: '',
          props: {
            filter: [
              ...inputLayoutBuilder({
                input: [{template: 'checkbox', name: 'irjen', title: 'Pegawai Itjen'}],
              }),
            ],
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
              {title: 'Level Jabatan'},
              {title: 'Satker'},
            ],
          },
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    className: 'col-12',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Detail Isi Naskah',
      },
    },
  },
  {
    name: 'jenis_naskah',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jenis Naskah',
          placeholder: 'Jenis Naskah',
        },
      },
      datalist: {
        api: '/tata_usaha/jenis-naskah',
        id: 'id',
        params: 'text',
        result: '$text',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'text',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/jenis-naskah',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Text',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputJenisNaskah,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputJenisNaskah,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    name: 'sifat_naskah',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Sifat Naskah',
          placeholder: 'Sifat Naskah',
        },
      },
      datalist: {
        api: '/tata_usaha/sifat-naskah',
        id: 'id',
        params: 'text',
        result: '$text',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'text',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/sifat-naskah',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Text',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputJenisNaskah,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputJenisNaskah,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    name: 'tingkat_urgensi',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tingkat Urgensi',
          placeholder: 'Tingkat Urgensi',
        },
      },
      datalist: {
        api: '/tata_usaha/tingkat-urgensi',
        id: 'id',
        params: 'text',
        result: '$text',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'text',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/tingkat-urgensi',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Text',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputJenisNaskah,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputJenisNaskah,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    name: 'nomor_naskah',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Naskah',
          placeholder: 'Nomor Naskah',
        },
      },
    },
  },
  {
    name: 'nomor_ref',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Referensi',
          placeholder: 'Nomor Referensi',
        },
      },
    },
  },
  {
    name: 'tanggal_naskah',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Naskah',
          placeholder: 'Tanggal Naskah',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'tanggal_diterima',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Naskah',
          placeholder: 'Tanggal Naskah',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'hal',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Hal',
          placeholder: 'Hal',
        },
      },
    },
  },
  {
    name: 'isi_ringkas',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Isi Ringkas',
          placeholder: 'Isi Ringkas',
        },
      },
    },
  },
  {
    name: 'file_naskah',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'File Naskah',
          placeholder: 'File Naskah',
          type: 'file',
          accept: '.pdf',
        },
      },
      upload: {
        url: {
          folder: 'naskah',
        },
      },
    },
  },
  {
    name: 'lampiran_naskah',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Lampiran Naskah',
          placeholder: 'Lampiran Naskah',
          type: 'file',
          accept: '.pdf',
          multiple: true,
        },
      },
      upload: {
        url: {
          folder: 'naskah',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    className: 'col-12',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Tujuan Utama',
      },
    },
  },
  {
    name: 'grup_tujuan',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Grup Tujuan',
          placeholder: 'Grup Tujuan',
        },
      },
      datalist: {
        api: '/tata_usaha/grup_tujuan',
        id: 'id',
        params: 'nama',
        result: '$nama',
      },
    },
  },
  {
    name: 'utama',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Utama (Internal / Srikandi)',
          placeholder: 'Utama (Internal / Srikandi)',
        },
      },
      datalist: {
        isListpicker: true,
        api: '/tata_usaha/tujuan',
        id: 'id',
        params: 'kepada',
        result: '$kepada',
      },
    },
  },
  {
    name: 'tembusan',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tembusan (Internal / Srikandi)',
          placeholder: 'Tembusan (Internal / Srikandi)',
        },
      },
      datalist: {
        api: '/tata_usaha/tembusan',
        id: 'id',
        params: 'kepada',
        result: '$kepada',
      },
    },
  },
]

const inputList: FormInputProps[] = [
  {
    name: 'nama',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
  {
    name: 'description',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Deskripsi',
          placeholder: 'Deskripsi',
        },
      },
    },
  },
  {
    name: 'information',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      textarea: {
        useTextEditor: true,
        props: {
          title: 'Informasi Tambahan',
          placeholder: 'Informasi Tambahan',
        },
      },
    },
  },
  {
    name: 'year',
    type: 'select',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    listData: getListYear(),
    options: {
      input: {
        props: {
          title: 'Tahun',
          placeholder: 'Tahun',
        },
      },
    },
  },
  {
    name: 'price',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Harga',
          placeholder: 'Harga',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'days',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Hari',
          placeholder: 'Hari',
        },
      },
    },
  },
  {
    name: 'total_price',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string(),
    disabled: true,
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Total Harga',
          placeholder: 'Total Harga',
          type: 'number',
        },
      },
    },
  },
  {
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Jangka Waktu',
        labelType: 'both',
      },
    },
  },
  {
    name: 'start_date',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Tanggal Mulai',
          placeholder: 'Tanggal Mulai',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'end_date',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Tanggal Selesai',
          placeholder: 'Tanggal Selesai',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'total_date',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string(),
    disabled: true,
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Total Hari',
          placeholder: 'Total Hari',
          type: 'date',
        },
      },
    },
  },
  {
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Jangka Waktu',
        labelType: 'line',
      },
    },
  },
  {
    name: 'members',
    type: 'component',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    disabled: true,
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Anggota',
          placeholder: 'Anggota',
          type: 'date',
        },
      },
    },
    component: [
      {
        name: 'name',
        type: 'input',
        className: 'col-12 col-md-6',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Nama',
              placeholder: 'Nama',
            },
          },
        },
      },
      {
        name: 'email',
        type: 'input',
        className: 'col-12 col-md-6',
        validator: Yup.string().required('Mohon untuk diisi.').email('Format harus Email.'),
        options: {
          input: {
            props: {
              title: 'Email',
              placeholder: 'Email',
            },
          },
        },
      },
      {
        name: 'link_member',
        type: 'datalist',
        className: 'col-12',
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Link Member',
              placeholder: 'Link Member',
            },
          },
          datalist: {
            api: '/account/users',
            id: 'id',
            params: 'fullname,email',
            result: (
              <div>
                <div>
                  <span className='fw-bold'>Nama: </span>
                  <span>$fullname</span>
                </div>
                <div>
                  <span className='fw-bold'>Email: </span>
                  <span>$email</span>
                </div>
              </div>
            ),
          },
        },
      },
      {
        name: 'link_member2',
        type: 'datalist',
        className: 'col-12',
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Link Member',
              placeholder: 'Link Member',
            },
          },
          datalist: {
            api: '/account/users',
            id: 'id',
            params: 'fullname,email',
            type: 'table',
            result: (
              <div>
                <div>
                  <span className='fw-bold'>Nama: </span>
                  <span>$fullname</span>
                </div>
                <div>
                  <span className='fw-bold'>Email: </span>
                  <span>$email</span>
                </div>
              </div>
            ),
            table: {
              protect: '/sample/sample',
              data: [
                {
                  id: 'fullname',
                  type: 'text',
                },
                {
                  id: 'email',
                  type: 'text',
                },
              ],
              props: {
                route: {
                  url: '/acccount/users',
                },
                headerData: [
                  {
                    title: 'No.',
                    props: {className: 'min-w-50px'},
                  },
                  {
                    title: 'Nama',
                  },
                  {
                    title: 'Email',
                  },
                ],
              },
            },
          },
        },
      },
    ],
  },
]

export {formInputList, filterFormInputList}
