/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../helper/AppFunction'
import {
  TableInputDetail,
  TableInputDetailFile,
  TableInputDetailColumn,
} from '../../../helper/TableInputDetail'
import FormInput from '../../../helper/FormInput'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '100px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='NIP' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'nip'})}
                  </TableColumn>
                  <TableColumn label='Nama' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'nama'})}
                  </TableColumn>
                  <TableColumn label='Status kepegawaian' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'status_kepegawaian'})}
                  </TableColumn>
                  <TableColumn label='Pangkat / Golongan' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pangkat.pangkat'}) + ' / '}
                    {NullProof({input: l, params: 'pangkat.golongan'})}
                  </TableColumn>
                  <TableColumn label='Jabatan' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'jabatan.fungsional'})}
                  </TableColumn>
                  <TableColumn label='Unit Atasan Kerja' props={{style: {minWidth: '250px'}}}>
                    {NullProof({input: l, params: 'satker_3'})}
                  </TableColumn>
                  <TableColumn
                    label='Wilayah Kerja / Tim Kerja'
                    props={{style: {minWidth: '250px'}}}
                  >
                    {NullProof({input: l, params: 'unit_kerja'})}
                  </TableColumn>
                  {/* <td className='min-w-150px'>{i + 1}</td>
                  <td className='min-w-150px'>{NullProof({input: l, params: 'nip'})}</td>
                  <td className='min-w-150px'>{NullProof({input: l, params: 'nama'})}</td>
                  <td className='min-w-150px'>
                    {NullProof({input: l, params: 'status_kepegawaian'})}
                  </td>
                  <td className='min-w-150px'>
                    {NullProof({input: l, params: 'pangkat.pangkat'}) + ' / '}
                    {NullProof({input: l, params: 'pangkat.golongan'})}
                  </td>
                  <td className='min-w-150px'>
                    {NullProof({input: l, params: 'jabatan.fungsional'})}
                  </td>
                  <td className='min-w-250px'>{NullProof({input: l, params: 'satker_3'})}</td>
                  <td className='min-w-250px'>{NullProof({input: l, params: 'unit_kerja'})}</td> */}
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  const keyParams = {
    profil: [
      {title: 'Kode KAU', param: 'kode_kua'},
      {title: 'NSM', param: 'nsm'},
      {title: 'NPSN', param: 'npsn'},
      {title: 'NIK', param: 'nik'},
      {title: 'Jenis Kelamin', param: 'jenis_kelamin'},
      {title: 'Tempat Lahir', param: 'tempat_lahir'},
      {title: 'Tanggal Lahir', param: 'tanggal_lahir'},
      {title: 'Pendidikan', param: 'pendidikan'},
      {title: 'Agama', param: 'agama'},
      {title: 'Status Kawin', param: 'status_kawin'},
      {title: 'Email', param: 'email'},
      {title: 'Telepon', param: 'telepon'},
      {title: 'No HP', param: 'no_hp'},
      {title: 'Alamat', param: 'alamat_1'},
      {title: 'Kecamatan', param: 'alamat_2'},
      {title: 'Kabupatan/Kota', param: 'kab_kota'},
      {title: 'Provinsi', param: 'provinsi'},
      {title: 'Kode Pos', param: 'kode_pos'},
      {title: 'Kode Lokasi', param: 'kode_lokasi'},
      {title: 'No Rekening', param: 'no_rek'},
      {title: 'Nama Rekening', param: 'nama_rek'},
      {title: 'Nama Bank', param: 'bank'},
    ],
    tmt: [
      {title: 'TMT CPNS', param: 'tmt_cpns'},
      {title: 'TMT Jabatan', param: 'tmt_jabatan'},
      {title: 'TMT Pangkat', param: 'tmt_pangkat'},
      {title: 'TMT Pangkat YAD', param: 'tmt_pangkat_yad'},
      {title: 'TMT KGB YAD', param: 'tmt_kgb_yad'},
      {title: 'TMT Pensiun', param: 'tmt_pensiun'},
    ],
    satker: [
      {title: 'Satker 1', param: 'satker_1', kode_satker: 'kode_satker_1'},
      {title: 'Satker 2', param: 'satker_2', kode_satker: 'kode_satker_2'},
      {title: 'Satker 3', param: 'satker_3', kode_satker: 'kode_satker_3'},
      {title: 'Satker 4', param: 'satker_4', kode_satker: 'kode_satker_4'},
      {title: 'Satker 5', param: 'satker_5', kode_satker: 'kode_satker_5'},
    ],
    bank: [],
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Nama'}}>
            {NullProof({input: data, params: 'nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'NIP'}}>
            {NullProof({input: data, params: 'nip'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Unit Kerja'}}>
            {NullProof({input: data, params: 'unit_kerja'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Signature'}}>
            <TableInputDetailFile
              data={NullProof({input: data, params: 'signature', isLabel: false})}
            />
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Wilayah'}}>
            {NullProof({input: data, params: 'wilayah'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Golongan Perjadin'}}>
            {NullProof({input: data, params: 'golongan_perjadin'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{
              value: 'Satker',
              props: {
                className: 'fs-5',
              },
            }}
          >
            <TableInputDetailColumn hideDivider title={{value: 'Kode'}}>
              {NullProof({input: data, params: 'satker.kode'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'satker.nama_satker'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{
              value: 'Jabatan',
              props: {
                className: 'fs-5',
              },
            }}
          >
            <TableInputDetailColumn hideDivider title={{value: 'Eselon'}}>
              {NullProof({input: data, params: 'level_jabatan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Fungsional'}}>
              {NullProof({input: data, params: 'jabatan.fungsional'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{
              value: 'Pangkat',
              props: {
                className: 'fs-5',
              },
            }}
          >
            <TableInputDetailColumn hideDivider title={{value: 'Golongan'}}>
              {NullProof({input: data, params: 'pangkat.golongan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Pangkat'}}>
              {NullProof({input: data, params: 'pangkat.pangkat'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Status Kepegawaian'}}>
            {NullProof({input: data, params: 'status_kepegawaian'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Level Jabatan'}}>
            {NullProof({input: data, params: 'level_jabatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kode Level Jabatan'}}>
            {NullProof({input: data, params: 'kode_level_jabatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tipe Jabatan'}}>
            {NullProof({input: data, params: 'tipe_jabatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kode Jabatan'}}>
            {NullProof({input: data, params: 'kode_jabatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tampil Jabatan'}}>
            {NullProof({input: data, params: 'tampil_jabatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Golongan Ruang'}}>
            {NullProof({input: data, params: 'gol_ruang'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn isResponsive='desktop' hideDivider>
            {keyParams.satker.map(
              (item: {title: string; param: string; kode_satker: string}, i: number) => {
                return (
                  <div className='my-5' key={i}>
                    <TableInputDetailColumn hideDivider title={{value: item.title}}>
                      {NullProof({input: data, params: item.param})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn hideDivider title={{value: `Kode ${item.title}`}}>
                      {NullProof({input: data, params: item.kode_satker})}
                    </TableInputDetailColumn>
                  </div>
                )
              }
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Satker full'}}>
            {NullProof({input: data, params: 'satuan_kerja'})}
          </TableInputDetailColumn>
          {keyParams.tmt.map((item: {title: string; param: string}, i: number) => {
            return (
              <TableInputDetailColumn key={i} title={{value: item.title}}>
                {NullProof({input: data, params: item.param, type: 'date'})}
              </TableInputDetailColumn>
            )
          })}
          <TableInputDetailColumn title={{value: 'Masa Kerja'}}>
            {NullProof({input: data, params: 'masakerja_tahun'})} Tahun{' '}
            {NullProof({input: data, params: 'masakerja_bulan'})} Bulan
          </TableInputDetailColumn>
          {keyParams.profil.map((item: {title: string; param: string}, i: number) => {
            const type = item.param === 'tanggal_lahir' ? 'date' : 'text'
            return (
              <TableInputDetailColumn key={i} title={{value: item.title}}>
                {NullProof({input: data, params: item.param, type: type})}
              </TableInputDetailColumn>
            )
          })}
          <TableInputDetailColumn title={{value: 'Tanda Tangan'}}>
            <TableInputDetailFile
              data={NullProof({input: data, params: 'img_ttd', isLabel: false})}
            />
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
