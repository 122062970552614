import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  // {
  //   className: 'col-12',
  //   name: 'eselon',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Eselon is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Eselon',
  //         placeholder: 'Eselon',
  //       }
  //     }
  //   },
  // },
  {
    className: 'col-12',
    name: 'fungsional',
    type: 'input',
    value: '',
    validator: Yup.string().required('Fungsional is required'),
    options: {
      input: {
        props: {
          title: 'Fungsional',
          placeholder: 'Fungsional',
        },
      },
    },
  },
]

export {formInputList}
