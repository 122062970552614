import {DataRoutesProps} from '../../UserRolesValidation'
import PersiapanPelaksanaanPengawasanInternal from './persiapan-pelaksanaan-pengawasan-internal/PersiapanPelaksanaanPengawasanInternal'
import PelaksanaanPenugasanAssurans from './pelaksanaan-penugasan-assurans/PelaksanaanPenugasanAssurans'
import PenyusunanRencanaProgja from './penyusunan-rencana-progja/PenyusunanRencanaProgja'
import PelaporanPengawasanInternal from "./pelaporan-pengawasan-internal/PelaporanPengawasanInternal";

const PelaksanaanPenugasanKonsultasi: DataRoutesProps = {
  title: 'Pelaksanaan Penugasan Konsultasi',
  icon: 'element-10',
  route: 'pelaksanaan_penugasan_konsultasi',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Penyusunan Manajemen Resiko',
      icon: 'element-10',
      route: 'penyusunan_manajemen_resiko',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penyusunan Laporan Keuangan',
      icon: 'element-10',
      route: 'penyusunan_laporan_keuangan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Pengawasan Operasional Haji',
      icon: 'element-10',
      route: 'pengawasan_operasional_haji',
      elements: <></>,
      subroute: [],
    },
  ],
}

const PengawasanInternal: DataRoutesProps = {
  title: 'Pengawasan Internal',
  icon: 'element-10',
  route: 'pengawasan',
  elements: <></>,
  subroute: [
    {...PersiapanPelaksanaanPengawasanInternal},
    {...PenyusunanRencanaProgja},
    {...PelaksanaanPenugasanAssurans},
    {...PelaksanaanPenugasanKonsultasi},
    {...PelaporanPengawasanInternal}
  ],
}

export default PengawasanInternal
