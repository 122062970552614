import {DataRoutesProps} from '../../../UserRolesValidation'
import {MainRoutes as LogBerkasMasukRoutes} from './LogNaskahMasuk'
import {MainRoutes as LogBerkasKeluarRoutes} from './LogNaskahKeluar'
import {MainRoutes as LogBerkasDisposisipRoutes} from './LogNaskahDisposisi'


const LogNaskahRoute: DataRoutesProps = {
  title: 'Log Naskah',
  icon: 'element-10',
  route: 'log_naskah',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Log Naskah Masuk',
      icon: 'element-10',
      route: 'log_naskah_masuk',
      elements: <></>,
      subroute: LogBerkasMasukRoutes,
    },
    {
      isPage: true,
      title: 'Log Naskah Keluar',
      icon: 'element-10',
      route: 'log_naskah_keluar',
      elements: <></>,
      subroute: LogBerkasKeluarRoutes,
    },
    {
      isPage: true,
      title: 'Log Naskah Disposisi',
      icon: 'element-10',
      route: 'log_naskah_disposisi',
      elements: <></>,
      subroute: LogBerkasDisposisipRoutes,
    },
  ],
}

export default LogNaskahRoute
