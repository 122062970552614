import Component from '../../helper/i18n/id'
import Menu from './menu'
import Pages from './page'
import Sidebar from './sidebar'
import Global from './global'

const i18nIdAllMessages = {
  component: Component,
  sidebar: Sidebar,
  page: Pages,
  menu: Menu,
  global: Global,
}

export default i18nIdAllMessages
