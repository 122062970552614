import {DataRoutesProps} from '../../../UserRolesValidation'
import {MainRoutes as DaftarBerkasAktifRoutes} from './DaftarBerkasAktif'
import {MainRoutes as DaftarBerkasInaktifRoutes} from './DaftarBerkasInaktif'
import {MainRoutes as PeminjamanArsipRoutes} from './PeminjamanArsip'
import {MainRoutes as DaftarArsipDipinjamRoutes} from './DaftarArsipDipinjam'

const NaskahBerkasRoute: DataRoutesProps = {
  title: 'Berkas',
  icon: 'element-10',
  route: 'berkas',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Daftar Berkas Aktif',
      icon: 'element-10',
      route: 'berkas_aktif',
      elements: <></>,
      subroute: DaftarBerkasAktifRoutes,
    },
    {
      isPage: true,
      title: 'Daftar Berkas Inaktif',
      icon: 'element-10',
      route: 'berkas_inaktif',
      elements: <></>,
      subroute: DaftarBerkasInaktifRoutes,
    },
    {
      isPage: true,
      title: 'Peminjaman Arsip',
      icon: 'element-10',
      route: 'peminjaman_arsip',
      elements: <></>,
      subroute: PeminjamanArsipRoutes,
    },
    {
      isPage: true,
      title: 'Daftar Arsip Dipinjam',
      icon: 'element-10',
      route: 'arsip_dipinjam',
      elements: <></>,
      subroute: DaftarArsipDipinjamRoutes,
    },
  ],
}

export default NaskahBerkasRoute
