import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12 col-md-6',
    name: 'tanggal',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal',
          placeholder: 'Tanggal',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Sudah dibayar',
        value: 'true',
      },
      {
        title: 'Belum dibayar',
        value: 'false',
      },
    ],
    validator: Yup.string().required('Status wajib diisi.'),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Uraian Wajib diisi.'),
    options: {
      textarea: {
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Surat Tugas wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        // query: 'id_anggota=$pegawai_id',
        // useOnLoad: true,
        params: 'nama_kegiatan,deskripsi_kegiatan,totalRAB',
        result: '$nama_kegiatan',
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama_kegiatan', type: 'text'},
            {id: 'deskripsi_kegiatan', type: 'text'},
            {id: 'totalRAB', type: 'currency'},
          ],
          protect: '',
          props: {
            route: {
              url: '/pengawasan_internal/surat-tugas',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama Kegiatan'},
              {title: 'Deskripsi Kegiatan'},
              {title: 'Total RAB'},
            ],
            link: {
              action: [
              ],
            },
          },
        },
      },
      // watchInput: {
      //   id: 'pegawai_id'
      // }
    }
  },
  {
    className: 'col-12 col-md-6',
    name: 'spm_mak',
    type: 'input',
    value: '',
    validator: Yup.string().required('MAK Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'MAK',
          placeholder: 'MAK',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'spm',
    type: 'input',
    value: '',
    validator: Yup.string().required('SPM Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SPM',
          placeholder: 'SPM',
          maxLength: 5,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'keg',
    type: 'input',
    value: '',
    validator: Yup.string().required('KEG Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'KEG',
          placeholder: 'KEG',
          maxLength: 5,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'out',
    type: 'input',
    value: '',
    validator: Yup.string().required('OUT Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'OUT',
          placeholder: 'OUT',
          maxLength: 4,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'sub_out',
    type: 'input',
    value: '',
    validator: Yup.string().required('SUB OUT Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SUB OUT',
          placeholder: 'SUB OUT',
          maxLength: 4,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'kom',
    type: 'input',
    value: '',
    validator: Yup.string().required('KOM Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'KOM',
          placeholder: 'KOM',
          maxLength: 4,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'sub_kom',
    type: 'input',
    value: '',
    validator: Yup.string().required('SUB KOM Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SUB KOM',
          placeholder: 'SUB KOM',
          maxLength: 2,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'akun',
    type: 'input',
    value: '',
    validator: Yup.string().required('AKUN Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'AKUN',
          placeholder: 'AKUN',
          maxLength: 7,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nilai',
    type: 'input',
    value: '',
    validator: Yup.number().required('Nilai Wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Nilai',
          placeholder: 'Nilai',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'realisasi',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Realisasi',
          placeholder: 'Realisasi',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'selisih',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Selisih',
          placeholder: 'Selisih',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'realisasi_transfer',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Realisasi Transfer',
          placeholder: 'Realisasi Transfer',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'selisih_trf',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Selisih Transfer',
          placeholder: 'Selisih Transfer',
          type: 'number',
        },
      },
    },
  },
]

const formUpdateList: FormInputProps[] = [
  {
    className: 'col-12 col-md-6',
    name: 'tanggal',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal',
          placeholder: 'Tanggal',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Sudah dibayar',
        value: 'true',
      },
      {
        title: 'Belum dibayar',
        value: 'false',
      },
    ],
    validator: Yup.string().required('Status wajib diisi.'),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Uraian Wajib diisi.'),
    options: {
      textarea: {
        props: {
          title: 'Uraian',
          placeholder: 'Uraian',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    disabled: true,
    validator: Yup.number().required('Surat Tugas wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        // query: 'id_anggota=$pegawai_id',
        // useOnLoad: true,
        params: 'nama_kegiatan,deskripsi_kegiatan,totalRAB',
        result: '$nama_kegiatan',
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama_kegiatan', type: 'text'},
            {id: 'deskripsi_kegiatan', type: 'text'},
            {id: 'totalRAB', type: 'currency'},
          ],
          protect: '',
          props: {
            route: {
              url: '/pengawasan_internal/surat-tugas',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama Kegiatan'},
              {title: 'Deskripsi Kegiatan'},
              {title: 'Total RAB'},
            ],
            link: {
              action: [
              ],
            },
          },
        },
      },
      // watchInput: {
      //   id: 'pegawai_id'
      // }
    }
  },
  {
    className: 'col-12 col-md-6',
    name: 'spm_mak',
    type: 'input',
    value: '',
    validator: Yup.string().required('MAK Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'MAK',
          placeholder: 'MAK',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'spm',
    type: 'input',
    value: '',
    validator: Yup.string().required('SPM Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SPM',
          placeholder: 'SPM',
          maxLength: 5,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'keg',
    type: 'input',
    value: '',
    validator: Yup.string().required('KEG Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'KEG',
          placeholder: 'KEG',
          maxLength: 5,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'out',
    type: 'input',
    value: '',
    validator: Yup.string().required('OUT Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'OUT',
          placeholder: 'OUT',
          maxLength: 4,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'sub_out',
    type: 'input',
    value: '',
    validator: Yup.string().required('SUB OUT Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SUB OUT',
          placeholder: 'SUB OUT',
          maxLength: 4,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'kom',
    type: 'input',
    value: '',
    validator: Yup.string().required('KOM Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'KOM',
          placeholder: 'KOM',
          maxLength: 4,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'sub_kom',
    type: 'input',
    value: '',
    validator: Yup.string().required('SUB KOM Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SUB KOM',
          placeholder: 'SUB KOM',
          maxLength: 2,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6 col-lg-4',
    name: 'akun',
    type: 'input',
    value: '',
    validator: Yup.string().required('AKUN Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'AKUN',
          placeholder: 'AKUN',
          maxLength: 7,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nilai',
    type: 'input',
    value: '',
    validator: Yup.number().required('Nilai Wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Nilai',
          placeholder: 'Nilai',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'realisasi',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Realisasi',
          placeholder: 'Realisasi',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'selisih',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Selisih',
          placeholder: 'Selisih',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'realisasi_transfer',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Realisasi Transfer',
          placeholder: 'Realisasi Transfer',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'selisih_trf',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Selisih Transfer',
          placeholder: 'Selisih Transfer',
          type: 'number',
        },
      },
    },
  },
]

export {formInputList, formUpdateList}
