import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {InfoData, PageData, TableInputData, TableInputDetailData} from './PageData'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../helper/AppFunction'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CardUi} from '../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../helper/TableInput'
import FormInput from '../../../helper/FormInput'
import {formInputList} from './InputLayout'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import Modal from '../../../helper/Modal'
import {ReactPdfViewer} from '../../../helper/ReactPdf'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              //mengelompokan data sesuai kolom database
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Nama Pegawai' className='min-w-150px'>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </TableColumn>
                  <TableColumn label='Keterangan' className='min-w-150px'>
                    {NullProof({input: l, params: 'keterangan'})}
                  </TableColumn>
                  <TableColumn label='Status' className='min-w-150px'>
                    {NullProof({input: l, params: 'status'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  return (
    <>
      <Modal
        id='modal_lampiran'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Lampiran'
      >
        <ReactPdfViewer url={`/${data?.file}`} />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Nama'}}>
            {NullProof({input: data, params: 'pegawai.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Keterangan'}}>
            {NullProof({input: data, params: 'keterangan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'File'}}>
            {NullProof({input: data, params: 'file'}) && (
              <button
                className='btn btn-primary btn-sm'
                onClick={() => {
                  setModal((p: any) => ({...p, statusUbah: true}))
                }}
              >
                Lihat Dokumen
              </button>
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Status'}}>
            {NullProof({input: data, params: 'status'})}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper}
