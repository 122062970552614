import {
    MainWrapper,
    // PdfWrapper,
    TambahWrapper,
    UbahWrapper,
    DetailWrapper,
  } from '../../../pages/tata-usaha/pengelolaan-naskah-dinas/daftar-disposisi/MainWrapper'
  import {DataRoutesProps} from '../../UserRolesValidation'
  
  const DaftarDisposisiRoute: DataRoutesProps[] = [
    {
      icon: '',
      title: '',
      route: '*',
      elements: <MainWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'tambah',
      elements: <TambahWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'ubah/:id',
      elements: <UbahWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'detail/:id',
      elements: <DetailWrapper />,
      subroute: [],
    },
    // {
    //   icon: '',
    //   title: '',
    //   route: 'pdf/:id',
    //   elements: <PdfWrapper />,
    //   subroute: [],
    // },
  ]
  
  export default DaftarDisposisiRoute
  