import {divIcon} from 'leaflet'
import {MapContainer, Marker, Polygon, TileLayer} from 'react-leaflet'
import {FC, Fragment, useEffect, useRef, useState} from 'react'
import {getData} from './FormAxios'
import Modal from './Modal'
import GeomapData from './GeomapData'
import {formatDate} from './DateFns'
import geoJsonData from '../helper/geodata/indonesia-province.json'
import ReactIcon from './ReactIcon'
import {renderToStaticMarkup} from 'react-dom/server'
import {WithChildren} from '../../_metronic/helpers'
import ReactLoading from './ReactLoading'

interface ReactLeafletProps {
  id: string
  data?: ReactLeafletDataProps[]
  dataProps: {
    api: string /** API Datalist */
    params: string /** ID Data Tampilan List */
    header: string
    result: string /** Tampilan DataList */
    id: string /** id untuk post */
    filter?: string
  }
  typeMap?: 'pin' | 'area' | 'both'
  title?: string
  onComplete?: any
  onOpenDetail?: any
}

interface ReactLeafletDataProps {
  id: string // Nama Provinsi
  data: any[]
}

const ReactLeaflet: FC<ReactLeafletProps & WithChildren> = ({
  id,
  dataProps,
  title,
  typeMap = 'both',
  data,
  onComplete,
  onOpenDetail,
  children,
}) => {
  const [loading, setLoading] = useState<any>(true)
  const [modal, setModal] = useState<any>({})
  const [dataDetail, setDataDetail] = useState<any>([
    {
      id: 'Jawa Barat',
      data: [
        {
          nama: 'Nama Pegawai',
          kota: 'Bogor',
          saldo: 500000,
        },
        {
          nama: 'Nama Pegawai',
          kota: 'Bandung',
          saldo: 500000,
        },
      ],
    },
    {
      id: 'DKI Jakarta',
      data: [
        {
          nama: 'Nama Pegawai',
          kota: 'Jakarta Pusat',
          saldo: 200000000,
        },
        {
          nama: 'Nama Pegawai',
          kota: 'Jakarta Utara',
          saldo: 200000000,
        },
        {
          nama: 'Nama Pegawai',
          kota: 'Jakarta Timur',
          saldo: 200000000,
        },
      ],
    },
  ])
  const mapRef = useRef<HTMLDivElement>(null)
  const mapRefContainer = useRef(null)

  const createHeader = () => {
    const dlProps: any[] = dataProps.header?.split(',') || []
    return dlProps
  }
  const createDataTable = (i: number, data: any) => {
    const dlTitleProps: any = dataProps.result?.split(',') || []
    let dlTitle = dlTitleProps[i]
    const dlProps: any[] = dataProps.params?.split(',') || []
    const filter: any[] = dataProps.filter?.split(',') || []
    dlProps.forEach((l) => {
      const attr: string[] = l.split('.')
      let result: any = data
      let isNull = false
      for (const attrs of attr) {
        if (result == null) {
          isNull = true
          break
        }
        result = result[attrs]
      }
      filter?.forEach((lf: any) => {
        if (lf === 'formatDate') {
          dlTitle = dlTitle?.replaceAll(
            `${lf}=$${l}`,
            isNull || result == null ? '-' : formatDate({date: result, dateFormat: 'dd MMMM yyyy'})
          )
        }
      })
      if (isNull || result == null) {
        dlTitle = dlTitle?.replaceAll(`$${l}`, '-')
      } else {
        dlTitle = dlTitle?.replaceAll(`$${l}`, result)
      }
    })
    return dlTitle
  }

  const loadData = async () => {
    try {
      const detail: any = await getData('', `${dataProps.api}` || '')
      if (detail.status) {
        const resultData: any[] = detail.data || []
        let result: ReactLeafletDataProps[] = []
        for (const l of resultData) {
          const capitalizeWords = (input: string): string => {
            return input
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          }
          const provinsi = capitalizeWords(l?.provinsi.toLowerCase())
          const iProvinsi = result.findIndex((lf) => lf.id === provinsi)
          if (iProvinsi > -1) {
            result[iProvinsi].data.push(l)
          } else {
            let json: ReactLeafletDataProps = {
              id: provinsi,
              data: [l],
            }
            result.push(json)
          }
        }
        setDataDetail(result)
        onComplete && onComplete(result)
        setLoading(false)
      } else {
      }
    } catch (_) {}
  }

  const swapArr = (arr: any) => {
    let json: any = []
    for (const l of arr) {
      let subJson = []
      const subArr = l[0]
      for (const lsub of subArr) {
        const newArr = [lsub[1], lsub[0]]
        subJson.push(newArr)
      }
      subJson = [subJson]
      json.push(subJson)
    }
    return json
  }
  useEffect(() => {
    if (dataProps.api) {
      loadData()
    } else {
      setLoading(false)
    }
  }, [])
  return (
    <>
      <Modal
        id={`modal_${id}`}
        title={`${title} - ${modal?.data}`}
        isShow={modal?.status}
        onClose={() => {
          setModal((p: any) => ({...p, status: false}))
        }}
      >
        {children}
        {/* <div>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800'>
                  {createHeader().map((l: any, i: number) => (
                    <th className='min-w-200px' key={i}>
                      {l}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataDetail.filter((l: any) => l.id === modal?.data).length > 0 ? (
                  dataDetail
                    .filter((l: any) => l.id === modal?.data)[0]
                    ?.data.map((l: any, i: number) => (
                      <tr key={i}>
                        {Object.keys(l).map((lsub: any, isub: number) => (
                          <td key={isub}>{createDataTable(isub, l)}</td>
                        ))}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td>Data Kosong</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div> */}
      </Modal>
      <div className='card card-flush p-4 d-flex flex-column gap-2'>
        <div>
          <div className='fs-5 text-gray-500'>{title}</div>
        </div>
        <div className='position-relative'>
          {loading && (
            <div
              className='position-absolute d-flex align-items-center justify-content-center bg-light bg-opacity-50 left-0 top-0 w-100 h-100'
              style={{zIndex: 10}}
            >
              <ReactLoading />
            </div>
          )}
          <div className='overflow-hidden relative' ref={mapRef}>
            <MapContainer
              center={[0.859217, 123.75959]}
              zoom={5}
              maxZoom={10}
              style={{height: '400px'}}
              ref={mapRefContainer}
            >
              <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {GeomapData.map((l, i) => {
                let saldo: number = 0
                const detail: any = dataDetail.filter((j: any) => j.id === l.name)
                const geoData = geoJsonData.features.filter((geodata) =>
                  l.name.includes(geodata.properties.state)
                )
                if (geoData.length > 0) {
                  l.geodata = geoData[0].geometry
                }
                if (detail.length > 0) {
                  const detailAll: any = detail[0].data
                  detailAll?.forEach((j: any) => {
                    saldo += j?.saldo || 0
                  })
                }
                return (
                  <Fragment key={i}>
                    <Polygon
                      eventHandlers={{
                        click: (e) => {
                          setModal({
                            status: true,
                            data: l.name,
                          })
                          onOpenDetail && onOpenDetail(l.name)
                        },
                      }}
                      pathOptions={{
                        fillColor:
                          typeMap === 'both' || typeMap === 'area'
                            ? saldo > 0 && saldo <= 1000000 * 100
                              ? '#f1c40f'
                              : saldo > 1000000 * 100 && saldo <= 1000000 * 500
                              ? '#f18f0f'
                              : saldo > 1000000 * 500 && saldo <= 1000000 * 1000
                              ? '#e74c3c'
                              : saldo > 1000000 * 1000 && saldo <= 1000000 * 5000
                              ? '#85170c'
                              : saldo > 1000000 * 5000
                              ? '#000000'
                              : '#2ecc71'
                            : '#00000000',
                        stroke: typeMap === 'both' || typeMap === 'area' ? true : false,
                        color: '#000000',
                        weight: 0.5,
                        fillOpacity: 0.5,
                      }}
                      positions={swapArr(l.geodata?.coordinates) || []}
                    />
                    <Marker
                      eventHandlers={{
                        click: (e) => {
                          setModal({
                            status: true,
                            data: l.name,
                          })
                          onOpenDetail && onOpenDetail(l.name)
                        },
                      }}
                      position={l.location}
                      icon={divIcon({
                        html: renderToStaticMarkup(
                          <ReactIcon
                            icon='RiMapPin2Fill'
                            props={{
                              style: {
                                fontSize: '30px',
                                color:
                                  typeMap === 'pin'
                                    ? saldo > 0 && saldo <= 1000000 * 100
                                      ? '#f1c40f'
                                      : saldo > 1000000 * 100 && saldo <= 1000000 * 500
                                      ? '#f18f0f'
                                      : saldo > 1000000 * 500 && saldo <= 1000000 * 1000
                                      ? '#e74c3c'
                                      : saldo > 1000000 * 1000 && saldo <= 1000000 * 5000
                                      ? '#85170c'
                                      : saldo > 1000000 * 5000
                                      ? '#000000'
                                      : '#2ecc71'
                                    : typeMap === 'area'
                                    ? '#00000000'
                                    : '#FF0000',
                              },
                            }}
                          />
                        ),
                        className:
                          'bg-transparent d-flex justify-content-center align-items-center w-50px h-50px ms-n5 mt-n5 pb-8',
                      })}
                    ></Marker>
                  </Fragment>
                )
              })}
            </MapContainer>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReactLeaflet
