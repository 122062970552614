import {
  MainWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
} from '../../../../pages/kepegawaian/data-audit/data-kegiatan/MainWrapper'
import {DataRoutesProps} from '../../../UserRolesValidation'

const DataKegiatanRoutes: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
]

export {DataKegiatanRoutes}
