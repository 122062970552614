import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
// import {Helmet} from 'react-helmet'

// const redirectUrlList: {host: string; target: string; protocol: string}[] = [
//   {protocol: 'https:', host: 'siapi.kemenag.go.id', target: 'siapi.cloud'},
//   // {protocol: 'http:', host: 'localhost:5011', target: 'siapi.cloud'},
// ]

const App = () => {
  // useEffect(() => {
  //   redirectUrlList.map((l, i) => {
  //     if (l.host === window.location.host) {
  //       const targetHost = `${l.protocol}//${l.target}`
  //       window.location.href = targetHost
  //     }
  //   })
  // }, [redirectUrlList])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {/* <Helmet>
        <meta
          http-equiv='Content-Security-Policy'
          content="
            default-src 'self' 'unsafe-eval' 'unsafe-inline'
                *.googleapis.com
                *.gstatic.com
                *.openstreetmap.org
                *.kemenag.go.id
                https://apinyasiapi.cloud
                https://siapi.cloud/;
            img-src 'self' https: data: blob:;
            frame-src 'self' https: data: blob:;
          "
        />
      </Helmet> */}
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
