import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    name: 'pka_id',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'PKA',
          placeholder: 'PKA',
        },
      },
      datalist: {
        api: '/pengawasan_internal/program-kerja-audit',
        id: 'id',
        params: 'judul',
        result: '$judul',
      },
    },
  },
  {
    name: 'kode',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode',
          placeholder: 'Kode',
        },
      },
    },
  },
  {
    name: 'no_kka',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor KKA',
          placeholder: 'Nomor KKA',
        },
      },
    },
  },
  {
    name: 'ref_pka',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Ref PKA',
          placeholder: 'Ref PKA',
        },
      },
    },
  },
  {
    name: 'disusun_oleh',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Disusun Oleh',
          placeholder: 'Disusun Oleh',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        id: 'id',
        params: 'nip,nama',
        result: (
          <div>
            <div>
              <span className='fw-bold'>NIP: </span>
              <span>$nip</span>
            </div>
            <div>
              <span className='fw-bold'>Nama: </span>
              <span>$nama</span>
            </div>
          </div>
        ),
      },
    },
  },
  {
    name: 'tanggal_paraf',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Paraf',
          placeholder: 'Tanggal Paraf',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'direviu_oleh',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Disusun Oleh',
          placeholder: 'Disusun Oleh',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        id: 'id',
        params: 'nip,nama',
        result: (
          <div>
            <div>
              <span className='fw-bold'>NIP: </span>
              <span>$nip</span>
            </div>
            <div>
              <span className='fw-bold'>Nama: </span>
              <span>$nama</span>
            </div>
          </div>
        ),
      },
    },
  },
  {
    name: 'tanggal_review',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Reviu',
          placeholder: 'Tanggal Reviu',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'disetujui_oleh',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Disusun Oleh',
          placeholder: 'Disusun Oleh',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        id: 'id',
        params: 'nip,nama',
        result: (
          <div>
            <div>
              <span className='fw-bold'>NIP: </span>
              <span>$nip</span>
            </div>
            <div>
              <span className='fw-bold'>Nama: </span>
              <span>$nama</span>
            </div>
          </div>
        ),
      },
    },
  },
  {
    name: 'tanggal_disetujui',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Disetujui',
          placeholder: 'Tanggal Disetujui',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'sasaran',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Sasaran',
          placeholder: 'Sasaran',
        },
      },
    },
  },
  {
    name: 'periode',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Periode',
          placeholder: 'Periode',
        },
      },
    },
  },
  {
    name: 'fungsi',
    type: 'select',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    listData: [
      {title: 'PERENCANAAN', value: 'FUNGSI PERENCANAAN'},
      {title: 'PELAKSANAAN', value: 'FUNGSI PELAKSANAAN'},
      {title: 'EVALUASI DAN PELAPORAN', value: 'FUNGSI EVALUASI DAN PELAPORAN'},
      {
        title: 'CAPAIAN SASARAN HASIL (OUTCOME)',
        value: 'FUNGSI CAPAIAN SASARAN HASIL (OUTCOME)',
      },
    ],
    options: {
      input: {
        props: {
          title: 'Fungsi',
          placeholder: 'Fungsi',
        },
      },
    },
  },
  {
    name: 'subfungsi',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Sub Fungsi',
          placeholder: 'Sub Fungsi',
        },
      },
    },
  },
  {
    name: 'komponen',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Komponen',
          placeholder: 'Komponen',
        },
      },
    },
  },
  {
    name: 'bobot',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Bobot',
          placeholder: 'Bobot',
        },
      },
    },
  },
  {
    name: 'pengukuran_audit',
    type: 'component',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Pengukuran Audit',
          placeholder: 'Pengukuran Audit',
        },
      },
    },
    component: [
      {
        name: 'uraian',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Uraian',
              placeholder: 'Uraian',
            },
          },
        },
      },
      {
        name: 'bobot',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Bobot',
              placeholder: 'Bobot',
            },
          },
        },
      },
      {
        name: 'nilai',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Nilai',
              placeholder: 'Nilai',
            },
          },
        },
      },
      {
        name: 'hasil',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Hasil',
              placeholder: 'Hasil',
            },
          },
        },
      },
      {
        name: 'penjelasan_langkah_kerja',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Penjelasan / Langkah Kerja',
              placeholder: 'Penjelasan / Langkah Kerja',
            },
          },
        },
      },
      {
        name: 'dokumen_sumber',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Dokumen Sumber',
              placeholder: 'Dokumen Sumber',
            },
          },
        },
      },
      {
        name: 'kondisi_positif',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Kondisi Positif',
              placeholder: 'Kondisi Positif',
            },
          },
        },
      },
      {
        name: 'kondisi_negatif',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Kondisi Negatif',
              placeholder: 'Kondisi Negatif',
            },
          },
        },
      },
      {
        name: 'akibat',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Akibat',
              placeholder: 'Akibat',
            },
          },
        },
      },
      {
        name: 'sebab',
        type: 'input',
        className: 'col-12',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Sebab',
              placeholder: 'Sebab',
            },
          },
        },
      },
    ],
  },
  {
    name: 'jumlah_bobot',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jumlah Bobot',
          placeholder: 'Jumlah Bobot',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'hasil',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Hasil',
          placeholder: 'Hasil',
        },
      },
    },
  },
]

export {formInputList}
