/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList, formInputStatus, formInputStatusEvalap} from './InputLayout'
import PdfLayout from './PdfLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import {CardUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../helper/AppFunction'
import FormInput from '../../../../helper/FormInput'
import {
  TableInputDetail,
  TableInputDetailColumn,
  TableInputDetailFile,
} from '../../../../helper/TableInputDetail'
import {ProtectComponent} from '../../../../helper/UserRolesValidation'
import Modal from '../../../../helper/Modal'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }

            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='NIP' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pegawai.nip'})}
                  </TableColumn>
                  <TableColumn label='Pegawai' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </TableColumn>
                  <TableColumn label='Surat Tugas' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                  </TableColumn>
                  <TableColumn label='Untuk Pembayaran' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'untuk_pembayaran'})}
                  </TableColumn>
                  <TableColumn label='Tanggal' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'tanggal_pembayaran', type: 'date'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    bendahara: false,
    evalap: false,
  })
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <Modal
        id='modal_status_bendahara'
        isShow={modal?.bendahara}
        onClose={() => {
          setModal((p: any) => ({...p, bendahara: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatus}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-bendahara`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, bendahara: false}))
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_evalap'
        isShow={modal?.evalap}
        onClose={() => {
          setModal((p: any) => ({...p, evalap: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatusEvalap}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-evalap`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, evalap: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Nomor Surat'}}>
              {NullProof({input: data, params: 'no_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Surat Tugas'}}>
              {NullProof({input: data, params: 'surat_tugas.nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Surat Perjalanan Dinas'}}>
              {NullProof({input: data, params: 'spd.nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Untuk Pembayaran'}}>
              {NullProof({input: data, params: 'untuk_pembayaran'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal'}}>
              {NullProof({input: data, params: 'tanggal_pembayaran', type: 'date'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Mata Anggaran'}}>
              {NullProof({input: data, params: 'mata_anggaran'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nominal'}}>
              {NullProof({input: data, params: 'jumlah_biaya', type: 'currency'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kwitansi'}}>
              <TableInputDetailFile
                data={NullProof({input: data, params: 'kwitansi', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status Bendahara'}}>
              <div className='d-flex align-items-center gap-2'>
                <div>{NullProof({input: data, params: 'status_bendahara'})}</div>
                <div>
                  <ProtectComponent id='ubah_status_bendahara/:id' route={InfoData.path}>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal((p: any) => ({...p, bendahara: true}))
                      }}
                    >
                      Ubah Status
                    </button>
                  </ProtectComponent>
                </div>
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Bendahara'}}>
              {NullProof({input: data, params: 'bendahara.pegawai.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan Bendahara'}}>
              {NullProof({input: data, params: 'catatan_bendahara'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  Bendahara'}}>
              {NullProof({
                input: data,
                params: 'status_bendahara_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status evalap'}}>
              <div className='d-flex align-items-center gap-2'>
                <div>{NullProof({input: data, params: 'status_evalap'})}</div>
                <div>
                  <ProtectComponent id='ubah_status_evalap/:id' route={InfoData.path}>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal((p: any) => ({...p, evalap: true}))
                      }}
                    >
                      Ubah Status
                    </button>
                  </ProtectComponent>
                </div>
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'evalap'}}>
              {NullProof({input: data, params: 'evalap.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan evalap'}}>
              {NullProof({input: data, params: 'catatan_evalap'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  evalap'}}>
              {NullProof({
                input: data,
                params: 'status_evalap_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper}
