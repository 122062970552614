const FormikMessages = {
  REQUIRED: 'Input is Required.',
  FORM_ERROR: 'Please double check your form.',
}

const FormInputMessages = {
  'DATE.PLACEHOLDER': 'Select Date',
  'NUMBER.DAY.PLACEHOLDER': 'Day',
  'SELECT.PLACEHOLDER_LEADING': 'Select',
  'SELECT.PLACEHOLDER_END': 'Data',
  'INPUT.FILE.PLACEHOLDER': 'Select File',
  'DATALIST.ERROR_SERVER': 'Failed to load data - Click to Refresh',
  'DATALIST.MODAL.ERROR_SERVER': 'Failed to connecting server',
  'DATALIST.MODAL.ERROR_SERVER.DESCRIPTION': 'Please try again later',
  'DATALIST.LOADING': 'Loading Data',
  'DATALIST.BUTTON.REFRESH': 'Refresh',
  'DATALIST.CHECKED.TITLE': 'Selected Data',
  'DATALIST.TITLE': 'List Data',
  'INPUT.PASSWORD.SHOWPASSWORD': 'Show',
}

const TableInputMessages = {
  'BUTTON.ADD': 'Add',
  'BUTTON.SEARCH': 'Search',
  'BUTTON.DELETE': 'Delete',
  'BUTTON.DETAIL': 'Detail',
  'BUTTON.ACTION': 'Action',
  'MODAL.ACTION.TITLE': 'Select Action',
  'BUTTON.SHOW_TOTAL_DATA': 'Total Data',
}

const InfoPageMessages = {
  EDIT_PAGE: 'Edit {page} Page',
  ADD_PAGE: 'Add {page} Page',
}

const i18nComponentAllMessages = {
  forminput: FormInputMessages,
  tableinput: TableInputMessages,
  formik: FormikMessages,
  infopage: InfoPageMessages,
}

export default i18nComponentAllMessages
