import {DataRoutesProps} from '../../../UserRolesValidation'
import Nominatif from './Nominatif'
import RekapSpm from './RekapSpm'
// import Transfer from './Transfer'
import TransferSuccess from './TransferSuccess'
import TransferPending from './transfer/UM_Pending'
import TransferProses from './transfer/UM_proses'
import TransfersSuccess from './transfer/UM_Sukses'
import TransferVFPending from './transfer/VF_Pending'
import TransferVFProses from './transfer/VF_Proses'
import TransfersVFSuccess from './transfer/VF_Sukses'

const TransferRoutes: DataRoutesProps = {
  title: 'Transfer',
  icon: 'element-10',
  route: 'transfer',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Transfer UM Pending',
      icon: 'element-10',
      route: 'transfer_um_pending',
      elements: <></>,
      subroute: TransferPending,
    },
    {
      isPage: true,
      title: 'Transfer UM Proses',
      icon: 'element-10',
      route: 'transfer_um_proses',
      elements: <></>,
      subroute: TransferProses,
    },
    {
      isPage: true,
      title: 'Transfer UM Success',
      icon: 'element-10',
      route: 'transfer_um_success',
      elements: <></>,
      subroute: TransfersSuccess,
    },
    {
      isPage: true,
      title: 'Transfer VF Pending',
      icon: 'element-10',
      route: 'transfer_vf_pending',
      elements: <></>,
      subroute: TransferVFPending,
    },
    {
      isPage: true,
      title: 'Transfer VF Proses',
      icon: 'element-10',
      route: 'transfer_vf_proses',
      elements: <></>,
      subroute: TransferVFProses,
    },
    {
      isPage: true,
      title: 'Transfer VF Success',
      icon: 'element-10',
      route: 'transfer_vf_success',
      elements: <></>,
      subroute: TransfersVFSuccess,
    },
  ],
}

const LsBendahara: DataRoutesProps[] = [
  {
    title: 'LS Bendahara',
    icon: 'element-10',
    route: 'ls_bendahara',
    elements: <></>,
    subroute: [
      {
        isPage: true,
        title: 'Nominatif',
        icon: 'element-10',
        route: 'nominatif',
        elements: <></>,
        subroute: Nominatif,
      },
      {
        isPage: true,
        title: 'Rekap MAK SPM',
        icon: 'element-10',
        route: 'rekap_mak_spm',
        elements: <></>,
        subroute: RekapSpm,
      },
      {...TransferRoutes},
      // {
      //   isPage: true,
      //   title: 'Transfer',
      //   icon: 'element-10',
      //   route: 'transfer',
      //   elements: <></>,
      //   subroute: Transfer,
      // },
      // {
      //   isPage: true,
      //   title: 'Transfer - Success',
      //   icon: 'element-10',
      //   route: 'transfer_success',
      //   elements: <></>,
      //   subroute: TransferSuccess,
      // },
    ],
  },
]

export default LsBendahara
