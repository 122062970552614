/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../helper/AppFunction'
import FormInput from '../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import PdfLayout from './PdfLayout'
import {PdfPageViewer} from '../../../helper/PdfPageViewer'

const listSkor = [
  {
    label: 'Skor Pengawasan',
    name: 'skor_pengawasan',
    sum: 15 / 100,
  },
  {
    label: 'SDM Auditi',
    name: 'sdm_auditi',
    sum: 5 / 100,
  },
  {
    label: 'Perubahan Personil Kunci',
    name: 'perubahan_personil',
    sum: 5 / 100,
  },
  {
    label: 'Jenis dan Tipologi Satker',
    name: 'jenis_tipologi',
    sum: 10 / 100,
  },
  {
    label: 'Anggaran',
    name: 'anggaran',
    sum: 10 / 100,
  },
  {
    label: 'Temuan dan Tindak Lanjut',
    name: 'temuan_tindak_lanjut',
    sum: 20 / 100,
  },
  {
    label: 'Tahun Terakhir Audit',
    name: 'tahun_terakhir_audit',
    sum: 20 / 100,
  },
  {
    label: 'Jarak Audit',
    name: 'jarak_audit',
    sum: 10 / 100,
  },
  {
    label: 'Sistem Informasi',
    name: 'sistem_informasi',
    sum: 5 / 100,
  },
]

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Nilai Skor' props={{style: {minWidth: '400px'}}}>
                    {listSkor.map((ls, is) => (
                      <Fragment key={is}>
                        <div>
                          <span className='fw-bold'>{ls.label} : </span>
                          <span>{`${NullProof({input: l, params: ls.name, isLabel: false})}`}</span>
                        </div>
                      </Fragment>
                    ))}
                  </TableColumn>
                  <TableColumn label='Total Skor' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'total'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={(e: any) => {
            const sData = e?._data?.get
            let totalSkor = 0
            for (const l of listSkor) {
              const total = Number((sData[l.name] || 0) * l.sum)
              totalSkor += total
            }
            const result = {...sData, total: totalSkor}
            e?.resetForm({values: result})
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={(e: any) => {
            const sData = e?._data?.get
            let totalSkor = 0
            for (const l of listSkor) {
              const total = Number((sData[l.name] || 0) * l.sum)
              totalSkor += total
            }
            const result = {...sData, total: totalSkor}
            e?.resetForm({values: result})
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Nilai Skor', props: {className: 'py-2'}}}
          >
            {listSkor.map((l, i) => (
              <Fragment key={i}>
                <TableInputDetailColumn hideDivider title={{value: l.label}}>
                  {`${NullProof({input: data, params: l.name, isLabel: false})} x ${
                    l.sum * 100
                  }% = ${Number(NullProof({input: data, params: l.name, isLabel: false}) * l.sum)}`}
                </TableInputDetailColumn>
              </Fragment>
            ))}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Total Skor'}}>
            {NullProof({input: data, params: 'total', isLabel: false})}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper, PdfWrapper}
