import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'parent_id',
    type: 'datalist',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Instansi',
          placeholder: 'Instansi',
        },
      },
      datalist: {
        api: '/kepegawaian/data-instansi',
        params: 'kode,nama,wilayah',
        result: '$kode | $nama | $wilayah',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'kode',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kode Satker is required'),
    options: {
      input: {
        props: {
          title: 'Kode Satker',
          placeholder: 'Kode Satker',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker',
        params: 'kode,nama,wilayah',
        result: '$kode | $nama | $wilayah',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama is required'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'pimpinan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Pimpinan is required'),
    options: {
      input: {
        props: {
          title: 'Pimpinan',
          placeholder: 'Pimpinan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kontak',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kontak is required'),
    options: {
      input: {
        props: {
          title: 'Kontak',
          placeholder: 'Kontak',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nip',
    type: 'input',
    value: '',
    validator: Yup.string().required('NIP is required'),
    options: {
      input: {
        props: {
          title: 'NIP',
          placeholder: 'NIP',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'pangkat',
    type: 'input',
    value: '',
    validator: Yup.string().required('Pangkat is required'),
    options: {
      input: {
        props: {
          title: 'Pangkat',
          placeholder: 'Pangkat',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'golongan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Golongan is required'),
    options: {
      input: {
        props: {
          title: 'Golongan',
          placeholder: 'Golongan',
        },
      },
    },
  },
]

export {formInputList}
