import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  value: any;
  forgotPw: {
    email: string
  };
}

const initialState: AuthState = {
  value: [],
  forgotPw: {
    email: ''
  },
};

const authSlice: any = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    post: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
    postForgotPw: (state, action: PayloadAction<any>) => {
      state.forgotPw = action.payload;
    },
  },
});

export const { post, postForgotPw } = authSlice.actions;
export default authSlice.reducer;
