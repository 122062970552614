import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useNavigate, useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList, formTandaTanganElektronik} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {CardUi, ListdataUi, TabsUi, TimelineUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../helper/AppFunction'
import FormInput from '../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {ProtectComponentRoles} from '../../../../helper/UserRolesValidation'
import Modal from '../../../../helper/Modal'
import {
  TableRiwayatPerubahanMetadata,
  TablePenandaTanganan,
  TableDaftarVerifikator,
  TableInputNaskahDinas,
  TableInputLampiran,
  TableInputTujuan,
  TableInputVerifikator,
  TableInputPenandatanganan,
  TableInputStatusKirim,
} from './TableWrapper'
import Accordion from '../../../../helper/Accordion'
import {formatDate} from '../../../../helper/DateFns'
import {ReactPdfViewer} from '../../../../helper/ReactPdf'

const dummyData = [
  {
    id: 1,
    tanggal_naskah: '2024-01-18T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    hal: 'coba tanda tangan konvensional',
    asal_naskah: 'Inspektorat Wilayah IV',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
    nomor_referensi: 'Tidak ada',
    isi: 'mencoba testing aplikasi tanda tangan konvensional',
    jenis_naskah: 'Nota Dinas',
    sifat_naskah: 'Biasa',
    klasifikasi: 'PS.00 - Pengawasan Administrasi Umum',
    tipe_tandatangan: 'TTE',
    status_berkas: 'Belum diberkaskan',
    tanggal_diterima: '2024-01-18T11:02:59Z',
    tanggal_dibaca: '2024-01-18T11:02:59Z',
    tanggal_dibaca_setelah: '2024-01-18T11:02:59Z',
    pegawai: {
      nama: 'Kastolan - Plt. Sekretaris Inspektorat Jenderal',
      jabatan: 'Sekretariat Inspektorat Jenderal Kementerian Agama - Kementerian Agama',
      foto: 'files/avatar/avatar_12122023204007_nemeka.jpg',
    },
    penerima: {
      utama: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
      tebusan: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
    },
  },
  {
    id: 2,
    tanggal_naskah: '2024-01-18T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    hal: 'coba tanda tangan konvensional',
    asal_naskah: 'Inspektorat Wilayah IV',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
  },
  {
    id: 3,
    tanggal_naskah: '2024-01-18T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    hal: 'coba tanda tangan konvensional',
    asal_naskah: 'Inspektorat Wilayah IV',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
  },
]

const MainWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          // isDummy
          // staticData={dummyData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Tanggal Naskah' className='min-w-150px'>
                    {formatDate({
                      date: NullProof({input: l, params: 'createdAt'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableColumn label='Nomor Naskah' className='min-w-150px'>
                    {NullProof({input: l, params: 'nomor_naskah'})}
                  </TableColumn>
                  <TableColumn label='Hal' className='min-w-250px'>
                    {NullProof({input: l, params: 'hal'})}
                  </TableColumn>
                  <TableColumn label='Asal Naskah' className='min-w-150px'>
                    {NullProof({input: l, params: 'dikirimkan_melalui_data.nama_satker'})}
                  </TableColumn>
                  <TableColumn label='Tingkat Urgensi' className='min-w-150px'>
                    {NullProof({input: l, params: 'tingkat_urgensi_data.text'})}
                  </TableColumn>
                  <TableColumn label='Status Penandatanganan' className='min-w-150px'>
                    {/* {NullProof({input: l, params: 'verifikator[0].status'})} */}
                    {NullProof({input: l, params: 'penandatangan', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div>
                            {`${NullProof({
                              input: l1,
                              params: 'status',
                            })}`}
                          </div>
                        </Fragment>
                      )
                    )}
                  </TableColumn>
                  <TableColumn label='Status Kirim' className='min-w-150px'>
                    {NullProof({input: l, params: 'status_kirim'}) ? 'Sudah' : 'Belum'}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const navigate = useNavigate()
  const query = useParams()
  const [data, setData] = useState<any>(dummyData[0])
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <Modal
        id='modal_statusubah'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id?type=penandatangan`, query),
            isFeedback: true,
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              // console.log(e)
              setModal((p: any) => ({...p, statusUbah: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi
          title={PageData.detail?.page.breadcrumb.title}
          // toolbars={
          //   <div className='fs-6'>
          //     <DropdownUi
          //       title='Tindak Lanjut'
          //       icon='RiCornerDownLeftLine'
          //       submenu={[
          //         {
          //           id: 'saya_balas',
          //           title: 'Saya Balas',
          //           isFeedback: true,
          //         },
          //         {
          //           id: 'dibalas',
          //           title: 'Dibalaskan Oleh',
          //           isFeedback: true,
          //         },
          //         {
          //           id: 'disposisi',
          //           title: 'Disposisi / Koordinasi / Saran',
          //           isFeedback: true,
          //         },
          //       ]}
          //       onFeedback={(e: any) => {
          //         if (e?.props?.id === 'disposisi') {
          //           navigate(RouterQueryParams(`./disposisi`, query))
          //         }
          //         console.log(e)
          //       }}
          //     />
          //   </div>
          // }
        >
          <TableInputDetail
            // isDummy
            onSuccess={(val: any) => setData(val)}
            {...TableInputDetailData}
          >
            <div className='row'>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Nomor Referensi'}}
                >
                  {NullProof({input: data, params: 'nomor_ref'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Nomor Naskah'}}
                >
                  {NullProof({input: data, params: 'nomor_naskah'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Tanggal Naskah'}}
                >
                  {NullProof({input: data, params: 'createdAt', type: 'date'})}
                </TableInputDetailColumn>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Hal'}}>
                  {NullProof({input: data, params: 'hal'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Isi'}}>
                  {NullProof({input: data, params: 'isi_ringkas'})}
                </TableInputDetailColumn>
              </div>
            </div>
            <div className='my-4 d-flex flex-column gap-2'>
              <Accordion
                title='Detail Naskah'
                enabled
                props={{body: {className: 'p-4 border-bottom'}}}
              >
                <div className='row'>
                  <div className='row col-12 col-md-6'>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Jenis Naskah'}}
                    >
                      {NullProof({input: data, params: 'JenisNaskah.text'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Sifat Naskah'}}
                    >
                      {NullProof({input: data, params: 'SifatNaskah.text'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Tingkat Urgensi'}}
                    >
                      {NullProof({input: data, params: 'tingkat_urgensi_data.text'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Klasifikasi'}}
                    >
                      {NullProof({input: data, params: 'KlasifikasiNaskah.text'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Tipe Tandatangan'}}
                    >
                      {NullProof({input: data, params: 'tipe_tandatangan'})}
                    </TableInputDetailColumn>
                  </div>
                  <div className='row col-12 col-md-6'>
                    <TableInputDetailColumn
                      props={{className: 'row gy-1'}}
                      hideDivider
                      title={{value: 'Berkas'}}
                    >
                      {NullProof({input: data, params: 'status_berkas'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Diregistrasikan Pada'}}
                    >
                      {NullProof({input: data, params: 'createdAt', type: 'date'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Diregistrasikan Oleh'}}
                    >
                      {NullProof({input: data, params: 'pegawai.nama'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Dikirim Melalui'}}
                    >
                      {NullProof({input: data, params: 'dikirimkan_melalui_data.nama_satker'})}
                    </TableInputDetailColumn>
                  </div>
                </div>
              </Accordion>
              <Accordion title='Tujuan' enabled props={{body: {className: 'p-4 border-bottom'}}}>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      props={{className: 'row mb-4'}}
                      hideDivider
                      title={{value: 'Utama:'}}
                    ></TableInputDetailColumn>
                    {NullProof({input: data, params: 'utama', isMap: true})?.map(
                      (l: any, i: number) => (
                        <Fragment key={i}>
                          <TableInputDetailColumn
                            props={{className: 'row mb-2'}}
                            hideDivider
                            title={{value: NullProof({input: l, params: 'pegawai.nama'})}}
                          >
                            {NullProof({input: l, params: 'pegawai.tampil_jabatan'})}
                          </TableInputDetailColumn>
                        </Fragment>
                      )
                    )}
                  </div>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      props={{className: 'row mb-4'}}
                      hideDivider
                      title={{value: 'Tembusan:'}}
                    ></TableInputDetailColumn>
                    {NullProof({input: data, params: 'tembusan', isMap: true})?.map(
                      (l: any, i: number) => (
                        <Fragment key={i}>
                          <TableInputDetailColumn
                            props={{className: 'row mb-2'}}
                            hideDivider
                            title={{value: NullProof({input: l, params: 'pegawai.nama'})}}
                          >
                            {NullProof({input: l, params: 'pegawai.tampil_jabatan'})}
                          </TableInputDetailColumn>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
              </Accordion>
            </div>
          </TableInputDetail>
        </CardUi>
        <CardUi props={{className: 'my-6'}}>
          <ReactPdfViewer
            url={`${
              NullProof({input: data, params: 'file_naskah', isLabel: false})
                ? `/${NullProof({input: data, params: 'file_naskah', isLabel: false})}`
                : ''
            }`}
          />
        </CardUi>
        <CardUi title='Lampiran' props={{className: 'my-6'}}>
          {NullProof({input: data, params: 'lampiran_naskah', isMap: true})?.length > 0 ? (
            <ListdataUi
              id='lampiran'
              title='Lampiran'
              data={NullProof({input: data, params: 'lampiran_naskah', isMap: true})}
            />
          ) : (
            'Tidak ada lampiran'
          )}
        </CardUi>
        <CardUi title='Daftar Verifikator' props={{className: 'my-6'}}>
          <TableDaftarVerifikator />
        </CardUi>
        <CardUi title='Daftar Penandatanganan' props={{className: 'my-6'}}>
          <TablePenandaTanganan
            onComplete={() => {
              setKey((p) => (p += 1))
            }}
          />
        </CardUi>
        <CardUi title='Form TTE (Tanda Tangan Elektronik)' props={{className: 'my-6'}}>
          <FormInput
            input={formTandaTanganElektronik}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id`, query),
              isFeedback: true,
              type: 'post',
              onFeedback: (e: any) => {
                if (e?.status) {
                  setKey((p) => p + 1)
                }
              },
            }}
          />
        </CardUi>
        <TabsUi
          title='Daftar Riwayat Perubahan Metadata'
          tabs={[
            {
              id: 'naskah_dinas',
              title: 'Naskah Dinas',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputNaskahDinas}
                  title={'Naskah Dinas'}
                />
              ),
            },
            {
              id: 'lampiran',
              title: 'Lampiran',
              children: (
                <TableRiwayatPerubahanMetadata dataInput={TableInputLampiran} title={'Lampiran'} />
              ),
            },
            {
              id: 'tujuan',
              title: 'Tujuan',
              children: (
                <TableRiwayatPerubahanMetadata dataInput={TableInputTujuan} title={'Tujuan'} />
              ),
            },
            {
              id: 'verifikator',
              title: 'Verifikator',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputVerifikator}
                  title={'Verifikator'}
                />
              ),
            },
            {
              id: 'penandatanganan',
              title: 'Penandatanganan',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputPenandatanganan}
                  title={'Penandatanganan'}
                />
              ),
            },
            {
              id: 'status_kirim',
              title: 'Status Kirim',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputStatusKirim}
                  title={'Status Kirim'}
                />
              ),
            },
          ]}
        />
        <CardUi title='Linimasa'>
          {NullProof({input: data, params: 'linimasa', isMap: true})?.map((l: any, i: number) => (
            <Fragment key={i}>
              <TimelineUi
                isEnd={i + 1 === NullProof({input: data, params: 'linimasa', isMap: true})?.length}
                title={NullProof({input: l, params: 'perubahan'})}
                date={formatDate({
                  date: NullProof({input: l, params: 'createdAt', isLabel: false}) || '',
                  dateFormat: 'dd MMMM yyyy HH:mm:ss',
                })}
              >
                <div>{NullProof({input: l, params: 'pegawai.nama'})}</div>
                <div>{NullProof({input: l, params: 'pegawai.tampil_jabatan'})}</div>
              </TimelineUi>
            </Fragment>
          ))}
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper}
