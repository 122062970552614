import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {CardUi} from '../../../../../helper/AppUi'
import {TableAction, TableColumn, TableInput, TableRow} from '../../../../../helper/TableInput'
import {
  ConvertCurrency,
  NullProof,
  numberlistPagination,
  RouterQueryParams,
  BreadcrumbQueryParams,
} from '../../../../../helper/AppFunction'
import {formatDate} from '../../../../../helper/DateFns'
import FormInput from '../../../../../helper/FormInput'
import {formInputList} from './InputLayout'
import {useParams} from 'react-router-dom'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../helper/TableInputDetail'
import { PdfPageViewer } from '../../../../../helper/PdfPageViewer'
import {PdfLayout, PdfLayoutLinkEvidence} from './PdfLayout'
import {ExcelLayout, ExcelLayoutLinkEvidence} from './ExcelLayout'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({
            input: data,
            params: 'data',
            isMap: true,
          }).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Satuan Kerja' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'satker.nama_satker'})}
                  </TableColumn>
                  <TableColumn label='Penandatangan Komitmen' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'penandatangan_komitmen.nama'})}
                  </TableColumn>
                  <TableColumn label='PIC Satker' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'pic_satker.nama'})}
                  </TableColumn>
                  <TableColumn label='PIC Irjen' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'pic_irjen.nama'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data)}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  let InitPdfLayout = PdfLayout
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={InitPdfLayout}
        justPreview={true}
        ExcelLayout={ExcelLayout}
      />
    </>
  )
}

const PdfWrapperLinkEvidence: FC = () => {
  const query = useParams()
  let InitPdfLayout = PdfLayoutLinkEvidence
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api_link_evidence}`, query)}
        PageData={{
          breadcrumb: PageData.link_evidence?.page.breadcrumb.data || [],
          title: PageData.link_evidence?.page.breadcrumb.title || '',
        }}
        PdfLayout={InitPdfLayout}
        justPreview={true}
        ExcelLayout={ExcelLayoutLinkEvidence}
      />
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})

  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
        <TableInputDetailColumn title={{value: 'Satuan Kerja'}}>
            {NullProof({input: data, params: 'satker.nama_satker'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Penandatangan Komitmen'}}>
            {NullProof({input: data, params: 'penandatangan_komitmen.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jabatan'}}>
            {NullProof({input: data, params: 'penandatangan_komitmen.jabatan.jabatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'No HP'}}>
            {NullProof({input: data, params: 'penandatangan_komitmen.no_hp'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Target Waktu Penyelesaian'}}>
            {NullProof({input: data, params: 'target_waktu',  type: 'date'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Petugas Pemantau'}}>
            {NullProof({input: data, params:'petugas_pemantau.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nama PIC Satuan Kerja'}}>
            {NullProof({input: data, params:'pic_satker.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'No HP PIC Satuan Kerja'}}>
            {NullProof({input: data, params:'pic_satker.no_hp'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nama PIC Inspektoral Jendral'}}>
            {NullProof({input: data, params:'pic_irjen.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'No HP PIC Inspektoral Jendral'}}>
            {NullProof({input: data, params:'pic_irjen.no_hp'})}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper,PdfWrapper, PdfWrapperLinkEvidence}
