import {FC, useEffect, useRef, useState} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import {Modal as BSModal} from 'react-bootstrap'
import clsx from 'clsx'
import Swal from 'sweetalert2'

export type ModalUiProps = {
  id: string
  title?: string
  isShow?: boolean
  hasConfirm?: boolean
  onClose?: any
  className?: string
}

const Modal: FC<ModalUiProps & WithChildren> = ({
  children,
  id,
  title = 'Modal title',
  isShow = false,
  hasConfirm,
  onClose,
  className = '',
}) => {
  return (
    <>
      <BSModal
        className='bg-black bg-opacity-50'
        dialogClassName={`modal-dialog-centered mx-auto px-4 ${className}`}
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={isShow}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='modal-title'>{title}</h3>

            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={async () => {
                if (hasConfirm) {
                  const {value: accept} = await Swal.fire({
                    icon: 'question',
                    title: 'Batalkan tindakan?',
                    showCancelButton: true,
                  })
                  accept && onClose()
                } else if (!hasConfirm) {
                  onClose()
                }
              }}
            >
              <i className='ki-duotone ki-cross fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </div>
          </div>

          <div className='modal-body'>{children}</div>
        </div>
      </BSModal>
    </>
  )
}

export default Modal
