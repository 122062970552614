import {FormInputProps} from '../../../../helper/FormInput'
import * as Yup from 'yup'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        params: 'nomor_surat,nama_kegiatan',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nomor Surat : </span>
              <span>$nomor_surat</span>
            </div>
            <div>
              <span className='fw-bold'>Nama Kegiatan : </span>
              <span>$nama_kegiatan</span>
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'pembukuan_no',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nomor Pembukuan is required'),
    options: {
      input: {
        props: {
          title: 'Nomor Pembukuan',
          placeholder: 'Nomor Pembukuan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tanggal_pembayaran',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Pembayaran',
          placeholder: 'Tanggal Pembayaran',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'untuk_pembayaran',
    type: 'input',
    value: '',
    validator: Yup.string().required('Untuk Pembayaran is required'),
    options: {
      input: {
        props: {
          title: 'Untuk Pembayaran',
          placeholder: 'Untuk Pembayaran',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'jumlah_biaya',
    type: 'input',
    value: '',
    validator: Yup.string().required('Jumlah Biaya is required'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah Biaya',
          placeholder: 'Jumlah Biaya',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kwitansi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kwitansi Pembayaran waji diisi.'),
    options: {
      input: {
        props: {
          title: 'Kwitansi Pembayaran',
          placeholder: 'Kwitansi Pembayaran',
          accept: '.pdf, .jpg, .jpeg, .png',
          type: 'file',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
      },
    },
  }
]

const formInputStatus: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_bendahara',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_bendahara',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
]

const formInputStatusEvalap: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_evalap',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_evalap',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
]

export {formInputList, formInputStatus, formInputStatusEvalap}
