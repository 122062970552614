import {DataRoutesProps} from '../../../../UserRolesValidation'
import {
  MainWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
} from '../../../../../pages/pengawasan-internal/pelaporan-pengawasan-internal/implementasi-pakta-integritas/form-evaluasi/komitmen-kerja/MainWrapper'
import {Indikator} from './Indikator'

const KomitmenKerja: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:komitmen_id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:komitmen_id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: ':komitmen_id/indikator',
    elements: <></>,
    subroute: Indikator,
  },
]

export {KomitmenKerja}
