import {da} from 'date-fns/locale'
import {NullProof} from '../../../../../helper/AppFunction'
import {formatDate} from '../../../../../helper/DateFns'
import {BorderStyle, CellStyle, Workbook, createWorkbook} from '../../../../../helper/ReactExcelJs'
import {PdfLayoutDataProps} from '../../../../../helper/ReactPdf'
import { ConvertCurrency } from '../../../../../helper/AppFunction'

const WorksheetRinci = (wb: Workbook, data: any) => {
  // More Info: https://www.npmjs.com/package/exceljs
  // Init ExcelJS
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({title: 'Judulnya'})
  console.log(data)

  // Init Template Theme
  const themeColor = {
    ungu: '60497A', // Format Color: HEX without #
    kuning: 'ffff00',
  }
  type themeStyleProps = {
    style: CellStyle
    border: BorderStyle
  }
  const themeStyle: themeStyleProps = {
    style: {
      backgroundColor: themeColor.ungu,
      color: themeColor.kuning,
      fontConfig: {
        wrap: true,
        alignment: {
          h: 'center',
        },
      },
    },
    border: {
      color: themeColor.kuning,
    },
  }

  // Init Column Width
  const columnWidths: any = {
    A: 5,
    B: 40,
    C: 20,
    D: 40,
    E: 40,
    F: 40,
    G: 25,
    H: 30,
    I: 40,
    J: 30,
    K: 40,
    L: 40,
    M: 30,
    N: 30,
    O: 30,
  }
  setupWidth(columnWidths)

  // Start Making ExcelJS
  cell({id: 'A1'}).value = 'No.'
  cell({id: 'B1'}).value = 'Pengusul / Pengguna Anggaran'
  cell({id: 'C1'}).value = 'Jenis Perjalanan Dinas'
  cell({id: 'D1'}).value = 'No. ST'
  cell({id: 'E1'}).value = 'Tanggal ST'
  cell({id: 'F1'}).value = 'Uraian Kegiatan'
  cell({id: 'G1'}).value = 'Provinsi'
  cell({id: 'H1'}).value = 'Jenis Satuan Kerja'
  cell({id: 'I1'}).value = 'Nama Satuan Kerja'
  cell({id: 'J1'}).value = 'Wilayah (Wilayah Kerja)'
  cell({id: 'K1'}).value = 'Sumber Penugasan'
  cell({id: 'L1'}).value = 'Topik/Nama PKPT/Judul Lainnya'
  cell({id: 'M1'}).value = 'Kategori'
  cell({id: 'N1'}).value = 'Jumlah Pengeluaran Rill'
  cell({id: 'O1'}).value = 'MAK'

  NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
    let jumlahTotalDibayar = 0
    let jumlahTotalPengeluaran = 0
    let JumlahTotalSelisih = jumlahTotalDibayar - jumlahTotalPengeluaran
    console.log(JumlahTotalSelisih, 'JumlahTotalSelisih');
    const total = (data: any, param: string) => {
      const uang_muka = data ? data.reduce((a: any, b: any) => a + Number(b[param]), 0) || 0 : 0
      return Number(uang_muka) 
    }
    const cekIsUangNull = (uang: any) => {
      return uang ? Number(uang) : 0
    }
    NullProof({input: l, params: 'bukti_perjalanan', isMap: true}).map(
      (item: any, i: number) => {
        const uangTransportKantor = item.transportasi_data ? item.transportasi_data.filter((l:any) => l.dibayar_oleh === 'Kantor'): []
        const totalDibayar = total(item.transfer, 'uang_harian')+total(item.transfer, 'hotel')+total(item.transfer, 'uang_representatif')+total(item.transfer, 'transport')+total(uangTransportKantor, 'total')+total(item.transfer, 'bst_kantor')+total(item.transfer, 'bst_lokasi')
        const totalPengeluraran = cekIsUangNull(item.uang_harian_total)+total(item.uang_penginapan_data, 'total')+cekIsUangNull(item.uang_representatif_total)+total(item.transportasi_data, 'total')+cekIsUangNull(item.bst_kantor)+cekIsUangNull(item.bst_lokasi)+total(item.kwitansi_lain_lain_list, 'total')
        jumlahTotalDibayar += totalDibayar
        jumlahTotalPengeluaran += totalPengeluraran
        JumlahTotalSelisih = jumlahTotalDibayar - jumlahTotalPengeluaran
      })
    cell({id: `A${1 + (i + 1)}`}).value = i + 1
    cell({id: `B${1 + (i + 1)}`}).value = NullProof({input: l, params: 'pengguna_anggaran.nama'})
    cell({id: `C${1 + (i + 1)}`}).value = NullProof({input: l, params: 'jenis'})
    cell({id: `D${1 + (i + 1)}`}).value = NullProof({input: l, params: 'nomor_surat'})
    cell({id: `E${1 + (i + 1)}`}).value = NullProof({input: l, params: 'createdAt', type: 'date'})
    cell({id: `F${1 + (i + 1)}`}).value = NullProof({input: l, params: 'deskripsi_kegiatan'})
    cell({id: `G${1 + (i + 1)}`}).value = NullProof({input: l, params: 'pengguna_anggaran.provinsi'})
    cell({id: `H${1 + (i + 1)}`}).value = NullProof({
      input: l,
      params: 'tujuan',
      isMap: true,
    })
      ?.map(
        (item: any, index: number) =>
          `${
            index === 0
              ? ' '
              : index === l.tujuan.length - 1
              ? `${index !== 1 ? ',' : ''} dan `
              : ', '
          }${NullProof({input: item, params: 'jenis_tujuan'})}`
      )
      .join('')
    cell({id: `I${1 + (i + 1)}`}).value = NullProof({input: l, params: 'pengguna_anggaran.satuan_kerja'})
    cell({id: `J${1 + (i + 1)}`}).value = NullProof({input: l, params: 'pengguna_anggaran.wilayah',})
    cell({id: `K${1 + (i + 1)}`}).value = NullProof({input: l, params: 'usulan.sumber_penugasan'})
    cell({id: `L${1 + (i + 1)}`}).value =
      NullProof({input: l, params: 'usulan.sumber_penugasan'}) === 'Form APP'
        ? NullProof({input: l, params: 'usulan.form_app_detail'})
        : NullProof({input: l, params: 'usulan.sumber_penugasan'}) === 'Lainnya'
        ? NullProof({input: l, params: 'usulan.lainnya'})
        : NullProof({
            input: l,
            params: 'usulan.pkpt.rencana_audit_jangka_menengah.satker.nama_satker',
          })
    cell({id: `M${1 + (i + 1)}`}).value = NullProof({input: l, params: 'kategori.kategori'})
    cell({id: `N${1 + (i + 1)}`}).value = ConvertCurrency(JumlahTotalSelisih)
    cell({id: `O${1 + (i + 1)}`}).value = NullProof({input: l, params: 'mak'})
  })
}

const ExcelLayout = (data: PdfLayoutDataProps) => {
  const downloadExcel = async () => {
    const wb = createWorkbook()
    WorksheetRinci(wb, data)
    wb.download()
  }
  return downloadExcel
}

export default ExcelLayout
