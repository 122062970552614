import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../helper/template/FormInputTemplate'
import {inputList} from '../klasifikasi-naskah/InputLayout'

const filterFormInputList: FormInputProps[] = [
  ...inputLayoutBuilder({
    input: [{template: 'search'}, {template: 'status'}],
  }),
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'dikirimkan_melalui',
    type: 'datalist',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Dikirimkan Melalui',
          placeholder: 'Pilih',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker-unit',
        id: 'id',
        params: 'nama_satker',
        result: '$nama_satker',
      },
    },
    listData: [
      {
        title: 'Kantor Urusan Agama Kecamatan Tidore Kementerian Agama Kota Tidore Kepulauan',
        value: 1,
      },
      {
        title: 'KUA Kecamatan Comal Kantor Kementerian Agama Kabupaten Pemalang',
        value: 2,
      },
    ],
  },
  {
    name: 'jenis_naskah',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jenis Naskah',
          placeholder: 'Jenis Naskah',
        },
      },
      datalist: {
        api: '/tata_usaha/jenis-naskah',
        id: 'id',
        params: 'text',
        result: '$text',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'text',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/jenis-naskah',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Text',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputList,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputList,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    name: 'sifat_naskah',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Sifat Naskah',
          placeholder: 'Sifat Naskah',
        },
      },
      datalist: {
        api: '/tata_usaha/sifat-naskah',
        id: 'id',
        params: 'text',
        result: '$text',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'text',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/sifat-naskah',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Text',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputList,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputList,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    name: 'tingkat_urgensi',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tingkat Urgensi',
          placeholder: 'Tingkat Urgensi',
        },
      },
      datalist: {
        api: '/tata_usaha/tingkat-urgensi',
        id: 'id',
        params: 'text',
        result: '$text',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'text',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/tingkat-urgensi',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Text',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputList,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputList,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    name: 'klasifikasi',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Klasifikasi',
          placeholder: 'Klasifikasi',
        },
      },
      datalist: {
        api: '/tata_usaha/klasifikasi-naskah',
        id: 'id',
        params: 'text',
        result: '$text',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'kode',
              type: 'text',
            },
            {
              id: 'text',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/klasifikasi-naskah',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Kode',
              },
              {
                title: 'Text',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputList,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputList,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    name: 'nomor_naskah',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Naskah',
          placeholder: 'Nomor Naskah',
        },
      },
    },
  },
  {
    name: 'nomor_ref_id',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Nomor Referensi',
          placeholder: 'Nomor Referensi',
          disabled:true
        },
      },
    },
  },
  {
    name: 'hal',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Hal',
          placeholder: 'Hal',
        },
      },
    },
  },
  {
    name: 'isi_ringkas',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Isi Ringkas',
          placeholder: 'Isi Ringkas',
        },
      },
    },
  },
  {
    name: 'file_naskah',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'File Naskah',
          placeholder: 'File Naskah',
          type: 'file',
          accept: '.pdf',
        },
      },
      upload: {
        url: {
          folder: 'naskah',
        },
        size: 5
      },
    },
  },
  {
    name: 'lampiran_naskah',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Lampiran Naskah',
          placeholder: 'Lampiran Naskah',
          type: 'file',
          accept: '.pdf',
          multiple: true,
        },
      },
      upload: {
        url: {
          folder: 'naskah',
        },
        size: 5
      },
    },
  },
  {
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Tujuan Utama',
        labelType: 'both',
      },
    },
  },
  {
    name: 'grup_tujuan',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.array(),
    options: {
      input: {
        props: {
          title: 'Grup Tujuan',
          placeholder: 'Grup Tujuan',
        },
      },
      datalist: {
        isListpicker: true,
        api: '/tata_usaha/grup_tujuan',
        id: 'pegawai_id',
        params: 'nama',
        result: '$nama',
      },
    },
  },
  {
    name: 'utama',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Utama (Internal / Srikandi)',
          placeholder: 'Utama (Internal / Srikandi)',
        },
      },
      datalist: {
        isListpicker: true,
        api: '/tata_usaha/tujuan',
        id: 'pegawai_id',
        params: 'pegawai.nama, jenis',
        result: '$pegawai.nama',
      },
    },
  },
  {
    name: 'utama_eksternal',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.array(),
    options: {
      input: {
        props: {
          title: 'Utama Eksternal',
          placeholder: 'Utama Eksternal',
        },
      },
      datalist: {
        isListpicker: true,
        api: '/tata_usaha/tujuan',
        id: 'pegawai_id',
        params: 'kepada',
        result: '$kepada',
      },
    },
  },
  // {
  //   name: 'utama',
  //   type: 'datalist',
  //   className: 'col-12',
  //   validator: Yup.number().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Utama (Internal / Srikandi)',
  //         placeholder: 'Utama (Internal / Srikandi)',
  //       },
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-tenaga-audit',
  //       id: 'id',
  //       params: 'nip,nama',
  //       type: 'table',
  //       result: (
  //         <div>
  //           <div>
  //             <span className='fw-bold'>NIP: </span>
  //             <span>$nip</span>
  //           </div>
  //           <div>
  //             <span className='fw-bold'>Nama: </span>
  //             <span>$nama</span>
  //           </div>
  //         </div>
  //       ),
  //       table: {
  //         protect: '/tata_usaha/pengelolaan_naskah_dinas/registrasi_naskah_keluar',
  //         data: [
  //           {
  //             id: 'nip',
  //             type: 'text',
  //           },
  //           {
  //             id: 'nama',
  //             type: 'text',
  //           },
  //         ],
  //         props: {
  //           route: {
  //             url: '/kepegawaian/data-tenaga-audit',
  //           },
  //           headerData: [
  //             {
  //               title: 'No.',
  //               props: {className: 'min-w-50px'},
  //             },
  //             {
  //               title: 'NIP',
  //             },
  //             {
  //               title: 'Nama',
  //             },
  //           ],
  //         },
  //       },
  //     },
  //   },
  // },
  // {
  //   name: 'utama_ekternal',
  //   type: 'datalist',
  //   className: 'col-12',
  //   validator: Yup.number().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Utama Eksternal',
  //         placeholder: 'Utama Eksternal',
  //       },
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-tenaga-audit',
  //       id: 'id',
  //       params: 'nip,nama',
  //       type: 'table',
  //       result: (
  //         <div>
  //           <div>
  //             <span className='fw-bold'>NIP: </span>
  //             <span>$nip</span>
  //           </div>
  //           <div>
  //             <span className='fw-bold'>Nama: </span>
  //             <span>$nama</span>
  //           </div>
  //         </div>
  //       ),
  //       table: {
  //         protect: '/tata_usaha/pengelolaan_naskah_dinas/registrasi_naskah_keluar',
  //         data: [
  //           {
  //             id: 'nip',
  //             type: 'text',
  //           },
  //           {
  //             id: 'nama',
  //             type: 'text',
  //           },
  //         ],
  //         props: {
  //           route: {
  //             url: '/kepegawaian/data-tenaga-audit',
  //           },
  //           headerData: [
  //             {
  //               title: 'No.',
  //               props: {className: 'min-w-50px'},
  //             },
  //             {
  //               title: 'NIP',
  //             },
  //             {
  //               title: 'Nama',
  //             },
  //           ],
  //         },
  //       },
  //     },
  //   },
  // },
  {
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Tujuan Tembusan',
        labelType: 'both',
      },
    },
  },
  {
    name: 'tembusan',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tembusan (Internal / Srikandi)',
          placeholder: 'Tembusan (Internal / Srikandi)',
        },
      },
      datalist: {
        isListpicker: true,
        api: '/tata_usaha/tembusan',
        id: 'pegawai_id',
        params: 'pegawai.nama',
        result: '$pegawai.nama',
      },
    },
  },
  {
    name: 'tembusan_eksternal',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.array(),
    options: {
      input: {
        props: {
          title: 'Tembusan Eksternal',
          placeholder: 'Tembusan Eksternal',
        },
      },
      datalist: {
        isListpicker: true,
        api: '/tata_usaha/tembusan',
        id: 'pegawai_id',
        params: 'pegawai.nama',
        result: '$pegawai.nama',
      },
    },
  },
  // {
  //   name: 'tembusan_ekternal',
  //   type: 'datalist',
  //   className: 'col-12',
  //   validator: Yup.number().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Tembusan Eksternal',
  //         placeholder: 'Tembusan Eksternal',
  //       },
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-tenaga-audit',
  //       id: 'id',
  //       params: 'nip,nama',
  //       type: 'table',
  //       result: (
  //         <div>
  //           <div>
  //             <span className='fw-bold'>NIP: </span>
  //             <span>$nip</span>
  //           </div>
  //           <div>
  //             <span className='fw-bold'>Nama: </span>
  //             <span>$nama</span>
  //           </div>
  //         </div>
  //       ),
  //       table: {
  //         protect: '/tata_usaha/pengelolaan_naskah_dinas/registrasi_naskah_keluar',
  //         data: [
  //           {
  //             id: 'nip',
  //             type: 'text',
  //           },
  //           {
  //             id: 'nama',
  //             type: 'text',
  //           },
  //         ],
  //         props: {
  //           route: {
  //             url: '/kepegawaian/data-tenaga-audit',
  //           },
  //           headerData: [
  //             {
  //               title: 'No.',
  //               props: {className: 'min-w-50px'},
  //             },
  //             {
  //               title: 'NIP',
  //             },
  //             {
  //               title: 'Nama',
  //             },
  //           ],
  //         },
  //       },
  //     },
  //   },
  // },
  {
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Verifikator dan Penandatangan Naskah',
        labelType: 'both',
      },
    },
  },
  {
    name: 'verifikator',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Verifikator',
          placeholder: 'Verifikator',
        },
      },
      datalist: {
        isListpicker: true,
        api: '/tata_usaha/verifikator',
        id: 'pegawai_id',
        params: 'pegawai.nama,keterangan',
        type: 'table',
        result: (
          <div>
            <div>
              <span className='fw-bold'>Nama: </span>
              <span>$pegawai.nama</span>
            </div>
            <div>
              <span className='fw-bold'>Keterangan: </span>
              <span>$keterangan</span>
            </div>
          </div>
        ),
        table: {
          protect: '',
          data: [
            {
              id: 'pegawai.nama',
              type: 'text',
            },
            {
              id: 'keterangan',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/verifikator',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Nama',
              },
              {
                title: 'Keterangan',
              },
            ],
          },
        },
      },
    },
  },
  {
    name: 'penandatangan',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Penandatangan',
          placeholder: 'Penandatangan',
        },
      },
      datalist: {
        isListpicker: true,
        api: '/tata_usaha/penandatanganan',
        id: 'pegawai_id',
        params: 'pegawai.nama,keterangan',
        type: 'table',
        result: (
          <div>
            <div>
              <span className='fw-bold'>Nama: </span>
              <span>$pegawai.nama</span>
            </div>
            <div>
              <span className='fw-bold'>Keterangan: </span>
              <span>$keterangan</span>
            </div>
          </div>
        ),
        table: {
          protect: '/tata_usaha/penandatanganan',
          data: [
            {
              id: 'pegawai.nama',
              type: 'text',
            },
            {
              id: 'keterangan',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/penandatanganan',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Nama',
              },
              {
                title: 'Keterangan',
              },
            ],
          },
        },
      },
    },
  },
  {
    name: 'tipe_tandatangan',
    type: 'select',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    listData: [
      {
        title: 'TTE',
        value: 'TTE',
      },
      {
        title: 'Konvensional',
        value: 'Konvensional',
      },
    ],
    options: {
      select: {
        props: {
          title: 'Tipe Tanda Tangan',
          placeholder: 'Tipe Tanda Tangan',
        },
      },
    },
  },
]

export {formInputList, filterFormInputList}
