import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'
import {getListYear} from '../../../../helper/DateFns'

const formInputImportList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Panduan untuk Upload',
        description: (
          <div className='fw-normal'>
            <ol>
              <li>Unduh Template Excel Data Terbaru nya diatas.</li>
              <li>Buka melalui aplikasi Excel pada komputer.</li>
              <li>Ubah data sesuai update terbaru dari anda.</li>
              <li>Upload file yang sudah diubah pada komputer anda dibawah.</li>
            </ol>
          </div>
        ),
      },
    },
  },
  {
    className: 'col-12',
    name: 'csv',
    type: 'csv',
    value: '',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Template Excel Terbaru',
          placeholder: 'Template Excel Terbaru',
        },
      },
    },
  },
]

const infoKomponenList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'lokasi',
    type: 'input',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Lokasi',
          placeholder: 'Lokasi',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-2',
    name: 'kode',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Program',
          placeholder: 'Kode Program',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-10',
    name: 'nama',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Program',
          placeholder: 'Nama Program',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'jumlah_harga',
    type: 'input',
    disabled: true,
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah Biaya',
          placeholder: 'Jumlah Biaya',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kegiatan',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kegiatan',
          placeholder: 'Kegiatan',
        },
      },
    },
    component: [
      {
        className: 'col-12 col-md-2',
        name: 'kode',
        type: 'input',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Kode Kegiatan',
              placeholder: 'Kode Kegiatan',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-10',
        name: 'nama',
        type: 'input',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Nama Kegiatan',
              placeholder: 'Nama Kegiatan',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'jumlah_harga',
        type: 'input',
        disabled: true,
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Jumlah Biaya',
              placeholder: 'Jumlah Biaya',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'subkegiatan',
        type: 'component',
        value: '',
        validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Sub Kegiatan',
              placeholder: 'Sub Kegiatan',
            },
          },
        },
        component: [
          {
            className: 'col-12 col-md-2',
            name: 'kode',
            type: 'input',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Kode Kegiatan',
                  placeholder: 'Kode Kegiatan',
                },
              },
            },
          },
          {
            className: 'col-12 col-md-10',
            name: 'nama',
            type: 'input',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Nama Kegiatan',
                  placeholder: 'Nama Kegiatan',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'jumlah_harga',
            type: 'input',
            disabled: true,
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                isCurrency: true,
                props: {
                  title: 'Jumlah Biaya',
                  placeholder: 'Jumlah Biaya',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'daftarkegiatan',
            type: 'component',
            value: '',
            validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Daftar Kegiatan',
                  placeholder: 'Daftar Kegiatan',
                },
              },
            },
            component: [
              {
                className: 'col-12 col-md-2',
                name: 'kode',
                type: 'input',
                validator: Yup.string().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Kode Kegiatan',
                      placeholder: 'Kode Kegiatan',
                    },
                  },
                },
              },
              {
                className: 'col-12 col-md-10',
                name: 'nama',
                type: 'input',
                validator: Yup.string().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Nama Kegiatan',
                      placeholder: 'Nama Kegiatan',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'keterangan',
                type: 'input',
                validator: Yup.string().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Keterangan',
                      placeholder: 'Keterangan',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'jumlah_harga',
                type: 'input',
                disabled: true,
                validator: Yup.string().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    isCurrency: true,
                    props: {
                      title: 'Jumlah Biaya',
                      placeholder: 'Jumlah Biaya',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'infokegiatan',
                type: 'component',
                value: '',
                validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Info Kegiatan',
                      placeholder: 'Info Kegiatan',
                    },
                  },
                },
                component: [
                  {
                    className: 'col-12',
                    name: 'nama',
                    type: 'input',
                    validator: Yup.string().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Info Kegiatan',
                          placeholder: 'Info Kegiatan',
                        },
                      },
                    },
                  },
                  {
                    className: 'col-12',
                    name: 'jumlah_harga',
                    type: 'input',
                    disabled: true,
                    validator: Yup.string().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        isCurrency: true,
                        props: {
                          title: 'Jumlah Biaya',
                          placeholder: 'Jumlah Biaya',
                        },
                      },
                    },
                  },
                  {
                    className: 'col-12',
                    name: 'detailkegiatan',
                    type: 'component',
                    value: '',
                    validator: Yup.number()
                      .min(1, 'Mohon untuk diisi.')
                      .required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Detail Kegiatan',
                          placeholder: 'Detail Kegiatan',
                        },
                      },
                    },
                    component: [
                      {
                        className: 'col-12',
                        name: 'nama',
                        type: 'input',
                        validator: Yup.string().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Nama',
                              placeholder: 'Nama',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12 col-md-8',
                        name: 'volume',
                        type: 'input',
                        validator: Yup.string().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Volume',
                              placeholder: 'Volume',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12 col-md-4',
                        name: 'volume_jenis',
                        type: 'input',
                        validator: Yup.string().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Jenis Volume',
                              placeholder: 'Jenis Volume',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12 col-md-8',
                        name: 'harga',
                        type: 'input',
                        validator: Yup.string().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            isCurrency: true,
                            props: {
                              title: 'Harga Satuan',
                              placeholder: 'Harga Satuan',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12 col-md-4',
                        name: 'jumlah_harga',
                        type: 'input',
                        disabled: true,
                        validator: Yup.string().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            isCurrency: true,
                            props: {
                              title: 'Jumlah Biaya',
                              placeholder: 'Jumlah Biaya',
                            },
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

const formInputList: FormInputProps[] = [
  // {
  //   className: 'col-12 col-md-2',
  //   name: 'kode_program',
  //   type: 'input',
  //   validator: Yup.string().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Kode Program',
  //         placeholder: 'Kode Program',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12 col-md-10',
  //   name: 'nama_program',
  //   type: 'input',
  //   validator: Yup.string().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Nama Program',
  //         placeholder: 'Nama Program',
  //       },
  //     },
  //   },
  // },
  {
    className: 'col-12 col-md-2',
    name: 'kode_kegiatan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Kegiatan',
          placeholder: 'Kode Kegiatan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-10',
    name: 'nama_kegiatan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Kegiatan',
          placeholder: 'Nama Kegiatan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-2',
    name: 'kode_komponen',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Komponen',
          placeholder: 'Kode Komponen',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-10',
    name: 'nama_komponen',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Komponen',
          placeholder: 'Nama Komponen',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'jumlah_harga',
    type: 'input',
    disabled: true,
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah Biaya',
          placeholder: 'Jumlah Biaya',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'program',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Program',
          placeholder: 'Program',
        },
      },
    },
    component: infoKomponenList,
  },
]

export {formInputList, formInputImportList}
