import {FC, Fragment} from 'react'
import {Document, Page, View, Text, Image} from '@react-pdf/renderer'
import {
  PdfFontBuilder,
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfSignatureBuilder,
  PdfStyle,
  PdfStylePallete,
  PdfTabCol,
} from '../../../helper/ReactPdf'
import {formatDate} from '../../../helper/DateFns'
import {AlphabetIndex, ConvertCurrency, NullProof, ReactSrc} from '../../../helper/AppFunction'

const API_URL = process.env.REACT_APP_API_URL

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'

  return (
    <Document title='Dokumen'>
      <Page size={{width: '1800'}} style={{...PdfStyle.page, fontFamily: 'Arial', padding: '20pt'}}>
        <View style={{fontWeight: 'bold', textAlign: 'center'}}>
          <Text
            style={{
              textTransform: 'uppercase',
              ...PdfFontBuilder({template: 'title', size: 'large'}),
            }}
          >
            Peta Pengawasan
          </Text>
          <Text style={{...PdfFontBuilder({template: 'title', size: 'large'})}}>
            Per Tahun Pengawasan {NullProof({input: data.data, params: 'tahun'})}
          </Text>
        </View>
        <PdfTabCol direction='column'>
          {/* Header */}
          <PdfTabCol style={{view: {height: '150pt'}}}>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              Nama Auditi (Instansi, Satker / UPT, Kegiatan, Program, dll)
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              Besaran resiko pengawasan
            </PdfTabCol>
            <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '25%'}}}>
              <PdfTabCol center type='text' border={{bottom: 1}} noWrap>
                Tenaga Auditor yang dimiliki Inspektorat Wilayah / Investigasi
              </PdfTabCol>
              <PdfTabCol center border={{}}>
                <PdfTabCol type='text' center border={{right: 1}}>
                  Daltu
                </PdfTabCol>
                <PdfTabCol type='text' center border={{right: 1}}>
                  Dalnis
                </PdfTabCol>
                <PdfTabCol type='text' center border={{right: 1}}>
                  {`K.\nTim`}
                </PdfTabCol>
                <PdfTabCol type='text' center border={{}}>
                  {`A.\nTim`}
                </PdfTabCol>
              </PdfTabCol>
            </PdfTabCol>
            <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '20%'}}}>
              <PdfTabCol center type='text' border={{bottom: 1}}>
                Tenaga tata usaha Unit
              </PdfTabCol>
              <PdfTabCol center border={{}}>
                <PdfTabCol type='text' center border={{right: 1}}>
                  {`Gol.\nIV`}
                </PdfTabCol>
                <PdfTabCol type='text' center border={{right: 1}}>
                  {`Gol.\nIII`}
                </PdfTabCol>
                <PdfTabCol type='text' center border={{}}>
                  {`Gol.\nI / II`}
                </PdfTabCol>
              </PdfTabCol>
            </PdfTabCol>
            <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '20%'}}}>
              <PdfTabCol center type='text' border={{bottom: 1}}>
                Sarana dan Prasarana unit
              </PdfTabCol>
              <PdfTabCol center border={{}}>
                <PdfTabCol type='text' center border={{right: 1}}>
                  Komp
                </PdfTabCol>
                <PdfTabCol type='text' center border={{right: 1}}>
                  Kend
                </PdfTabCol>
                <PdfTabCol type='text' center border={{}}>
                  Lainnya
                </PdfTabCol>
              </PdfTabCol>
            </PdfTabCol>
            <PdfTabCol
              center
              type='text'
              border={{}}
              style={{view: {width: '15%', padding: '10pt 10pt', textAlign: 'center'}}}
            >
              Lain-lain
            </PdfTabCol>
          </PdfTabCol>
          {/* Data */}
          <PdfTabCol style={{view: {height: '150pt'}}}>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              {`${NullProof({
                input: data.data,
                params: 'satker.nama_satker',
                isLabel: false,
              })} / ${NullProof({
                input: data.data,
                params: 'kegiatan.nama',
                isLabel: false,
              })} / ${NullProof({
                input: data.data,
                params: 'program.nama',
                isLabel: false,
              })}`}
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              {NullProof({input: data.data, params: 'besaran_resiko_audit'})}
            </PdfTabCol>
            <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '25%'}}}>
              <PdfTabCol center border={{}} style={{view: {textTransform: 'capitalize'}}}>
                <PdfTabCol type='text' center border={{right: 1}}>
                  {NullProof({input: data.data, params: 'daltu_data.nama'})?.toLowerCase()}
                </PdfTabCol>
                <PdfTabCol type='text' center border={{right: 1}}>
                  {NullProof({input: data.data, params: 'dalnis_data.nama'})?.toLowerCase()}
                </PdfTabCol>
                <PdfTabCol type='text' center border={{right: 1}}>
                  {NullProof({input: data.data, params: 'ketua_data.nama'})?.toLowerCase()}
                </PdfTabCol>
                <PdfTabCol type='text' center border={{}}>
                  {NullProof({input: data.data, params: 'data_tenaga_data'})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <PdfTabCol type='text' center border={{}}>
                          {i + 1}. {NullProof({input: l, params: 'nama'})?.toLowerCase()}
                        </PdfTabCol>
                      </Fragment>
                    )
                  )}
                </PdfTabCol>
              </PdfTabCol>
            </PdfTabCol>
            <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '20%'}}}>
              <PdfTabCol center border={{}} style={{view: {textTransform: 'capitalize'}}}>
                <PdfTabCol type='text' center border={{right: 1}}>
                  {NullProof({input: data.data, params: 'gol_iv_data.nama'})?.toLowerCase()}
                </PdfTabCol>
                <PdfTabCol type='text' center border={{right: 1}}>
                  {NullProof({input: data.data, params: 'gol_iii_data.nama'})?.toLowerCase()}
                </PdfTabCol>
                <PdfTabCol type='text' center border={{}}>
                  {NullProof({input: data.data, params: 'gol_i_ii_data.nama'})?.toLowerCase()}
                </PdfTabCol>
              </PdfTabCol>
            </PdfTabCol>
            <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '20%'}}}>
              <PdfTabCol center border={{}}>
                <PdfTabCol type='text' center border={{right: 1}}>
                  {NullProof({input: data.data, params: 'spu_komputer'})}
                </PdfTabCol>
                <PdfTabCol type='text' center border={{right: 1}}>
                  {NullProof({input: data.data, params: 'spu_kendaraan'})}
                </PdfTabCol>
                <PdfTabCol type='text' center border={{}}>
                  {NullProof({input: data.data, params: 'spu_lainnya'})}
                </PdfTabCol>
              </PdfTabCol>
            </PdfTabCol>
            <PdfTabCol
              center
              type='text'
              border={{}}
              style={{view: {width: '15%', padding: '10pt 10pt', textAlign: 'center'}}}
            >
              {NullProof({input: data.data, params: 'lain_lain'})}
            </PdfTabCol>
          </PdfTabCol>
        </PdfTabCol>
        <PdfSignatureBuilder
          data={[
            {
              line1: `${NullProof({
                input: data.data,
                params: 'lokasi',
              })}, ${NullProof({input: data.data, params: 'createdAt', type: 'date'})}`,
              line2: 'Inspektur Jenderal,',
              typeSignature: 'string',
              signature: '$',
              align: 'center',
              name: NullProof({
                input: data.data,
                params: 'ketua_data.nama',
                label: 'Inspektur Jenderal',
              }),
            },
          ]}
          options={{
            align: 'flex-end',
            data: {align: 'flex-end'},
          }}
          style={{marginBottom: '100pt'}}
        />
      </Page>
    </Document>
  )
}

export default PdfLayout
