import {DataRoutesProps} from '../../../UserRolesValidation'
import {DaftarVerifikatorRoutes} from './DaftarVerifikator'
import {PenandatangananNaskahRoutes} from './PenandatangananNaskah'
import {PenomoranNaskahRoutes} from './PenomoranNaskah'
import {DaftarGrupTujuanRoutes} from './DaftarGrupTujuan'
import {DaftarGrupTujuanDisposisiRoutes} from './DaftarGrupTujuanDisposisi'
import {DaftarTembusanRoutes} from './DaftarTembusan'
import {DaftarTujuanRoutes} from './DaftarTujuan'
import { JenisNaskahRoutes } from './JenisNaskah'
import { SifatNaskahRoutes } from './SifatNaskah'
import { TingkatUrgensiRoutes } from './TingkatUrgensi'
import { KlasifikasiNaskahRoutes } from './Klasifikasi'
import PenyusutanAkhirRoutes from './PenyusutanAkhir'

const NaskahPengaturanRoute: DataRoutesProps = {
  title: 'Pengaturan',
  icon: 'element-10',
  route: 'pengaturan',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Penomoran Naskah',
      icon: 'element-10',
      route: 'penomoran_naskah',
      elements: <></>,
      subroute: PenomoranNaskahRoutes,
    },
    {
      isPage: true,
      title: 'Daftar Penandatanganan',
      icon: 'element-10',
      route: 'penandatanganan_naskah',
      elements: <></>,
      subroute: PenandatangananNaskahRoutes,
    },
    {
      isPage: true,
      title: 'Daftar Verifikator',
      icon: 'element-10',
      route: 'daftar_verifikator',
      elements: <></>,
      subroute: DaftarVerifikatorRoutes,
    },
    {
      isPage: true,
      title: 'Daftar Tujuan',
      icon: 'element-10',
      route: 'daftar_tujuan',
      elements: <></>,
      subroute: DaftarTujuanRoutes,
    },
    {
      isPage: true,
      title: 'Daftar Grup Tujuan',
      icon: 'element-10',
      route: 'daftar_grup_tujuan',
      elements: <></>,
      subroute: DaftarGrupTujuanRoutes,
    },
    {
      isPage: true,
      title: 'Daftar Grup Tujuan Disposisi',
      icon: 'element-10',
      route: 'daftar_grup_tujuan_disposisi',
      elements: <></>,
      subroute: DaftarGrupTujuanDisposisiRoutes,
    },
    {
      isPage: true,
      title: 'Daftar Tembusan',
      icon: 'element-10',
      route: 'daftar_tembusan',
      elements: <></>,
      subroute: DaftarTembusanRoutes,
    },
    {
      isPage: true,
      title: 'Jenis Naskah',
      icon: 'element-10',
      route: 'jenis_naskah',
      elements: <></>,
      subroute: JenisNaskahRoutes,
    },
    {
      isPage: true,
      title: 'Sifat Naskah',
      icon: 'element-10',
      route: 'sifat_naskah',
      elements: <></>,
      subroute: SifatNaskahRoutes,
    },
    {
      isPage: true,
      title: 'Tingkat Urgensi',
      icon: 'element-10',
      route: 'tingkat_urgensi',
      elements: <></>,
      subroute: TingkatUrgensiRoutes,
    },
    {
      isPage: true,
      title: 'Klasifikasi',
      icon: 'element-10',
      route: 'klasifikasi_naskah',
      elements: <></>,
      subroute: KlasifikasiNaskahRoutes,
    },
    {
      isPage: true,
      title: 'Penyusutan Akhir',
      icon: 'element-10',
      route: 'penyusutan_akhir',
      subroute: PenyusutanAkhirRoutes,
    },
  ],
}

export default NaskahPengaturanRoute
