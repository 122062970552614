import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../../../_metronic/layout/core'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {CardUi} from '../../../../../../../helper/AppUi'
import {
  TableAction,
  TableColumn,
  TableInput,
  TableRow,
} from '../../../../../../../helper/TableInput'
import {
  ConvertCurrency,
  NullProof,
  numberlistPagination,
  RouterQueryParams,
} from '../../../../../../../helper/AppFunction'
import {formatDate} from '../../../../../../../helper/DateFns'
import FormInput from '../../../../../../../helper/FormInput'
import {formInputList, formInputListUraianTargetBulanan, formInputListUraianImplementasiBulanan} from './InputLayout'
import {useParams} from 'react-router-dom'
import {
  TableInputDetail,
  TableInputDetailColumn,
} from '../../../../../../../helper/TableInputDetail'
import {BaseInputOnChangeProps} from '../../../../../../../helper/FormInput'

const onChange = async (e: BaseInputOnChangeProps) => {
  const data = {...e._data.get}
  const result = {
    ...data,
  }
  e?.resetForm({values: result})
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({
            input: data,
            params: 'data',
            isMap: true,
          }).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Indikator' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'indikator'})}
                  </TableColumn>
                  <TableColumn label='Target' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'target'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const query = useParams()
  const id = query.id
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          // onChange={async (e: BaseInputOnChangeProps) => {
          //     if (e.type === 'onInit') {
          //         console.log('init');
          //       }
          //       if (e.type === 'onChange') {
          //         console.log('change');
          //       }
          // }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.detail_api}/:indikator_id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UraianTargeBulanan: FC = () => {
  const query = useParams()

  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.target.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.target.page.title}>
        <FormInput
          input={formInputListUraianTargetBulanan}
          route={{
            url: RouterQueryParams(`${InfoData.uraian_target_bulanan_api}`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UraianImplementasiBulanan: FC = () => {
  const query = useParams()

  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.implementasi.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.implementasi.page.title}>
        <FormInput
          input={formInputListUraianImplementasiBulanan}
          route={{
            url: RouterQueryParams(`${InfoData.uraian_implementasi_bulanan_api}`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})

  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tanggal Pemantauan'}}>
            {formatDate({
              date: NullProof({
                input: data,
                params: 'tanggal_pemantauan',
              }),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Hasil Pemantauan'}}>
            {NullProof({
              input: data,
              params: 'hasil_pemantauan',
              type: 'html',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Saran Itjen'}}>
            {NullProof({
              input: data,
              params: 'saran_itjen',
              type: 'html',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Pemantau'}}>
            {NullProof({
              input: data,
              params: 'pemantau',
              isMap: true,
            })?.map((l1: any, i1: number) => (
              <Fragment key={i1}>
                <div>
                  {`${i1 + 1}. ${NullProof({
                    input: l1,
                    params: 'pegawai.nama',
                  })}`}
                </div>
              </Fragment>
            ))}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {
  MainWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
  UraianTargeBulanan,
  UraianImplementasiBulanan,
}
