import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'
import {getListYear} from '../../../../helper/DateFns'

const formInputImportList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Panduan untuk Upload',
        description: (
          <div className='fw-normal'>
            <ol>
              <li>Unduh Template Excel Data Terbaru nya diatas.</li>
              <li>Buka melalui aplikasi Excel pada komputer.</li>
              <li>Ubah data sesuai update terbaru dari anda.</li>
              <li>Upload file yang sudah diubah pada komputer anda dibawah.</li>
            </ol>
          </div>
        ),
      },
    },
  },
  {
    className: 'col-12',
    name: 'csv',
    type: 'csv',
    value: '',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Template Excel Terbaru',
          placeholder: 'Template Excel Terbaru',
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'tahun',
    type: 'select',
    listData: getListYear('asc', new Date().getFullYear(), new Date().getFullYear() + 10),
    validator: Yup.string().required('Tahun mohon untuk diisi.'),
    options: {
      select: {
        props: {
          title: 'Tahun',
          placeholder: 'Tahun',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'provinsi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Provinsi is required'),
    options: {
      input: {
        props: {
          title: 'Provinsi',
          placeholder: 'Provinsi',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kota_bandara',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kota / Bandara is required'),
    options: {
      input: {
        props: {
          title: 'Kota / Bandara',
          placeholder: 'Kota / Bandara',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'uang_harian',
    type: 'input',
    value: '',
    validator: Yup.number().required('Uang Harian wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Uang Harian',
          placeholder: 'Uang Harian',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'eselon1',
    type: 'input',
    value: '',
    validator: Yup.number().required('Penginapan Eselon 1 wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Penginapan Eselon 1',
          placeholder: 'Penginapan Eselon 1',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'eselon2',
    type: 'input',
    value: '',
    validator: Yup.number().required('Penginapan Eselon 2 wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Penginapan Eselon 2',
          placeholder: 'Penginapan Eselon 2',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'eselon3_gol4',
    type: 'input',
    value: '',
    validator: Yup.number().required('Penginapan Eselon 3 & Gol 4 wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Penginapan Eselon 3 & Gol 4',
          placeholder: 'Penginapan Eselon 3 & Gol 4',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'eselon4_gol123',
    type: 'input',
    value: '',
    validator: Yup.number().required('Penginapan Eselon 4 & Gol 1, 2, 3 wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Penginapan Eselon 4 & Gol 1, 2, 3',
          placeholder: 'Penginapan Eselon 4 & Gol 1, 2, 3',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'bst_kantor_sbm',
    type: 'input',
    value: '',
    validator: Yup.number().required('BST Kantor (SBM) wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'BST Kantor (SBM)',
          placeholder: 'BST Kantor (SBM)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'bst_lokasi_sbm',
    type: 'input',
    value: '',
    validator: Yup.number().required('BST Lokasi (SBM) wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'BST Lokasi (SBM)',
          placeholder: 'BST Lokasi (SBM)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tiket_transport',
    type: 'input',
    value: '',
    validator: Yup.number().required('Tiket Transport wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Tiket Transport',
          placeholder: 'Tiket Transport',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tiket_bst_full',
    type: 'input',
    value: '',
    validator: Yup.number().required('TIKET + BST FULL wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'TIKET + BST FULL',
          placeholder: 'TIKET + BST FULL',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tiket_transport_bisnis',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Tiket Transport Bisnis',
          placeholder: 'Tiket Transport Bisnis',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tiket_bst_full_bisnis',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'TIKET + BST FULL BISNIS',
          placeholder: 'TIKET + BST FULL BISNIS',
          type: 'number',
        },
      },
    },
  },
]

export {formInputList, formInputImportList}
