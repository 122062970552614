import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'
import inputLayoutBuilder from '../../../helper/template/FormInputTemplate'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Pegawai',
          placeholder: 'Nama Pegawai',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
            {id: 'level_jabatan', type: 'text'},
            {id: 'satker_4', type: 'text'},
          ],
          protect: '',
          props: {
            filter: [
              ...inputLayoutBuilder({
                input: [{template: 'checkbox', name: 'irjen', title: 'Pegawai Itjen'}],
              }),
            ],
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
              {title: 'Level Jabatan'},
              {title: 'Satker'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'keterangan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Keterangan is required'),
    options: {
      input: {
        props: {
          title: 'Keterangan',
          placeholder: 'Keterangan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'no_pok',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'NO POK',
          placeholder: 'NO POK',
        },
      },
    },
  },
]

export {formInputList}
