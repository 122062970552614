import {FC, Fragment} from 'react'
import {Document, Page, View, Text, Image} from '@react-pdf/renderer'
import {
  PdfHeaderApp,
  PdfSignatureBuilder,
  PdfStyle,
  PdfStylePallete,
} from '../../../../helper/ReactPdf'
import {
  AlphabetIndex,
  ConvertCurrency,
  NullProof,
  currencyToWords,
  capitalizeEachWord,
} from '../../../../helper/AppFunction'
import {formatDate} from '../../../../helper/DateFns'
import {filterJabatanAnggota} from '../../../../helper/KemenagFunction'

export type PdfLayoutDataProps = {
  image: any
  perusahaan: {
    logo: string
    nama: string
    alamat: string
    telepon: string
    fax: string
    website: string
  }
  data: any
}

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  const TableData = () => {
    return (
      <>
        <View
          style={{
            ...PdfStylePallete.table.style,
            marginTop: '10px',
            borderStyle: 'solid',
            fontFamily: 'Arial',
            fontSize: PdfStylePallete.typography.label.medium,
          }}
        >
          <View style={{flexDirection: 'row'}}>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '6%',
                textAlign: 'center',
                paddingHorizontal: '5px',
              }}
            >
              No.
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '60%',
                textAlign: 'left',
                paddingHorizontal: '5px',
              }}
            >
              Perincian Biaya
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '20%',
                textAlign: 'left',
                paddingHorizontal: '5px',
              }}
            >
              Jumlah
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '15%',
                textAlign: 'left',
                paddingHorizontal: '5px',
              }}
            >
              Keterangan
            </Text>
          </View>
          {/* Uang Harian */}
          <View style={{flexDirection: 'row'}}>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '6%',
                textAlign: 'center',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            >
              1.
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '30%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            >
              Uang Harian
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '30%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            >
              {`${NullProof({
                input: data.data,
                params: 'uang_harian_hari',
              })} Hari @ ${ConvertCurrency(
                NullProof({input: data.data, params: 'uang_harian_harga', isLabel: false})
              )}`}
            </Text>
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '20%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '5px',
              }}
            >
              <Text>Rp</Text>
              <Text style={{width: '100%', textAlign: 'right'}}>
                {ConvertCurrency(
                  NullProof({input: data.data, params: 'uang_harian_total', isLabel: false})
                ).replace('Rp', '')}
              </Text>
            </View>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '15%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            ></Text>
          </View>
          {/* Uang Representatif */}
          <View style={{flexDirection: 'row'}}>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '6%',
                textAlign: 'center',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            >
              2.
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '30%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            >
              Uang Representatif
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '30%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            >
              {`${NullProof({
                input: data.data,
                params: 'uang_representatif_hari',
              })} Hari @ ${ConvertCurrency(
                NullProof({input: data.data, params: 'uang_representatif_harga', isLabel: false})
              )}`}
            </Text>
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '20%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '5px',
              }}
            >
              <Text>Rp</Text>
              <Text style={{width: '100%', textAlign: 'right'}}>
                {ConvertCurrency(
                  NullProof({input: data.data, params: 'uang_representatif_total', isLabel: false})
                ).replace('Rp', '')}
              </Text>
            </View>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '15%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            ></Text>
          </View>
          {/* Uang Penginapan */}
          {NullProof({input: data.data, params: 'uang_penginapan_data', isMap: true})?.map(
            (l: any, i: any) => (
              <Fragment key={i}>
                <View style={{flexDirection: 'row'}}>
                  <Text
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '6%',
                      textAlign: 'center',
                      paddingHorizontal: '5px',
                      borderBottomWidth: 0,
                    }}
                  >
                    {i === 0 && '3.'}
                  </Text>
                  <Text
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '30%',
                      textAlign: 'left',
                      paddingHorizontal: '5px',
                      borderBottomWidth: 0,
                    }}
                  >
                    {i === 0 && 'Uang Penginapan'}
                  </Text>
                  <Text
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '30%',
                      textAlign: 'left',
                      paddingHorizontal: '5px',
                      borderBottomWidth: 0,
                    }}
                  >
                    {`${NullProof({
                      input: l,
                      params: 'hari',
                    })} Hari @ ${ConvertCurrency(
                      NullProof({input: l, params: 'harga', isLabel: false})
                    )}`}
                  </Text>
                  <View
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '20%',
                      textAlign: 'left',
                      paddingHorizontal: '5px',
                      borderBottomWidth: 0,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      gap: '5px',
                    }}
                  >
                    <Text>Rp</Text>
                    <Text style={{width: '100%', textAlign: 'right'}}>
                      {ConvertCurrency(
                        NullProof({input: l, params: 'total', isLabel: false})
                      ).replace('Rp', '')}
                    </Text>
                  </View>
                  <Text
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '15%',
                      textAlign: 'left',
                      paddingHorizontal: '5px',
                      borderBottomWidth: 0,
                    }}
                  ></Text>
                </View>
              </Fragment>
            )
          )}
          {/* Uang Transport */}
          {NullProof({input: data.data, params: 'transportasi_data', isMap: true})?.map(
            (l: any, i: any) => (
              <Fragment key={i}>
                <View style={{flexDirection: 'row'}}>
                  <Text
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '6%',
                      textAlign: 'center',
                      paddingHorizontal: '5px',
                      borderBottomWidth: 0,
                    }}
                  >
                    {i === 0 && `4${i !== 0 ? AlphabetIndex(i - 1) : ''}.`}
                  </Text>
                  <Text
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '30%',
                      textAlign: 'left',
                      paddingHorizontal: '5px',
                      borderBottomWidth: 0,
                    }}
                  >
                    {`Transport - ${NullProof({input: l, params: 'asal'})}`}
                  </Text>
                  <View
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '30%',
                      textAlign: 'left',
                      paddingHorizontal: '5px',
                      borderBottomWidth: 0,
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={{width: '70%', textAlign: 'center'}}>
                      {NullProof({input: l, params: 'tujuan'})}
                    </Text>
                    <Text style={{width: '30%', textAlign: 'right'}}>
                      {NullProof({input: l, params: 'jenis'})}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '20%',
                      textAlign: 'left',
                      paddingHorizontal: '5px',
                      borderBottomWidth: 0,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      gap: '5px',
                    }}
                  >
                    <Text>Rp</Text>
                    <Text style={{width: '100%', textAlign: 'right'}}>
                      {ConvertCurrency(
                        NullProof({input: l, params: 'harga', isLabel: false})
                      ).replace('Rp', '')}
                    </Text>
                  </View>
                  <Text
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '15%',
                      textAlign: 'left',
                      paddingHorizontal: '5px',
                      borderBottomWidth: 0,
                    }}
                  ></Text>
                </View>
              </Fragment>
            )
          )}
          {/* Airport Tax */}
          <View
            style={{
              flexDirection: 'row',
              borderStyle: 'solid',
              borderWidth: 0,
              borderBottomWidth: 1,
            }}
          >
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '6%',
                textAlign: 'center',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            >
              5.
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '30%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            >
              Uang Airport Tax
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '30%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            ></Text>
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '20%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '5px',
              }}
            >
              <Text>Rp</Text>
              <Text style={{width: '100%', textAlign: 'right'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data.data,
                    params: 'airport_tax',
                    isLabel: false,
                  })
                ).replace('Rp', '')}
              </Text>
            </View>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '15%',
                textAlign: 'left',
                paddingHorizontal: '5px',
                borderBottomWidth: 0,
              }}
            ></Text>
          </View>
        </View>
        {/* Total Nominal */}
        <View
          style={{
            flexDirection: 'row',
            marginVertical: '5px',
            borderStyle: 'solid',
            borderWidth: 0,
            borderBottomWidth: 1,
            fontWeight: 700,
          }}
        >
          <Text
            style={{
              ...PdfStylePallete.table.col,
              borderStyle: 'solid',
              width: '66%',
              textAlign: 'right',
              paddingHorizontal: '5px',
              borderWidth: 0,
            }}
          >
            Jumlah Dibayarkan:
          </Text>
          <View
            style={{
              ...PdfStylePallete.table.col,
              borderStyle: 'solid',
              width: '20%',
              textAlign: 'left',
              paddingHorizontal: '5px',
              borderWidth: 0,
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '5px',
            }}
          >
            <Text>Rp</Text>
            <Text style={{width: '100%', textAlign: 'right'}}>
              {ConvertCurrency(
                NullProof({input: data.data, params: 'jumlah_dibayarkan', isLabel: false})
              ).replace('Rp', '')}
            </Text>
          </View>
          <Text
            style={{
              ...PdfStylePallete.table.col,
              borderStyle: 'solid',
              width: '15%',
              textAlign: 'left',
              paddingHorizontal: '5px',
              borderWidth: 0,
            }}
          ></Text>
        </View>
        {/* Terbilang */}
        <Text
          style={{
            textAlign: 'center',
            fontWeight: 700,
            textTransform: 'capitalize',
          }}
        >
          {`Terbilang: ${currencyToWords(
            NullProof({input: data.data, params: 'jumlah_dibayarkan', isLabel: false}) || 0
          )}`}
        </Text>
      </>
    )
  }

  return (
    <Document title='Dokumen'>
      <Page wrap={false} style={{...PdfStyle.page, fontFamily: 'Arial'}}>
        <PdfHeaderApp data={data} />
        <View
          style={{
            height: '2px',
            backgroundColor: '#000000',
          }}
        ></View>
        <View
          style={{
            marginHorizontal: 'auto',
            width: '100%',
            fontFamily: 'Arial',
            justifyContent: 'center',
            letterSpacing: '0.5px',
            fontSize: PdfStylePallete.typography.label.medium,
          }}
        >
          <Text
            style={{
              width: '100%',
              textAlign: 'center',
              marginVertical: '15px',
              fontSize: PdfStylePallete.typography.title.medium,
              fontWeight: 700,
              textTransform: 'uppercase',
              textDecoration: 'underline',
            }}
          >
            Rincian Biaya Perjalanan Dinas
          </Text>
          <View
            style={{
              marginVertical: '5px',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '35%',
                }}
              >
                Lampiran SPD Nomor
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <Text
                style={{
                  width: '100%',
                }}
              >
                {NullProof({
                  input: data.data,
                  params: 'surat_tugas.nomor_surat',
                  label: 'Nomor Surat',
                })}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '35%',
                }}
              >
                Tanggal
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <Text
                style={{
                  width: '100%',
                }}
              >
                {formatDate({
                  date:
                    NullProof({input: data.data, params: 'surat_tugas.tanggal', isLabel: false}) ||
                    '',
                  dateFormat: 'dd MMMM yyyy',
                })}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '35%',
                }}
              >
                Kegiatan
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <Text
                style={{
                  width: '100%',
                }}
              ></Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '100%',
                }}
              >
                {NullProof({
                  input: data.data,
                  params: 'surat_tugas.nama_kegiatan',
                  label: 'Nama Kegiatan',
                })}
              </Text>
            </View>
          </View>
          <TableData />
          <View
            style={{
              marginTop: '10px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                width: '45%',
              }}
            >
              <Text>Telah dibayar sejumlah</Text>
              <Text>
                {ConvertCurrency(
                  NullProof({input: data.data, params: 'jumlah_dibayarkan', isLabel: false}) || 0
                )}
              </Text>
              <Text style={{textTransform: 'capitalize'}}>
                {currencyToWords(
                  NullProof({input: data.data, params: 'jumlah_dibayarkan', isLabel: false}) || 0
                )}
              </Text>
            </View>
            <View
              style={{
                width: '45%',
              }}
            >
              <Text>{NullProof({input: data.data, params: 'lokasi'})},</Text>
              <Text>Telah menerima jumlah uang</Text>
              <Text>
                {ConvertCurrency(
                  Number(NullProof({input: data.data, params: 'uang_muka', isLabel: false}) || 0) +
                    Number(
                      NullProof({
                        input: data.data,
                        params: 'uang_muka_transportasi',
                        isLabel: false,
                      }) || 0
                    )
                )}
              </Text>
              <Text style={{textTransform: 'capitalize'}}>
                {currencyToWords(
                  Number(NullProof({input: data.data, params: 'uang_muka', isLabel: false}) || 0) +
                    Number(
                      NullProof({
                        input: data.data,
                        params: 'uang_muka_transportasi',
                        isLabel: false,
                      }) || 0
                    )
                )}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginTop: '30px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {/* <View
              style={{
                width: '45%',
              }}
            >
              <Text>Bendahara Pengeluaran</Text>
              <View
                style={{
                  marginTop: '50px',
                }}
              >
                <Text>
                  {NullProof({input: data.data, params: 'bendahara.nama', label: 'Nama'})}
                  {`\n`}
                </Text>
                <Text>
                  NIP: {NullProof({input: data.data, params: 'bendahara.nip', label: 'NIP'})}
                </Text>
              </View>
            </View> */}

            <PdfSignatureBuilder
              data={[
                {
                  line1: `Bendahara Pengeluaran`,
                  line2: '',
                  typeSignature: 'string',
                  signature: '*',
                  name: `${NullProof({
                    input: data.data,
                    params: 'bendahara.pegawai.nama',
                    label: 'Nama',
                  })}\nNIP: ${NullProof({
                    input: data.data,
                    params: 'bendahara.pegawai.nip',
                    label: 'NIP',
                  })}`,
                  align: 'center',
                },
              ]}
              options={{
                align: 'center',
                data: {align: 'center', nameStyle: {textAlign: 'center'}},
              }}
            />
            {/* <View
              style={{
                width: '45%',
              }}
            >
              <Text>Yang menerima,</Text>
              <View
                style={{
                  marginTop: '50px',
                }}
              >
                <Text>
                  {NullProof({input: data.data, params: 'pegawai.nama', label: 'Nama'})}
                  {`\n`}
                </Text>
                <Text>
                  NIP: {NullProof({input: data.data, params: 'pegawai.nip', label: 'NIP'})}
                </Text>
              </View>
            </View> */}
            <PdfSignatureBuilder
              data={[
                {
                  line1: `Yang Menerima`,
                  line2: '',
                  typeSignature: 'string',
                  signature: '^',
                  name: `${NullProof({
                    input: data.data,
                    params: 'pegawai.nama',
                    label: 'Nama',
                  })}\nNIP: ${NullProof({input: data.data, params: 'pegawai.nip', label: 'NIP'})}`,
                  align: 'center',
                },
              ]}
              options={{
                align: 'center',
                data: {align: 'center', nameStyle: {textAlign: 'center'}},
              }}
            />
          </View>
          <View
            style={{
              height: '2px',
              marginTop: '10px',
              backgroundColor: '#000000',
            }}
          ></View>
          <Text
            style={{
              width: '100%',
              textAlign: 'center',
              marginVertical: '15px',
              fontSize: PdfStylePallete.typography.title.small,
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            Perhitungan SPD Rampung
          </Text>
          <View
            style={{
              margin: 'auto',
              width: '80%',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '35%',
                }}
              >
                Ditetapkan sejumlah
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <View
                style={{
                  textAlign: 'right',
                  width: '60%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '30%'}}>Rp.</Text>
                <Text>
                  {ConvertCurrency(
                    NullProof({input: data.data, params: 'jumlah_dibayarkan', isLabel: false})
                  ).replace('Rp', '')}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '35%',
                }}
              >
                Yang telah dibayar semula
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <View
                style={{
                  textAlign: 'right',
                  width: '60%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '30%'}}>Rp.</Text>
                <Text>
                  {ConvertCurrency(
                    Number(
                      NullProof({input: data.data, params: 'uang_muka', isLabel: false}) || 0
                    ) +
                      Number(
                        NullProof({
                          input: data.data,
                          params: 'uang_muka_transportasi',
                          isLabel: false,
                        }) || 0
                      )
                  ).replace('Rp', '')}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '35%',
                }}
              >
                Sisa (kurang) / lebih
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <View
                style={{
                  textAlign: 'right',
                  width: '60%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderTop: '1px solid black',
                }}
              >
                <Text style={{width: '30%'}}>Rp.</Text>
                <Text>
                  {ConvertCurrency(
                    Number(
                      NullProof({input: data.data, params: 'jumlah_dibayarkan', isLabel: false}) ||
                        0
                    ) -
                      (Number(
                        NullProof({input: data.data, params: 'uang_muka', isLabel: false}) || 0
                      ) +
                        Number(
                          NullProof({
                            input: data.data,
                            params: 'uang_muka_transportasi',
                            isLabel: false,
                          }) || 0
                        ))
                  ).replace('Rp', '')}
                </Text>
              </View>
            </View>
          </View>
          <PdfSignatureBuilder
            data={[
              {
                line1: `Mengetahui:`,
                line2: 'Pejabat Pembuat Komitmen',
                typeSignature: 'string',
                signature: '$',
                name: `${NullProof({
                  input: data.data,
                  params: 'ppk.nama',
                  label: 'Nama',
                })}\nNIP: ${NullProof({input: data.data, params: 'ppk.nip', label: 'NIP'})}`,
                align: 'center',
              },
            ]}
            options={{
              align: 'center',
              data: {align: 'center', nameStyle: {textAlign: 'center'}},
            }}
            style={{paddingRight: '0', marginBottom: '100pt'}}
          />
        </View>
      </Page>
    </Document>
  )
}

const PdfLayout2: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  const dataPdf = data.data.revisi ? data.data.revisi?.old : data.data
  // const currentDate = new Date();
  const bulan_sekarang = formatDate({
    date: NullProof({input: dataPdf, params: 'createdAt'}),
    dateFormat: 'dd MMMM yyyy',
  })
  const filterProvinsi = (provinsi: string) => {
    if (provinsi.includes('DKI' || 'D.I')) {
      const words = provinsi.split(' ')
      words[1] = capitalizeEachWord(words[1])
      return words.join(' ')
    } else {
      return capitalizeEachWord(provinsi)
    }
  }
  const formatDateDiff = (startDate: Date, endDate: Date) => {
    const earliest = new Date(startDate)
    const latest = new Date(endDate)
    if (
      earliest.getFullYear() === latest.getFullYear() &&
      earliest.getMonth() === latest.getMonth()
    ) {
      return `${formatDate({date: earliest.toString(), dateFormat: 'dd'})} - ${formatDate({
        date: latest.toString(),
        dateFormat: 'dd MMMM yyyy',
      })}`
    } else if (earliest.getMonth() !== latest.getMonth()) {
      return `${formatDate({date: earliest.toString(), dateFormat: 'dd MMMM'})} - ${formatDate({
        date: latest.toString(),
        dateFormat: 'dd MMMM yyyy',
      })}`
    } else {
      return `${formatDate({date: earliest.toString(), dateFormat: 'dd MMMM yyyy'})} - ${formatDate(
        {date: latest.toString(), dateFormat: 'dd MMMM yyyy'}
      )}`
    }
  }
  const _kwitansi_lain_lain_list: Array<any> =
    NullProof({input: dataPdf, params: 'kwitansi_lain_lain_list', isMap: true}).filter(
      (l: any) => l.kwitansi && l.kwitansi !== null
    ) || []
  const _uang_penginapan_data: Array<any> =
    NullProof({input: dataPdf, params: 'uang_penginapan_data', isMap: true}).filter(
      (l: any) => l.kwitansi && l.kwitansi !== null
    ) || []
  const _uang_harian_total: number = parseInt(dataPdf.uang_harian_total) || 0
  const _uang_representatif_total: number = parseInt(dataPdf.uang_representatif_total) || 0
  const _uang_penginapan_total: number = NullProof({
    input: dataPdf,
    params: 'uang_penginapan_data',
  }).reduce((a: any, b: any) => a + b.total, 0)
  const _transportasi_data_total: number = NullProof({
    input: dataPdf,
    params: 'transportasi_data',
    isMap: true,
  }).reduce((a: any, b: any) => a + b.total, 0)
  const _bst_kantor: number = parseInt(dataPdf.bst_kantor) || 0
  const _bst_lokasi: number = parseInt(dataPdf.bst_lokasi) || 0
  const _kwitansi_lain_lain_list_total: number = NullProof({
    input: dataPdf,
    params: 'kwitansi_lain_lain_list',
  }).reduce((a: any, b: any) => a + b.total, 0)
  const _total_pengeluaran_rill: number =
    _uang_harian_total +
    _uang_representatif_total +
    _uang_penginapan_total +
    _transportasi_data_total +
    _bst_kantor +
    _bst_lokasi +
    _kwitansi_lain_lain_list_total
  const keterangan_penginapan =
    NullProof({input: dataPdf, params: 'uang_penginapan_data', isMap: true}).filter(
      (l: any) => l.tanpa_hotel == true
    ) || []
  const filterKetuaAnggota = filterJabatanAnggota({
    data: NullProof({
      input: data.data,
      params: 'surat_tugas.kepada',
      isMap: true,
    }),
    jabatan: ['Ketua Tim'],
    anggota: NullProof({
      input: data.data,
      params: 'anggota',
    }),
  })
  // console.log(filterKetuaAnggota)

  const LineTable = ({child1, child2, child3}: {child1: any; child2?: any; child3?: any}) => {
    return (
      <View style={{flexDirection: 'row'}}>
        <Text
          style={{
            ...PdfStylePallete.table.col,
            borderStyle: 'solid',
            width: '33%',
            textAlign: 'left',
          }}
        >
          {child1}
        </Text>
        <Text
          style={{
            ...PdfStylePallete.table.col,
            borderStyle: 'solid',
            width: '27%',
            textAlign: 'right',
            justifyContent: 'space-between',
          }}
        >
          {child2}
        </Text>
        <Text
          style={{
            ...PdfStylePallete.table.col,
            borderStyle: 'solid',
            width: '40%',
            textAlign: 'left',
            justifyContent: 'space-between',
          }}
        >
          {child3}
        </Text>
      </View>
    )
  }
  const TableData = () => {
    return (
      <>
        <View
          style={{
            ...PdfStylePallete.table.style,
            marginTop: '10px',
            borderStyle: 'solid',
            fontFamily: 'Arial',
            fontSize: PdfStylePallete.typography.label.medium,
          }}
        >
          {/* Header Table */}
          <View style={{flexDirection: 'row'}}>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '33%',
                textAlign: 'center',
                fontWeight: 600,
              }}
            >
              JENIS PENGELUARAN RIIL
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '27%',
                textAlign: 'center',
                fontWeight: 600,
              }}
            >
              PENGELUARAN RIIL
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '40%',
                textAlign: 'center',
                fontWeight: 600,
              }}
            >
              KETERANGAN
            </Text>
          </View>
          <LineTable
            child1={'Uang Harian'}
            child2={NullProof({
              input: dataPdf,
              params: 'uang_harian_total',
              type: 'currency',
              isLabel: false,
            })}
            child3={''}
          />
          <LineTable
            child1={'Uang Representatif'}
            child2={NullProof({
              input: dataPdf,
              params: 'uang_representatif_total',
              type: 'currency',
              isLabel: false,
            })}
            child3={''}
          />
          <LineTable
            child1={'Transportasi'}
            child2={ConvertCurrency(
              NullProof({
                input: dataPdf,
                params: 'transportasi_data',
                type: 'currency',
                isMap: true,
              }).reduce((a: any, b: any) => a + b.total, 0)
            )}
            child3={''}
          />
          <LineTable
            child1={'Penginapan/Hotel'}
            child2={ConvertCurrency(
              NullProof({
                input: dataPdf,
                params: 'uang_penginapan_data',
                type: 'currency',
                isMap: true,
              }).reduce((a: any, b: any) => a + b.total, 0)
            )}
            child3={
              keterangan_penginapan.length > 0 &&
              keterangan_penginapan.map(
                (l: any, i: any) => `${l.hari} mlm*(30% * ${ConvertCurrency(l.harga)}) `
              )
            }
          />
          <LineTable
            child1={'Taksi B/S/T ** - Kantor (PP)'}
            child2={NullProof({
              input: dataPdf,
              params: 'bst_kantor',
              type: 'currency',
              isLabel: false,
            })}
            child3={''}
          />
          <LineTable
            child1={'Taksi B/S/T ** - Lokasi (PP)'}
            child2={NullProof({
              input: dataPdf,
              params: 'bst_lokasi',
              type: 'currency',
              isLabel: false,
            })}
            child3={''}
          />
          <LineTable
            child1={'Transport Kota 2'}
            // child2={}
            // child3={''}
          />
          <LineTable
            child1={'Lain-lain'}
            child2={ConvertCurrency(
              NullProof({
                input: dataPdf,
                params: 'kwitansi_lain_lain_list',
                type: 'currency',
                isMap: true,
              }).reduce((a: any, b: any) => a + b.total, 0)
            )}
            child3={''}
          />
          {/* Buttom Table */}
          <View style={{flexDirection: 'row'}}>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '33%',
                textAlign: 'center',
                fontWeight: 600,
                textTransform: 'uppercase',
              }}
            >
              JUMLAH
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '27%',
                textAlign: 'right',
                justifyContent: 'space-between',
              }}
            >
              {ConvertCurrency(_total_pengeluaran_rill)}
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '40%',
                textAlign: 'left',
                justifyContent: 'space-between',
              }}
            ></Text>
          </View>
        </View>
      </>
    )
  }

  const FormatData = ({
    title,
    content,
    isTable = false,
    isWidthFull = false,
    style,
  }: {
    title: any
    content?: any
    isTable?: boolean
    isWidthFull?: boolean
    style?: object
  }) => {
    return (
      <View
        style={{
          width: isWidthFull ? '100%' : '90%',
          marginVertical: '5px',
          ...style,
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text
            style={{
              width: '100%',
              fontWeight: 600,
              textTransform: 'uppercase',
            }}
          >
            {title}
          </Text>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: '1',
              marginLeft: isTable ? '15px' : '40px',
              letterSpacing: '.5',
            }}
          >
            {content}
          </View>
        </View>
      </View>
    )
  }

  const PdfHeader = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          borderBottom: '1px solid black',
          position: 'relative',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            lineHeight: '1',
            padding: '0% 4%',
            letterSpacing: '.5',
            width: '50%',
            borderRight: '1px solid black',
          }}
        >
          <Image
            src={data?.perusahaan?.logo}
            style={{
              width: '90px',
              height: '90px',
              objectFit: 'contain',
              marginRight: '10px',
            }}
          />
          <View
            style={{
              width: '100%',
            }}
          >
            <View
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text
                style={{
                  width: '100%',
                  fontWeight: 600,
                  fontSize: PdfStylePallete.typography.label.mini,
                  marginBottom: '30px',
                }}
              >
                {data?.perusahaan?.nama}
              </Text>
              <Text
                style={{
                  width: '100%',
                  fontWeight: 600,
                  fontSize: PdfStylePallete.typography.label.mini,
                }}
              >
                FORMULIR VERIFIKASI PERJALANAN DINAS
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            lineHeight: '1',
            padding: '0% 4%',
            letterSpacing: '.5',
            width: '50%',
            height: '100%',
          }}
        >
          <Text
            style={{
              fontWeight: 400,
              fontSize: PdfStylePallete.typography.label.small,
              marginBottom: '10px',
            }}
          >
            Nomor Surat Tugas : {NullProof({input: dataPdf, params: 'surat_tugas.nomor_surat'})}
          </Text>
          <Text
            style={{
              fontWeight: 400,
              fontSize: PdfStylePallete.typography.label.small,
            }}
          >
            Tanggal Surat Tugas :{' '}
            {NullProof({input: dataPdf, params: 'surat_tugas.tanggal', type: 'date'})}
          </Text>
        </View>
      </View>
    )
  }
  return (
    <Document title='Dokumen'>
      <Page
        wrap={false}
        style={{...PdfStyle.page, gap: 0, fontFamily: 'Arial', border: '1px solid black'}}
      >
        <View
          style={{
            marginHorizontal: 'auto',
            width: '100%',
            fontFamily: 'Arial',
            justifyContent: 'center',
            letterSpacing: '0.5px',
            fontSize: PdfStylePallete.typography.label.medium,
            border: '1px solid black',
          }}
        >
          <PdfHeader />
          <FormatData
            title={'1.  Identitas'}
            content={
              <Fragment>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{width: '30%'}}>NAMA/NIP</Text>
                    <Text
                      style={{
                        width: '70%',
                        textDecoration: 'underline',
                      }}
                    >
                      {NullProof({input: dataPdf, params: 'pegawai.nama'})} /{' '}
                      {NullProof({input: dataPdf, params: 'pegawai.nip'})}
                    </Text>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{width: '30%'}}>WILAYAH/SUBBAG</Text>
                    <Text
                      style={{
                        width: '70%',
                        textDecoration: 'underline',
                      }}
                    >
                      {NullProof({input: dataPdf, params: 'pegawai.unit_kerja'})}
                    </Text>
                  </View>
                </View>
              </Fragment>
            }
          />
          <FormatData
            title={'2.  PERJALANAN DINAS'}
            content={
              <Fragment>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{width: '30%'}}>TIM/KEGIATAN</Text>
                    <Text
                      style={{
                        width: '70%',
                        textDecoration: 'underline',
                      }}
                    >
                      {NullProof({input: dataPdf, params: 'surat_tugas.nama_kegiatan'})}
                    </Text>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{width: '30%'}}>KOTA TUJUAN</Text>
                    <Text
                      style={{
                        width: '70%',
                        textDecoration: 'underline',
                      }}
                    >
                      {NullProof({input: dataPdf, params: 'surat_tugas.tujuan', isMap: true})?.map(
                        (l: any, i: number) => (
                          <Fragment key={i}>
                            {l.tipe_tujuan !== 'Luar negeri' ? (
                              <View
                                style={{
                                  flexDirection: 'column',
                                  width: '100%',
                                }}
                              >
                                <Text style={{width: '95%', textAlign: 'justify'}}>
                                  {NullProof({
                                    input: l,
                                    params: 'satker.nama_satker',
                                    label: 'satker',
                                  })}
                                  {', '}
                                  {l.kota2 !== null && l.satker_2 && (
                                    <Text style={{width: '95%', textAlign: 'justify'}}>
                                      {NullProof({
                                        input: l,
                                        params: 'satker_2.nama_satker',
                                        label: 'satker2',
                                      })}
                                      {', '}
                                    </Text>
                                  )}
                                  {l.kota3 !== null && l.satker_3 && (
                                    <Text style={{width: '95%', textAlign: 'justify'}}>
                                      {NullProof({
                                        input: l,
                                        params: 'satker_3.nama_satker',
                                        label: 'satker3',
                                      })}
                                      {', '}
                                    </Text>
                                  )}
                                  {capitalizeEachWord(
                                    NullProof({
                                      input: l,
                                      params: 'kota',
                                      label: 'kota',
                                    })?.toLowerCase()
                                  )}
                                </Text>
                                {l.kota2 && NullProof({input: l, params: 'kota2'}) !== '' && (
                                  <Text style={{width: '95%', textAlign: 'justify'}}>
                                    {l.kota3 ? ', ' : ' dan '}
                                    {NullProof({
                                      input: l,
                                      params: 'satker_2.nama_satker',
                                      label: 'satker2',
                                    })}
                                    {', '}
                                    {capitalizeEachWord(
                                      NullProof({
                                        input: l,
                                        params: 'kota2',
                                        label: 'kota2',
                                      })?.toLowerCase()
                                    )}{' '}
                                  </Text>
                                )}
                                {l.kota3 && NullProof({input: l, params: 'kota3'}) !== '' && (
                                  <Text style={{width: '95%', textAlign: 'justify'}}>
                                    dan{' '}
                                    {NullProof({
                                      input: l,
                                      params: 'satker_3.nama_satker',
                                      label: 'satker3',
                                    })}
                                    {', '}
                                    {capitalizeEachWord(
                                      NullProof({
                                        input: l,
                                        params: 'kota3',
                                        label: 'kota3',
                                      })?.toLowerCase()
                                    )}
                                  </Text>
                                )}
                                <Text style={{width: '95%', textAlign: 'justify'}}>
                                  {', Provinsi '}
                                  {filterProvinsi(
                                    NullProof({input: l, params: 'provinsi', label: 'provinsi'})
                                  )}
                                </Text>
                              </View>
                            ) : (
                              <View
                                style={{
                                  flexDirection: 'column',
                                  width: '100%',
                                }}
                              >
                                <Text style={{width: '95%', textAlign: 'justify'}}>
                                  {capitalizeEachWord(
                                    NullProof({input: l, params: 'kota', label: 'kota'})
                                  )}
                                  {', Negara'}
                                  {capitalizeEachWord(
                                    NullProof({input: l, params: 'negara', label: 'negara'})
                                  )}
                                </Text>
                              </View>
                            )}
                          </Fragment>
                        )
                      )}
                    </Text>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{width: '30%'}}>LAMA PELAKSANAAN</Text>
                    <Text
                      style={{
                        width: '70%',
                        textDecoration: 'underline',
                      }}
                    >
                      {NullProof({
                        input: dataPdf,
                        params: 'tanggal_realisasi_data',
                        isMap: true,
                      }).reduce((a: any, b: any) => a + b.tanggal_total, 0)}{' '}
                      Hari, Tanggal
                      {NullProof({
                        input: dataPdf,
                        params: 'tanggal_realisasi_data',
                        isMap: true,
                      }).map((item: any, index: number) => {
                        return `${
                          index === 0
                            ? ' '
                            : index === dataPdf.tanggal_realisasi_data.length - 1
                            ? `${index !== 1 ? ',' : ''} dan `
                            : ', '
                        }${formatDateDiff(item.tanggal_mulai, item.tanggal_selesai)}`
                      })}
                    </Text>
                  </View>
                </View>
              </Fragment>
            }
          />
          <FormatData
            title={'3.  Rincian Rill Biaya Perjalanan Dinas'}
            content={<TableData />}
            isTable={true}
          />
          <FormatData
            title={'4.  BUKTI PERJALANAN DINAS'}
            style={{borderBottom: '1px solid black', width: '100%', paddingBottom: '10px'}}
            content={
              <Fragment>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{width: '33%'}}>a. Surat Tugas</Text>
                    <View style={{width: '3%', border: '1px solid black', textAlign: 'center'}}>
                      {dataPdf.surat_tugas && <Text>√</Text>}
                    </View>
                    <Text style={{width: '12%'}}> Ada</Text>
                    <Text style={{width: '30%'}}> f. Boarding Pass Pergi</Text>
                    <View style={{width: '3%', border: '1px solid black', textAlign: 'center'}}>
                      {dataPdf.kwitansi_tiket_pergi_boarding && <Text>√</Text>}
                    </View>
                    <Text style={{width: '12%'}}> Ada</Text>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{width: '33%'}}>b. Fotokopi SPD</Text>
                    <View style={{width: '3%', border: '1px solid black', textAlign: 'center'}}>
                      {dataPdf.lampiran_penugasan && <Text>√</Text>}
                    </View>
                    <Text style={{width: '12%'}}> Ada</Text>
                    <Text style={{width: '30%'}}> g. Boarding Pass Pulang</Text>
                    <View style={{width: '3%', border: '1px solid black', textAlign: 'center'}}>
                      {dataPdf.kwitansi_tiket_pergi_boarding && <Text>√</Text>}
                    </View>
                    <Text style={{width: '12%'}}> Ada</Text>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{width: '33%'}}>c. Invoice Hotel</Text>
                    <View style={{width: '3%', border: '1px solid black', textAlign: 'center'}}>
                      {_uang_penginapan_data.length && <Text>√</Text>}
                    </View>
                    <Text style={{width: '12%'}}> Ada</Text>
                    <Text style={{width: '30%'}}> h. Tiket Pergi</Text>
                    <View style={{width: '3%', border: '1px solid black', textAlign: 'center'}}>
                      {dataPdf.kwitansi_tiket_pergi_boarding && <Text>√</Text>}
                    </View>
                    <Text style={{width: '12%'}}> Ada</Text>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{width: '33%'}}>d. Bukti B/S/T - Kantor (PP)</Text>
                    <View style={{width: '3%', border: '1px solid black', textAlign: 'center'}}>
                      {dataPdf.bst_kantor_lampiran && <Text>√</Text>}
                    </View>
                    <Text style={{width: '12%'}}> Lengkap</Text>
                    <Text style={{width: '30%'}}> i. Tiket Pulang</Text>
                    <View style={{width: '3%', border: '1px solid black', textAlign: 'center'}}>
                      {dataPdf.kwitansi_tiket_pulang_boarding && <Text>√</Text>}
                    </View>
                    <Text style={{width: '12%'}}> Ada</Text>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{width: '33%'}}>e. Bukti B/S/T - Lokasi (PP)</Text>
                    <View style={{width: '3%', border: '1px solid black', textAlign: 'center'}}>
                      {dataPdf.bst_lokasi_lampiran && <Text>√</Text>}
                    </View>
                    <Text style={{width: '12%'}}> Lengkap</Text>
                    <Text style={{width: '30%'}}> j. Kuitansi Lainnya</Text>
                    <View style={{width: '3%', border: '1px solid black', textAlign: 'center'}}>
                      {_kwitansi_lain_lain_list.length && <Text>√</Text>}
                    </View>
                    <Text style={{width: '12%'}}> Lengkap</Text>
                  </View>
                </View>
              </Fragment>
            }
          />
          <FormatData
            title={'5.  KETERANGAN: '}
            style={{
              width: '100%',
              paddingBottom: '50px',
              marginVertical: '0px',
              borderBottom: '1px solid black',
            }}
          />
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                width: '45%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                borderRight: '1px solid black',
              }}
            >
              <Text style={{fontWeight: 600, marginBottom: '15px', paddingHorizontal: '5px'}}>
                Saya menyatakan bahwa form ini diisi dengan sebenarnya,
              </Text>
              <View style={{flexDirection: 'row', marginBottom: '40px', lineHeight: '1.2'}}>
                <Text>Tanggal</Text>
                <Text style={{width: '50%', borderBottom: '1px solid black', textAlign: 'center'}}>
                  {' '}
                  {formatDate({
                    date: NullProof({input: dataPdf, params: 'createdAt'}),
                    dateFormat: 'dd MMMM yyyy',
                  })}
                </Text>
              </View>
              <Text style={{borderBottom: '1px solid black', width: '65%', lineHeight: '1.2'}}>
                {capitalizeEachWord(NullProof({input: dataPdf, params: 'pegawai.nama'}))}
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                paddingLeft: '5px',
                display: 'flex',
                flexDirection: 'column',
                borderRight: '1px solid black',
              }}
            >
              <Text style={{fontWeight: 600}}>Petugas:</Text>
              <Text style={{fontWeight: 600}}>Ketua Tim</Text>
              <View style={{flexDirection: 'row', marginBottom: '40px', lineHeight: '1.2'}}>
                <Text>Tanggal</Text>
                <Text style={{width: '50%', borderBottom: '1px solid black', textAlign: 'center'}}>
                  {' '}
                  {formatDate({
                    date: NullProof({input: dataPdf, params: 'status_ketua_tgl  '}),
                    dateFormat: 'dd MMMM yyyy',
                  })}
                </Text>
              </View>
              <Text
                style={{
                  width: '85%',
                  textAlign: 'left',
                  marginBottom: '15px',
                  borderBottom: '1px solid black',
                  lineHeight: '1.2',
                }}
              >
                {filterKetuaAnggota.ketuaTim.map((l: any, i: number) => (
                  <Fragment key={i}>
                    <Text>{capitalizeEachWord(NullProof({input: l, params: 'pegawai.nama'}))}</Text>
                  </Fragment>
                ))}
              </Text>
              <Text style={{fontWeight: 600}}>Tim Verifikasi</Text>
              <View style={{flexDirection: 'row', marginBottom: '40px', lineHeight: '1.2'}}>
                <Text>Tanggal</Text>
                <Text style={{width: '50%', borderBottom: '1px solid black', textAlign: 'center'}}>
                  {' '}
                  {formatDate({
                    date: NullProof({input: dataPdf, params: 'status_ppk_tgl'}),
                    dateFormat: 'dd MMMM yyyy',
                  })}
                </Text>
              </View>
              <Text style={{borderBottom: '1px solid black', width: '65%', lineHeight: '1.2'}}>
                {capitalizeEachWord(NullProof({input: dataPdf, params: 'ppk.nama'}))}
              </Text>
            </View>
            <View
              style={{
                width: '30%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text style={{fontWeight: 600}}>Catatan:</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export {PdfLayout, PdfLayout2}
