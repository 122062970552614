import {FC, Fragment, useEffect, useState} from 'react'
import {CardUi, TabsUi, XfiniteDataUi} from '../../helper/AppUi'
import {DateFNS} from '../../helper/DateFns'
import {getApiPath, NullProof} from '../../helper/AppFunction'
import ReactIcon from '../../helper/ReactIcon'
import clsx from 'clsx'
import axios from 'axios'
import {NotifikasiUi} from './NotifikasiButton'

const MainWrapper: FC = () => {
  const [activeTab, setActiveTab] = useState<any>('semua')
  const [data, setData] = useState<any>([])

  const filterData = (type: 'semua' | 'dibaca' | 'belum_dibaca' = 'semua') => {
    let _data = [...data]
    if (type === 'dibaca') {
      _data = _data.filter((l) => l?.is_read)
    } else if (type === 'belum_dibaca') {
      _data = _data.filter((l) => !l?.is_read)
    }
    return _data
  }

  return (
    <>
      <TabsUi
        title='Notifikasi'
        onChange={(id) => setActiveTab(id)}
        tabs={[
          {
            id: 'semua',
            title: 'Semua',
            children: <></>,
          },
          {
            id: 'dibaca',
            title: 'Dibaca',
            children: <></>,
          },
          {
            id: 'belum_dibaca',
            title: 'Belum Dibaca',
            children: <></>,
          },
        ]}
      >
        <XfiniteDataUi
          title='Notifikasi'
          scrollTargetId='window'
          onSuccess={(e) => setData(e?.data)}
          route={{query: 'limit=5&orderby=asc', api: '/account/notification'}}
        >
          <div className='d-flex flex-column gap-2'>
            {filterData(activeTab)?.map((l: any, i: number) => (
              <Fragment key={i}>
                <NotifikasiUi l={l} autoRead />
              </Fragment>
            ))}
            {!((filterData(activeTab)?.length || 0) > 0) && (
              <div className='py-4 px-2 d-flex flex-column align-items-center justify-content-center'>
                <div className='text-capitalize'>Notifikasi masih kosong</div>
              </div>
            )}
          </div>
        </XfiniteDataUi>
      </TabsUi>
    </>
  )
}

export {MainWrapper}
