import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'jenis_agenda',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Naskah Masuk',
        value: 'Naskah Masuk',
      },
      {
        title: 'Naskah Keluar',
        value: 'Naskah Keluar',
      },
    ],
    validator: Yup.string().required('Jenis Agenda is required'),
    options: {
      input: {
        props: {
          title: 'Jenis Agenda',
          placeholder: 'Jenis Agenda',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nomor_naskah',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nomor Naskahis required'),
    options: {
      input: {
        props: {
          title: 'Nomor Naskah',
          placeholder: 'Nomor Naskah',
        },
      },
    },
  },
]

export {formInputList}
