import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'text',
    type: 'input',
    value: '',
    validator: Yup.string().required('Jenis Naskah wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Jenis Naskah',
          placeholder: '',
        },
      },
    },
  },
]

export {formInputList}
