import {
  MainWrapper,
  PdfWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
  AnggotaWrapper,
  SpdWrapper,
  SpdPdfWrapper,
  TujuanWrapper,
} from '../../../../pages/pengawasan-internal/persiapan-pelaksanaan-pengawasan-internal/form-surat-tugas/MainWrapper'
import {DataRoutesProps} from '../../../UserRolesValidation'

const FormSuratTugasRoutes: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'pdf/:id',
    elements: <PdfWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tujuan/:id',
    elements: <TujuanWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'anggota/:id',
    elements: <></>,
    subroute: [
      {
        icon: '',
        title: '',
        route: '*',
        elements: <AnggotaWrapper />,
        subroute: [],
      },
      {
        icon: '',
        title: '',
        route: 'spd/:pegawaiid',
        elements: <SpdWrapper />,
        subroute: [],
      },
      {
        icon: '',
        title: '',
        route: 'pdf/:pegawaiid',
        elements: <SpdPdfWrapper />,
        subroute: [],
      },
    ],
  },
]

export {FormSuratTugasRoutes}
