import {DataRoutesProps} from '../../UserRolesValidation'
import {MainRoutes as DataPokokPangkatRoutes} from './DataPokokPangkat'
import {MainRoutes as DataTenagaAuditorRoutes} from './DataTenagaAuditor'
import {MainRoutes as DataPokokJabatanRoutes} from './DataPokokJabatan'
import {MainRoutes as DataAuditInstansiRoutes} from './data-audit/DataAuditInstansi'
import {MainRoutes as DataSatkerRoutes} from './DataSatker'
import {DataSatker2Routes} from './DataSatker2'
import {DataKegiatanRoutes} from './data-audit/DataKegiatan'
import {DataProgramRoutes} from './data-audit/DataProgram'
import {DataPegawaiRoutes} from './DataPegawai'

const DataAudit: DataRoutesProps = {
  title: 'Data Auditi',
  icon: 'element-10',
  route: 'data_auditi',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Data Instansi',
      icon: 'element-10',
      route: 'instansi',
      elements: <></>,
      subroute: DataAuditInstansiRoutes,
    },
    {
      isPage: true,
      title: 'Data Kegiatan',
      icon: 'element-10',
      route: 'kegiatan',
      elements: <></>,
      subroute: DataKegiatanRoutes,
    },
    {
      isPage: true,
      title: 'Data Program',
      icon: 'element-10',
      route: 'program',
      elements: <></>,
      subroute: DataProgramRoutes,
    },
  ],
}

const SumberDayaManusia: DataRoutesProps = {
  title: 'Sumber Daya Manusia',
  icon: 'element-10',
  route: 'sumber_daya_manusia',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Penilaian Kinerja Auditor',
      icon: 'element-10',
      route: 'penilaian_kinerja_auditor',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Kartu Penilaian Kinerja Auditor',
      icon: 'element-10',
      route: 'kartu_penilaian_kinerja',
      elements: <></>,
      subroute: [],
    },
  ],
}

const Kepegawaian: DataRoutesProps = {
  title: 'Kepegawaian',
  icon: 'element-10',
  route: 'kepegawaian',
  elements: <></>,
  subroute: [
    {...SumberDayaManusia},
    {...DataAudit},
    {
      isPage: true,
      title: 'Data Pokok Pangkat',
      icon: 'element-10',
      route: 'data_pokok_pangkat',
      elements: <></>,
      subroute: DataPokokPangkatRoutes,
    },
    {
      isPage: true,
      title: 'Data Pokok Jabatan',
      icon: 'element-10',
      route: 'data_pokok_jabatan',
      elements: <></>,
      subroute: DataPokokJabatanRoutes,
    },
    {
      isPage: true,
      title: 'Data Pegawai Itjen',
      icon: 'element-10',
      route: 'data_itjen',
      elements: <></>,
      subroute: DataTenagaAuditorRoutes,
    },
    {
      isPage: true,
      title: 'Data Pegawai',
      icon: 'element-10',
      route: 'data_pegawai',
      elements: <></>,
      subroute: DataPegawaiRoutes,
    },
    // {
    //   isPage: true,
    //   title: 'Data Satuan Kerja',
    //   icon: 'element-10',
    //   route: 'data_satker',
    //   elements: <></>,
    //   subroute: DataSatkerRoutes,
    // },
    // {
    //   isPage: true,
    //   title: 'Data Satker',
    //   icon: 'element-10',
    //   route: 'data_satker2',
    //   elements: <></>,
    //   subroute: DataSatker2Routes,
    // },
    {
      isPage: true,
      title: 'Data Tenaga Tata Usaha APIP',
      icon: 'element-10',
      route: 'data_tenaga_tata_usaha_apip',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Perancanaan Kebutuhan Pegawai',
      icon: 'element-10',
      route: 'perancanaan_kebutuhan_pegawai',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Pengadaan Pegawai',
      icon: 'element-10',
      route: 'pengadaan_pegawai',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Administrasi Kepegawaian',
      icon: 'element-10',
      route: 'administrasi_kepegawaian',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penilaian dan Pengembangan Kompetensi',
      icon: 'element-10',
      route: 'penilaian_pengembangan_kompetensi',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penilaian Kinerja dan Disiplin',
      icon: 'element-10',
      route: 'penilaian_kinerja_disiplin',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Reward & Punisment',
      icon: 'element-10',
      route: 'reward_punisment',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Pengembangan Karir dan Talenta',
      icon: 'element-10',
      route: 'pengembangan_karir_dan_talenta',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Pemberhentian',
      icon: 'element-10',
      route: 'pemberhentian',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Manajemen dan Analisis SDM',
      icon: 'element-10',
      route: 'manajemen_analisis_sdm',
      elements: <></>,
      subroute: [],
    },
    // {...DataPengaturan},
  ],
}

export default Kepegawaian
