import * as Yup from 'yup'
import {ReactIconList} from '../../../helper/ReactIcon'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'name',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'heading',
    type: 'input',
    value: '',
    validator: Yup.string().required('Heading wajib diisi'),
    disabled: true,
    options: {
      input: {
        props: {
          title: 'Heading',
          placeholder: 'Heading',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'route_seo',
    type: 'input',
    value: '',
    validator: Yup.string().required('Route SEO wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Route SEO',
          placeholder: 'Route SEO',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'route',
    type: 'input',
    value: '',
    validator: Yup.string().required('Route wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Route',
          placeholder: 'Route',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'bootstrapIcon',
    type: 'datalist',
    value: 'RiLayout5Line',
    validator: Yup.string().required('Icon wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Icon',
          placeholder: 'Icon',
        },
      },
      datalist: {
        api: '',
        params: 'nama',
        filter: 'icon',
        result: 'icon=$nama $nama',
        id: 'id',
        data: [
          ...ReactIconList.map((l, i) => {
            return {
              id: l,
              nama: l,
            }
          }),
        ],
      },
    },
  },
  {
    className: 'col-12',
    name: 'sorting',
    type: 'input',
    value: '',
    validator: Yup.number().required('Sorting wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Sorting',
          placeholder: 'Sorting',
          type: 'number',
        },
      },
    },
  },
]

const formInputListSubmenu: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'sub_id',
    type: 'datalist',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Sub Menu',
          placeholder: 'Sub Menu',
        },
      },
      datalist: {
        api: '/account/menu',
        params: 'name,route_seo,route',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$name</span>
            </div>
            <div>
              <span className='fw-bold'>Route : </span>
              <span>$route</span>
            </div>
            <div>
              <span className='fw-bold'>Route SEO : </span>
              <span>$route_seo</span>
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  ...formInputList,
]

const formInputListAction: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'menu_id',
    type: 'datalist',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Menu',
          placeholder: 'Menu',
        },
      },
      datalist: {
        api: '/account/menu',
        params: 'name,route_seo,route',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$name</span>
            </div>
            <div>
              <span className='fw-bold'>Route : </span>
              <span>$route</span>
            </div>
            <div>
              <span className='fw-bold'>Route SEO : </span>
              <span>$route_seo</span>
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'name',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'route_seo',
    type: 'input',
    value: '',
    validator: Yup.string().required('Route SEO wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Route SEO',
          placeholder: 'Route SEO',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'route',
    type: 'input',
    value: '',
    validator: Yup.string().required('Route wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Route',
          placeholder: 'Route',
        },
      },
    },
  },
]

export {formInputList, formInputListAction, formInputListSubmenu}
