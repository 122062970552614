/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../../../../helper/AppUi'
import {TableAction, TableInput, TableRow} from '../../../../../../helper/TableInput'
import {
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../../../helper/AppFunction'
import FormInput from '../../../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../../helper/TableInputDetail'
import {formatDate} from '../../../../../../helper/DateFns'

const onChange = (e: any) => {
  const data = e?._data?.get
  let total = 0
  const countList = [
    {
      name: 'jumlah_jam',
    },
    {
      name: 'jumlah_lembur',
    },
  ]
  for (const l of countList) {
    total += Number(data[l.name] || 0)
  }
  const result = {...data, jumlah: total}
  e?.resetForm({values: result})
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td style={{minWidth: '50px'}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'hari_kerja'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </td>
                  <td style={{minWidth: '300px'}}>{NullProof({input: l, params: 'nomor_ref'})}</td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'uraian_hasil'})}
                  </td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Nomor Surat Tugas'}}>
            {NullProof({input: data, params: 'surat_tugas.nomor_surat'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Auditor'}}>
            <TableInputDetailColumn hideDivider title={{value: 'NIP'}}>
              {NullProof({input: data, params: 'pegawai.nip'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'pegawai.nama'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Hari Kerja'}}>
            {formatDate({
              date: NullProof({input: data, params: 'hari_kerja'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Hasil Kegiatan', props: {className: 'mb-2'}}}
          >
            <TableInputDetailColumn hideDivider title={{value: 'Nomor Referensi'}}>
              {NullProof({input: data, params: 'nomor_ref'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Uraian Hasil'}}>
              {NullProof({input: data, params: 'uraian_hasil'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Jam kerja pada hari kerja normal', props: {className: 'mb-2'}}}
          >
            <TableInputDetailColumn hideDivider title={{value: 'Jumlah'}}>
              {NullProof({input: data, params: 'jumlah_jam'})} Jam
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Paraf'}}>
              <TableInputDetailColumn hideDivider title={{value: 'NIP'}}>
                {NullProof({input: data, params: 'paraf_kerja.nip'})}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
                {NullProof({input: data, params: 'paraf_kerja.nama'})}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Lembur', props: {className: 'mb-2'}}}
          >
            <TableInputDetailColumn hideDivider title={{value: 'Jumlah'}}>
              {NullProof({input: data, params: 'jumlah_lembur'})} Hari
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Keterangan'}}>
              {NullProof({input: data, params: 'lembur_keterangan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Paraf'}}>
              <TableInputDetailColumn hideDivider title={{value: 'NIP'}}>
                {NullProof({input: data, params: 'paraf_kerja.nip'})}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
                {NullProof({input: data, params: 'paraf_kerja.nama'})}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
            {NullProof({input: data, params: 'jumlah'})}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
