import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'misi',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Misi is required'),
    options: {
      textarea: {
        props: {
          title: 'Misi',
          rows: 3,
          placeholder: 'Misi',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tujuan',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Tujuan is required'),
    options: {
      textarea: {
        props: {
          title: 'Tujuan',
          rows: 3,
          placeholder: 'Tujuan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'sasaran',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Sasaran is required'),
    options: {
      textarea: {
        props: {
          title: 'Sasaran',
          rows: 3,
          placeholder: 'Sasaran',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'strategi',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Strategi is required'),
    options: {
      textarea: {
        props: {
          title: 'Strategi',
          rows: 3,
          placeholder: 'Strategi',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'penanggung_jawab',
    type: 'input',
    value: '',
    validator: Yup.string().required('Penanggung Jawab is required'),
    options: {
      input: {
        props: {
          title: 'Penanggung Jawab',
          placeholder: 'Penanggung Jawab',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'keterangan',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Keterangan is required'),
    options: {
      textarea: {
        props: {
          title: 'Keterangan',
          rows: 3,
          placeholder: 'Keterangan',
        },
      },
    },
  },
]

export {formInputList}
