import {PageTitle} from '../../../../../_metronic/layout/core'
import {FC, Fragment, useState} from 'react'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import {useParams} from 'react-router-dom'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {CardUi} from '../../../../helper/AppUi'
import FormInput from '../../../../helper/FormInput'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Kode Resido' className='min-w-150px'>
                    {NullProof({input: l, params: 'code'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const query = useParams()
  if (query['id']) {
    formInputList[0].disabled = true
    formInputList[0].value = query['id']
  }
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data)}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            type: 'post',
            url: InfoData.api,
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.detail?.page.breadcrumb.data)}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn
            isResponsive='mobile'
            title={{value: 'Kode', props: {className: 'fs-5'}}}
          >
            {NullProof({input: data, params: 'code'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Score Inherent', props: {className: 'fs-5'}}}>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Likelihood'}}>
              {NullProof({input: data, params: 'score_inherent_likelihood'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Impact'}}>
              {NullProof({input: data, params: 'score_inherent_impact'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Level'}}>
              {NullProof({input: data, params: 'score_inherent_level'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Score Residual', props: {className: 'fs-5'}}}>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Likelihood'}}>
              {NullProof({input: data, params: 'score_residual_likelihood'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Impact'}}>
              {NullProof({input: data, params: 'score_residual_impact'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Level'}}>
              {NullProof({input: data, params: 'score_residual_level'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Score After', props: {className: 'fs-5'}}}>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Likelihood'}}>
              {NullProof({input: data, params: 'score_after_likelihood'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Impact'}}>
              {NullProof({input: data, params: 'score_after_impact'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Level'}}>
              {NullProof({input: data, params: 'score_after_level'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Existing Control', props: {className: 'fs-5'}}}>
            <TableInputDetailColumn
              isResponsive='mobile'
              hideDivider
              title={{value: 'Ada / Tidak Ada'}}
            >
              {NullProof({input: data, params: 'existing_control_adatidak'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Memadai'}}>
              {NullProof({input: data, params: 'existing_control_memadai'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Skala'}}>
              {NullProof({input: data, params: 'existing_control_skala'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Keterangan'}}>
              {NullProof({input: data, params: 'existing_control_keterangan'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Risk Threadment', props: {className: 'fs-5'}}}>
            <TableInputDetailColumn isResponsive='mobile' hideDivider title={{value: 'Opsi'}}>
              {NullProof({input: data, params: 'risk_threadment_opsi'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' hideDivider title={{value: 'Deskripsi'}}>
              {NullProof({input: data, params: 'risk_threadment_deskripsi', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <div>
                      {i + 1}. {NullProof({input: l, params: 'deskripsi'})}
                    </div>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  if (query['id']) {
    formInputList[0].disabled = true
    formInputList[0].value = query['id']
  }
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            type: 'put',
            url: RouterQueryParams(`${InfoData.api}/:subid`, query),
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:subid`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper, PdfWrapper}
