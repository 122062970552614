import {
  MainWrapper,
  PdfWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
} from '../../../../pages/evaluasi-pelaporan/verifikasi-perjalanan-dinas/form-dpr/MainWrapper'
import {DataRoutesProps} from '../../../UserRolesValidation'

const MainRoutes: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'pdf/:id',
    elements: <PdfWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
]

export {MainRoutes}
