import {
  MainWrapper,
  PdfWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
} from '../../../../pages/kebijakan-teknis/risk-register/MainWrapper'
import {RiskRegisterIdentifikasiRoutes} from './Identifikasi'
import {RiskRegisterAnalisisRoutes} from './Analisis'
import {RiskRegisterMonitoringRoutes} from './Monitoring'

import {DataRoutesProps} from '../../../UserRolesValidation'

const RiskRegisterRoutes: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'pdf/:id',
    elements: <PdfWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'identifikasi/:id',
    elements: <></>,
    subroute: RiskRegisterIdentifikasiRoutes,
  },
  {
    icon: '',
    title: '',
    route: 'analisis/:id',
    elements: <></>,
    subroute: RiskRegisterAnalisisRoutes,
  },
  {
    icon: '',
    title: '',
    route: 'monitoring/:id',
    elements: <></>,
    subroute: RiskRegisterMonitoringRoutes,
  },
]

export {RiskRegisterRoutes}
