/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../helper/AppFunction'
import {CardUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import FormInput from '../../../../helper/FormInput'
import Modal from '../../../../helper/Modal'
import ReactIcon from '../../../../helper/ReactIcon'
import {ReactBigCalendar} from '../../../../helper/ReactBigCalendar'

const dummyData = [
  {
    id: 1,
    tanggal_naskah: '2024-01-28T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    nomor_agenda: 'AGN-20240118-0001',
    hal: 'coba tanda tangan konvensional',
    asal_naskah: 'Inspektorat Wilayah IV',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Sudah',
    status_baca: 'Sudah',
    nomor_referensi: 'Tidak ada',
    isi: 'mencoba testing aplikasi tanda tangan konvensional',
    jenis_naskah: 'Nota Dinas',
    sifat_naskah: 'Biasa',
    klasifikasi: 'PS.00 - Pengawasan Administrasi Umum',
    tipe_tandatangan: 'TTE',
    status_berkas: 'Belum diberkaskan',
    tanggal_diterima: '2024-01-28T11:02:59Z',
    tanggal_dibaca: '2024-01-18T11:02:59Z',
    tanggal_dibaca_setelah: '2024-01-18T11:02:59Z',
    pegawai: {
      nama: 'Kastolan - Plt. Sekretaris Inspektorat Jenderal',
      jabatan: 'Sekretariat Inspektorat Jenderal Kementerian Agama - Kementerian Agama',
      foto: 'files/avatar/avatar_12122023204007_nemeka.jpg',
    },
    penerima: {
      utama: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
      tebusan: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
    },
  },
  {
    id: 2,
    tanggal_naskah: '2024-01-19T11:02:59Z',
    tanggal_diterima: '2024-01-21T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    nomor_agenda: 'AGN-20240118-0002',
    hal: 'agenda 2 ',
    asal_naskah: 'Inspektorat Wilayah IV',
    jenis_naskah: 'Nota Dinas',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
  },
  {
    id: 3,
    tanggal_naskah: '2024-01-29T11:02:59Z',
    tanggal_diterima: '2024-01-30T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    nomor_agenda: 'AGN-20240118-0003',
    hal: 'agenda 3',
    asal_naskah: 'Inspektorat Wilayah IV',
    jenis_naskah: 'Nota Dinas',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
  },
]

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  const params = {
    title: 'nomor_agenda',
    start: 'tanggal_naskah',
    end: 'tanggal_diterima',
    data: 'data',
  }

  console.log('data', data)
  // if(data.length > 0){
  //   const coba = filterEvent(params, data)
  //   console.log("data", coba)
  // }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title} toolbars={<ButtonModal />}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          isDummy
          staticData={dummyData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '100px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Jenis' props={{style: {width: '150px'}}}>
                    {NullProof({input: l, params: 'jenis_naskah'})}
                  </TableColumn>
                  <TableColumn label='Nomor Agenda' props={{style: {width: '150px'}}}>
                    {NullProof({input: l, params: 'nomor_agenda'})}
                  </TableColumn>
                  <TableColumn label='Nomor Naskah' props={{style: {width: '150px'}}}>
                    {NullProof({input: l, params: 'nomor_naskah'})}
                  </TableColumn>
                  <TableColumn label='Tanggal' props={{style: {width: '150px'}}}>
                    {NullProof({input: l, params: 'tanggal_naskah', type: 'date'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
      <div className='mb-5'></div>
      <CardUi title='Kalender Agenda Naskah'>
        <div className='d-flex justify-content-center'>
          <div style={{width: '100%'}}>
            <ReactBigCalendar data={data} params={params} />
          </div>
        </div>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Kode'}}>
              {NullProof({input: data, params: 'kode'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Satker'}}>
              {NullProof({input: data, params: 'nama_satker'})}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

const ButtonModal: FC = () => {
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  const query = useParams()
  return (
    <>
      <div className=' d-flex flex-row-reverse mb-3'>
        <button
          className='btn btn-primary d-flex gap-1 align-items-center'
          onClick={() => {
            setModal({
              statusUbah: true,
            })
          }}
        >
          <ReactIcon icon='RiSearchLine' props={{className: 'fs-3'}} />
          {'Cek Nomor'}
        </button>
      </div>
      <Modal
        id='modal_ceknomor'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title={'Form Cek Nomor'}
      >
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            isFeedback: true,
            type: 'put',
            onFeedback: (e: any) => {
              setModal((p: any) => ({...p, statusUbah: false}))
            },
          }}
          isModal
        />
      </Modal>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
