import {FC, useState} from 'react'
import { InfoData } from './PageData'
import {useParams} from 'react-router-dom'
import {putData} from '../../../../helper/FormAxios'
import Swal from 'sweetalert2'

const RouteList = {
  filterUsulanPegawai: '/pengawasan_internal/list-usulan-date-based',
  filterUsulanTujuanSatker: '/pengawasan_internal/list-tujuan-satker',
  filterStPegawai: '/pengawasan_internal/list-st-date-based',
  filterStTujuanSatker: '/pengawasan_internal/list-st-tujuan-satker',
}
const ButtonPembatalanST: FC<{onRefresh?: any}> = ({onRefresh}) => {
  const query = useParams()
  const TableInputData = null
  const kirimNaskah = async () => {
    try {
      const {value: accept} = await Swal.fire({
        icon: 'question',
        title: 'Apakah anda yakin untuk membatalkan surat tugas ini?',
        showCancelButton: true,
      })
      if(accept){
        const result: any = await putData({status: 'Dibatalkan'}, `${InfoData.api}/${query.id}/batalkan-st`)
        if (result?.status === true) {
          onRefresh && onRefresh()
          Swal.fire({
            icon: 'success',
            title: 'Surat Tugas berhasil dibatalkan.',
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan.',
          })
        }
      }
      
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan.',
      })
    }
  }

  return (
      <div>
        <button
          className='btn btn-danger btn-sm'
          onClick={() => kirimNaskah()}
        >
          Batalkan ST
        </button>
      </div>
  )
}

export {ButtonPembatalanST}
