/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Penugasan',
    path: '/profil/tugas',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PageData = {
  title: 'Surat Tugas dan Surat Perjalanan Dinas (SPD)',
  breadcrumb: Breadcrumbs,
}

const MainPage: FC = () => {
  const dummyData = {
    surattugas: [
      {
        id: 1,
        title: 'Surat Tugas #1',
        tanggal: '17 Juni 2023',
      },
      {
        id: 2,
        title: 'Surat Tugas #2',
        tanggal: '17 Juni 2023',
      },
      {
        id: 3,
        title: 'Surat Tugas #3',
        tanggal: '17 Juni 2023',
      },
      {
        id: 4,
        title: 'Surat Tugas #4',
        tanggal: '17 Juni 2023',
      },
      {
        id: 5,
        title: 'Surat Tugas #5',
        tanggal: '17 Juni 2023',
      },
    ],
  }
  const ListUi: FC<{data: {title: string; tanggal: string; id: number}}> = ({data}) => {
    return (
      <div className='form-control form-control-solid d-flex align-items-center justify-content-between'>
        <div>
          <div className='text-truncate text-dark fw-bold'>{data.title}</div>
          <div className='fs-7'>{data.tanggal}</div>
        </div>
        <Link
          to={`/profil/tugas/surat_tugas/${data.id}`}
          className='btn btn-sm btn-light text-dark'
        >
          Lihat
        </Link>
      </div>
    )
  }
  return (
    <>
      <div className='row g-5'>
        <div className='col-12'>
          <div className={`card card-flush h-100`}>
            <div className='card-header py-5'>
              <div className='card-title d-flex flex-row justify-content-between w-100'>
                <div>{PageData.title}</div>
                <div
                  className='d-flex'
                  style={{
                    gap: '5px',
                  }}
                >
                  {/* <button type='button' className='btn btn-primary btn-sm'>
                    Tambah
                  </button> */}
                </div>
              </div>
              <div className='card-body px-0 w-100'>
                <div>
                  <div
                    className='d-flex flex-column'
                    style={{
                      gap: '5px',
                    }}
                  >
                    {dummyData.surattugas.map((l, i) => (
                      <Fragment key={i}>
                        <ListUi data={l} />
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ProfilSuratTugasWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={PageData.breadcrumb}>{PageData.title}</PageTitle>
      <MainPage />
    </>
  )
}

export {ProfilSuratTugasWrapper}
