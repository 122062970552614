import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TableState {
  data: any;
}

const initialState: TableState = {
  data: {},
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    post: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    clear: (state) => {
      state.data = {}
    }
  },
});

export const { post, clear } = tableSlice.actions;
export default tableSlice.reducer;
