import {TableInputDataProps} from '../../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../../helper/TableInputDetail'

export const InfoData = {
  title: 'Sumber Penugasan',
  path: '/pengawasan/persiapan/data_tambahan/sumber_penugasan',
  api: '/pengawasan_internal/sumber-penugasan',
}

const BaseBreadcrumb = [
  {
    title: 'Pelaksanaan Pengawasan Internal',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Persiapan Pelaksanaan Pegawasan Internal',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Data Tambahan',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'min-w-50px',
      },
    },
    {
      title: 'Detail',
    },
  ],
  link: {
    tambah: {
      id: 'tambah',
      protect: 'tambah',
      title: 'Tambah',
      route: 'tambah',
    },
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'id',
        protect: 'ubah/:id',
        title: 'Ubah',
        route: 'ubah/:id',
      },
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: '',
      },
    ],
  },
  route: {
    url: InfoData.api,
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `Detail ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
