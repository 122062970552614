const FormikMessages = {
  REQUIRED: 'Mohon untuk diisi.',
  FORM_ERROR: 'Mohon cek kembali form anda.',
}

const FormInputMessages = {
  'DATE.PLACEHOLDER': 'Pilih Tanggal',
  'NUMBER.DAY.PLACEHOLDER': 'Hari',
  'SELECT.PLACEHOLDER_LEADING': 'Pilih',
  'SELECT.PLACEHOLDER_END': 'Data',
  'INPUT.FILE.PLACEHOLDER': 'Pilih File',
  'DATALIST.ERROR_SERVER': 'Gagal Memuat Data - Klik untuk Refresh',
  'DATALIST.MODAL.ERROR_SERVER': 'Gagal menghubungkan ke server',
  'DATALIST.MODAL.ERROR_SERVER.DESCRIPTION': 'Mohon untuk coba lagi nanti',
  'DATALIST.LOADING': 'Memuat Data',
  'DATALIST.BUTTON.REFRESH': 'Segarkan',
  'DATALIST.CHECKED.TITLE': 'Data yang terpilih',
  'DATALIST.TITLE': 'List Data',
  'INPUT.PASSWORD.SHOWPASSWORD': 'Lihat',
}

const TableInputMessages = {
  'BUTTON.ADD': 'Tambah',
  'BUTTON.SEARCH': 'Cari',
  'BUTTON.DELETE': 'Hapus',
  'BUTTON.DETAIL': 'Detail',
  'BUTTON.ACTION': 'Aksi',
  'MODAL.ACTION.TITLE': 'Pilih Tindakan',
  'BUTTON.SHOW_TOTAL_DATA': 'Jumlah Data',
}

const InfoPageMessages = {
  EDIT_PAGE: 'Ubah {page} Page',
  ADD_PAGE: 'Tambah {page} Page',
}

const i18nComponentAllMessages = {
  forminput: FormInputMessages,
  tableinput: TableInputMessages,
  formik: FormikMessages,
  infopage: InfoPageMessages,
}

export default i18nComponentAllMessages
