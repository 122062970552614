import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {InfoData, PageData, TableInputData, TableInputDetailData} from './PageData'
import {
  BreadcrumbQueryParams,
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../helper/AppFunction'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CardUi} from '../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../helper/TableInput'
import FormInput from '../../../helper/FormInput'
import {formInputList} from './InputLayout'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import {formatDate} from '../../../helper/DateFns'
import {PdfPageViewer} from '../../../helper/PdfPageViewer'
import {PdfLayoutSemua} from './PdfLayout'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='NIP' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pegawai.nip'})}
                  </TableColumn>
                  <TableColumn label='Pegawai' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </TableColumn>
                  <TableColumn label='Surat Tugas' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'no_surat_tugas'})}
                  </TableColumn>
                  <TableColumn label='MAK' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'mak'})}
                  </TableColumn>
                  <TableColumn label='Jumlah' props={{style: {minWidth: '200px'}}}>
                    {ConvertCurrency(NullProof({input: l, params: 'jumlah', isLabel: false}) || 0)}
                  </TableColumn>
                  <TableColumn label='Saldo' props={{style: {minWidth: '200px'}}}>
                    {ConvertCurrency(NullProof({input: l, params: 'saldo', isLabel: false}) || 0)}
                  </TableColumn>
                  <TableColumn label='Transfer' props={{style: {minWidth: '200px'}}}>
                    {ConvertCurrency(
                      NullProof({input: l, params: 'transfer', isLabel: false}) || 0
                    )}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tanggal'}}>
            {formatDate({
              date: NullProof({input: data, params: 'tanggal'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal Potong UM'}}>
            {formatDate({
              date: NullProof({input: data, params: 'tanggal_potong_um'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal Potong Verifikasi'}}>
            {formatDate({
              date: NullProof({input: data, params: 'tanggal_potong_verif'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'NIP'}}>
            {NullProof({input: data, params: 'pegawai.nip'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Pegawai'}}>
            {NullProof({input: data, params: 'pegawai.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Surat Tugas'}}>
            {NullProof({input: data, params: 'no_surat_tugas'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'MAK'}}>
            {NullProof({input: data, params: 'mak'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'SPM'}}>
            {NullProof({input: data, params: 'spm'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Bagian'}}>
            {NullProof({input: data, params: 'bagian'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jumlah'}}>
            {ConvertCurrency(NullProof({input: data, params: 'jumlah', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Saldo'}}>
            {ConvertCurrency(NullProof({input: data, params: 'saldo', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Transfer'}}>
            {ConvertCurrency(NullProof({input: data, params: 'transfer', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Cek'}}>
            {NullProof({input: data, params: 'cek'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Cek Billing'}}>
            {NullProof({input: data, params: 'cek_billing'})}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  let InitPdfLayout = PdfLayoutSemua
  if (query?.id !== 'semua') {
    // InfoData.api = `${InfoData.api}/:id`
    // InitPdfLayout = PdfLayout
  }
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={InitPdfLayout}
      />
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper, PdfWrapper}
