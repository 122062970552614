import {useFormik} from 'formik'
import {FC, Fragment, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {
  ConvertCurrency,
  DynamicUrl,
  ListBindDataByUrl,
  NullProof,
  PaginationStaticDataProps,
  PopupConfirm,
  RouterQueryParams,
  checkFileExtension,
  filenameGenerator,
  getPaginationStaticData,
  numberlistPagination,
} from './AppFunction'
import Swal from 'sweetalert2'
import {getData, postData, postGenerateUploadUrl, putData} from './FormAxios'
import {formatDate} from './DateFns'
import ReactDatepicker from './ReactDatepicker'
import CurrencyFormat from 'react-currency-format'
import Modal from './Modal'
import {ReactPdfViewer} from './ReactPdf'
import ReactIcon from './ReactIcon'
import Pagination from './Pagination'
import ReactDOMServer from 'react-dom/server'
import {WithChildren} from '../../_metronic/helpers'
import Accordion from './Accordion'
import {TableAction, TableInput, TableInputDataProps} from './TableInput'
import {ButtonLoading} from './AppUi'
import {Crop} from 'react-image-crop'
import ReactCropper from './ReactCropper'
import ReactTiptap from './ReactTiptap'
import {useDispatch, useSelector} from 'react-redux'
import {post as postTable, clear as clearTable} from '../../app/redux/tableSlice'
import {csvToObject, xlsxToObj} from './XlsxToCsv'

const isDebugging = process.env.REACT_APP_COMPONENT_DEBUGGING || false

const API_URL = process.env.REACT_APP_API_URL

// Function Props

type typeCallProps = 'onInit' | 'onLoad' | 'onChange' | 'onBlur' | 'onReload' | 'onClick'

interface _updateDataProps {
  fieldsToUpdate: Partial<any>
  initServerData?: any
  options?: {
    refresh?: boolean
    type?: typeCallProps
    isBlur?: boolean
  }
  id?: string
  index?: number
}

interface OptionInputProps {
  input?: {
    useImageCrop?: boolean
    dateOption?: 'date' | 'datetime' | 'time'
    isCurrency?: boolean
    isDate?: boolean
    bindInput?: {
      sourceData?: 'api' | 'total'
      id?: string
      type?: 'bind' | 'fill'
      input: string
      params: string
      result?: string[]
    }
    props?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  }
  imageCropper?: {
    aspect?: number
    shape?: 'rect' | 'round'
  }
  textarea?: {
    useTextEditor?: boolean
    props?: React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >
  }
  select?: {
    props?: React.DetailedHTMLProps<
      React.SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >
  }
  multicheckbox?: {
    rowType?: 'wrap' | 'row'
    rowClassName?: string
    colClassName?: string
    props?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  }
  currency?: {
    props?: CurrencyFormat.Props
  }
  upload?: {
    size?: number
    title?: string
    url?: {
      path?: string
      folder?: string
      filename?: string
    }
  }
  label?: {
    name?: string
    description?:
      | string
      | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    className?: string
    lineClassName?: string
    labelType?: 'label' | 'line' | 'both'
    hideLabel?: boolean
  }
  datalist?: {
    title?: string
    id: string
    api: string
    apiPut?: string
    apiInput?: string
    apiInputData?: {
      id?: string
      input?: string
      params: string
    }
    noApiDetailId?: boolean
    data?: any
    query?: string
    result?: string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    filter?: string
    params?: string
    paramValue?: string
    autoFillInput?: string
    type?: 'datalist' | 'table'
    table?: {
      protect: string
      data: TableDatalistDataProps[]
      props: TableInputDataProps
    }
    isListpicker?: boolean
    useOnLoad?: boolean
    apiSingleLoad?: boolean
    apiSingleLoadOptions?: {
      props?: {
        [key: string]: PaginationStaticDataProps
      }
    }
  }
  watchInput?: {
    name?: string
    type?: 'watch-only' | 'watch-reset'
    scope?: 'component' | 'global'
    index?: number
    id: string
  }
  parent?: {
    name?: string
    index?: number
  }
  component?: {
    isObject?: boolean
    useAccordion?: boolean
    useGuide?: boolean
    useOrderNumber?: boolean
    cloneInitData?: boolean
    resultType?: 'number' | 'date' | 'currency'
  }
  calc?: {
    type?: 'number' | 'date'
    sourceData?: 'data' | 'total'
    data: any[]
    result: string
  }
  sidebutton?: {
    title?: string
    props?: React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  }
  validator?: {
    useReconfirmDelete?: boolean
  }
}

interface TableDatalistDataProps {
  id: string
  className?: string
  type: 'text' | 'currency' | 'date' | 'map'
  params?: string
  map?: TableDatalistDataProps[]
}

type FormInputTypeProps =
  | 'input'
  | 'select'
  | 'multicheckbox'
  | 'datalist'
  | 'textarea'
  | 'label'
  | 'component'
  | 'csv'

interface InputListProps {
  className?: string
  name: string
  protect?: string[]
  type: FormInputTypeProps
  hide?: boolean
  removeOnSubmit?: boolean
  disabled?: boolean
  lockData?: boolean
  validator: any
  value?: string | number
  listData?: {
    title: string
    value: number | string
  }[]
  options?: OptionInputProps
}

interface ComponentInputProps {
  name: string
  data: FormInputProps & InputListOptionProps
  component: FormInputProps[]
}

interface InputListOptionProps {
  formName?: string
  onLoad?: (e: onLoadProps) => void
  onFeedback?: (e: any) => void
  parentName?: string
  componentIndex?: number
  route?: RouteOptionProps
  core: {
    _data: {
      post({fieldsToUpdate, options}: _updateDataProps): void
      value: any
    }
    _dataIndicator: {
      post({fieldsToUpdate, options}: _updateDataProps): void
      value: any
    }
    _apiData: {
      value: any
      post(fieldsToUpdate: Partial<any>): any
      server: any
    }
    total: {
      value: any
    }
    input: {
      value: any
    }
    formik: any
    formError: {
      value: boolean
      post(value: boolean): void
    }
    formLock: boolean
    errorMessage: any
    setError: ({field, message}: {field: string; message: string}) => void
    refreshData: {
      value: any
      post(fieldsToUpdate: Partial<any>): any
    }
    type?: typeCallProps
  }
}

export interface FormInputProps extends InputListProps {
  component?: FormInputProps[]
}

export interface FormInputValidatorProps extends FormInputProps {
  parentId?: string
  parentName?: string
  parentData?: any
  index?: number
}

export interface RouteOptionProps {
  url: string
  urlSubmit?: string
  redirect?: boolean
  useServerMessage?: boolean
  withServerData?: boolean
  type: 'post' | 'put'
  query?: string
  data?: any
  bindData?: BindDataProps[]
  isFeedback?: boolean
  resetOnFeedback?: boolean
  onFeedback?: any
  onFeedbackSuccessMessage?: string
}

export interface BaseInputOnChangeProps {
  _dataIndicator: {
    get: any
    post: any
  }
  _data: {
    getObject: any
    get: any
    post: any
  }
  _apiData: {
    get: any
    post: any
    server: any
  }
  _total: {
    get: any
    post: any
  }
  setError: ({field, message}: {field: string; message: string}) => void
  refreshInput: (fieldsToUpdate: Partial<any>) => void
  resetForm: ({values}: {values: any}) => void
  lockForm: (val: boolean) => void
  type?: typeCallProps
  clickId?: string
}

interface onLoadDatasProps {
  _dataIndicator: {
    get: any
  }
  _data: {
    getObject: any
    get: any
  }
  _apiData: {
    get: any
    server: any
  }
}

interface onLoadDataProps {
  result?: any
  query?: any
}

export interface onLoadProps {
  id: string
  initLoading: () => void
  query?: {
    get?: any
    post: (query: any) => void
  }
  data?: onLoadDatasProps
  load: ({query, result}: onLoadDataProps) => void
}

export interface onBeforeSubmitProps {
  data?: onLoadDatasProps
  submit: ({values}: {values: any}) => void
}

interface BaseInputProps {
  name?: string
  isLocked?: boolean
  isModal?: boolean
  isSubmitByBlur?: boolean
  useToast?: boolean
  input: FormInputProps[]
  route: RouteOptionProps
  isLoading?: boolean
  className?: string
  onLoad?: (e: onLoadProps) => void
  onChange?: (e: BaseInputOnChangeProps) => void
  onFeedback?: (e: any) => void
  onBeforeSubmit?: (e: onBeforeSubmitProps) => Promise<void>
  options?: {
    actionButton?: {
      submit?: {
        hide?: boolean
        title?: string
      }
      cancel?: {
        hide?: boolean
        title?: string
        confirm?: {
          enabled?: boolean
          title?: string
          success?: string
        }
      }
      align?: 'start' | 'end'
    }
  }
}

interface JsonDataBuilderProps {
  data: FormInputProps[]
  _data: any
  value: any
  total: any
  isSubmit?: boolean
}

export type BindDataProps = {
  urlParams?: string /** URL Params to variable */
  url: string /** API Data URL */
  params: string /** Pick variable data API to result variable */
  result: string /** Split each variable to input data, use :data for url, $data from api */
  bindToInput?: boolean /** Enable Bind Data to Input */
}

const errorUi: FC<InputListOptionProps & {name: string}> = ({core, name}) => {
  return (
    <>
      {((core.formError.value && core.formik.errors[name]) || core.errorMessage[name]) && (
        <div className='fv-plugins-message-container mx-2 mt-2 px-4 py-2 border border-danger rounded bg-danger bg-opacity-10'>
          <div className='fv-help-block d-flex align-items-center gap-2'>
            <ReactIcon icon='RiErrorWarningLine' props={{className: 'fs-2'}} />
            <div className='fw-semibold'>{core.errorMessage[name] || core.formik.errors[name]}</div>
          </div>
        </div>
      )}
    </>
  )
}

const getInitialValues = (props: {
  data: FormInputValidatorProps[]
  serverData?: any
  isTemp?: boolean
}) => {
  var init: any = {}
  const DropdownValue = (l: FormInputValidatorProps) => {
    const totalData: number =
      l.parentData && l.parentName
        ? l.parentData[l.parentName]?.length || l.value || 0
        : props.serverData
        ? props.serverData[l.name]?.length || l.value || 0
        : l.value || 0
    init[l.name] = totalData
    for (let index = 0; index < totalData; index++) {
      const component: FormInputValidatorProps[] = l?.component || []
      component.map((lsub: FormInputValidatorProps, isub) => {
        const name = `${l.name}_${component[isub].name}_${index + 1}`
        const childData =
          l.parentData && l.parentName
            ? l.parentData[l.parentName][index]
            : props.serverData
            ? props.serverData[l.name][index]
            : {}
        if ((component[isub].component?.length || 0) > 0) {
          DropdownValue({
            ...lsub,
            name: name,
            parentName: component[isub].name,
            parentData: props.serverData ? childData : undefined,
            parentId: l.name,
          })
        } else if (
          component[isub].type === 'input' &&
          component[isub].options?.input?.props?.type === 'file'
        ) {
          init[name] =
            props.isTemp || component[isub].options?.input?.props?.multiple
              ? childData[component[isub].name]
              : ''
        } else {
          if (
            component[isub].type === 'input' &&
            component[isub].options?.input?.props?.type === 'date'
          ) {
            init[name] =
              formatDate({
                date: childData[component[isub].name]?.toString(),
                dateFormat:
                  component[isub].options?.input?.dateOption === 'datetime'
                    ? 'yyyy-MM-dd HH:mm'
                    : component[isub].options?.input?.dateOption === 'time'
                    ? 'HH:mm'
                    : 'yyyy-MM-dd',
              }) || ''
          } else {
            init[name] =
              childData[component[isub].name] ||
              (component[isub].type === 'input' &&
              component[isub].options?.input?.props?.type === 'checkbox'
                ? false
                : lsub.value || '')
          }
        }
      })
    }
  }
  props.data.map((l) => {
    if (props.serverData) {
      if ((l.component?.length || 0) > 0) {
        DropdownValue(l)
      } else if (l.type === 'label') {
      } else if (l.type === 'select') {
        if (props.serverData[l.name]?.id) {
          init[l.name] = props.serverData[l.name]?.id || ''
        } else {
          init[l.name] = props.serverData[l.name] || ''
        }
      } else if (l.type === 'input' && l.options?.input?.props?.type === 'file') {
        init[l.name] =
          props.isTemp || l.options?.input?.props?.multiple ? props.serverData[l.name] || '' : ''
      } else {
        if (l.options?.input?.props?.type === 'date') {
          init[l.name] =
            formatDate({date: props.serverData[l.name]?.toString(), dateFormat: 'yyyy-MM-dd'}) || ''
        } else {
          init[l.name] =
            props.serverData[l.name] || (l.options?.input?.props?.type === 'checkbox' ? false : '')
        }
      }
    } else {
      if ((l.component?.length || 0) > 0) {
        DropdownValue(l)
      } else {
        if (l.options?.input?.props?.type === 'date') {
          init[l.name] =
            formatDate({date: l.value?.toString() || '', dateFormat: 'yyyy-MM-dd'}) || ''
        } else {
          init[l.name] =
            l.value || (l.options?.input?.props?.type === 'checkbox' ? false : l.value || '')
        }
      }
    }
  })
  return init
}

const uploadBuilder = async (props: {
  file: File
  folder?: string
  path?: string
  limitSize?: number
}) => {
  let result = {
    status: false,
    error: false,
    message: '',
    data: '',
  }
  try {
    // Upload Function Here
    let formData = new FormData()
    const fileSize: number = parseFloat((props.file.size / (1024 * 1024)).toFixed(1))
    if (fileSize > (props?.limitSize || 2)) {
      result.message = `Ukuran file tidak boleh lebih dari ${props?.limitSize || 2}MB`
      result.error = true
    } else {
      formData.append('files', props.file)
      formData.append('folder', props.folder || 'dokumen')
      const response: {status: boolean; data: string} = await postGenerateUploadUrl(
        formData,
        props?.path || '/upload/uploadfile'
      )
      if (response?.status) {
        result.status = true
        result.data = response.data
      }
    }
  } catch (e) {}
  return result
}

function InputListValidator(props: {data: FormInputValidatorProps[]; serverData?: any}) {
  var builder: any = {}
  const DropdownValidator = (l: FormInputValidatorProps) => {
    const totalData = props.serverData
      ? l.parentData && l.parentName
        ? l.parentData[l.parentName]?.length || 0
        : props.serverData[l.name]?.length || 0
      : 0
    builder[l.name] = l.validator
    for (let index = 0; index < totalData; index++) {
      l?.component?.map((lsub, isub) => {
        const name: string = `${l.name}_${lsub.name}_${index + 1}`
        const serverData =
          l.parentData && l.parentName
            ? l.parentData[l.parentName][index]
            : props.serverData[l.name][index]
        if ((lsub.component?.length || 0) > 0) {
          DropdownValidator({
            ...lsub,
            name: name,
            parentId: l.name,
            parentName: lsub.name,
            parentData: serverData,
          })
        } else if (lsub.type === 'input' && lsub.options?.input?.props?.type === 'file') {
          builder[name] = props.serverData
            ? serverData[lsub.name] && !lsub.options?.input?.props?.multiple
              ? Yup.string()
              : lsub.validator
            : lsub.validator
        } else {
          builder[name] = lsub.validator
        }
      })
    }
  }
  props.data.map((l) => {
    if ((l.component?.length || 0) > 0) {
      DropdownValidator(l)
    } else if (l.type === 'label') {
    } else if (l.type === 'input' && l.options?.input?.props?.type === 'file') {
      let serverData
      if (l.parentId) {
        const splitData = l.name.replaceAll(`${l.parentId}_`, '').split('_')
        try {
          serverData = props.serverData
            ? props.serverData[l.parentId][parseInt(splitData[1]) - 1][splitData[0]]
            : ''
        } catch (_) {}
      }
      builder[l.name] = props.serverData
        ? (l.parentId ? serverData : props.serverData[l.name]) && !l.options?.input?.props?.multiple
          ? Yup.string()
          : l.validator
        : l.validator
    } else {
      builder[l.name] = l.validator
    }
  })
  return builder
}

const InputList: FC<InputListProps & InputListOptionProps> = ({
  formName,
  name,
  parentName,
  componentIndex,
  className,
  hide,
  removeOnSubmit,
  lockData,
  type,
  route,
  disabled,
  options,
  listData,
  validator,
  onFeedback,
  onLoad,
  core,
}) => {
  const queryUrl = useParams()
  const formikProps = core.formik.getFieldProps(name)
  const [defaultValue, setDefaultValue] = useState<any>()
  const [inputKey, setInputKey] = useState<number>(0)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [fileListUpload, setFileListUpload] = useState<{
    files: {file: File; name: string; status: 'queue' | 'progress' | 'success' | 'error'}[]
    uploading: boolean
    queue: number
  }>({files: [], queue: 0, uploading: false})
  const [inputError, setInputError] = useState(false)
  const [modal, setModal] = useState<any>({
    status: false,
  })
  const [modalCropper, setModalCropper] = useState<{status?: boolean; file?: File; crop?: Crop}>()
  const [tableDatalist, setTableDatalist] = useState<any>([])
  const [isMouseStatusTable, setMouseStatusTable] = useState<'up' | 'down' | 'drag'>('up')
  const handleMouseStatusTable = ({
    e,
    type,
  }: {
    e: React.MouseEvent<HTMLDivElement>
    type: 'up' | 'down' | 'move'
  }) => {
    if (type === 'move') {
      isMouseStatusTable === 'down' && setMouseStatusTable('drag')
    } else if (type === 'up') {
      setTimeout(() => {
        setMouseStatusTable('up')
      }, 1)
    } else if (type === 'down') {
      setMouseStatusTable('down')
    }
  }
  const [modalForm, setModalForm] = useState<any>({
    status: false,
  })
  const [modalInputDatalist, setModalInputDatalist] = useState<any>([])
  const modalConfigInputDatalist = {
    data: modalInputDatalist,
    post: setModalInputDatalist,
  }
  const [uploadData, setUploadData] = useState({
    status: false,
    title: '',
  })
  const [datalistKey, setDatalistKey] = useState<number>(0)
  const [datalist, setDataList] = useState<any>({
    search: '',
    title: '',
    data: [],
    error: false,
    loading: true,
    total: 0,
    totalData: 0,
    currentPage: 1,
    totalPages: 1,
  })
  const [datalistSingleData, setDatalistSingleData] = useState<any[]>([])
  const [datalistCache, setDatalistCache] = useState<any[]>([])
  const [datalistQ, setDataListQ] = useState<any>({
    page: 1,
    search: '',
    size: 10,
  })
  const updateDatalistCache = ({
    id = 'id',
    data,
    value,
  }: {
    id?: string
    data?: any
    value?: any
  }) => {
    if (value) {
      let dataCollection = [...datalistCache]
      let valueCollection = [...NullProof({input: formikProps, params: 'value', isMap: true})]
      let index = valueCollection.findIndex((e) => e[id] === value)
      if (index !== -1) {
        dataCollection = dataCollection.filter(
          (l) => NullProof({input: l, params: `data.${id}`}) !== value
        )
        valueCollection.splice(index, 1)
      } else {
        dataCollection = [...dataCollection, {status: 'done', data: data, value: value}]
        valueCollection = [...valueCollection, {[id]: value}]
      }
      setDatalistCache(dataCollection)
      core.formik.setFieldValue(name, valueCollection)
      core._data.post({
        fieldsToUpdate: {
          [name]: valueCollection,
        },
        index: componentIndex,
      })
    }
  }
  useEffect(() => {
    if (options?.datalist?.isListpicker && datalistCache.length > 0) {
      let _id = options?.datalist?.id || ''
      let dataCollection = [...datalistCache]
      const getRequest = async (id: any, index: number) => {
        const api = RouterQueryParams(
          queryBuilder({
            api:
              route?.type === 'put'
                ? options?.datalist?.apiPut || options?.datalist?.api
                : options?.datalist?.api,
            type: 'api',
          })[0],
          queryUrl
        )
        if (id) {
          try {
            const request = await getData('', `${api}/${id}` || '')
            if (request?.status) {
              dataCollection[index] = {
                ...dataCollection[index],
                data: request?.data,
                status: 'done',
              }
              setDatalistCache(dataCollection)
            }
          } catch (error) {
            dataCollection[index] = {
              ...dataCollection[index],
              status: 'error',
            }
            setDatalistCache(dataCollection)
          }
        } else {
          dataCollection.splice(index, 1)
          setDatalistCache(dataCollection)
        }
      }
      dataCollection?.map((l: any, i: number) => {
        if (l?.status === 'pending' && !(Object.keys(l?.data || {}).length > 0)) {
          getRequest(l?.value[_id], i)
        }
      })
    }
  }, [datalistCache])
  const updateDataList = (fieldsToUpdate: Partial<any>, data: any, post: any): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    post((p: any) => ({...p, ...updatedData}))
  }
  const dataListTitle = (data: any) => {
    let dlTitle: any = ReactDOMServer.renderToString(<>{options?.datalist?.result}</>)
    const dlProps: any[] = options?.datalist?.params?.split(',') || []
    const filter: any[] = options?.datalist?.filter?.split(',') || []
    dlProps.forEach((l) => {
      const attr: string[] = l.split('.')
      let result: any = data
      let isNull = false
      for (const attrs of attr) {
        if (result == null) {
          isNull = true
          break
        }
        result = result[attrs]
      }
      filter.forEach((lf) => {
        if (lf === 'formatDate') {
          dlTitle = dlTitle?.replaceAll(
            `${lf}=$${l}`,
            isNull || result == null ? '-' : formatDate({date: result, dateFormat: 'dd MMMM yyyy'})
          )
        } else if (lf === 'currency') {
          dlTitle = dlTitle?.replaceAll(
            `${lf}=$${l}`,
            isNull || result == null ? '-' : ConvertCurrency(result)
          )
        } else if (lf === 'icon') {
          dlTitle = dlTitle?.replaceAll(
            `${lf}=$${l}`,
            isNull || result == null
              ? '-'
              : ReactDOMServer.renderToString(
                  <ReactIcon icon={result} props={{className: 'fs-1'}} />
                )
          )
        }
      })
      if (isNull || result == null) {
        dlTitle = dlTitle?.replaceAll(`$${l}`, '-')
      } else {
        dlTitle = dlTitle?.replaceAll(`$${l}`, result)
      }
    })
    return dlTitle
  }
  useEffect(() => {
    if (!formikProps.value) {
      setDataList((p: any) => ({...p, title: ''}))
    }
  }, [formikProps.value])
  const checkWatchProps = () => {
    let pass = true
    try {
      const watchId = options?.watchInput?.id.split(',') || []
      watchId?.forEach((l) => {
        const watchData =
          core._data.value[
            options?.watchInput?.scope === 'global'
              ? `${l}`
              : `${parentName ? `${parentName}_` : ''}${l}${
                  componentIndex ? `_${componentIndex}` : ''
                }`
          ]
        if (!watchData) {
          pass = false
        }
      })
    } catch (_) {
      pass = false
    }
    return pass
  }
  const queryBuilder = ({api, type = 'query'}: {api?: string; type?: 'api' | 'query'}) => {
    let _api: string = api || ''
    let query: any[] = []
    Object.keys(datalistQ).map((l) => {
      const result = `${l}=${datalistQ[l]}`
      datalistQ[l] && query.push(result)
    })
    const watchId = options?.watchInput?.id.split(',') || []
    let watchQuery = options?.datalist?.query || ''
    watchId?.forEach((l) => {
      const targetName =
        options?.watchInput?.scope === 'global'
          ? `${l}`
          : `${parentName ? `${parentName}_` : ''}${l}${componentIndex ? `_${componentIndex}` : ''}`
      const watchData = core._data.value[targetName]
      watchQuery = watchQuery.replaceAll(`$${l}`, watchData)
      _api = _api.replaceAll(`$${l}`, watchData)
    })
    if (watchId.length > 0 || watchQuery) {
      query.push(...watchQuery.split(','))
    }
    if (type === 'api') {
      return [_api]
    } else {
      return query
    }
  }
  const queryObjectBuilder = () => {
    let result: any = {}
    const query: any[] = [...queryBuilder({})]
    for (const l of query) {
      let dataQuery = l?.split('=')
      result[dataQuery[0]] = dataQuery[1]
    }
    return result
  }
  const onPostQuery = (query: any) => {
    let arrQuery: string[] = []
    let baseQuery = {...query}
    delete baseQuery?.filter
    for (const l of Object.keys(baseQuery)) {
      if (baseQuery[l]) {
        arrQuery.push(`${l}=${baseQuery[l]}`)
      }
    }
    return arrQuery.join('&')
  }
  const returnDataBuilder = async () => {
    return {
      _data: {
        get: core._data.value,
        getObject: await JsonDataBuilder({
          data: core.input.value,
          _data: core._data.value,
          total: core.total.value,
          value: core._data.value,
        }),
      },
      _dataIndicator: {
        get: core._dataIndicator.value,
      },
      _apiData: {
        get: core._apiData.value,
        server: core._apiData.server,
      },
    }
  }
  const loadDataList = async () => {
    setTimeout(() => {
      document.getElementById(`${name}_search`)?.focus()
    }, 1)
    if (options?.datalist?.useOnLoad && onLoad) {
      const onLoadData = ({query, result}: onLoadDataProps) => {
        if (result?.status) {
          let _result: any[] = []
          if (options?.datalist?.paramValue) {
            _result = NullProof({input: result?.data, params: options.datalist.paramValue})
          } else {
            _result = result?.data
          }
          core._apiData.post({
            [name]: _result,
          })
          updateDataList(
            {
              search: datalistQ.search,
              data: _result,
              totalData: result.totalItems ?? _result.length,
              currentPage: result.currentPage ?? 1,
              totalPages: result.totalPages ?? 1,
              total: _result.length,
              loading: false,
              error: false,
            },
            datalist,
            setDataList
          )
        } else {
          updateDataList(
            {
              loading: false,
              error: true,
            },
            datalist,
            setDataList
          )
        }
        updateDataList(
          {
            loading: false,
          },
          datalist,
          setDataList
        )
      }
      onLoad({
        id: name,
        data: await returnDataBuilder(),
        query: {
          get: queryObjectBuilder(),
          post: onPostQuery,
        },
        initLoading: () => {
          updateDataList(
            {
              loading: true,
              error: false,
            },
            datalist,
            setDataList
          )
        },
        load: onLoadData,
      })
    } else {
      updateDataList(
        {
          loading: true,
          error: false,
        },
        datalist,
        setDataList
      )
      let query: any[] = queryBuilder({})
      let result: any = {}
      const api = queryBuilder({api: options?.datalist?.api, type: 'api'})[0]
      if (options?.datalist?.api) {
        if (
          (options.datalist.apiSingleLoad && !(datalistSingleData.length > 0)) ||
          !options.datalist.apiSingleLoad
        ) {
          result = await getData(query.join('&'), RouterQueryParams(api, queryUrl) || '')
          options.datalist.apiSingleLoad && setDatalistSingleData(result?.data || [])
        }
        if (options.datalist.apiSingleLoad) {
          result = getPaginationStaticData({
            data: datalistSingleData.length > 0 ? datalistSingleData : result?.data || [],
            size: Number(datalistQ?.size || 10),
            page: datalistQ.page,
            search: {
              value: datalistQ.search,
              options: options?.datalist?.apiSingleLoadOptions?.props,
            },
          })
        }
      } else {
        const name = `${parentName ? `${parentName}_` : ``}${
          options?.datalist?.apiInputData?.input
        }${componentIndex ? `_${componentIndex}` : ``}`
        const apiInput = options?.datalist?.apiInput ? core._data.value[name] : null
        let apiData = options?.datalist?.apiInputData ? core._apiData.value[name] : null
        apiData = apiData?.filter(
          (fl: any) =>
            fl[options?.datalist?.apiInputData?.id || 'id'].toString() ===
            core._data.value[name].toString()
        )[0]
        apiData = NullProof({
          input: apiData,
          params: options?.datalist?.apiInputData?.params || '',
          isMap: true,
        })
        const sourceData = options?.datalist?.data || apiInput || apiData || []
        const resultData = datalistQ.search
          ? sourceData?.filter((l: any) => {
              for (const ls of Object.keys(l)) {
                return l[ls].toLowerCase().includes(datalistQ.search.toLowerCase())
              }
            })
          : sourceData || []
        result = {
          status: true,
          data: resultData?.slice(((datalistQ.page || 1) - 1) * 10, (datalistQ.page || 1) * 10),
          currentPage: datalistQ.page,
          totalPages: Math.ceil((resultData?.length || 0) / 10),
          totalData: resultData?.length || 0,
          total: resultData?.length || 0,
        }
      }
      if (result.status) {
        let _result: any[] = []
        if (options?.datalist?.paramValue) {
          _result = NullProof({input: result?.data, params: options.datalist.paramValue})
        } else {
          _result = result?.data
        }
        core._apiData.post({
          [name]: _result,
        })
        updateDataList(
          {
            search: datalistQ.search,
            data: _result,
            totalData: result.totalItems ?? _result.length,
            currentPage: result.currentPage ?? 1,
            totalPages: result.totalPages ?? 1,
            total: _result.length,
            loading: false,
            error: false,
          },
          datalist,
          setDataList
        )
      } else {
        updateDataList(
          {
            loading: false,
            error: true,
          },
          datalist,
          setDataList
        )
      }
      updateDataList(
        {
          loading: false,
        },
        datalist,
        setDataList
      )
    }
  }
  const initDatalist = async (type: typeCallProps = 'onLoad') => {
    if (!options?.datalist?.isListpicker) {
      setInputError(false)
      setLoading(true)
      let query: any[] = queryBuilder({})
      const value = formikProps?.value || core._data.value[name]
      if (value && options?.datalist?.api) {
        const api = RouterQueryParams(
          queryBuilder({api: options?.datalist?.api, type: 'api'})[0],
          queryUrl
        )
        let result: any = {status: false, data: {}}
        let detail: any = {status: false, data: {}}
        //
        try {
          result = await getData(query.join('&'), `${api}` || '')
          if (options?.datalist?.noApiDetailId && result?.status) {
            detail = {...result}
          } else {
            detail = await getData('', `${api}/${value}` || '')
          }
        } catch (_) {}
        if (detail?.status) {
          let sourceDetailData = {}
          if (options?.datalist?.noApiDetailId) {
            const searchDetail: any[] = detail?.data?.filter(
              (l: any) => l[options.datalist?.id || ''] === value
            )
            sourceDetailData = searchDetail.length > 0 ? searchDetail[0] : {}
          } else {
            sourceDetailData = detail?.data
          }
          let title: any = {}
          title = sourceDetailData
          core._apiData.post({
            [`${name}_detail`]: sourceDetailData,
          })
          updateDataList(
            {
              title: dataListTitle(title),
            },
            datalist,
            setDataList
          )
        } else {
          if (result?.status) {
            const filteredData = result?.data?.filter(
              (l: any) => l[options.datalist?.id || 'id'] === value
            )
            if (filteredData?.length > 0) {
              let title: any = {}
              title = filteredData[0]
              core._apiData.post({
                [`${name}_detail`]: filteredData[0],
              })
              updateDataList(
                {
                  title: dataListTitle(title),
                },
                datalist,
                setDataList
              )
            }
          } else {
            setInputError(true)
          }
        }
        if (result?.status) {
          core._apiData.post({
            [name]: result?.data,
          })
        }
      } else if (value && !options?.datalist?.api) {
        const sourceData = options?.datalist?.data || []
        const resultId = options?.datalist?.id || ''
        const result = sourceData?.filter((l: any) => l[resultId] === value)[0]
        updateDataList(
          {
            title: sourceData?.length > 0 ? dataListTitle(result) : value,
          },
          datalist,
          setDataList
        )
      }
      core._data.post({fieldsToUpdate: {}, options: {type: type}})
    } else {
      if (formikProps.value) {
        let _datalistCache = []
        for (const l of NullProof({input: formikProps, params: 'value', isMap: true})) {
          _datalistCache.push({status: 'pending', data: {}, value: l})
        }
        setDatalistCache(_datalistCache)
      }
    }
    setLoading(false)
  }
  useEffect(() => {
    if (type === 'datalist') {
      initDatalist()
    } else {
      setInputError(false)
      setLoading(false)
    }
  }, [formikProps?.value])
  useEffect(() => {
    const result = {...core.refreshData.value}
    if (core.refreshData.value[name]) {
      initDatalist('onReload')
      delete result[name]
      core.refreshData.post(result)
    }
  }, [core.refreshData.value])
  const toggleModal = (fieldsToUpdate: Partial<any>) => {
    const updatedData = Object.assign(modal, fieldsToUpdate)
    setModal((p: any) => ({...p, ...updatedData}))
  }
  const toggleModalForm = (fieldsToUpdate: Partial<any>) => {
    const updatedData = Object.assign(modalForm, fieldsToUpdate)
    setModalForm((p: any) => ({...p, ...updatedData}))
  }
  const checkUploadFileType = (url: string) => {
    let type: string = 'image'
    if (url?.indexOf('.pdf') > -1) {
      type = 'pdf'
    }
    return type
  }
  const addQueueUpload = async (files: FileList) => {
    const setup: any[] = []
    for (const l of files) {
      setup.push({
        file: l,
        name: l.name,
        status: 'queue',
      })
    }
    setFileListUpload((p) => ({...p, files: [...p.files, ...setup]}))
  }
  useEffect(() => {
    if (options?.input?.props?.type == 'file') {
      let queueData = {...fileListUpload}
      let queueFiles = queueData.files
      const startUpload = async () => {
        setLoading(true)
        const currentQueue = queueFiles[queueData.queue]
        const file = currentQueue.file
        const result = await uploadBuilder({
          file: file,
          folder: options?.upload?.url?.folder,
          path: options?.upload?.url?.path,
          limitSize: options?.upload?.size,
        })
        if (result?.status) {
          currentQueue.status = 'success'
          let dataQueue
          if (options?.input?.props?.multiple) {
            dataQueue = [
              ...NullProof({input: formikProps, params: 'value', isMap: true}),
              {name: currentQueue.name, file: result.data},
            ]
            core.formik.setFieldValue(name, dataQueue)
            core._data.post({
              fieldsToUpdate: {
                [name]: dataQueue,
              },
              index: componentIndex,
            })
          } else {
            core.formik.setFieldValue(name, result)
            core._data.post({
              fieldsToUpdate: {
                [name]: result,
              },
              index: componentIndex,
            })
          }
          queueFiles.splice(queueData.queue, 1)
        } else {
          currentQueue.status = 'error'
        }
        queueData.uploading = false
        setFileListUpload(queueData)
      }
      const startEntries = () => {
        for (const [i, l] of queueFiles.entries()) {
          if (l?.status === 'queue') {
            l.status = 'progress'
            queueData.queue = i
            queueData.uploading = true
            break
          }
        }
        setFileListUpload(queueData)
      }
      if (!queueData.uploading) {
        startEntries()
      } else {
        startUpload()
      }
      if (queueFiles.every((p) => p.status === 'success')) {
        setLoading(false)
      }
    }
  }, [fileListUpload.files, fileListUpload.uploading])
  const uploadProgress = async (file: File) => {
    setLoading(true)
    const result = await uploadBuilder({
      file: file,
      folder: options?.upload?.url?.folder,
      path: options?.upload?.url?.path,
      limitSize: options?.upload?.size,
    })
    if (result?.status) {
      setUploadData({
        status: true,
        title: file.name,
      })
      core._data.post({
        fieldsToUpdate: {
          [name]: result.data,
        },
        index: componentIndex,
      })
      core.formik.setFieldValue(name, file.name)
    }
    core.setError({field: name, message: result.message})
    setLoading(false)
  }
  const titleUi = (props: any) => {
    const isRequired = validator?.tests?.some((e: any) => e?.OPTIONS?.name === 'required')
    const _props = props || options?.input
    return (
      <>
        {!options?.label?.hideLabel && _props?.props?.title && (
          <div className='mb-2 d-flex gap-1'>
            <div>{`${_props?.props?.title}`}</div>
            <div className='text-danger fw-bold fs-4'>{`${isRequired ? '*' : ''}`}</div>
          </div>
        )}
      </>
    )
  }
  const noteUi = (props: any) => {
    const _props = props || {}
    return (
      <>
        {(_props?.props?.title ||
          _props?.props?.description ||
          _props?.title ||
          _props?.description) && (
          <div className='my-2'>
            {_props?.props?.title ||
              _props?.props?.description ||
              _props?.title ||
              _props?.description}
          </div>
        )}
      </>
    )
  }
  const baseInputProps = (props: any) => {
    let _props = {
      ...props,
      title: props?.title || options?.input?.props?.title,
      placeholder:
        props?.placeholder ||
        props?.title ||
        options?.input?.props?.placeholder ||
        options?.input?.props?.title,
      disabled: props?.disabled || !checkWatchProps() || disabled,
    }
    if (!props) {
      _props = {
        ..._props,
        title: options?.input?.props?.title,
        placeholder: options?.input?.props?.placeholder,
        disabled: options?.input?.props?.disabled || !checkWatchProps() || disabled,
      }
    }
    return _props
  }
  const debugUi = () => {
    return (
      <>
        {isDebugging && (
          <>
            <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
              <code>Name: {JSON.stringify(name)}</code>
            </pre>
            <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
              <code>Data: {JSON.stringify(core._data.value[name])}</code>
            </pre>
            <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
              <code>Formik: {JSON.stringify(formikProps.value)}</code>
            </pre>
          </>
        )}
      </>
    )
  }
  const updateBindInput = () => {
    const input: FormInputProps[] = core.input.value
    const DropdownInput = (l: FormInputValidatorProps) => {
      if (l.options?.input?.bindInput) {
        const targetSource = l.options.input.bindInput.sourceData
        const targetApi = l.options?.input?.bindInput.input
        const targetName = l?.parentId ? `${l?.parentId}_${targetApi}_${l.index}` : targetApi
        if (targetName === name) {
          if (targetSource === 'total') {
            if (core.total.value[targetName]) {
              let dataApi = core.total.value[targetName]
              core._data.post({
                fieldsToUpdate: {[l.name]: dataApi},
                options: {
                  refresh: false,
                },
                index: componentIndex,
              })
              core.formik.setFieldValue(l.name, dataApi)
            }
          } else {
            if (core._apiData.value[targetApi]) {
              try {
                const dataInputApi = core._apiData.value[targetApi]?.filter(
                  (ls: any) =>
                    ls[l.options?.input?.bindInput?.id || 'id'] === core._data.value[targetName]
                )[0]
                const paramsList = l.options.input.bindInput.params?.split(',')
                let paramsIndex = 0
                for (const params of paramsList) {
                  let dataApi = dataInputApi[params] || ''
                  if (!dataApi) {
                    paramsIndex++
                    continue
                  }
                  if (l.options?.input.bindInput.result) {
                    dataApi = l.options?.input.bindInput.result[paramsIndex]?.replaceAll(
                      `$${params}`,
                      dataApi
                    )
                  }
                  core._data.post({
                    fieldsToUpdate: {[l.name]: dataApi},
                    options: {
                      refresh: false,
                    },
                    index: componentIndex,
                  })
                  core.formik.setFieldValue(l.name, dataApi)
                }
              } catch (_) {}
            }
          }
        }
      }
      if ((l.component?.length || 0) > 0) {
        l.component?.map((lsub, isub) => {
          const name = `${l.name}_${lsub.name}_${isub + 1}`
          DropdownInput({
            ...lsub,
            name: name,
            parentName: lsub.name,
            parentId: l.name,
            index: isub,
          })
        })
      }
    }
    input.map((l, i) => {
      DropdownInput(l)
    })
  }
  if (hide) {
    return <></>
  }
  const InputSidebutton = ({children}: WithChildren) => (
    <>
      {!options?.sidebutton?.title ? (
        <>{children}</>
      ) : (
        <>
          <div className='mx-0 row g-2'>
            <div className='col'>{children}</div>
            <div className='col-auto'>
              <ButtonLoading
                icon='RiInformationLine'
                title={{
                  button: options?.sidebutton?.title || 'Button',
                }}
                props={{
                  onClick: () => {
                    core._data.post({
                      fieldsToUpdate: {},
                      options: {refresh: true, type: 'onClick'},
                      id: name,
                      index: componentIndex,
                    })
                  },
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
  let DatalistCacheUi = () => (
    <>
      {options?.datalist?.isListpicker && datalistCache.length > 0 && (
        <div className='border rounded p-4 mb-2'>
          <div className='d-flex justify-content-between align-items-center gap-2 mb-2'>
            <div className='fw-bold fs-4'>Data yang terpilih</div>
            <button
              type='button'
              className='btn btn-danger'
              onClick={async () => {
                if (
                  await PopupConfirm({
                    validated: options?.validator?.useReconfirmDelete,
                    hasConfirm: false,
                  })
                ) {
                  setDatalistCache([])
                  core.formik.setFieldValue(name, [])
                }
              }}
            >
              Hapus Semua
            </button>
          </div>
          <div className='overflow-y-auto' style={{maxHeight: '200px'}}>
            <div className='d-flex flex-wrap gap-4'>
              {datalistCache?.map((l: any, i: number) => (
                <Fragment key={i}>
                  <div className='bg-light py-2 px-4 border rounded d-flex gap-4 align-items-center'>
                    {Object.keys(l?.data || {}).length > 0 ? (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: dataListTitle(l?.data || {}) || '',
                          }}
                        ></div>
                      </>
                    ) : (
                      <div className='d-flex gap-2 align-items-center'>
                        {NullProof({input: l, params: 'status'}) === 'pending' && (
                          <>
                            <div>Memuat data...</div>
                            <div className='spinner-border spinner-border-sm' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </div>
                          </>
                        )}
                        {NullProof({input: l, params: 'status'}) === 'error' && (
                          <>
                            <div>Gagal memuat data</div>
                            <div
                              className='fs-2 cursor-pointer'
                              onClick={() => {
                                const result = [...datalistCache]
                                const target = result[i]
                                target.status = 'pending'
                                setDatalistCache((p) => [...result])
                              }}
                            >
                              <ReactIcon icon='RiRestartLine' />
                            </div>
                            <div
                              className='fs-2 cursor-pointer text-danger'
                              onClick={() => {
                                updateDatalistCache({
                                  id: options?.datalist?.id,
                                  data: {},
                                  value: NullProof({
                                    input: l,
                                    params: `data.${options?.datalist?.id}`,
                                    isLabel: true,
                                  }),
                                })
                              }}
                            >
                              <ReactIcon icon='RiDeleteBinLine' />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
  const titleUiData =
    type === 'input'
      ? options?.input
      : type === 'textarea'
      ? options?.textarea
      : type === 'select'
      ? options?.select
      : type === 'label'
      ? options?.label
      : options?.input
  const _labelType = options?.label?.labelType || 'label'
  return (
    <div className={`${className || 'col-12'} ${hide ? 'd-none' : ''}`}>
      {type !== 'label' && titleUi(titleUiData)}
      {type === 'datalist' && (
        <>
          <Modal
            id={`${formName ? `${formName}_` : ``}form_${name}`}
            title={modalForm?.title || 'Tambah Data'}
            isShow={modalForm.status}
            onClose={() => {
              toggleModalForm({status: false})
            }}
          >
            <FormInput
              isModal
              input={modalForm?.input}
              route={{
                url:
                  `${options?.datalist?.api}${
                    modalForm?.id === 'ubah' ? `/${modalForm?.data?.id}` : ''
                  }` || '',
                type: modalForm?.type,
                redirect: false,
                onFeedback: async (val: any) => {
                  if (val?.status) {
                    if (options?.datalist?.type !== 'table') {
                      await loadDataList()
                    } else {
                      setDatalistKey((p) => p + 1)
                    }
                  }
                  toggleModalForm({status: false})
                },
              }}
            ></FormInput>
          </Modal>
          <Modal
            id={`${formName ? `${formName}_` : ``}${name}`}
            title={`Pilih ${
              options?.datalist?.title ||
              options?.input?.props?.title ||
              options?.input?.props?.placeholder ||
              'List Data'
            }`}
            isShow={modal.status}
            onClose={() => {
              toggleModal({status: false})
            }}
          >
            {<DatalistCacheUi />}
            <div className='d-flex flex-column gap-2'>
              {options?.datalist?.type === 'table' ? (
                <Fragment key={datalistKey}>
                  <TableInput
                    isModal
                    protect={options?.datalist?.table?.protect}
                    modal={modalConfigInputDatalist}
                    filter={options?.datalist?.table?.props.filter}
                    onSuccess={(val: any) => setTableDatalist(val)}
                    route={{
                      url:
                        RouterQueryParams(
                          queryBuilder({
                            api:
                              options?.datalist?.table?.props?.route?.url || options?.datalist?.api,
                            type: 'api',
                          })[0],
                          queryUrl
                        ) || '',
                      query: options?.datalist?.table?.props?.route?.query,
                      singleLoad:
                        options?.datalist?.apiSingleLoad ||
                        options?.datalist?.table?.props?.route?.singleLoad,
                    }}
                    link={{
                      tambah: options?.datalist?.table?.props.link?.tambah,
                      action: options?.datalist?.table?.props.link?.action || [],
                    }}
                    onLoad={
                      options?.datalist?.useOnLoad && onLoad
                        ? async (e) => {
                            onLoad({
                              id: name,
                              data: await returnDataBuilder(),
                              query: e.query,
                              initLoading: e.initLoading,
                              load: e.load,
                            })
                          }
                        : undefined
                    }
                    onFeedback={async (val: any) => {
                      const idFeedback = val?.id
                      if (idFeedback === 'tambah' || idFeedback === 'ubah') {
                        toggleModalForm({
                          status: true,
                          title: idFeedback === 'tambah' ? 'Tambah Data' : 'Ubah Data',
                          type: idFeedback === 'tambah' ? 'post' : 'put',
                          id: idFeedback,
                          data: val?.data,
                          input: val?.input,
                        })
                      } else if (idFeedback === 'detail') {
                        onFeedback &&
                          onFeedback({
                            type: 'table',
                            data: val,
                          })
                      } else {
                        setDatalistKey((p) => p + 1)
                      }
                      setModalInputDatalist((p: any) => ({...p, status: false}))
                    }}
                    headerData={options?.datalist?.table?.props.headerData}
                    modeResponsive='table'
                  >
                    {tableDatalist?.data?.map((l: any, i: number) => {
                      const tableDataBuilder = {
                        modal: modalConfigInputDatalist,
                        input: {data: l, index: i},
                        action: options?.datalist?.table?.props.link?.action || [],
                        route: options?.datalist?.table?.protect,
                      }
                      const _id: string = options?.datalist?.id || ''
                      const selectData = () => {
                        if (isMouseStatusTable === 'down') {
                          if (!options?.datalist?.isListpicker) {
                            updateDataList(
                              {
                                title: dataListTitle(l),
                              },
                              datalist,
                              setDataList
                            )
                            core._apiData.post({
                              [`${name}_detail`]: l,
                            })
                            core._data.post({
                              fieldsToUpdate: {
                                [name]: l[_id],
                              },
                              index: componentIndex,
                            })
                            core.formik.setFieldValue(name, l[_id])
                            const autoFillList = options?.datalist?.autoFillInput?.split(',') || []
                            for (const lsub of autoFillList) {
                              const lValue = lsub.split('=')
                              core.formik.setFieldValue(lValue[0], l[lValue[1]] || '')
                            }
                            updateBindInput()
                            toggleModal({status: false})
                          } else {
                            updateDatalistCache({data: l, value: l[_id], id: _id})
                          }
                        }
                      }
                      return (
                        <Fragment key={i}>
                          <tr
                            onMouseDown={(e) => handleMouseStatusTable({e, type: 'down'})}
                            onMouseMove={(e) => handleMouseStatusTable({e, type: 'move'})}
                            onMouseUp={(e) => handleMouseStatusTable({e, type: 'up'})}
                            onMouseLeave={(e) => handleMouseStatusTable({e, type: 'up'})}
                            className={`cursor-pointer ${
                              options?.datalist?.isListpicker &&
                              NullProof({
                                input: formikProps,
                                params: 'value',
                                isMap: true,
                              })?.some((e: any) => e[_id] === l[_id])
                                ? 'table-primary'
                                : ''
                            }`}
                          >
                            <td className='min-w-50px' onClick={selectData}>
                              {numberlistPagination({
                                n: i,
                                p: tableDatalist?.page,
                                t: tableDatalist?.size,
                              })}
                            </td>
                            {options?.datalist?.table?.data.map((ltd, itd) => {
                              return (
                                <Fragment key={itd}>
                                  <td className={`${ltd.className}`} onClick={selectData}>
                                    {ltd.type !== 'map'
                                      ? NullProof({input: l, params: ltd.id, type: ltd.type})
                                      : NullProof({input: l, params: ltd.id, isMap: true})?.map(
                                          (ldt: any, idt: number) => {
                                            const idParams = ltd?.map || []
                                            return (
                                              <Fragment key={idt}>
                                                <div>
                                                  {idt + 1}.{' '}
                                                  {NullProof({
                                                    input: ldt,
                                                    params: idParams[idt].id || '',
                                                    type: ltd.type,
                                                  })}
                                                </div>
                                              </Fragment>
                                            )
                                          }
                                        )}
                                  </td>
                                </Fragment>
                              )
                            })}
                            <TableAction {...tableDataBuilder} />
                          </tr>
                        </Fragment>
                      )
                    })}
                  </TableInput>
                  {options?.datalist?.isListpicker && (
                    <div className='d-flex justify-content-end gap-2'>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => toggleModal({status: false})}
                      >
                        Simpan
                      </button>
                    </div>
                  )}
                </Fragment>
              ) : (
                <>
                  <div className='input-group input-group-solid mb-2'>
                    <input
                      id={`${formName ? `${formName}_` : ``}${name}_search`}
                      disabled={datalist?.error}
                      type='text'
                      className='form-control'
                      placeholder='Cari...'
                      value={datalistQ?.search}
                      onChange={(e) => {
                        updateDataList({search: e.target.value}, datalistQ, setDataListQ)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          loadDataList()
                        }
                      }}
                    />
                    <button
                      disabled={datalist?.error}
                      type='button'
                      className={`btn ${
                        (datalistQ?.search === datalist?.search && datalist?.search?.length > 0) ||
                        (!datalistQ?.search && datalist?.search?.length > 0)
                          ? 'btn-danger'
                          : 'btn-primary'
                      } d-flex align-items-center gap-1`}
                      onClick={() => {
                        if (
                          datalistQ?.search === datalist?.search &&
                          datalist?.search?.length > 0
                        ) {
                          updateDataList({search: ''}, datalistQ, setDataListQ)
                        }
                        loadDataList()
                      }}
                    >
                      {(datalistQ?.search === datalist?.search && datalist?.search?.length > 0) ||
                      (!datalistQ?.search && datalist?.search?.length > 0) ? (
                        <>
                          <ReactIcon icon='RiCloseLine' props={{className: 'fs-3'}} />
                        </>
                      ) : (
                        <>
                          <ReactIcon icon='RiSearchLine' props={{className: 'fs-3'}} />
                          Cari
                        </>
                      )}
                    </button>
                  </div>
                  <div
                    className='d-flex flex-column gap-2 overflow-y-auto position-relative'
                    style={{
                      height: '200px',
                    }}
                  >
                    {datalist?.loading && (
                      <div
                        className='position-absolute w-100 h-100 top-0 left-0 bg-white bg-opacity-50 d-flex justify-content-center align-items-center'
                        style={{
                          zIndex: 5,
                        }}
                      >
                        <div>
                          <div className='spinner-border' role='status'>
                            <span className='sr-only'>Loading...</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {!datalist?.loading &&
                      datalist?.data?.map((l: any, i: number) => {
                        const _id: string = options?.datalist?.id || ''
                        return (
                          <button
                            key={i}
                            type='button'
                            className={`btn ${
                              options?.datalist?.isListpicker &&
                              NullProof({
                                input: formikProps,
                                params: 'value',
                                isMap: true,
                              })?.some((e: any) => e[_id] === l[_id])
                                ? 'btn-primary'
                                : 'btn-light'
                            } text-start`}
                            onClick={() => {
                              if (!options?.datalist?.isListpicker) {
                                updateDataList(
                                  {
                                    title: dataListTitle(l),
                                  },
                                  datalist,
                                  setDataList
                                )
                                core._apiData.post({
                                  [`${name}_detail`]: l,
                                })
                                core._data.post({
                                  fieldsToUpdate: {
                                    [name]: l[_id],
                                  },
                                  index: componentIndex,
                                })
                                const autoFillList =
                                  options?.datalist?.autoFillInput?.split(',') || []
                                for (const lsub of autoFillList) {
                                  const lValue = lsub.split('=')
                                  core.formik.setFieldValue(lValue[0], l[lValue[1]] || '')
                                }
                                core.formik.setFieldValue(name, l[_id])
                                updateBindInput()
                                toggleModal({status: false})
                              } else {
                                updateDatalistCache({data: l, value: l[_id], id: _id})
                              }
                            }}
                          >
                            <div dangerouslySetInnerHTML={{__html: dataListTitle(l) || ''}}></div>
                          </button>
                        )
                      })}
                    {!datalist?.loading && (
                      <>
                        {datalist?.data?.length > 0 ? (
                          <></>
                        ) : (
                          <>
                            <div
                              className='p-4 d-flex flex-column justify-content-center align-items-center'
                              style={{
                                minHeight: '200px',
                              }}
                            >
                              {datalist?.error ? (
                                <>
                                  <div className='fs-3 fw-bold'>Gagal menghubungkan ke server</div>
                                  <div>Mohon untuk coba lagi nanti</div>
                                </>
                              ) : (
                                <>
                                  <div className='fs-3 fw-bold'>Belum ada data</div>
                                  <div>Data tidak ditemukan</div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className='mt-4'>
                    <Pagination
                      disabled={datalist?.error}
                      currentPage={datalist?.currentPage}
                      maxButtons={3}
                      totalPages={datalist?.totalPages || 1}
                      onPageChange={(e) => {
                        if (datalist?.currentPage !== e) {
                          updateDataList({page: e}, datalistQ, setDataListQ)
                          loadDataList()
                        }
                      }}
                    />
                  </div>
                  {options?.datalist?.isListpicker && (
                    <div className='d-flex justify-content-end gap-2'>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => toggleModal({status: false})}
                      >
                        Simpan
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </Modal>
        </>
      )}
      {InputSidebutton({
        children: (
          <>
            {type === 'input' && (
              <>
                {options?.input?.props?.type === 'date' ? (
                  <ReactDatepicker
                    value={NullProof({input: formikProps, params: 'value', isLabel: false})}
                    props={{
                      name: name,
                      ...formikProps,
                      ...baseInputProps(options?.input?.props),
                      ...{
                        showTimeSelect:
                          options?.input?.dateOption === 'time' ||
                          options?.input?.dateOption === 'datetime',
                      },
                      ...{showTimeSelectOnly: options?.input?.dateOption === 'time'},
                    }}
                    onChange={(val: any) => {
                      const dateZ = val?.toJSON()
                      core._data.post({
                        fieldsToUpdate: {
                          [name]: dateZ,
                        },
                        index: componentIndex,
                      })
                      core.formik.setFieldValue(name, dateZ)
                    }}
                  />
                ) : (
                  <>
                    {options?.input?.props?.type === 'checkbox' ? (
                      <div className='form-check d-flex align-items-center gap-2'>
                        <input
                          id={`${formName ? `${formName}_` : ``}${name}`}
                          name={name}
                          type='checkbox'
                          className='form-check-input form-check-input-lg cursor-pointer min-h-25px min-w-25px h-25px w-25px'
                          {...baseInputProps(options?.input?.props)}
                          {...formikProps}
                          checked={NullProof({input: formikProps, params: 'value', isLabel: false})}
                          onChange={(val) => {
                            core._data.post({
                              fieldsToUpdate: {
                                [name]: val.target.checked,
                              },
                              index: componentIndex,
                            })
                            core.formik.setFieldValue(name, val.target.checked)
                          }}
                        />
                        <label
                          className='form-check-label text-dark cursor-pointer user-select-none'
                          htmlFor={name}
                        >
                          {options?.input?.props?.placeholder}
                        </label>
                      </div>
                    ) : (
                      <div className='d-flex flex-column gap-4'>
                        {options?.input?.isCurrency || options?.input?.isDate ? (
                          <CurrencyFormat
                            name={name}
                            className='form-control form-control-lg mb-3 mb-lg-0'
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={options?.input?.isCurrency ? 'Rp. ' : ''}
                            suffix={options?.input?.isDate ? ' Hari' : ''}
                            min={0}
                            {...baseInputProps(options?.currency?.props)}
                            {...formikProps}
                            displayType={
                              options?.calc || options.input.bindInput
                                ? 'text'
                                : options?.currency?.props?.displayType || 'input'
                            }
                            value={options?.calc ? core.total.value[name] : formikProps?.value || 0}
                            onChange={() => {}}
                            onValueChange={(val) => {
                              if (!options?.calc) {
                                core._data.post({
                                  fieldsToUpdate: {
                                    [name]: Number(val.value),
                                  },
                                  index: componentIndex,
                                })
                                core.formik.setFieldValue(name, Number(val.value))
                              }
                            }}
                          />
                        ) : (
                          <>
                            {(options?.input?.props?.type || 'text') !== 'file' && (
                              <input
                                name={name}
                                className='form-control form-control-lg mb-3 mb-lg-0'
                                {...baseInputProps(options?.input?.props)}
                                type={showPassword ? 'text' : options?.input?.props?.type}
                                {...formikProps}
                                onBlur={(val) => {
                                  if ((options?.input?.props?.type || 'text') === 'text') {
                                    core._data.post({
                                      fieldsToUpdate: {
                                        [name]: val.target.value,
                                      },
                                      index: componentIndex,
                                      options: {
                                        refresh: true,
                                        type: 'onChange',
                                        isBlur: true,
                                      },
                                    })
                                    formikProps.onChange(val)
                                  }
                                }}
                                onChange={(val) => {
                                  if (options?.input?.props?.type !== 'text') {
                                    core._data.post({
                                      fieldsToUpdate: {
                                        [name]: val.target.value,
                                      },
                                      index: componentIndex,
                                    })
                                    formikProps.onChange(val)
                                  }
                                }}
                              />
                            )}
                          </>
                        )}
                        {options?.input?.props?.type === 'password' && (
                          <div className='form-check d-flex align-items-center gap-2'>
                            <input
                              id={`${formName ? `${formName}_` : ``}check-${name}`}
                              type='checkbox'
                              className='form-check-input form-check-input-lg cursor-pointer min-h-25px min-w-25px h-25px w-25px'
                              onChange={(val) => {
                                setShowPassword((p) => !p)
                              }}
                            />
                            <label
                              className='form-check-label text-dark cursor-pointer user-select-none'
                              htmlFor={`check-${name}`}
                            >
                              Lihat {options?.input?.props?.placeholder}
                            </label>
                          </div>
                        )}
                        {options?.input?.props?.type === 'file' && (
                          <>
                            <Modal
                              id={`${formName ? `${formName}_` : ``}${name}-cropper`}
                              title={'Crop Foto'}
                              isShow={modalCropper?.status}
                              onClose={() => {
                                setModalCropper((p) => ({...p, status: false}))
                              }}
                            >
                              <ReactCropper
                                data={modalCropper?.file}
                                onSave={async (file) => {
                                  uploadProgress(file)
                                  setModalCropper((p) => ({...p, status: false}))
                                }}
                                options={{
                                  filename: filenameGenerator({
                                    initName: options?.upload?.url?.filename || 'kemenag',
                                    uniqueId: 'kemenag',
                                  }),
                                  aspect: options.imageCropper?.aspect,
                                  shape: options.imageCropper?.shape,
                                }}
                              />
                            </Modal>
                            <Modal
                              id={`${formName ? `${formName}_` : ``}${name}`}
                              title={
                                options?.upload?.title ||
                                options?.input?.props?.placeholder ||
                                options?.input?.props?.title
                              }
                              isShow={modal.status && modal?.data}
                              onClose={() => {
                                toggleModal({status: false})
                              }}
                            >
                              {checkUploadFileType(modal?.data) === 'pdf' ? (
                                <>
                                  <ReactPdfViewer
                                    url={`/${modal?.data}`}
                                    options={{showTitleFilename: true}}
                                  />
                                </>
                              ) : (
                                <img
                                  className='ratio ratio-1x1'
                                  src={`${API_URL}/${modal?.data}`}
                                  alt={name}
                                />
                              )}
                            </Modal>
                            <input
                              key={inputKey}
                              id={`${formName ? `${formName}_` : ``}${name}`}
                              name={name}
                              type='file'
                              className={`form-control form-control-lg mb-3 mb-lg-0 d-none`}
                              {...baseInputProps(options?.input?.props)}
                              accept={
                                options?.input?.props?.accept
                                  ? options?.input?.props?.accept
                                  : '.pdf, .jpg, .jpeg, .png'
                              }
                              onChange={async (val) => {
                                try {
                                  if (val.target.files) {
                                    setDefaultValue(core._data.value[name])
                                    const files: FileList = val.target.files
                                    const file: File = files[0]
                                    if (
                                      file.type.includes('image') &&
                                      file &&
                                      options?.input?.useImageCrop &&
                                      !options.input.props?.multiple
                                    ) {
                                      setModalCropper((p) => ({...p, file: file, status: true}))
                                    } else {
                                      if (options.input?.props?.multiple) {
                                        addQueueUpload(files)
                                      } else {
                                        uploadProgress(file)
                                      }
                                    }
                                    setInputKey((p) => (p += 1))
                                  }
                                } catch (_) {}
                              }}
                            />
                            {options?.input?.props?.multiple &&
                              ((NullProof({
                                input: fileListUpload,
                                params: 'files',
                                isMap: true,
                              })?.length || 0) > 0 ||
                                (NullProof({
                                  input: formikProps,
                                  params: 'value',
                                  isMap: true,
                                })?.length || 0) > 0) && (
                                <div>
                                  <div className='d-flex flex-wrap gap-2'>
                                    {NullProof({
                                      input: fileListUpload,
                                      params: 'files',
                                      isMap: true,
                                    })?.map((l: any, i: number) => (
                                      <Fragment key={0}>
                                        <div
                                          className={`${
                                            l?.status === 'error'
                                              ? 'bg-danger text-white'
                                              : 'bg-light'
                                          } px-4 py-2 d-flex align-items-center gap-2`}
                                        >
                                          <div>{NullProof({input: l, params: 'name'})}</div>
                                          <div className='d-flex align-items-center gap-2'>
                                            {NullProof({input: l, params: 'status'}) ===
                                              'queue' && (
                                              <>
                                                <div
                                                  className='fs-2 cursor-pointer'
                                                  onClick={() => {
                                                    const result = [...fileListUpload.files]
                                                    result.splice(i, 1)
                                                    setFileListUpload((p) => ({
                                                      ...p,
                                                      files: result,
                                                    }))
                                                  }}
                                                >
                                                  <ReactIcon icon='RiCloseLine' />
                                                </div>
                                              </>
                                            )}
                                            {NullProof({input: l, params: 'status'}) ===
                                              'progress' && (
                                              <>
                                                <div
                                                  className='spinner-border spinner-border-sm'
                                                  role='status'
                                                >
                                                  <span className='sr-only'>Loading...</span>
                                                </div>
                                              </>
                                            )}
                                            {NullProof({input: l, params: 'status'}) ===
                                              'error' && (
                                              <>
                                                <div
                                                  className='fs-2 cursor-pointer'
                                                  onClick={() => {
                                                    const result = [...fileListUpload.files]
                                                    const target = result[i]
                                                    target.status = 'queue'
                                                    setFileListUpload((p) => ({
                                                      ...p,
                                                      files: result,
                                                    }))
                                                  }}
                                                >
                                                  <ReactIcon icon='RiRestartLine' />
                                                </div>
                                                <div
                                                  className='fs-2 cursor-pointer'
                                                  onClick={() => {
                                                    const result = [...fileListUpload.files]
                                                    result.splice(i, 1)
                                                    setFileListUpload((p) => ({
                                                      ...p,
                                                      files: result,
                                                    }))
                                                  }}
                                                >
                                                  <ReactIcon icon='RiCloseLine' />
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </Fragment>
                                    ))}
                                    {NullProof({
                                      input: formikProps,
                                      params: 'value',
                                      isMap: true,
                                    })?.map((l: any, i: number) => (
                                      <Fragment key={i}>
                                        <div className='bg-light px-4 py-2 d-flex align-items-center gap-2'>
                                          <div>{NullProof({input: l, params: 'name'})}</div>
                                          <div className='d-flex align-items-center gap-2'>
                                            <div
                                              className='fs-2 cursor-pointer'
                                              onClick={() => {
                                                toggleModal({status: true, data: l?.file})
                                              }}
                                            >
                                              <ReactIcon icon='RiEyeLine' />
                                            </div>
                                            <div
                                              className='fs-2 cursor-pointer text-danger'
                                              onClick={async () => {
                                                if (
                                                  await PopupConfirm({
                                                    validated:
                                                      options?.validator?.useReconfirmDelete,
                                                    hasConfirm: false,
                                                  })
                                                ) {
                                                  const result = [
                                                    ...NullProof({
                                                      input: formikProps,
                                                      params: 'value',
                                                      isMap: true,
                                                    }),
                                                  ]
                                                  result.splice(i, 1)
                                                  core.formik.setFieldValue(name, result)
                                                  core._data.post({
                                                    fieldsToUpdate: {
                                                      [name]: result,
                                                    },
                                                    index: componentIndex,
                                                  })
                                                }
                                              }}
                                            >
                                              <ReactIcon icon='RiDeleteBinLine' />
                                            </div>
                                          </div>
                                        </div>
                                      </Fragment>
                                    ))}
                                  </div>
                                </div>
                              )}
                            <div
                              className={`form-control form-control-lg mb-3 mb-lg-0 ${
                                loading || !checkWatchProps() || disabled ? 'bg-light' : ''
                              }`}
                              style={{gap: '10px'}}
                            >
                              <div className='row d-flex flex-column flex-md-row align-items-center mx-auto'>
                                <div
                                  className={`col text-truncate text-start ps-0 ${
                                    !(loading || !checkWatchProps() || disabled)
                                      ? 'cursor-pointer'
                                      : ''
                                  }`}
                                  onClick={() => {
                                    if (
                                      !uploadData.title &&
                                      !(loading || !checkWatchProps() || disabled)
                                    ) {
                                      document
                                        .getElementById(`${formName ? `${formName}_` : ``}${name}`)
                                        ?.click()
                                    } else if (
                                      !options?.input?.props?.multiple &&
                                      core._data.value[name] &&
                                      'pdf,jpg,jpeg,png'
                                        .split(',')
                                        .includes(core._data.value[name].split('.').pop())
                                    ) {
                                      toggleModal({status: true, data: core._data.value[name]})
                                    }
                                  }}
                                >
                                  {options?.input?.props?.multiple ? (
                                    <>
                                      {NullProof({input: formikProps, params: 'value', isMap: true})
                                        ?.length > 0
                                        ? `Pilih File...`
                                        : 'Pilih File...'}
                                    </>
                                  ) : (
                                    <>{uploadData.title || 'Pilih File...'}</>
                                  )}
                                </div>
                                <div className='col-auto d-flex gap-2'>
                                  {!options?.input?.props?.multiple &&
                                    core._data.value[name] &&
                                    'pdf,jpg,jpeg,png'
                                      .split(',')
                                      .includes(core._data.value[name].split('.').pop()) && (
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-primary'
                                        disabled={loading || !checkWatchProps() || disabled}
                                        onClick={() => {
                                          toggleModal({status: true, data: core._data.value[name]})
                                        }}
                                      >
                                        Preview
                                      </button>
                                    )}
                                  <button
                                    type='button'
                                    className={`btn btn-sm ${
                                      uploadData.status ? 'btn-danger' : 'btn-primary'
                                    }`}
                                    disabled={loading || !checkWatchProps() || disabled}
                                    data-kt-indicator={loading ? 'on' : 'off'}
                                    onClick={async () => {
                                      if (uploadData.status) {
                                        if (
                                          await PopupConfirm({
                                            validated: options?.validator?.useReconfirmDelete,
                                            hasConfirm: false,
                                          })
                                        ) {
                                          setUploadData({
                                            status: false,
                                            title: '',
                                          })
                                          core.formik?.setFieldValue(
                                            name,
                                            defaultValue ? defaultValue : null
                                          )
                                          core._data.post({
                                            fieldsToUpdate: {
                                              [name]: defaultValue ? defaultValue : null,
                                            },
                                            index: componentIndex,
                                          })
                                          setInputKey((p) => (p += 1))
                                        }
                                      } else {
                                        document
                                          .getElementById(
                                            `${formName ? `${formName}_` : ``}${name}`
                                          )
                                          ?.click()
                                      }
                                    }}
                                  >
                                    <span className='indicator-label'>
                                      {uploadData.status ? 'Hapus' : 'Upload'}
                                    </span>
                                    <span className='indicator-progress'>
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {type === 'textarea' && (
              <>
                {options?.textarea?.useTextEditor ? (
                  <ReactTiptap
                    value={NullProof({input: formikProps, params: 'value', isLabel: false})}
                    onChange={(val) => {
                      core._data.post({
                        fieldsToUpdate: {
                          [name]: val.html,
                        },
                        index: componentIndex,
                      })
                      core.formik.setFieldValue(name, val.html)
                    }}
                    options={{
                      props: baseInputProps(options?.textarea?.props),
                    }}
                  />
                ) : (
                  <textarea
                    name={name}
                    type='text'
                    className='form-control form-control-lg mb-3 mb-lg-0'
                    {...baseInputProps(options?.textarea?.props)}
                    {...formikProps}
                    onChange={(val) => {
                      core._data.post({
                        fieldsToUpdate: {
                          [name]: val.target.value,
                        },
                        index: componentIndex,
                      })
                      formikProps.onChange(val)
                    }}
                  >
                    {NullProof({input: formikProps, params: 'value', isLabel: false})}
                  </textarea>
                )}
              </>
            )}
            {type === 'select' && (
              <>
                <select
                  name={name}
                  className='form-select'
                  aria-label={options?.select?.props?.title}
                  {...baseInputProps(options?.select?.props)}
                  {...formikProps}
                  onChange={(val) => {
                    core._data.post({
                      fieldsToUpdate: {
                        [name]: val.target.value,
                      },
                      index: componentIndex,
                    })
                    formikProps.onChange(val)
                  }}
                >
                  <option value={''}>{`Pilih ${options?.select?.props?.title || 'Data'}`}</option>
                  {listData?.map((l, i) => (
                    <option key={i} value={l.value}>
                      {l.title}
                    </option>
                  ))}
                </select>
              </>
            )}
            {type === 'multicheckbox' && (
              <>
                <div
                  className={`${
                    options?.multicheckbox?.rowType === 'row'
                      ? `mx-0 row g-2`
                      : 'd-flex flex-wrap gap-2'
                  } ${options?.multicheckbox?.rowClassName || ''}`}
                >
                  {listData?.map((l, i) => (
                    <Fragment key={i}>
                      <div
                        className={`${options?.multicheckbox?.rowType === 'row' ? 'col' : ''} ${
                          options?.multicheckbox?.colClassName || ''
                        } form-check d-flex align-items-center gap-2`}
                      >
                        <input
                          id={`${formName ? `${formName}_` : ``}${name}_${i + 1}`}
                          name={`${name}_${i + 1}`}
                          type='checkbox'
                          className='form-check-input form-check-input-lg cursor-pointer min-h-25px min-w-25px h-25px w-25px'
                          {...baseInputProps(options?.input?.props)}
                          checked={
                            NullProof({input: formikProps, params: 'value', isMap: true})?.includes(
                              l?.value || ''
                            ) || false
                          }
                          onChange={(val) => {
                            let dataCollection = [
                              ...NullProof({input: formikProps, params: 'value', isMap: true}),
                            ]
                            let index = dataCollection.indexOf(l?.value)
                            if (index !== -1) {
                              dataCollection.splice(index, 1)
                            } else {
                              dataCollection = [...dataCollection, l?.value]
                            }
                            core._data.post({
                              fieldsToUpdate: {
                                [name]: dataCollection,
                              },
                              index: componentIndex,
                            })
                            core.formik.setFieldValue(name, dataCollection)
                          }}
                        />
                        <label
                          className='form-check-label text-dark cursor-pointer user-select-none'
                          htmlFor={`${name}_${i + 1}`}
                        >
                          {l?.title}
                        </label>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </>
            )}
            {type === 'label' && (
              <>
                <div className='fw-bold fs-5 py-2'>
                  {(_labelType === 'label' || _labelType === 'both') && (
                    <div className={` ${options?.label?.className || 'mb-2'}`}>
                      {options?.label?.name || 'Label'}
                    </div>
                  )}
                  {options?.label?.description && <>{options?.label?.description}</>}
                  {(_labelType === 'line' || _labelType === 'both') && (
                    <div
                      className={`w-100 rounded-circle ${
                        options?.label?.lineClassName || 'bg-dark opacity-10'
                      }`}
                      style={{height: '2px'}}
                    ></div>
                  )}
                </div>
              </>
            )}
            {type === 'datalist' && (
              <>
                {<DatalistCacheUi />}
                <div>
                  <button
                    disabled={loading || !checkWatchProps() || disabled}
                    type='button'
                    className='form-control form-control-sm fs-6 mb-3 mb-lg-0 text-start'
                    onClick={() => {
                      if (inputError) {
                        initDatalist()
                      } else {
                        toggleModal({status: true})
                        if (options?.datalist?.type !== 'table') {
                          loadDataList()
                        }
                      }
                    }}
                  >
                    <div className='d-flex justify-content-between align-items-center'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: inputError
                            ? `Gagal Memuat Data - Klik Untuk Coba Lagi`
                            : loading
                            ? `Memuat Data...`
                            : datalist?.title
                            ? datalist?.title
                            : `Pilih ${options?.datalist?.title || 'Data'}`,
                        }}
                      ></div>
                      <div className='fs-3'>
                        <ReactIcon icon='RiArrowDownSLine' />
                      </div>
                    </div>
                  </button>
                </div>
              </>
            )}
            {type === 'csv' && (
              <>
                <div className='d-flex flex-column gap-4'>
                  <input
                    key={inputKey}
                    id={`${formName ? `${formName}_` : ``}${name}`}
                    name={name}
                    type='file'
                    className={`form-control form-control-lg mb-3 mb-lg-0 d-none`}
                    {...baseInputProps(options?.input?.props)}
                    accept={
                      options?.input?.props?.accept ? options?.input?.props?.accept : '.csv, .xlsx'
                    }
                    onChange={async (val) => {
                      try {
                        if (val.target.files) {
                          const files: FileList = val.target.files
                          const file: File = files[0]
                          const infoFile = checkFileExtension(file.name)
                          let data: any
                          if (infoFile.ext === 'xlsx') {
                            data = await xlsxToObj(file)
                          } else if (infoFile.ext === 'csv') {
                            data = await csvToObject(new Blob([file], {type: file.type}), {
                              delimiter: ';',
                            })
                          }
                          if (data) {
                            core._data.post({
                              fieldsToUpdate: {
                                [name]: data,
                              },
                              index: componentIndex,
                            })
                            core.formik.setFieldValue(name, data)
                            setUploadData({
                              status: true,
                              title: file.name,
                            })
                          }
                        }
                      } catch (_) {}
                    }}
                  />
                  <div
                    className='form-control form-control-lg mb-3 mb-lg-0 d-flex flex-column flex-md-row align-items-center row mx-auto'
                    style={{gap: '10px'}}
                  >
                    <div
                      className='col text-truncate ps-0 cursor-pointer'
                      onClick={() => {
                        document.getElementById(`${formName ? `${formName}_` : ``}${name}`)?.click()
                      }}
                    >
                      {uploadData.title || 'Pilih File...'}
                    </div>
                    <div className='col-auto d-flex gap-2'>
                      <button
                        type='button'
                        className={`btn btn-sm ${uploadData.status ? 'btn-danger' : 'btn-primary'}`}
                        disabled={loading}
                        data-kt-indicator={loading ? 'on' : 'off'}
                        onClick={async () => {
                          if (uploadData.status) {
                            if (
                              await PopupConfirm({
                                validated: options?.validator?.useReconfirmDelete,
                                hasConfirm: false,
                              })
                            ) {
                              setUploadData({
                                status: false,
                                title: '',
                              })
                              core.formik?.setFieldValue(
                                name,
                                core._data.value[name] ? core._data.value[name] : null
                              )
                              setInputKey((p) => (p += 1))
                            }
                          } else {
                            document
                              .getElementById(`${formName ? `${formName}_` : ``}${name}`)
                              ?.click()
                          }
                        }}
                      >
                        <span className='indicator-label'>
                          {uploadData.status ? 'Hapus' : 'Upload'}
                        </span>
                        <span className='indicator-progress'>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ),
      })}
      {type !== 'label' && noteUi(options?.label)}
      {errorUi({core: core, name: name})}
      {debugUi()}
    </div>
  )
}

const ComponentInput: FC<ComponentInputProps & WithChildren> = ({
  name,
  data,
  component,
  children,
}) => {
  const [lastTotalComponent, setLastTotalComponent] = useState<number>(0)
  const [listComponent, setListComponent] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const addComponent = () => {
    const componentLength = listComponent.length || 0
    const json = {
      data: '',
    }
    setListComponent((p: any[]) => [...p, {...json}])
    if (!loading) {
      component.map((l, i) => {
        const componentName = `${name}_${l.name}_${componentLength + 1}`
        let initData = data.core._data.value[componentName]
          ? data.core._data.value[componentName]
          : (l.component?.length || 0) > 0
          ? 0
          : l.type === 'input'
          ? l.options?.input?.props?.type === 'checkbox'
            ? false
            : ''
          : ''
        if (
          (data.options?.component?.cloneInitData || l.options?.component?.cloneInitData) &&
          i > 0
        ) {
          initData = data.core._data.value[`${name}_${l.name}_${componentLength}`]
        }
        data.core.formik.setFieldValue(componentName, initData)
        data.core._data.post({
          fieldsToUpdate: {
            [componentName]: initData,
          },
          options: {
            type: 'onLoad',
          },
          id: name,
          index: i,
        })
      })
      data.core._data.post({
        fieldsToUpdate: {
          [name]: componentLength + 1,
        },
        options: {
          type: 'onLoad',
        },
        id: name,
        index: componentLength + 1,
      })
      data.core.formik.setFieldValue(name, componentLength + 1)
    }
  }
  const removeComponent = async (index: number, force: boolean = false) => {
    let confirm: any = false
    if (!force) {
      confirm = await PopupConfirm({
        validated: data.options?.validator?.useReconfirmDelete,
        hasConfirm: false,
      })
    }
    if (confirm || force) {
      const migrateDataComponent = ({
        component,
        parent,
        index,
      }: {
        component: FormInputProps[]
        parent?: string
        index: string
      }) => {
        let result: any = {}
        component.map((l, i) => {
          const childName = `${parent ? `${parent}_` : `${name}_`}${l.name}${
            parent ? '' : `_${index}`
          }`
          try {
            Object.keys(data.core._data.value).forEach((l) => {
              if (l.includes(childName)) {
                result[l] = data.core._data.value[l]
              }
            })
          } catch (_) {}
        })
        return result
      }
      const oldData = migrateDataComponent({component: component, index: `${index + 1}`})
      Object.keys(oldData).map((l) => {
        data.core.formik.setFieldValue(l, undefined)
        data.core.formik.unregisterField(l)
        data.core._data.post({
          fieldsToUpdate: {
            [l]: undefined,
          },
          options: {
            type: 'onChange',
          },
        })
        delete data.core._data.value[l]
      })
      for (let i = index + 1; i < listComponent.length; i++) {
        const targetData = migrateDataComponent({component: component, index: `${i + 1}`})
        Object.keys(targetData).map((l) => {
          const targetName = `${name}${l.replace(name, '').replace(`${i + 1}`, `${i}`)}`
          data.core.formik.setFieldValue(targetName, targetData[l])
          data.core._data.post({
            fieldsToUpdate: {
              [targetName]: targetData[l],
            },
            options: {
              type: 'onChange',
            },
          })
          if (i === listComponent.length - 1) {
            data.core.formik.setFieldValue(l, undefined)
            data.core.formik.unregisterField(l)
            data.core._data.post({
              fieldsToUpdate: {
                [l]: undefined,
              },
              options: {
                type: 'onChange',
              },
            })
            delete data.core._data.value[l]
          }
        })
      }
      const componentLength = data.core._data.value[name] || 0
      setListComponent((p: any[]) => p.slice(0, p.length - 1))
      data.core._data.post({
        fieldsToUpdate: {
          [name]: componentLength - 1,
        },
        options: {
          type: 'onChange',
        },
      })
      data.core.formik.setFieldValue(name, componentLength - 1)
      setLoading(true)
    }
  }
  const checkComponentError = (i: number) => {
    let error = false
    error = data.core.formik.errors[name]
    for (let index = 0; index < component.length; index++) {
      const componentName = `${name}_${component[index].name}_${i + 1}`
      error = data.core.formik.errors[componentName]
      if (error) {
        break
      }
    }
    return error
  }
  useEffect(() => {
    if (data.core._data.value[name]) {
      if (lastTotalComponent !== data.core._data.value[name] || 0) {
        setLastTotalComponent(data.core._data.value[name] || 0)
        setLoading(true)
      }
      if (loading) {
        const totalComponent = data.core._data.value[name] || 0
        if (totalComponent > 0) {
          if (listComponent.length < totalComponent) {
            addComponent()
          } else if (totalComponent < listComponent.length) {
            setListComponent((p: any[]) => p.slice(0, p.length - 1))
          } else {
            setLoading(false)
          }
        } else {
          setLoading(false)
        }
      }
    } else {
      setLoading(false)
    }
  }, [loading, data.core._data.value[name], listComponent.length])

  return (
    <div className={data.className}>
      {!data.options?.component?.isObject && (
        <>
          <div
            className='d-flex align-items-center justify-content-between mt-6 mb-2'
            style={{
              gap: '5px',
            }}
          >
            <div>
              <div className='fs-4 fw-bold'>
                {data?.options?.input?.props?.title || data?.options?.input?.props?.placeholder}
              </div>
              {data.options?.component?.resultType && (
                <CurrencyFormat
                  className='fs-6 fw-semibold'
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={data.options?.component?.resultType === 'currency' ? 'Rp. ' : ''}
                  suffix={data.options?.component?.resultType === 'date' ? ' Hari' : ''}
                  displayType='text'
                  value={data.core._dataIndicator.value[name] || 0}
                />
              )}
            </div>
          </div>
          {errorUi({core: data.core, name: name})}
          <div className='w-full h-1px bg-dark bg-opacity-10 my-4'></div>
        </>
      )}
      <div className='position-relative px-0 d-flex flex-column mt-0 gap-2'>
        {listComponent.map((l, i) => (
          <Fragment key={i}>
            <Accordion
              title={`${data.options?.component?.isObject ? '' : `${i + 1}. `}${
                data?.options?.input?.props?.title || data?.options?.input?.props?.placeholder || ''
              }`}
              enabled={data?.options?.component?.useAccordion}
              error={checkComponentError(i) && data.core.formError.value}
            >
              {(data?.options?.input?.props?.title ||
                data?.options?.input?.props?.placeholder ||
                !data.options?.component?.isObject) && (
                <div className='d-flex align-items-center justify-content-between gap-2'>
                  {(data?.options?.input?.props?.title ||
                    data?.options?.input?.props?.placeholder) && (
                    <div
                      className={`${data.options?.component?.isObject ? 'fs-5 fw-bold' : 'fs-6'}`}
                    >
                      {`${data.options?.component?.isObject ? '' : `${i + 1}. `}${
                        data?.options?.input?.props?.title ||
                        data?.options?.input?.props?.placeholder ||
                        ''
                      }`}
                    </div>
                  )}
                  {!data.options?.component?.isObject && !data.disabled && !data.lockData && (
                    <button
                      type='button'
                      className='btn btn-sm btn-danger d-flex justify-content-center gap-2 p-3'
                      onClick={() => removeComponent(i)}
                    >
                      <ReactIcon icon='RiDeleteBinLine' props={{className: 'fs-4'}} />
                      <span className='d-none d-md-block'>Hapus</span>
                    </button>
                  )}
                </div>
              )}
              {data.options?.label?.description && <>{data.options?.label?.description}</>}

              <div className={`row w-100 g-4 my-0 position-relative mx-0`}>
                {(data?.options?.component?.useOrderNumber ||
                  data?.options?.component?.useGuide) && (
                  <div
                    className='col-auto mt-0 fs-3 fw-bold d-flex align-items-center justify-content-center position-relative'
                    style={{minWidth: '20px'}}
                  >
                    {data?.options?.component?.useGuide && (
                      <>
                        <div
                          className='position-absolute rounded-circle bg-primary'
                          style={{
                            left: '11px',
                            top: '16px',
                            width: '10px',
                            height: '10px',
                          }}
                        ></div>
                        <div
                          className={`position-absolute ${
                            data?.options?.component?.useGuide
                              ? 'border-start mt-0 border-primary w-5px h-100 pe-none'
                              : ''
                          }`}
                          style={{left: '15px', top: 0}}
                        ></div>
                      </>
                    )}
                    {data?.options?.component?.useOrderNumber && <span>{i + 1}.</span>}
                  </div>
                )}
                <div className='col mt-0 pt-4'>
                  <div className='row g-2'>
                    {component?.map((lsub, isub) => {
                      const _core: FormInputProps & InputListOptionProps = {
                        formName: data.formName,
                        parentName: name,
                        name: `${name}_${lsub.name}_${i + 1}`,
                        disabled: data.disabled || lsub.disabled,
                        componentIndex: i + 1,
                        className: lsub.className,
                        hide: lsub.hide,
                        removeOnSubmit: lsub.removeOnSubmit,
                        lockData: lsub.lockData || data.lockData,
                        type: lsub.type,
                        options: lsub.options,
                        value: lsub.value,
                        listData: lsub.listData,
                        validator: lsub.validator,
                        component: lsub.component,
                        onFeedback: data.onFeedback,
                        onLoad: data.onLoad,
                        route: data.route,
                        core: data.core,
                      }
                      return (
                        <Fragment key={isub}>
                          {(lsub.component?.length || 0) > 0 ? (
                            <ComponentInput
                              name={_core.name}
                              data={_core}
                              component={lsub.component || []}
                            ></ComponentInput>
                          ) : (
                            <InputList {..._core} />
                          )}
                        </Fragment>
                      )
                    })}
                  </div>
                </div>
              </div>
              {((!data.options?.component?.isObject && i !== listComponent.length - 1) ||
                data.disabled) && <div className='w-full h-1px bg-dark bg-opacity-10 my-4'></div>}
              {/* <div
                  className='d-flex align-items-center justify-content-end mb-2 ms-n2 mt-4'
                  style={{
                    gap: '5px',
                  }}
                >
                  <button
                    type='button'
                    className='btn btn-sm btn-danger d-flex justify-content-center gap-2'
                    onClick={() => removeComponent(i)}
                  >
                    <ReactIcon icon='RiDeleteBinLine' props={{className: 'fs-4'}} />
                    Hapus{' '}
                    {data?.options?.input?.props?.title || data?.options?.input?.props?.placeholder}
                  </button>
                </div> */}
            </Accordion>
          </Fragment>
        ))}
      </div>
      {!data.options?.component?.isObject && !data.disabled && !data.lockData && (
        <>
          <div
            className='d-flex align-items-center justify-content-center mt-4 mb-2'
            style={{
              gap: '5px',
            }}
          >
            <button
              type='button'
              className='btn btn-sm btn-primary d-flex justify-content-center gap-2 p-3 w-100 w-md-auto'
              onClick={addComponent}
            >
              <ReactIcon icon='RiAddLine' props={{className: 'fs-4'}} />
              <span>
                Tambah{' '}
                {data?.options?.input?.props?.title || data?.options?.input?.props?.placeholder}
              </span>
            </button>
          </div>
        </>
      )}
      {isDebugging && (
        <>
          <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
            <code>
              {JSON.stringify(
                component.map((l, i) => {
                  return `${l.name}:${
                    data.core.formik.values[`${name}_${l.name}_${i + 1}`] || null
                  }`
                })
              )}
            </code>
          </pre>
        </>
      )}
    </div>
  )
}

const JsonDataBuilder = async ({data, _data, total, value, isSubmit}: JsonDataBuilderProps) => {
  let json: any = {}
  // Generate Upload URL
  const DropdownValue = async (l: FormInputProps) => {
    let _json: any = []
    const totalData = value[l.name] || 0
    for (let index = 0; index < totalData; index++) {
      let subData: any = {}
      l.component?.map(async (lsub: FormInputProps, isub) => {
        if (!lsub.removeOnSubmit) {
          const name: string = `${l.name}_${lsub.name}_${index + 1}`
          if ((lsub.component?.length || 0) > 0) {
            subData[lsub.name] = await DropdownValue({
              ...lsub,
              name: name,
            })
          } else if (lsub.type === 'input' && lsub.options?.input?.props?.type === 'file') {
            const typeProps = typeof _data[name]
            if (typeProps === 'string' || typeProps === 'number') {
              const _value = _data[name] === '' || _data[name] === 0 ? null : _data[name]
              subData[lsub.name] = _value
            }
          } else if (
            (lsub.type === 'input' &&
              (lsub.options?.input?.isCurrency || lsub.options?.input?.isDate) &&
              !lsub.options?.calc) ||
            (lsub.options?.input?.props?.type === 'number' && !lsub.options?.calc)
          ) {
            subData[lsub.name] = Number(value[name] || 0)
          } else if (
            lsub.type === 'input' &&
            (lsub.options?.input?.isCurrency || lsub.options?.input?.isDate) &&
            lsub.options?.calc
          ) {
            subData[lsub.name] = Number(total[name] || 0)
          } else if (lsub.type === 'label') {
          } else {
            const typeProps = typeof value[name]
            if (typeProps === 'string' || typeProps === 'number') {
              const _value = value[name] === '' || value[name] === 0 ? null : value[name]
              if (typeProps === 'string') {
                _value?.replace(/\n$/, '')
              }
              subData[lsub.name] = _value
            } else {
              const _value = value[name]
              subData[lsub.name] = _value
            }
          }
        }
      })
      _json.push(subData)
    }
    return _json
  }
  data.map(async (l, i) => {
    if (!l.removeOnSubmit) {
      if ((l.component?.length || 0) > 0) {
        json[l.name] = await DropdownValue(l)
      } else if (l.type === 'input' && l.options?.input?.props?.type === 'file') {
        const typeProps = typeof _data[l.name]
        if (typeProps === 'string' || typeProps === 'number') {
          const _value = _data[l.name] === '' || _data[l.name] === 0 ? null : _data[l.name]
          json[l.name] = _value
        }
      } else if (
        (l.type === 'input' &&
          (l.options?.input?.isCurrency || l.options?.input?.isDate) &&
          !l.options?.calc) ||
        (l.options?.input?.props?.type === 'number' && !l.options.calc)
      ) {
        json[l.name] = Number(value[l.name] || 0)
      } else if (l.type === 'input' && l.options?.calc) {
        json[l.name] = Number(total[l.name] || 0)
      } else if (l.type === 'label') {
      } else {
        const typeProps = typeof value[l.name]
        if (typeProps === 'string' || typeProps === 'number') {
          const _value = value[l.name] === '' || value[l.name] === 0 ? null : value[l.name]
          if (typeProps === 'string') {
            _value?.replace(/\n$/, '')
          }
          json[l.name] = _value
        } else {
          const _value = value[l.name]
          json[l.name] = _value
        }
      }
    }
  })
  return json
}

const FormInput: FC<BaseInputProps> = ({
  name,
  isLocked = false,
  isModal,
  isSubmitByBlur,
  useToast = true,
  input,
  route,
  className,
  isLoading,
  onLoad,
  onChange,
  onFeedback,
  onBeforeSubmit,
  options,
}) => {
  const reduxDispatch = useDispatch()
  const reduxTableData = useSelector((state: any) => state.table.data)
  const reduxRouteData = useSelector((state: any) => state.route.value)

  const protectInput = (): FormInputProps[] => {
    let result = [...input]
    result = result.filter((l, i) => {
      let hasAccess = false
      if ((l.protect?.length || 0) > 0) {
        for (const lf of reduxRouteData?.route) {
          if (l.protect?.some((e) => e === lf)) {
            hasAccess = true
            break
          }
        }
      } else {
        hasAccess = true
      }
      return hasAccess
    })
    return result
  }

  const query = useParams()
  const navigate = useNavigate()
  const initialValues = getInitialValues({data: protectInput()})
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape(InputListValidator({data: protectInput()}))
  )
  const [_data, _setData] = useState<any>(initialValues)
  const [_dataIndicator, _setDataIndicator] = useState<any>({})
  const [_apiData, _setApiData] = useState<any>({})
  const [_total, _setTotal] = useState<any>({})
  const [total, setTotal] = useState<any>({})
  const [serverData, setServerData] = useState<any>({})
  const [staticData, setStaticData] = useState<any>({})

  const [lockForm, setLockForm] = useState<boolean>(isLocked)
  const [inputErrorMessage, setInputErrorMessage] = useState<any>({})
  const [inputRefreshData, setInputRefreshData] = useState<any>({})
  const [inputKey, setInputKey] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const [formError, setFormError] = useState(false)
  const [formSubmitError, setFormSubmitError] = useState(false)

  useEffect(() => {
    setLockForm(isLocked)
  }, [isLocked])

  const _updateApiData = (fieldsToUpdate: Partial<any>): void => {
    const updatedData = Object.assign(_apiData, fieldsToUpdate)
    _setApiData(updatedData)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await onSubmit(values, setSubmitting)
    },
  })

  const {validateForm, resetForm} = formik

  const updateBindInputApi = () => {
    const DropdownInput = (l: FormInputValidatorProps) => {
      if (l.options?.input?.bindInput && l.options.input.bindInput.type !== 'fill') {
        const targetSource = l.options.input.bindInput.sourceData
        const targetApi = l.options?.input?.bindInput.input
        const targetName = l?.parentId ? `${l?.parentId}_${targetApi}_${l.index}` : targetApi
        if (targetSource === 'total') {
          if (total[targetName]) {
            const dataApi = total[targetName]
            _updateData({fieldsToUpdate: {[l.name]: dataApi}})
            formik.setFieldValue(l.name, dataApi)
          }
        } else {
          if (_apiData[targetApi]) {
            try {
              const dataInputApi = _apiData[targetApi]?.filter(
                (ls: any) => ls[l.options?.input?.bindInput?.id || 'id'] === _data[targetName]
              )[0]
              const paramsList = l.options.input.bindInput.params?.split(',')
              for (const params of paramsList) {
                const dataApi = dataInputApi[params] || ''
                if (!dataApi) {
                  continue
                }
                _updateData({
                  fieldsToUpdate: {[l.name]: dataApi},
                  options: {
                    refresh: false,
                  },
                  id: targetName,
                  index: l.index,
                })
                formik.setFieldValue(l.name, dataApi)
              }
            } catch (_) {}
          }
        }
      }
      if ((l.component?.length || 0) > 0) {
        l.component?.map((lsub, isub) => {
          const name = `${l.name}_${lsub.name}_${isub + 1}`
          DropdownInput({
            ...lsub,
            name: name,
            parentName: lsub.name,
            parentId: l.name,
            index: isub,
          })
        })
      }
    }
    protectInput().map((l, i) => {
      DropdownInput(l)
    })
  }

  const updateTotalData = () => {
    let json: any = {}
    let filterCurrency: any[] = []
    const sumBuilder = (input: any) => {
      let result: number = total[input.name] || 0
      let resultString: string = input?.calcValue?.result
      const checkParent = (name: any) => {
        return input.parentName ? `${input.parentName}_${name}_${input.position}` : name
      }
      if (input?.calcValue?.type === 'date') {
        if (input?.calcValue?.data[0] && input?.calcValue?.data[1]) {
          const startDate = _data[checkParent(input?.calcValue?.data[0])] || ''
          const endDate = _data[checkParent(input?.calcValue?.data[1])] || ''
          let calcDate =
            formatDate({date: startDate, targetDate: endDate, dateFormat: 'range'}) + 1 || 0
          if (calcDate < 0) {
            calcDate = 0
          }
          result = calcDate
        }
      } else {
        input?.calcValue?.data.map((l: any, i: number) => {
          const targetNameValue = checkParent(l)
          const targetValue =
            input?.calcValue?.sourceData === 'total'
              ? total[targetNameValue] || json[targetNameValue] || 0
              : _data[targetNameValue] || total[targetNameValue] || json[targetNameValue] || 0
          resultString = resultString.replaceAll(`$${l}`, targetValue)
        })
        try {
          result = Number(eval(resultString))
        } catch (_) {}
      }
      if (input?.parentName) {
        json[input.parentName] = (json[input.parentName] || 0) + result
      }
      json[input.name] = result
      return result
    }
    protectInput()
      .filter(
        (l: FormInputProps) =>
          l.type === 'input' && (l.options?.input?.isCurrency || l.options?.input?.isDate)
      )
      .map((l: FormInputProps) => {
        if (l.options?.calc) {
          filterCurrency.push({
            name: l.name,
            position: 0,
            calcValue: l.options?.calc,
          })
        }
      })
    protectInput()
      .filter((l) => (l.component?.length || 0) > 0)
      .map((l) => {
        l.component
          ?.filter(
            (lsub: FormInputProps) =>
              lsub.type === 'input' &&
              (lsub.options?.input?.isCurrency || lsub.options?.input?.isDate)
          )
          .map((lsub: FormInputProps) => {
            for (let index = 0; index < formik.values[l.name]; index++) {
              const name = `${l.name}_${lsub.name}_${index + 1}`
              if (lsub.options?.calc) {
                filterCurrency.push({
                  name: name,
                  parentName: l.name,
                  position: index + 1,
                  calcValue: lsub.options?.calc,
                })
              }
            }
          })
      })
    filterCurrency.map((l) => {
      sumBuilder(l)
    })
    _updateTotal(json)
  }

  const RebuildValidator = (updatedData: any) => {
    let builder: FormInputValidatorProps[] = [...protectInput()]
    const DropdownValidator = (l: FormInputValidatorProps) => {
      if ((l.component?.length || 0) > 0) {
        const totalComponent = updatedData[l.name]
        const component = [...(l.component || [])]
        component?.forEach((lsub: FormInputValidatorProps, isub) => {
          for (let index = 1; index <= totalComponent; index++) {
            const name = `${l.name}_${lsub.name}_${index}`
            if ((lsub.component?.length || 0) > 0) {
              builder.push({
                ...lsub,
                name: name,
                parentId: lsub.name,
              })
              DropdownValidator({
                ...lsub,
                name: name,
                parentId: lsub.name,
              })
            } else {
              builder.push({
                ...lsub,
                name: name,
                parentId: l.name,
              })
            }
          }
        })
      }
    }
    builder.forEach((l) => {
      DropdownValidator(l)
    })
    const initValidationSchema = Yup.object().shape(InputListValidator({data: builder}))
    setValidationSchema(initValidationSchema)
  }
  const updateInputRefreshData = (fieldsToUpdate: Partial<any>): void => {
    const updatedData = Object.assign(inputRefreshData, fieldsToUpdate)
    setInputRefreshData(updatedData)
  }
  const _updateDataIndicator = (fieldsToUpdate: Partial<any>): void => {
    const updatedData = Object.assign(_dataIndicator, fieldsToUpdate)
    _setDataIndicator(updatedData)
  }
  const _updateTotal = (fieldsToUpdate: Partial<any>): void => {
    const updatedData = Object.assign(total, fieldsToUpdate)
    setTotal(updatedData)
  }
  const errorForm = async ({field, message}: {field: string; message: string}) => {
    let result = Object.assign(inputErrorMessage, {[field]: message})
    for (const l of Object.keys(result)) {
      result[l].length < 1 && delete result[l]
    }
    setInputErrorMessage({...result})
  }
  const _updateData = async ({
    fieldsToUpdate,
    initServerData,
    options = {
      refresh: true,
      type: 'onChange',
    },
    id,
    index,
  }: _updateDataProps) => {
    const updatedData = Object.assign(_data, fieldsToUpdate)
    const listUpdatedData = Object.keys(fieldsToUpdate)
    const _id = id || (listUpdatedData.length > 0 ? listUpdatedData[0] : '')
    if (options.type === 'onChange') {
      const resetInputWatchInput = (input: FormInputProps[], parentName?: string) => {
        let listData: {id: string; name: string; parentName?: string}[] = []
        for (const l of input) {
          if (
            l.options?.watchInput &&
            (l.options?.watchInput?.type || 'watch-reset') === 'watch-reset'
          ) {
            listData.push({
              id: l.options?.watchInput?.id || '',
              name: l.name,
              parentName: parentName,
            })
          } else if (l.component) {
            listData = [...listData, ...resetInputWatchInput(l.component, l.name)]
          }
        }
        return listData
      }
      let listDataReset: {id: string; name: string; parentName?: string}[] = resetInputWatchInput(
        protectInput()
      )
      for (const l of listDataReset) {
        const propsName: string = `${l.parentName ? `${l.parentName}_` : ''}${l.name}${
          index ? `_${index}` : ''
        }`
        const watchId = l.id.split(',')
        for (const l1 of watchId) {
          const inputName: string = `${l.parentName ? `${l.parentName}_` : ''}${l1}${
            index ? `_${index}` : ''
          }`
          if (listUpdatedData.includes(inputName)) {
            formik.setFieldValue(propsName, '')
            updatedData[propsName] = ''
          }
        }
      }
    }
    RebuildValidator(updatedData)
    _setData(updatedData)
    const updateForm = (e: any) => {
      let listKey: any = {}
      for (const l of Object.keys(e?.values || {})) {
        if (e?.values[l] !== _data[l]) {
          listKey[l] = (inputKey[l] || 0) + 1
        }
      }
      setInputKey({...inputKey, ...listKey})
      _setData(e.values)
      resetForm({values: e.values})
    }
    onChange &&
      onChange({
        _data: {
          getObject: await JsonDataBuilder({
            data: protectInput(),
            _data: _data,
            total: total,
            value: _data,
          }),
          get: _data,
          post: _setData,
        },
        _dataIndicator: {
          get: _dataIndicator,
          post: _setDataIndicator,
        },
        _apiData: {
          get: _apiData,
          post: _apiData,
          server: initServerData || serverData,
        },
        _total: {
          get: _total,
          post: _setTotal,
        },
        setError: errorForm,
        resetForm: updateForm,
        type: options.type,
        lockForm: setLockForm,
        refreshInput: updateInputRefreshData,
        clickId: _id,
      })
    if (isSubmitByBlur && options?.isBlur) {
      if (!(formError || loading)) {
        const isError =
          !formik.isValid ||
          Object.keys(formik.errors).length > 0 ||
          Object.keys(inputErrorMessage).length > 0
        setFormSubmitError(isError)
        formik.handleSubmit()
      }
    }
    if (options.refresh) {
      updateTotalData()
      updateBindInputApi()
    }
  }

  const loadData = async () => {
    setLoading(true)
    if (route?.url) {
      try {
        let result: {status: boolean; data: any} = {status: false, data: {}}
        if (route?.data) {
          result = {status: true, data: route.data}
        } else {
          if (route.url !== '#') {
            result = await getData('', DynamicUrl({input: route.url, params: '', query: query}))
          }
          const bindDataList = await ListBindDataByUrl({data: route.bindData || [], query: query})
          result = {
            ...result,
            data: {
              ...result.data,
              ...bindDataList.propsList,
            },
          }
          setStaticData(bindDataList.dataList)
        }
        setServerData(result.status ? result.data : {})
        setFormError(!result.status)
        const initData = getInitialValues({data: protectInput(), serverData: result.data})
        const tempData = getInitialValues({
          data: protectInput(),
          serverData: result.data,
          isTemp: true,
        })
        const initValidationSchema = Yup.object().shape(
          InputListValidator({data: protectInput(), serverData: result.data})
        )
        formik.resetForm({values: initData})
        setValidationSchema(initValidationSchema)
        _updateData({
          fieldsToUpdate: tempData,
          initServerData: result.status ? result.data : {},
          options: {
            type: 'onInit',
          },
        })
      } catch (_) {}
      setLoading(false)
    }
  }

  const onSubmit = async (values: any, setSubmitting: any) => {
    setFormSubmitError(false)
    setSubmitting(true)
    try {
      let result = {status: false, data: {}, message: ''}
      let json: any = await JsonDataBuilder({
        data: protectInput(),
        _data: _data,
        total: total,
        value: values,
        isSubmit: route.type === 'put',
      })
      const url = DynamicUrl({
        input: route?.urlSubmit || route.url,
        params: '',
        query: query,
      })
      if (route.isFeedback) {
        const resetOnFeedback = route.resetOnFeedback === undefined ? true : route.resetOnFeedback
        if (route.withServerData) {
          json = {...json, ...serverData}
        }
        result = {status: true, data: json, message: ''}
        route.onFeedback && route.onFeedback(result)
        if (resetOnFeedback) {
          formik.resetForm({values: getInitialValues({data: protectInput()})})
          _setData(getInitialValues({data: protectInput()}))
        }
        useToast &&
          Swal.fire({
            icon: 'success',
            title: route.onFeedbackSuccessMessage || 'Berhasil',
          })
      } else if (route.type === 'put') {
        if (route.withServerData) {
          json = {...json, ...serverData}
        }
        result = await putData(json, url)
        route.onFeedback && route.onFeedback({status: result?.status, data: json})
        useToast &&
          Swal.fire({
            icon: 'success',
            title: 'Berhasil mengubah data',
          })
      } else {
        result = await postData(json, url)
        route.onFeedback && route.onFeedback({status: result?.status, data: json})
        useToast &&
          Swal.fire({
            icon: 'success',
            title: 'Berhasil menambah data',
          })
      }
      if (result?.status) {
        if (route.redirect) {
          navigate(-1)
          reduxDispatch(postTable({...reduxTableData, refresh: true}))
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: route.useServerMessage
            ? result?.message || 'Mohon cek kembali data anda.'
            : 'Mohon cek kembali data anda.',
        })
      }
    } catch (ex) {
      Swal.fire({
        icon: 'error',
        title: 'Mohon cek kembali data anda.',
      })
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    validateForm()
    updateTotalData()
    updateBindInputApi()
  }, [validationSchema])

  useEffect(() => {
    if (
      (route?.url && route?.type === 'put') ||
      (route?.url && Object.keys(route?.data || {}).length > 0)
    ) {
      loadData()
    } else {
      if (isLoading) {
        setLoading(isLoading)
      } else {
        setLoading(false)
      }
    }
  }, [route?.url, isLoading])

  const onFormSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    let beforeErrorList: any = {}
    if (onBeforeSubmit) {
      await onBeforeSubmit({
        data: {
          _data: {
            get: _data,
            getObject: await JsonDataBuilder({
              data: protectInput(),
              _data: _data,
              total: total,
              value: _data,
            }),
          },
          _dataIndicator: {
            get: _dataIndicator,
          },
          _apiData: {
            get: _apiData,
            server: serverData,
          },
        },
        submit: async ({values}) => {
          let listKey: any = {}
          for (const l of Object.keys(values || {})) {
            if (values[l] !== _data[l]) {
              listKey[l] = (inputKey[l] || 0) + 1
            }
          }
          setInputKey({...inputKey, ...listKey})
          _setData(values)
          resetForm({values: values})
          beforeErrorList = await formik.validateForm(values)
        },
      })
    }
    let isError: boolean
    const defaultError =
      !formik.isValid ||
      Object.keys(formik.errors).length > 0 ||
      Object.keys(inputErrorMessage).length > 0
    const beforeError = Object.keys(beforeErrorList).length > 0
    if (onBeforeSubmit) {
      isError = beforeError
    } else {
      isError = defaultError
    }
    setFormSubmitError(isError)
    if (isError) {
      e?.preventDefault()
    } else {
      formik.handleSubmit(e)
    }
  }

  return (
    <form
      id={name || 'forminput'}
      onSubmit={onFormSubmit}
      method='post'
      className={`form container position-relative ${className ? className : ''} ${
        isModal ? '' : 'min-h-100px'
      }`}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
        }
      }}
    >
      {formError || loading ? (
        <div
          className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-light bg-opacity-75'
          style={{
            zIndex: 1,
          }}
        >
          <div className='d-flex flex-column align-items-center gap-4'>
            {formError && (
              <>
                Gagal Memuat Data
                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={() => {
                    loadData()
                  }}
                >
                  Refresh Halaman
                </button>
              </>
            )}
            {loading && (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className='p-2'>
            <div className='row g-4'>
              {protectInput().map((l, i) => {
                const _core: FormInputProps & InputListOptionProps = {
                  formName: name,
                  name: l.name,
                  disabled: l.disabled,
                  className: l.className,
                  hide: l.hide,
                  removeOnSubmit: l.removeOnSubmit,
                  lockData: l.lockData,
                  type: l.type,
                  options: l.options,
                  value: l.value,
                  listData: l.listData,
                  route: route,
                  validator: l.validator,
                  component: l.component,
                  onFeedback: onFeedback,
                  onLoad: onLoad,
                  core: {
                    _data: {
                      value: _data,
                      post: _updateData,
                    },
                    _dataIndicator: {
                      value: _dataIndicator,
                      post: _updateDataIndicator,
                    },
                    _apiData: {
                      value: _apiData,
                      post: _updateApiData,
                      server: serverData,
                    },
                    total: {
                      value: total,
                    },
                    input: {
                      value: protectInput(),
                    },
                    formik: formik,
                    formError: {
                      value: formSubmitError,
                      post: setFormSubmitError,
                    },
                    setError: errorForm,
                    errorMessage: inputErrorMessage,
                    refreshData: {
                      value: inputRefreshData,
                      post: updateInputRefreshData,
                    },
                    formLock: lockForm,
                  },
                }
                return (
                  <Fragment key={i}>
                    {(l.component?.length || 0) > 0 ? (
                      <Fragment key={inputKey[_core.name] || 0}>
                        <ComponentInput
                          name={_core.name}
                          data={_core}
                          component={l.component || []}
                        ></ComponentInput>
                      </Fragment>
                    ) : (
                      <InputList {..._core} />
                    )}
                  </Fragment>
                )
              })}
            </div>
            {(formError || formSubmitError) && (
              <div className='fv-plugins-message-container mx-2 mt-2 px-4 py-2 border border-danger rounded bg-danger bg-opacity-10'>
                <div className='fv-help-block d-flex align-items-center gap-2'>
                  <ReactIcon icon='RiErrorWarningLine' props={{className: 'fs-2'}} />
                  <div className='fw-semibold'>Mohon cek kembali form anda.</div>
                </div>
              </div>
            )}
            {(!options?.actionButton?.submit?.hide || !options?.actionButton?.cancel?.hide) && (
              <div
                className={`mt-4 d-flex ${
                  options?.actionButton?.align === 'end' ? 'justify-content-end' : ''
                } gap-2`}
              >
                {!options?.actionButton?.submit?.hide && (
                  <>
                    {!lockForm && (
                      <ButtonLoading
                        icon='RiSaveLine'
                        title={{button: options?.actionButton?.submit?.title || 'Simpan'}}
                        loading={loading || formik.isSubmitting}
                        props={{type: 'submit'}}
                      />
                    )}
                  </>
                )}
                {!options?.actionButton?.cancel?.hide && (
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={async () => {
                      let result: {status: boolean; type: string} = {
                        status: false,
                        type: '',
                      }
                      if (options?.actionButton?.cancel?.confirm?.enabled) {
                        const json: any = await PopupConfirm({
                          typeData: 'object',
                          hasConfirm: false,
                          confirmText: {
                            title:
                              options?.actionButton?.cancel?.confirm?.title ||
                              'Data belum disimpan, apakah ingin menyimpan data?',
                            description: '',
                          },
                          successText: {
                            title:
                              options?.actionButton?.cancel?.confirm?.success ||
                              'Berhasil menyimpan data',
                          },
                        })
                        result = {
                          status:
                            (json?.swal?.isDismissed && json?.swal?.dismiss === 'cancel') ||
                            json?.status,
                          type: json?.swal?.dismiss || 'confirm',
                        }
                      } else {
                        result = {
                          status: true,
                          type: 'cancel',
                        }
                      }
                      if (result.status) {
                        if (isModal) {
                          if (result.type === 'cancel') {
                            route.onFeedback && route.onFeedback({status: false})
                          }
                        } else {
                          if (result.type === 'confirm') {
                            onFormSubmit()
                          } else {
                            navigate(-1)
                          }
                        }
                      }
                    }}
                  >
                    <span className='indicator-label'>
                      {options?.actionButton?.cancel?.title || 'Batal'}
                    </span>
                  </button>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {isDebugging && (
        <>
          <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
            <code>Data: {JSON.stringify(_data)}</code>
          </pre>
          <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
            <code>Error: {JSON.stringify(inputErrorMessage)}</code>
          </pre>
          <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
            <code>Formik: {JSON.stringify(formik.values)}</code>
          </pre>
          <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
            <code>Formik Error: {JSON.stringify(formik.errors)}</code>
          </pre>
        </>
      )}
    </form>
  )
}

export default FormInput
