import {da, ro} from 'date-fns/locale'
import {NullProof} from '../../../../../helper/AppFunction'
import {formatDate} from '../../../../../helper/DateFns'
import {BorderStyle, CellStyle, Workbook, createWorkbook} from '../../../../../helper/ReactExcelJs'
import {PdfLayoutDataProps} from '../../../../../helper/ReactPdf'


const WorksheetRinci = (wb: Workbook, data: any) => {
  // More Info: https://www.npmjs.com/package/exceljs
  // Init ExcelJS
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({title: 'Implementasi', options: {views:[ {state: 'frozen', xSplit: 6, ySplit:8, activeCell: 'A1', showGridLines:false} ]}})

  // Init Template Theme
  const themeColor = {
    ungu: '60497A', // Format Color: HEX without #
    kuning: 'ffff00',
  }
  type themeStyleProps = {
    style: CellStyle
    border: BorderStyle
  }
  const themeStyle: themeStyleProps = {
    style: {
      backgroundColor: themeColor.ungu,
      color: themeColor.kuning,
      fontConfig: {
        wrap: true,
        alignment: {
          h: 'center',
        },
      },
    },
    border: {
      color: themeColor.kuning,
    },
  }

  // Init Column Width
  const columnWidths: any = {
    A: 7,
    B: 10,
    C: 20,
    D: 30,
    E: 40,
    F: 15,
    G: 40,
    H: 40,
    I: 40,
    J: 40,
    K: 40,
    L: 40,
    M: 40,
    N: 40,
    O: 40,
    P: 10,
    Q: 10,
    R: 10,
    S: 10,
    T: 10,
    U: 10,
    V: 10,
    W: 10,
    X: 10,
  }
  setupWidth(columnWidths)
  // const imageId = wb.workbook.addImage({
  //   filename: data.perusahaan.logo, // Replace with the path to your image file
  //   extension: 'png',
  // });

  // // Add the image to a cell
  // worksheet.addImage(imageId, {
  //   ext: {height: 50, width: 50},
  //   tl: {col: 2, row: 3},
  // })
  const styleHeaderMerge = ({size=10, isMerge=false, textCenter=true, isBold=false, backgroundColor='ffffff', isBorder=true}:{size?:number, isMerge?:boolean, textCenter?:boolean, isBold?:boolean, backgroundColor?:string, isBorder?:boolean}) => { 
    const initialStyle: { style: CellStyle, options?: { isMerged: boolean }, border?: BorderStyle} = {style:{backgroundColor: backgroundColor, fontConfig:{size:size, name:'Arial', style: !isBold ? 'normal' : 'bold'}}}
    if(textCenter) initialStyle.style.fontConfig = {alignment: {h:'center', v:'middle'}, size:size, name:'Arial', style: !isBold ? 'normal' : 'bold'}
    if(isMerge) initialStyle.options = {isMerged:true}
    if(isBorder) initialStyle.border = {color: '000000', style: 'thin', borderTop: true, borderBottom: true, borderLeft: true, borderRight: true}

    return initialStyle
  }
    
  cell({id: 'A1:C1', ...styleHeaderMerge({isMerge:true, size:14, textCenter:false, isBold:true, isBorder:false})}).value = 'URAIAN TARGET';
  cell({id: 'A2:D2', ...styleHeaderMerge({isMerge:true, size:14, textCenter:false, isBold:true, isBorder:false})}).value = 'PAKTA INTEGRITAS KOMITMEN KERJA';
  cell({id: 'A3:C3', ...styleHeaderMerge({isMerge:true, size:14, textCenter:false, isBold:true, isBorder:false})}).value = 'TAHUN 2024';
  cell({id: 'A5:C5', ...styleHeaderMerge({isMerge:true, size:11, textCenter:false, isBorder:false})}).value = 'Nama Satuan Kerja';
  cell({id: 'D5:E5', ...styleHeaderMerge({isMerge:true, size:11, textCenter:false, isBorder:false})}).value = `: ${NullProof({input: data.data, params:'satker.nama_satker'})}`;
  
  cell({id: 'A7:A8', ...styleHeaderMerge({isMerge:true, size:11, backgroundColor:'ffff00', isBold:true})}).value = 'No';
  cell({id: 'B7:B8', ...styleHeaderMerge({isMerge:true, size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Kode';
  cell({id: 'C7:D8', ...styleHeaderMerge({isMerge:true, size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Komitmen Kerja (Program/Kegiatan)';
  cell({id: 'E7:E8', ...styleHeaderMerge({isMerge:true, size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Indikator Keberhasilan';
  cell({id: 'F7:F8', ...styleHeaderMerge({isMerge:true, size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Target (Bulan)';
  cell({id: 'G7:O7', ...styleHeaderMerge({isMerge:true, size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Uraian Target Bulanan';
  cell({id: 'P7:X7', ...styleHeaderMerge({isMerge:true, size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Presentase Target Bulanan (%)';

  cell({id: 'G8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Februari';
  cell({id: 'H8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Maret';
  cell({id: 'I8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'April';
  cell({id: 'J8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Mei';
  cell({id: 'K8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Juni';
  cell({id: 'L8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Juli';
  cell({id: 'M8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Agustus';
  cell({id: 'N8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'September';
  cell({id: 'O8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Oktober';
  cell({id: 'P8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Feb';
  cell({id: 'Q8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Mar';
  cell({id: 'R8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Apr';
  cell({id: 'S8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Mei';
  cell({id: 'T8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Jun';
  cell({id: 'U8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Jul';
  cell({id: 'V8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Agt';
  cell({id: 'W8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Sep';
  cell({id: 'X8', ...styleHeaderMerge({size:11, backgroundColor:'ffff00', isBold:true})}).value = 'Okt';
  let rowIndex = 9
  {NullProof({input: data.data, params:'komitmen_kerja'}).map((item: any, index: number) => {
    const lengthIndikator = item.indikator?.length ? item.indikator.length : 0
    cell({id: `A${rowIndex}`, ...styleHeaderMerge({})}).value = index + 1
    cell({id: `B${rowIndex}`, ...styleHeaderMerge({})}).value = item.kode
    cell({id: `C${rowIndex}:D${rowIndex}`, ...styleHeaderMerge({isMerge:true ,textCenter:false})}).value = item.kegiatan
    item.indikator && NullProof({input: item, params:'indikator'})?.map((indikator: any, indexIndikator: number) => {
      cell({id: `A${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})})
      cell({id: `B${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})})
      cell({id: `C${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})})
      cell({id: `D${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})})
      cell({id: `E${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})}).value = indikator.indikator
      cell({id: `F${rowIndex+indexIndikator}`, ...styleHeaderMerge({})}).value = indikator.target
      cell({id: `G${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})}).value = indikator.uraian_target_bulanan?.uraian_februari
      cell({id: `H${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})}).value = indikator.uraian_target_bulanan?.uraian_maret
      cell({id: `I${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})}).value = indikator.uraian_target_bulanan?.uraian_april 
      cell({id: `J${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})}).value = indikator.uraian_target_bulanan?.uraian_mei
      cell({id: `K${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})}).value = indikator.uraian_target_bulanan?.uraian_juni
      cell({id: `L${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})}).value = indikator.uraian_target_bulanan?.uraian_juli
      cell({id: `M${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})}).value = indikator.uraian_target_bulanan?.uraian_agustus
      cell({id: `N${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})}).value = indikator.uraian_target_bulanan?.uraian_september
      cell({id: `O${rowIndex+indexIndikator}`, ...styleHeaderMerge({textCenter:false})}).value = indikator.uraian_target_bulanan?.uraian_oktober 
      cell({id: `P${rowIndex+indexIndikator}`, ...styleHeaderMerge({})}).value = `${indikator.uraian_target_bulanan?.presentase_februari > 0 ? `${indikator.uraian_target_bulanan?.presentase_febru + '%'}` : ''}`
      cell({id: `Q${rowIndex+indexIndikator}`, ...styleHeaderMerge({})}).value = `${indikator.uraian_target_bulanan?.presentase_maret > 0 ? `${indikator.uraian_target_bulanan?.presentase_maret + '%'}` : ''}`
      cell({id: `R${rowIndex+indexIndikator}`, ...styleHeaderMerge({})}).value = `${indikator.uraian_target_bulanan?.presentase_april > 0 ? `${indikator.uraian_target_bulanan?.presentase_april + '%'}` : ''}`
      cell({id: `S${rowIndex+indexIndikator}`, ...styleHeaderMerge({})}).value = `${indikator.uraian_target_bulanan?.presentase_mei > 0  ? `${indikator.uraian_target_bulanan?.presentase_mei + '%'}` : ''}`
      cell({id: `T${rowIndex+indexIndikator}`, ...styleHeaderMerge({})}).value = `${indikator.uraian_target_bulanan?.presentase_juni > 0  ? `${indikator.uraian_target_bulanan?.presentase_juni + '%'}` : ''}`
      cell({id: `U${rowIndex+indexIndikator}`, ...styleHeaderMerge({})}).value = `${indikator.uraian_target_bulanan?.presentase_juli > 0  ? `${indikator.uraian_target_bulanan?.presentase_juli + '%'}` : ''}`
      cell({id: `V${rowIndex+indexIndikator}`, ...styleHeaderMerge({})}).value = `${indikator.uraian_target_bulanan?.presentase_agustus > 0  ? `${indikator.uraian_target_bulanan?.presentase_agust + '%'}` : ''}`
      cell({id: `W${rowIndex+indexIndikator}`, ...styleHeaderMerge({})}).value = `${indikator.uraian_target_bulanan?.presentase_september > 0  ? `${indikator.uraian_target_bulanan?.presentase_septe + '%'}` : ''}`
      cell({id: `X${rowIndex+indexIndikator}`, ...styleHeaderMerge({})}).value = `${indikator.uraian_target_bulanan?.presentase_oktober > 0  ? `${indikator.uraian_target_bulanan?.presentase_oktob + '%'}` : ''}`
    })
    rowIndex += lengthIndikator
    })
  }
  
}

const ExcelLayout = (data: PdfLayoutDataProps) => {
  const downloadExcel = async () => {
    const wb = createWorkbook()
    WorksheetRinci(wb, data)
    wb.download()
  }
  return downloadExcel
}

export default ExcelLayout
