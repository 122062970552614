/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import {CardUi} from '../../../../helper/AppUi'
import FormInput from '../../../../helper/FormInput'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {formInputImportList, formInputList} from './InputLayout'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {getData} from '../../../../helper/FormAxios'
import ExcelLayoutCsv from '../../../../helper/template/ExcelObjectCsv'

const onChange = (e: any) => {
  const data = e?._data.get
  const dataObject = e?._data.getObject

  let result: any = {...data}
  result['tiket_bst_full'] =
    Number(result.tiket_transport) + Number(result.bst_kantor_sbm) + Number(result.bst_lokasi_sbm)
  if (result['tiket_transport_bisnis'] !== '') {
    if (result['tiket_transport_bisnis'] === 0) {
      result['tiket_bst_full_bisnis'] =
        Number(result.tiket_transport_bisnis) +
        Number(result.bst_kantor_sbm) +
        Number(result.bst_lokasi_sbm)
    }
    // e?.resetForm({values: result})
  }
  e?.resetForm({values: result})
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Provinsi' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'provinsi'})}
                  </TableColumn>
                  <TableColumn label='Kota' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'kota_bandara'})}
                  </TableColumn>
                  <TableColumn label='Tahun' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'tahun'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const ImportWrapper: FC = () => {
  const titlePage = 'Import Data Excel'
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>{titlePage}</PageTitle>
      <CardUi
        title={titlePage}
        toolbars={
          <>
            <button
              className='btn btn-sm btn-primary'
              onClick={async () => {
                const result = await getData('get=all', InfoData.api)
                if (result?.status) {
                  ExcelLayoutCsv(result?.data)()
                }
              }}
            >
              Download Excel Terbaru
            </button>
          </>
        }
      >
        <FormInput
          input={formInputImportList}
          route={{
            url: InfoData.api,
            type: 'post',
            isFeedback: true,
            onFeedback: (e: any) => {
              console.log(e)
            },
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tahun'}}>
            {NullProof({input: data, params: 'tahun'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Provinsi'}}>
            {NullProof({input: data, params: 'provinsi'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kota'}}>
            {NullProof({input: data, params: 'kota_bandara'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'BST Kantor'}}>
            {ConvertCurrency(
              NullProof({input: data, params: 'bst_kantor_sbm', isLabel: false}) || 0
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'BST Lokasi'}}>
            {ConvertCurrency(
              NullProof({input: data, params: 'bst_lokasi_sbm', isLabel: false}) || 0
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tiket Transport'}}>
            {ConvertCurrency(
              NullProof({input: data, params: 'tiket_transport', isLabel: false}) || 0
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tiket BST Full'}}>
            {ConvertCurrency(
              NullProof({input: data, params: 'tiket_bst_full', isLabel: false}) || 0
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tiket Transport Bisnis'}}>
            {ConvertCurrency(
              NullProof({input: data, params: 'tiket_bst_full_bisnis', isLabel: false}) || 0
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tiket BST Full Bisnis'}}>
            {ConvertCurrency(
              NullProof({input: data, params: 'tiket_transport_bisnis', isLabel: false}) || 0
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Uang Harian'}}>
            {ConvertCurrency(NullProof({input: data, params: 'uang_harian', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Biaya Penginapan Eselon I'}}>
            {ConvertCurrency(NullProof({input: data, params: 'eselon1', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Biaya Penginapan Eselon II'}}>
            {ConvertCurrency(NullProof({input: data, params: 'eselon2', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Biaya Penginapan Eselon III / Golongan IV'}}>
            {ConvertCurrency(NullProof({input: data, params: 'eselon3_gol4', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Biaya Penginapan Eselon IV / Golongan III/II/I'}}>
            {ConvertCurrency(
              NullProof({input: data, params: 'eselon4_gol123', isLabel: false}) || 0
            )}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper, ImportWrapper}
