import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfSignatureBuilder,
  PdfStyle,
  PdfStylePallete,
  PdfTabCol,
} from '../../../../../helper/ReactPdf'
import {formatDate} from '../../../../../helper/DateFns'
import {AlphabetIndex, NullProof, capitalizeEachWord} from '../../../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  const twoPagesMode: boolean = data.data?.kepada?.length > 6 ? true : false
  const LampiranPages = () => {
    return (
      <>
        <Page
          size={'FOLIO'}
          wrap={false}
          style={{
            ...PdfStyle.page,
            position: 'relative',
            gap: '5px',
          }}
        >
          <PdfHeaderApp data={data} />
          <View
            style={{
              height: '2px',
              backgroundColor: '#000000',
              // paddingHorizontal: '100px',
              width: '90%',
              marginHorizontal: 'auto',
            }}
          ></View>
          <View
            style={{
              marginHorizontal: 'auto',
              width: '90%',
              fontFamily: 'Arial',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                width: '100%',
              }}
            >
              <Text>Lampiran Surat Tugas</Text>
              <View style={{flexDirection: 'row'}}>
                <Text style={{width: '20%'}}>Nomor</Text>
                <Text style={{width: '2%'}}>:</Text>
                <Text style={{width: '78%'}}>
                  {NullProof({input: data.data, params: 'nomor_surat', label: 'Nomor'})}
                </Text>
              </View>
              <View style={{flexDirection: 'row'}}>
                <Text style={{width: '20%'}}>Tanggal</Text>
                <Text style={{width: '2%'}}>:</Text>
                <Text style={{width: '78%'}}>
                  {formatDate({date: data.data?.tanggal, dateFormat: 'dd MMMM yyyy'})}
                </Text>
              </View>
              <Text style={{marginTop: '10px', textAlign: 'justify', marginHorizontal: '30px'}}>
                Nama-nama Pegawai{' '}
                {NullProof({input: data.data, params: 'deskripsi_kegiatan', label: 'Deskripsi'})}{' '}
                {NullProof({input: data.data, params: 'tujuan', isMap: true})?.map(
                  (l: any, i: number) => (
                    <Fragment key={i}>
                      <View
                        style={{
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <Text style={{width: '95%', textAlign: 'justify'}}>
                          {capitalizeEachWord(
                            NullProof({
                              input: l,
                              params: 'satker.nama_satker',
                              label: 'satker',
                            })?.toLowerCase()
                          )}
                          {', '}
                          {!l.kota2 && l.satker2 && (
                            <Text style={{width: '95%', textAlign: 'justify'}}>
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'satker.nama_satker2',
                                  label: 'satker2',
                                })?.toLowerCase()
                              )}
                              {', '}
                            </Text>
                          )}
                          {!l.kota3 && l.satker3 && (
                            <Text style={{width: '95%', textAlign: 'justify'}}>
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'satker.nama_satker3',
                                  label: 'satker3',
                                })?.toLowerCase()
                              )}
                              {', '}
                            </Text>
                          )}
                          {capitalizeEachWord(
                            NullProof({input: l, params: 'kota', label: 'kota'})?.toLowerCase()
                          )}{' '}
                        </Text>
                        {l.kota2 && NullProof({input: l, params: 'kota2'}) !== '' && (
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {l.kota3 ? ', ' : 'dan '}
                            {capitalizeEachWord(
                              NullProof({
                                input: l,
                                params: 'satker.nama_satker2',
                                label: 'satker2',
                              })?.toLowerCase()
                            )}
                            {', '}
                            {capitalizeEachWord(
                              NullProof({
                                input: l,
                                params: 'kota2',
                                label: 'kota2',
                              })?.toLowerCase()
                            )}{' '}
                          </Text>
                        )}
                        {l.kota3 && NullProof({input: l, params: 'kota3'}) !== '' && (
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            dan{' '}
                            {capitalizeEachWord(
                              NullProof({
                                input: l,
                                params: 'satker.nama_satker3',
                                label: 'satker3',
                              })?.toLowerCase()
                            )}
                            {', '}
                            {capitalizeEachWord(
                              NullProof({
                                input: l,
                                params: 'kota3',
                                label: 'kota3',
                              })?.toLowerCase()
                            )}
                            ,{' '}
                          </Text>
                        )}
                        <Text style={{width: '95%', textAlign: 'justify'}}>
                          {', Provinsi '}
                          {capitalizeEachWord(
                            NullProof({
                              input: l,
                              params: 'provinsi',
                              label: 'kota3',
                            })?.toLowerCase()
                          )}
                        </Text>
                      </View>
                    </Fragment>
                  )
                )}
              </Text>
            </View>
            <View
              style={{
                marginTop: '10px',
                marginHorizontal: '30px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  borderBottom: '1px solid black',
                  borderTop: '1px solid black',
                }}
              >
                <Text
                  style={{
                    width: '10%',
                    borderRight: '1px solid black',
                    borderLeft: '1px solid black',
                    textAlign: 'center',
                    fontWeight: 700,
                  }}
                >
                  NO
                </Text>
                <Text
                  style={{
                    width: '60%',
                    borderRight: '1px solid black',
                    textAlign: 'center',
                    fontWeight: 700,
                  }}
                >
                  Nama
                </Text>
                <Text
                  style={{
                    width: '40%',
                    borderRight: '1px solid black',
                    textAlign: 'center',
                    fontWeight: 700,
                  }}
                >
                  Jabatan
                </Text>
                <Text
                  style={{
                    width: '30%',
                    borderRight: '1px solid black',
                    textAlign: 'center',
                    fontWeight: 700,
                  }}
                >
                  Waktu Tugas
                </Text>
              </View>
              {NullProof({input: data.data, params: 'kepada', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                        borderBottom: '1px solid black',
                      }}
                    >
                      <Text
                        style={{
                          width: '10%',
                          textAlign: 'center',
                          borderRight: '1px solid black',
                          borderLeft: '1px solid black',
                        }}
                      >
                        {i + 1}
                      </Text>
                      <View style={{width: '60%', borderRight: '1px solid black'}}>
                        <Text>
                          {' '}
                          {capitalizeEachWord(
                            NullProof({
                              input: l,
                              params: 'pegawai.nama',
                              label: 'Nama',
                            })?.toLowerCase()
                          )}
                        </Text>
                        <Text> {NullProof({input: l, params: 'pegawai.nip', label: 'NIP'})}</Text>
                      </View>
                      <Text
                        style={{
                          width: '40%',
                          textAlign: 'center',
                          borderRight: '1px solid black',
                          paddingVertical: '10px',
                        }}
                      >
                        {' '}
                        {NullProof({input: l, params: 'jabatan', label: 'Jabatan'})}
                      </Text>
                      <Text
                        style={{
                          width: '30%',
                          textAlign: 'center',
                          borderRight: '1px solid black',
                          paddingVertical: '10px',
                        }}
                      >
                        {' '}
                        {NullProof({
                          input: l,
                          params: 'hari',
                          label: 'Hari',
                          isLabel: false,
                        }) || 0}{' '}
                        Hari
                      </Text>
                    </View>
                  </Fragment>
                )
              )}
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: '20px',
                marginRight: '100px',
              }}
            >
              <PdfSignatureBuilder
                data={[
                  {
                    line1: `${NullProof({
                      input: data.data,
                      params: 'lokasi',
                    })}, ${NullProof({input: data.data, params: 'tanggal', type: 'date'})}`,
                    line2: `${NullProof({
                      input: data.data,
                      params: 'penandatangan_2_detail.atas_nama',
                    })}`,
                    line3: `${NullProof({
                      input: data.data,
                      params: 'penandatangan_2_detail.jabatan',
                    })}`,
                    typeSignature: 'string',
                    signature: '^',
                    name: `${capitalizeEachWord(
                      NullProof({input: data.data, params: 'penandatangan_2.nama'}).toLowerCase()
                    )}`,
                    align: 'flex-start',
                  },
                ]}
                options={{
                  align: 'flex-end',
                  data: {
                    align: 'flex-start',
                    signature: {style: {textAlign: 'left', marginLeft: '20pt'}},
                    line2StyleView: {marginBottom: 0},
                    line2Style: {textAlign: 'left', marginLeft: '-20pt'},
                    line3Style: {textAlign: 'left'},
                  },
                }}
                style={{paddingRight: '0', marginBottom: '50pt'}}
              />
            </View>
          </View>
          {data.data?.tembusan?.length > 0 && (
            <View
              style={{
                marginBottom: '15px',
                width: '90%',
                marginHorizontal: 'auto',
              }}
            >
              <Text>Tembusan</Text>
              {/* <Text>Inspektur Jenderal Kementerian Agama</Text> */}

              {NullProof({input: data.data, params: 'tembusan', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      <Text style={{width: '5%', textTransform: 'lowercase'}}>
                        {AlphabetIndex(i)}.
                      </Text>
                      <Text style={{width: '95%', textAlign: 'justify'}}>
                        {NullProof({input: l, params: 'detail', label: 'Detail'})}
                      </Text>
                    </View>
                  </Fragment>
                )
              )}
            </View>
          )}
          <View
            style={{
              padding: '2pt',
              border: '2px solid black',
              fontWeight: 700,
              width: '90%',
              marginHorizontal: 'auto',
            }}
          >
            <PdfTabCol
              type='text'
              border={{}}
              style={{view: {padding: '5pt', justifyContent: 'center', textAlign: 'left'}}}
              noWrap
            >
              Pegawai Inspektorat Jenderal Kementerian Agama tidak menerima GRATIFIKASI dalam
              melaksanakan tugas.
            </PdfTabCol>
          </View>
          <View style={{paddingBottom: '100px'}}></View>
        </Page>
      </>
    )
  }
  return (
    <Document title='Dokumen'>
      <Page
        size={'A4'}
        wrap={false}
        style={{
          ...PdfStyle.page,
          position: 'relative',
          gap: '5px',
        }}
      >
        <PdfHeaderApp data={data} />
        <View
          style={{
            height: '2px',
            backgroundColor: '#000000',
          }}
        ></View>
        <View
          style={{
            marginHorizontal: 'auto',
            width: '95%',
            justifyContent: 'center',
            letterSpacing: '0.5px',
          }}
        >
          <View
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Text
              style={{
                textTransform: 'uppercase',
              }}
            >
              Surat Tugas
            </Text>
            <Text>
              NOMOR: {NullProof({input: data.data, params: 'nomor_surat', label: 'Nomor Surat'})}
            </Text>
          </View>
          <View style={{}}>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: '5px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '15%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '95%'}}>Menimbang</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '85%',
                }}
              >
                {NullProof({input: data.data, params: 'menimbang', isMap: true})?.map(
                  (l: any, i: number) => (
                    <Fragment key={i}>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <Text style={{width: '5%', textTransform: 'lowercase'}}>
                          {AlphabetIndex(i)}.
                        </Text>
                        <Text style={{width: '95%', textAlign: 'justify'}}>
                          {NullProof({input: l, params: 'detail', label: 'Detail'})}
                        </Text>
                      </View>
                    </Fragment>
                  )
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: '5px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '15%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '90%'}}>Dasar</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '85%',
                }}
              >
                {NullProof({input: data.data, params: 'dasarnya', isMap: true})?.map(
                  (l: any, i: number) => (
                    <Fragment key={i}>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <Text style={{width: '5%', textTransform: 'lowercase'}}>{i + 1}.</Text>
                        <Text style={{width: '95%', textAlign: 'justify'}}>
                          {NullProof({input: l, params: 'detail', label: 'Detail'})}{' '}
                          {data.data.dasarnya[data.data.dasarnya.length - 1] === l
                            ? ` MAK ${NullProof({input: data.data, params: 'mak'})}`
                            : ''}
                        </Text>
                      </View>
                    </Fragment>
                  )
                )}
              </View>
            </View>
            <Text style={{width: '100%', textAlign: 'center'}}>Memberi Tugas</Text>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '15%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '90%'}}>Kepada</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '85%',
                }}
              >
                {twoPagesMode ? (
                  <Text>Nama-nama sebagaimana terlampir dalam surat tugas ini</Text>
                ) : (
                  <>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      <Text style={{width: '10%'}}>No.</Text>
                      <Text style={{width: '40%'}}>Nama/NIP</Text>
                      <Text style={{width: '35%'}}>Jabatan</Text>
                      <Text style={{width: '20%', textAlign: 'center'}}>Waktu Tugas</Text>
                    </View>
                    {NullProof({input: data.data, params: 'kepada', isMap: true})
                      ?.map((ll: any, ii: number) => ({...ll}))
                      .sort((a: any, b: any) => {
                        const jabatanOrder: {[key: string]: number} = {
                          'Penanggung Jawab': 1,
                          'Pengendali Teknis': 2,
                          'Ketua Tim': 3,
                          Anggota: 4,
                          'Pengendali Mutu': 5,
                          'Ketua Sub Tim': 6,
                          'Anggota Sub Tim': 7,
                        }

                        return jabatanOrder[a.jabatan] - jabatanOrder[b.jabatan]
                      })
                      ?.map((l: any, i: number) => (
                        <Fragment key={i}>
                          <View
                            style={{
                              flexDirection: 'row',
                              width: '100%',
                            }}
                          >
                            <Text style={{width: '5%'}}>{i + 1}.</Text>
                            <View style={{width: '40%'}}>
                              <Text>
                                {capitalizeEachWord(
                                  NullProof({
                                    input: l,
                                    params: 'pegawai.nama',
                                    label: 'Nama',
                                  })?.toLowerCase()
                                )}
                              </Text>
                              <Text>
                                {NullProof({input: l, params: 'pegawai.nip', label: 'NIP'})}
                              </Text>
                            </View>
                            <Text style={{width: '35%'}}>
                              {NullProof({input: l, params: 'jabatan', label: 'Jabatan'})}
                            </Text>
                            <Text style={{width: '20%', textAlign: 'center'}}>
                              {NullProof({
                                input: l,
                                params: 'hari',
                                label: 'Hari',
                                isLabel: false,
                              }) || 0}{' '}
                              Hari
                            </Text>
                          </View>
                        </Fragment>
                      ))}
                  </>
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: '5px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '15%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '90%'}}>Untuk</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '85%',
                }}
              >
                <Text>
                  {NullProof({input: data.data, params: 'deskripsi_kegiatan', label: 'Deskripsi'})}{' '}
                  {NullProof({input: data.data, params: 'tujuan', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <View
                          style={{
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {capitalizeEachWord(
                              NullProof({
                                input: l,
                                params: 'satker.nama_satker',
                                label: 'satker',
                              })?.toLowerCase()
                            )}
                            {', '}
                            {!l.kota2 && l.satker2 && (
                              <Text style={{width: '95%', textAlign: 'justify'}}>
                                {capitalizeEachWord(
                                  NullProof({
                                    input: l,
                                    params: 'satker.nama_satker2',
                                    label: 'satker2',
                                  })?.toLowerCase()
                                )}
                                {', '}
                              </Text>
                            )}
                            {!l.kota3 && l.satker3 && (
                              <Text style={{width: '95%', textAlign: 'justify'}}>
                                {capitalizeEachWord(
                                  NullProof({
                                    input: l,
                                    params: 'satker.nama_satker3',
                                    label: 'satker3',
                                  })?.toLowerCase()
                                )}
                                {', '}
                              </Text>
                            )}
                            {capitalizeEachWord(
                              NullProof({input: l, params: 'kota', label: 'kota'})?.toLowerCase()
                            )}{' '}
                          </Text>
                          {l.kota2 && NullProof({input: l, params: 'kota2'}) !== '' && (
                            <Text style={{width: '95%', textAlign: 'justify'}}>
                              {l.kota3 ? ', ' : 'dan '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'satker.nama_satker2',
                                  label: 'satker2',
                                })?.toLowerCase()
                              )}
                              {', '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'kota2',
                                  label: 'kota2',
                                })?.toLowerCase()
                              )}{' '}
                            </Text>
                          )}
                          {l.kota3 && NullProof({input: l, params: 'kota3'}) !== '' && (
                            <Text style={{width: '95%', textAlign: 'justify'}}>
                              dan{' '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'satker.nama_satker3',
                                  label: 'satker3',
                                })?.toLowerCase()
                              )}
                              {', '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'kota3',
                                  label: 'kota3',
                                })?.toLowerCase()
                              )}
                              ,{' '}
                            </Text>
                          )}
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {', Provinsi '}
                            {capitalizeEachWord(
                              NullProof({
                                input: l,
                                params: 'provinsi',
                                label: 'kota3',
                              })?.toLowerCase()
                            )}
                          </Text>
                        </View>
                      </Fragment>
                    )
                  )}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: '5px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '15%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '90%'}}>Waktu</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '85%',
                }}
              >
                <Text>
                  Pelaksanaan tugas diantara tanggal{' '}
                  {formatDate({
                    date:
                      NullProof({input: data.data, params: 'tanggal_mulai', isLabel: false}) || '',
                    dateFormat: 'dd MMMM',
                  })}{' '}
                  s.d{' '}
                  {formatDate({
                    date:
                      NullProof({input: data.data, params: 'tanggal_selesai', isLabel: false}) ||
                      '',
                    dateFormat: 'dd MMMM yyyy',
                  })}
                </Text>
              </View>
            </View>
            <View>
              <Text>
                Laporan disampaikan kepada pemberi tugas paling lambat 10 hari setelah
                pelaksanaan tugas.
              </Text>
            </View>
            <PdfSignatureBuilder
              data={[
                {
                  line1: `${NullProof({
                    input: data.data,
                    params: 'lokasi',
                  })}, ${NullProof({input: data.data, params: 'tanggal', type: 'date'})}`,
                  line2: `${NullProof({
                    input: data.data,
                    params: 'penandatangan_2_detail.atas_nama',
                  })}`,
                  line3: `${NullProof({
                    input: data.data,
                    params: 'penandatangan_2_detail.jabatan',
                  })}`,
                  typeSignature: 'string',
                  signature: '*',
                  name: `${capitalizeEachWord(
                    NullProof({input: data.data, params: 'penandatangan_2.nama'}).toLowerCase()
                  )}`,
                  align: 'flex-start',
                },
              ]}
              options={{
                align: 'flex-end',
                data: {
                  align: 'flex-start',
                  signature: {style: {textAlign: 'left', marginLeft: '20pt'}},
                  line2StyleView: {marginBottom: 0},
                  line2Style: {textAlign: 'left', marginLeft: '-20pt'},
                  line3Style: {textAlign: 'left'},
                },
              }}
              style={{paddingRight: '0', marginBottom: '50pt'}}
            />
          </View>
        </View>
        <View style={{paddingBottom: '120px'}}></View>
        <View
          style={{
            fontFamily: 'Arial',
            position: 'absolute',
            bottom: '100pt',
            left: 0,
            width: '100vw',
            marginHorizontal: 'auto',
            padding: '2% 6%',
            letterSpacing: '0.5px',
          }}
        >
          {data.data?.tembusan?.length > 0 && (
            <View
              style={{
                marginBottom: '10px',
                padding: '0% 4%',
              }}
            >
              <Text>Tembusan</Text>
              {/* <Text>Inspektur Jenderal Kementerian Agama</Text> */}

              {NullProof({input: data.data, params: 'tembusan', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      {i > 0 && (
                        <Text style={{width: '5%', textTransform: 'lowercase'}}>
                          {AlphabetIndex(i)}.
                        </Text>
                      )}
                      <Text style={{width: '95%', textAlign: 'justify'}}>
                        {NullProof({input: l, params: 'detail', label: 'Detail'})}
                      </Text>
                    </View>
                  </Fragment>
                )
              )}
            </View>
          )}
          <View
            style={{
              padding: '10px 10px',
              margin: '2% 4%',
              border: '2px solid black',
              fontWeight: 700,
            }}
          >
            <Text style={{gap: '5px'}}>
              Pegawai Inspektorat Jenderal Kementerian Agama tidak menerima{'\n'}
              GRATIFIKASI dalam melaksanakan tugas.
            </Text>
          </View>
        </View>
        <View style={{paddingBottom: '70px'}}></View>
      </Page>
      {twoPagesMode && <LampiranPages />}
    </Document>
  )
}

export default PdfLayout
