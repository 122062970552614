import { FC } from 'react'
import { PageTitle } from '../../../../../../../_metronic/layout/core'
import PageData, { InfoData } from '../PageData'
import { CardUi } from '../../../../../../helper/AppUi'
import FormInput from '../../../../../../helper/FormInput'
import { formInputList } from '../InputLayout'

const TambahWrapper: FC = () => {
	return (
		<>
			<PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
				{PageData.tambah.page.breadcrumb.title}
			</PageTitle>
			<CardUi title={PageData.tambah.page.title}>
				<FormInput
					input={formInputList}
					route={{
						url: InfoData.api,
						type: 'post',
						redirect: true,
					}}
				/>
			</CardUi>
		</>
	)
}

export default TambahWrapper