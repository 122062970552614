import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'

const inputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'byuser=true',
        params: 'nomor_surat,nama_kegiatan',
        result: '$nomor_surat | $nama_kegiatan',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_pelaksanaan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Pelaksanaan',
          type: 'date',
          placeholder: 'Tanggal Pelaksanaan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'komponen',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Komponen',
          placeholder: 'Komponen',
        },
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'index_kka',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Index KKA',
              placeholder: 'Index KKA',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'komentar',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Komentar / Permasalahan',
              placeholder: 'Komentar / Permasalahan',
              rows: 3,
            },
          },
        },
      },
      {
        className: 'col-6',
        name: 'penyelesaian',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Penyelesaian',
              placeholder: 'Penyelesaian',
              rows: 3,
            },
          },
        },
      },
      {
        className: 'col-6',
        name: 'persetujuan',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Persetujuan',
              placeholder: 'Persetujuan',
              rows: 3,
            },
          },
        },
      },
    ],
  },
]

export {inputList}
