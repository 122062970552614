import {FC} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {getData} from './FormAxios'
import {BindDataProps} from './FormDynamic'
import {FormInputProps} from './FormInput'
import * as Yup from 'yup'
import slugify from 'slugify'
import {formatDate} from './DateFns'
import {Chance} from 'chance'
import Swal from 'sweetalert2'

const colorPalleteList = {
  primary: '#009ef7',
  success: '#50cd89',
  danger: '#f1416c',
  info: '#8385ae',
  warning: '#ffc700',
}

function isColorCode(input: string): boolean {
  const colorCodeRegex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
  return colorCodeRegex.test(input)
}

const ReactSrc: FC<{data: any} & WithChildren> = ({data = null, children}) => {
  if (data === null) {
    return <></>
  } else {
    return <>{children}</>
  }
}

const RouterQueryParams = (data: any, query: any) => {
  let _query = query
  let result = data
  Object.keys(_query).forEach((lq) => {
    result = result.replaceAll(`:${lq}`, _query[lq])
  })
  return result
}

const BreadcrumbQueryParams = (data: any) => {
  const query = useParams()
  let result: any = []
  data.map((l: any) => {
    let json = {...l}
    Object.keys(query).forEach((lq) => {
      json = {...json, path: json.path.replaceAll(`:${lq}`, query[lq])}
    })
    result.push(json)
  })
  return result
}

const DynamicUrl = ({input, params, query}: {input: string; params?: string; query: any}) => {
  const attr = params ? params.split(',') : Object.keys(query)
  let result = input
  attr.forEach((l) => {
    result = result.replaceAll(`:${l}`, `${query[l]}`)
  })
  return result
}

const ListBindDataByUrl = async ({data, query}: {data: BindDataProps[]; query: any}) => {
  let propsList: any = {}
  let dataList: any = {}
  for (const l of data) {
    let json: any = {status: true, data: {}}
    if (l.url !== '#') {
      json = await getData('', DynamicUrl({input: l.url, params: l.urlParams || '', query: query}))
    }
    if (json?.status) {
      let params = l.params.split(',')
      let resultsub = l.result
      params.forEach((lsub: any) => {
        resultsub = resultsub.replaceAll(
          `$${lsub}`,
          NullProof({input: json.data, params: lsub, isLabel: false})
        )
      })
      Object.keys(query).forEach((lsub) => {
        resultsub = resultsub.replaceAll(`:${lsub}`, query[lsub])
      })
      const listBindInput = resultsub.split(',')
      listBindInput.forEach((lsub: any) => {
        const split = lsub.split('=')
        propsList = {...propsList, [split[0]]: split[1]}
        dataList = {...dataList, [split[0]]: {bind: l.bindToInput || false, data: json.data}}
      })
    }
  }
  return {propsList, dataList}
}

function AlphabetIndex(index: number, uppercase: boolean = false): string {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'
  const base = alphabet.length

  let combination = ''
  let quotient = Math.floor(index / base)
  let remainder = index % base

  combination += alphabet.charAt(remainder)

  while (quotient > 0) {
    remainder = quotient % base
    quotient = Math.floor(quotient / base)
    combination = alphabet.charAt(remainder - 1) + combination
  }

  return uppercase ? combination.toUpperCase() : combination
}

function RomanIndex(number: number): string {
  number++
  const romanNumerals = [
    {value: 1000, numeral: 'M'},
    {value: 900, numeral: 'CM'},
    {value: 500, numeral: 'D'},
    {value: 400, numeral: 'CD'},
    {value: 100, numeral: 'C'},
    {value: 90, numeral: 'XC'},
    {value: 50, numeral: 'L'},
    {value: 40, numeral: 'XL'},
    {value: 10, numeral: 'X'},
    {value: 9, numeral: 'IX'},
    {value: 5, numeral: 'V'},
    {value: 4, numeral: 'IV'},
    {value: 1, numeral: 'I'},
  ]

  let result = ''

  for (const numeralData of romanNumerals) {
    const {value, numeral} = numeralData

    while (number >= value) {
      result += numeral
      number -= value
    }

    if (number === 0) {
      break
    }
  }

  return result
}

interface NullProofOptions {
  text?: {
    limit?: number
  }
  currency?: {}
  date?: {
    format: string
  }
}

export interface NullProofProps {
  input: any
  label?: string
  params: string
  isLabel?: boolean
  isBool?: boolean
  isMap?: boolean
  limitChar?: number
  type?: 'text' | 'currency' | 'date' | 'map' | 'html'
  option?: NullProofOptions
}

function NullProof({
  input,
  label,
  params,
  isLabel = true,
  isBool = false,
  isMap = false,
  limitChar = 0,
  option,
  type = 'text',
}: {
  input: any
  label?: string
  params: string
  isLabel?: boolean
  isBool?: boolean
  isMap?: boolean
  limitChar?: number
  type?: 'text' | 'currency' | 'date' | 'map' | 'html'
  option?: NullProofOptions
}) {
  const attr: string[] = params.split('.')
  let result: any = input
  let isNull = false
  for (const attrs of attr) {
    if (result == null) {
      isNull = true
      break
    }
    result = result[attrs]
  }
  const processData = () => {
    const limitCh = limitChar || option?.text?.limit || 0
    if (isMap) {
      if (typeof result === 'string' || typeof result === 'number') {
        return []
      } else if (Array.isArray(result)) {
        return result
      } else {
        return [{...result}]
      }
    } else if (type === 'text' || type === 'html') {
      let json: any = result
      // if (typeof json === 'object' || Array.isArray(json)) {
      //   return isLabel ? (label ? `${label}` : '_') : null
      // }
      if (limitCh > 0) {
        json = `${result.substring(0, limitCh)}${result.length > limitCh ? '...' : ''}`
      }
      if (type === 'html') {
        json = <div dangerouslySetInnerHTML={{__html: json}}></div>
      }
      return json
    } else if (type === 'currency') {
      return ConvertCurrency(result || 0)
    } else if (type === 'date') {
      return formatDate({date: result, dateFormat: option?.date?.format || 'dd MMMM yyyy'}) || '_'
    }
  }
  const nullData = () => {
    if (isMap) {
      return []
    } else if (isLabel) {
      return label ? `(${label})` : '_'
    } else {
      return null
    }
  }
  if (isBool) {
    return isNull || result == null
  } else {
    if (isNull || result == null) {
      return nullData()
    } else {
      return processData()
    }
  }
}

function letterAvatar(data: string, maxChars: number = 1) {
  const words = data.split(' ').slice(0, 2)
  const firstChars = words.map((word) => word.substring(0, maxChars))
  return firstChars.join('')
}

function getRandomColor(data: string, targetLum: number = 0.7) {
  const charCode = data.charCodeAt(0)
  const red = charCode % 256
  const green = (charCode + 50) % 256
  const blue = (charCode + 100) % 256
  const colorHex = `#${red.toString(16).padStart(2, '0')}${green
    .toString(16)
    .padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`
  const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255
  const textColor = luminance > targetLum ? '#000000' : '#ffffff'
  return {
    backgroundColor: colorHex,
    textColor: textColor,
  }
}

function getColorDecimal(data: string) {
  const color = data.replace('#', '')
  const r = color.substring(0, 2)
  const g = color.substring(2, 4)
  const b = color.substring(4, 6)
  const red = parseInt(r, 16) % 256
  const green = parseInt(g, 16) % 256
  const blue = parseInt(b, 16) % 256
  return {red, green, blue}
}

function isTextDark(data: string, percent: number = 0.7) {
  const {red, green, blue} = getColorDecimal(data)
  const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255
  return luminance > percent
}

function getTextColor(data: string, percent: number = 0.7, invert: boolean = false) {
  const textColor = isTextDark(data, percent)
    ? invert
      ? '#ffffff'
      : '#000000'
    : invert
    ? '#000000'
    : '#ffffff'
  return textColor
}

function getColorHover(data: string, intens: number = 0) {
  let {red, green, blue} = getColorDecimal(data)
  red -= intens
  green -= intens
  blue -= intens
  red = Math.max(0, red)
  green = Math.max(0, green)
  blue = Math.max(0, blue)
  const colorHex = `#${red.toString(16).padStart(2, '0')}${green
    .toString(16)
    .padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`
  return colorHex
}

function getColorPallete(data: string, light: number = 0.2) {
  let _data = data
  if ((_data || '#FFFFFF') in colorPalleteList) {
    //@ts-ignore
    _data = colorPalleteList[_data || 'primary']
  }
  if (!isColorCode(_data)) {
    _data = '#FFFFFF'
  }
  const colorHex = getColorHover(_data)
  const lightColor = lightenColor(_data, 0.9)
  const textColor = getTextColor(_data, light)
  const invertTextColor = getTextColor(_data, light, true)
  const lightTextColor = getTextColor(lightColor, light)
  return {
    backgroundColor: colorHex,
    backgroundColorHover: getColorHover(colorHex, 15),
    lightColor: lightColor,
    lightColorHover: getColorHover(lightColor, 15),
    textColor: textColor,
    invertTextColor: invertTextColor,
    lightTextColor: lightTextColor,
  }
}

function lightenColor(data: string, percent: number) {
  const {red, green, blue} = getColorDecimal(data)
  const r = Math.min(255, Math.round(red + (255 - red) * percent))
  const g = Math.min(255, Math.round(green + (255 - green) * percent))
  const b = Math.min(255, Math.round(blue + (255 - blue) * percent))
  var newHex =
    '#' +
    (r < 16 ? '0' : '') +
    r.toString(16) +
    (g < 16 ? '0' : '') +
    g.toString(16) +
    (b < 16 ? '0' : '') +
    b.toString(16)

  return newHex
}

function generateVerificationCode() {
  const min = 100000 // Minimum value (inclusive)
  const max = 999999 // Maximum value (inclusive)

  const code = Math.floor(Math.random() * (max - min + 1)) + min
  return code.toString() // Convert the code to a string
}

function generateCaptcha() {
  const num1 = 1 + Math.floor(Math.random() * 10)
  const num2 = 1 + Math.floor(Math.random() * 10)
  const sum = num1 + num2
  return {
    num1,
    num2,
    sum,
  }
}

function ConvertCurrency(number: number): string {
  const _number = parseInt((number || 0).toString())
  let result: string = _number.toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0,
  })
  return result
}

function currencyToWords(currency: number): string {
  const words: string[] = [
    '',
    'satu',
    'dua',
    'tiga',
    'empat',
    'lima',
    'enam',
    'tujuh',
    'delapan',
    'sembilan',
  ]

  function toWords(num: number): string {
    if (num < 10) {
      return words[num]
    } else if (num < 20) {
      const teens: string[] = [
        'sepuluh',
        'sebelas',
        'dua belas',
        'tiga belas',
        'empat belas',
        'lima belas',
        'enam belas',
        'tujuh belas',
        'delapan belas',
        'sembilan belas',
      ]
      return teens[num - 10]
    } else if (num < 100) {
      const tens: string[] = [
        'dua puluh',
        'tiga puluh',
        'empat puluh',
        'lima puluh',
        'enam puluh',
        'tujuh puluh',
        'delapan puluh',
        'sembilan puluh',
      ]
      return tens[Math.floor(num / 10) - 2] + ' ' + toWords(num % 10)
    } else if (num < 1000) {
      return words[Math.floor(num / 100)] + ' ratus ' + toWords(num % 100)
    } else if (num < 1000000) {
      return toWords(Math.floor(num / 1000)) + ' ribu ' + toWords(num % 1000)
    } else if (num < 1000000000) {
      return toWords(Math.floor(num / 1000000)) + ' juta ' + toWords(num % 1000000)
    } else if (num < 1000000000000) {
      return toWords(Math.floor(num / 1000000000)) + ' miliar ' + toWords(num % 1000000000)
    } else {
      return 'Angka terlalu besar untuk diubah ke kata.'
    }
  }

  if (currency === 0) {
    return 'nol rupiah'
  } else if (currency < 0 || currency >= 1000000000000000) {
    return 'Angka harus di antara 0 dan 999.999.999.999.999.'
  } else {
    let string = toWords(currency).trim() + ' rupiah'
    string = string.replaceAll('  ', ' ')
    return string
  }
}

const updateData = ({
  fieldsToUpdate,
  data,
  setData,
}: {
  fieldsToUpdate: Partial<any>
  data: any
  setData: any
}): void => {
  const updatedData = Object.assign(data, fieldsToUpdate)
  setData(updatedData)
}

export interface listVarProps {
  title: string
  desc?: any
  name: any
  group?: any
  number?: string
  isLabel?: boolean
  className?: string
}

function createGroupingFormObject(data: listVarProps[]) {
  let groupData: any = {}
  for (const l of data) {
    if (l.name) {
      if (groupData[l.name]) {
        const subGroupDataFilter = groupData[l.name].data[l.group]
        if (subGroupDataFilter) {
          subGroupDataFilter.data = [...subGroupDataFilter.data, l]
        } else {
          groupData[l.name].data[l.group] = {
            name: l.group,
            data: [l],
          }
        }
      } else {
        groupData[l.name] = {
          name: l.name,
          data: {
            [l.group]: {name: l.group, data: [l]},
          },
        }
      }
    }
  }
  return groupData
}

function createFormObjectList(
  data: listVarProps[],
  component: FormInputProps[] = [],
  type: 'input' | 'name' | 'all' = 'input'
): any {
  let json: {
    input: FormInputProps[]
    name: any[]
  } = {
    input: [],
    name: [],
  }
  let inputJson: FormInputProps[] = []
  let nameJson: string[] = []
  for (const l of data) {
    if (l.isLabel) {
      inputJson.push({
        className: l.className || 'col-12',
        name: '',
        type: 'label',
        validator: Yup.string(),
        options: {
          label: {
            name: `${l.number ? `${l.number}. ` : ''}${l.title}`,
            description: l.desc,
            labelType: 'both',
          },
        },
      })
      nameJson.push('')
    } else {
      const name = slugify(
        `${l.name ? `${l.name}_` : ''}${
          l.group && l.group !== l.number ? `${l.group.toLowerCase()}${l.number ? '' : '_'}` : ''
        }${l.number ? `${l.number.toLowerCase()}_` : ''}${l.title.substring(0, 50)}`,
        {
          replacement: '_',
          lower: true,
        }
      )
      nameJson.push(name)
      inputJson.push({
        className: l.className || 'col-12 my-2',
        name: name,
        type: 'component',
        validator: Yup.string().required('Mohon untuk diisi.'),
        value: 1,
        options: {
          input: {
            props: {
              title: `${l.number ? `${l.number}. ` : ''}${l.title}`,
              placeholder: `${l.number ? `${l.number}. ` : ''}${l.title}`,
            },
          },
          label: {
            description: l.desc,
          },
          component: {
            isObject: true,
          },
        },
        component: [...component],
      })
    }
  }
  if (type === 'all') {
    return json
  } else if (type === 'name') {
    return nameJson
  } else {
    return inputJson
  }
}

const formInputListSelector = ({
  formInputList,
  name,
}: {
  formInputList: FormInputProps[]
  name: string
}) => {
  let result: FormInputProps = formInputList.filter((l) => l.name === name)[0]
  return result
}

const objectListSelector = ({data, name, search}: {data: any[]; name: string; search: string}) => {
  let filter = data.filter((l) => l[name] === search)
  let result: any = filter.length > 0 ? filter[0] : {}
  return result
}

const numberlistPagination = ({n, p = 1, t = 10}: {n: number; p?: number; t?: number}) => {
  return n + 1 + ((p || 1) - 1) * t
}

const filenameGenerator = ({initName, uniqueId}: {initName: string; uniqueId: string}) => {
  const chance = new Chance()
  const randomName = chance.string({pool: uniqueId, length: 6})
  const date = formatDate({date: 'now', dateFormat: 'ddMMyyyyHHmmss'})
  const result = `${initName}_${date}_${randomName}`
  return result
}

const getApiPath = () => {
  const API_URL = process.env.REACT_APP_API_URL
  return API_URL
}

const getStatusColor = (title: string = '') => {
  const listStatusColor: {
    title: string
    color: string
  }[] = [
    {color: '#e74c3c', title: 'ditolak,error,gagal,false'},
    {color: '#f1c40f', title: 'revisi,warning,pending'},
    {color: '#2ecc71', title: 'disetujui,sukses,berhasil,lunas,true'},
    {color: '#3498db', title: 'menunggu'},
  ]
  let result: string = '#e74c3c'
  try {
    for (const l of listStatusColor) {
      const listTitle = l.title.split(',')
      if (listTitle.includes(title?.toLowerCase())) {
        result = l.color
        break
      }
    }
  } catch (_) {}
  return result
}

const capitalizeEachWord = (sentence: string) => {
  return sentence.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase())
}

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      const base64 = (reader.result as string).split(',')[1]
      resolve(base64)
    }
    reader.onerror = function () {
      reject(new Error('Failed to read the Blob as Base64.'))
    }
    reader.readAsDataURL(blob)
  })
}

export interface PaginationStaticDataProps {
  type?: 'string' | 'date' | 'currency' | 'map'
  ignore?: boolean
  date?: {
    format?: string
  }
}

const getPaginationStaticData = ({
  data,
  size = 10,
  page = 1,
  search,
}: {
  data: any[]
  size?: number
  page?: number
  search?: {
    value?: string
    options?: {
      [key: string]: PaginationStaticDataProps
    }
  }
}) => {
  let _data: any[] = [...data]
  if (search?.value) {
    try {
      const searchQuery = search?.value?.split(' ') || []
      _data = _data.filter((e: any) => {
        let found: boolean = false
        const listObj = Object.keys(e)
        for (const l of listObj) {
          const options = (search?.options || {})[l] || {type: 'string', ignore: false}
          if (options?.ignore || options?.type === 'map') {
            continue
          }
          let val: string = ''
          if (options?.type === 'date') {
            val = formatDate({date: e[l], dateFormat: options?.date?.format || 'dd MMMM yyyy'})
          } else if (options?.type === 'currency') {
            val = ConvertCurrency(parseInt(`${e[l]}`))
          } else {
            val = `${e[l]}`
          }
          if (
            searchQuery.every((f) => (val.toLowerCase().match(f.toLowerCase())?.length || 0) > 0)
          ) {
            found = true
            break
          }
        }
        return found
      })
    } catch (e) {}
  }
  const dataSplit = Array.from({length: Math.ceil(_data.length / size)}, (v, i) =>
    _data.slice(i * size, i * size + size)
  )
  const totalPages = dataSplit.length
  const result = {
    currentPage: page,
    data: page > 0 && page <= totalPages ? dataSplit[page - 1] : [],
    status: true,
    totalItems: _data.length,
    totalPages: totalPages,
  }
  return result
}

const convertObjectToCsv = async ({
  data,
  filename,
  ext,
}: {
  data: any
  ext?: 'xls' | 'csv'
  filename?: string
}) => {}

const PopupConfirm = async ({
  validated = false,
  hasConfirm = true,
  confirmText = {
    title: 'Apakah anda yakin untuk menghapus data ini?',
    description: 'Data yang dihapus tidak dapat dikembalikan.',
  },
  successText = {
    title: 'Berhasil menghapus data',
    description: '',
  },
  typeData = 'default',
}: {
  validated?: boolean
  hasConfirm?: boolean
  confirmText?: {
    title?: string
    description?: string
  }
  successText?: {
    title?: string
    description?: string
  }
  typeData?: 'default' | 'object'
}) => {
  let status = false
  const confirmFirst = await Swal.fire({
    icon: 'question',
    title: confirmText?.title || 'Apakah anda yakin untuk menghapus data ini?',
    text: confirmText?.description,
    showCancelButton: true,
    showCloseButton: true,
  })
  if (validated && confirmFirst?.value) {
    const captchaUi = async () => {
      const captcha = generateCaptcha()
      await Swal.fire({
        icon: 'question',
        title: `Harap untuk Konfirmasi ulang untuk menghapus\n\n${captcha.num1} + ${captcha.num2} = ?`,
        showCancelButton: true,
        input: 'text',
        preConfirm: async (inputValue) => {
          if (Number(inputValue) === captcha.sum) {
            status = true
          } else {
            await captchaUi()
          }
        },
      })
    }
    await captchaUi()
  } else {
    status = confirmFirst?.value
  }
  if (status && hasConfirm) {
    Swal.fire({
      icon: 'success',
      title: successText?.title,
    })
  }
  if (typeData === 'object') {
    return {
      status: status || false,
      swal: confirmFirst,
    }
  }
  return status || false
}

const checkFileExtension = (file: string) => {
  const fileFullPath = file.split('/')
  const filePath = fileFullPath[fileFullPath.length - 1].split('.')
  fileFullPath.splice(fileFullPath.length - 1)
  const fileExt = filePath[filePath.length - 1]
  filePath.splice(filePath.length - 1)
  return {
    path: fileFullPath.join('/'),
    name: filePath.join('-'),
    ext: fileExt,
  }
}

const kmbGenerator = (num: number): string => {
  num = parseFloat(num.toString().replace(/[^0-9.]/g, ''))
  if (num < 1000) {
    return num.toString()
  }
  const si: {v: number; s: string}[] = [
    {v: 1e3, s: 'K'},
    {v: 1e6, s: 'M'},
    {v: 1e9, s: 'B'},
    {v: 1e12, s: 'T'},
    {v: 1e15, s: 'P'},
    {v: 1e18, s: 'E'},
  ]
  let index: number
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s
}

export {
  isColorCode,
  colorPalleteList,
  ReactSrc,
  BreadcrumbQueryParams,
  RouterQueryParams,
  AlphabetIndex,
  RomanIndex,
  NullProof,
  ConvertCurrency,
  DynamicUrl,
  ListBindDataByUrl,
  currencyToWords,
  getRandomColor,
  getColorPallete,
  getColorDecimal,
  getTextColor,
  isTextDark,
  letterAvatar,
  generateCaptcha,
  generateVerificationCode,
  updateData,
  createFormObjectList,
  createGroupingFormObject,
  formInputListSelector,
  numberlistPagination,
  objectListSelector,
  filenameGenerator,
  getApiPath,
  getStatusColor,
  capitalizeEachWord,
  blobToBase64,
  getPaginationStaticData,
  convertObjectToCsv,
  PopupConfirm,
  checkFileExtension,
  kmbGenerator,
}
