import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {CardUi} from '../../../../../helper/AppUi'
import {TableAction, TableColumn, TableInput, TableRow} from '../../../../../helper/TableInput'
import {
  ConvertCurrency,
  NullProof,
  numberlistPagination,
  RouterQueryParams,
} from '../../../../../helper/AppFunction'
import {formatDate} from '../../../../../helper/DateFns'
import FormInput from '../../../../../helper/FormInput'
import {formInputList} from './InputLayout'
import {useParams} from 'react-router-dom'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../helper/TableInputDetail'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({
            input: data,
            params: 'data',
            isMap: true,
          }).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Satuan Kerja' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'evaluasi_depan.satker.nama_satker'})}
                  </TableColumn>
                  <TableColumn label='Penandatangan Komitmen' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'evaluasi_depan.penandatangan_komitmen.nama'})}
                  </TableColumn>
                  <TableColumn label='Bulan' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'bulan'})}
                  </TableColumn>
                  {/* <TableColumn label='PIC Irjen' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'pic_irjen.nama'})}
                  </TableColumn> */}
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})

  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Satuan Kerja'}}>
            {NullProof({
              input: data,
              params: 'evaluasi_depan.satker.nama_satker',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Penandatangan Komitmen'}}>
            {NullProof({
              input: data,
              params: 'evaluasi_depan.penandatangan_komitmen.nama',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Target Waktu'}}>
            {NullProof({
              input: data,
              params: 'evaluasi_depan.target_waktu',
              type: 'date',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Link Evidence'}}>
            {NullProof({
              input: data,
              params: 'link_evidence',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Bulan'}}>
            {NullProof({
              input: data,
              params: 'bulan',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Keterangan'}}>
            {NullProof({
              input: data,
              params: 'keterangan',
            })}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper}
