import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12 col-md-6',
    name: 'sp2d',
    type: 'input',
    value: '',
    validator: Yup.string().required('SP2D Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SP2D',
          placeholder: 'SP2D',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-3',
    name: 'spm',
    type: 'input',
    value: '',
    validator: Yup.string().required('SPM Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SPM',
          placeholder: 'SPM',
          maxLength: 5,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-3',
    name: 'akun',
    type: 'input',
    value: '',
    validator: Yup.string().required('Akun Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Akun',
          placeholder: 'Akun',
          maxLength: 4,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'nilai_spm',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Nilai SPM',
          placeholder: 'Nilai SPM',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'nilai_sp2d',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Nilai SP2D',
          placeholder: 'Nilai SP2D',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8',
    name: 'tanggal_sp2d',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal SP2D Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal SP2D',
          placeholder: 'Tanggal SP2D',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'bln',
    type: 'input',
    value: '',
    validator: Yup.number().required('Bulan Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Bulan',
          placeholder: 'Bulan',
          type: 'number',
          min: 1,
          max: 12,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'ket',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Keterangan Wajib diisi.'),
    options: {
      textarea: {
        props: {
          title: 'Keterangan',
          placeholder: 'Keterangan',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'nominatif',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Nominatif',
          placeholder: 'Nominatif',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'saldo_spm',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Saldo SPM',
          placeholder: 'Saldo SPM',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'cek',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Ya',
        value: 'true',
      },
      {
        title: 'Tidak',
        value: 'false',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Cek',
          placeholder: 'Cek',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'detail',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Detail wajib diisi').required('Detail wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Detail',
          placeholder: 'Detail',
        },
      },
      component: {
        useAccordion: true,
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'tanggal',
        type: 'input',
        value: '',
        validator: Yup.string().required('Tanggal Wajib diisi.'),
        options: {
          input: {
            props: {
              title: 'Tanggal',
              placeholder: 'Tanggal',
              type: 'date',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'saldo',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Saldo',
              placeholder: 'Saldo',
              type: 'number',
            },
          },
        },
      },
    ],
  },
]

export {formInputList}
