import {PageDataContent} from '../../../../../helper/Model'
import {TableInputDataProps} from '../../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Penyusutan Akhir',
  path: '/tata_usaha/pengelolaan_naskah_dinas/penyusutan_akhir',
  api: '/tata_usaha/penyusutan-akhir',
  // api: '/tata_usaha/pengelolaan_naskah_dinas/penyusutan_akhir',
  api_history_naskah: '/tata_usaha/pengelolaan_naskah_dinas/naskah_masuk',
  api_disposisi: '/tata_usaha/pengelolaan_naskah_dinas/naskah_masuk',
  api_balas: '/tata_usaha/pengelolaan_naskah_dinas/naskah_masuk',
}

const BaseBreadcrumb = [
  {
    title: 'Tata Usaha',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Pengelolaan Naskah Dinas',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Nama',
    },
    {
      title: 'Tanggal Pembuatan',
    },
  ],
  link: {
    tambah: {
      id: 'tambah',
      protect: 'tambah',
      title: 'Tambah',
      route: 'tambah',
    },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'id',
        protect: 'ubah/:id',
        title: 'Ubah',
        route: 'ubah/:id',
      },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: '',
      },
    ],
  },
  route: {
    url: InfoData.api,
    // query: 'byuser=true',
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

export const TableInputDataDisposisi: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Dilakukan Oleh',
    },
    {
      title: 'Kepada',
    },
    {
      title: 'Pesan Disposisi / Koordinasi',
    },
    {
      title: 'Instruksi / Pesan Tambahan',
    },
    {
      title: 'Lampiran',
    },
    {
      title: 'Tanggal Disposisi',
    },
    {
      title: 'Batas Waktu',
    },
    {
      title: 'Status',
    },
  ],
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: InfoData.api_history_naskah,
    // query: 'byuser=true',
  },
}

export const TableInputDataBalas: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Dilakukan Oleh',
    },
    {
      title: 'Kepada',
    },
    {
      title: 'Tanggal Balas',
    },
  ],
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: InfoData.api_history_naskah,
    // query: 'byuser=true',
  },
}

export const TableInputDataHistoryNaskah: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Tanggal',
    },
    {
      title: 'Pengirim',
    },
    {
      title: 'Asal',
    },
    {
      title: 'Tujuan',
    },
    {
      title: 'Jenis Naskah',
    },
    {
      title: 'Hal',
    },
    {
      title: 'File',
    },
    {
      title: 'Lampiran',
    },
  ],
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: InfoData.api_history_naskah,
    // query: 'byuser=true',
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
