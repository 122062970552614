import {FC, Fragment, useState} from "react";
import {PageTitle} from "../../../../../../_metronic/layout/core";
import PageData, {InfoData, TableInputData, TableInputDetailData} from "./PageData";
import {CardUi} from "../../../../../helper/AppUi";
import {TableAction, TableColumn, TableInput, TableRow} from "../../../../../helper/TableInput";
import {ConvertCurrency, NullProof, numberlistPagination, RouterQueryParams} from "../../../../../helper/AppFunction";
import {formatDate} from "../../../../../helper/DateFns";
import FormInput from "../../../../../helper/FormInput";
import {formInputList} from "./InputLayout";
import {useParams} from "react-router-dom";
import {TableInputDetail, TableInputDetailColumn} from "../../../../../helper/TableInputDetail";

const MainWrapper: FC = () => {
    const [data, setData] = useState<any>([]);
    const [modal, setModal] = useState<any>([]);
    const modalConfig = {
        data: modal,
        post: setModal,
    };

    return (
        <>
            <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
                {PageData.semua.page.breadcrumb.title}
            </PageTitle>
            <CardUi title={PageData.semua.page.title}>
                <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
                    {NullProof({
                        input: data,
                        params: 'data',
                        isMap: true
                    }).map((l: any, i: number) => {
                        const tableDataBuilder = {
                            modal: modalConfig,
                            input: {data: l, index: i},
                            action: TableInputData.link?.action || [],
                        }
                        return (
                            <Fragment key={i}>
                                <TableRow {...tableDataBuilder}>
                                    <td style={{minWidth: '50px'}}>
                                        {numberlistPagination({
                                            n: i, p: data?.page,
                                            t: data?.size
                                        })}
                                    </td>
                                    <td style={{minWidth: '200px'}}>
                                        {NullProof({
                                            input: l,
                                            params: 'tanggal_pemantauan',
                                            type: 'date'
                                        })}
                                    </td>
                                    <td style={{minWidth: '200px'}}>
                                        {NullProof({input: l, params: 'pemantau', isMap: true})?.map(
                                            (l1: any, i1: number) => (
                                                <Fragment key={i1}>
                                                    <div>
                                                        {`${i1 + 1}. ${NullProof({
                                                            input: l1,
                                                            params: 'pegawai.nama',
                                                        })}`}
                                                    </div>
                                                </Fragment>
                                            )
                                        )}
                                    </td>
                                    <td style={{minWidth: '200px'}}>
                                        {formatDate({
                                            date: NullProof({
                                                input: l,
                                                params: 'createdAt'
                                            }),
                                            dateFormat: 'dd MMMM yyyy',
                                        })}
                                        <br />
                                        {formatDate({
                                            date: NullProof({
                                                input: l,
                                                params: 'createdAt'
                                            }),
                                            dateFormat: 'HH:mm:ss',
                                        })}
                                    </td>
                                    <TableAction {...tableDataBuilder} />
                                </TableRow>
                            </Fragment>
                        )
                    })}
                </TableInput>
            </CardUi>
        </>
    )
}

const TambahWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
                {PageData.tambah.page.breadcrumb.title}
            </PageTitle>
            <CardUi title={PageData.tambah.page.title}>
                <FormInput
                    input={formInputList}
                    route={{
                        url: InfoData.api,
                        type: 'post',
                        redirect: true,
                    }}
                />
            </CardUi>
        </>
    )
}

const UbahWrapper: FC = () => {
    const query = useParams();

    return (
        <>
            <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
                {PageData.ubah.page.breadcrumb.title}
            </PageTitle>
            <CardUi title={PageData.ubah.page.title}>
                <FormInput
                    input={formInputList}
                    route={{
                        url: RouterQueryParams(`${InfoData.api}/:id`, query),
                        type: 'put',
                        redirect: true,
                    }}
                />
            </CardUi>
        </>
    )
}

const DetailWrapper: FC = () => {
    const [data, setData] = useState<any>({});

    return (
        <>
            <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
                {PageData.detail?.page.breadcrumb.title}
            </PageTitle>
            <CardUi title={PageData.detail?.page.breadcrumb.title}>
                <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
                    <TableInputDetailColumn title={{value: 'Tanggal Pemantauan'}}>
                        {formatDate({
                            date: NullProof({
                                input: data,
                                params: 'tanggal_pemantauan'
                            }),
                            dateFormat: 'dd MMMM yyyy'
                        })}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn title={{value: 'Hasil Pemantauan'}}>
                        {NullProof({
                            input: data,
                            params: 'hasil_pemantauan',
                            type: 'html'
                        })}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn title={{value: 'Saran Itjen'}}>
                        {NullProof({
                            input: data,
                            params: 'saran_itjen',
                            type: 'html'
                        })}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn title={{value: 'Status Tindak Lanjut'}}>
                        {NullProof({
                            input: data,
                            params: 'tindak_lanjut',
                            type: 'html'
                        })}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn title={{value: 'Pemantau'}}>
                        {NullProof({
                            input: data,
                            params: 'pemantau',
                            isMap: true
                        })?.map(
                            (l1: any, i1: number) => (
                                <Fragment key={i1}>
                                    <div>
                                        {`${i1 + 1}. ${NullProof({
                                            input: l1,
                                            params: 'pegawai.nama',
                                        })}`}
                                    </div>
                                </Fragment>
                            )
                        )}
                    </TableInputDetailColumn>
                </TableInputDetail>
            </CardUi>
        </>
    )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper}