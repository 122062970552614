import * as Yup from 'yup'
import { FormInputProps } from '../../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../../helper/template/FormInputTemplate'

const filterFormInputList: FormInputProps[] = [
  ...inputLayoutBuilder({
    input: [{template: 'search'}, {template: 'status'}],
  }),
]

const formInputList: FormInputProps[] = [
  {
    name: 'berkas',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Berkas Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Berkas',
          placeholder: 'Berkas',
        },
      },
    },
  },
  {
    name: 'lama_pinjaman',
    type: 'input',
    className: 'col-12',
    validator: Yup.date().required('Lama Pinjam Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Lama Pinjam',
          placeholder: 'Lama Pinjam',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'keterangan',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string().required('Keterangan Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Keterangan',
          placeholder: 'Keterangan',
        },
      },
    },
  },
]

export {formInputList, filterFormInputList}
