/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {
  InfoData,
  TableInputData,
  TableInputDetailData,
} from './PageData'
import {
  formInputStatus,
  formInputStatusEvalap,
  formInputStatusketua,
  formInputStatusppk,
} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import {PdfLayout} from './PdfLayout'
import {CardUi} from '../../../../helper/AppUi'
import FormInput from '../../../../helper/FormInput'
import {
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  getStatusColor,
} from '../../../../helper/AppFunction'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import {formatDate} from '../../../../helper/DateFns'
import {
  TableInputDetail,
  TableInputDetailColumn,
  TableInputDetailFile,
} from '../../../../helper/TableInputDetail'
import {ProtectComponent} from '../../../../helper/UserRolesValidation'
import Modal from '../../../../helper/Modal'
import {useAuth} from '../../../../modules/auth'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {i + 1 + ((NullProof({input: data, params: 'page'}) || 1) - 1) * 10}
                  </TableColumn>
                  <TableColumn label='Surat Tugas' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                  </TableColumn>
                  {/* <TableColumn label='Pegawai' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </TableColumn> */}
                  <TableColumn label='Kegiatan' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'surat_tugas.nama_kegiatan'})}
                  </TableColumn>
                  <TableColumn label='Jumlah Bukti Perjalanan' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'total_bukti_perjalanan'})}
                  </TableColumn>
                  <TableColumn label='Dibuat' props={{style: {minWidth: '200px'}}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'surat_tugas.createdAt'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PdfPageViewer
        route={`${InfoData.api}/${query.id}`}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
        justPreview={true}
      />
    </>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    bendahara: false,
    evalap: false,
    ketua: false,
    ppk: false,
  })
  const [key, setKey] = useState<number>(0)
  const sisa_pengembalian = NullProof({
    input: data,
    params: 'sisa_pengembalian',
    isLabel: false,
  })
  const {auth} = useAuth()
  const idPegawai = NullProof({input: auth?.data, params: 'pegawai_id'})
  return (
    <>
      <Modal
        id='modal_status_ketua'
        isShow={modal?.ketua}
        onClose={() => {
          setModal((p: any) => ({...p, ketua: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatusketua}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-ketua`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, ketua: false}))
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_bendahara'
        isShow={modal?.bendahara}
        onClose={() => {
          setModal((p: any) => ({...p, bendahara: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatus}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-bendahara`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, bendahara: false}))
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_evalap'
        isShow={modal?.evalap}
        onClose={() => {
          setModal((p: any) => ({...p, evalap: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatusEvalap}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-evalap`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, evalap: false}))
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_ppk'
        isShow={modal?.ppk}
        onClose={() => {
          setModal((p: any) => ({...p, ppk: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatusppk}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-ppk`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, ppk: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
          {/* Data Perjadin */}
        <TableInputDetail
          onSuccess={(val: any) => {
            setData(val)
          }}
          {...TableInputDetailData}
        >
          <CardUi title={PageData.detail?.page.breadcrumb.title}>
            <TableInputDetailColumn title={{value: 'Dibuat'}}>
              {formatDate({
                date: NullProof({input: data, params: 'createdAt'}),
                dateFormat: 'dd MMMM yyyy',
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Surat Tugas'}}>
              {NullProof({input: data, params: 'surat_tugas.nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Lampiran Penugasan'}}>
              <TableInputDetailFile
                data={NullProof({input: data, params: 'lampiran_penugasan', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Pegawai'}}>
              {NullProof({input: data, params: 'pegawai.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Tanggal Realisasi'}}
            >
              {NullProof({input: data, params: 'tanggal_realisasi_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: `${i + 1}. Tanggal`}}>
                        {`${formatDate({
                          date: NullProof({input: l, params: 'tanggal_mulai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })} - ${formatDate({
                          date: NullProof({input: l, params: 'tanggal_selesai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })} (${
                          NullProof({input: l, params: 'tanggal_total', isLabel: false}) || 0
                        } Hari)`}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Muka'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Pembayaran Awal / Uang Muka'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_muka',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Uang Muka Transportasi'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_muka_transportasi',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total Uang Muka'}}>
                {ConvertCurrency(
                  parseInt(
                    NullProof({
                      input: data,
                      params: 'uang_muka',
                      isLabel: false,
                    }) || 0
                  ) +
                    NullProof({
                      input: data,
                      params: 'uang_muka_transportasi',
                      isLabel: false,
                    }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Harian'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                {`${NullProof({input: data, params: 'uang_harian_hari'})} Hari x ${ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_harian_harga',
                    isLabel: false,
                  }) || 0
                )}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_harian_total',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Representatif'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                {`${NullProof({input: data, params: 'uang_representatif_hari'})} Hari x ${ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_representatif_harga',
                    isLabel: false,
                  }) || 0
                )}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_representatif_total',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Uang Penginapan'}}
            >
              {NullProof({input: data, params: 'uang_penginapan_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                        {`${NullProof({
                          input: l,
                          params: 'hari',
                        })} Hari x ${ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}`}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Transportasi'}}
            >
              {NullProof({input: data, params: 'transportasi_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Dibayar oleh'}}>
                        {NullProof({input: l, params: 'dibayar_oleh'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Asal'}}>
                        {NullProof({input: l, params: 'asal'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Tujuan'}}>
                        {NullProof({input: l, params: 'tujuan'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Jenis'}}>
                        {NullProof({input: l, params: 'jenis'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'BST Kantor'}}>
              {ConvertCurrency(
                NullProof({
                  input: data,
                  params: 'bst_kantor',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kwitansi BST Kantor'}}>
              <TableInputDetailFile
                data={NullProof({input: data, params: 'bst_kantor_lampiran', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'BST Lokasi'}}>
              {ConvertCurrency(
                NullProof({
                  input: data,
                  params: 'bst_lokasi',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kwitansi BST Lokasi'}}>
              <TableInputDetailFile
                data={NullProof({input: data, params: 'bst_lokasi_lampiran', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Biaya Lainnya'}}
            >
              {NullProof({input: data, params: 'kwitansi_lain_lain_list', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Deskripsi'}}>
                        {NullProof({input: l, params: 'deskripsi'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Nominal Total'}}>
              {ConvertCurrency(
                NullProof({
                  input: data,
                  params: 'jumlah_dibayarkan',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: sisa_pengembalian < 0 ? 'Nominal Total Pengembalian' : 'Nominal Total Sisa',
              }}
            >
              {ConvertCurrency(sisa_pengembalian < 0 ? sisa_pengembalian * -1 : sisa_pengembalian)}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status Ketua Tim'}}>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='rounded-circle'
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    backgroundColor: getStatusColor(
                      NullProof({input: data, params: 'status_ketua', isLabel: false})
                    ),
                  }}
                ></div>
                <div>{NullProof({input: data, params: 'status_ketua'})}</div>
                {/* {idPegawai ===
                  objectListSelector({
                    data: NullProof({input: data, params: 'surat_tugas.kepada', isMap: true}),
                    name: 'jabatan',
                    search: 'Ketua Tim',
                  })?.pegawai_id && ( */}
                <div>
                  {/* <ProtectComponent id='ubah_status_ketua/:id' route={InfoData.path}> */}
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                      setModal((p: any) => ({...p, ketua: true}))
                    }}
                  >
                    Ubah Status
                  </button>
                  {/* </ProtectComponent> */}
                </div>
                {/* )} */}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Ketua Tim'}}>
              {NullProof({input: data, params: 'ketua.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan Ketua Tim'}}>
              {NullProof({input: data, params: 'catatan_ketua'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan Ketua Tim'}}>
              {NullProof({
                input: data,
                params: 'status_ketua_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status Evalap'}}>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='rounded-circle'
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    backgroundColor: getStatusColor(
                      NullProof({input: data, params: 'status_evalap', isLabel: false})
                    ),
                  }}
                ></div>
                <div>{NullProof({input: data, params: 'status_evalap'})}</div>
                {NullProof({input: data, params: 'status_ketua'}) === 'Disetujui' && (
                  <div>
                    <ProtectComponent id='ubah_status_evalap/:id' route={InfoData.path}>
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                          setModal((p: any) => ({...p, evalap: true}))
                        }}
                      >
                        Ubah Status
                      </button>
                    </ProtectComponent>
                  </div>
                )}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Evalap'}}>
              {NullProof({input: data, params: 'evalap.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan Evalap'}}>
              {NullProof({input: data, params: 'catatan_evalap'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  Evalap'}}>
              {NullProof({
                input: data,
                params: 'status_evalap_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status ppk'}}>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='rounded-circle'
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    backgroundColor: getStatusColor(
                      NullProof({input: data, params: 'status_ppk', isLabel: false})
                    ),
                  }}
                ></div>
                <div>{NullProof({input: data, params: 'status_ppk'})}</div>
                {NullProof({input: data, params: 'status_evalap'}) === 'Disetujui' &&
                  NullProof({input: data, params: 'status_ketua'}) === 'Disetujui' && (
                    <div>
                      <ProtectComponent id='ubah_status_ppk/:id' route={InfoData.path}>
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            setModal((p: any) => ({...p, ppk: true}))
                          }}
                        >
                          Ubah Status
                        </button>
                      </ProtectComponent>
                    </div>
                  )}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'ppk'}}>
              {NullProof({input: data, params: 'ppk.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan ppk'}}>
              {NullProof({input: data, params: 'catatan_ppk'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  PPK'}}>
              {NullProof({
                input: data,
                params: 'status_ppk_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status Bendahara'}}>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='rounded-circle'
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    backgroundColor: getStatusColor(
                      NullProof({input: data, params: 'status_bendahara', isLabel: false})
                    ),
                  }}
                ></div>
                <div>{NullProof({input: data, params: 'status_bendahara'})}</div>
                {NullProof({input: data, params: 'status_ppk'}) === 'Disetujui' &&
                  NullProof({input: data, params: 'status_evalap'}) === 'Disetujui' &&
                  NullProof({input: data, params: 'status_ketua'}) === 'Disetujui' && (
                    <div>
                      <ProtectComponent id='ubah_status_bendahara/:id' route={InfoData.path}>
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            setModal((p: any) => ({...p, bendahara: true}))
                          }}
                        >
                          Ubah Status
                        </button>
                      </ProtectComponent>
                    </div>
                  )}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Bendahara'}}>
              {NullProof({input: data, params: 'bendahara.pegawai.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan Bendahara'}}>
              {NullProof({input: data, params: 'catatan_bendahara'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  Bendahara'}}>
              {NullProof({
                input: data,
                params: 'status_bendahara_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
          </CardUi>

          {/* Data Pengusul */}
          {data?.revisi &&
          <>
          <CardUi title='Data Pengusul' props={{ className: 'mt-5' }}>
            <TableInputDetailColumn title={{value: 'Dibuat'}}>
              {formatDate({
                date: NullProof({input: data.revisi?.old
                  
                  , params: 'createdAt'}),
                dateFormat: 'dd MMMM yyyy',
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Surat Tugas'}}>
              {NullProof({input: data.revisi?.old
                
                , params: 'surat_tugas.nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Lampiran Penugasan'}}>
              <TableInputDetailFile
                data={NullProof({input: data.revisi?.old
                  
                  , params: 'lampiran_penugasan', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Pegawai'}}>
              {NullProof({input: data.revisi?.old
                
                , params: 'pegawai.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Tanggal Realisasi'}}
            >
              {NullProof({input: data.revisi?.old
              
              , params: 'tanggal_realisasi_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: `${i + 1}. Tanggal`}}>
                        {`${formatDate({
                          date: NullProof({input: l, params: 'tanggal_mulai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })} - ${formatDate({
                          date: NullProof({input: l, params: 'tanggal_selesai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })} (${
                          NullProof({input: l, params: 'tanggal_total', isLabel: false}) || 0
                        } Hari)`}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Muka'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Pembayaran Awal / Uang Muka'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data.revisi?.old
                    
                    ,
                    params: 'uang_muka',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Uang Muka Transportasi'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data.revisi?.old
                    
                    ,
                    params: 'uang_muka_transportasi',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total Uang Muka'}}>
                {ConvertCurrency(parseInt(NullProof({
                    input: data.revisi?.old
                    
                    ,
                    params: 'uang_muka',
                    isLabel: false,
                  }) || 0) +  NullProof({
                    input: data.revisi?.old
                    
                    ,
                    params: 'uang_muka_transportasi',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Harian'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                {`${NullProof({input: data.revisi?.old
                
                , params: 'uang_harian_hari'})} Hari x ${ConvertCurrency(
                  NullProof({
                    input: data.revisi?.old
                    
                    ,
                    params: 'uang_harian_harga',
                    isLabel: false,
                  }) || 0
                )}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data.revisi?.old
                    
                    ,
                    params: 'uang_harian_total',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Representatif'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                {`${NullProof({input: data, params: 'uang_representatif_hari'})} Hari x ${ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_representatif_harga',
                    isLabel: false,
                  }) || 0
                )}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_representatif_total',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Uang Penginapan'}}
            >
              {NullProof({input: data.revisi?.old
              
              , params: 'uang_penginapan_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                        {`${NullProof({
                          input: l,
                          params: 'hari',
                        })} Hari x ${ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}`}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Transportasi'}}
            >
              {NullProof({input: data.revisi?.old
              
              , params: 'transportasi_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Dibayar oleh'}}>
                        {NullProof({input: l, params: 'dibayar_oleh'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Asal'}}>
                        {NullProof({input: l, params: 'asal'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Tujuan'}}>
                        {NullProof({input: l, params: 'tujuan'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Jenis'}}>
                        {NullProof({input: l, params: 'jenis'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'total',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'BST Kantor'}}>
              {ConvertCurrency(
                NullProof({
                  input: data.revisi?.old
                  
                  ,
                  params: 'bst_kantor',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kwitansi BST Kantor'}}>
              <TableInputDetailFile
                data={NullProof({input: data.revisi?.old
                  
                  , params: 'bst_kantor_lampiran', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'BST Lokasi'}}>
              {ConvertCurrency(
                NullProof({
                  input: data.revisi?.old
                  
                  ,
                  params: 'bst_lokasi',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kwitansi BST Lokasi'}}>
              <TableInputDetailFile
                data={NullProof({input: data.revisi?.old
                  
                  , params: 'bst_lokasi_lampiran', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Biaya Lainnya'}}
            >
              {NullProof({input: data.revisi?.old
              
              , params: 'kwitansi_lain_lain_list', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Deskripsi'}}>
                        {NullProof({input: l, params: 'deskripsi'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Nominal Total'}}>
              {ConvertCurrency(
                NullProof({
                  input: data.revisi?.old
                  
                  ,
                  params: 'jumlah_dibayarkan',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: NullProof({
                  input: data.revisi?.old,
                  params: 'sisa_pengembalian',
                  isLabel: false,
                }) < 0 ? 'Nominal Total Pengembalian' : 'Nominal Total Sisa',
              }}
            >
              {ConvertCurrency(NullProof({
                  input: data.revisi?.old,
                  params: 'sisa_pengembalian',
                  isLabel: false,
                }) < 0 ? NullProof({
                  input: data.revisi?.old,
                  params: 'sisa_pengembalian',
                  isLabel: false,
                }) * -1 : NullProof({
                  input: data.revisi?.old,
                  params: 'sisa_pengembalian',
                  isLabel: false,
                }))}
            </TableInputDetailColumn>
        </CardUi>
        <CardUi title='Data Revisi Evalap' props={{ className: 'mt-5' }}>
          <TableInputDetailColumn title={{value: 'Dibuat'}}>
              {formatDate({
                date: NullProof({input: data.revisi?.updated
                  
                  , params: 'createdAt'}),
                dateFormat: 'dd MMMM yyyy',
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Surat Tugas'}}>
              {NullProof({input: data.revisi?.updated
                
                , params: 'surat_tugas.nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Lampiran Penugasan'}}>
              <TableInputDetailFile
                data={NullProof({input: data.revisi?.updated
                  
                  , params: 'lampiran_penugasan', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Pegawai'}}>
              {NullProof({input: data.revisi?.updated
                
                , params: 'pegawai.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Tanggal Realisasi'}}
            >
              {NullProof({input: data.revisi?.updated
              
              , params: 'tanggal_realisasi_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: `${i + 1}. Tanggal`}}>
                        {`${formatDate({
                          date: NullProof({input: l, params: 'tanggal_mulai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })} - ${formatDate({
                          date: NullProof({input: l, params: 'tanggal_selesai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })} (${
                          NullProof({input: l, params: 'tanggal_total', isLabel: false}) || 0
                        } Hari)`}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Muka'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Pembayaran Awal / Uang Muka'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data.revisi?.updated
                    
                    ,
                    params: 'uang_muka',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Uang Muka Transportasi'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data.revisi?.updated
                    
                    ,
                    params: 'uang_muka_transportasi',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total Uang Muka'}}>
                {ConvertCurrency(parseInt(NullProof({
                    input: data.revisi?.updated
                    
                    ,
                    params: 'uang_muka',
                    isLabel: false,
                  }) || 0) +  NullProof({
                    input: data.revisi?.updated
                    
                    ,
                    params: 'uang_muka_transportasi',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Harian'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                {`${NullProof({input: data.revisi?.updated
                
                , params: 'uang_harian_hari'})} Hari x ${ConvertCurrency(
                  NullProof({
                    input: data.revisi?.updated
                    
                    ,
                    params: 'uang_harian_harga',
                    isLabel: false,
                  }) || 0
                )}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data.revisi?.updated
                    
                    ,
                    params: 'uang_harian_total',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Representatif'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                {`${NullProof({input: data, params: 'uang_representatif_hari'})} Hari x ${ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_representatif_harga',
                    isLabel: false,
                  }) || 0
                )}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_representatif_total',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Uang Penginapan'}}
            >
              {NullProof({input: data.revisi?.updated
              
              , params: 'uang_penginapan_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                        {`${NullProof({
                          input: l,
                          params: 'hari',
                        })} Hari x ${ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}`}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Transportasi'}}
            >
              {NullProof({input: data.revisi?.updated
              
              , params: 'transportasi_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Dibayar oleh'}}>
                        {NullProof({input: l, params: 'dibayar_oleh'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Asal'}}>
                        {NullProof({input: l, params: 'asal'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Tujuan'}}>
                        {NullProof({input: l, params: 'tujuan'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Jenis'}}>
                        {NullProof({input: l, params: 'jenis'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'total',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'BST Kantor'}}>
              {ConvertCurrency(
                NullProof({
                  input: data.revisi?.updated
                  
                  ,
                  params: 'bst_kantor',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kwitansi BST Kantor'}}>
              <TableInputDetailFile
                data={NullProof({input: data.revisi?.updated
                  
                  , params: 'bst_kantor_lampiran', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'BST Lokasi'}}>
              {ConvertCurrency(
                NullProof({
                  input: data.revisi?.updated
                  
                  ,
                  params: 'bst_lokasi',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kwitansi BST Lokasi'}}>
              <TableInputDetailFile
                data={NullProof({input: data.revisi?.updated
                  
                  , params: 'bst_lokasi_lampiran', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Biaya Lainnya'}}
            >
              {NullProof({input: data.revisi?.updated
              
              , params: 'kwitansi_lain_lain_list', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Deskripsi'}}>
                        {NullProof({input: l, params: 'deskripsi'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Nominal Total'}}>
              {ConvertCurrency(
                NullProof({
                  input: data.revisi?.updated
                  
                  ,
                  params: 'jumlah_dibayarkan',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: NullProof({
                  input: data.revisi?.updated,
                  params: 'sisa_pengembalian',
                  isLabel: false,
                }) < 0 ? 'Nominal Total Pengembalian' : 'Nominal Total Sisa',
              }}
            >
              {ConvertCurrency(NullProof({
                  input: data.revisi?.updated,
                  params: 'sisa_pengembalian',
                  isLabel: false,
                }) < 0 ? NullProof({
                  input: data.revisi?.updated,
                  params: 'sisa_pengembalian',
                  isLabel: false,
                }) * -1 : NullProof({
                  input: data.revisi?.updated,
                  params: 'sisa_pengembalian',
                  isLabel: false,
                }))}
            </TableInputDetailColumn>
          </CardUi>
          </>
          }
        </TableInputDetail>
      </Fragment>

    </>
  )
}


export {
  MainWrapper,
  PdfWrapper,
  DetailWrapper,
}
