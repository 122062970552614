import {DataRoutesProps} from '../../../../../../UserRolesValidation'
import {BeritaAcaraRoutes} from './BeritaAcara'
import {NotulensiRoutes} from './Notulensi'

const BeritaAcaraNotulensi: DataRoutesProps[] = [
  {
    isPage: true,
    title: 'Berita Acara',
    icon: 'element-10',
    route: 'berita_acara',
    elements: <></>,
    subroute: BeritaAcaraRoutes,
  },
  {
    isPage: true,
    title: 'Notulensi',
    icon: 'element-10',
    route: 'notulensi',
    elements: <></>,
    subroute: NotulensiRoutes,
  },
]

export default BeritaAcaraNotulensi
