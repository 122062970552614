import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfStyle,
  PdfStylePallete,
  PdfTableColumnBuilder,
  PdfTabCol,
} from '../../../../../helper/ReactPdf'
import {formatDate} from '../../../../../helper/DateFns'
import {AlphabetIndex, ConvertCurrency, NullProof} from '../../../../../helper/AppFunction'

const HeaderContent: FC = () => {
  return (
    <>
      <View style={{ flexDirection: 'row', borderBottom: '1px solid black', backgroundColor:'yellow' }}>
        <View style={{ width: '2%', borderRight:'1px solid black', display: 'flex', justifyContent:'center' }}>
          <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>No</Text>
        </View>
        <View style={{width: '3%', borderRight:'1px solid black', display: 'flex', justifyContent:'center' }}>
          <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Kode</Text>
        </View>
        <View style={{width: '10%', borderRight:'1px solid black', display: 'flex', justifyContent:'center' }}>
          <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Komitmen Kerja (Program kerja)</Text>
        </View>
        <View style={{width: '8%', borderRight:'1px solid black', display: 'flex', justifyContent:'center' }}>
          <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Indikator Keberhasilan</Text>
        </View>
        <View style={{width: '3.5%', borderRight:'1px solid black', display: 'flex', justifyContent:'center' }}>
          <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Target 100% (Bulan)</Text>
        </View>
        <View style={{width: '55.5%', borderRight:'1px solid black', textAlign:'center'}}>
          <View style={{ borderBottom: '1px solid black' }}>
            <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold', paddingVertical: '3px' }}>Uraian Target Bulanan</Text>
          </View>
          <View style={{ display: 'flex', flexDirection:'row' }}>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Februari</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Maret</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>April</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Mei</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Juni</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Juli</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Agustus</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>September</Text>
              </View>
              <View style={{ width: '11.11%'}}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Oktober</Text>
              </View>
          </View>
        </View>
        <View style={{width: '20%', textAlign:'center'}}>
          <View style={{ borderBottom: '1px solid black' }}>
            <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold', paddingVertical: '3px' }}>Presentase Target Bulanan (%)</Text>
          </View>
          <View style={{ display: 'flex', flexDirection:'row' }}>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Feb</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Mar</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Apr</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Mei</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Jun</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Jul</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Agt</Text>
              </View>
              <View style={{ width: '11.11%', borderRight: '1px solid black' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Sep</Text>
              </View>
              <View style={{ width: '11.11%' }}>
                <Text style={{ width: '100%', textAlign:'center', fontWeight: 'bold' }}>Okt</Text>
              </View>
          </View>
        </View>
      </View>
    </>
  )
}

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  console.log(data)
  return (
    <Document title='Dokumen'>
      <Page
        size={[2800]}
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px', paddingVertical: '50px', paddingHorizontal: '20px'}}
      >
        <View style={{width: '100%'}}>
        <View style={{ width: '100%', marginBottom: '20px'}}>
              <Text style={{ 
                fontSize: PdfStylePallete.typography.title.small,
                fontWeight: 700,
                textTransform: 'uppercase',
               }}
              >URAIAN TARGET</Text>
              <Text style={{ 
                fontSize: PdfStylePallete.typography.title.small,
                fontWeight: 700,
                textTransform: 'uppercase',
               }}
              >PAKTA INTEGRITAS KOMITMEN KERJA</Text>
              <Text style={{ 
                fontSize: PdfStylePallete.typography.title.small,
                fontWeight: 700,
                textTransform: 'uppercase',
               }}
              >TAHUN 2024</Text>
            </View>
            <View style={{ width: '100%', marginBottom: '20px', display: 'flex', flexDirection:'row' }}>
            <Text style={{ width: '5%' }}>Nama Satuan Kerja</Text>
              <Text>: {NullProof({input:data.data, params: 'satker.nama_satker' })}</Text>
            </View>
            <View style={{ width: '100%', borderRight: '1px solid black', borderLeft: '1px solid black', borderTop: '1px solid black' }}>
              <HeaderContent/>
              <View style={{ width: '100%'}}>
                {NullProof({input:data.data, params:'komitmen_kerja', isMap:true})?.map((l:any, i:number) => {
                  return (
                    <Fragment key={i}>
                      <View style={{ borderBottom: '1px solid black', display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '1.965%', borderRight:'1px solid black', display: 'flex', justifyContent:'center' }}>
                          <Text style={{ width: '100%', textAlign:'center'}}>{i+1}</Text>
                        </View>
                        <View style={{width: '2.945%', borderRight:'1px solid black', display: 'flex', justifyContent:'center' }}>
                          <Text style={{ width: '100%', textAlign:'center'}}>
                            {NullProof({input:l, params:'kode'})}
                          </Text>
                        </View>
                        <View style={{minHeight: '50px', width: '9.8%', borderRight:'1px solid black'}}>
                          <Text style={{ width: '100%', textAlign:'left'}}>
                            {NullProof({input:l, params:'kegiatan'})}
                          </Text>
                        </View>
                        <View style={{ width: '85.290%', display: 'flex', flexDirection:'column', alignItems:'stretch' }}>
                          {NullProof({input:l, params: 'indikator', isMap:true})?.map((ll:any, ii:number) => {
                            const islastIndex = (ii+1) === NullProof({input:l, params: 'indikator', isMap:true})?.length ? true : false
                            return (
                              <View key={ii} style={{flex: 1, width: '100%', borderBottom: islastIndex ? 'none':'1px solid black', display: 'flex', flexDirection:'row'}}>
                                <View style={{width: '9.2%', borderRight:'1px solid black'}}>
                                  <Text>{NullProof({input:ll, params:'indikator'})}</Text>
                                </View>
                                <View style={{width: '4.015%', borderRight:'1px solid black'}}>
                                  <Text style={{ width: '100%', textAlign:'center'}}>{NullProof({input:ll, params:'target'})}</Text>
                                </View>
                                <View style={{width: '7.08%', borderRight:'1px solid black'}}>
                                  <Text>{ll.uraian_target_bulanan?.uraian_februari && NullProof({input:ll, params:'uraian_target_bulanan.uraian_februari'})}</Text>
                                </View>
                                <View style={{width: '7.08%', borderRight:'1px solid black'}}>
                                  <Text>{ll.uraian_target_bulanan?.uraian_maret && NullProof({input:ll, params:'uraian_target_bulanan.uraian_maret'})}</Text>
                                </View>
                                <View style={{width: '7.08%', borderRight:'1px solid black'}}>
                                  <Text>{ll.uraian_target_bulanan?.uraian_april && NullProof({input:ll, params:'uraian_target_bulanan.uraian_april'})}</Text>
                                </View>
                                <View style={{width: '7.08%', borderRight:'1px solid black'}}>
                                  <Text>{ll.uraian_target_bulanan?.uraian_mei && NullProof({input:ll, params:'uraian_target_bulanan.uraian_mei'})}</Text>
                                </View>
                                <View style={{width: '7.09%', borderRight:'1px solid black'}}>
                                  <Text>{ll.uraian_target_bulanan?.uraian_juni && NullProof({input:ll, params:'uraian_target_bulanan.uraian_juni'})}</Text>
                                </View>
                                <View style={{width: '7.09%', borderRight:'1px solid black'}}>
                                  <Text>{ll.uraian_target_bulanan?.uraian_juli && NullProof({input:ll, params:'uraian_target_bulanan.uraian_juli'})}</Text>
                                </View>
                                <View style={{width: '7.09%', borderRight:'1px solid black'}}>
                                  <Text>{ll.uraian_target_bulanan?.uraian_agustus && NullProof({input:ll, params:'uraian_target_bulanan.uraian_agustus'})}</Text>
                                </View>
                                <View style={{width: '7.09%', borderRight:'1px solid black'}}>
                                  <Text>{ll.uraian_target_bulanan?.uraian_september && NullProof({input:ll, params:'uraian_target_bulanan.uraian_september'})}</Text>
                                </View>
                                <View style={{width: '7.1%', borderRight:'1px solid black'}}>
                                  <Text>{ll.uraian_target_bulanan?.uraian_oktober && NullProof({input:ll, params:'uraian_target_bulanan.uraian_oktober'})}</Text>
                                </View>
                                <View style={{width: '2.555%', borderRight:'1px solid black', display: 'flex', justifyContent:'center'}}>
                                  <Text style={{ width: '100%', textAlign: 'center' }}>{ll.uraian_target_bulanan?.presentase_febru > 0  ? `${NullProof({input:ll, params:'uraian_target_bulanan.presentase_febru'})}%` : ''}</Text>
                                </View>
                                <View style={{width: '2.555%', borderRight:'1px solid black', display: 'flex', justifyContent:'center'}}>
                                  <Text style={{ width: '100%', textAlign: 'center' }}>{ll.uraian_target_bulanan?.presentase_maret > 0  ? `${NullProof({input:ll, params:'uraian_target_bulanan.presentase_maret'})}%` : ''}</Text>
                                </View>
                                <View style={{width: '2.555%', borderRight:'1px solid black', display: 'flex', justifyContent:'center'}}>
                                  <Text style={{ width: '100%', textAlign: 'center' }}>{ll.uraian_target_bulanan?.presentase_april > 0  ? `${NullProof({input:ll, params:'uraian_target_bulanan.presentase_april'})}%` : ''}</Text>
                                </View>
                                <View style={{width: '2.555%', borderRight:'1px solid black', display: 'flex', justifyContent:'center'}}>
                                  <Text style={{ width: '100%', textAlign: 'center' }}>{ll.uraian_target_bulanan?.presentase_mei > 0  ? `${NullProof({input:ll, params:'uraian_target_bulanan.presentase_mei'})}%` : ''}</Text>
                                </View>
                                <View style={{width: '2.555%', borderRight:'1px solid black', display: 'flex', justifyContent:'center'}}>
                                  <Text style={{ width: '100%', textAlign: 'center' }}>{ll.uraian_target_bulanan?.presentase_juni > 0  ? `${NullProof({input:ll, params:'uraian_target_bulanan.presentase_juni'})}%` : ''}</Text>
                                </View>
                                <View style={{width: '2.555%', borderRight:'1px solid black', display: 'flex', justifyContent:'center'}}>
                                  <Text style={{ width: '100%', textAlign: 'center' }}>{ll.uraian_target_bulanan?.presentase_juli > 0  ? `${NullProof({input:ll, params:'uraian_target_bulanan.presentase_juli'})}%` : ''}</Text>
                                </View>
                                <View style={{width: '2.555%', borderRight:'1px solid black', display: 'flex', justifyContent:'center'}}>
                                  <Text style={{ width: '100%', textAlign: 'center' }}>{ll.uraian_target_bulanan?.presentase_agust > 0  ? `${NullProof({input:ll, params:'uraian_target_bulanan.presentase_agust'})}%` : ''}</Text>
                                </View>
                                <View style={{width: '2.555%', borderRight:'1px solid black', display: 'flex', justifyContent:'center'}}>
                                  <Text style={{ width: '100%', textAlign: 'center' }}>{ll.uraian_target_bulanan?.presentase_septe > 0  ? `${NullProof({input:ll, params:'uraian_target_bulanan.presentase_septe'})}%` : ''}</Text>
                                </View>
                                <View style={{width: '2%', display: 'flex', justifyContent:'center'}}>
                                  <Text style={{ width: '100%', textAlign: 'center' }}>{ll.uraian_target_bulanan?.presentase_oktob > 0  ? `${NullProof({input:ll, params:'uraian_target_bulanan.presentase_oktob'})}%` : ''}</Text>
                                </View>
                              </View>
                            )
                          })}
                        </View>
                      </View>
                    </Fragment>
                  )
                })}
              </View>
            </View>
        </View>
      </Page>
    </Document>
  )
}

export default PdfLayout
