/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
// import { Registration } from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {VerificationCode} from './components/VerificationCode'
import {ResetPassword} from './components/ResetPassword'
import {KebijakanPrivasiModal} from '../../../_metronic/partials/modals/kebijakan-privasi/KebijakanPrivasi'
import {PanduanPenggunaanModal} from '../../../_metronic/partials/modals/panduan-penggunaan/PanduanPenggunaan'

const AuthLayout = () => {
  const [isOpenKebijakanPrivasi, setIsOpenKebijakanPrivasi] = useState(false)
  const [isOpenPanduanPenggunaan, setIsOpenPanduanPenggunaan] = useState(false)

  const handleOpenKebijakanPrivasi = () => setIsOpenKebijakanPrivasi(true)
  const handleCloseKebijakanPrivasi = () => setIsOpenKebijakanPrivasi(false)

  const handleOpenPanduanPenggunaan = () => setIsOpenPanduanPenggunaan(true)
  const handleClosePanduanPenggunaan = () => setIsOpenPanduanPenggunaan(false)

  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    // <div
    //   className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    //   style={{
    //     backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/bg-login.png')})`,
    //   }}
    // >
    <>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/bg-login-5.png')})`,
        }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          <a href='#' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logokma.png')} className='h-150px' />
          </a>
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <Outlet />
            <div
              className='d-flex flex-column flex-md-row align-items-center justify-content-center gap-3 py-3'
              style={{width: '100%'}}
            >
              {/* <span onClick={handleOpenPanduanPenggunaan} style={{cursor: 'pointer'}}>
                Panduan Penggunaan
              </span> */}
              <span onClick={handleOpenKebijakanPrivasi} style={{cursor: 'pointer'}}>
                Kebijakan Privasi
              </span>
            </div>
            <div className='text-dark text-center order-2 order-md-1'>
              <span className='text-muted fw-semibold me-1'>2023©</span>
              <span>Kementerian Agama RI</span>
            </div>
            <hr />
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-center'>
              <img alt='Logo' src={toAbsoluteUrl('/media/bsre.png')} className='h-40px' />
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}
        {/* <div className='app-footer' id='kt_app_footer'>
          <div
            className='app-container d-flex flex-column flex-md-row flex-center py-3'
            style={{flex: 'none'}}
          >
            <div className='text-dark order-2 order-md-1'>
              <span className='text-muted fw-semibold me-1'>2023©</span>
              <span>Kementerian Agama RI</span>
            </div>
          </div>
          <div
            className='app-container d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 py-3'
            style={{width: '100%'}}
          >
            <span onClick={handleOpenPanduanPenggunaan} style={{cursor: 'pointer'}}>
              Panduan Penggunaan
            </span>
            <span onClick={handleOpenKebijakanPrivasi} style={{cursor: 'pointer'}}>
              Kebijakan Privasi
            </span>
          </div>
        </div> */}
        {/* end::Footer */}
      </div>
      <KebijakanPrivasiModal show={isOpenKebijakanPrivasi} onClose={handleCloseKebijakanPrivasi} />
      {/* <PanduanPenggunaanModal
        show={isOpenPanduanPenggunaan}
        onClose={handleClosePanduanPenggunaan}
      /> */}
    </>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      {/* <Route path='registration' element={<Registration />} /> */}
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='verify-code' element={<VerificationCode />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
