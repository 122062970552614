import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'penerbit_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Penerbit',
          placeholder: 'Nama Penerbit',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'byuser=true',
        params: 'nomor_surat,nama_kegiatan',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nomor Surat : </span>
              <span>$nomor_surat</span>
            </div>
            <div>
              <span className='fw-bold'>Nama Kegiatan : </span>
              <span>$nama_kegiatan</span>
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal',
          placeholder: 'Tanggal',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'nomor_laporan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Laporan',
          placeholder: 'Nomor Laporan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'uraian',
    type: 'textarea',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      textarea: {
        props: {
          title: 'Uraian Hasil',
          placeholder: 'Uraian Hasil',
          rows: 3,
        },
      },
    },
  },
]

export {formInputList}
