/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {PdfPageViewer} from '../../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {CardUi} from '../../../../../helper//AppUi'
import {
  TableAction,
  TableInput,
  TableColumn,
  TableRow,
  onLoadProps as onLoadPropsTable,
} from '../../../../../helper//TableInput'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../../helper/AppFunction'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../helper/TableInputDetail'
import {ProtectComponentRoles} from '../../../../../helper/UserRolesValidation'
import ExcelLayout from './ExcelLayout'
import {getData} from '../../../../../helper/FormAxios'
import * as Yup from 'yup'
import inputLayoutBuilder from '../../../../../helper/template/FormInputTemplate'

const onLoadTable = async (e: onLoadPropsTable) => {
  // Custom API with onLoad
  e?.initLoading()
  const eQuery = e.query?.get
  const result = await getData(e?.query?.post(eQuery) || '', InfoData.api)
  e?.load({result: result, query: eQuery})
}

const MainWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data)}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          onFeedback={(e) => {
            if (e?.id === 'lihat') {
              console.log(e)
            }
          }}
          filter={[
            ...inputLayoutBuilder({
              input: [{template: 'wilayah', name: 'wilayah'}],
            }),
            {
              name: 'startDate',
              type: 'input',
              className: 'col-12',
              value: '',
              validator: Yup.string(),
              options: {
                input: {
                  props: {
                    title: 'Tanggal Mulai',
                    placeholder: 'Tanggal Mulai',
                    type: 'date',
                  },
                },
              },
            },
            {
              name: 'endDate',
              type: 'input',
              className: 'col-12',
              value: '',
              validator: Yup.string(),
              options: {
                input: {
                  props: {
                    title: 'Tanggal Selesai',
                    placeholder: 'Tanggal Selesai',
                    type: 'date',
                  },
                },
              },
            }
          ]}
          options={{
            checkbox: [
              {
                title: 'Lihat PDF',
                name: 'lihat-pdf',
                icon: 'RiFilePdfLine',
                type: 'link',
                url: `${InfoData.path}/pdf/pilihan`,
                api: '',
              },
              {
                title: 'Lihat Excel',
                name: 'lihat-excel',
                icon: 'RiFileExcelLine',
                type: 'link',
                url: `${InfoData.path}/pdf/pilihan`,
                api: '',
              },
            ],
          }}
          {...TableInputData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='No'
                    className='min-w-100px'
                  >
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Pengguna Anggaran'
                    className='min-w-150px'
                  >
                    {NullProof({input: l, params: 'pengguna_anggaran.nama'})}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Jenis Perjalanan Dinas'
                    className='min-w-150px'
                  >
                    {NullProof({input: l, params: 'jenis'})}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='No Surat Tugas'
                    className='min-w-150px'
                  >
                    {NullProof({input: l, params: 'nomor_surat'})}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Satker Tujuan'
                    className='min-w-200px'
                  >
                    {NullProof({input: l, params: 'tujuan', isMap: true})?.map(
                      (item: any, index: number) => (
                        <Fragment key={index}>
                          <div>
                            {`${index + 1}. `}
                            <b>Wilayah:</b>{' '}
                            {`${NullProof({
                              input: item,
                              params: 'provinsi',
                            })} - ${NullProof({
                              input: item,
                              params: 'kota',
                            })}`}
                          </div>
                          <div>
                            <b>Satuan Kerja:</b> <br />
                            {(item.satker?.kode || item.satker?.nama) && (
                              <>
                                <span>
                                  {`${NullProof({
                                    input: item,
                                    params: 'satker.kode',
                                  })} - ${NullProof({
                                    input: item,
                                    params: 'satker.nama_satker',
                                  })}`}
                                </span>
                                <br />
                              </>
                            )}
                            {(item.satker_2?.kode || item.satker_2?.nama) && (
                              <>
                                <span>
                                  {`${NullProof({
                                    input: item,
                                    params: 'satker_2.kode',
                                  })} - ${NullProof({
                                    input: item,
                                    params: 'satker_2.nama_satker',
                                  })}`}
                                </span>
                                <br />
                              </>
                            )}
                            {(item.satker_3?.kode || item.satker_3?.nama) && (
                              <>
                                <span>
                                  {`${NullProof({
                                    input: item,
                                    params: 'satker_3.kode',
                                  })} - ${NullProof({
                                    input: item,
                                    params: 'satker_3.nama_satker',
                                  })}`}
                                </span>
                                <br />
                              </>
                            )}
                          </div>
                        </Fragment>
                      )
                    )}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  let InitPdfLayout = PdfLayout
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={InitPdfLayout}
        // justPreview={true}
        ExcelLayout={ExcelLayout}
        options={{
          isRedux: query?.id === 'pilihan',
        }}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Pengusul / Pengguna Anggaran'}}>
              {NullProof({input: data, params: 'pengguna_anggaran.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Jenis Perjalanan Dinas'}}>
              {NullProof({input: data, params: 'jenis'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'No. Surat Tugas'}}>
              {NullProof({input: data, params: 'nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Surat Tugas'}}>
              {NullProof({input: data, params: 'nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Uraian Kegiatan'}}>
              {NullProof({input: data, params: 'deskripsi_kegiatan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Provinsi'}}>
              {NullProof({input: data, params: 'pengguna_anggaran.provinsi'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Jenis Satuan Kerja'}}>
              {NullProof({input: data, params: 'pengguna_anggaran.jenis_satker'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nama Satuan Kerja'}}>
              {NullProof({input: data, params: 'pengguna_anggaran.satuan_kerja'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Wilayah (Wilayah Kerja)'}}>
              {NullProof({input: data, params: 'pengguna_anggaran.provinsi'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Sumber Penugasan'}}>
              {NullProof({input: data, params: 'pengguna_anggaran.provinsi'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Topik/Nama PKPT/Judul Lainnya'}}>
              {NullProof({input: data, params: ''})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kategori'}}>
              {NullProof({input: data, params: ''})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Anggaran Rll'}}>
              {NullProof({input: data, params: 'bukti_perjalanan.jumlah_dibayarkan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'MAK'}}>
              {NullProof({input: data, params: 'mak'})}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, PdfWrapper, DetailWrapper}
