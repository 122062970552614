import {FC, Fragment} from 'react'
import {Document, Page, Text, View, Image} from '@react-pdf/renderer'
import {PdfLayoutDataProps, PdfStyle, PdfTabCol, PdfHeaderApp, PdfSignatureBuilder, PdfStylePallete } from '../../../../helper/ReactPdf'
import {ConvertCurrency, NullProof, capitalizeEachWord, AlphabetIndex,} from '../../../../helper/AppFunction'
import {UrutkanAnggotaSt} from '../../../../helper/KemenagFunction'
import {formatDate} from '../../../../helper/DateFns'
import { DefaultPdfLayoutData } from '../../../../helper/ReactPdf'

const filterProvinsi = (provinsi: string) => {
  if (provinsi.includes('DKI' || 'D.I')){
    const words = provinsi.split(' ')
    words[1] = capitalizeEachWord(words[1])
    return words.join(' ')
  }else{
    return capitalizeEachWord(provinsi)
  }
}

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  return (
    <Document title='Dokumen'>
      <Page
        size={{width: '1800'}}
        style={{...PdfStyle.page, fontFamily: 'Arial', padding: '20pt', gap: 0}}
      >
        {NullProof({input: data.data, params: 'usulan_tim', isMap: true})?.map(
          (l: any, i: number) => (
        <Fragment key={i}>
        <PdfTabCol style={{view: {height: '', fontWeight: 'bold'}}}>
          <PdfTabCol
            center
            border={{right: 1}}
            type='text'
            style={{view: {width: '50pt', textTransform: 'uppercase'}}}
          >
            No
          </PdfTabCol>
          <PdfTabCol
            center
            border={{right: 1}}
            type='text'
            style={{view: {width: '150pt', textTransform: 'uppercase'}}}
          >
            {NullProof({input: l, params: 'tipe_tujuan'}) === 'Luar negeri' ? 'Negara' : 'Provinsi' }
          </PdfTabCol>
          <PdfTabCol
            center
            border={{right: 1}}
            type='text'
            style={{view: {width: '200pt', textTransform: 'uppercase'}}}
          >
            Kota
          </PdfTabCol>
          {NullProof({input: l, params: 'tipe_tujuan'}) !== 'Luar negeri' &&
            <PdfTabCol
              center
              border={{right: 1}}
              type='text'
              style={{view: {width: '150pt', textTransform: 'uppercase'}}}
            >
              {`Satuan\nKerja`}
            </PdfTabCol>
          }
          <PdfTabCol
            center
            border={{right: 1}}
            type='text'
            style={{view: {width: '150pt', textTransform: 'uppercase'}}}
          >
            Keterangan
          </PdfTabCol>
          <PdfTabCol
            center
            direction='column'
            border={{right: 1}}
            style={{view: {width: '500pt', textTransform: 'uppercase'}}}
          >
            <PdfTabCol center border={{bottom: 1}} style={{view: {textTransform: 'uppercase'}}}>
              <PdfTabCol
                center
                border={{}}
                type='text'
                style={{view: {width: '500pt', textTransform: 'uppercase'}}}
              >
                Susunan Tim
              </PdfTabCol>
            </PdfTabCol>
            <PdfTabCol center border={{}} style={{view: {textTransform: 'uppercase'}}}>
              <PdfTabCol
                center
                border={{right: 1}}
                type='text'
                style={{view: {width: '50pt', textTransform: 'uppercase'}}}
              >
                No
              </PdfTabCol>
              <PdfTabCol
                center
                border={{right: 1}}
                type='text'
                style={{view: {width: '200pt', textTransform: 'uppercase'}}}
              >
                Nama
              </PdfTabCol>
              <PdfTabCol
                center
                border={{right: 1}}
                type='text'
                style={{view: {width: '200pt', textTransform: 'uppercase'}}}
              >
                Peran
              </PdfTabCol>
              <PdfTabCol
                center
                border={{}}
                type='text'
                style={{view: {width: '50pt', textTransform: 'uppercase'}}}
              >
                HP
              </PdfTabCol>
            </PdfTabCol>
          </PdfTabCol>
          <PdfTabCol
            center
            border={{right: 1}}
            type='text'
            style={{view: {width: '600pt', textTransform: 'uppercase'}}}
          >
            Rincian RAB
          </PdfTabCol>
          <PdfTabCol
            center
            border={{}}
            type='text'
            style={{view: {width: '200pt', textTransform: 'uppercase'}}}
          >
            Rab
          </PdfTabCol>
        </PdfTabCol>
            <Fragment key={i}>
              <PdfTabCol border={{width: 1, top: 0}} style={{view: {height: ''}}}>
                <PdfTabCol center direction='column' border={{}} style={{view: {width: `${NullProof({input: l, params: 'tipe_tujuan'}) === 'Luar negeri' ? '550pt' : '700pt'}`}}}>
                  {NullProof({input: l, params: 'kepada', isMap: true})?.map(
                    (ls: any, is: number) => (
                      <Fragment key={is}>
                        <PdfTabCol center border={{top: is === 0 ? 0 : 1}} style={{view: {}}}>
                          <PdfTabCol
                            center
                            border={{right: 1}}
                            type='text'
                            style={{view: {width: '50pt'}}}
                          >
                            {i + 1}
                          </PdfTabCol>
                          <PdfTabCol
                            center
                            border={{right: 1}}
                            type='text'
                            style={{view: {width: '150pt'}}}
                          >
                            {NullProof({input: l, params: 'tipe_tujuan'}) === 'Luar negeri' ? 
                            capitalizeEachWord(NullProof({input: l, params: 'negara'})) 
                            : 
                            filterProvinsi(NullProof({input: l, params: 'provinsi'}))
                            }
                          </PdfTabCol>
                          {(NullProof({input: l, params: 'kota'}) !== '' &&
                            NullProof({input: l, params: 'kota2'}) === '') ||
                          NullProof({input: l, params: 'kota2'}) === '_' ? (
                            <PdfTabCol
                              center
                              border={{right: 1}}
                              type='text'
                              style={{view: {width: '200pt'}}}
                            >
                              {capitalizeEachWord(
                                NullProof({input: l, params: 'kota'}).toLowerCase()
                              )}
                            </PdfTabCol>
                          ) : NullProof({input: l, params: 'kota2'}) !== '' &&
                            NullProof({input: l, params: 'kota'}) !== '' ? (
                            <PdfTabCol
                              center
                              border={{right: 1}}
                              style={{view: {width: '200pt'}}}
                              direction='column'
                            >
                              <PdfTabCol
                                center
                                border={{}}
                                type='text'
                                style={{view: {width: '160pt'}}}
                              >
                                {capitalizeEachWord(
                                  NullProof({input: l, params: 'kota'}).toLowerCase()
                                )}
                              </PdfTabCol>
                              <PdfTabCol
                                center
                                border={{top: 1}}
                                type='text'
                                style={{view: {width: '160pt'}}}
                              >
                                {capitalizeEachWord(
                                  NullProof({input: l, params: 'kota2'}).toLowerCase()
                                )}
                              </PdfTabCol>
                            </PdfTabCol>
                          ) : (
                            <PdfTabCol
                              center
                              border={{right: 1}}
                              style={{view: {width: '200pt'}}}
                              direction='column'
                            >
                              <PdfTabCol
                                center
                                border={{right: 1}}
                                type='text'
                                style={{view: {width: '160pt'}}}
                              >
                                {capitalizeEachWord(
                                  NullProof({input: l, params: 'kota'}).toLowerCase()
                                )}
                              </PdfTabCol>
                              <PdfTabCol
                                center
                                border={{top: 1}}
                                type='text'
                                style={{view: {width: '160pt'}}}
                              >
                                {capitalizeEachWord(
                                  NullProof({input: l, params: 'kota2'}).toLowerCase()
                                )}
                              </PdfTabCol>
                              <PdfTabCol
                                center
                                border={{top: 1}}
                                type='text'
                                style={{view: {width: '160pt'}}}
                              >
                                {capitalizeEachWord(
                                  NullProof({input: l, params: 'kota3'}).toLowerCase()
                                )}
                              </PdfTabCol>
                            </PdfTabCol>
                          )}
                          {NullProof({input: l, params: 'tipe_tujuan'}) !== 'Luar negeri' &&
                            <PdfTabCol
                              center
                              border={{right: 1}}
                              type='text'
                              style={{view: {width: '150pt'}}}
                            >
                              {NullProof({input: l, params: 'satker.nama_satker'})}
                            </PdfTabCol>
                          }
                          <PdfTabCol
                            noWrap
                            center
                            border={{right: 1}}
                            type='text'
                            style={{view: {width: '150pt'}}}
                          >
                            {`Antara tgl ${NullProof({
                              input: l,
                              params: 'tanggal_mulai',
                              type: 'date',
                              option: {date: {format: 'd'}},
                            })} s.d. ${NullProof({
                              input: l,
                              params: 'tanggal_selesai',
                              type: 'date',
                            })}`}
                          </PdfTabCol>
                        </PdfTabCol>
                      </Fragment>
                    )
                  )}
                </PdfTabCol>

                {/*  */}
                <PdfTabCol
                  center
                  border={{right: 1}}
                  direction='column'
                  style={{view: {width: '500pt'}}}
                >
                  {UrutkanAnggotaSt(NullProof({input: l, params: 'kepada', isMap: true}))
                    ?.map((ll: any, ii: number) => ({...ll}))
                    ?.map((result: any, iii: number) => (
                      <Fragment key={iii}>
                        <PdfTabCol
                          center
                          border={{top: iii === 0 ? 0 : 1}}
                          style={{
                            view: {
                              fontWeight: ['Supervisor', 'Ketua Tim', 'Penanggung Jawab'].includes(
                                NullProof({input: result, params: 'jabatan'})
                              )
                                ? 'bold'
                                : 'normal',
                            },
                          }}
                        >
                          <PdfTabCol
                            center
                            border={{right: 1}}
                            type='text'
                            style={{view: {width: '50pt'}}}
                          >
                            {iii + 1}
                          </PdfTabCol>
                          <PdfTabCol
                            center
                            noWrap
                            border={{right: 1}}
                            type='text'
                            style={{view: {width: '200pt'}}}
                          >
                            {capitalizeEachWord(
                              NullProof({input: result, params: 'pegawai.nama'}).toLowerCase()
                            )}
                          </PdfTabCol>
                          <PdfTabCol
                            center
                            noWrap
                            border={{right: 1}}
                            type='text'
                            style={{view: {width: '200pt'}}}
                          >
                            {NullProof({input: result, params: 'jabatan'})}
                          </PdfTabCol>
                          <PdfTabCol center border={{}} type='text' style={{view: {width: '50pt'}}}>
                            {NullProof({input: result, params: 'hari'})}
                          </PdfTabCol>
                        </PdfTabCol>
                      </Fragment>
                    ))}
                </PdfTabCol>
                <PdfTabCol
                  center
                  border={{right: 1}}
                  direction='column'
                  style={{view: {width: '600pt'}}}
                >
                  {UrutkanAnggotaSt(NullProof({input: l, params: 'kepada', isMap: true}))
                    ?.map((ll: any, ii: number) => ({...ll}))
                    ?.map((result: any, iii: number) => {
                      const satuanList = [
                        {
                          title: 'Uang Harian',
                          id: 'uang_harian',
                        },
                        {
                          title: 'Uang Hotel',
                          id: 'uang_penginapan',
                        },
                        {
                          title: 'Transport/Tiket (PP) Tanpa BST',
                          id: 'uang_transportasi',
                        },
                        {
                          title: 'BST PP',
                          id: 'bst_full',
                        },
                      ]
                      const SplitTableUi: FC<{column1?: any; column2?: any; border?: boolean}> = ({
                        column1,
                        column2,
                        border,
                      }) => {
                        return (
                          <PdfTabCol
                            center
                            border={{top: border ? 0 : 1}}
                            style={{
                              view: {
                                fontWeight: [
                                  'Supervisor',
                                  'Ketua Tim',
                                  'Penanggung Jawab',
                                ].includes(NullProof({input: result, params: 'jabatan'}))
                                  ? 'bold'
                                  : 'normal',
                              },
                            }}
                          >
                            <PdfTabCol
                              center
                              noWrap
                              border={{right: 1}}
                              type='text'
                              style={{view: {width: '300pt'}}}
                            >
                              {column1}
                            </PdfTabCol>
                            <PdfTabCol
                              center
                              noWrap
                              border={{}}
                              type='text'
                              style={{view: {width: '300pt'}}}
                            >
                              {column2}
                            </PdfTabCol>
                          </PdfTabCol>
                        )
                      }
                      return (
                        <Fragment key={iii}>
                          <SplitTableUi
                            border={iii === 0}
                            column1={`${`${NullProof({
                              input: satuanList[0],
                              params: 'title',
                            })}\n${ConvertCurrency(
                              Number(
                                NullProof({
                                  input: result,
                                  params: satuanList[0].id,
                                })
                              )
                            )}`}`}
                            column2={`${`${NullProof({
                              input: satuanList[1],
                              params: 'title',
                            })}\n${ConvertCurrency(
                              Number(
                                NullProof({
                                  input: result,
                                  params: satuanList[1].id,
                                })
                              )
                            )}`}`}
                          />
                          <SplitTableUi
                            column1={`${`${NullProof({
                              input: satuanList[2],
                              params: 'title',
                            })}\n${ConvertCurrency(
                              Number(
                                NullProof({
                                  input: result,
                                  params: satuanList[2].id,
                                })
                              )
                            )}`}`}
                            column2={`${`${NullProof({
                              input: satuanList[3],
                              params: 'title',
                            })}\n${ConvertCurrency(
                              Number(
                                NullProof({
                                  input: result,
                                  params: satuanList[3].id,
                                })
                              )
                            )}`}`}
                          />
                        </Fragment>
                      )
                    })}
                </PdfTabCol>
                <PdfTabCol center border={{}} type='text' style={{view: {width: '200pt'}}}>
                  {ConvertCurrency(NullProof({input: l, params: 'totalRAB', isLabel: false}) || 0)}
                </PdfTabCol>
              </PdfTabCol>
            </Fragment>
            </Fragment>
          )
        )}
        <View style={{paddingBottom: '100px'}}></View>
      </Page>
    </Document>
  )
}


const PdfLayoutPreviewST: FC<{data: any}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  let usulanTim = NullProof({input: data, params: 'usulan_tim.kepada'}) || []
  let kepada = usulanTim?.map((l: any) => l.kepada) || []
  let sortKepada = kepada?.sort((a: any, b: any) => {
    const jabatanOrder: {[key: string]: number} = {
      'Penanggung Jawab': 1,
      'Pengendali Teknis': 2,
      'Ketua Tim': 3,
      Anggota: 4,
      'Pengendali Mutu': 5,
      'Ketua Sub Tim': 6,
      'Anggota Sub Tim': 7,
    }
    return jabatanOrder[a.jabatan] - jabatanOrder[b.jabatan]
  }) || []
  // console.log("dataaaaa", data)
  // console.log("kkkepada", kepada)

  const twoPagesMode: boolean = data?.usulan_tim?.kepada.length > 6 ? true : false

  const HeaderApp = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          lineHeight: '1',
          padding: '0% 4%',
          letterSpacing: '.5',
        }}
      >
        <Image
          src={DefaultPdfLayoutData.perusahaan?.logo}
          style={{
            width: '90px',
            height: '90px',
            objectFit: 'contain',
            marginRight: '10px',
          }}
        />
        <View style={{width: '100%', marginRight: '70px'}}>
          <View
            style={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                fontSize: PdfStylePallete.typography.title.small,
              }}
            >
              {DefaultPdfLayoutData.perusahaan?.nama}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: PdfStylePallete.typography.label.mini,
              }}
            >
              {DefaultPdfLayoutData.perusahaan?.alamat}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: PdfStylePallete.typography.label.mini,
              }}
            >
              Telepon {DefaultPdfLayoutData.perusahaan?.telepon}; Faksimili {DefaultPdfLayoutData.perusahaan?.fax}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontStyle: 'italic',
                fontSize: PdfStylePallete.typography.label.mini,
              }}
            >
              Website {DefaultPdfLayoutData.perusahaan?.website}
            </Text>
          </View>
        </View>
      </View>
    )
  }

  const LampiranPages = () => {
    return (
      <>
        <Page
          size={'FOLIO'}
          wrap={false}
          style={{
            ...PdfStyle.page,
            position: 'relative',
            gap: '5px',
          }}
        >
          <PdfHeaderApp data={data} customChild={<HeaderApp  />} />
          <View
            style={{
              height: '2px',
              backgroundColor: '#000000',
              // paddingHorizontal: '100px',
              width: '90%',
              marginHorizontal: 'auto',
            }}
          ></View>
          <View
            style={{
              marginHorizontal: 'auto',
              width: '90%',
              fontFamily: 'Arial',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                width: '100%',
              }}
            >
              <Text>Lampiran Surat Tugas</Text>
              <View style={{flexDirection: 'row'}}>
                <Text style={{width: '20%'}}>Nomor</Text>
                <Text style={{width: '2%'}}>:</Text>
                <Text style={{width: '78%'}}>
                  {NullProof({input: data.data, params: 'nomor_surat', label: 'Nomor'})}
                </Text>
              </View>
              <View style={{flexDirection: 'row'}}>
                <Text style={{width: '20%'}}>Tanggal</Text>
                <Text style={{width: '2%'}}>:</Text>
                <Text style={{width: '78%'}}>
                  {NullProof({input: data.data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => {
                      return formatDate({
                        date: l.tanggal_mulai,
                        dateFormat: 'dd MMMM yyyy',
                  })})}
                </Text>
              </View>
              <Text style={{marginTop: '10px', textAlign: 'justify', marginHorizontal: '30px'}}>
                Nama-nama Pegawai{' '}
                {NullProof({input: data, params: 'deskripsi_kegiatan', label: 'Deskripsi'})}{' '}
                {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        {l.tipe_usulan !== 'Luar negeri' ?
                        <View
                          style={{
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {capitalizeEachWord(
                              NullProof({
                                input: l,
                                params: 'satker.nama_satker',
                                label: 'satker',
                              })?.toLowerCase()
                            )}
                            {', '}
                            {!l.kota2 && l.satker2 && (
                              <Text style={{width: '95%', textAlign: 'justify'}}>
                                {capitalizeEachWord(
                                  NullProof({
                                    input: l,
                                    params: 'satker_2.nama_satker',
                                    label: 'satker2',
                                  })?.toLowerCase()
                                )}
                                {', '}
                              </Text>
                            )}
                            {!l.kota3 && l.satker3 && (
                              <Text style={{width: '95%', textAlign: 'justify'}}>
                                {capitalizeEachWord(
                                  NullProof({
                                    input: l,
                                    params: 'satker_3.nama_satker',
                                    label: 'satker3',
                                  })?.toLowerCase()
                                )}
                                {', '}
                              </Text>
                            )}
                            {capitalizeEachWord(
                              NullProof({input: l, params: 'kota', label: 'kota'})?.toLowerCase()
                            )}{' '}
                          </Text>
                          {l.kota2 && NullProof({input: l, params: 'kota2'}) !== '' && (
                            <Text style={{width: '95%', textAlign: 'justify'}}>
                              {l.kota3 ? ', ' : 'dan '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'satker.nama_satker2',
                                  label: 'satker2',
                                })?.toLowerCase()
                              )}
                              {', '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'kota2',
                                  label: 'kota2',
                                })?.toLowerCase()
                              )}{' '}
                            </Text>
                          )}
                          {l.kota3 && NullProof({input: l, params: 'kota3'}) !== '' && (
                            <Text style={{width: '95%', textAlign: 'justify'}}>
                              dan{' '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'satker.nama_satker3',
                                  label: 'satker3',
                                })?.toLowerCase()
                              )}
                              {', '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'kota3',
                                  label: 'kota3',
                                })?.toLowerCase()
                              )}
                              ,{' '}
                            </Text>
                          )}
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {', Provinsi '}
                            {filterProvinsi(NullProof({input: l, params: 'provinsi'}))}
                          </Text>
                        </View>
                        :
                        <View
                          style={{
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {capitalizeEachWord(NullProof({input: l, params: 'kota', label: 'kota'}))}
                            {', Negara'}
                            {capitalizeEachWord(NullProof({input: l, params: 'negara', label: 'negara'}))}
                          </Text>
                        </View>
                        }
                      </Fragment>
                    )
                  )}
              </Text>
            </View>
            <View
              style={{
                marginTop: '10px',
                marginHorizontal: '30px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  borderBottom: '1px solid black',
                  borderTop: '1px solid black',
                }}
              >
                <Text
                  style={{
                    width: '10%',
                    borderRight: '1px solid black',
                    borderLeft: '1px solid black',
                    textAlign: 'center',
                    fontWeight: 700,
                  }}
                >
                  NO
                </Text>
                <Text
                  style={{
                    width: '60%',
                    borderRight: '1px solid black',
                    textAlign: 'center',
                    fontWeight: 700,
                  }}
                >
                  Nama
                </Text>
                <Text
                  style={{
                    width: '40%',
                    borderRight: '1px solid black',
                    textAlign: 'center',
                    fontWeight: 700,
                  }}
                >
                  Jabatan
                </Text>
                <Text
                  style={{
                    width: '30%',
                    borderRight: '1px solid black',
                    textAlign: 'center',
                    fontWeight: 700,
                  }}
                >
                  Waktu Tugas
                </Text>
              </View>
              {NullProof({input: data, params: 'usulan_tim.kepada', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                        borderBottom: '1px solid black',
                      }}
                    >
                      <Text
                        style={{
                          width: '10%',
                          textAlign: 'center',
                          borderRight: '1px solid black',
                          borderLeft: '1px solid black',
                        }}
                      >
                        {i + 1}
                      </Text>
                      <View style={{width: '60%', borderRight: '1px solid black'}}>
                        <Text>
                          {' '}
                          {capitalizeEachWord(
                            NullProof({
                              input: l,
                              params: 'pegawai.nama',
                              label: 'Nama',
                            })?.toLowerCase()
                          )}
                        </Text>
                        <Text> {NullProof({input: l, params: 'pegawai.nip', label: 'NIP'})}</Text>
                      </View>
                      <Text
                        style={{
                          width: '40%',
                          textAlign: 'center',
                          borderRight: '1px solid black',
                          paddingVertical: '10px',
                        }}
                      >
                        {' '}
                        {NullProof({input: l, params: 'jabatan', label: 'Jabatan'})}
                      </Text>
                      <Text
                        style={{
                          width: '30%',
                          textAlign: 'center',
                          borderRight: '1px solid black',
                          paddingVertical: '10px',
                        }}
                      >
                        {' '}
                        {NullProof({
                          input: l,
                          params: 'hari',
                          label: 'Hari',
                          isLabel: false,
                        }) || 0}{' '}
                        Hari
                      </Text>
                    </View>
                  </Fragment>
                )
              )}
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: '20px',
                marginRight: '100px',
              }}
            >
              <PdfSignatureBuilder
                data={[
                  {
                    line1: `${NullProof({
                      input: data.data,
                      params: 'lokasi',
                    })}, ${formatDate({
                        date: NullProof({input: data, params: 'usulan_tim.tanggal_mulai', label: 'lokasi'}) || '',
                        dateFormat: 'dd MMMM yyyy',
                      })}`,
                    line2: `${NullProof({
                      input: data.data,
                      params: 'penandatangan_2_detail.atas_nama',
                      label: 'Atas Nama',
                    })}`,
                    line3: `${NullProof({
                      input: data.data,
                      params: 'penandatangan_2.jabatan.tampil_jabatan',
                      label: 'Jabatan',
                    })}`,
                    typeSignature: 'string',
                    signature: '^',
                    name: `${capitalizeEachWord(
                      NullProof({input: data.data, params: 'penandatangan_2.nama'}).toLowerCase()
                    )}`,
                    align: 'flex-start',
                  },
                ]}
                options={{
                  align: 'flex-end',
                  data: {
                    align: 'flex-start',
                    signature: {style: {textAlign: 'left', marginLeft: '20pt'}},
                    line2StyleView: {marginBottom: 0},
                    line2Style: {textAlign: 'left', marginLeft: '-20pt'},
                    line3Style: {textAlign: 'left'},
                  },
                }}
                style={{paddingRight: '0', marginBottom: '50pt'}}
              />
            </View>
          </View>
          {data?.data.tembusan?.length > 0 && (
            <View
              style={{
                marginBottom: '15px',
                width: '90%',
                marginHorizontal: 'auto',
              }}
            >
              <Text>Tembusan</Text>
              {/* <Text>Inspektur Jenderal Kementerian Agama</Text> */}

              {NullProof({input: data, params: 'tembusan', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      <Text style={{width: '5%', textTransform: 'lowercase'}}>
                        {AlphabetIndex(i)}.
                      </Text>
                      <Text style={{width: '95%', textAlign: 'justify'}}>
                        {NullProof({input: l, params: 'detail', label: 'Detail'})}
                      </Text>
                    </View>
                  </Fragment>
                )
              )}
            </View>
          )}
          <View
            style={{
              padding: '2pt',
              border: '2px solid black',
              fontWeight: 700,
              width: '90%',
              marginHorizontal: 'auto',
            }}
          >
            <PdfTabCol
              type='text'
              border={{}}
              style={{view: {padding: '5pt', justifyContent: 'center', textAlign: 'left'}}}
              noWrap
            >
              Pegawai Inspektorat Jenderal Kementerian Agama tidak menerima GRATIFIKASI dalam
              melaksanakan tugas.
            </PdfTabCol>
          </View>
          <View style={{paddingBottom: '100px'}}></View>
        </Page>
      </>
    )
  }
  return (
    <Document title='Dokumen'>
      <Page
        size={'FOLIO'}
        wrap={false}
        style={{
          ...PdfStyle.page,
          position: 'relative',
          gap: '5px',
        }}
      >
        <PdfHeaderApp data={data} customChild={<HeaderApp  />} />
        <View
          style={{
            height: '2px',
            backgroundColor: '#000000',
            width: '90%',
            marginHorizontal: 'auto',
          }}
        ></View>
        <View
          style={{
            marginHorizontal: 'auto',
            width: '90%',
            justifyContent: 'center',
            letterSpacing: '0.5px',
          }}
        >
          <View
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Text
              style={{
                textTransform: 'uppercase',
              }}
            >
              Surat Tugas
            </Text>
            <Text>
              NOMOR: {NullProof({input: data, params: 'nomor_surat', label: 'Nomor Surat'})}
            </Text>
          </View>
          <View style={{}}>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: '5px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '15%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '95%'}}>Menimbang</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '85%',
                }}
              >
                {NullProof({input: data, params: 'menimbang', isMap: true})?.map(
                  (l: any, i: number) => (
                    <Fragment key={i}>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <Text style={{width: '5%', textTransform: 'lowercase'}}>
                          {AlphabetIndex(i)}.
                        </Text>
                        <Text style={{width: '95%', textAlign: 'justify'}}>
                          {NullProof({input: l, params: 'detailMenimbang', label: 'Detail'})}
                        </Text>
                      </View>
                    </Fragment>
                  )
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: '5px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '15%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '90%'}}>Dasar</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '85%',
                }}
              >
                {NullProof({input: data, params: 'dasarnya', isMap: true})?.map(
                  (l: any, i: number) => (
                    <Fragment key={i}>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <Text style={{width: '5%', textTransform: 'lowercase'}}>{i + 1}.</Text>
                        <Text style={{width: '95%', textAlign: 'justify'}}>
                          {NullProof({input: l, params: 'detail', label: 'Detail'})}{' '}
                          {data.data.dasarnya[data.data.dasarnya.length - 1] === l
                            ? ` MAK ${NullProof({input: data.data, params: 'mak'})}`
                            : ''}
                        </Text>
                      </View>
                    </Fragment>
                  )
                )}
              </View>
            </View>
            <Text style={{width: '100%', textAlign: 'center'}}>Memberi Tugas</Text>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '15%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '90%'}}>Kepada</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '85%',
                }}
              >
                {twoPagesMode ? (
                  <Text>Nama-nama sebagaimana terlampir dalam surat tugas ini</Text>
                ) : (
                  <>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      <Text style={{width: '10%'}}>No.</Text>
                      <Text style={{width: '40%'}}>Nama/NIP</Text>
                      <Text style={{width: '35%'}}>Jabatan</Text>
                      <Text style={{width: '20%', textAlign: 'center'}}>Waktu Tugas</Text>
                    </View>
                    {NullProof({input: data, params: 'usulan_tim.kepada', isMap: true})
                      ?.map((ll: any, ii: number) => ({...ll}))
                      .sort((a: any, b: any) => {
                        const jabatanOrder: {[key: string]: number} = {
                          'Penanggung Jawab': 1,
                          'Pengendali Teknis': 2,
                          'Ketua Tim': 3,
                          Anggota: 4,
                          'Pengendali Mutu': 5,
                          'Ketua Sub Tim': 6,
                          'Anggota Sub Tim': 7,
                        }

                        return jabatanOrder[a.jabatan] - jabatanOrder[b.jabatan]
                      })
                      ?.map((l: any, i: number) => (
                        <Fragment key={i}>
                          <View
                            style={{
                              flexDirection: 'row',
                              width: '100%',
                            }}
                          >
                            <Text style={{width: '10%'}}>{i + 1}.</Text>
                            <View style={{width: '40%'}}>
                              <Text>
                                {capitalizeEachWord(
                                  NullProof({
                                    input: l,
                                    params: 'pegawai.nama',
                                    label: 'Nama',
                                  })?.toLowerCase()
                                )}
                              </Text>
                              <Text>
                                {NullProof({input: l, params: 'pegawai.nip', label: 'NIP'})}
                              </Text>
                            </View>
                            <Text style={{width: '35%'}}>
                              {NullProof({input: l, params: 'jabatan', label: 'Jabatan'})}
                            </Text>
                            <Text style={{width: '20%', textAlign: 'center'}}>
                              {NullProof({
                                input: l,
                                params: 'hari',
                                label: 'Hari',
                                isLabel: false,
                              }) || 0}{' '}
                              Hari
                            </Text>
                          </View>
                        </Fragment>
                      ))}
                  </>
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: '5px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '15%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '90%'}}>Untuk</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '85%',
                }}
              >
                <Text>
                  {NullProof({input: data, params: 'deskripsi_kegiatan', label: 'Deskripsi'})}{' '}
                  {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        {l.tipe_usulan !== 'Luar negeri' ?
                        <View
                          style={{
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {capitalizeEachWord(
                              NullProof({
                                input: l,
                                params: 'satker.nama_satker',
                                label: 'satker',
                              })?.toLowerCase()
                            )}
                            {', '}
                            {!l.kota2 && l.satker2 && (
                              <Text style={{width: '95%', textAlign: 'justify'}}>
                                {capitalizeEachWord(
                                  NullProof({
                                    input: l,
                                    params: 'satker.nama_satker2',
                                    label: 'satker2',
                                  })?.toLowerCase()
                                )}
                                {', '}
                              </Text>
                            )}
                            {!l.kota3 && l.satker3 && (
                              <Text style={{width: '95%', textAlign: 'justify'}}>
                                {capitalizeEachWord(
                                  NullProof({
                                    input: l,
                                    params: 'satker.nama_satker3',
                                    label: 'satker3',
                                  })?.toLowerCase()
                                )}
                                {', '}
                              </Text>
                            )}
                            {capitalizeEachWord(
                              NullProof({input: l, params: 'kota', label: 'kota'})?.toLowerCase()
                            )}{' '}
                          </Text>
                          {l.kota2 && NullProof({input: l, params: 'kota2'}) !== '' && (
                            <Text style={{width: '95%', textAlign: 'justify'}}>
                              {l.kota3 ? ', ' : 'dan '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'satker_2.nama_satker',
                                  label: 'satker2',
                                })?.toLowerCase()
                              )}
                              {', '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'kota2',
                                  label: 'kota2',
                                })?.toLowerCase()
                              )}{' '}
                            </Text>
                          )}
                          {l.kota3 && NullProof({input: l, params: 'kota3'}) !== '' && (
                            <Text style={{width: '95%', textAlign: 'justify'}}>
                              dan{' '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'satker_3.nama_satker',
                                  label: 'satker3',
                                })?.toLowerCase()
                              )}
                              {', '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'kota3',
                                  label: 'kota3',
                                })?.toLowerCase()
                              )}
                              ,{' '}
                            </Text>
                          )}
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {', Provinsi '}
                            {filterProvinsi(NullProof({input: l, params: 'provinsi'}))}
                          </Text>
                        </View>
                        :
                        <View
                          style={{
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {capitalizeEachWord(NullProof({input: l, params: 'kota', label: 'kota'}))}
                            {', Negara'}
                            {capitalizeEachWord(NullProof({input: l, params: 'negara', label: 'negara'}))}
                          </Text>
                        </View>
                        }
                      </Fragment>
                    )
                  )}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: '5px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '15%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '90%'}}>Waktu</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '85%',
                }}
              >
                <Text>
                  Pelaksanaan tugas diantara tanggal{' '}
                  {formatDate({
                    date:
                      NullProof({input: data, params: 'usulan_tim.tanggal_mulai', isLabel: false}) || '',
                    dateFormat: 'dd MMMM',
                  })}{' '}
                  s.d{' '}
                  {formatDate({
                    date:
                      NullProof({input: data, params: 'usulan_tim.tanggal_selesai', isLabel: false}) ||
                      '',
                    dateFormat: 'dd MMMM yyyy',
                  })}
                </Text>

              </View>
            </View>
            <View>
              <Text>
                Laporan disampaikan kepada pemberi tugas paling lambat 10 hari setelah
                pelaksanaan tugas.
              </Text>
            </View>
            <PdfSignatureBuilder
              data={[
                {
                  line1: `${NullProof({
                    input: data.data,
                    params: 'lokasi',
                  })}, ${formatDate({
                        date: NullProof({input: data, params: 'usulan_tim.tanggal_mulai', label: 'lokasi'}) || '',
                        dateFormat: 'dd MMMM yyyy',
                      })}`,
                  line2: `${NullProof({
                    input: data.data,
                    params: 'penandatangan_2_detail.atas_nama',
                    label: 'Atas Nama',
                  })}`,
                  line3: `${NullProof({
                    input: data.data,
                    params: 'penandatangan_2.jabatan.tampil_jabatan',
                    label: 'Jabatan',
                  })}`,
                  typeSignature: 'string',
                  signature: '*',
                  name: `${capitalizeEachWord(
                    NullProof({input: data.data, params: 'penandatangan_2.nama'}).toLowerCase()
                  )}`,
                  align: 'flex-start',
                },
              ]}
              options={{
                align: 'flex-end',
                data: {
                  align: 'flex-start',
                  signature: {style: {textAlign: 'left', marginLeft: '20pt'}},
                  line2StyleView: {marginBottom: 0},
                  line2Style: {textAlign: 'left', marginLeft: '-20pt'},
                  line3Style: {textAlign: 'left'},
                },
              }}
              style={{paddingRight: '0', marginBottom: '50pt'}}
            />
          </View>
        </View>
        <View style={{paddingBottom: '120px'}}></View>
        <View
          style={{
            fontFamily: 'Arial',
            position: 'absolute',
            bottom: '100pt',
            left: 0,
            width: '100vw',
            marginHorizontal: 'auto',
            padding: '2% 6%',
            letterSpacing: '0.5px',
          }}
        >
          {data.data?.tembusan?.length > 0 && (
            <View
              style={{
                marginBottom: '10px',
                padding: '0% 4%',
              }}
            >
              <Text>Tembusan</Text>
              {/* <Text>Inspektur Jenderal Kementerian Agama</Text> */}

              {NullProof({input: data, params: 'tembusan', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      {i > 0 && (
                        <Text style={{width: '5%', textTransform: 'lowercase'}}>
                          {AlphabetIndex(i)}.
                        </Text>
                      )}
                      <Text style={{width: '95%', textAlign: 'justify'}}>
                        {NullProof({input: l, params: 'detail', label: 'Detail'})}
                      </Text>
                    </View>
                  </Fragment>
                )
              )}
            </View>
          )}
          <View
            style={{
              padding: '10px 10px',
              margin: '2% 4%',
              border: '2px solid black',
              fontWeight: 700,
            }}
          >
            <Text style={{gap: '5px'}}>
              Pegawai Inspektorat Jenderal Kementerian Agama tidak menerima{'\n'}
              GRATIFIKASI dalam melaksanakan tugas.
            </Text>
          </View>
        </View>
        <View style={{paddingBottom: '70px'}}></View>
      </Page>
      {twoPagesMode && <LampiranPages />}
    </Document>
  )
}

export {PdfLayout, PdfLayoutPreviewST}
