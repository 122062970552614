import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'

const componentLinkEvident: FormInputProps[] = [
  {
    name: 'link_evidence',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Link Evidence Februari',
          placeholder: 'Link Evidence Februari',
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'evaluasi_depan_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Evaluasi Depan Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Evaluasi Depan',
          placeholder: 'Evaluasi Depan',
        },
      },
      datalist: {
        api: '/pengawasan_internal/form-evaluasi-depan',
        query: 'orderby=asc',
        params: 'satker.nama_satker,penandatangan_komitmen.nama,target_waktu',
        result: (
          <>
            <div>
              <span className='fw-bold'>Satuan Kerja : </span>
              <span>$satker.nama_satker</span>
            </div>
            <div>
              <span className='fw-bold'>Penendatangan Komitmen : </span>
              <span>$penandatangan_komitmen.nama</span>
            </div>
            <div>
              <span className='fw-bold'>Target : </span>
              <span>$target_waktu</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'satker.nama_satker', type: 'text'},
            {id: 'penandatangan_komitmen.nama', type: 'text'},
            {id: 'target_waktu', type: 'date'},
            // {id: 'level_jabatan', type: 'text'},
            // {id: 'satker_4', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/pengawasan_internal/form-evaluasi-depan',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Satuan Kerja'},
              {title: 'Penandatangan Komitmen'},
              {title: 'Target Waktu'},
              // {title: 'Satker'},
            ],
          },
        },
      },
    },
  },
  {
    name: 'bulan',
    type: 'select',
    className: 'col-12',
    validator: Yup.string().required('Bulan Mohon untuk diisi.'),
    listData: [
      {title: 'Februari', value: 'Februari'},
      {title: 'Maret', value: 'Maret'},
      {title: 'April', value: 'April'},
      {title: 'Mei', value: 'Mei'},
      {title: 'Juni', value: 'Juni'},
      {title: 'Juli', value: 'Juli'},
      {title: 'Agustus', value: 'Agustus'},
      {title: 'September', value: 'September'},
      {title: 'Oktober', value: 'Oktober'},
    ],
    options: {
      input: {
        props: {
          title: 'bulan',
          placeholder: 'bulan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'link_evidence',
    type: 'input',
    value: '',
    validator: Yup.string().required('Link Evidence Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Link Evidence',
          placeholder: 'Link Evidence',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'keterangan',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Keterangan Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Keterangan',
          placeholder: 'Keterangan',
        },
      },
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'link_evidence_februari',
  //   type: 'component',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Link Evidence Februari',
  //         placeholder: 'Link Evidence Februari',
  //       },
  //     },
  //   },
  //   component: componentLinkEvident,
  // },
  // {
  //   className: 'col-12',
  //   name: 'link_evidence_maret',
  //   type: 'component',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Link Evidence Maret',
  //         placeholder: 'Link Evidence Maret',
  //       },
  //     },
  //   },
  //   component: componentLinkEvident
  // },
  // {
  //   className: 'col-12',
  //   name: 'link_evidence_april',
  //   type: 'component',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Link Evidence April',
  //         placeholder: 'Link Evidence April',
  //       },
  //     },
  //   },
  //   component: componentLinkEvident
  // },
  // {
  //   className: 'col-12',
  //   name: 'link_evidence_mei',
  //   type: 'component',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Link Evidence Mei',
  //         placeholder: 'Link Evidence Mei',
  //       },
  //     },
  //   },
  //   component: componentLinkEvident
  // },
  // {
  //   className: 'col-12',
  //   name: 'link_evidence_juni',
  //   type: 'component',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Link Evidence Juni',
  //         placeholder: 'Link Evidence Juni',
  //       },
  //     },
  //   },
  //   component: componentLinkEvident
  // },
  // {
  //   className: 'col-12',
  //   name: 'link_evidence_juli',
  //   type: 'component',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Link Evidence Juli',
  //         placeholder: 'Link Evidence Juli',
  //       },
  //     },
  //   },
  //   component: componentLinkEvident
  // },
  // {
  //   className: 'col-12',
  //   name: 'link_evidence_agustus',
  //   type: 'component',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Link Evidence Agustus',
  //         placeholder: 'Link Evidence Agustus',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'link_evidence_september',
  //   type: 'component',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Link Evidence September',
  //         placeholder: 'Link Evidence September',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'link_evidence_oktober',
  //   type: 'component',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Link Evidence Oktober',
  //         placeholder: 'Link Evidence Oktober',
  //       },
  //     },
  //   },
  // }
]

export {formInputList}
