import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfLayoutDataProps,
  PdfStyle,
  PdfFontBuilder,
  PdfTableColumnBuilder,
  PdfTabCol,
} from '../../../helper/ReactPdf'
import {ConvertCurrency, NullProof} from '../../../helper/AppFunction'

const Letterhead: FC<{data: any; halaman: number}> = ({data, halaman}) => {
  const kode_kemen = data?.kode_program?.split('.')[0]
  const kode_unit_org = data?.kode_program?.split('.')[1]
  type LetterheadProps = {
    data: {
      label: string
      number: string
      value?: string
    }[]
  }
  const dataLetterhead: LetterheadProps = {
    data: [
      {label: 'KEMEN/LEMB', number: kode_kemen, value: 'Kementrian Agama'},
      {label: 'unit org', number: kode_unit_org, value: 'Inspektorat Jenderal'},
      {label: 'unit kerja', number: '426282', value: 'Inspektorat Jenderal Kementrian Agama RI'},
      {label: 'alokasi', number: data?.alokasi_dana},
    ],
  }
  return (
    <Fragment>
      <View style={{fontWeight: 'bold', textAlign: 'center'}}>
        <Text
          style={{
            textTransform: 'uppercase',
            ...PdfFontBuilder({template: 'header', size: 'default'}),
          }}
        >
          RINCIAN KERTAS KERJA SATKER T.A. {NullProof({input: data, params: 'tahun'})}
        </Text>
      </View>
      <View style={{fontSize: '14pt'}}>
        {NullProof({input: dataLetterhead, params: 'data', isMap: true})?.map(
          (l: any, i: number) => {
            const isLast: boolean = dataLetterhead?.data.length - 1 == i
            return (
              <View key={i} style={{display: 'flex', flexDirection: 'row', marginBottom: 5}}>
                <PdfTabCol
                  border={{width: 0}}
                  style={{view: {textAlign: 'left', width: `${isLast ? '18%' : '25%'}`}}}
                >
                  <Text
                    style={{
                      textTransform: 'uppercase',
                    }}
                  >
                    {NullProof({input: l, params: 'label'})}
                  </Text>
                </PdfTabCol>
                <PdfTabCol border={{width: 0}} style={{view: {width: `${isLast ? '' : '15%'}`}}}>
                  <Text>
                    {NullProof({
                      input: l,
                      params: 'number',
                      type: `${isLast ? 'currency' : 'text'}`,
                    }) || 0}
                  </Text>
                </PdfTabCol>
                {l.value ? (
                  <PdfTabCol border={{width: 0}}>
                    <Text
                      style={{
                        textAlign: 'left',
                        textTransform: `${i === 1 ? 'capitalize' : 'uppercase'}`,
                      }}
                    >
                      {NullProof({input: l, params: 'value'})}
                    </Text>
                  </PdfTabCol>
                ) : null}
              </View>
            )
          }
        )}
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: 30,
          textAlign: 'right',
          paddingRight: 25,
        }}
      >
        <Text
          style={{
            textTransform: 'capitalize',
            borderBottom: 1,
            fontSize: '14pt',
          }}
        >
          Halaman:
        </Text>
        <Text
          style={{
            borderBottom: 1,
            fontSize: '14pt',
          }}
        >
          {halaman ? halaman : '-'}
        </Text>
      </View>
    </Fragment>
  )
}

const HeaderContent: FC<{data: any}> = ({data}) => {
  return (
    <View style={{position: 'relative', fontSize: '14pt', minHeight: 80}}>
      <PdfTableColumnBuilder
        borderBottom
        style={{textTransform: 'uppercase', fontWeight: 'medium', height: '60pt'}}
      >
        <PdfTableColumnBuilder center borderRight style={{width: '10%'}}>
          <Text>KODE</Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '35%'}}>
          <Text
            style={{
              padding: '5px 15px',
              textAlign: 'center',
            }}
          >
            program kegiatan/kro/ro/komponen/subkomp/detil
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder direction='column' center borderRight style={{width: '52%'}}>
          <PdfTableColumnBuilder center borderBottom style={{width: '100%'}}>
            <Text
              style={{
                textAlign: 'center',
              }}
            >
              perhitungan tahun {NullProof({input: data, params: 'tahun'})}
            </Text>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight style={{width: '25%'}}>
              <Text>volume</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight style={{width: '30%'}}>
              <Text>harga satuan</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center style={{width: '45%'}}>
              <Text>jumlah biaya</Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center style={{width: '3%'}}>
          <Text style={{textAlign: 'center'}}>sd/ cp</Text>
        </PdfTableColumnBuilder>
      </PdfTableColumnBuilder>
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          borderBottom: '1px solid black',
        }}
        wrap
      >
        <PdfTabCol border={{right: 1}} center style={{view: {width: '10%'}}}>
          <Text style={{paddingVertical: 4}}>(1)</Text>
        </PdfTabCol>
        <PdfTabCol border={{right: 1}} center style={{view: {width: '35%'}}}>
          <Text style={{paddingVertical: 4}}>(2)</Text>
        </PdfTabCol>
        <PdfTabCol border={{right: 1}} center style={{view: {width: '52%'}}}>
          <PdfTabCol border={{right: 1}} center style={{view: {width: '25%'}}}>
            <Text style={{paddingVertical: 4}}>(3)</Text>
          </PdfTabCol>
          <PdfTabCol border={{right: 1}} center style={{view: {width: '30%'}}}>
            <Text style={{paddingVertical: 4}}>(4)</Text>
          </PdfTabCol>
          <PdfTabCol border={{width: 0}} center style={{view: {width: '45%'}}}>
            <Text style={{paddingVertical: 4}}>(5)</Text>
          </PdfTabCol>
        </PdfTabCol>
        <PdfTabCol border={{width: 0}} center style={{view: {width: '3%'}}}>
          <Text style={{paddingVertical: 4}}>(6)</Text>
        </PdfTabCol>
      </View>
    </View>
  )
}

const RowTable: FC<{
  childrenKode?: React.ReactNode
  childrenProgram?: React.ReactNode
  childrenVolume?: React.ReactNode
  childrenHargaSatuan?: React.ReactNode
  childrenJumlahBiaya?: React.ReactNode
  childrenKodePerhitungan?: React.ReactNode
  childrenSdCp?: React.ReactNode
  height?: number | string
}> = ({
  childrenKode,
  childrenProgram,
  childrenVolume,
  childrenHargaSatuan,
  childrenJumlahBiaya,
  childrenKodePerhitungan,
  childrenSdCp,
  height = 'auto',
}) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: height,
        fontSize: '12pt',
      }}
    >
      {/* === kode === */}
      <PdfTableColumnBuilder
        borderRight
        style={{width: '10%', textAlign: 'left', paddingBottom: 10}}
      >
        {childrenKode}
      </PdfTableColumnBuilder>
      {/* === Program === */}
      <PdfTableColumnBuilder
        borderRight
        style={{
          width: '35%',
          paddingBottom: 10,
        }}
      >
        {childrenProgram}
      </PdfTableColumnBuilder>
      {/* === Perhitungan Tahunan === */}
      <PdfTableColumnBuilder style={{display: 'flex', flexDirection: 'row', width: '52%'}}>
        <PdfTableColumnBuilder borderRight style={{width: '25%'}}>
          {childrenVolume}
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '30%'}}>
          {childrenHargaSatuan}
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder borderRight style={{width: '40%'}}>
          {childrenJumlahBiaya}
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder borderRight style={{width: '5%'}}>
          {childrenKodePerhitungan}
        </PdfTableColumnBuilder>
      </PdfTableColumnBuilder>
      <PdfTableColumnBuilder style={{width: '3%'}}>{childrenSdCp}</PdfTableColumnBuilder>
    </View>
  )
}

const PdfLayoutSemua: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  let detailList: {tanggal: string; saldo: number}[] = []
  return (
    <Document title='Dokumen'>
      {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => (
        <Page
          key={i}
          size={{width: '1000'}}
          style={{...PdfStyle.page, fontFamily: 'Arial', padding: '20pt'}}
        >
          <Letterhead data={l} halaman={i + 1} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid black',
              fontSize: '12pt',
            }}
            wrap
          >
            {/* Header */}
            <HeaderContent data={l} />
            {/* Looping Here */}
            {NullProof({input: l, params: 'detail', isMap: true})?.map((detail: any, i: number) => (
              <Fragment key={i}>
                {/* 1 */}
                <RowTable
                  childrenKode={
                    <PdfTableColumnBuilder>
                      <Text
                        color='#FF0000'
                        style={{
                          color: 'red',
                          paddingLeft: '10px',
                        }}
                      >
                        {NullProof({input: detail, params: 'kode_kegiatan'})}
                      </Text>
                    </PdfTableColumnBuilder>
                  }
                  childrenProgram={
                    <PdfTableColumnBuilder>
                      <Text
                        style={{
                          color: 'red',
                        }}
                      >
                        {NullProof({input: detail, params: 'nama_kegiatan'})}
                      </Text>
                    </PdfTableColumnBuilder>
                  }
                  childrenJumlahBiaya={
                    <PdfTableColumnBuilder>
                      <Text
                        style={{
                          color: 'red',
                          textAlign: 'right',
                          width: '100%',
                        }}
                      >
                        {ConvertCurrency(
                          NullProof({input: detail, params: 'jumlah_harga', isLabel: false}) || 0
                        )}
                      </Text>
                    </PdfTableColumnBuilder>
                  }
                />
                {/* 2 */}
                <RowTable
                  childrenKode={
                    <PdfTableColumnBuilder>
                      <Text
                        style={{
                          color: 'blue',
                          paddingLeft: '10px',
                        }}
                      >
                        {NullProof({input: detail, params: 'kode_komponen'})}
                      </Text>
                    </PdfTableColumnBuilder>
                  }
                  childrenProgram={
                    <PdfTableColumnBuilder>
                      <Text
                        style={{
                          color: 'blue',
                        }}
                      >
                        {NullProof({input: detail, params: 'nama_komponen'})}
                      </Text>
                    </PdfTableColumnBuilder>
                  }
                  childrenJumlahBiaya={
                    <PdfTableColumnBuilder>
                      <Text
                        style={{
                          color: 'blue',
                          textAlign: 'right',
                          width: '100%',
                        }}
                      >
                        {ConvertCurrency(
                          NullProof({input: detail, params: 'jumlah_harga', isLabel: false}) || 0
                        )}
                      </Text>
                    </PdfTableColumnBuilder>
                  }
                />
                {/* 3 */}
                <RowTable
                  height={2}
                  childrenKode={
                    <PdfTabCol border={{bottom: 2}} style={{view: {borderBottomStyle: 'dashed'}}} />
                  }
                  childrenProgram={
                    <PdfTabCol border={{bottom: 2}} style={{view: {borderBottomStyle: 'dashed'}}} />
                  }
                />
                {/* 4 */}
                {NullProof({input: detail, params: 'program', isMap: true})?.map(
                  (program: any, i: number) => (
                    <Fragment key={i}>
                      <RowTable
                        childrenProgram={
                          <PdfTabCol border={{width: 0}}>
                            <Text
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              Lokasi : {NullProof({input: program, params: 'lokasi'})}
                            </Text>
                          </PdfTabCol>
                        }
                      />
                      <RowTable
                        childrenKode={
                          <PdfTableColumnBuilder>
                            <Text
                              style={{
                                paddingLeft: '10px',
                                fontWeight: 'bold',
                              }}
                            >
                              {NullProof({input: program, params: 'kode'})}
                            </Text>
                          </PdfTableColumnBuilder>
                        }
                        childrenProgram={
                          <PdfTableColumnBuilder>
                            <Text
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {NullProof({input: program, params: 'nama'})}
                            </Text>
                          </PdfTableColumnBuilder>
                        }
                        childrenJumlahBiaya={
                          <PdfTableColumnBuilder direction='column'>
                            <Text
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              {ConvertCurrency(
                                NullProof({
                                  input: program,
                                  params: 'jumlah_harga',
                                  isLabel: false,
                                }) || 0
                              )}
                            </Text>
                          </PdfTableColumnBuilder>
                        }
                      />
                      {/* 5 */}
                      {NullProof({input: program, params: 'kegiatan', isMap: true})?.map(
                        (kegiatan: any, i: number) => (
                          <Fragment key={i}>
                            <RowTable
                              childrenKode={
                                <PdfTableColumnBuilder>
                                  <Text
                                    style={{
                                      paddingLeft: '10px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {NullProof({input: kegiatan, params: 'kode'})}
                                  </Text>
                                </PdfTableColumnBuilder>
                              }
                              childrenProgram={
                                <PdfTableColumnBuilder>
                                  <Text
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {NullProof({input: kegiatan, params: 'nama'})}
                                  </Text>
                                </PdfTableColumnBuilder>
                              }
                              childrenJumlahBiaya={
                                <PdfTableColumnBuilder direction='column'>
                                  <Text
                                    style={{
                                      textAlign: 'right',
                                      width: '100%',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {ConvertCurrency(
                                      NullProof({
                                        input: kegiatan,
                                        params: 'jumlah_harga',
                                        isLabel: false,
                                      }) || 0
                                    )}
                                  </Text>
                                </PdfTableColumnBuilder>
                              }
                              childrenKodePerhitungan={
                                <PdfTableColumnBuilder>
                                  <Text
                                    style={{
                                      textAlign: 'center',
                                      width: '100%',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    U
                                  </Text>
                                </PdfTableColumnBuilder>
                              }
                            />
                            {/* 6 */}
                            {NullProof({input: kegiatan, params: 'subkegiatan', isMap: true})?.map(
                              (subkegiatan: any, i: number) => (
                                <Fragment key={i}>
                                  <RowTable
                                    childrenKode={
                                      <PdfTableColumnBuilder>
                                        <Text
                                          style={{
                                            paddingLeft: '20px',
                                          }}
                                        >
                                          {NullProof({input: subkegiatan, params: 'kode'})}
                                        </Text>
                                      </PdfTableColumnBuilder>
                                    }
                                    childrenProgram={
                                      <PdfTableColumnBuilder>
                                        <Text
                                          style={{
                                            fontStyle: 'italic',
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {NullProof({input: subkegiatan, params: 'nama'})}
                                        </Text>
                                      </PdfTableColumnBuilder>
                                    }
                                    childrenJumlahBiaya={
                                      <PdfTableColumnBuilder direction='column'>
                                        <Text
                                          style={{
                                            textAlign: 'right',
                                            width: '100%',
                                          }}
                                        >
                                          {ConvertCurrency(
                                            NullProof({
                                              input: subkegiatan,
                                              params: 'jumlah_harga',
                                              isLabel: false,
                                            }) || 0
                                          )}
                                        </Text>
                                      </PdfTableColumnBuilder>
                                    }
                                  />
                                  {/* 7 */}
                                  {NullProof({
                                    input: subkegiatan,
                                    params: 'daftarkegiatan',
                                    isMap: true,
                                  })?.map((daftarkegiatan: any, i: number) => (
                                    <Fragment key={i}>
                                      <RowTable
                                        childrenKode={
                                          <PdfTableColumnBuilder>
                                            <Text
                                              style={{
                                                paddingLeft: '20px',
                                              }}
                                            >
                                              {NullProof({input: daftarkegiatan, params: 'kode'})}
                                            </Text>
                                          </PdfTableColumnBuilder>
                                        }
                                        childrenProgram={
                                          <PdfTableColumnBuilder>
                                            <Text
                                              style={{
                                                borderBottom: '2px solid black',
                                              }}
                                            >
                                              {NullProof({input: daftarkegiatan, params: 'nama'})}
                                            </Text>
                                          </PdfTableColumnBuilder>
                                        }
                                        childrenJumlahBiaya={
                                          <PdfTableColumnBuilder direction='column'>
                                            <Text
                                              style={{
                                                textAlign: 'right',
                                                width: '100%',
                                              }}
                                            >
                                              {ConvertCurrency(
                                                NullProof({
                                                  input: daftarkegiatan,
                                                  params: 'jumlah_harga',
                                                  isLabel: false,
                                                }) || 0
                                              )}
                                            </Text>
                                          </PdfTableColumnBuilder>
                                        }
                                        childrenSdCp={
                                          <PdfTableColumnBuilder>
                                            <Text
                                              style={{
                                                textAlign: 'center',
                                                width: '100%',
                                              }}
                                            >
                                              RM
                                            </Text>
                                          </PdfTableColumnBuilder>
                                        }
                                      />
                                      {/* 8 */}
                                      <RowTable
                                        childrenProgram={
                                          <PdfTableColumnBuilder>
                                            <Text>
                                              {NullProof({
                                                input: daftarkegiatan,
                                                params: 'keterangan',
                                              })}
                                            </Text>
                                          </PdfTableColumnBuilder>
                                        }
                                      />
                                      {/* 9 */}
                                      {NullProof({
                                        input: daftarkegiatan,
                                        params: 'infokegiatan',
                                        isMap: true,
                                      })?.map((infokegiatan: any, i: number) => (
                                        <Fragment key={i}>
                                          {NullProof({input: infokegiatan, params: 'nama'}) && NullProof({input: infokegiatan, params: 'nama'}) !== ' ' && (
                                            <RowTable
                                              childrenProgram={
                                                <PdfTableColumnBuilder>
                                                  <Text
                                                    style={{
                                                      paddingLeft: '10px',
                                                    }}
                                                  >
                                                    {'>'}{' '}
                                                    {NullProof({input: infokegiatan, params: 'nama'})}
                                                  </Text>
                                                </PdfTableColumnBuilder>
                                              }
                                              childrenJumlahBiaya={
                                                <PdfTableColumnBuilder direction='column'>
                                                  <Text
                                                    style={{
                                                      textAlign: 'right',
                                                      width: '100%',
                                                    }}
                                                  >
                                                    {ConvertCurrency(
                                                      NullProof({
                                                        input: infokegiatan,
                                                        params: 'jumlah_harga',
                                                        isLabel: false,
                                                      }) || 0
                                                    )}
                                                  </Text>
                                                </PdfTableColumnBuilder>
                                              }
                                            />
                                          )}
                                          {/* 10 */}
                                          {NullProof({
                                            input: infokegiatan,
                                            params: 'detailkegiatan',
                                            isMap: true,
                                          })?.map((detailkegiatan: any, i: number) => (
                                            <Fragment key={i}>
                                              <RowTable
                                                childrenProgram={
                                                  <PdfTableColumnBuilder>
                                                    <Text
                                                      style={{
                                                        paddingLeft: '15px',
                                                      }}
                                                    >
                                                      -{' '}
                                                      {NullProof({
                                                        input: detailkegiatan,
                                                        params: 'nama',
                                                      })}
                                                    </Text>
                                                  </PdfTableColumnBuilder>
                                                }
                                                childrenVolume={
                                                  <PdfTableColumnBuilder center>
                                                    <Text>
                                                      {NullProof({
                                                        input: detailkegiatan,
                                                        params: 'volume',
                                                      })}
                                                      .0{' '}
                                                      {NullProof({
                                                        input: detailkegiatan,
                                                        params: 'volume_jenis',
                                                      })}
                                                    </Text>
                                                  </PdfTableColumnBuilder>
                                                }
                                                childrenHargaSatuan={
                                                  <PdfTableColumnBuilder>
                                                    <Text
                                                      style={{
                                                        textAlign: 'right',
                                                        width: '100%',
                                                      }}
                                                    >
                                                      {ConvertCurrency(
                                                        NullProof({
                                                          input: detailkegiatan,
                                                          params: 'harga',
                                                          isLabel: false,
                                                        }) || 0
                                                      )}
                                                    </Text>
                                                  </PdfTableColumnBuilder>
                                                }
                                                childrenJumlahBiaya={
                                                  <PdfTableColumnBuilder direction='column'>
                                                    <Text
                                                      style={{
                                                        textAlign: 'right',
                                                        width: '100%',
                                                      }}
                                                    >
                                                      {ConvertCurrency(
                                                        NullProof({
                                                          input: detailkegiatan,
                                                          params: 'jumlah_harga',
                                                          isLabel: false,
                                                        }) || 0
                                                      )}
                                                    </Text>
                                                  </PdfTableColumnBuilder>
                                                }
                                              />
                                            </Fragment>
                                          ))}
                                        </Fragment>
                                      ))}
                                    </Fragment>
                                  ))}
                                </Fragment>
                              )
                            )}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )
                )}
              </Fragment>
            ))}
          </View>
        </Page>
      ))}
    </Document>
  )
}

export {PdfLayoutSemua}
