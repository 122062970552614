/* eslint-disable jsx-a11y/anchor-is-valid */

type Props = {
  className: string
  rowLength?: number
  header: any
  layout: {
    id: string
    column: string[]
  }
  data: any[]
}

const Table = ({className, header, data = [], layout}: Props) => (
  <div className='table-responsive'>
    <table className='table table-hover table-rounded table-striped table-bordered border gy-7 gs-7'>
      <thead className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200 text-center'>
        {header}
        {/* <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
          <th>No</th>
          {header.map((data, i) => (
            <th className={data.className}>{data.title}</th>
          ))}
          <th>Action</th>
        </tr> */}
      </thead>
      <tbody>
        {data.map((ldata, index) => (
          <tr>
            <td>{index + 1}</td>
            {layout.column.map((column) => (
              <td dangerouslySetInnerHTML={{__html: data[index][column]}}></td>
            ))}
            <td className='min-w-200px'>
              <a className='btn btn-sm btn-primary w-100 my-1'>Ubah</a>
              <a className='btn btn-sm btn-danger w-100 my-1'>Hapus</a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)
export {Table}
