import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfLayoutDataProps,
  PdfStyle,
  PdfStylePallete,
} from '../../../../helper/ReactPdf'
import {NullProof} from '../../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  const themeColor = {
    header: '#00ff00',
    rencana: '#00b0f0',
    pelaksanaan: '#92d050',
  }
  const monthColor = (input: any, params: string) => {
    const label = NullProof({input: input, params: params})
    return label === 'Rencana'
      ? themeColor.rencana
      : label === 'Pelaksanaan'
      ? themeColor.pelaksanaan
      : 'transparent'
  }
  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28, 1400]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View
          style={{
            ...PdfStylePallete.table.style,
            marginTop: '-5px',
            borderStyle: 'solid',
            fontFamily: 'Arial',
            fontSize: PdfStylePallete.typography.label.medium,
          }}
        >
          {/* Header */}
          <View
            style={{
              flexDirection: 'row',
              backgroundColor: themeColor.header,
              fontWeight: 'semibold',
              height: '300px',
            }}
          >
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '2%',
                height: '100%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>No</Text>
            </View>
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '13%',
                height: '100%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>Risk Event</Text>
            </View>
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '15%',
                height: '100%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>Rencana Mitigasi</Text>
            </View>
            <View
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '70%',
                height: '100%',
              }}
            >
              <View
                style={{
                  ...PdfStylePallete.table.col,
                  borderStyle: 'solid',
                  borderRightWidth: 0,
                  width: '100%',
                  height: '40px',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>Waktu Pelaksanaan Mitigasi & Realisasi Mitigasi</Text>
              </View>
              <View
                style={{
                  width: '100%',
                  height: '100%',
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    width: '30%',
                    height: '100%',
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <View
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>Triwulan I</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Jan</Text>
                    </View>
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Feb</Text>
                    </View>
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Mar</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '30%',
                    height: '100%',
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <View
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>Triwulan II</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Apr</Text>
                    </View>
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Mei</Text>
                    </View>
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Jun</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '30%',
                    height: '100%',
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <View
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>Triwulan III</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Jul</Text>
                    </View>
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Agu</Text>
                    </View>
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Sep</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '30%',
                    height: '100%',
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <View
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>Triwulan IV</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Okt</Text>
                    </View>
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Nov</Text>
                    </View>
                    <View
                      style={{
                        ...PdfStylePallete.table.col,
                        borderStyle: 'solid',
                        borderBottomWidth: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Des</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    ...PdfStylePallete.table.col,
                    borderStyle: 'solid',
                    borderBottomWidth: 0,
                    width: '15%',
                    height: '100%',
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>Evidence</Text>
                </View>
                <View
                  style={{
                    ...PdfStylePallete.table.col,
                    borderStyle: 'solid',
                    borderBottomWidth: 0,
                    borderRightWidth: 0,
                    width: '20%',
                    height: '100%',
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>PIC / Risk Owner</Text>
                </View>
              </View>
            </View>
          </View>
          {/* Content */}
          {/* Looping Here */}
          {NullProof({input: data.data, params: 'rencana_mitigasi', isMap: true})?.map(
            (l: any, i: number) => (
              <Fragment key={i}>
                <View
                  style={{
                    flexDirection: 'row',
                    // backgroundColor: themeColor.header,
                    fontWeight: 'semibold',
                  }}
                >
                  <View
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '2%',
                      height: '100%',
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{i + 1}</Text>
                  </View>
                  <View
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '13%',
                      height: '100%',
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{NullProof({input: l, params: 'risk_event'})}</Text>
                  </View>
                  <View style={{width: '85%'}}>
                    {/* Looping Here */}
                    {NullProof({input: l, params: 'rencana_mitigasi', isMap: true})?.map(
                      (lsub: any, isub: number) => (
                        <Fragment key={isub}>
                          <View
                            style={{
                              width: '100%',
                              flexDirection: 'row',
                            }}
                          >
                            <View
                              style={{
                                ...PdfStylePallete.table.col,
                                borderStyle: 'solid',
                                width: '17.8%',
                                height: '100%',
                                textAlign: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Text>{NullProof({input: lsub, params: 'nama'})}</Text>
                            </View>
                            <View
                              style={{
                                ...PdfStylePallete.table.col,
                                borderStyle: 'solid',
                                width: '83.2%',
                                height: '100%',
                              }}
                            >
                              <View
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  flexDirection: 'row',
                                }}
                              >
                                <View
                                  style={{
                                    width: '30%',
                                    height: '100%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      width: '100%',
                                      height: '100%',
                                    }}
                                  >
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'januari'),
                                      }}
                                    ></View>
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'februari'),
                                      }}
                                    ></View>
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'maret'),
                                      }}
                                    ></View>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    width: '30%',
                                    height: '100%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      width: '100%',
                                      height: '100%',
                                    }}
                                  >
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'april'),
                                      }}
                                    ></View>
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'mei'),
                                      }}
                                    ></View>
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'juni'),
                                      }}
                                    ></View>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    width: '30%',
                                    height: '100%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      width: '100%',
                                      height: '100%',
                                    }}
                                  >
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'juli'),
                                      }}
                                    ></View>
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'agustus'),
                                      }}
                                    ></View>
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'september'),
                                      }}
                                    ></View>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    width: '30%',
                                    height: '100%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      width: '100%',
                                      height: '100%',
                                    }}
                                  >
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'oktober'),
                                      }}
                                    ></View>
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'november'),
                                      }}
                                    ></View>
                                    <View
                                      style={{
                                        ...PdfStylePallete.table.col,
                                        borderStyle: 'solid',
                                        borderBottomWidth: 0,
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: monthColor(lsub, 'desember'),
                                      }}
                                    ></View>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    ...PdfStylePallete.table.col,
                                    borderStyle: 'solid',
                                    borderBottomWidth: 0,
                                    width: '15%',
                                    height: '100%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Text>{NullProof({input: lsub, params: 'evidence'})}</Text>
                                </View>
                                <View
                                  style={{
                                    ...PdfStylePallete.table.col,
                                    borderStyle: 'solid',
                                    borderBottomWidth: 0,
                                    borderRightWidth: 0,
                                    width: '20%',
                                    height: '100%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Text>{NullProof({input: lsub, params: 'pic'})}</Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        </Fragment>
                      )
                    )}
                  </View>
                </View>
              </Fragment>
            )
          )}
        </View>
        <View style={{marginVertical: '20px', gap: '10px'}}>
          <View
            style={{
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <View
              style={{backgroundColor: themeColor.rencana, height: '30px', width: '100px'}}
            ></View>
            <Text>Rencana Mitigasi</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <View
              style={{backgroundColor: themeColor.pelaksanaan, height: '30px', width: '100px'}}
            ></View>
            <Text>Pelaksanaan Mitigasi</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default PdfLayout
