import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfLayoutDataProps,
  PdfStyle,
  PdfTableColumnBuilder,
} from '../../../helper/ReactPdf'
import {NullProof} from '../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  return (
    <Document title='Dokumen'>
      <Page
        size={[800, 5000]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{width: '100%'}}>
          <PdfTableColumnBuilder direction='column' border>
            <PdfTableColumnBuilder direction='row' style={{height: '300px', minHeight: '300px'}}>
              <PdfTableColumnBuilder
                direction='column'
                borderBottom
                borderRight
                style={{height: '300px', width: '14680px'}}
              >
                <PdfTableColumnBuilder center borderBottom style={{height: '100px'}}>
                  <Text
                    style={{
                      paddingVertical: '5px',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      fontSize: '40px',
                    }}
                  >
                    Risk Register
                  </Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder style={{height: '200px'}}>
                  <PdfTableColumnBuilder
                    center
                    borderRight
                    style={{minWidth: '80px', width: '80px'}}
                  >
                    <Text
                      style={{
                        paddingVertical: '10px',
                        textAlign: 'center',
                      }}
                    >
                      No.
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '1000px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Objective / Tujuan
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '800px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Proses Bisnis
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '1200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Risk Category / Jenis Kelompok Risiko
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '600px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Kode Risiko
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '1000px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Risk Event / Uraian Peristiwa Risiko
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '1000px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Risk Cause / Penyebab Risiko
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '1000px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Sumber Risiko (Internal / Eksternal)
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    direction='column'
                    center
                    borderRight
                    style={{width: '1000px'}}
                  >
                    <PdfTableColumnBuilder center borderBottom style={{height: '100px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        Severity / Akibat / Potensi Kerugian
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder direction='row' center style={{height: '100px'}}>
                      <PdfTableColumnBuilder center borderRight>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Deskripsi
                        </Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center style={{width: '150px'}}>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Rp
                        </Text>
                      </PdfTableColumnBuilder>
                    </PdfTableColumnBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '500px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Risk Owner / Pemilik Risiko
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '700px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Nama Dept. / Unit Internal Terkait Penyebab Risiko
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    direction='column'
                    center
                    borderRight
                    style={{width: '2500px'}}
                  >
                    <PdfTableColumnBuilder center borderBottom style={{height: '100px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        Score / Nilai{`\n`}Inherent Risk
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center style={{height: '100px'}}>
                      <PdfTableColumnBuilder center borderRight>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Likelihood
                        </Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Impact
                        </Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Level of Risk / Tingkat Risiko
                        </Text>
                      </PdfTableColumnBuilder>
                    </PdfTableColumnBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    direction='column'
                    center
                    borderRight
                    style={{width: '2500px'}}
                  >
                    <PdfTableColumnBuilder center borderBottom style={{height: '100px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        Existing Control / Pengendalian yang ada
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center style={{height: '100px'}}>
                      <PdfTableColumnBuilder center borderRight>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Ada / Tidak ada
                        </Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Memadai / belum memadai
                        </Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Dijalankan 1% / belum dijalankan 1%
                        </Text>
                      </PdfTableColumnBuilder>
                    </PdfTableColumnBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder direction='column' center style={{width: '2500px'}}>
                    <PdfTableColumnBuilder center borderBottom style={{height: '100px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        Score / Nilai{`\n`}Residual Risk
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center style={{height: '100px'}}>
                      <PdfTableColumnBuilder center borderRight>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Likelihood
                        </Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Impact
                        </Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center>
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          Level of Risk / Tingkat Risiko
                        </Text>
                      </PdfTableColumnBuilder>
                    </PdfTableColumnBuilder>
                  </PdfTableColumnBuilder>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
              <PdfTableColumnBuilder
                direction='column'
                center
                borderRight
                style={{width: '1500px'}}
              >
                <PdfTableColumnBuilder center borderBottom style={{height: '200px'}}>
                  <Text
                    style={{
                      padding: '10px 20px',
                      textAlign: 'center',
                    }}
                  >
                    Risk Treatment
                  </Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder center borderBottom style={{height: '100px'}}>
                  <PdfTableColumnBuilder center borderRight>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Opsi Perlakuan Risiko
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Deskripsi Tindakan Mitigasi
                    </Text>
                  </PdfTableColumnBuilder>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
              <PdfTableColumnBuilder
                direction='column'
                center
                borderBottom
                borderRight
                style={{width: '2000px'}}
              >
                <PdfTableColumnBuilder center borderBottom style={{height: '200px'}}>
                  <Text
                    style={{
                      padding: '10px 20px',
                      textAlign: 'center',
                    }}
                  >
                    Score / Nilai Target{`\n`}Risk After Mitigation
                  </Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder center style={{height: '100px'}}>
                  <PdfTableColumnBuilder center borderRight>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Likelihood
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Impact
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      Level of Risk / Tingkat Risiko
                    </Text>
                  </PdfTableColumnBuilder>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
            </PdfTableColumnBuilder>
            {/* Content */}
            {NullProof({input: data.data, params: 'identifikasi', isMap: true})?.map(
              (l: any, i: number) => (
                <Fragment key={i}>
                  <View style={{flexDirection: 'row', width: '100%'}}>
                    <PdfTableColumnBuilder borderBottom borderRight style={{width: '100%'}}>
                      <PdfTableColumnBuilder
                        center
                        borderRight
                        style={{minWidth: '80px', width: '80px'}}
                      >
                        <Text
                          style={{
                            paddingVertical: '10px',
                            textAlign: 'center',
                          }}
                        >
                          {i + 1}.
                        </Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder
                        center
                        borderRight
                        style={{minWidth: '223px', maxWidth: '223px'}}
                      >
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          {NullProof({input: l, params: 'tujuan'})}
                        </Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder
                        center
                        borderRight
                        style={{minWidth: '178px', maxWidth: '178px'}}
                      >
                        <Text
                          style={{
                            padding: '10px 20px',
                            textAlign: 'center',
                          }}
                        >
                          {NullProof({input: l, params: 'proses_bisnis'})}
                        </Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder direction='column' style={{width: '100%'}}>
                        {NullProof({input: l, params: 'risk_category_list', isMap: true})?.map(
                          (l2: any, i2: number) => (
                            <Fragment key={i2}>
                              <PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '267px', maxWidth: '267px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({input: l2, params: 'nama'})}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '134px', maxWidth: '134px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({input: l2, params: 'kode'})}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '223px', maxWidth: '223px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({input: l2, params: 'event'})}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '223px', maxWidth: '223px'}}
                                >
                                  <View
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({input: l2, params: 'risk_cause_list'})?.map(
                                      (rcl: any, ircl: number) => (
                                        <Fragment key={ircl}>
                                          <View>
                                            <Text>
                                              {ircl + 1}.{' '}
                                              {NullProof({input: rcl, params: 'deskripsi'})}
                                            </Text>
                                          </View>
                                        </Fragment>
                                      )
                                    )}
                                  </View>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '222px', maxWidth: '222px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({input: l2, params: 'sumber'})}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '223px', maxWidth: '223px'}}
                                >
                                  <View
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'risk_severity_list',
                                      isMap: true,
                                    })?.map((rcl: any, ircl: number) => (
                                      <Fragment key={ircl}>
                                        <View>
                                          <Text>
                                            {ircl + 1}.{' '}
                                            {NullProof({input: rcl, params: 'deskripsi'})}
                                          </Text>
                                        </View>
                                      </Fragment>
                                    ))}
                                  </View>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '112px', maxWidth: '112px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    (Risk Owner / Pemilik Risiko)
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '155px', maxWidth: '155px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    (Nama Dept. / Unit Internal Terkait Penyebab Risiko)
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '186px', maxWidth: '186px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_inherent_likelihood',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '185px', maxWidth: '185px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_inherent_impact',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '187px', maxWidth: '187px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_inherent_level',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '185px', maxWidth: '185px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.existing_control_adatidak',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '185px', maxWidth: '185px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.existing_control_memadai',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '187px', maxWidth: '187px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.existing_control_skala',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '186px', maxWidth: '186px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_residual_likelihood',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '185px', maxWidth: '185px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_residual_impact',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '187px', maxWidth: '187px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_residual_level',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '189px', maxWidth: '189px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_residual_impact',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '190px', maxWidth: '190px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_residual_level',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '169px', maxWidth: '169px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_after_likelihood',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  borderRight
                                  style={{minWidth: '168px', maxWidth: '168px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_after_impact',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                                <PdfTableColumnBuilder
                                  center
                                  style={{minWidth: '168px', maxWidth: '168px'}}
                                >
                                  <Text
                                    style={{
                                      padding: '10px 20px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {NullProof({
                                      input: l2,
                                      params: 'analisis.score_after_level',
                                    })}
                                  </Text>
                                </PdfTableColumnBuilder>
                              </PdfTableColumnBuilder>
                            </Fragment>
                          )
                        )}
                      </PdfTableColumnBuilder>
                    </PdfTableColumnBuilder>
                  </View>
                </Fragment>
              )
            )}
          </PdfTableColumnBuilder>
        </View>
      </Page>
    </Document>
  )
}

export default PdfLayout
