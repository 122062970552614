import {AlphabetIndex, NullProof} from '../AppFunction'
import {formatDate} from '../DateFns'
import {BorderStyle, CellStyle, Workbook, createWorkbook} from '../ReactExcelJs'
import {PdfLayoutDataProps} from '../ReactPdf'

const WorksheetRinci = (wb: Workbook, data?: any, title?: string) => {
  // More Info: https://www.npmjs.com/package/exceljs
  // Init ExcelJS
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({title: title || 'Judulnya'})

  // Init Column Width
  const columnWidths: any = {
    A: 5,
  }

  const _data: any[] = data || []
  const _detail = _data[0] || {}
  _data.sort((a, b) => a?.id - b?.id)

  Object.keys(_detail).map((ls, is) => {
    columnWidths[AlphabetIndex(is + 1, true)] = 30
  })

  Object.keys(_detail).map((ls, is) => {
    cell({id: `${AlphabetIndex(is, true)}1`}).value = ls
  })

  _data?.map((l: any, i: number) => {
    Object.keys(_detail)?.map((ls: any, is: number) => {
      cell({id: `${AlphabetIndex(is, true)}${1 + (i + 1)}`}).value = NullProof({
        input: l,
        params: ls,
        isLabel: false,
      })
    })
  })
  setupWidth(columnWidths)
}

const ExcelLayoutCsv = (data?: any[]) => {
  const downloadExcel = async () => {
    const wb = createWorkbook()
    WorksheetRinci(wb, data)
    wb.download()
  }
  return downloadExcel
}

export default ExcelLayoutCsv
