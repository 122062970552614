import {FC, useEffect} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import {WithChildren} from '../helpers'
import i18nAllMessagesLang from '../../app/i18n/main'
import {useDispatch, useSelector} from 'react-redux'
import {post} from '../../app/redux/pageSlice'

const allMessages = {
  id: i18nAllMessagesLang.id,
  en: i18nAllMessagesLang.en,
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]
  const reduxData = useSelector((state: any) => state.page.data)
  const reduxDispatch = useDispatch()
  useEffect(() => {
    reduxDispatch(post({...reduxData, language: locale}))
  }, [])

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      onError={(err) => {
        // console.warn(err)
      }}
    >
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
