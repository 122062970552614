import i18nEnAllMessages from "./en"
import i18nIdAllMessages from "./id"

const convertObjectToJson = (data: any) => {
  return JSON.stringify(data)
}

const i18nMessagesBuilder = (data: any) => {
  let result: any = {}
  const extractObject = ({data, parent}:{data: any, parent?: string}) => {
    Object.keys(data).map((l,i) => {
      const value = data[l]
      const type = typeof value
      if (type === 'object') {
        extractObject({data: value, parent: `${parent ? `${parent}.` : ''}${l}`.toUpperCase()})
      } else {
        result[`${parent ? `${parent}.` : ''}${l}`] = value
      }
    })
  }
  extractObject({data: data})
  return result
}

const i18nAllMessagesLang = {
  id: i18nMessagesBuilder(i18nIdAllMessages),
  en: i18nMessagesBuilder(i18nEnAllMessages)
}

export default i18nAllMessagesLang