import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {InfoData, PageData, TableInputData, TableInputDetailData} from './PageData'
import {
  BreadcrumbQueryParams,
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {CardUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import FormInput from '../../../../helper/FormInput'
import {formInputList, formUpdateList} from './InputLayout'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {formatDate} from '../../../../helper/DateFns'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import {PdfLayoutSemua} from './PdfLayout'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          options={{
            checkbox: [
              {
                title: 'Lihat PDF',
                name: 'lihat-pdf',
                icon: 'RiFilePdfLine',
                type: 'link',
                url: `${InfoData.path}/pdf/pilihan`,
                api: '',
              },
            ],
          }}
          {...TableInputData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Tanggal' props={{style: {minWidth: '200px'}}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'tanggal'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableColumn label='Uraian' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'uraian'})}
                  </TableColumn>
                  <TableColumn label='MAK' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'spm_mak'})}
                  </TableColumn>
                  <TableColumn label='Nilai' props={{style: {minWidth: '200px'}}}>
                    {ConvertCurrency(NullProof({input: l, params: 'nilai', isLabel: false}) || 0)}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formUpdateList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tanggal'}}>
            {formatDate({
              date: NullProof({input: data, params: 'tanggal'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Informasi', props: {className: 'fs-5'}}}
          >
            <TableInputDetailColumn hideDivider title={{value: 'KEG'}}>
              {NullProof({input: data, params: 'keg'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'OUT'}}>
              {NullProof({input: data, params: 'out'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'SUB OUT'}}>
              {NullProof({input: data, params: 'sub_out'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'KOM'}}>
              {NullProof({input: data, params: 'kom'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'SUB KOM'}}>
              {NullProof({input: data, params: 'sub_kom'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'AKUN'}}>
              {NullProof({input: data, params: 'akun'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'MAK'}}>
            {NullProof({input: data, params: 'spm_mak'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'SPM'}}>
            {NullProof({input: data, params: 'spm'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nilai'}}>
            {ConvertCurrency(NullProof({input: data, params: 'nilai', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Realisasi'}}>
            {ConvertCurrency(NullProof({input: data, params: 'realisasi', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Selisih'}}>
            {ConvertCurrency(NullProof({input: data, params: 'selisih', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Realisasi Transfer'}}>
            {ConvertCurrency(
              NullProof({input: data, params: 'realisasi_transfer', isLabel: false}) || 0
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Selisih Transfer'}}>
            {ConvertCurrency(NullProof({input: data, params: 'selisih_trf', isLabel: false}) || 0)}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  let InitPdfLayout = PdfLayoutSemua
  if (query?.id !== 'semua') {
    // InfoData.api = `${InfoData.api}/:id`
    // InitPdfLayout = PdfLayout
  }
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={InitPdfLayout}
        options={{
          isRedux: query?.id === 'pilihan',
        }}
      />
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper, PdfWrapper}
