import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'
import inputLayoutBuilder from '../../../helper/template/FormInputTemplate'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'title',
    type: 'input',
    value: '',
    validator: Yup.string().required('Judul is required'),
    options: {
      input: {
        props: {
          title: 'Judul',
          placeholder: 'Judul',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'desc',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Deskripsi is required'),
    options: {
      textarea: {
        props: {
          title: 'Deskripsi',
          placeholder: 'Dekskripsi',
        },
      },
    },
  },
  {
    name: 'fileurl',
    type: 'input',
    className: 'col-12',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'File',
          placeholder: 'File',
          type: 'file',
          accept: '.pdf',
        },
      },
      upload: {
        url: {
          folder: 'panduan',
        },
      },
    },
  },
]

export {formInputList}
