import {MainWrapper} from '../../../pages/notifikasi/MainWrapper'
import {DataRoutesProps} from '../../UserRolesValidation'

const MainRoutes: DataRoutesProps = {
  onlyRoute: true,
  title: 'Notifikasi',
  icon: 'element-10',
  route: 'notifikasi',
  elements: <></>,
  subroute: [
    {
      title: '',
      icon: '',
      route: '*',
      elements: <MainWrapper />,
      subroute: [],
    },
  ],
}

export default MainRoutes
