import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {PdfPageViewer} from '../../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {AvatarUi, CardUi, ListdataUi, TabsUi} from '../../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../../helper/AppFunction'
import FormInput from '../../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../helper/TableInputDetail'
import {ProtectComponentRoles} from '../../../../../helper/UserRolesValidation'
import Modal from '../../../../../helper/Modal'
import {LampiranViewer, TableBalas, TableDisposisi, TableHistoriNaskah} from './TableWrapper'
import Accordion from '../../../../../helper/Accordion'
import {ReactPdfViewer} from '../../../../../helper/ReactPdf'

const dummyData = [
  {
    id: 1,
    tanggal_naskah: '2024-01-18T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    hal: 'coba tanda tangan konvensional',
    asal_naskah: 'Inspektorat Wilayah IV',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
    nomor_referensi: 'Tidak ada',
    isi: 'mencoba testing aplikasi tanda tangan konvensional',
    jenis_naskah: 'Nota Dinas',
    sifat_naskah: 'Biasa',
    klasifikasi: 'PS.00 - Pengawasan Administrasi Umum',
    tipe_tandatangan: 'TTE',
    status_berkas: 'Belum diberkaskan',
    tanggal_diterima: '2024-01-18T11:02:59Z',
    tanggal_dibaca: '2024-01-18T11:02:59Z',
    tanggal_dibaca_setelah: '2024-01-18T11:02:59Z',
    pegawai: {
      nama: 'Kastolan - Plt. Sekretaris Inspektorat Jenderal',
      jabatan: 'Sekretariat Inspektorat Jenderal Kementerian Agama - Kementerian Agama',
      foto: 'files/avatar/avatar_12122023204007_nemeka.jpg',
    },
    penerima: {
      utama: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
      tebusan: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
    },
  },
  {
    id: 2,
    tanggal_naskah: '2024-01-18T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    hal: 'coba tanda tangan konvensional',
    asal_naskah: 'Inspektorat Wilayah IV',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
  },
  {
    id: 3,
    tanggal_naskah: '2024-01-18T11:02:59Z',
    nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
    hal: 'coba tanda tangan konvensional',
    asal_naskah: 'Inspektorat Wilayah IV',
    tingkat_urgensi: 'Biasa',
    status_tindak_lanjut: 'Pending',
    status_baca: 'Pending',
  },
]

const MainWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          // isDummy
          // staticData={dummyData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Nomor Naskah' className='min-w-150px'>
                    {NullProof({input: l, params: 'NaskahMasuk.nomor_naskah'})}
                  </TableColumn>
                  <TableColumn label='Hal' className='min-w-150px'>
                    {NullProof({input: l, params: 'NaskahMasuk.hal'})}
                  </TableColumn>
                  <TableColumn label='Kepada' className='min-w-150px'>
                    {NullProof({input: l, params: 'kepada_id', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div className='d-flex gap-2'>
                            <div>{`${NullProof({
                              input: l1,
                              params: 'status_baca',
                            })} - ${NullProof({
                              input: l1,
                              params: 'pegawai_id.nama',
                            })}`}</div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </TableColumn>
                  <TableColumn label='Instruksi' className='min-w-150px'>
                    {' '}
                    {l.pesan.map((value: string, index: number) => (
                      <div key={index}>{value}</div>
                    ))}
                  </TableColumn>
                  <TableColumn label='Instruksi Tambahan' className='min-w-150px'>
                    {NullProof({input: l, params: 'intruksi'})}
                  </TableColumn>
                  <TableColumn label='Lampiran' className='min-w-150px'>
                    {NullProof({input: l, params: 'lampiran_fix'})}
                  </TableColumn>
                  <TableColumn label='Tanggal Disposisi' className='min-w-150px'>
                    {NullProof({input: l, params: 'createdAt', type: 'date'})}
                  </TableColumn>
                  <TableColumn label='Batas Waktu' className='min-w-150px'>
                    {NullProof({input: l, params: 'batas_waktu', type: 'date'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <Modal
        id='modal_statusubah'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            isFeedback: true,
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              // console.log(e)
              setModal((p: any) => ({...p, statusUbah: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Naskah Masuk Dari', props: {className: 'mb-2 fs-5'}}}
            >
              <div className='d-flex gap-4 align-items-center'>
                <div>
                  <AvatarUi
                    name={NullProof({input: data, params: 'NaskahMasuk.nama_pengirim'})}
                    url={NullProof({input: data, params: 'pegawai.foto'})}
                  />
                </div>
                <div>
                  <div className='fw-bold fs-5'>
                    {NullProof({input: data, params: 'NaskahMasuk.nama_pengirim'})}
                  </div>
                  <div>{NullProof({input: data, params: 'NaskahMasuk.jabatan_pengirim'})}</div>
                </div>
              </div>
            </TableInputDetailColumn>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Nomor Referensi'}}
                >
                  {NullProof({input: data, params: 'NaskahMasuk.nomor_ref'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  isResponsive='desktop'
                  hideDivider
                  title={{value: 'Nomor Naskah'}}
                >
                  {NullProof({input: data, params: 'NaskahMasuk.nomor_naskah'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  isResponsive='desktop'
                  hideDivider
                  title={{value: 'Tanggal Naskah'}}
                >
                  {NullProof({input: data, params: 'NaskahMasuk.tanggal_naskah', type: 'date'})}
                </TableInputDetailColumn>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Hal'}}>
                  {NullProof({input: data, params: 'NaskahMasuk.hal'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Isi'}}>
                  {NullProof({input: data, params: 'NaskahMasuk.isi'})}
                </TableInputDetailColumn>
              </div>
            </div>
            <div className='my-4 d-flex flex-column gap-2'></div>
            <Accordion
              title='Detail Naskah'
              enabled
              props={{body: {className: 'p-4 border-bottom'}}}
            >
              <div className='row'>
                <div className='row col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Jenis Naskah'}}
                  >
                    {NullProof({input: data, params: 'NaskahMasuk.JenisNaskah.text'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Sifat Naskah'}}
                  >
                    {NullProof({input: data, params: 'NaskahMasuk.SifatNaskah.text'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Tingkat Urgensi'}}
                  >
                    {NullProof({input: data, params: 'NaskahMasuk.TingkatUrgensi.text'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Klasifikasi'}}
                  >
                    {NullProof({input: data, params: 'klasifikasi'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Tipe Tandatangan'}}
                  >
                    KONVENSIONAL
                  </TableInputDetailColumn>
                </div>
                <div className='row col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Berkas'}}
                  >
                    {NullProof({input: data, params: 'NaskahMasuk.status_berkas'})
                      ? 'Sudah'
                      : 'Belum'}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Diterima Pada'}}
                  >
                    {NullProof({input: data, params: 'NaskahMasuk.tanggal_diterima', type: 'date'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Dibaca Pada'}}
                  >
                    {NullProof({input: data, params: 'NaskahMasuk.tanggal_dibaca', type: 'date'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Dibaca Setelah'}}
                  >
                    {NullProof({
                      input: data,
                      params: 'NaskahMasuk.tanggal_dibaca_setelah',
                      type: 'date',
                    })}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Diregistrasikan pada'}}
                  >
                    {NullProof({input: data, params: 'NaskahMasuk.tanggal_naskah', type: 'date'})}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn
                    props={{className: 'row gy-1'}}
                    hideDivider
                    title={{value: 'Diregistrasikan oleh'}}
                  >
                    {NullProof({input: data, params: 'NaskahMasuk.pegawai_id.nama'})}
                  </TableInputDetailColumn>
                </div>
              </div>
            </Accordion>
            <Accordion title='Penerima' enabled props={{body: {className: 'p-4 border-bottom'}}}>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'mb-4'}}
                    hideDivider
                    title={{value: 'Utama:'}}
                  ></TableInputDetailColumn>
                  {NullProof({input: data, params: 'NaskahMasuk.utama', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableInputDetailColumn props={{className: 'row mb-2'}} hideDivider>
                          {NullProof({input: l, params: 'pegawai_id.nama'})}
                        </TableInputDetailColumn>
                      </Fragment>
                    )
                  )}
                </div>
                <div className='col-12 col-md-6'>
                  <TableInputDetailColumn
                    props={{className: 'mb-4'}}
                    hideDivider
                    title={{value: 'Tembusan:'}}
                  ></TableInputDetailColumn>
                  {NullProof({input: data, params: 'NaskahMasuk.tembusan', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableInputDetailColumn props={{className: 'row mb-2'}} hideDivider>
                          {NullProof({input: l, params: 'pegawai_id.nama'})}
                        </TableInputDetailColumn>
                      </Fragment>
                    )
                  )}
                </div>
              </div>
            </Accordion>
          </TableInputDetail>
        </CardUi>
        <CardUi props={{className: 'my-6'}}>
          <ReactPdfViewer
            url={`${
              NullProof({input: data, params: 'NaskahMasuk.file', isLabel: false})
                ? `/${NullProof({input: data, params: 'NaskahMasuk.file', isLabel: false})}`
                : ''
            }`}
          />
        </CardUi>
        {/* <PdfPageViewer
          props={{className: 'my-6'}}
          options={{hideBreadcrumb: true, hideBack: true}}
          route={RouterQueryParams(`${InfoData.api}/:id`, query)}
          PageData={{
            breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
            title: '',
          }}
          PdfLayout={PdfLayout}
          data={{}}
        /> */}
        <CardUi title='Lampiran' props={{className: 'my-6'}}>
          {NullProof({input: data, params: 'lampiran_naskah', isMap: true})?.length > 0 ? (
            <ListdataUi
              id='lampiran'
              title='Lampiran'
              data={NullProof({input: data, params: 'lampiran_naskah', isMap: true})}
            />
          ) : (
            'Tidak ada lampiran'
          )}
        </CardUi>
        <TabsUi
          tabs={[
            {
              id: 'disposisi',
              title: 'Disposisi',
              children: <TableDisposisi />,
            },
            {
              id: 'balas',
              title: 'Balas',
              children: <TableBalas />,
            },
          ]}
        />
        <CardUi title='History Naskah' props={{className: 'my-6'}}>
          <TableHistoriNaskah />
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper}
