import {configureStore} from '@reduxjs/toolkit'
import counterReducer from './redux/counterSlice'
import authReducer from './redux/authSlice'
import routeReducer from './redux/routeSlice'
import tableReducer from './redux/tableSlice'
import notifReducer from './redux/notifSlice'
import pageReducer from './redux/pageSlice'

const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    route: routeReducer,
    table: tableReducer,
    notif: notifReducer,
    page: pageReducer,
  },
})

export default store
