import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'nama',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: '',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'deskripsi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Deskripsi wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Deskripsi',
          placeholder: '',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'list_tujuan',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'List Tujuan',
          placeholder: 'List Tujuan',
        },
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'pegawai_id',
        type: 'datalist',
        value: '',
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Nama Pegawai',
              placeholder: 'Nama Pegawai',
            },
          },
          datalist: {
            api: '/tata_usaha/tujuan',
            query: 'orderby=asc',
            params: 'pegawai.nama,jenis',
            result: (
              <>
                <div>
                  <span className='fw-bold'>Nama : </span>
                  <span>$pegawai.nama</span>
                </div>
                <div>
                  <span className='fw-bold'>Keterangan : </span>
                  <span>$jenis</span>
                </div>
              </>
            ),
            id: 'id',
            type: 'table',
            table: {
              data: [
                {id: 'pegawai.nama', type: 'text'},
                {id: 'jenis', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/tata_usaha/tujuan',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'Keterangan'},
                ],
              },
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'keterangan',
        type: 'input',
        value: '',
        validator: Yup.string().required('Keterangan wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Keterangan',
              placeholder: '',
            },
          },
        },
      },
    ],
  },
]

export {formInputList}
