/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'
import {getListYear} from '../../../../../../helper/DateFns'

// const listDataNamaLangkah = [
//   {title: 'PERENCANAAN', value: 'FUNGSI PERENCANAAN'},
//   {title: 'PELAKSANAAN', value: 'FUNGSI PELAKSANAAN'},
//   {title: 'EVALUASI DAN PELAPORAN', value: 'FUNGSI EVALUASI DAN PELAPORAN'},
//   {
//     title: 'CAPAIAN SASARAN HASIL (OUTCOME)',
//     value: 'FUNGSI CAPAIAN SASARAN HASIL (OUTCOME)',
//   },
// ]

// const createInput = (title: string, name: string, validator: any): FormInputProps => ({
//   className: 'col-12',
//   name,
//   type: 'input',
//   value: '',
//   validator,
//   options: {input: {props: {title, placeholder: title}}},
// })

// const selectInput = (
//   title: string,
//   name: string,
//   validator: any,
//   listData: {title: string; value: string | number}[]
// ): FormInputProps => ({
//   className: 'col-12',
//   name,
//   type: 'select',
//   value: '',
//   validator,
//   options: {select: {props: {title, placeholder: title}}},
//   listData,
// })

// const textareaInput = (title: string, name: string, validator: any): FormInputProps => ({
//   className: 'col-12',
//   name,
//   type: 'textarea',
//   value: '',
//   validator,
//   options: {textarea: {props: {title, placeholder: title, rows: 3}}},
// })

// const datalistInput = (
//   title: string,
//   name: string,
//   validator: any,
//   api: string,
//   params: string,
//   result: string,
//   id: string
// ): FormInputProps => ({
//   className: 'col-12',
//   name,
//   type: 'datalist',
//   value: '',
//   validator,
//   options: {
//     input: {props: {title, placeholder: title}},
//     datalist: {api, params, result, id, title},
//   },
// })

// const componentInput = (
//   title: string,
//   name: string,
//   validator: any,
//   component: any,
//   useAccordion: boolean = false,
//   useGuide: boolean = false,
//   value: number = 0
// ): FormInputProps => ({
//   className: 'col-12',
//   name,
//   type: 'component',
//   value,
//   validator,
//   options: {input: {props: {title, placeholder: title}}, component: {useAccordion, useGuide}},
//   component,
// })

// const inputPoint: FormInputProps[] = [
//   createInput('Rencana Dikerjakan', 'rencana_dikerjakan', Yup.string()),
//   createInput('Realisasi Dikerjakan', 'realisasi_dikerjakan', Yup.string()),
//   createInput('Rencana Waktu', 'rencana_waktu', Yup.number()),
//   createInput('Realisasi Waktu', 'realisasi_waktu', Yup.number()),
//   createInput('Nomor KKA', 'nomor_kka', Yup.string()),
//   createInput('Keterangan', 'keterangan', Yup.string()),
// ]

// const inputList: FormInputProps[] = [
//   datalistInput(
//     'Surat Tugas',
//     'surat_tugas_id',
//     Yup.string().required('Surat Tugas is required'),
//     '/pengawasan_internal/surat-tugas',
//     'nomor_surat,nama_kegiatan',
//     '$nomor_surat | $nama_kegiatan',
//     'id'
//   ),
//   createInput('No PKA', 'no_pka', Yup.string().required('No PKA wajib diisi.')),
//   datalistInput(
//     'Auditi',
//     'auditi_id',
//     Yup.string().required('Auditi is required'),
//     '/kepegawaian/data-satker-unit',
//     'singkatan_satker,nama_satker',
//     '$singkatan_satker - $nama_satker',
//     'id'
//   ),
//   selectInput('Tahun', 'tahun', Yup.string().required('Tahun wajib diisi'), getListYear()),
//   createInput('Judul', 'judul', Yup.string().required('Judul wajib diisi.')),
//   componentInput('Tujuan', 'tujuan', Yup.string().required('Tujuan wajib diisi.'), [
//     textareaInput('Detail', 'detail', Yup.string().required('Detail wajib diisi.')),
//   ]),
//   componentInput(
//     'Ruang Lingkup',
//     'ruang_lingkup',
//     Yup.string().required('Ruang Lingkup wajib diisi.'),
//     [textareaInput('Detail', 'detail', Yup.string().required('Detail wajib diisi.'))]
//   ),
//   componentInput('Pokok Audit', 'pokok_audit', Yup.string().required('Pokok Audit wajib diisi.'), [
//     textareaInput('Detail', 'detail', Yup.string().required('Detail wajib diisi.')),
//   ]),
//   componentInput(
//     'Langkah Audit',
//     'langkah_audit',
//     Yup.number().min(1, 'Langkah Audit wajib diisi').required('Wajib diisi.'),
//     [
//       selectInput(
//         'Nama Langkah',
//         'nama_langkah',
//         Yup.string().required('Nama Langkah wajib diisi.'),
//         listDataNamaLangkah
//       ),
//       createInput('Sasaran', 'sasaran', Yup.string().required('Sasaran wajib diisi.')),
//       ...inputPoint,
//       componentInput(
//         'Prosedur Audit',
//         'prosedur_audit',
//         Yup.number().min(1, 'Prosedur Audit wajib diisi').required('Wajib diisi.'),
//         [
//           createInput(
//             'Nama Prosedur',
//             'nama_prosedur',
//             Yup.string().required('Nama Prosedur wajib diisi.')
//           ),
//           ...inputPoint,
//           componentInput(
//             'Sub 1 Prosedur Audit',
//             'sub1_prosedur_audit',
//             Yup.number(),
//             [
//               createInput(
//                 'Nama Prosedur',
//                 'nama_prosedur',
//                 Yup.string().required('Nama Prosedur wajib diisi.')
//               ),
//               ...inputPoint,
//               componentInput(
//                 'Sub 2 Prosedur Audit',
//                 'sub2_prosedur_audit',
//                 Yup.number(),
//                 [
//                   createInput(
//                     'Nama Prosedur',
//                     'nama_prosedur',
//                     Yup.string().required('Nama Prosedur wajib diisi.')
//                   ),
//                   ...inputPoint,
//                   componentInput(
//                     'Sub 3 Prosedur Audit',
//                     'sub3_prosedur_audit',
//                     Yup.number(),
//                     [
//                       createInput(
//                         'Nama Prosedur',
//                         'nama_prosedur',
//                         Yup.string().required('Nama Prosedur wajib diisi.')
//                       ),
//                       ...inputPoint,
//                     ],
//                     true,
//                     true
//                   ),
//                 ],
//                 true,
//                 true
//               ),
//             ],
//             true,
//             true
//           ),
//         ],
//         true,
//         true
//       ),
//     ],
//     true,
//     true
//   ),
// ]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        params: 'nomor_surat,nama_kegiatan',
        result: '$nomor_surat | $nama_kegiatan',
        id: 'id',
        title: 'Surat Tugas',
      },
    },
  },
  {
    className: 'col-12',
    name: 'no_pka',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'No PKA',
          placeholder: 'No PKA',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'auditi_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Auditi',
          placeholder: 'Auditi',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker-unit',
        params: 'singkatan_satker,nama_satker',
        result: '$singkatan_satker - $nama_satker',
        id: 'id',
        title: 'Auditi',
      },
    },
  },
  {
    className: 'col-12',
    name: 'tahun',
    type: 'select',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      select: {
        props: {
          title: 'Tahun',
          placeholder: 'Tahun',
        },
      },
    },
    listData: getListYear(),
  },
  {
    className: 'col-12',
    name: 'judul',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Judul',
          placeholder: 'Judul',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tujuan',
    type: 'component',
    value: 0,
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tujuan',
          placeholder: 'Tujuan',
        },
      },
      component: {
        useAccordion: false,
        useGuide: false,
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'detail',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Detail',
              placeholder: 'Detail',
              rows: 3,
            },
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'ruang_lingkup',
    type: 'component',
    value: 0,
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Ruang Lingkup',
          placeholder: 'Ruang Lingkup',
        },
      },
      component: {
        useAccordion: false,
        useGuide: false,
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'detail',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Detail',
              placeholder: 'Detail',
              rows: 3,
            },
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'pokok_audit',
    type: 'component',
    value: 0,
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Pokok Audit',
          placeholder: 'Pokok Audit',
        },
      },
      component: {
        useAccordion: false,
        useGuide: false,
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'detail',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Detail',
              placeholder: 'Detail',
              rows: 3,
            },
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'langkah_audit',
    type: 'component',
    value: 0,
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Langkah Audit',
          placeholder: 'Langkah Audit',
        },
      },
      component: {
        useAccordion: true,
        useGuide: true,
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'nama_langkah',
        type: 'select',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          select: {
            props: {
              title: 'Nama Langkah',
              placeholder: 'Nama Langkah',
            },
          },
        },
        listData: [
          {
            title: 'PERENCANAAN',
            value: 'FUNGSI PERENCANAAN',
          },
          {
            title: 'PELAKSANAAN',
            value: 'FUNGSI PELAKSANAAN',
          },
          {
            title: 'EVALUASI DAN PELAPORAN',
            value: 'FUNGSI EVALUASI DAN PELAPORAN',
          },
          {
            title: 'CAPAIAN SASARAN HASIL (OUTCOME)',
            value: 'FUNGSI CAPAIAN SASARAN HASIL (OUTCOME)',
          },
        ],
      },
      {
        className: 'col-12',
        name: 'sasaran',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Sasaran',
              placeholder: 'Sasaran',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'rencana_dikerjakan',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Rencana Dikerjakan',
              placeholder: 'Rencana Dikerjakan',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'realisasi_dikerjakan',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Realisasi Dikerjakan',
              placeholder: 'Realisasi Dikerjakan',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'rencana_waktu',
        type: 'input',
        value: '',
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Rencana Waktu',
              placeholder: 'Rencana Waktu',
              type: 'number',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'realisasi_waktu',
        type: 'input',
        value: '',
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Realisasi Waktu',
              placeholder: 'Realisasi Waktu',
              type: 'number',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'nomor_kka',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Nomor KKA',
              placeholder: 'Nomor KKA',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'keterangan',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Keterangan',
              placeholder: 'Keterangan',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'prosedur_audit',
        type: 'component',
        value: 0,
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Prosedur Audit',
              placeholder: 'Prosedur Audit',
            },
          },
          component: {
            useAccordion: true,
            useGuide: true,
          },
        },
        component: [
          {
            className: 'col-12',
            name: 'nama_prosedur',
            type: 'input',
            value: '',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Nama Prosedur',
                  placeholder: 'Nama Prosedur',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'rencana_dikerjakan',
            type: 'input',
            value: '',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Rencana Dikerjakan',
                  placeholder: 'Rencana Dikerjakan',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'realisasi_dikerjakan',
            type: 'input',
            value: '',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Realisasi Dikerjakan',
                  placeholder: 'Realisasi Dikerjakan',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'rencana_waktu',
            type: 'input',
            value: '',
            validator: Yup.number().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Rencana Waktu',
                  placeholder: 'Rencana Waktu',
                  type: 'number',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'realisasi_waktu',
            type: 'input',
            value: '',
            validator: Yup.number().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Realisasi Waktu',
                  placeholder: 'Realisasi Waktu',
                  type: 'number',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'nomor_kka',
            type: 'input',
            value: '',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Nomor KKA',
                  placeholder: 'Nomor KKA',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'keterangan',
            type: 'input',
            value: '',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Keterangan',
                  placeholder: 'Keterangan',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'sub1_prosedur_audit',
            type: 'component',
            value: 0,
            validator: Yup.number().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Sub 1 Prosedur Audit',
                  placeholder: 'Sub 1 Prosedur Audit',
                },
              },
              component: {
                useAccordion: true,
                useGuide: true,
              },
            },
            component: [
              {
                className: 'col-12',
                name: 'nama_prosedur',
                type: 'input',
                value: '',
                validator: Yup.string().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Nama Prosedur',
                      placeholder: 'Nama Prosedur',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'rencana_dikerjakan',
                type: 'input',
                value: '',
                validator: Yup.string().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Rencana Dikerjakan',
                      placeholder: 'Rencana Dikerjakan',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'realisasi_dikerjakan',
                type: 'input',
                value: '',
                validator: Yup.string().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Realisasi Dikerjakan',
                      placeholder: 'Realisasi Dikerjakan',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'rencana_waktu',
                type: 'input',
                value: '',
                validator: Yup.number().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Rencana Waktu',
                      placeholder: 'Rencana Waktu',
                      type: 'number',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'realisasi_waktu',
                type: 'input',
                value: '',
                validator: Yup.number().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Realisasi Waktu',
                      placeholder: 'Realisasi Waktu',
                      type: 'number',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'nomor_kka',
                type: 'input',
                value: '',
                validator: Yup.string().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Nomor KKA',
                      placeholder: 'Nomor KKA',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'keterangan',
                type: 'input',
                value: '',
                validator: Yup.string().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Keterangan',
                      placeholder: 'Keterangan',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'sub2_prosedur_audit',
                type: 'component',
                value: 0,
                validator: Yup.number().required('Mohon untuk diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Sub 2 Prosedur Audit',
                      placeholder: 'Sub 2 Prosedur Audit',
                    },
                  },
                  component: {
                    useAccordion: true,
                    useGuide: true,
                  },
                },
                component: [
                  {
                    className: 'col-12',
                    name: 'nama_prosedur',
                    type: 'input',
                    value: '',
                    validator: Yup.string().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Nama Prosedur',
                          placeholder: 'Nama Prosedur',
                        },
                      },
                    },
                  },
                  {
                    className: 'col-12',
                    name: 'rencana_dikerjakan',
                    type: 'input',
                    value: '',
                    validator: Yup.string().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Rencana Dikerjakan',
                          placeholder: 'Rencana Dikerjakan',
                        },
                      },
                    },
                  },
                  {
                    className: 'col-12',
                    name: 'realisasi_dikerjakan',
                    type: 'input',
                    value: '',
                    validator: Yup.string().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Realisasi Dikerjakan',
                          placeholder: 'Realisasi Dikerjakan',
                        },
                      },
                    },
                  },
                  {
                    className: 'col-12',
                    name: 'rencana_waktu',
                    type: 'input',
                    value: '',
                    validator: Yup.number().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Rencana Waktu',
                          placeholder: 'Rencana Waktu',
                          type: 'number',
                        },
                      },
                    },
                  },
                  {
                    className: 'col-12',
                    name: 'realisasi_waktu',
                    type: 'input',
                    value: '',
                    validator: Yup.number().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Realisasi Waktu',
                          placeholder: 'Realisasi Waktu',
                          type: 'number',
                        },
                      },
                    },
                  },
                  {
                    className: 'col-12',
                    name: 'nomor_kka',
                    type: 'input',
                    value: '',
                    validator: Yup.string().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Nomor KKA',
                          placeholder: 'Nomor KKA',
                        },
                      },
                    },
                  },
                  {
                    className: 'col-12',
                    name: 'keterangan',
                    type: 'input',
                    value: '',
                    validator: Yup.string().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Keterangan',
                          placeholder: 'Keterangan',
                        },
                      },
                    },
                  },
                  {
                    className: 'col-12',
                    name: 'sub3_prosedur_audit',
                    type: 'component',
                    value: 0,
                    validator: Yup.number().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Sub 3 Prosedur Audit',
                          placeholder: 'Sub 3 Prosedur Audit',
                        },
                      },
                      component: {
                        useAccordion: true,
                        useGuide: true,
                      },
                    },
                    component: [
                      {
                        className: 'col-12',
                        name: 'nama_prosedur',
                        type: 'input',
                        value: '',
                        validator: Yup.string().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Nama Prosedur',
                              placeholder: 'Nama Prosedur',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12',
                        name: 'rencana_dikerjakan',
                        type: 'input',
                        value: '',
                        validator: Yup.string().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Rencana Dikerjakan',
                              placeholder: 'Rencana Dikerjakan',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12',
                        name: 'realisasi_dikerjakan',
                        type: 'input',
                        value: '',
                        validator: Yup.number().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Realisasi Dikerjakan',
                              placeholder: 'Realisasi Dikerjakan',
                              type: 'number',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12',
                        name: 'rencana_waktu',
                        type: 'input',
                        value: '',
                        validator: Yup.number().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Rencana Waktu',
                              placeholder: 'Rencana Waktu',
                              type: 'number',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12',
                        name: 'realisasi_waktu',
                        type: 'input',
                        value: '',
                        validator: Yup.number().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Realisasi Waktu',
                              placeholder: 'Realisasi Waktu',
                              type: 'number',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12',
                        name: 'nomor_kka',
                        type: 'input',
                        value: '',
                        validator: Yup.string().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Nomor KKA',
                              placeholder: 'Nomor KKA',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12',
                        name: 'keterangan',
                        type: 'input',
                        value: '',
                        validator: Yup.string().required('Mohon untuk diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Keterangan',
                              placeholder: 'Keterangan',
                            },
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

export {formInputList}
