import {DataRoutesProps} from '../UserRolesValidation'
import {DashboardWrapper} from '../../pages/dashboard/DashboardWrapper'
import {MainWrapper} from '../../pages/dashboard/MainWrapper'

const Dashboard: DataRoutesProps = {
  title: 'Dashboard',
  icon: 'element-11',
  route: 'dashboard',
  elements: <MainWrapper />,
  subroute: [],
}

export default Dashboard
