import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'

const inputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'jabatan',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Jabatan is required'),
    options: {
      input: {
        props: {
          title: 'Jabatan',
          placeholder: 'Jabatan',
        },
      },
      datalist: {
        api: '/kepegawaian/data-jabatan',
        params: 'fungsional',
        result: '$fungsional',
        id: 'fungsional',
      },
    },
  },
]

export {inputList}
