import {getListYear} from '../../../helper/DateFns'
import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const inputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'nama',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama APP wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nama APP',
          placeholder: 'Nama APP',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tahun',
    type: 'select',
    value: '',
    listData: getListYear(),
    validator: Yup.string().required('Tahun wajib diisi'),
    options: {
      select: {
        props: {
          title: 'Tahun',
          placeholder: 'Tahun',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'list_data',
    type: 'component',
    value: 0,
    // validator: Yup.number(),
    validator: Yup.number().min(1, 'Sektor wajib diisi').required('Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Sektor',
          placeholder: 'Sektor',
        },
      },
      component: {
        useAccordion: true,
        useGuide: true,
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'judul',
        type: 'input',
        value: '',
        validator: Yup.string().required('Nama Sektor wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Nama Sektor',
              placeholder: 'Nama Sektor',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'sektor',
        type: 'component',
        value: 0,
        // validator: Yup.number(),
        validator: Yup.number().min(1, 'Tema wajib diisi').required('Wajib diisi.'),
        options: {
          input: {
            props: {
              title: 'Tema',
              placeholder: 'Tema',
            },
          },
          component: {
            useAccordion: true,
            useGuide: true,
          },
        },
        component: [
          {
            className: 'col-12',
            name: 'judul',
            type: 'input',
            value: '',
            validator: Yup.string().required('Nama Tema wajib diisi.'),
            options: {
              input: {
                props: {
                  title: 'Nama Tema',
                  placeholder: 'Nama Tema',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'topik',
            type: 'component',
            value: 0,
            // validator: Yup.number(),
            validator: Yup.number().min(1, 'Topik wajib diisi.').required('Wajib diisi.'),
            options: {
              input: {
                props: {
                  title: 'Topik',
                  placeholder: 'Topik',
                },
              },
              component: {
                useAccordion: true,
                useGuide: true,
              },
            },
            component: [
              {
                className: 'col-12',
                name: 'judul',
                type: 'input',
                value: '',
                validator: Yup.string().required('Nama Topik wajib diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Nama Topik',
                      placeholder: 'Nama Topik',
                    },
                  },
                },
              },
              {
                className: 'col-12',
                name: 'pegawasan',
                type: 'component',
                value: 0,
                // validator: Yup.number(),
                validator: Yup.number()
                  .min(1, 'Informasi Hasil Pengawasan wajib diisi.')
                  .required('Wajib diisi.'),
                options: {
                  input: {
                    props: {
                      title: 'Informasi Hasil Pengawasan',
                      placeholder: 'Informasi Hasil Pengawasan',
                    },
                  },
                  component: {
                    useAccordion: true,
                    useGuide: true,
                  },
                },
                component: [
                  {
                    className: 'col-12',
                    name: 'judul',
                    type: 'input',
                    value: '',
                    validator: Yup.string().required('Informasi Hasil Pengawasan wajib diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Informasi Hasil Pengawasan',
                          placeholder: 'Informasi Hasil Pengawasan',
                        },
                      },
                    },
                  },
                  {
                    className: 'col-12',
                    name: 'jenis_pengawasan',
                    type: 'component',
                    value: 0,
                    // validator: Yup.number(),
                    validator: Yup.number()
                      .min(1, 'Jenis Pengawasan wajib diisi.')
                      .required('Wajib diisi.'),
                    options: {
                      input: {
                        props: {
                          title: 'Jenis Pengawasan',
                          placeholder: 'Jenis Pengawasan',
                        },
                      },
                      component: {
                        useAccordion: true,
                        useGuide: true,
                      },
                    },
                    component: [
                      {
                        className: 'col-12',
                        name: 'jenis',
                        type: 'select',
                        value: '',
                        listData: [
                          {
                            title: 'Pengawasan Lainnya',
                            value: 'Pengawasan Lainnya',
                          },
                          {
                            title: 'Evaluasi',
                            value: 'Evaluasi',
                          },
                          {
                            title: 'Audit',
                            value: 'Audit',
                          },
                          {
                            title: 'Pemantauan',
                            value: 'Pemantauan',
                          },
                          {
                            title: 'Pengawasan Lainnya',
                            value: 'Pengawasan Lainnya',
                          },
                          {
                            title: 'Audit dengan Tujuan Tertentu',
                            value: 'Audit dengan Tujuan Tertentu',
                          },
                          {
                            title: 'Reviu',
                            value: 'Reviu',
                          },
                        ],
                        validator: Yup.string(),
                        // validator: Yup.string().required('Jenis Pengawasan wajib diisi.'),
                        options: {
                          select: {
                            props: {
                              title: 'Jenis Pengawasan',
                              placeholder: 'Jenis Pengawasan',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12',
                        name: 'judul',
                        type: 'input',
                        value: '',
                        validator: Yup.string(),
                        // validator: Yup.string().required('Uraian Jenis Pengawasan wajib diisi.'),
                        options: {
                          input: {
                            props: {
                              title: 'Uraian Jenis Pengawasan',
                              placeholder: 'Uraian Jenis Pengawasan',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12',
                        name: 'text',
                        type: 'label',
                        value: '',
                        validator: Yup.string(),
                        options: {
                          label: {
                            name: 'Rencana Penyampaian Hasil Pengawasan',
                            labelType: 'both',
                          },
                        },
                      },
                      {
                        className: 'col-3',
                        name: 'triwulan_1',
                        type: 'input',
                        value: '',
                        validator: Yup.boolean(),
                        options: {
                          input: {
                            props: {
                              type: 'checkbox',
                              title: 'Triwulan I',
                              placeholder: 'Koordinator',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-3',
                        name: 'triwulan_2',
                        type: 'input',
                        value: '',
                        validator: Yup.boolean(),
                        options: {
                          input: {
                            props: {
                              type: 'checkbox',
                              title: 'Triwulan II',
                              placeholder: 'Koordinator',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-3',
                        name: 'triwulan_3',
                        type: 'input',
                        value: '',
                        validator: Yup.boolean(),
                        options: {
                          input: {
                            props: {
                              type: 'checkbox',
                              title: 'Triwulan III',
                              placeholder: 'Koordinator',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-3',
                        name: 'triwulan_4',
                        type: 'input',
                        value: '',
                        validator: Yup.boolean(),
                        options: {
                          input: {
                            props: {
                              type: 'checkbox',
                              title: 'Triwulan IV',
                              placeholder: 'Koordinator',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-12',
                        name: 'text',
                        type: 'label',
                        value: '',
                        validator: Yup.string(),
                        options: {
                          label: {
                            name: 'Unit Pelaksana',
                            labelType: 'both',
                          },
                        },
                      },
                      {
                        className: 'col-2',
                        name: 'sek',
                        type: 'select',
                        value: '',
                        listData: [
                          {
                            title: 'Koordinator',
                            value: 'Koordinator',
                          },
                          {
                            title: 'Kontributor',
                            value: 'Kontributor',
                          },
                        ],
                        validator: Yup.string(),
                        options: {
                          select: {
                            props: {
                              title: 'Sek',
                              placeholder: 'Sek',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-2',
                        name: 'ir_1',
                        type: 'select',
                        value: '',
                        listData: [
                          {
                            title: 'Koordinator',
                            value: 'Koordinator',
                          },
                          {
                            title: 'Kontributor',
                            value: 'Kontributor',
                          },
                        ],
                        validator: Yup.string(),
                        options: {
                          select: {
                            props: {
                              title: 'Ir I',
                              placeholder: 'Ir I',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-2',
                        name: 'ir_2',
                        type: 'select',
                        value: '',
                        listData: [
                          {
                            title: 'Koordinator',
                            value: 'Koordinator',
                          },
                          {
                            title: 'Kontributor',
                            value: 'Kontributor',
                          },
                        ],
                        validator: Yup.string(),
                        options: {
                          select: {
                            props: {
                              title: 'Ir II',
                              placeholder: 'Ir II',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-2',
                        name: 'ir_3',
                        type: 'select',
                        value: '',
                        listData: [
                          {
                            title: 'Koordinator',
                            value: 'Koordinator',
                          },
                          {
                            title: 'Kontributor',
                            value: 'Kontributor',
                          },
                        ],
                        validator: Yup.string(),
                        options: {
                          select: {
                            props: {
                              title: 'Ir III',
                              placeholder: 'Ir III',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-2',
                        name: 'ir_4',
                        type: 'select',
                        value: '',
                        listData: [
                          {
                            title: 'Koordinator',
                            value: 'Koordinator',
                          },
                          {
                            title: 'Kontributor',
                            value: 'Kontributor',
                          },
                        ],
                        validator: Yup.string(),
                        options: {
                          select: {
                            props: {
                              title: 'Ir IV',
                              placeholder: 'Ir IV',
                            },
                          },
                        },
                      },
                      {
                        className: 'col-2',
                        name: 'irvest',
                        type: 'select',
                        value: '',
                        listData: [
                          {
                            title: 'Koordinator',
                            value: 'Koordinator',
                          },
                          {
                            title: 'Kontributor',
                            value: 'Kontributor',
                          },
                        ],
                        validator: Yup.string(),
                        options: {
                          select: {
                            props: {
                              title: 'Irvest',
                              placeholder: 'Irvest',
                            },
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]
export {inputList}
