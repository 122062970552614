import * as Yup from 'yup'
import { FormInputProps } from '../../../helper/FormInput'
import { inputListPenomoran } from '../penomoran-naskah/InputListPenomoran/InputLayout'
import { inputList } from '../../tata-usaha/pengelolaan-naskah-dinas/klasifikasi-naskah/InputLayout'


//inidia
const formInputList: FormInputProps[] = [
  {
    className: 'col-12 col-md-4',
    name: 'nomor_dimulai',
    type: 'input',
    value: '',
    validator: Yup.number().required('Mohon Untuk Diisi'),
    options: {
      input: {
        props: {
          title: 'Nomor Dimulai',
          placeholder: 'Nomor Dimulai',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8',
    name: 'kombinasi_variabel',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon Untuk Diisi'),
    options: {
      input: {
        props: {
          title: 'Kombinasi Variabel',
          placeholder: 'Kombinasi Variabel',
        },
      },
    },
  },
  {
    name: 'reset_nomor_id',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Reset Nomor',
          placeholder: 'Reset Nomor',
        },
      },
      datalist: {
        api: '/tata_usaha/reset-nomor',
        id: 'id',
        params: 'nama',
        result: '$nama',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'nama',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/reset-nomor',
            },
            headerData: [
              {
                title: 'No.',
                props: { className: 'min-w-50px' },
              },
              {
                title: 'Reset Nomor',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputListPenomoran,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputListPenomoran,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    name: 'jenis_penomoran_id',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jenis Penomoran',
          placeholder: 'Jenis Penomoran',
        },
      },
      datalist: {
        api: '/tata_usaha/jenis-penomoran',
        id: 'id',
        params: 'nama',
        result: '$nama',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'nama',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/jenis-penomoran',
            },
            headerData: [
              {
                title: 'No.',
                props: { className: 'min-w-50px' },
              },
              {
                title: 'Jenis Penomoran',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputListPenomoran,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputListPenomoran,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    name: 'jenis_penulisan_bulan_id',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jenis Penulisan Bulan',
          placeholder: 'Jenis Penulisan Bulan',
        },
      },
      datalist: {
        api: '/tata_usaha/jenis-penulisan-bulan',
        id: 'id',
        params: 'nama',
        result: '$nama',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'nama',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/jenis-penulisan-bulan',
            },
            headerData: [
              {
                title: 'No.',
                props: { className: 'min-w-50px' },
              },
              {
                title: 'Jenis Penulisan Bulan',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputListPenomoran,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputListPenomoran,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    name: 'jenis_naskah_id',
    type: 'datalist',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jenis Naskah',
          placeholder: 'Jenis Naskah',
        },
      },
      datalist: {
        api: '/tata_usaha/jenis-naskah',
        id: 'id',
        params: 'text',
        result: '$text',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'text',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/jenis-naskah',
            },
            headerData: [
              {
                title: 'No.',
                props: { className: 'min-w-50px' },
              },
              {
                title: 'Jenis Naskah',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputList,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputList,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8',
    name: 'variabel_1',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon Untuk Diisi'),
    options: {
      input: {
        props: {
          title: 'Variabel 1',
          placeholder: 'Masukkan Variabel 1',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8',
    name: 'variabel_2',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon Untuk Diisi'),
    options: {
      input: {
        props: {
          title: 'Variabel 2',
          placeholder: 'Masukkan Variabel 2',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8',
    name: 'variabel_3',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon Untuk Diisi'),
    options: {
      input: {
        props: {
          title: 'Variabel 3',
          placeholder: 'Masukkan Variabel 3',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8',
    name: 'variabel_4',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon Untuk Diisi'),
    options: {
      input: {
        props: {
          title: 'Variabel 4',
          placeholder: 'Masukkan Variabel 4',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8',
    name: 'variabel_5',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon Untuk Diisi'),
    options: {
      input: {
        props: {
          title: 'Variabel 5',
          placeholder: 'Masukkan Variabel 5',
        },
      },
    },
  },
]

export { formInputList }
