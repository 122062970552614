import {DataRoutesProps} from '../../UserRolesValidation'
import {MainWrapper} from '../../../pages/dashboard/irjen/DashboardIrjen'

const KomunikasiHasilPengawasan: DataRoutesProps = {
  title: 'Komunikasi Hasil Pengawasan',
  icon: 'element-10',
  route: 'komunikasi_hasil_pengawasan',
  elements: <></>,
  subroute: [
    {
      title: 'Konvergensi hasil pengawasan',
      icon: 'element-11',
      route: 'konvergensi-hasil-pengawasan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Kapitalisasi hasil pengawasan',
      icon: 'element-11',
      route: 'kapitalisasi-hasil-pengawasan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penyampaian informasi hasil pengawasan',
      icon: 'element-11',
      route: 'penyampaian-info-hasil-pengawasan',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default KomunikasiHasilPengawasan
