import {DataRoutesProps} from "../../../../UserRolesValidation";
import {
    MainWrapper,
    TambahWrapper,
    UbahWrapper,
    DetailWrapper
} from "../../../../../pages/pengawasan-internal/pelaporan-pengawasan-internal/pelaporan-pengawasan-haji/katering/MainWrapper";

const Katering: DataRoutesProps[] = [
    {
        icon: '',
        title: '',
        route: '*',
        elements: <MainWrapper />,
        subroute: []
    },
    {
        icon: '',
        title: '',
        route: 'tambah',
        elements: <TambahWrapper />,
        subroute: [],
    },
    {
        icon: '',
        title: '',
        route: 'ubah/:id',
        elements: <UbahWrapper />,
        subroute: [],
    },
    {
        icon: '',
        title: '',
        route: 'detail/:id',
        elements: <DetailWrapper />,
        subroute: [],
    },
]

export {Katering}
