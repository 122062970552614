import { DataRoutesProps } from '../../UserRolesValidation'
import MainWrapper, { DetailWrapper, TambahWrapper, UbahWrapper } from '../../../pages/tata-usaha/pengelolaan-naskah-dinas/klasifikasi-berkas/MainWrapper'

const RouteKlasifikasiBerkas: DataRoutesProps[] = [
	{
		icon: '',
		title: '',
		route: '*',
		elements: <MainWrapper />,
		subroute: [],
	},
	{
		icon: '',
		title: '',
		route: 'tambah',
		elements: <TambahWrapper />,
		subroute: [],
	},
	{
		icon: '',
		title: '',
		route: 'ubah/:id',
		elements: <UbahWrapper />,
		subroute: [],
	},
	{
		icon: '',
		title: '',
		route: 'detail/:id',
		elements: <DetailWrapper />,
		subroute: [],
	}
]

export default RouteKlasifikasiBerkas