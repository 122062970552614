import {getListYear} from '../../../helper/DateFns'
import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'rencana_strategis_id',
    type: 'datalist',
    validator: Yup.number().required('Rencana Strategis mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Rencana Strategis',
          placeholder: 'Rencana Strategis',
        },
      },
      datalist: {
        api: '/perencanaan_anggaran/rencana-strategis',
        params: 'tujuan,sasaran',
        result: (
          <>
            <div>
              <b>Tujuan: </b>$tujuan
            </div>
            <div>
              <b>Sasaran: </b>$sasaran
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'program_id',
    type: 'datalist',
    validator: Yup.number().required('Program mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Program',
          placeholder: 'Program',
        },
      },
      datalist: {
        api: '/kepegawaian/data-program',
        params: 'kode,nama',
        result: (
          <>
            <div>
              <b>Kode: </b>$kode
            </div>
            <div>
              <b>Nama: </b>$nama
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'kegiatan_id',
    type: 'datalist',
    validator: Yup.number().required('Kegiatan mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kegiatan',
          placeholder: 'Kegiatan',
        },
      },
      datalist: {
        api: '/kepegawaian/data-kegiatan',
        params: 'kode,nama',
        result: (
          <>
            <div>
              <b>Kode: </b>$kode
            </div>
            <div>
              <b>Nama: </b>$nama
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'satker_id',
    type: 'datalist',
    validator: Yup.number().required('Auditi mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Auditi',
          placeholder: 'Auditi',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker-unit',
        params: 'nama_satker',
        result: '$nama_satker',
        id: 'kode',
      },
    },
  },
  {
    className: 'col-12',
    name: 'tahun',
    type: 'select',
    listData: getListYear(),
    validator: Yup.string().required('Tahun mohon untuk diisi.'),
    options: {
      select: {
        props: {
          title: 'Tahun',
          placeholder: 'Tahun',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama',
    type: 'input',
    validator: Yup.string().required('Nama mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'besaran_resiko_audit',
    type: 'input',
    validator: Yup.number().required('Besaran Resiko Audit mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Besaran Resiko Audit',
          placeholder: 'Besaran Resiko Audit',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Data Unit',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'du_sppd',
    type: 'input',
    validator: Yup.number().required('SPPD mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'SPPD',
          placeholder: 'SPPD',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'du_lainnya',
    type: 'input',
    validator: Yup.number().required('Lainnya mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Lainnya',
          placeholder: 'Lainnya',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: '',
        labelType: 'line',
      },
    },
  },
  {
    className: 'col-12',
    name: 'dalnis',
    type: 'datalist',
    validator: Yup.number().required('Auditor Pengendali Teknis mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Auditor Pengendali Teknis',
          placeholder: 'Auditor Pengendali Teknis',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc&satker_id=$satker_id',
        params: 'nama,nip',
        result: '$nama | $nip',
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
      watchInput: {
        id: 'satker_id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'daltu',
    type: 'datalist',
    validator: Yup.number().required('Auditor Pengendali Mutu mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Auditor Pengendali Mutu',
          placeholder: 'Auditor Pengendali Mutu',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc&satker_id=$satker_id',
        params: 'nama,nip',
        result: '$nama | $nip',
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
      watchInput: {
        id: 'satker_id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'ketua',
    type: 'datalist',
    validator: Yup.number().required('Ketua Tim Auditor mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Ketua Tim Auditor',
          placeholder: 'Ketua Tim Auditor',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc&satker_id=$satker_id',
        params: 'nama,nip',
        result: '$nama | $nip',
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
      watchInput: {
        id: 'satker_id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'data_tenaga',
    type: 'component',
    validator: Yup.number().required('Anggota Tim Auditor mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Anggota Tim Auditor',
          placeholder: 'Anggota Tim Auditor',
        },
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'id_pegawai',
        type: 'datalist',
        value: '',
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Pegawai',
              placeholder: 'Pegawai',
            },
          },
          datalist: {
            api: '/kepegawaian/data-tenaga-audit',
            query: 'orderby=asc&satker_id=$satker_id',
            params: 'nama,nip',
            result: (
              <div>
                <div>
                  <b>Nama: </b>$nama
                </div>
                <div>
                  <b>NIP: </b>$nip
                </div>
              </div>
            ),
            id: 'id',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'nip', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/kepegawaian/data-tenaga-audit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'NIP'},
                ],
              },
            },
          },
          watchInput: {
            id: 'satker_id',
            scope: 'global',
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Tenaga Tata Usaha Audit',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'gol_iv',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'GOL IV',
          placeholder: 'GOL IV',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc&satker_id=$satker_id',
        params: 'nama,nip',
        result: (
          <div>
            <div>
              <b>Nama: </b>$nama
            </div>
            <div>
              <b>NIP: </b>$nip
            </div>
          </div>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
      watchInput: {
        id: 'satker_id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'gol_iii',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'GOL III',
          placeholder: 'GOL III',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc&satker_id=$satker_id',
        params: 'nama,nip',
        result: (
          <div>
            <div>
              <b>Nama: </b>$nama
            </div>
            <div>
              <b>NIP: </b>$nip
            </div>
          </div>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
      watchInput: {
        id: 'satker_id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'gol_i_ii',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'GOL I / II',
          placeholder: 'GOL I / II',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc&satker_id=$satker_id',
        params: 'nama,nip',
        result: (
          <div>
            <div>
              <b>Nama: </b>$nama
            </div>
            <div>
              <b>NIP: </b>$nip
            </div>
          </div>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
      watchInput: {
        id: 'satker_id',
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Sarana dan Prasarana Unit',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'spu_komputer',
    type: 'input',
    validator: Yup.string().required('Komputer mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Komputer',
          placeholder: 'Komputer',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'spu_kendaraan',
    type: 'input',
    validator: Yup.string().required('Kendaraan mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kendaraan',
          placeholder: 'Kendaraan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'spu_lainnya',
    type: 'input',
    validator: Yup.string().required('Lainnya mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Lainnya',
          placeholder: 'Lainnya',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: '',
        labelType: 'line',
      },
    },
  },
  {
    className: 'col-12',
    name: 'lain_lain',
    type: 'input',
    validator: Yup.string().required('Lain - Lain mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Lain - Lain',
          placeholder: 'Lain - Lain',
        },
      },
    },
  },
]

export {formInputList}
