import {DataRoutesProps} from '../../UserRolesValidation'
import {MainWrapper} from '../../../pages/dashboard/irjen/DashboardIrjen'

const OrganisasiTataLaksana: DataRoutesProps = {
  title: 'Organisasi Tata Laksana',
  icon: 'element-10',
  route: 'organisasi_tata_laksana',
  elements: <></>,
  subroute: [
    {
      title: 'Penyusunan laporan kinerja',
      icon: 'element-11',
      route: 'penyusunan-laporan-kinerja',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penyusunan analisis beban kerja dan analisis jabatan',
      icon: 'element-11',
      route: 'penyusunan-analisis-beban',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penyiapan tindak lanjut hasil pengawasan',
      icon: 'element-11',
      route: 'penyusunan-tindak-lanjut',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Fasilitasi penyusunan SOP',
      icon: 'element-11',
      route: 'fasilitasi-penyusunan-sop',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pelayanan LHKPN & LHKASN',
      icon: 'element-11',
      route: 'pelayanan-lhkpn',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pelaksanaan RB & SPIP',
      icon: 'element-11',
      route: 'pelaksanaan-rb-spip',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default OrganisasiTataLaksana
