import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfFontBuilder,
  PdfLayoutDataProps,
  PdfSignatureBuilder,
  PdfStyle,
  PdfTabCol,
} from '../../../../helper/ReactPdf'
import {
  NullProof,
  createFormObjectList,
} from '../../../../helper/AppFunction'
import {listVar} from './InputLayout'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'

  const listName = createFormObjectList(listVar, [], 'name')
  let _data: any = []
  listVar.map((l, i) => {
    _data.push({
      name: listName[i],
      number: l.number,
      title: l.title,
    })
  })
  _data[3].desc = `meliputi: \na. pengumpulan data dan profil Auditi serta menginventarisir permasalahannya.\nb. menelaah KKA, LHA, dan rekomendasi yang dilaksanakan sebelumnya baik oleh Itjen Kemenag
  maupun dari instansi pengawasan ekternal\nc. penelaah peraturan perundang-undangan`
  console.log(listName)

  return (
    <Document title='Dokumen'>
      <Page size={{width: '1000'}} style={{...PdfStyle.page, fontFamily: 'Arial', padding: '20pt'}}>
        <View style={{textAlign: 'center'}}>
          <Text
            style={{
              textTransform: 'uppercase',
              textDecoration: 'underline',
              ...PdfFontBuilder({template: 'title', size: 'large'}),
            }}
          >
            Tanggal dan Alokasi Waktu Audit
          </Text>
          <Text style={{...PdfFontBuilder({template: 'title', size: 'large'})}}>
            (hanya jam-jam efektif)
          </Text>
        </View>
        <PdfTabCol direction='column'>
          {/* Header */}
          <PdfTabCol style={{view: {height: '80pt'}}}>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '5%'}}}>
              No
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '40%'}}}>
              Jenis pekerjaan yang harus dilakukan
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '20%'}}}>
              Ketua Tim & Anggota Tim
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '15%'}}}>
              Tanggal
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{}} style={{view: {width: '20%'}}}>
              Rencana / Alokasi Waktu
            </PdfTabCol>
          </PdfTabCol>
          {/* Data */}
          {_data?.map((l: any, i: number) => {
            const _subdata = NullProof({
              input: data.data.detail[0],
              params: l?.name || '',
              isMap: true,
            })[0]
            console.log(l)
            return (
              <Fragment key={i}>
                <PdfTabCol style={{view: {height: ''}}}>
                  <PdfTabCol
                    center={Number(l?.number).toString() === l?.number || false}
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{
                      view: {
                        width: '5%',
                      },
                    }}
                  >
                    {l?.number}
                  </PdfTabCol>
                  <PdfTabCol
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{
                      view: {
                        width: '40%',
                        textAlign: 'left',
                        textTransform:
                          Number(l?.number).toString() === l?.number || false
                            ? 'capitalize'
                            : 'uppercase',
                      },
                    }}
                  >
                    {`${NullProof({input: l, params: 'title', isLabel: false})}\n${
                      NullProof({
                        input: l,
                        params: 'desc',
                        isLabel: false,
                      }) || ''
                    }`}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '20%'}}}
                  >
                    {NullProof({
                      input: _subdata,
                      params: 'anggaran_biaya',
                      type: 'currency',
                      isLabel: false,
                    }) || ''}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '15%'}}}
                  >
                    {NullProof({
                      input: _subdata,
                      params: 'tanggal',
                      type: 'date',
                      isLabel: false,
                    }) || ''}
                  </PdfTabCol>
                  <PdfTabCol center noWrap type='text' border={{}} style={{view: {width: '20%'}}}>
                    {NullProof({input: _subdata, params: 'anggaran_waktu', isLabel: false}) || ''}
                  </PdfTabCol>
                </PdfTabCol>
              </Fragment>
            )
          })}
        </PdfTabCol>
        <PdfSignatureBuilder
          data={[
            {
              line1: `${NullProof({
                input: data.data,
                params: 'lokasi',
              })}, ${NullProof({input: data.data, params: 'createdAt', type: 'date'})}`,
              line2: 'Ketua Tim,',
              typeSignature: 'string',
              signature: '$',
              name: NullProof({
                input: data.data,
                params: 'ketua_data.nama',
                label: 'Ketua Tim',
              }),
              align: 'center',
            },
          ]}
          options={{
            align: 'flex-end',
            data: {align: 'center'},
          }}
          style={{marginBottom: '100pt'}}
        />
      </Page>
    </Document>
  )
}

export default PdfLayout
