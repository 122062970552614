import {DataRoutesProps} from '../../../../UserRolesValidation'
import {
  MainWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
  PdfWrapper,
  PdfWrapperLinkEvidence,
} from '../../../../../pages/pengawasan-internal/pelaporan-pengawasan-internal/implementasi-pakta-integritas/form-evaluasi/MainWrapper'
import {KomitmenKerja} from './KomitmenKerja'

const FormEvalusi: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'pdf/:id',
    elements: <PdfWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'link_evidence/:id',
    elements: <PdfWrapperLinkEvidence />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: ':id/komitmen_kerja',
    elements: <></>,
    subroute: KomitmenKerja,
  },
]

export {FormEvalusi}
