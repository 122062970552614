import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfLayoutDataProps,
  PdfStyle,
  PdfTableColumnBuilder,
} from '../../../../helper/ReactPdf'
import {ConvertCurrency, NullProof} from '../../../../helper/AppFunction'
import {formatDate} from '../../../../helper/DateFns'

const HeaderContent: FC = () => {
  return (
    <>
      <PdfTableColumnBuilder
        borderBottom
        style={{textTransform: 'uppercase', fontWeight: 'semibold'}}
      >
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl. Pencairan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '400px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            MAK
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Item
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nominal Pencairan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Kegiatan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Wil. / Bag.
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            No SPM (4DIGIT)
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nama
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            NIP
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '120px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SPD (5DIGIT)
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tujuan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '250px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            {`Tanggal\nPelaksanaan`}
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            HP
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Uang Harian
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Representasi
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Penginapan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Transport / Tiket
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            BST
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Realisasi
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Selisih
          </Text>
        </PdfTableColumnBuilder>
      </PdfTableColumnBuilder>
    </>
  )
}

const RowContent: FC<{input: any; index?: number; isLast?: boolean}> = ({input, index, isLast}) => {
  return (
    <>
      <PdfTableColumnBuilder borderBottom={isLast}>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            {formatDate({
              date: NullProof({input: input, params: 'tanggal_perancangan'}),
              dateFormat: 'dd-MMM-yy',
            })}
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '400px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            {NullProof({input: input, params: 'mak'})}
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            {ConvertCurrency(NullProof({input: input, params: 'jumlah'}))}
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Kegiatan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Wil. / Bag.
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            No SPM (4DIGIT)
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nama
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            NIP
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '120px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SPD (5DIGIT)
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tujuan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tanggal Pelaksanaan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            HP
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Uang Harian
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Representasi
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Penginapan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Transport / Tiket
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            BST
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Realisasi
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Selisih
          </Text>
        </PdfTableColumnBuilder>
      </PdfTableColumnBuilder>
    </>
  )
}

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data = [{mak: 'Test'}, {mak: 'Test'}, {mak: 'Test'}]

  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{width: '100%'}}>
          <PdfTableColumnBuilder direction='column' border>
            {/* Header */}
            <HeaderContent />
            {/* Looping Here */}
            {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <RowContent input={l} />
              </Fragment>
            ))}
          </PdfTableColumnBuilder>
        </View>
      </Page>
    </Document>
  )
}

const PdfLayoutSemua: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  console.log(data.data)

  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{width: '100%'}}>
          <PdfTableColumnBuilder direction='column' border>
            {/* Header */}
            <HeaderContent />
            {/* Looping Here */}
            {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
              let totalJumlah: number = 0
              let totalRealisasi: number = 0
              let totalSelisih: number = 0
              return (
                <Fragment key={i}>
                  {NullProof({input: l, params: 'data_kegiatan', isMap: true})?.map(
                    (lkegiatan: any, ikegiatan: number) => {
                      totalJumlah += NullProof({input: lkegiatan, params: 'uang_jumlah'}) || 0
                      totalRealisasi += NullProof({input: lkegiatan, params: 'uang_realisasi'}) || 0
                      totalSelisih += NullProof({input: lkegiatan, params: 'uang_selisih'}) || 0
                      return (
                        <Fragment key={ikegiatan}>
                          <PdfTableColumnBuilder borderBottom>
                            <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {formatDate({
                                  date: NullProof({input: l, params: 'tanggal_perancangan'}),
                                  dateFormat: 'dd-MMM-yy',
                                })}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '400px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {NullProof({input: l, params: 'mak'})}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {ikegiatan == 0 && NullProof({input: l, params: 'item'})}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {ikegiatan == 0 && ConvertCurrency(totalJumlah)}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {NullProof({input: lkegiatan, params: 'nama'})}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {NullProof({input: lkegiatan, params: 'wilayah_bagian'})}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {NullProof({input: lkegiatan, params: 'no_spm'})}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {NullProof({input: lkegiatan, params: 'pegawai.nama'})}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {NullProof({input: lkegiatan, params: 'pegawai.nip'})}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '120px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {NullProof({input: lkegiatan, params: 'no_spd'})}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {NullProof({input: lkegiatan, params: 'tujuan'})}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '250px'}}>
                              <PdfTableColumnBuilder center borderRight style={{width: '50%'}}>
                                <Text
                                  style={{
                                    textAlign: 'center',
                                    padding: '10px',
                                  }}
                                >
                                  {formatDate({
                                    date: NullProof({
                                      input: lkegiatan,
                                      params: 'tanggal_pelaksanaan_mulai',
                                    }),
                                    dateFormat: 'dd-MMM-yy',
                                  })}
                                </Text>
                              </PdfTableColumnBuilder>
                              <PdfTableColumnBuilder center style={{width: '50%'}}>
                                <Text
                                  style={{
                                    textAlign: 'center',
                                    padding: '10px',
                                  }}
                                >
                                  {formatDate({
                                    date: NullProof({
                                      input: lkegiatan,
                                      params: 'tanggal_pelaksanaan_selesai',
                                    }),
                                    dateFormat: 'dd-MMM-yy',
                                  })}
                                </Text>
                              </PdfTableColumnBuilder>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {NullProof({input: lkegiatan, params: 'hp'})}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {ConvertCurrency(
                                  NullProof({input: lkegiatan, params: 'uang_harian'})
                                )}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {ConvertCurrency(
                                  NullProof({input: lkegiatan, params: 'uang_representasi'})
                                )}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {ConvertCurrency(
                                  NullProof({input: lkegiatan, params: 'uang_penginapan'})
                                )}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {ConvertCurrency(
                                  NullProof({input: lkegiatan, params: 'uang_transport'})
                                )}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {ConvertCurrency(NullProof({input: lkegiatan, params: 'uang_bst'}))}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {ConvertCurrency(
                                  NullProof({input: lkegiatan, params: 'uang_jumlah'})
                                )}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {ConvertCurrency(
                                  NullProof({input: lkegiatan, params: 'uang_realisasi'})
                                )}
                              </Text>
                            </PdfTableColumnBuilder>
                            <PdfTableColumnBuilder center style={{width: '200px'}}>
                              <Text
                                style={{
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                }}
                              >
                                {ConvertCurrency(
                                  NullProof({input: lkegiatan, params: 'uang_selisih'})
                                )}
                              </Text>
                            </PdfTableColumnBuilder>
                          </PdfTableColumnBuilder>
                        </Fragment>
                      )
                    }
                  )}
                  <PdfTableColumnBuilder
                    borderBottom={
                      NullProof({input: data, params: 'data', isMap: true})?.length !== i + 1
                    }
                  >
                    <PdfTableColumnBuilder
                      borderRight
                      style={{width: '3270px', justifyContent: 'flex-end', alignItems: 'center'}}
                    >
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'right',
                        }}
                      >
                        Total
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(totalJumlah)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(totalRealisasi)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(totalSelisih)}
                      </Text>
                    </PdfTableColumnBuilder>
                  </PdfTableColumnBuilder>
                </Fragment>
              )
            })}
          </PdfTableColumnBuilder>
        </View>
      </Page>
    </Document>
  )
}

export {PdfLayout, PdfLayoutSemua}
