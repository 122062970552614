import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfFontBuilder,
  PdfLayoutDataProps,
  PdfSignatureBuilder,
  PdfStyle,
  PdfTabCol,
} from '../../../helper/ReactPdf'
import {NullProof} from '../../../helper/AppFunction'
import {UrutkanAnggotaSt} from '../../../helper/KemenagFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  let _data = []
  const isMultipleData = Array.isArray(data.data)
  if (isMultipleData) {
    _data = [...data.data]
  } else {
    _data.push(data.data)
  }
  data.data.lokasi = 'Jakarta'

  const listSkor: any[] = [
    {title: 'Skor Pengawasan', name: 'skor_pengawasan', fee: 15},
    {title: 'SDM Auditi', name: 'sdm_auditi', fee: 5},
    {title: 'Perubahan Personil Kunci', name: 'perubahan_personil', fee: 5},
    {title: 'Jenis dan Tipologi Satker', name: 'jenis_tipologi', fee: 10},
    {title: 'Anggaran', name: 'anggaran', fee: 10},
    {title: 'Temuan dan Tindak Lanjut', name: 'temuan_tindak_lanjut', fee: 20},
    {title: 'Tahun Terakhir Audit', name: 'tahun_terakhir_audit', fee: 20},
    {title: 'Jarak Audit', name: 'jarak_audit', fee: 10},
    {title: 'Sistem Informasi', name: 'sistem_informasi', fee: 5},
  ]

  return (
    <Document title='Dokumen'>
      <Page size={{width: '1500'}} style={{...PdfStyle.page, fontFamily: 'Arial', padding: '20pt'}}>
        <View style={{fontWeight: 'bold', textAlign: 'center'}}>
          <Text
            style={{
              textTransform: 'uppercase',
              ...PdfFontBuilder({template: 'title', size: 'large'}),
            }}
          >
            Usulan Program Kerja Audit Tahunan
          </Text>
          <Text style={{...PdfFontBuilder({template: 'title', size: 'large'})}}>
            Tahun Audit {NullProof({input: data.data, params: 'tahun'})}
          </Text>
        </View>
        <PdfTabCol direction='column'>
          {/* Header */}
          <PdfTabCol style={{view: {height: '130pt'}}} border={{}}>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '5%'}}}>
              No
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '20%'}}}>
              Auditi
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              Risiko
            </PdfTabCol>
            <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '20%'}}}>
              <PdfTabCol center type='text' border={{bottom: 1}} style={{view: {height: '70pt'}}}>
                Tanggal
              </PdfTabCol>
              <PdfTabCol center border={{}}>
                <PdfTabCol type='text' center noWrap border={{right: 1}}>
                  Mulai
                </PdfTabCol>
                <PdfTabCol type='text' center noWrap border={{}}>
                  Selesai
                </PdfTabCol>
              </PdfTabCol>
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              Nama Auditor
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              Jabatan
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '10%'}}}>
              Biaya (Rp)
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '5%'}}}>
              LHA
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{}} style={{view: {width: '10%'}}}>
              Keterangan
            </PdfTabCol>
          </PdfTabCol>
          {/* Data */}
          {_data?.map((l: any, i: number) => {
            const listAuditor = UrutkanAnggotaSt(
              NullProof({input: l, params: 'list_auditor', isMap: true})
            )
            return (
              <Fragment key={i}>
                <PdfTabCol style={{view: {}}} border={{top: 1}}>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '5%'}}}
                  >
                    {i + 1}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '20%'}}}
                  >
                    {NullProof({
                      input: l,
                      params: 'rencana_audit_jangka_menengah.satker.nama_satker',
                    })}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '10%'}}}
                  >
                    {NullProof({input: l, params: 'besaran_resiko_audit'})}
                  </PdfTabCol>
                  <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '20%'}}}>
                    <PdfTabCol center border={{}}>
                      <PdfTabCol type='text' center border={{right: 1}}>
                        {NullProof({input: l, params: 'tgl_mulai', type: 'date'})}
                      </PdfTabCol>
                      <PdfTabCol type='text' center border={{}}>
                        {NullProof({input: l, params: 'tgl_selesai', type: 'date'})}
                      </PdfTabCol>
                    </PdfTabCol>
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    direction='column'
                    border={{right: 1}}
                    style={{view: {width: '10%', textTransform: 'capitalize'}}}
                  >
                    {listAuditor?.map((ls: any, is: number) => (
                      <Fragment key={is}>
                        <PdfTabCol type='text' center border={{}} noWrap style={{}}>
                          {NullProof({input: ls, params: 'pegawai.nama'})?.toLowerCase()}
                        </PdfTabCol>
                      </Fragment>
                    ))}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    direction='column'
                    border={{right: 1}}
                    style={{view: {width: '10%', textTransform: 'capitalize'}}}
                  >
                    {listAuditor?.map((ls: any, is: number) => (
                      <Fragment key={is}>
                        <PdfTabCol type='text' center border={{}} noWrap style={{}}>
                          {NullProof({input: ls, params: 'jabatan'})?.toLowerCase()}
                        </PdfTabCol>
                      </Fragment>
                    ))}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '10%'}}}
                  >
                    {NullProof({input: l, params: 'biaya', type: 'currency'})}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '5%'}}}
                  >
                    {NullProof({input: l, params: 'lha'})}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='html'
                    border={{right: 1}}
                    style={{view: {width: '10%'}}}
                  >
                    {NullProof({input: data.data, params: 'keterangan'})}
                  </PdfTabCol>
                </PdfTabCol>
              </Fragment>
            )
          })}
        </PdfTabCol>
        <PdfSignatureBuilder
          data={[
            {
              line1: `${NullProof({
                input: data.data,
                params: 'lokasi',
              })}, ${NullProof({input: data.data, params: 'createdAt', type: 'date'})}`,
              line2: 'Inspektur,',
              typeSignature: 'string',
              signature: '$',
              align: 'center',
              name: NullProof({
                input: data.data,
                params: 'ketua_data.nama',
                label: 'Inspektur',
              }),
            },
          ]}
          options={{
            align: 'flex-end',
            data: {align: 'flex-end'},
          }}
          style={{marginBottom: '100pt'}}
        />
      </Page>
    </Document>
  )
}

export default PdfLayout
