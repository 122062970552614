/* eslint-disable react/jsx-no-target-blank */
import {FC, Fragment, useEffect, useState} from 'react'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {
  DataRoutesProps,
  ParseRouteMenuV2,
  ParseRoutesMenu,
  SidebarMenuBuilder,
  hasAccessRoutes,
} from '../../../../../app/helper/UserRolesValidation'
import {Routes as DataRoutes} from '../../../../../app/helper/routes/index'
import {useDispatch, useSelector} from 'react-redux'
import {getData} from '../../../../../app/helper/FormAxios'
import {post as postRoute} from '../../../../../app/redux/routeSlice'
import {useAuth} from '../../../../../app/modules/auth'
import {useLocation} from 'react-router-dom'

const ListMenu: FC<{data: DataRoutesProps[]; route?: string; isChild?: boolean}> = ({
  data,
  route,
  isChild,
}) => {
  const {pathname} = useLocation()
  const {auth} = useAuth()
  const roleId = auth?.data.role_id
  const routeData = useSelector((state: any) => state.route.value)
  let children: any = []
  data.map((l, i) => {
    let routeName = ''
    try {
      routeName = routeData?.label[`${route || ''}/${l.route}`.substring(1)] || ''
    } catch (_) {}
    if (l.subroute.length > 0 && !l.isPage) {
      const to = `${route || ''}/${l.route}`
      const result = (
        <Fragment key={i}>
          <SidebarMenuItemWithSub
            key={i}
            to={`${route || ''}/${l.route}`}
            title={routeName || l.title}
            // icon={isChild ? '' : l.icon}
            icon={isChild ? '' : routeData?.icon[to.substring(1)] || 'RiLayout2Line'}
            hasBullet={isChild}
          >
            <ListMenu data={l.subroute} route={to} isChild />
          </SidebarMenuItemWithSub>
        </Fragment>
      )
      if (hasAccessRoutes(routeData?.route, to.substring(1), roleId === 1, true) || l.isLabel) {
        if (!l.onlyRoute) {
          children.push(result)
        }
      }
    } else {
      const to = `${route || ''}/${l.route}`
      const isActive =
        (pathname === to && pathname.includes(to)) ||
        (pathname.includes(to) && pathname.replaceAll(to, '').includes('/'))
      const result = (
        <Fragment key={i}>
          <SidebarMenuItem
            key={i}
            to={to}
            isActive={isActive}
            // icon={isChild ? '' : l.icon}
            icon={isChild ? '' : routeData?.icon[to.substring(1)] || 'RiLayout2Line'}
            title={routeName || l.title}
            hasBullet={isChild}
          />
        </Fragment>
      )
      if (
        hasAccessRoutes(
          routeData?.route,
          `${route || ''}/${l.route}`.substring(1),
          roleId === 1,
          true
        ) ||
        l.isLabel
      ) {
        if (!l.onlyRoute) {
          if (l.isLabel) {
            children.push(
              <Fragment key={i}>
                <ListMenuLabel label={l.title} />
              </Fragment>
            )
          } else {
            children.push(result)
          }
        }
      }
    }
  })
  return children
}

const ListMenuLabel: FC<{label: string}> = ({label}) => {
  return (
    <div className='menu-item'>
      {label === 'Option' ? (
        <div className='menu-content pt-4 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{label}</span>
        </div>
      ) : (
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{label}</span>
        </div>
      )}
    </div>
  )
}

const SidebarMenuMain = () => {
  const data: any = SidebarMenuBuilder(DataRoutes)
  const routeData = useSelector((state: any) => state.route.value)
  const {auth} = useAuth()
  const dispatch = useDispatch()
  const loadData = async () => {
    const roleId = auth?.data.role_id
    let json: any = []
    if (routeData?.route < 1) {
      json = await getData('', `/account/roles/permission/${roleId}`)
      if (json.status) {
        const result = ParseRouteMenuV2({api: json?.data, options: {isAdmin: roleId === 1}})
        dispatch(postRoute(result.route))
      }
    }
  }
  useEffect(() => {
    loadData()
    //
  }, [])
  return (
    <>
      <ListMenu data={data} />
    </>
  )
}

export {SidebarMenuMain}
