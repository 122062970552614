import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        params: 'nomor_surat,nama_kegiatan',
        result: '$nomor_surat | $nama_kegiatan',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'temuan_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Temuan is required'),
    options: {
      input: {
        props: {
          title: 'Temuan',
          placeholder: 'Temuan',
        },
      },
      datalist: {
        api: '/pengawasan_internal/form-temuan',
        query: 'surat_tugas_id=$surat_tugas_id',
        params: 'kondisi,rekomendasi',
        result: 'Kondisi: $kondisi | Rekomendasi: $rekomendasi',
        id: 'id',
      },
      watchInput: {
        id: 'surat_tugas_id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'tanggal',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal is required'),
    options: {
      input: {
        props: {
          title: 'Tanggal',
          placeholder: 'Tanggal',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'perihal',
    type: 'input',
    value: '',
    validator: Yup.string().required('Perihal is required'),
    options: {
      input: {
        props: {
          title: 'Perihal',
          placeholder: 'Perihal',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'ekseplar',
    type: 'input',
    value: '',
    validator: Yup.string().required('Ekseplar is required'),
    options: {
      input: {
        props: {
          title: 'Ekseplar',
          placeholder: 'Ekseplar',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tindak_lanjut',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Tindak Lanjut is required'),
    options: {
      textarea: {
        props: {
          title: 'Tindak Lanjut',
          placeholder: 'Tindak Lanjut',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tanggal_penyelesaian',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Penyelesaian is required'),
    options: {
      input: {
        props: {
          title: 'Tanggal Penyelesaian',
          placeholder: 'Tanggal Penyelesaian',
          type: 'date',
        },
      },
    },
  },
]

export {formInputList}
