import {PageDataContent} from '../../../../helper/Model'
import {TableInputDataProps} from '../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../helper/TableInputDetail'

interface PageDataProps {
  semua: {
    page: PageDataContent
  }
  detail: {
    page: PageDataContent
  }
  tambah: {
    page: PageDataContent
  }
  ubah: {
    page: PageDataContent
  }
  pdf: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Identifikasi',
  path: '/kebijakan_teknis/risk_register/identifikasi/:id',
  api: '/kebijakan_teknis/risk-register/:id/identifikasi',
}

const BaseBreadcrumb = [
  {
    title: 'Perumusan Kebijakan Teknis Pengawasan',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Risk Register',
    path: '/kebijakan_teknis/risk_register',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
    },
    {
      title: 'Tujuan',
    },
    {
      title: 'Proses Bisnis',
    },
  ],
  link: {
    tambah: {
      id: 'tambah',
      protect: 'identifikasi/:id/tambah',
      title: 'Tambah',
      route: 'tambah',
    },
    action: [
      {
        id: 'id',
        protect: 'identifikasi/:id/detail/:subid',
        title: 'Detail',
        route: 'identifikasi/$id/detail/:id',
      },
      {
        id: 'id',
        protect: 'identifikasi/:id/ubah/:subid',
        title: 'Ubah',
        route: 'identifikasi/$id/ubah/:id',
      },
      {
        id: 'id',
        protect: 'analisis/:id',
        title: 'Analisis',
        route: `analisis/:id`,
      },
    ],
  },
  route: {
    url: InfoData.api,
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:subid`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
