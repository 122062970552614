import {DataRoutesProps} from '../../UserRolesValidation'
import {RencanaStrategisWrapper} from '../../../pages/rencana-strategis/RencanaStrategisWrapper'
import {FormAppRoutes} from './FormApp'
import {RiskRegisterRoutes} from './risk-register/RiskRegister'

const KebijakanTeknis: DataRoutesProps = {
  title: 'Kebijakan Teknis',
  icon: 'element-10',
  route: 'kebijakan_teknis',
  elements: <RencanaStrategisWrapper />,
  subroute: [
    {
      isPage: true,
      title: 'Form APP',
      icon: 'element-10',
      route: 'form_app',
      elements: <></>,
      subroute: FormAppRoutes,
    },
    {
      isPage: true,
      title: 'Risk Register',
      icon: 'element-10',
      route: 'risk_register',
      elements: <></>,
      subroute: RiskRegisterRoutes,
    },
    {
      isPage: true,
      title: 'Perumusan Kebijakan Teknis Pengawasan',
      icon: 'element-10',
      route: 'perumusan_kebijakan_teknis_pengawasan',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default KebijakanTeknis
