import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {PdfLayoutDataProps, PdfStyle, PdfTableColumnBuilder} from '../../../../../helper/ReactPdf'
import {ConvertCurrency, NullProof} from '../../../../../helper/AppFunction'
import {formatDate} from '../../../../../helper/DateFns'

const HeaderContent: FC = () => {
  return (
    <>
      <PdfTableColumnBuilder
        borderBottom
        style={{textTransform: 'uppercase', fontWeight: 'semibold'}}
      >
        <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            No.
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            No. Rek. Debet
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nama Rek. Debet
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            No. Rek. Tujuan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nama Rek Tujuan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Notifikasi
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Bank
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Pelaku Perjadin
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tanggal Transfer
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SPD
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SPM
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SPMTGL
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            UM / VF
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nilai
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Kontrol
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '400px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Kontrol MAK
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SPDT
          </Text>
        </PdfTableColumnBuilder>
      </PdfTableColumnBuilder>
    </>
  )
}

const PdfLayoutSemua: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  console.log(data.data)

  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{width: '100%'}}>
          <PdfTableColumnBuilder direction='column' border>
            {/* Header */}
            <HeaderContent />
            {/* Looping Here */}
            {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {i + 1}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'no_rek_debet'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'nama_rek_debet'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'no_rek_tujuan'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'nama_rek_tujuan'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(NullProof({input: l, params: 'jumlah'}) || 0)}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {`${NullProof({
                        input: l,
                        params: 'notifikasi',
                      })}`}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'bank'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'pelaku_perjadin_data.nama'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal_transfer'}),
                        dateFormat: 'yyyyMMdd',
                      })}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'spd'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'spm'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {`${NullProof({input: l, params: 'spm'})}${formatDate({
                        date: NullProof({input: l, params: 'tanggal_transfer'}),
                        dateFormat: 'yyyyMMdd',
                      })}`}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'um_uf'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(NullProof({input: l, params: 'nilai'}) || 0)}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {`${NullProof({input: l, params: 'nama_rek_tujuan'})}${NullProof({
                        input: l,
                        params: 'spd',
                      })}${NullProof({input: l, params: 'um_uf'})}`}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '400px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {`${NullProof({input: l, params: 'kontrol_mak'})}`}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {`${NullProof({input: l, params: 'nama_rek_tujuan'})}${NullProof({
                        input: l,
                        params: 'spd',
                      })?.substring(0, 4)}`}
                    </Text>
                  </PdfTableColumnBuilder>
                </PdfTableColumnBuilder>
              </Fragment>
            ))}
          </PdfTableColumnBuilder>
        </View>
      </Page>
    </Document>
  )
}

export {PdfLayoutSemua}
