import {FC, Fragment, useEffect, useRef, useState} from 'react'
import {useThemeMode} from '../../_metronic/partials'
import CurrencyFormat from 'react-currency-format'
import {Link, useParams} from 'react-router-dom'
import {
  NullProof,
  RouterQueryParams,
  colorPalleteList,
  getColorDecimal,
  getColorPallete,
} from './AppFunction'
import ReactIcon from './ReactIcon'
import * as Icons from 'react-icons/ri'
import * as IconsHi2 from 'react-icons/hi2'
import ReactLoading from './ReactLoading'
import {getData} from './FormAxios'
import {WithChildren} from '../../_metronic/helpers'
import CountUpUi from './CountUp'

type SizeComponentProps = 'col-3' | 'col-4' | 'col-6' | 'col-12'
const componentResponsiveList = (minSize: 1 | 2 | 3 = 1) => {
  const minSizeList = {
    1: 'col-12',
    2: 'col-6',
    3: 'col-4',
  }
  const responsiveList = {
    item4: {
      'col-3': `${minSizeList[minSize]}`,
      'col-4': `${minSizeList[minSize]} col-md-6`,
      'col-6': `${minSizeList[minSize]} col-md-6`,
      'col-12': `${minSizeList[minSize]} col-md-3`,
    },
    item3: {
      'col-3': `${minSizeList[minSize]}`,
      'col-4': `${minSizeList[minSize]} col-md-6`,
      'col-6': `${minSizeList[minSize]} col-md-4`,
      'col-12': `${minSizeList[minSize]} col-md-4`,
    },
    item2: {
      'col-3': `${minSizeList[minSize]}`,
      'col-4': `${minSizeList[minSize]} col-md-6`,
      'col-6': `${minSizeList[minSize]} col-md-6`,
      'col-12': `col-12 col-md-6`,
    },
    item1: {
      'col-3': `${minSizeList[minSize]}`,
      'col-4': `${minSizeList[minSize]}`,
      'col-6': `${minSizeList[minSize]}`,
      'col-12': `${minSizeList[minSize]}`,
    },
  }
  return responsiveList
}

type DashboardDataProps = {
  id?: number
  title: string
  desc?: string | number
  value?: string | number
}

type ChartCircleProps = {
  size?: SizeComponentProps
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  id: string
  title?: string
  color?: 'primary' | 'danger' | 'success' | 'warning' | 'info'
  themeConfig?: {
    theme?: 'light' | 'dark'
    color?: {
      value?: string | keyof typeof colorPalleteList
      titlePrimary?: boolean
      textInvert?: boolean
    }
  }
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  isCurrency?: boolean
  data?: {
    title: string
    value: number
    color: string
  }[]
  filter?: {
    value: string
    placeholder?: string
    data: {
      label: string
      value: string
    }[]
  }
}

const ChartCircle: FC<ChartCircleProps> = ({
  size = 'col-12',
  props,
  id = 'circlechart',
  title = 'Title',
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
  isCurrency = false,
  data = [],
  filter,
  color = 'primary',
  themeConfig = {
    theme: 'dark',
    color: {
      titlePrimary: false,
      value: 'primary',
      textInvert: false,
    },
  },
}) => {
  const [datas, setDatas] = useState<any>([])
  const [totalDatas, setTotalDatas] = useState<number>(0)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) return
    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate)
    }, 10)
  }

  const initChart = function (
    chartSize: number = 70,
    chartLine: number = 11,
    chartRotate: number = 145
  ) {
    const el = document.getElementById(id)
    if (!el) return
    el.innerHTML = ''

    var options = {
      size: chartSize,
      lineWidth: chartLine,
      rotate: chartRotate,
    }

    const canvas = document.createElement('canvas')
    const span = document.createElement('span')

    // @ts-ignore
    if (typeof G_vmlCanvasManager !== 'undefined') {
      // @ts-ignore
      G_vmlCanvasManager.initElement(canvas)
    }

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size

    el.appendChild(span)
    el.appendChild(canvas)

    // @ts-ignore
    ctx.translate(options.size / 2, options.size / 2) // change center
    // @ts-ignore
    ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

    //imd = ctx.getImageData(0, 0, 240, 240);
    const radius = (options.size - options.lineWidth) / 2

    const drawCircle = function (color: string, lineWidth: number, percent: number) {
      percent = Math.min(Math.max(0, percent || 1), 1)
      if (!ctx) {
        return
      }

      ctx.beginPath()
      ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
      ctx.strokeStyle = color
      ctx.lineCap = 'round' // butt, round or square
      ctx.lineWidth = lineWidth
      ctx.stroke()
    }

    let totalData: number = 0
    let totalPercent: number = 0
    for (const item of data) {
      totalData += item.value
    }
    setTotalDatas(totalData)
    const result: any[] = [...data]

    // Init 2
    data.forEach((l, i) => {
      totalPercent += (l.value / totalData) * 100
      result[i].percent = (l.value / totalData) * 100
      result[i].pie = totalPercent
    })
    result
      .sort((a, b) => b.pie - a.pie)
      .forEach((l) => {
        drawCircle(l.color, options.lineWidth, l.pie / 100)
      })
    setDatas(result)
  }
  const baseColor = themeConfig.color?.value || '#FFFFFF'
  const colorPallete = getColorPallete(baseColor, 0.8)
  const appBackgroundColor =
    themeConfig.theme === 'light' ? colorPallete.lightColor : colorPallete.backgroundColor
  const appBackgroundColorHover =
    themeConfig.theme === 'light' ? colorPallete.lightColorHover : colorPallete.backgroundColorHover
  const textColor =
    themeConfig.theme === 'light' ? colorPallete.lightTextColor : colorPallete.textColor
  return (
    <div {...props} className={`${size}`}>
      <div className='card card-flush h-100'>
        <div className='d-flex flex-column gap-3'>
          <div
            className={`fs-2hx fw-bold d-flex flex-column p-4 rounded shadow`}
            style={{
              backgroundColor: appBackgroundColor,
              color: textColor,
            }}
          >
            <div className='d-flex justify-content-between'>
              <CurrencyFormat
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={isCurrency ? 'Rp. ' : ''}
                displayType='text'
                value={totalDatas}
              />
              {filter && (
                <div>
                  <select className='form-select form-select'>
                    <option value=''>{filter.placeholder || 'Pilih Data'}</option>
                    {filter.data.map((l, i) => (
                      <Fragment key={i}>
                        <option value={l.value}>{l.label}</option>
                      </Fragment>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className='fs-5'>{title}</div>
          </div>
          <div className='d-flex flex-row gap-4 p-4'>
            <div
              id={id}
              ref={chartRef}
              style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
              data-kt-size={chartSize}
              data-kt-line={chartLine}
            />
            <div className='row w-100 gx-2'>
              {datas.map((l: any, i: number) => (
                <div
                  key={i}
                  className={`d-flex align-items-center ${
                    (datas?.length || 0) % 4 === 0
                      ? componentResponsiveList(2).item4[size]
                      : (data?.length || 0) % 3 === 0
                      ? componentResponsiveList(2).item3[size]
                      : (data?.length || 0) % 2 === 0
                      ? componentResponsiveList(2).item2[size]
                      : 'col-6 col-md-12'
                  }`}
                >
                  <div className='d-flex flex-column fw-semibold my-1'>
                    <div className='d-flex align-items-center'>
                      <div
                        className='bullet w-8px h-3px rounded-2 me-3'
                        style={{backgroundColor: l.color}}
                      />
                      <div className='text-gray-500 flex-grow-1 me-4'>
                        {l.title} ({Math.round(l.percent)}%)
                      </div>
                    </div>
                    <div className=' fw-bolder text-gray-700 text-xxl'>
                      <CurrencyFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={isCurrency ? 'Rp. ' : ''}
                        displayType='text'
                        value={l.value}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type TotalDashboardProps = {
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  type?: 'currency' | 'number'
  isPercent?: boolean
  color?: string
  icon?: keyof typeof Icons
  isIcon?: boolean
  theme?: 'light' | 'dark'
  themeConfig?: {
    theme?: 'light' | 'dark'
    type?: 'default' | 'stripped' | 'variant'
    color?: {
      value?: string | keyof typeof colorPalleteList
      titlePrimary?: boolean
      textInvert?: boolean
    }
  }
  title: string
  total: number
  isTotal?: boolean
  maxTotal?: number
  link?: string
  data?: {
    title: string
    total: number
    className?: string
    link?: string
    icon?: keyof typeof Icons | keyof typeof IconsHi2
    iconColor?: string
  }[]
  list?: {
    show?: boolean
    data: DashboardDataProps[]
    valueType?: 'text' | 'number' | 'date' | 'currency'
    descType?: 'text' | 'number' | 'date' | 'currency'
    slug?: {
      data?: string
      id?: string
      external?: boolean
      label?: string
    }
    config?: {
      id: string
      className?: string
      label?: string
      labelClassName?: string
    }[]
  }
  api?: {
    query?: string
    url?: string
  }
  onComplete?: any
}

const TotalDashboard: FC<TotalDashboardProps> = ({
  props,
  title,
  link,
  total = 0,
  maxTotal = 1000,
  data = [],
  list,
  icon = 'RiDashboardLine',
  themeConfig = {
    theme: 'light',
    type: 'default',
    color: {
      titlePrimary: false,
      value: '#FFFFFF',
      textInvert: false,
    },
  },
  type = 'number',
  isPercent = false,
  api,
  onComplete,
}) => {
  const query = useParams()
  const [loading, setLoading] = useState(true)
  const checkPercent = Math.min(Math.round((total / (maxTotal || total)) * 100), 100)
  const percent = checkPercent
  const baseColor = themeConfig.color?.value || '#FFFFFF'
  const colorPallete = getColorPallete(baseColor, 0.8)
  const appColor =
    themeConfig.theme === 'light'
      ? themeConfig.color?.textInvert
        ? colorPallete.textColor
        : colorPallete.lightTextColor
      : themeConfig.color?.textInvert
      ? colorPallete.lightTextColor
      : colorPallete.textColor
  const appBackgroundColor =
    themeConfig.theme === 'light' ? colorPallete.lightColor : colorPallete.backgroundColor
  const appBackgroundColorHover =
    themeConfig.theme === 'light' ? colorPallete.lightColorHover : colorPallete.backgroundColorHover
  const textColor =
    themeConfig.theme === 'light' ? colorPallete.lightTextColor : colorPallete.textColor
  const colorDecimal = getColorDecimal(textColor)
  const SlugReplacer = (data: any, config: any) => {
    const id = config?.id?.split(',')
    let slug = config?.data
    for (const l of id) {
      slug = slug.replaceAll(`:${l}`, `${data[l]}`)
    }
    return slug
  }

  const reactLoadingConfig = {
    loadingProps: {
      style: {
        color: textColor,
      },
    },
  }

  const loadData = async () => {
    setLoading(true)
    let result: {status?: boolean; data?: any} = {status: false, data: {}}
    result = await getData(api?.query || '', api?.url || '')
    if (result?.status) {
      onComplete && onComplete(result?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (api) {
      loadData()
    } else {
      setLoading(false)
    }
  }, [])

  const LinkUi: FC<WithChildren & {slug?: string}> = ({children, slug}) => {
    return slug ? (
      <div className='d-flex'>
        <Link to={RouterQueryParams(slug, query)}>{children}</Link>
      </div>
    ) : (
      <>{children}</>
    )
  }

  return (
    <div {...props}>
      <div
        className={`card card-flush d-flex flex-column justify-content-evenly pb-0 rounded-bottom-0 ${
          list?.data && list.show ? 'text-center' : ''
        }`}
        style={{
          backgroundColor: appBackgroundColor,
          padding: '1rem',
        }}
      >
        <ReactLoading loading={loading} {...reactLoadingConfig}>
          <div className='fs-2hx fw-bold d-flex flex-column'>
            {!isPercent && (
              <>
                <ReactIcon icon={icon} props={{className: 'mb-2', style: {color: appColor}}} />
                <LinkUi slug={link}>
                  <CountUpUi
                    props={{style: {color: appColor}}}
                    value={total}
                    type={type === 'currency' ? 'currency' : 'default'}
                  />
                </LinkUi>
              </>
            )}
            <LinkUi slug={link}>
              <div className='fs-5 mb-4' style={{color: appColor}}>
                {title}
              </div>
            </LinkUi>
            {isPercent && (
              <>
                <div style={{color: appColor}}>
                  <div className='d-flex align-items-end gap-2'>
                    <div className='fs-2'>
                      <LinkUi slug={link}>
                        <CountUpUi
                          props={{style: {color: appColor}}}
                          value={total}
                          type={type === 'currency' ? 'currency' : 'default'}
                        />
                      </LinkUi>
                    </div>
                    <LinkUi slug={link}>
                      <div className='fs-7 opacity-75' style={{color: appColor}}>
                        {title}
                      </div>
                    </LinkUi>
                  </div>
                  <div className='d-flex gap-2 align-items-center' style={{color: appColor}}>
                    <div className='w-100'>
                      <div
                        className='h-8px w-100 rounded-pill'
                        style={{
                          backgroundColor: `rgba(${colorDecimal.red},${colorDecimal.green},${colorDecimal.blue}, 0.5)`,
                        }}
                      >
                        <div
                          className={`rounded h-8px`}
                          role='progressbar'
                          style={{width: `${percent}%`, backgroundColor: appColor}}
                        />
                      </div>
                    </div>
                    <div className='fs-6 fw-normal'>{percent}%</div>
                  </div>
                  <div className='fs-6 d-flex gap-2' style={{color: appColor}}>
                    <div>Dari</div>
                    <CountUpUi
                      props={{style: {color: appColor}}}
                      value={maxTotal}
                      type={type === 'currency' ? 'currency' : 'default'}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </ReactLoading>
      </div>
      <div
        className={`card card-flush d-flex flex-column rounded-top-0 ${
          list?.data && list.show ? 'text-center' : ''
        }`}
        style={{
          backgroundColor: list?.data && list.show ? appBackgroundColor : appBackgroundColorHover,
          padding: '1rem',
          paddingBottom: list?.data && list?.show ? '70px' : '1rem',
        }}
      >
        <div className='row g-2'>
          {data?.map((l, i) => (
            <div
              className={`${
                l.className
                  ? l.className
                  : `${
                      (data?.length || 0) % 3 === 0
                        ? 'col-4'
                        : (data?.length || 0) % 2 === 1
                        ? 'col-4'
                        : (data?.length || 0) % 2 === 0
                        ? 'col-6'
                        : 'col-12'
                    }`
              } fs-3 fw-bold d-flex flex-column`}
              style={{color: appColor}}
              key={i}
            >
              <ReactLoading hideTitle loading={loading} {...reactLoadingConfig}>
                <LinkUi slug={l.link}>
                  <CountUpUi
                    props={{style: {color: appColor}}}
                    value={l.total}
                    type={type === 'currency' ? 'currency' : 'default'}
                  />
                </LinkUi>
              </ReactLoading>
              <LinkUi slug={l.link}>
                <div className='fs-6' style={{color: appColor}}>
                  {l.title}
                </div>
              </LinkUi>
            </div>
          ))}
        </div>
      </div>
      {!loading && list?.show && (
        <div className='bg-body pb-3 card-rounded'>
          <div
            className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
            style={{marginTop: data ? '-50px' : '0'}}
          >
            {list?.data.map((item, index) => (
              <div key={index} className='d-flex align-items-center mb-6'>
                <div className='row align-items-center w-100 g-2'>
                  <div className='mb-1 pe-3 col'>
                    <span className='fs-5 text-gray-800 fw-bold'>{item.title}</span>
                    <div className='text-gray-400 fw-semibold fs-7'>{item.desc}</div>
                  </div>
                  {list?.slug ? (
                    <div className='col-auto'>
                      <div>
                        {list.slug.external ? (
                          <a href={SlugReplacer(item, list.slug)}>
                            <button
                              type='button'
                              className={`btn btn-primary px-8 py-2`}
                              style={{
                                //@ts-ignore
                                '--bs-primary': appBackgroundColor,
                                '--bs-primary-active': appBackgroundColorHover,
                              }}
                            >
                              {list.slug.label || 'Detail'}
                            </button>
                          </a>
                        ) : (
                          <Link to={SlugReplacer(item, list.slug)}>
                            <button
                              type='button'
                              className={`btn btn-primary px-8 py-2`}
                              style={{
                                //@ts-ignore
                                '--bs-primary': appBackgroundColor,
                                '--bs-primary-active': appBackgroundColorHover,
                              }}
                            >
                              {list.slug.label || 'Detail'}
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className='col-auto'>
                      <div className='fw-bold fs-5'>
                        {typeof item.value === 'string' ? (
                          item.value
                        ) : (
                          <CountUpUi
                            props={{style: {color: appColor}}}
                            value={item?.value || 0}
                            type={type === 'currency' ? 'currency' : 'default'}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {list.show && (
              <div className='d-flex align-items-center'>
                <button
                  type='button'
                  className={`btn btn-primary w-100`}
                  style={{
                    //@ts-ignore
                    '--bs-primary': appBackgroundColor,
                    '--bs-primary-active': appBackgroundColorHover,
                  }}
                >
                  Lihat Semua
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const dashboardDataBuilder = ({
  input = [],
  limit = 5,
  title = 'Data',
  params,
}: {
  input: any
  limit: number
  title: string
  params?: {title: string; desc?: string; value?: string; id?: string}
}) => {
  let result: any = []
  const data = {
    data: input || [],
  }
  input?.slice(0, limit).map((l: any, i: number) => {
    const _id = NullProof({input: l, params: params?.value || 'id'})
    const _title = params?.title
      ? NullProof({input: l, params: params?.title})
      : `${title} ${i + 1}`
    const _desc = params?.desc ? NullProof({input: l, params: params?.desc}) : null
    const _value = params?.value ? NullProof({input: l, params: params?.value}) : null
    result.push({
      id: _id,
      title: _title,
      desc: _desc,
      value: _value,
    })
  })
  return result
}

const TotalDashboardSmall: FC<TotalDashboardProps> = ({
  props,
  title,
  link,
  total = 0,
  isTotal = true,
  isIcon = true,
  maxTotal = 1000,
  data = [],
  icon = 'RiDashboardLine',
  themeConfig = {
    theme: 'light',
    type: 'default',
    color: {
      titlePrimary: false,
      value: '#FFFFFF',
      textInvert: false,
    },
  },
  type = 'number',
  isPercent = false,
  api,
  onComplete,
}) => {
  const query = useParams()
  const [loading, setLoading] = useState(true)
  const checkPercent = Math.min(Math.round((total / (maxTotal || total)) * 100), 100)
  const percent = checkPercent
  const baseColor = themeConfig.color?.value || '#FFFFFF'
  const colorPallete = getColorPallete(baseColor, 0.8)
  const appColor =
    themeConfig.theme === 'light'
      ? themeConfig.color?.textInvert
        ? colorPallete.textColor
        : colorPallete.lightTextColor
      : themeConfig.color?.textInvert
      ? colorPallete.lightTextColor
      : colorPallete.textColor
  const appBackgroundColor =
    themeConfig.theme === 'light' ? colorPallete.lightColor : colorPallete.backgroundColor
  const appBackgroundColorHover =
    themeConfig.theme === 'light' ? colorPallete.lightColorHover : colorPallete.backgroundColorHover
  const textColor =
    themeConfig.theme === 'light' ? colorPallete.lightTextColor : colorPallete.textColor
  const colorDecimal = getColorDecimal(textColor)
  const SlugReplacer = (data: any, config: any) => {
    const id = config?.id?.split(',')
    let slug = config?.data
    for (const l of id) {
      slug = slug.replaceAll(`:${l}`, `${data[l]}`)
    }
    return slug
  }

  const reactLoadingConfig = {
    loadingProps: {
      style: {
        color: textColor,
      },
    },
  }

  const loadData = async () => {
    setLoading(true)
    let result: {status?: boolean; data?: any} = {status: false, data: {}}
    result = await getData(api?.query || '', api?.url || '')
    if (result?.status) {
      onComplete && onComplete(result?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (api) {
      loadData()
    } else {
      setLoading(false)
    }
  }, [])

  const LinkUi: FC<WithChildren & {slug?: string}> = ({children, slug}) => {
    return slug ? (
      <div className='d-flex'>
        <Link to={RouterQueryParams(slug, query)}>{children}</Link>
      </div>
    ) : (
      <>{children}</>
    )
  }

  return (
    <div {...props} style={{backgroundColor: '#fff'}}>
      <div
        className={`d-flex flex-column justify-content-evenly pb-0 rounded-bottom-0`}
        style={{
          backgroundColor: appBackgroundColor,
          padding: '1rem',
        }}
      >
        <ReactLoading loading={loading} {...reactLoadingConfig}>
          <div className='fs-2hx fw-bold d-flex flex-column'>
            {!isPercent && (
              <>
                {isTotal && (
                  <LinkUi slug={link}>
                    <CountUpUi
                      props={{style: {color: appColor}}}
                      value={total}
                      type={type === 'currency' ? 'currency' : 'default'}
                    />
                  </LinkUi>
                )}
              </>
            )}
            <LinkUi slug={link}>
              <div className='fs-5 mb-4 pt-5 pb-3' style={{color: appColor}}>
                {title}
              </div>
            </LinkUi>
            {isPercent && (
              <>
                <div style={{color: appColor}}>
                  <div className='d-flex align-items-end gap-2'>
                    <div className='fs-2'>
                      <LinkUi slug={link}>
                        <CurrencyFormat
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={type === 'currency' ? 'Rp. ' : ''}
                          displayType='text'
                          value={total}
                          style={{color: appColor}}
                        />
                      </LinkUi>
                    </div>
                    <LinkUi slug={link}>
                      <div className='fs-7 opacity-75' style={{color: appColor}}>
                        {title}
                      </div>
                    </LinkUi>
                  </div>
                  <div className='d-flex gap-2 align-items-center' style={{color: appColor}}>
                    <div className='w-100'>
                      <div
                        className='h-8px w-100 rounded-pill'
                        style={{
                          backgroundColor: `rgba(${colorDecimal.red},${colorDecimal.green},${colorDecimal.blue}, 0.5)`,
                        }}
                      >
                        <div
                          className={`rounded h-8px`}
                          role='progressbar'
                          style={{width: `${percent}%`, backgroundColor: appColor}}
                        />
                      </div>
                    </div>
                    <div className='fs-6 fw-normal'>{percent}%</div>
                  </div>
                  <div className='fs-6 d-flex gap-2' style={{color: appColor}}>
                    <div>Dari</div>
                    <CountUpUi
                      props={{style: {color: appColor}}}
                      value={maxTotal || 0}
                      type={type === 'currency' ? 'currency' : 'default'}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </ReactLoading>
      </div>
      <div
        className={`d-flex flex-column rounded-top-0`}
        style={{
          backgroundColor: appBackgroundColor,
          padding: '1rem',
          paddingBottom: '1rem',
          maxHeight: 'max-content',
        }}
      >
        <div className={`${data.length > 1 ? 'row g-2' : 'd-flex justify-content-center '}`}>
          {data?.map((l, i) => {
            const iconColor = l.iconColor
              ? getColorPallete(l.iconColor, 0.8).backgroundColor
              : '#000'
            return (
              <div
                className={`${
                  l.className
                    ? l.className
                    : `${
                        (data?.length || 0) % 3 === 0
                          ? 'col-4'
                          : (data?.length || 0) % 2 === 0
                          ? 'col-6'
                          : 'col-12'
                      }`
                } fs-3 fw-bold d-flex flex-column align-items-center`}
                style={{color: appColor}}
                key={i}
              >
                {l.icon && (
                  <LinkUi slug={l.link}>
                    <ReactIcon
                      icon={l.icon}
                      props={{className: 'mb-2', style: {color: iconColor, fontSize: '20px'}}}
                    />
                  </LinkUi>
                )}
                <ReactLoading hideTitle loading={loading} {...reactLoadingConfig}>
                  <LinkUi slug={l.link}>
                    <CountUpUi
                      props={{style: {color: appColor}}}
                      value={l.total}
                      type={type === 'currency' ? 'currency' : 'default'}
                    />
                  </LinkUi>
                </ReactLoading>
                <LinkUi slug={l.link}>
                  <div className='fs-6' style={{color: appColor}}>
                    {l.title}
                  </div>
                </LinkUi>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export {ChartCircle, TotalDashboard, TotalDashboardSmall, dashboardDataBuilder}
