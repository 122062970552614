import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfStyle,
  PdfStylePallete,
} from '../../../../helper/ReactPdf'
import {formatDate} from '../../../../helper/DateFns'
import {AlphabetIndex, ConvertCurrency, NullProof} from '../../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  const TableData = () => {
    return (
      <>
        <View
          style={{
            ...PdfStylePallete.table.style,
            marginVertical: '10px',
            borderStyle: 'solid',
            fontFamily: 'Arial',
            fontSize: PdfStylePallete.typography.label.medium,
          }}
        >
          <View style={{flexDirection: 'row'}}>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '5%',
                textAlign: 'center',
                borderRightWidth: 0,
                paddingHorizontal: '5px',
              }}
            >
              No.
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '65%',
                textAlign: 'center',
                paddingHorizontal: '5px',
              }}
            >
              Uraian
            </Text>
            <Text
              style={{
                ...PdfStylePallete.table.col,
                borderStyle: 'solid',
                width: '30%',
                textAlign: 'left',
                paddingHorizontal: '5px',
              }}
            >
              Jumlah
            </Text>
          </View>
          {NullProof({input: data.data, params: 'list_pengeluaran', isMap: true})?.map(
            (l: any, i: any) => (
              <Fragment key={i}>
                <View style={{flexDirection: 'row'}}>
                  <Text
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '5%',
                      textAlign: 'center',
                      borderRightWidth: 0,
                      paddingHorizontal: '5px',
                    }}
                  >
                    {AlphabetIndex(i)}.
                  </Text>
                  <Text
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '65%',
                      textAlign: 'left',
                      paddingHorizontal: '5px',
                    }}
                  >
                    {l.uraian ? NullProof({input: l, params: 'uraian'}) : NullProof({input: l, params: 'Uraian'})}
                  </Text>
                  <Text
                    style={{
                      ...PdfStylePallete.table.col,
                      borderStyle: 'solid',
                      width: '30%',
                      textAlign: 'right',
                      paddingHorizontal: '5px',
                    }}
                  >
                    {ConvertCurrency(NullProof({input: l, params: 'jumlah', isLabel: false}) || 0)}
                  </Text>
                </View>
              </Fragment>
            )
          )}
        </View>
      </>
    )
  }

  return (
    <Document title='Dokumen'>
      <Page size={'FOLIO'} wrap={false} style={{...PdfStyle.page, fontFamily: 'Arial'}}>
        <PdfHeaderApp data={data} />
        <View
          style={{
            height: '2px',
            backgroundColor: '#000000',
          }}
        ></View>
        <View
          style={{
            marginHorizontal: 'auto',
            width: '90%',
            fontFamily: 'Arial',
            justifyContent: 'center',
            letterSpacing: '0.5px',
            fontSize: PdfStylePallete.typography.label.medium,
          }}
        >
          <Text
            style={{
              width: '100%',
              textAlign: 'center',
              marginVertical: '15px',
              fontSize: PdfStylePallete.typography.title.medium,
              fontWeight: 700,
              textTransform: 'uppercase',
              textDecoration: 'underline',
            }}
          >
            Daftar Pengeluaran Riil
          </Text>
          <View
            style={{
              marginVertical: '5px',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '15%',
                }}
              ></Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              ></Text>
              <Text
                style={{
                  width: '100%',
                }}
              >
                Yang bertanda tangan dibawah ini :
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '15%',
                }}
              >
                Nama
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <Text
                style={{
                  width: '100%',
                }}
              >
                {NullProof({input: data.data, params: 'pegawai.nama'})}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '15%',
                }}
              >
                NIP
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <Text
                style={{
                  width: '100%',
                }}
              >
                {NullProof({input: data.data, params: 'pegawai.nip'})}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '15%',
                }}
              >
                Jabatan
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <Text
                style={{
                  width: '100%',
                }}
              >
                {NullProof({input: data.data, params: 'pegawai.jabatan.fungsional'})}
              </Text>
            </View>
            <View
              style={{
                marginTop: '15px',
              }}
            >
              <Text>
                berdasarkan Surat Perjalanan Dinas (SPD) tanggal{' '}
                {NullProof({input: data.data, params: 'format_tanggal', label: 'Tanggal'})
                  || '(Tanggal)'}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '15%',
                }}
              >
                Nomor
              </Text>
              <Text
                style={{
                  paddingHorizontal: '10px',
                }}
              >
                :
              </Text>
              <Text
                style={{
                  width: '100%',
                }}
              >
                {NullProof({input: data.data, params: 'spd.nomor_surat', label: 'Nomor Surat'})},
                dengan ini kami menyatakan dengan sesungguhnya bahwa:
              </Text>
            </View>
          </View>
          <View style={{flexDirection: 'row', width: '100%'}}>
            <Text style={{paddingRight: '20px'}}>1.</Text>
            <Text>
              Biaya transport pegawai dan/atau biaya penginapan di bawah ini yang tidak dapat
              diperoleh bukti-bukti pengeluarannya, meliputi:
            </Text>
          </View>
          <TableData />
          <View style={{flexDirection: 'row', width: '100%'}}>
            <Text style={{paddingRight: '20px'}}>2.</Text>
            <Text>
              Jumlah uang tersebut pada angka 1 di atas benar-benar dikeluarkan untuk pelaksanaan
              perjalanan dinas dimaksud dan apabila di kemudian hari terdapat kelebihan atas
              pembayaran, kami bersedia untuk menyetorkan kelebihan tersebut ke Kas Negara.{'\n\n'}
              Demikian pernyataan ini kami buat dengan sebenarnya, untuk dipergunakan sebagaimana
              mestinya.
            </Text>
          </View>
          <View
            style={{
              marginTop: '30px',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '100pt',
            }}
          >
            <View
              style={{
                width: '45%',
              }}
            >
              <Text>Mengetahui/Menyetujui{'\n'}Pejabat Pembuat Komitmen</Text>
              <View
                style={{
                  ...PdfStylePallete.signature,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text>$</Text>
              </View>
              <View
                style={{
                  marginTop: '10px',
                }}
              >
                <Text>
                  {NullProof({input: data.data, params: 'pembuat_komitmen.nama', label: 'Nama'})}
                  {`\n`}
                </Text>
                <Text>
                  NIP:
                  {NullProof({input: data.data, params: 'pembuat_komitmen.nip', label: 'NIP'})}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: '45%',
              }}
            >
              <Text>
                {data.data?.lokasi},{'\n'}Pelaksana SPD,
              </Text>
              <View
                style={{
                  ...PdfStylePallete.signature,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text>*</Text>
              </View>
              <View
                style={{
                  marginTop: '10px',
                }}
              >
                <Text>
                  {NullProof({
                    input: data.data,
                    params: 'pegawai.nama',
                    label: 'Nama',
                  })}
                  {`\n`}
                </Text>
                <Text>
                  NIP:
                  {NullProof({
                    input: data.data,
                    params: 'pegawai.nip',
                    label: 'NIP',
                  })}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default PdfLayout
