import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'departemen',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Departemen wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nama Departemen',
          placeholder: 'Nama Departemen',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tanggal',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal',
          placeholder: 'Tanggal',
          type: 'date',
        },
      },
    },
  },
]

export {formInputList}
