import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfStyle,
  PdfStylePallete,
  PdfTableColumnBuilder,
  PdfTabCol,
} from '../../../../../helper/ReactPdf'
import {formatDate} from '../../../../../helper/DateFns'
import {AlphabetIndex, ConvertCurrency, NullProof} from '../../../../../helper/AppFunction'

const HeaderContent: FC = () => {
  return (
    <>
      <PdfTableColumnBuilder
        borderBottom
        style={{textTransform: 'uppercase', fontWeight: 'semibold'}}
      >
        <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            No.
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Pengusul / Pengguna Anggaran
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jenis Perjalanan Dinas
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            NO. Surat Tugas
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tanggal Surat Tugas
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Uraian Kegiatan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Provinsi
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jenis Satuan Kerja
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nama Satuan Kerja
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Wilayah (Wilayah Kerja)
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Sumber Penugasan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Topik/PKPT/Judul Lainnya
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Kategori
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah Anggaran Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '250px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            MAK
          </Text>
        </PdfTableColumnBuilder>
      </PdfTableColumnBuilder>
    </>
  )
}

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  console.log(data)
  let jumlahTotalDibayar = 0
  let jumlahTotalPengeluaran = 0
  let JumlahTotalSelisih = jumlahTotalDibayar - jumlahTotalPengeluaran
  const total = (data: any, param: string) => {
    const uang_muka = data ? data.reduce((a: any, b: any) => a + Number(b[param]), 0) || 0 : 0
    return Number(uang_muka)
  }
  const cekIsUangNull = (uang: any) => {
    return uang ? Number(uang) : 0
  }
  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{width: '100%'}}>
          <PdfTableColumnBuilder direction='column' border>
            {/* Header */}
            <HeaderContent />
            {/* Looping Here */}
            {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {i + 1}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'pengguna_anggaran.nama'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'jenis'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'nomor_surat'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'createdAt', type: 'date'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'deskripsi_kegiatan'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'pengguna_anggaran.provinsi'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'tujuan', isMap: true})?.map(
                        (item: any, index: number) => (
                          <Fragment key={index}>
                            {NullProof({input: item, params: 'jenis_tujuan'})}
                          </Fragment>
                        )
                      )}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'pengguna_anggaran.satuan_kerja'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'pengguna_anggaran.wilayah'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'usulan.sumber_penugasan'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'usulan.sumber_penugasan'}) === 'Form APP'
                        ? NullProof({input: l, params: 'usulan.form_app_detail'})
                        : NullProof({input: l, params: 'usulan.sumber_penugasan'}) === 'Lainnya'
                        ? NullProof({input: l, params: 'usulan.lainnya'})
                        : NullProof({input: l, params: 'usulan.pkpt'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'kategori.kategori'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'bukti_perjalanan', isMap: true}).map(
                        (item: any, i: number) => {
                          const uangTransportKantor = item.transportasi_data
                            ? item.transportasi_data.filter((l: any) => l.dibayar_oleh === 'Kantor')
                            : []
                          const totalDibayar =
                            total(item.transfer, 'uang_harian') +
                            total(item.transfer, 'hotel') +
                            total(item.transfer, 'uang_representatif') +
                            total(item.transfer, 'transport') +
                            total(uangTransportKantor, 'total') +
                            total(item.transfer, 'bst_kantor') +
                            total(item.transfer, 'bst_lokasi')
                          const totalPengeluraran =
                            cekIsUangNull(item.uang_harian_total) +
                            total(item.uang_penginapan_data, 'total') +
                            cekIsUangNull(item.uang_representatif_total) +
                            total(item.transportasi_data, 'total') +
                            cekIsUangNull(item.bst_kantor) +
                            cekIsUangNull(item.bst_lokasi) +
                            total(item.kwitansi_lain_lain_list, 'total')
                          jumlahTotalDibayar += totalDibayar
                          jumlahTotalPengeluaran += totalPengeluraran
                          JumlahTotalSelisih = jumlahTotalDibayar - jumlahTotalPengeluaran
                        }
                      )}
                      {ConvertCurrency(JumlahTotalSelisih)}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '250px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'mak'})}
                    </Text>
                  </PdfTableColumnBuilder>
                </PdfTableColumnBuilder>
              </Fragment>
            ))}
          </PdfTableColumnBuilder>
        </View>
      </Page>
    </Document>
  )
}

export default PdfLayout
