/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../../../../helper/AppFunction'
import {PdfPageViewer} from '../../../../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import ExcelLayout from './ExcelLayout'
import {CardUi} from '../../../../../../../helper/AppUi'
import {TableAction, TableInput, TableRow} from '../../../../../../../helper/TableInput'
import FormInput from '../../../../../../../helper/FormInput'
import {
  TableInputDetail,
  TableInputDetailColumn,
} from '../../../../../../../helper/TableInputDetail'

const MainWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td className='w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </td>
                  <td className='min-w-150px'>{NullProof({input: l, params: 'fullname'})}</td>
                  <td className='min-w-150px'>{NullProof({input: l, params: 'username'})}</td>
                  <td className='min-w-150px'>{NullProof({input: l, params: 'email'})}</td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={`${InfoData.api}/${query.id}`}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
        ExcelLayout={ExcelLayout}
      />
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Kode'}}>
              {NullProof({input: data, params: 'kode'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nomor KKA'}}>
              {NullProof({input: data, params: 'no_kka'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Ref PKA'}}>
              {NullProof({input: data, params: 'ref_pka'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Disusun Oleh'}}>
              {NullProof({input: data, params: 'disusun_oleh'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Paraf'}}>
              {NullProof({input: data, params: 'tanggal_paraf'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Direviu Oleh'}}>
              {NullProof({input: data, params: 'direviu_oleh'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal'}}>
              {NullProof({input: data, params: 'tanggal_review'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Disetujui Oleh'}}>
              {NullProof({input: data, params: 'disetujui_oleh'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Disetujui'}}>
              {NullProof({input: data, params: 'tanggal_disetujui'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Sasaran'}}>
              {NullProof({input: data, params: 'sasaran'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Periode'}}>
              {NullProof({input: data, params: 'periode'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Fungsi'}}>
              {NullProof({input: data, params: 'fungsi'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Sub Fungsi'}}>
              {NullProof({input: data, params: 'subfungsi'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Komponen'}}>
              {NullProof({input: data, params: 'komponen'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Bobot'}}>
              {NullProof({input: data, params: 'bobot'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Jumlah Bobot'}}>
              {NullProof({input: data, params: 'jumlah_bobot'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Hasil'}}>
              {NullProof({input: data, params: 'hasil'})}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper, PdfWrapper}
