/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, Fragment, useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {defaultAlerts, defaultLogs, KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import ReactIcon from '../../helper/ReactIcon'
import {NullProof} from '../../helper/AppFunction'
import {DateFNS} from '../../helper/DateFns'
import {getData, putData} from '../../helper/FormAxios'
import {XfiniteDataUi} from '../../helper/AppUi'
import {useSelector} from 'react-redux'

interface NotifikasiButtonProps {
  className?: {
    item?: string
    button?: string
  }
  onFeedback?: (e?: any) => void
}

export const NotifikasiUi: FC<{l: any; autoRead?: boolean}> = ({l, autoRead}) => {
  const navigate = useNavigate()
  const routeData = useSelector((state: any) => state.route.value)
  const [isRead, setIsRead] = useState<boolean>(
    NullProof({input: l, params: 'is_read', isLabel: false})
  )
  const id = NullProof({input: l, params: 'id', isLabel: false})
  const hasLink = NullProof({input: l, params: 'table_name', isLabel: false})
  useEffect(() => {
    if (autoRead) {
      setIsRead(true)
      putData({is_read: true}, `/account/notif/${id}`)
    }
  }, [])
  const onClick = () => {
    if (hasLink && routeData) {
      for (const ls of routeData?.route) {
        let route: string[] = ls.split('/')
        if (route[route.length - 1] === hasLink) {
          putData({is_read: true}, `/account/notif/${id}`)
          if (l?.table_id) {
            navigate(`./../${ls}/detail/${l?.table_id}`)
          }
        }
      }
    }
  }
  return (
    <div
      className={`${
        isRead ? 'bg-hover-light' : 'bg-primary bg-hover-opacity-10'
      } d-flex bg-opacity-5 rounded px-4 py-2 flex-stack py-4 cursor-pointer`}
      onClick={() => onClick()}
    >
      <div className='d-flex align-items-center cursor-pointer'>
        <div className='symbol symbol-35px me-4'>
          <span className={clsx('symbol-label')}>
            {' '}
            <ReactIcon icon='RiInformationLine' props={{className: 'fs-2'}} />
          </span>
        </div>

        <div className='mb-0 me-2 w-100'>
          <div className='fs-6 text-gray-800 fw-bolder'>
            {NullProof({input: l, params: 'title'})}
          </div>
          <div className='text-gray-400 fs-7'>
            {NullProof({input: l, params: 'desc'})?.replaceAll('undefined', '-')}
          </div>
        </div>
      </div>
      <div className='flex-grow-2 text-gray-400 fs-7 text-end mt-2 w-150px'>
        <DateFNS date={NullProof({input: l, params: 'createdAt'})} dateFormat='now' suffix />
      </div>
    </div>
  )
}

const NotifikasiButton: FC<NotifikasiButtonProps> = ({onFeedback, className}) => {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [data, setData] = useState<any>([])
  const [totalNotifikasi, setTotalNotifikasi] = useState<number>(0)

  return (
    <div className={clsx('app-navbar-item', className?.item)}>
      <div
        data-kt-menu-trigger="{default: 'click'}"
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        className={className?.button}
        onClick={() => {
          !refresh && setRefresh(true)
        }}
      >
        {totalNotifikasi > 0 && (
          <div className='position-absolute d-flex align-items-center justify-content-center top-0 end-0 w-20px h-20px bg-danger rounded-circle text-white'>
            {totalNotifikasi}
          </div>
        )}
        <ReactIcon icon='RiNotification3Line' props={{className: 'fs-2'}} />
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
        data-kt-menu='true'
      >
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top'
          style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
        >
          {/* <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
        Notifications <span className='fs-8 opacity-75 ps-3'>24 reports</span>
      </h3> */}
          <h3 className='text-white fw-bold px-9 mt-10 mb-6'>Notifications</h3>

          {/* <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
            <li className='nav-item'>
              <a
                className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_1'
              >
                Alerts
              </a>
            </li>
          </ul> */}
        </div>

        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
            <div id='popup-notifikasi-scroll' className='overflow-y-auto mh-325px my-5'>
              <XfiniteDataUi
                refresh={refresh}
                title='Notifikasi'
                scrollTargetId='popup-notifikasi-scroll'
                onSuccess={(e) => {
                  setRefresh(false)
                  if (e?.status) {
                    setData(e?.data)
                    let totalNotifikasiData: any =
                      Number(e?.totalItems) - Number(e?.count_unread) || 0
                    totalNotifikasiData = totalNotifikasiData > 99 ? 99 : totalNotifikasiData
                    setTotalNotifikasi(totalNotifikasiData)
                  }
                }}
                route={{query: 'limit=5&orderby=asc', api: '/account/notification'}}
              >
                <div className='d-flex flex-column gap-2'>
                  {data?.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <NotifikasiUi l={l} />
                    </Fragment>
                  ))}
                </div>
              </XfiniteDataUi>
            </div>

            <div className='py-3 text-center border-top'>
              <Link to='/notifikasi' className='btn btn-color-gray-600 btn-active-color-primary'>
                View All <KTIcon iconName='arrow-right' className='fs-5' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {NotifikasiButton}
