import {NullProof, capitalizeEachWord} from '../../../../../helper/AppFunction'
import {BorderStyle, CellStyle, Workbook, createWorkbook} from '../../../../../helper/ReactExcelJs'
import {PdfLayoutDataProps} from '../../../../../helper/ReactPdf'
import dataImage from '../../../../../helper/kemenagdata/image.json'

const WorksheetRinci = (wb: Workbook, data: PdfLayoutDataProps) => {
  // More Info: https://www.npmjs.com/package/exceljs
  // Init ExcelJS
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({
    title: 'Depan',
    options: {views: [{showGridLines: false}]},
  })
  // Init Template Theme
  const themeColor = {
    ungu: '60497A', // Format Color: HEX without #
    kuning: 'ffff00',
  }
  type themeStyleProps = {
    style: CellStyle
    border: BorderStyle
  }
  const themeStyle: themeStyleProps = {
    style: {
      backgroundColor: themeColor.ungu,
      color: themeColor.kuning,
      fontConfig: {
        wrap: true,
        alignment: {
          h: 'center',
        },
      },
    },
    border: {
      color: themeColor.kuning,
    },
  }

  // Init Column Width
  const columnWidths: any = {
    A: 15,
    B: 20,
    C: 20,
    D: 20,
    E: 20,
  }
  setupWidth(columnWidths)
  const styleCell = ({
    size = 10,
    isMerge = true,
    textCenter = false,
    isBold = false,
    backgroundColor = 'ffffff',
    isBorder = false,
  }: {
    size?: number
    isMerge?: boolean
    textCenter?: boolean
    isBold?: boolean
    backgroundColor?: string
    isBorder?: boolean
  }) => {
    const initialStyle: {style: CellStyle; options?: {isMerged: boolean}; border?: BorderStyle} = {
      style: {
        backgroundColor: backgroundColor,
        fontConfig: {size: size, name: 'Arial', style: !isBold ? 'normal' : 'bold'},
      },
    }
    if (textCenter)
      initialStyle.style.fontConfig = {
        alignment: {h: 'center', v: 'middle'},
        size: size,
        name: 'Arial',
        style: !isBold ? 'normal' : 'bold',
      }
    if (isMerge) initialStyle.options = {isMerged: true}
    if (isBorder)
      initialStyle.border = {
        color: '000000',
        style: 'thin',
        borderTop: true,
        borderBottom: true,
        borderLeft: true,
        borderRight: true,
      }
    return initialStyle
  }
  // const imageId = wb.workbook.addImage({
  //   filename: data.perusahaan.logo, // Replace with the path to your image file
  //   extension: 'png',
  // });

  // // Add the image to a cell
  // worksheet.addImage(imageId, {
  //   ext: {height: 50, width: 50},
  //   tl: {col: 2, row: 3},
  // })

  // Start Making ExcelJS
  const logoKma = wb.workbook.addImage({
    base64: dataImage.logo,
    extension: 'png',
  })
  worksheet.mergeCells('A1:E5')
  worksheet.addImage(logoKma, {
    tl: {col: 2.9, row: 0.5},
    ext: {height: 120, width: 120},
    editAs: '',
  })
  worksheet.getRow(1).height = 22
  worksheet.getRow(2).height = 22
  worksheet.getRow(3).height = 22
  worksheet.getRow(4).height = 22
  worksheet.getRow(5).height = 22
  cell({id: 'A6:E6', ...styleCell({size: 14, textCenter: true, isBold: true})}).value =
    'IMPLEMENTASI'
  cell({id: 'A7:E7', ...styleCell({size: 14, textCenter: true, isBold: true})}).value =
    'PAKTA INTEGRITAS KOMITMEN KERJA'
  cell({id: 'A8:E8', ...styleCell({size: 14, textCenter: true, isBold: true})}).value = 'TAHUN 2024'
  cell({id: 'A10:B10', ...styleCell}).value = 'Nama Satuan Kerja'
  cell({id: 'C10:E10', ...styleCell}).value = `: ${NullProof({
    input: data.data,
    params: 'satker.nama_satker',
  })}`
  cell({id: 'A11:B11', ...styleCell}).value = 'Penandatangan Komitmen'
  cell({id: 'C11:E11', ...styleCell}).value = `: ${capitalizeEachWord(
    NullProof({
      input: data.data,
      params: 'penandatangan_komitmen.nama',
    })?.toLowerCase()
  )}`
  cell({id: 'A12:B12', ...styleCell}).value = 'Jabatan'
  cell({id: 'C12:E12', ...styleCell}).value = `: ${NullProof({
    input: data.data,
    params: 'penandatangan_komitmen.jabatan.jabatan',
  })}`
  cell({id: 'A13:B13', ...styleCell}).value = 'Nomor Ponsel'
  cell({id: 'C13:E13', ...styleCell}).value = `: ${NullProof({
    input: data.data,
    params: 'penandatangan_komitmen.no_hp',
  })}`
  cell({id: 'A14:B14', ...styleCell}).value = 'Target Waktu Penyelesaian'
  cell({id: 'C14:E14', ...styleCell}).value = `: ${NullProof({
    input: data.data,
    params: 'target_waktu',
    type: 'date',
  })}`
  cell({id: 'A15:B15', ...styleCell}).value = 'Petugas Pemantau'
  cell({id: 'C15:E15', ...styleCell}).value = `: ${capitalizeEachWord(
    NullProof({
      input: data.data,
      params: 'petugas_pemantau.nama',
    })?.toLowerCase()
  )}`

  cell({id: 'A23:B23', ...styleCell}).value = 'PIC Teknis Satuan Kerja'
  cell({id: 'A24:B24', ...styleCell}).value = 'Nama'
  cell({id: 'C24:E24', ...styleCell}).value = `: ${capitalizeEachWord(
    NullProof({
      input: data.data,
      params: 'pic_satker.nama',
    })?.toLowerCase()
  )}`
  cell({id: 'A25:B25', ...styleCell}).value = 'Nomor Ponsel'
  cell({id: 'C25:E25', ...styleCell}).value = `: ${NullProof({
    input: data.data,
    params: 'pic_satker.no_hp',
  })}`

  cell({id: 'A27:B27', ...styleCell}).value = 'PIC Teknis Inspektoral Jenderal'
  cell({id: 'A28:B28', ...styleCell}).value = 'Nama'
  cell({id: 'C28:E28', ...styleCell}).value = `: ${capitalizeEachWord(
    NullProof({
      input: data.data,
      params: 'pic_irjen.nama',
    })?.toLowerCase()
  )}`
  cell({id: 'A29:B29', ...styleCell}).value = 'Nomor Ponsel'
  cell({id: 'C29:E29', ...styleCell}).value = `: ${NullProof({
    input: data.data,
    params: 'pic_irjen.no_hp',
  })}`

  worksheet.mergeCells('A27:B27')
  cell({id: 'A27'}).value = 'PIC Teknis Inspektoral Jenderal'
  worksheet.mergeCells('A28:B28')
  cell({id: 'A28'}).value = 'Nama'
  worksheet.mergeCells('C28:E28')
  cell({id: 'C28'}).value = `: ${capitalizeEachWord(
    NullProof({input: data.data, params: 'pic_irjen.nama'})?.toLowerCase()
  )}`
  worksheet.mergeCells('A29:B29')
  cell({id: 'A29'}).value = 'Nomor Ponsel'
  worksheet.mergeCells('C29:E29')
  cell({id: 'C29'}).value = `: ${NullProof({input: data.data, params: 'pic_irjen.no_hp'})}`
}

const WorksheetRinciLinkEvidence = (wb: Workbook, data: any) => {
  // More Info: https://www.npmjs.com/package/exceljs
  // Init ExcelJS
  const feb = data.data.link_evidence?.filter((item: any) => item.bulan === 'Februari')
  const mar = data.data.link_evidence?.filter((item: any) => item.bulan === 'Maret')
  const apr = data.data.link_evidence?.filter((item: any) => item.bulan === 'April')
  const mei = data.data.link_evidence?.filter((item: any) => item.bulan === 'Mei')
  const jun = data.data.link_evidence?.filter((item: any) => item.bulan === 'Juni')
  const jul = data.data.link_evidence?.filter((item: any) => item.bulan === 'Juli')
  const agu = data.data.link_evidence?.filter((item: any) => item.bulan === 'Agustus')
  const sep = data.data.link_evidence?.filter((item: any) => item.bulan === 'September')
  const okt = data.data.link_evidence?.filter((item: any) => item.bulan === 'Oktober')
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({
    title: 'Link',
    options: {views: [{showGridLines: false}]},
  })
  console.log(data)

  // Init Template Theme
  const themeColor = {
    ungu: '60497A', // Format Color: HEX without #
    kuning: 'ffff00',
  }
  type themeStyleProps = {
    style: CellStyle
    border: BorderStyle
  }
  const themeStyle: themeStyleProps = {
    style: {
      backgroundColor: themeColor.ungu,
      color: themeColor.kuning,
      fontConfig: {
        wrap: true,
        alignment: {
          h: 'center',
        },
      },
    },
    border: {
      color: themeColor.kuning,
    },
  }

  // Init Column Width
  const columnWidths: any = {
    A: 5,
    B: 15,
    C: 20,
    D: 30,
    E: 40,
  }
  setupWidth(columnWidths)
  const styleCell = ({
    size = 10,
    isMerge = false,
    textCenter = true,
    isBold = false,
    backgroundColor = 'ffffff',
    isBorder = true,
  }: {
    size?: number
    isMerge?: boolean
    textCenter?: boolean
    isBold?: boolean
    backgroundColor?: string
    isBorder?: boolean
  }) => {
    const initialStyle: {style: CellStyle; options?: {isMerged: boolean}; border?: BorderStyle} = {
      style: {
        backgroundColor: backgroundColor,
        fontConfig: {size: size, name: 'Arial', style: !isBold ? 'normal' : 'bold'},
      },
    }
    if (textCenter)
      initialStyle.style.fontConfig = {
        alignment: {h: 'center', v: 'middle'},
        size: size,
        name: 'Arial',
        style: !isBold ? 'normal' : 'bold',
      }
    if (isMerge) initialStyle.options = {isMerged: true}
    if (isBorder)
      initialStyle.border = {
        color: '000000',
        style: 'thin',
        borderTop: true,
        borderBottom: true,
        borderLeft: true,
        borderRight: true,
      }

    return initialStyle
  }
  // const imageId = wb.workbook.addImage({
  //   filename: data.perusahaan.logo, // Replace with the path to your image file
  //   extension: 'png',
  // });

  // // Add the image to a cell
  // worksheet.addImage(imageId, {
  //   ext: {height: 50, width: 50},
  //   tl: {col: 2, row: 3},
  // })

  // Start Making ExcelJS
  const logoKma = wb.workbook.addImage({
    base64: dataImage.logo,
    extension: 'png',
  })
  worksheet.mergeCells('A1:E5')
  worksheet.addImage(logoKma, {
    tl: {col: 2.9, row: 0.5},
    ext: {height: 120, width: 120},
    editAs: '',
  })
  worksheet.getRow(1).height = 22
  worksheet.getRow(2).height = 22
  worksheet.getRow(3).height = 22
  worksheet.getRow(4).height = 22
  worksheet.getRow(5).height = 22
  worksheet.mergeCells('A6:E6')
  cell({
    id: 'A1:C1',
    ...styleCell({isMerge: true, size: 14, textCenter: false, isBold: true, isBorder: false}),
  }).value = 'LINK EVIDENCE IMPLEMENTASI'
  cell({
    id: 'A2:D2',
    ...styleCell({isMerge: true, size: 14, textCenter: false, isBold: true, isBorder: false}),
  }).value = 'PAKTA INTEGRITAS KOMITMEN KERJA'
  cell({
    id: 'A3:C3',
    ...styleCell({isMerge: true, size: 14, textCenter: false, isBold: true, isBorder: false}),
  }).value = 'TAHUN 2024'
  cell({
    id: 'A5:B5',
    ...styleCell({isMerge: true, textCenter: false, size: 11, isBorder: false}),
  }).value = 'Nama Satuan Kerja'
  cell({
    id: 'C5:D5',
    ...styleCell({isMerge: true, textCenter: false, size: 11, isBorder: false}),
  }).value = `: ${NullProof({input: data.data, params: 'satker.nama_satker'})}`

  // worksheet.mergeCells('A7:A8');
  cell({
    id: 'A7:A8',
    ...styleCell({isMerge: true, size: 11, backgroundColor: 'ffff00', isBold: true}),
  }).value = 'No'
  cell({
    id: 'B7:C8',
    ...styleCell({isMerge: true, size: 11, backgroundColor: 'ffff00', isBold: true}),
  }).value = 'Bulan'
  cell({
    id: 'D7:D8',
    ...styleCell({isMerge: true, size: 11, backgroundColor: 'ffff00', isBold: true}),
  }).value = 'Link Evidence'

  let row = 9
  let no = 1
  cell({id: `A${row}`, ...styleCell({})}).value = no
  cell({id: `B${row}:C${row}`, ...styleCell({isMerge: true, textCenter: false})}).value = 'Februari'
  cell({id: `D${row}`, ...styleCell({textCenter: false})}).value = feb
    .map((item: any, index: number) => {
      return `${item.link_evidence}${index + 1 !== feb.length ? ', ' : ''}`
    })
    .join(' ')
  // feb.legth ? feb.map((item: any, index: number) => {
  //   cell({id: `D${row}`, ...styleCell({textCenter:false})}).value = `${item.link_evidence}${(index + 1) !== feb.length ? ', ' : ''}`;
  // }) : cell({id: `D${row}`, ...styleCell({})})
  row += 1
  no++
  cell({id: `A${row}`, ...styleCell({})}).value = no
  cell({id: `B${row}:C${row}`, ...styleCell({isMerge: true, textCenter: false})}).value = 'Maret'
  cell({id: `D${row}`, ...styleCell({textCenter: false})}).value = mar
    .map((item: any, index: number) => {
      return `${item.link_evidence}${index + 1 !== mar.length ? ', ' : ''}`
    })
    .join(' ')
  // mar.length ? mar.map((item: any, index:number) => {
  //   cell({id: `D${row}`, ...styleCell({textCenter:false})}).value = `${item.link_evidence}${(index + 1) !== mar.length ? ', ' : ''}`;
  // }) : cell({id: `D${row}`, ...styleCell({})})
  row += 1
  no++
  cell({id: `A${row}`, ...styleCell({})}).value = no
  cell({id: `B${row}:C${row}`, ...styleCell({isMerge: true, textCenter: false})}).value = 'April'
  cell({id: `D${row}`, ...styleCell({textCenter: false})}).value = apr
    .map((item: any, index: number) => {
      return `${item.link_evidence}${index + 1 !== apr.length ? ', ' : ''}`
    })
    .join(' ')
  // apr.length ? apr.map((item: any, index:number) => {
  //   cell({id: `D${row}`, ...styleCell({textCenter:false})}).value = `${item.link_evidence}${(index + 1) !== apr.length ? ', ' : ''}`;
  // }) : cell({id: `D${row}`, ...styleCell({})})
  row += 1
  no++
  cell({id: `A${row}`, ...styleCell({})}).value = no
  cell({id: `B${row}:C${row}`, ...styleCell({isMerge: true, textCenter: false})}).value = 'Mei'
  cell({id: `D${row}`, ...styleCell({textCenter: false})}).value = mei
    .map((item: any, index: number) => {
      return `${item.link_evidence}${index + 1 !== mei.length ? ', ' : ''}`
    })
    .join(' ')
  // mei.length ? mei.map((item: any, index:number) => {
  //   cell({id: `D${row}`, ...styleCell({textCenter:false})}).value = `${item.link_evidence}${(index + 1) !== mei.length ? ', ' : ''}`;
  // }) : cell({id: `D${row}`, ...styleCell({})})
  row += 1
  no++
  cell({id: `A${row}`, ...styleCell({})}).value = no
  cell({id: `B${row}:C${row}`, ...styleCell({isMerge: true, textCenter: false})}).value = 'Juni'
  cell({id: `D${row}`, ...styleCell({textCenter: false})}).value = jun
    .map((item: any, index: number) => {
      return `${item.link_evidence}${index + 1 !== jun.length ? ', ' : ''}`
    })
    .join(' ')
  // jun.length ? jun.map((item: any, index:number) => {
  //   cell({id: `D${row}`, ...styleCell({textCenter:false})}).value = `${item.link_evidence}${(index + 1) !== jun.length ? ', ' : ''}`;
  // }) : cell({id: `D${row}`, ...styleCell({})})
  row += 1
  no++
  cell({id: `A${row}`, ...styleCell({})}).value = no
  cell({id: `B${row}:C${row}`, ...styleCell({isMerge: true, textCenter: false})}).value = 'Juli'
  cell({id: `D${row}`, ...styleCell({textCenter: false})}).value = jul
    .map((item: any, index: number) => {
      return `${item.link_evidence}${index + 1 !== jul.length ? ', ' : ''}`
    })
    .join(' ')
  // jul.length ? jul.map((item: any, index:number) => {
  //   cell({id: `D${row}`, ...styleCell({textCenter:false})}).value = `${item.link_evidence}${(index + 1) !== jul.length ? ', ' : ''}`;
  // }) : cell({id: `D${row}`, ...styleCell({})})
  row += 1
  no++
  cell({id: `A${row}`, ...styleCell({})}).value = no
  cell({id: `B${row}:C${row}`, ...styleCell({isMerge: true, textCenter: false})}).value = 'Agustus'
  cell({id: `D${row}`, ...styleCell({textCenter: false})}).value = agu
    .map((item: any, index: number) => {
      return `${item.link_evidence}${index + 1 !== agu.length ? ', ' : ''}`
    })
    .join(' ')
  // agu.length ? agu.map((item: any, index:number) => {
  //   cell({id: `D${row}`, ...styleCell({textCenter:false})}).value = `${item.link_evidence}${(index + 1) !== agu.length ? ', ' : ''}`;
  // }) : cell({id: `D${row}`, ...styleCell({})})
  row += 1
  no++
  cell({id: `A${row}`, ...styleCell({})}).value = no
  cell({id: `B${row}:C${row}`, ...styleCell({isMerge: true, textCenter: false})}).value =
    'September'
  cell({id: `D${row}`, ...styleCell({textCenter: false})}).value = sep
    .map((item: any, index: number) => {
      return `${item.link_evidence}${index + 1 !== sep.length ? ', ' : ''}`
    })
    .join(' ')
  // sep.length ? sep.map((item: any, index:number) => {
  //   cell({id: `D${row}`, ...styleCell({textCenter:false})}).value = `${item.link_evidence}${(index + 1) !== sep.length ? ', ' : ''}`;
  // }) : cell({id: `D${row}`, ...styleCell({})})
  row += 1
  no++
  cell({id: `A${row}`, ...styleCell({})}).value = no
  cell({id: `B${row}:C${row}`, ...styleCell({isMerge: true, textCenter: false})}).value = 'Oktober'
  cell({id: `D${row}`, ...styleCell({textCenter: false})}).value = okt
    .map((item: any, index: number) => {
      return `${item.link_evidence}${index + 1 !== okt.length ? ', ' : ''}`
    })
    .join(' ')
  // okt.length ? okt.map((item: any, index:number) => {
  //   cell({id: `D${row}`, ...styleCell({textCenter:false})}).value = `${item.link_evidence}${(index + 1) !== okt.length ? ', ' : ''}`;
  // }) : cell({id: `D${row}`, ...styleCell({})})
}

const ExcelLayout = (data: PdfLayoutDataProps) => {
  const downloadExcel = async () => {
    const wb = createWorkbook()
    WorksheetRinci(wb, data)
    wb.download()
  }
  return downloadExcel
}
const ExcelLayoutLinkEvidence = (data: PdfLayoutDataProps) => {
  const downloadExcel = async () => {
    const wb = createWorkbook()
    WorksheetRinciLinkEvidence(wb, data)
    wb.download()
  }
  return downloadExcel
}

export {ExcelLayout, ExcelLayoutLinkEvidence}
