import {DataRoutesProps} from '../../../UserRolesValidation'
import {AlokasiAnggaranRoutes} from './AlokasiAnggaran'
import {KartuPenugasanRoutes} from './KartuPenugasan'

const PenyusunanRencanaProgja: DataRoutesProps = {
  title: 'Penyusunan Rencana PROGJA Tingkat Tim Audit',
  icon: 'element-10',
  route: 'penyusunan_rencana_progja',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Kartu Penugasan',
      icon: 'element-10',
      route: 'kartu_penugasan',
      elements: <></>,
      subroute: KartuPenugasanRoutes,
    },
    {
      isPage: true,
      title: 'Alokasi Anggaran',
      icon: 'element-10',
      route: 'alokasi_anggaran',
      elements: <></>,
      subroute: AlokasiAnggaranRoutes,
    },
    {
      isPage: true,
      title: 'Laporan Mingguan',
      icon: 'element-10',
      route: 'laporan_mingguan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Program Kerja Audit',
      icon: 'element-10',
      route: 'program_kerja_audit',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penyelesaian Penugasan Perencanaan',
      icon: 'element-10',
      route: 'penyelesaian_penugasan_perencanaan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Notulensi Kesepakatan',
      icon: 'element-10',
      route: 'notulensi_kesepakatan',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default PenyusunanRencanaProgja
