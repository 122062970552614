import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfFontBuilder,
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfSignatureBuilder,
  PdfStyle,
  PdfStylePallete,
  PdfTabCol,
} from '../../../helper/ReactPdf'
import {formatDate} from '../../../helper/DateFns'
import {AlphabetIndex, ConvertCurrency, NullProof, ReactSrc} from '../../../helper/AppFunction'

const API_URL = process.env.REACT_APP_API_URL

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  return (
    <Document title='Dokumen'>
      <Page size={{width: '1500'}} style={{...PdfStyle.page, fontFamily: 'Arial', padding: '20pt'}}>
        <View style={{fontWeight: 'bold', textAlign: 'center'}}>
          <Text
            style={{
              textTransform: 'uppercase',
              ...PdfFontBuilder({template: 'title', size: 'large'}),
            }}
          >
            Perencanaan Petugas Audit (Auditor)
          </Text>
          <Text
            style={{
              textTransform: 'uppercase',
              ...PdfFontBuilder({template: 'title', size: 'large'}),
            }}
          >
            {`Tahun ${NullProof({input: data.data, params: 'periode_start'})} s.d Tahun ${NullProof(
              {input: data.data, params: 'periode_end'}
            )}`}
          </Text>
        </View>
        <PdfTabCol direction='column'>
          {/* Header */}
          <PdfTabCol style={{view: {height: '120pt'}}}>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '5%'}}}>
              No
            </PdfTabCol>
            <PdfTabCol center noWrap type='text' border={{right: 1}} style={{view: {width: '20%'}}}>
              Nama Auditor
            </PdfTabCol>
            <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '75%'}}}>
              <PdfTabCol center type='text' border={{bottom: 1}} style={{view: {height: '100pt'}}}>
                Minggu / Auditi
              </PdfTabCol>
              <PdfTabCol center border={{bottom: 1}}>
                {Array.from({length: 26}, (_, index) => index + 1).map((ls, is) => (
                  <Fragment key={is}>
                    <PdfTabCol type='text' center noWrap border={is + 1 !== 26 ? {right: 1} : {}}>
                      {ls}
                    </PdfTabCol>
                  </Fragment>
                ))}
              </PdfTabCol>
              <PdfTabCol center border={{}}>
                {Array.from({length: 26}, (_, index) => index + 27).map((ls, is) => (
                  <Fragment key={is}>
                    <PdfTabCol type='text' center noWrap border={is + 1 !== 26 ? {right: 1} : {}}>
                      {ls}
                    </PdfTabCol>
                  </Fragment>
                ))}
              </PdfTabCol>
            </PdfTabCol>
          </PdfTabCol>
          {/* Data */}
          {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
            console.log(l)
            return (
              <Fragment key={i}>
                <PdfTabCol style={{view: {height: '80pt'}}}>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '5%'}}}
                  >
                    {i + 1}
                  </PdfTabCol>
                  <PdfTabCol
                    center
                    noWrap
                    type='text'
                    border={{right: 1}}
                    style={{view: {width: '20%'}}}
                  >
                    {NullProof({input: l, params: 'auditor.nama'})}
                  </PdfTabCol>
                  <PdfTabCol direction='column' border={{right: 1}} style={{view: {width: '75%'}}}>
                    <PdfTabCol center border={{bottom: 1}}>
                      {Array.from({length: 26}, (_, index) => index).map((ls, is) => (
                        <Fragment key={is}>
                          <PdfTabCol
                            type='text'
                            center
                            noWrap
                            border={is + 1 !== 26 ? {right: 1} : {}}
                          >
                            {NullProof({input: l, params: 'week', isMap: true})[ls]?.status
                              ? 'V'
                              : ''}
                          </PdfTabCol>
                        </Fragment>
                      ))}
                    </PdfTabCol>
                    <PdfTabCol center border={{}}>
                      {Array.from({length: 26}, (_, index) => index + 26).map((ls, is) => (
                        <Fragment key={is}>
                          <PdfTabCol
                            type='text'
                            center
                            noWrap
                            border={is + 1 !== 26 ? {right: 1} : {}}
                          >
                            {NullProof({input: l, params: 'week', isMap: true})[ls]?.status
                              ? 'V'
                              : ''}
                          </PdfTabCol>
                        </Fragment>
                      ))}
                    </PdfTabCol>
                  </PdfTabCol>
                </PdfTabCol>
              </Fragment>
            )
          })}
        </PdfTabCol>
        <PdfSignatureBuilder
          data={[
            {
              line1: `Inspektur...`,
              line2: '',
              signature:
                'https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Michael_Schumacher_Signature.svg/1024px-Michael_Schumacher_Signature.svg.png',
              name: NullProof({
                input: data.data,
                params: 'ketua_data.nama',
                label: 'Inspektur Jenderal',
              }),
              align: 'center',
            },
            {
              line1: `Pengendali Teknis`,
              line2: `.................`,
              signature:
                'https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Michael_Schumacher_Signature.svg/1024px-Michael_Schumacher_Signature.svg.png',
              name: NullProof({
                input: data.data,
                params: 'ketua_data.nama',
                label: 'Inspektur Jenderal',
              }),
              align: 'center',
            },
          ]}
          options={{
            align: 'flex-end',
            data: {align: 'flex-end'},
          }}
          style={{marginBottom: '100pt'}}
        />
      </Page>
    </Document>
  )
}

export default PdfLayout
