import * as XLSX from 'xlsx'

function xlsxToCsv(file: File) {
  return new Promise<Blob>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const data = event.target?.result
      if (data) {
        const workbook: XLSX.WorkBook = XLSX.read(data, {type: 'binary'})

        // Choose the first sheet
        const sheetName: string = workbook.SheetNames[0]
        const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName]

        // Convert sheet to CSV
        const csvData: string = XLSX.utils.sheet_to_csv(sheet)

        // Convert CSV string to Blob
        const csvBlob: Blob = new Blob([csvData], {type: 'text/csv'})

        resolve(csvBlob)
      } else {
        reject(new Error('Failed to read the file.'))
      }
    }

    reader.onerror = () => {
      reject(new Error('Error occurred while reading the file.'))
    }

    reader.readAsBinaryString(file)
  })
}

function csvToObject(csvBlob: Blob, options?: {delimiter?: string}): Promise<object[]> {
  const delimiter = options?.delimiter || ',' // Default delimiter is ',' if not provided in options

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(csvBlob)
    reader.onload = () => {
      const csvString = reader.result as string
      const rows = csvString.trim().split('\n') // Trim to remove empty lines

      const [header, ...dataRows] = rows
      const headers = header.split(delimiter)
      const data: object[] = dataRows.map((row) => {
        const values = row.split(delimiter)
        const obj: {[key: string]: string} = {}
        headers.forEach((header, index) => {
          obj[header.trim()] = values[index]?.trim() || '' // Trim values and handle missing values
        })
        return obj
      })

      resolve(data)
    }
    reader.onerror = () => {
      reject(new Error('Failed to read the CSV file.'))
    }
  })
}

const xlsxToObj = async (file: File) => {
  const csvBlob = await xlsxToCsv(file)
  const csvObj = await csvToObject(csvBlob)
  return csvObj
}

export {xlsxToCsv, csvToObject, xlsxToObj}
