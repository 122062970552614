import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'peta_audit_apip_id',
    type: 'datalist',
    validator: Yup.number().required('Peta Audit mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Peta Audit',
          placeholder: 'Peta Audit',
        },
      },
      datalist: {
        api: '/perencanaan_anggaran/peta-apip',
        params: 'nama,tahun',
        result: (
          <>
            <div>
              <b>Nama: </b>$nama
            </div>
            <div>
              <b>Tahun: </b>$tahun
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Nilai Skor',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'laporan_pengaduan',
    type: 'input',
    validator: Yup.number().required(
      'Laporan Hasil Pengawasan dan Pengaduan Masyarakat mohon untuk diisi.'
    ),
    options: {
      input: {
        props: {
          title: 'Laporan Hasil Pengawasan dan Pengaduan Masyarakat',
          placeholder: 'Laporan Hasil Pengawasan dan Pengaduan Masyarakat',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'skor_wilayah',
    type: 'input',
    validator: Yup.number().required(
      'Skor Rata-Rata Risiko Pada Inspektorat Wilayah mohon untuk diisi.'
    ),
    options: {
      input: {
        props: {
          title: 'Skor Rata-Rata Risiko Pada Inspektorat Wilayah',
          placeholder: 'Skor Rata-Rata Risiko Pada Inspektorat Wilayah',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8 d-flex flex-column justify-content-center text-end',
    name: '',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Total Skor',
        labelType: 'label',
        className: 'fs-6',
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'total',
    type: 'input',
    validator: Yup.number().required('Total Skor mohon untuk diisi.'),
    disabled: true,
    options: {
      input: {
        props: {
          placeholder: 'Total Skor',
          type: 'number',
        },
      },
    },
  },
]

export {formInputList}
