import { FC, Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'
import Modal from '../../../../../../helper/Modal'
import FormInput from '../../../../../../helper/FormInput'
import { formInputList } from '../InputLayout'
import { NullProof, RouterQueryParams } from '../../../../../../helper/AppFunction'
import PageData, { InfoData, TableInputDataDisposisi, TableInputDetailData } from '../PageData'
import { PageTitle } from '../../../../../../../_metronic/layout/core'
import { CardUi, ListdataUi, TabsUi, TimelineUi } from '../../../../../../helper/AppUi'
import ReactIcon from '../../../../../../helper/ReactIcon'
import { formatDate } from '../../../../../../helper/DateFns'
import { TableInputDetail, TableInputDetailColumn } from '../../../../../../helper/TableInputDetail'
import Accordion from '../../../../../../helper/Accordion'
import { ReactPdfViewer } from '../../../../../../helper/ReactPdf'
import {
	TableDaftarPenandatanganan,
	TableDaftarVerifikator, TableInputLampiran,
	TableInputNaskahDinas, TableInputPenandatanganan, TableInputStatusKirim, TableInputTujuan, TableInputVerifikator,
	TableRiwayatPerubahanMetadata,
} from '../TableWrapper'
import { putData } from '../../../../../../helper/FormAxios'
import Swal from 'sweetalert2'

const DetailWrapper: FC = () => {
	const query = useParams()
	const [data, setData] = useState<any>({})
	const [modal, setModal] = useState<any>({
		statusUbah: false,
	})
	const [key, setKey] = useState<number>(0)

	return (
		<>
			<Modal
				id='modal_statusubah'
				isShow={modal?.statusUbah}
				onClose={() => {
					setModal((p: any) => ({...p, statusUbah: false}))
				}}
				title='Ubah Status'
			>
				<FormInput
					input={formInputList}
					route={{
						url: RouterQueryParams(`${InfoData.api}/:id`, query),
						isFeedback: true,
						type: 'put',
						onFeedback: (e: any) => {
							if (e?.status) {
								setKey((p) => p + 1)
							}
							// console.log(e)
							setModal((p: any) => ({...p, statusUbah: false}))
						},
					}}
					isModal
				/>
			</Modal>

			<PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
				{PageData.detail?.page.breadcrumb.title}
			</PageTitle>

			<Fragment key={key}>
				<CardUi
					title={PageData.detail?.page.breadcrumb.title}
				>
					<TableInputDetail
						onSuccess={(val: any) => setData(val)}
						{...TableInputDetailData}
					>
						<div className='row'>
							<div className='col-12 col-md-4'>
								<TableInputDetailColumn
									hideDivider
									isResponsive='desktop'
									title={{value: 'Nama'}}
								>
									{NullProof({input: data, params: 'nama'})}
								</TableInputDetailColumn>
							</div>
							<div className='col-12 col-md-4'>
								<TableInputDetailColumn
									hideDivider
									isResponsive='desktop'
									title={{value: 'Terakhir Diubah'}}
								>
									{NullProof({input: data, params: 'updatedAt', type: 'date'})}
								</TableInputDetailColumn>
							</div>
							<div className='col-12 col-md-4'>
								<TableInputDetailColumn
									hideDivider
									isResponsive='desktop'
									title={{value: 'Tanggal Dibuat'}}
								>
									{NullProof({input: data, params: 'createdAt', type: 'date'})}
								</TableInputDetailColumn>
							</div>
						</div>
					</TableInputDetail>
					{data.file_naskah && <ReactPdfViewer url={`/${data.file_naskah}`} />}
				</CardUi>
			</Fragment>
		</>
	)
}

export default DetailWrapper