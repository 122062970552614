import {PageDataContent} from '../../../../../helper/Model'
import {TableInputDataProps} from '../../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../../helper/TableInputDetail'
import { filterFormInputList } from './InputLayout'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Wilayah 4 Surat Tugas',
  path: '/pengawasan/persiapan/export_surat_tugas/wilayah_4/wilayah_4_ST',
  api: '/pengawasan_internal/export-surat-tugas/wilayah-4',
}

const BaseBreadcrumb = [
  {
    title: 'Export Surat Tugas',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Wilayah 4',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Pengusul / Pengguna Anggara',
    },
    {
      title: 'Jenis Perjalanan Dinas',
    },
    {
      title: 'No. Surat Tugas',
    },
    {
      title: 'Satker Tujuan',
    },
  ],
  filter: filterFormInputList,
  link: {
    export: [
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
    ],
  },
  route: {
    url: InfoData.api,
    // query: 'byuser=true',
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/detail/:id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
