import {DataRoutesProps} from '../../UserRolesValidation'

const PengelolaanGratifikasi: DataRoutesProps = {
  title: 'Pengelolaan Gratifikasi',
  icon: 'element-10',
  route: 'pengelolaan_gratifikasi',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Perencanaan Pengendalian Gratifikasi',
      icon: 'element-10',
      route: 'perencanaan_pengendalian_gratifikasi',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Pelayanan Pelaporan Gratifikasi',
      icon: 'element-10',
      route: 'pelayanan_pelaporan_gratifikasi',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Sosialisasi dan Konsultasi Pengendalian Gratifikasi',
      icon: 'element-10',
      route: 'sosialisasi_konsultasi_pengendalian_gratifikasi',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penyusunan Laporan Pengendalian Gratifikasi',
      icon: 'element-10',
      route: 'penyusunan_laporan_pengendalian_gratifikasi',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default PengelolaanGratifikasi
