import {useDispatch, useSelector} from 'react-redux'
import {post as postRedux, clear as clearRedux} from '../../app/redux/tableSlice'

const useReduxGlobal = () => {
  const get = useSelector((state: any) => state.table.data)
  const reduxDispatch = useDispatch()

  const post = ({name, data}: {name: string; data: any}) => {
    reduxDispatch(
      postRedux({
        ...get,
        [name]: {...(get[name]?.data || {}), ...data},
      })
    )
  }
  const clear = (name?: any) => {
    if (name) {
      const newData = {...get}
      delete get[name]
      reduxDispatch(postRedux(newData))
    } else {
      reduxDispatch(clearRedux())
    }
  }
  return {
    get,
    post,
    clear,
  }
}

export default useReduxGlobal
