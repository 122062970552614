import {DataRoutesProps} from "../../../../UserRolesValidation";
import {
    MainWrapper,
    TambahWrapper,
    UbahWrapper,
    DetailWrapper,
    PdfWrapper,
} from "../../../../../pages/pengawasan-internal/pelaporan-pengawasan-internal/implementasi-pakta-integritas/form-target/MainWrapper";

const FormTarget: DataRoutesProps[] = [
    {
        icon: '',
        title: '',
        route: '*',
        elements: <MainWrapper />,
        subroute: []
    },
    {
        icon: '',
        title: '',
        route: 'tambah',
        elements: <TambahWrapper />,
        subroute: []
    },
    {
        icon: '',
        title: '',
        route: 'ubah/:id',
        elements: <UbahWrapper />,
        subroute: []
    },
    {
        icon: '',
        title: '',
        route: 'detail/:id',
        elements: <DetailWrapper />,
        subroute: []
    },
    {
        icon: '',
        title: '',
        route: 'pdf/:id',
        elements: <PdfWrapper />,
        subroute: [],
      },
]

export {FormTarget}
