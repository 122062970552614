import {FC, useEffect, useState} from 'react'
import {ChartCircle, TotalDashboard, TotalDashboardSmall} from '../../helper/DashboardComponent'
import ReactLeaflet from '../../helper/ReactLeaflet'
import {PageTitle} from '../../../_metronic/layout/core'
import {ProtectComponent, hasAccessRoutes} from '../../helper/UserRolesValidation'
import MixedCardDashboard from '../../modules/dashboard/components/MixedCardDashboard'
import {NullProof, updateData} from '../../helper/AppFunction'
import {formatDate} from '../../helper/DateFns'
import ReactIcon from '../../helper/ReactIcon'
import {useDispatch, useSelector} from 'react-redux'
import {useAuth} from '../../modules/auth'
import {post as postNotif} from '../../redux/notifSlice'
import store from '../../store'
import Modal from '../../helper/Modal'

const MainWrapper: FC = () => {
  const dispatch = useDispatch()
  const routeData = useSelector((state: any) => state.route.value)
  const notifData = useSelector((state: any) => state.notif.data)
  const [modalUltah, setModalUltah] = useState<boolean>(false)
  const {auth} = useAuth()
  const roleId = auth?.data.role_id
  const [data, setData] = useState<any>({
    pengawasan_internal: {},
  })
  const [leafletData, setLeafletData] = useState<any>({
    leaflet1: {},
    leaflet2: {},
    leaflet3: {},
  })
  const checkProtect = (search: string, value: string) => {
    return hasAccessRoutes(routeData?.route, search, roleId === 1, true) ? value : ''
  }

  useEffect(() => {
    if (notifData?.ultah) {
      setModalUltah(true)
      dispatch(postNotif({...notifData, ultah: false}))
    }
  }, [notifData?.ultah])

  return (
    <div>
      <Modal
        id='modal_ultah'
        title='Notifikasi'
        isShow={modalUltah}
        onClose={() => setModalUltah(false)}
        className='mw-500px'
      >
        <div className='d-flex flex-column gap-4 justify-content-center align-items-center'>
          <img src='./happy_birthday.gif' className='rounded-circle' style={{maxWidth: '200px'}} />
          <div
            className='d-flex flex-column align-items-center justify-content-center'
            style={{lineHeight: 1.2}}
          >
            <div className='fs-4'>Selamat Ulang Tahun</div>
            <div className='fw-bold fs-1 text-capitalize'>
              {NullProof({input: auth, params: 'data.pegawai.nama'})?.toLowerCase()}
            </div>
          </div>
          <button className='btn btn-primary' onClick={() => setModalUltah(false)}>
            Terima kasih!
          </button>
        </div>
      </Modal>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <div className='row g-4'>
        {/* Pengawasan Internal */}
        <ProtectComponent
          id={[
            'pengawasan_internal-jumlah_usulan',
            'pengawasan_internal-jumlah_st_terbit',
            'pengawasan_internal-jumlah_laporan_terbit',
            'pengawasan_internal-peta_penugasan_itjen',
            'pengawasan_internal-peta_profil_saldo_temuan',
            'pengawasan_internal-peta_posisi_realtime_pejabat',
          ].join()}
        >
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Pengawasan Internal</div>
            <div className='row g-4'>
              <ProtectComponent id='pengawasan_internal-jumlah_usulan'>
                <TotalDashboard
                  title='Usulan'
                  total={data?.pengawasan_internal?.total_usulan || 0}
                  link='/pengawasan/persiapan/form_usulan?filter=jenis-usulan'
                  props={{className: 'col-12 col-md-4'}}
                  themeConfig={{color: {value: 'warning'}}}
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-usulan',
                    // Contoh Query get=all
                    query: checkProtect(
                      'pengawasan/persiapan/form_usulan/$query=get=all',
                      'get=all'
                    ),
                  }}
                  onComplete={(e: any) => {
                    setData((p: any) => ({
                      ...p,
                      pengawasan_internal: {
                        ...p.pengawasan_internal,
                        total_usulan: NullProof({input: e, params: 'total', isLabel: false}) || 0,
                        usulan_persetujuan:
                          NullProof({input: e, params: 'persetujuan', isLabel: false}) || 0,
                        usulan_paraf: NullProof({input: e, params: 'paraf', isLabel: false}) || 0,
                        usulan_tanda_tangan:
                          NullProof({input: e, params: 'tanda_tangan', isLabel: false}) || 0,
                        usulan_ditolak:
                          NullProof({input: e, params: 'ditolak', isLabel: false}) || 0,
                        usulan_selesai:
                          NullProof({input: e, params: 'selesai', isLabel: false}) || 0,
                        usulan_revisi: NullProof({input: e, params: 'revisi', isLabel: false}) || 0,
                      },
                    }))
                  }}
                  data={[
                    {
                      title: 'Persetujuan',
                      total: data?.pengawasan_internal?.usulan_persetujuan || 0,
                      link: '/pengawasan/persiapan/daftar_status_usulan/persetujuan',
                    },
                    {
                      title: 'Paraf',
                      total: data?.pengawasan_internal?.usulan_paraf || 0,
                      link: '/pengawasan/persiapan/daftar_status_usulan/paraf',
                    },
                    {
                      title: 'Penandatanganan',
                      total: data?.pengawasan_internal?.usulan_tanda_tangan || 0,
                      link: '/pengawasan/persiapan/daftar_status_usulan/tanda_tangan',
                    },
                    {
                      title: 'Ditolak',
                      total: data?.pengawasan_internal?.usulan_ditolak || 0,
                      link: '/pengawasan/persiapan/daftar_status_usulan/ditolak',
                    },
                    {
                      title: 'Selesai',
                      total: data?.pengawasan_internal?.usulan_selesai || 0,
                      link: '/pengawasan/persiapan/daftar_status_usulan/selesai',
                    },
                    {
                      title: 'Revisi',
                      total: data?.pengawasan_internal?.usulan_revisi || 0,
                      link: '/pengawasan/persiapan/daftar_status_usulan/revisi',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-jumlah_st_terbit'>
                <TotalDashboard
                  title='Pelaksanaan Surat Tugas'
                  total={data?.pengawasan_internal?.total_surat_tugas || 0}
                  props={{className: 'col-12 col-md-4'}}
                  themeConfig={{color: {value: 'success'}}}
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-surat-tugas',
                  }}
                  onComplete={(e: any) => {
                    setData((p: any) => ({
                      ...p,
                      pengawasan_internal: {
                        ...p.pengawasan_internal,
                        total_surat_tugas:
                          NullProof({input: e, params: 'total', isLabel: false}) || 0,
                        surat_tugas_pending:
                          NullProof({input: e, params: 'pending', isLabel: false}) || 0,
                        surat_tugas_berjalan:
                          NullProof({input: e, params: 'berjalan', isLabel: false}) || 0,
                        surat_tugas_selesai:
                          NullProof({input: e, params: 'selesai', isLabel: false}) || 0,
                      },
                    }))
                  }}
                  data={[
                    {
                      title: 'Menunggu',
                      total: data?.pengawasan_internal?.surat_tugas_pending || 0,
                      link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      title: 'Berjalan',
                      total: data?.pengawasan_internal?.surat_tugas_berjalan || 0,
                      link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      title: 'Selesai',
                      total: data?.pengawasan_internal?.surat_tugas_selesai || 0,
                      link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-jumlah_laporan_terbit'>
                <div className='col-12 col-md-4'>
                  <TotalDashboard
                    title='Laporan yang terbit'
                    total={data?.pengawasan_internal?.laporan_surat_tugas || 0}
                    icon='RiFileList2Line'
                    themeConfig={{color: {value: 'primary'}}}
                    api={{
                      url: '/pengawasan_internal/dashboard-pelaporan-st',
                    }}
                    onComplete={(e: any) => {
                      setData((p: any) => ({
                        ...p,
                        pengawasan_internal: {
                          ...p.pengawasan_internal,
                          laporan_surat_tugas:
                            NullProof({input: e, params: 'surat_tugas', isLabel: false}) || 0,
                            laporan_belum:
                            NullProof({input: e, params: 'belum', isLabel: false}) || 0,
                            laporan_sudah:
                            NullProof({input: e, params: 'sudah', isLabel: false}) || 0,
                        },
                      }))
                    }}
                    data={[
                      {
                        title: 'Progress',
                        total: data?.pengawasan_internal?.laporan_belum || 0,
                        // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                      },
                      {
                        title: 'Selesai',
                        total: data?.pengawasan_internal?.laporan_sudah || 0,
                        // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                      },
                    ]}
                  />
                </div>
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-peta_penugasan_itjen'>
                <div className='col-12'>
                  <ReactLeaflet
                    title='Peta Penugasan Tim ITJEN'
                    typeMap='pin'
                    dataProps={{
                      api: '/pengawasan_internal/dashboard-peta-tim-audit',
                      id: 'id',
                      header: 'No. Surat Tugas,Kota,Satker',
                      params: 'surat_tugas.nomor,kota,satker.provinsi,satker.kota',
                      result: '$surat_tugas.nomor,$kota,$satker.provinsi - $satker.kota',
                    }}
                    id='leaflet1'
                    onComplete={(e: any) => {
                      setLeafletData((p: any) => ({...p, leaflet1: {...p.leaflet1, data: e}}))
                      // console.log(e)
                    }}
                    onOpenDetail={(e: any) => {
                      setLeafletData((p: any) => ({...p, leaflet1: {...p.leaflet1, id: e}}))
                    }}
                  >
                    {leafletData.leaflet1?.id && (
                      <div>
                        <div className='table-responsive'>
                          <table className='table'>
                            <thead>
                              <tr className='fw-bold fs-6 text-gray-800'>
                                <th className='min-w-50px'>No.</th>
                                <th className='min-w-200px'>No. Surat Tugas</th>
                                <th className='min-w-200px'>Tanggal Surat</th>
                                <th className='min-w-200px'>List Anggota</th>
                                <th className='min-w-200px'>Kota</th>
                                <th className='min-w-200px'>Satker</th>
                              </tr>
                            </thead>
                            <tbody>
                              {leafletData.leaflet1?.data?.filter(
                                (l: any) => l.id === leafletData.leaflet1?.id
                              ).length > 0 ? (
                                leafletData.leaflet1?.data
                                  ?.filter((l: any) => l.id === leafletData.leaflet1?.id)[0]
                                  ?.data.map((l: any, i: number) => (
                                    <tr key={i}>
                                      <td>{i + 1}.</td>
                                      <td>
                                        {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                                      </td>
                                      <td>
                                        {formatDate({
                                          date: NullProof({
                                            input: l,
                                            params: 'surat_tugas.tanggal',
                                          }),
                                          dateFormat: 'dd MMMM yyyy',
                                        })}
                                      </td>
                                      <td>
                                        {NullProof({
                                          input: l,
                                          params: 'surat_tugas.kepada',
                                          isMap: true,
                                        })?.map((l1: any, i1: number) => (
                                          <div>
                                            {`${i1 + 1}. ${NullProof({
                                              input: l1,
                                              params: 'pegawai.nama',
                                            })}`}
                                          </div>
                                        ))}
                                      </td>
                                      <td>{NullProof({input: l, params: 'kota'})}</td>
                                      <td>
                                        {`${NullProof({
                                          input: l,
                                          params: 'satker.singkatan_satker',
                                        })}`}{' '}
                                        -{' '}
                                        {`${NullProof({
                                          input: l,
                                          params: 'satker.nama_satker',
                                        })}`}
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr>
                                  <td>Data Kosong</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </ReactLeaflet>
                </div>
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-peta_profil_saldo_temuan'>
                <div className='col-12'>
                  <ReactLeaflet
                    title='Profil Saldo Temuan'
                    typeMap='area'
                    dataProps={{
                      api: '',
                      id: 'id',
                      header: 'Nama,Kota',
                      params: 'nama,kota',
                      result: '$nama,$kota',
                    }}
                    id='leaflet2'
                  />
                </div>
              </ProtectComponent>
              {/* <ProtectComponent id='dashboard_irjen-peta_posisi_realtime_pejabat'>
                <div className='col-12'>
                  <ReactLeaflet
                    title='Posisi Realtime Pejabat'
                    typeMap='pin'
                    dataProps={{
                      api: '',
                      id: 'id',
                      header: 'Nama,Kota',
                      params: 'nama,kota',
                      result: '$nama,$kota',
                    }}
                    id='leaflet3'
                  />
                </div>
              </ProtectComponent> */}
            </div>
          </div>
        </ProtectComponent>
        {/* Perencanaan Anggaran */}
        <ProtectComponent id={['perencanaan_anggaran-profil_anggaran'].join()}>
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Perencanaan Anggaran</div>
            <div className='row g-4'>
              <ProtectComponent id='perencanaan_anggaran-profil_anggaran'>
                <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'primary'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {
                      title: 'Saldo',
                      total: 50000,
                      link: '/pengawasan/persiapan/form_usulan?filter=type-saldo',
                    },
                    {
                      title: 'Realisasi',
                      total: 50000,
                      link: '/pengawasan/persiapan/form_usulan?filter=type-realisasi',
                    },
                  ]}
                />
                {/* <div className='col-12'>
                  <ChartCircle
                    props={{
                      className: 'col-6',
                    }}
                    id='profil_anggaran_itjen'
                    title='Profil Anggaran ITJEN'
                    color='info'
                    isCurrency
                    data={[
                      {color: '#2ecc71', title: 'Realisasi', value: 1000000},
                      {color: '#e74c3c', title: 'Saldo', value: 2000000},
                      {color: '#EFEFEF', title: 'Pagu', value: 3000000},
                    ]}
                    filter={{
                      data: [
                        {label: '2023', value: '2023'},
                        {label: '2022', value: '2022'},
                        {label: '2021', value: '2021'},
                      ],
                      value: '2023',
                      placeholder: 'Pilih Tahun',
                    }}
                  />
                </div> */}
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Penyusunan Peraturan */}
        <ProtectComponent id={['penyusunan_peraturan-daftar_legal_drafting_itjen'].join()}>
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Penyusunan Peraturan</div>
            <div className='row g-4'>
              <ProtectComponent id='penyusunan_peraturan-daftar_legal_drafting_itjen'>
                <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Legal Drafting yang di inisiasi ITJEN'
                  icon='RiFileTextLine'
                  themeConfig={{color: {value: 'danger'}}}
                  total={100}
                  data={[
                    {title: 'Mulai', total: 30},
                    {title: 'Progress', total: 20},
                    {title: 'Selesai', total: 50},
                  ]}
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Legal 1',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 2,
                        title: 'Legal 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 3,
                        title: 'Legal 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 4,
                        title: 'Legal 4',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 5,
                        title: 'Legal 5',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                    ],
                    slug: {
                      data: '/surat/:id',
                      id: 'id',
                    },
                    config: [{id: 'nama'}, {id: 'tanggal', className: 'fs-7'}],
                  }}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Pengelolaan Keuangan */}
        <ProtectComponent
          id={[
            'pengelolaan_keuangan-profil_realisasi_anggaran',
            'pengelolaan_keuangan-informasi_keuangan',
          ].join()}
        >
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Pengelolaan Keuangan</div>
            <div className='row g-4'>
              <ProtectComponent id='pengelolaan_keuangan-profil_realisasi_anggaran'>
                {/* <div className='col-12'>
                  <ChartCircle
                    id='profil_realisasi_anggaran'
                    title='Profil Realisasi Anggaran'
                    color='warning'
                    isCurrency
                    data={[
                      {color: '#2ecc71', title: 'Realisasi', value: 1000000},
                      {color: '#e74c3c', title: 'Saldo', value: 2000000},
                      {color: '#EFEFEF', title: 'Pagu', value: 3000000},
                    ]}
                    filter={{
                      data: [
                        {label: '2023', value: '2023'},
                        {label: '2022', value: '2022'},
                        {label: '2021', value: '2021'},
                      ],
                      value: '2023',
                      placeholder: 'Pilih Tahun',
                    }}
                  />
                </div> */}
                <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'primary'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                    {title: 'Total', total: 50000},
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_keuangan-profil_realisasi_anggaran'>
                {/* <div className='col-12'>
                  <ChartCircle
                    id='profil_realisasi_anggaran'
                    title='Profil Realisasi Anggaran'
                    color='warning'
                    isCurrency
                    data={[
                      {color: '#2ecc71', title: 'Realisasi', value: 1000000},
                      {color: '#e74c3c', title: 'Saldo', value: 2000000},
                      {color: '#EFEFEF', title: 'Pagu', value: 3000000},
                    ]}
                    filter={{
                      data: [
                        {label: '2023', value: '2023'},
                        {label: '2022', value: '2022'},
                        {label: '2021', value: '2021'},
                      ],
                      value: '2023',
                      placeholder: 'Pilih Tahun',
                    }}
                  />
                </div> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'warning'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                    {title: 'Total', total: 50000},
                  ]}
                /> */}
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_keuangan-informasi_keuangan'>
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'primary'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'warning'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                    {title: 'Total', total: 50000},
                  ]}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'success'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Uang Masuk 1',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 2,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 3,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 4,
                        title: 'Uang Masuk 4',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 5,
                        title: 'Uang Masuk 5',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                    ],
                    // slug: {
                    //   data: '/surat/:id',
                    //   id: 'id',
                    // },
                    config: [{id: 'nama'}, {id: 'tanggal', className: 'fs-7'}],
                  }}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'danger'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Uang Masuk 1',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 2,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 3,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 4,
                        title: 'Uang Masuk 4',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 5,
                        title: 'Uang Masuk 5',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                    ],
                    slug: {
                      data: '/surat/:id',
                      id: 'id',
                    },
                    config: [{id: 'nama'}, {id: 'tanggal', className: 'fs-7'}],
                  }}
                /> */}
              </ProtectComponent>

              <ProtectComponent id='pengelolaan_keuangan-informasi_keuangan'>
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'primary'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'warning'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                    {title: 'Total', total: 50000},
                  ]}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'danger'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Uang Masuk 1',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 2,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 3,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 4,
                        title: 'Uang Masuk 4',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 5,
                        title: 'Uang Masuk 5',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                    ],
                    // slug: {
                    //   data: '/surat/:id',
                    //   id: 'id',
                    // },
                    config: [{id: 'nama'}, {id: 'tanggal', className: 'fs-7'}],
                  }}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'danger'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Uang Masuk 1',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 2,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 3,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 4,
                        title: 'Uang Masuk 4',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 5,
                        title: 'Uang Masuk 5',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                    ],
                    slug: {
                      data: '/surat/:id',
                      id: 'id',
                    },
                    config: [{id: 'nama'}, {id: 'tanggal', className: 'fs-7'}],
                  }}
                /> */}
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Dashboard IRJEN */}
        {/* <ProtectComponent
          id={[
            'dashboard_irjen-aduan_masyarakat',
            'dashboard_irjen-ajuan_cuti',
            'dashboard_irjen-notifikasi',
            'dashboard_irjen-peta_posisi_tim_audit',
            'dashboard_irjen-peta_profil_saldo_temuan',
            'dashboard_irjen-peta_posisi_realtime_pejabat',
          ].join()}
        >
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Dashboard IRJEN</div>
            <div className='row g-4'>
              <ProtectComponent id='dashboard_irjen-aduan_masyarakat'>
                <MixedCardDashboard
                  title='Aduan Masyarakat'
                  className='col-12 col-md-4'
                  color='warning'
                  total={100}
                  icon='RiSpeakLine'
                />
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-ajuan_cuti'>
                <MixedCardDashboard
                  title='Ajuan Cuti'
                  className='col-12 col-md-4'
                  color='success'
                  total={100}
                  icon='RiSuitcaseLine'
                />
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-notifikasi'>
                <MixedCardDashboard
                  title='Notifikasi Approval LHA'
                  className='col-12 col-md-4'
                  total={10}
                  icon='RiNotification3Line'
                />
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-peta_posisi_tim_audit'>
                <div className='col-12'>
                  <ReactLeaflet
                    title='Posisi Tim Audit'
                    typeMap='pin'
                    dataProps={{
                      api: '/pengawasan_internal/surat-tugas',
                      id: 'id',
                      header: 'Nama,Kota',
                      params: 'nama,kota',
                      result: '$nama,$kota',
                    }}
                    id='leaflet1'
                  />
                </div>
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-peta_profil_saldo_temuan'>
                <div className='col-12'>
                  <ReactLeaflet
                    title='Profil Saldo Temuan'
                    typeMap='area'
                    dataProps={{
                      api: '/pengawasan_internal/surat-tugas',
                      id: 'id',
                      header: 'Nama,Kota',
                      params: 'nama,kota',
                      result: '$nama,$kota',
                    }}
                    id='leaflet2'
                  />
                </div>
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-peta_posisi_realtime_pejabat'>
                <div className='col-12'>
                  <ReactLeaflet
                    title='Posisi Realtime Pejabat'
                    typeMap='pin'
                    dataProps={{
                      api: '/pengawasan_internal/surat-tugas',
                      id: 'id',
                      header: 'Nama,Kota',
                      params: 'nama,kota',
                      result: '$nama,$kota',
                    }}
                    id='leaflet3'
                  />
                </div>
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent> */}
        {/* Pengelolaan Hasil Pengawasan */}
        <ProtectComponent
          id={[
            'pengelolaan_hasil_pengawasan-hasil_pengawasan_itjen',
            'pengelolaan_hasil_pengawasan-hasil_pemeriksaan_bpk',
            'pengelolaan_hasil_pengawasan-hasil_pengawasan_bpkp',
          ].join()}
        >
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Pengelolaan Hasil Pengawasan</div>
            <div className='row g-4'>
              <ProtectComponent id='pengelolaan_hasil_pengawasan-hasil_pengawasan_itjen'>
                <TotalDashboard
                  title='Hasil Pengawasan ITJEN'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'primary'},
                  }}
                  total={100}
                  icon='RiCheckLine'
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_hasil_pengawasan-hasil_pemeriksaan_bpk'>
                <TotalDashboard
                  title='Hasil Pemeriksaan BPK'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'success'},
                  }}
                  total={100}
                  icon='RiFileSearchLine'
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_hasil_pengawasan-hasil_pengawasan_bpkp'>
                <TotalDashboard
                  title='Hasil Pengawasan BPKP'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'danger'},
                  }}
                  total={100}
                  icon='RiCheckLine'
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Pengelolaan DUMAS */}
        <ProtectComponent
          id={['pengelolaan_dumas-profil_dumas', 'pengelolaan_dumas-dumas_proses'].join()}
        >
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Pengelolaan DUMAS</div>
            <div className='row g-4'>
              <ProtectComponent id='pengelolaan_dumas-profil_dumas'>
                <TotalDashboard
                  title='Profil DUMAS'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'warning'},
                  }}
                  total={100}
                  icon='RiUserSharedLine'
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_dumas-dumas_proses'>
                <TotalDashboard
                  title='DUMAS dalam proses telaah'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'primary'},
                  }}
                  total={100}
                  icon='RiFileListLine'
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Pengelolaan Gratifikasi */}
        <ProtectComponent id={['pengelolaan_gratifikasi-profil_ppg'].join()}>
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Pengelolaan Gratifikasi</div>
            <div className='row g-4'>
              <ProtectComponent id='pengelolaan_gratifikasi-profil_ppg'>
                <TotalDashboard
                  title='Profil PPG'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'success'},
                  }}
                  total={100}
                  icon='RiToolsLine'
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Pengelolaan Barang */}
        <ProtectComponent
          id={['pengelolaan_barang-profil_bmn', 'pengelolaan_barang-pengguna_bmn'].join()}
        >
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Pengelolaan Barang</div>
            <div className='row g-4'>
              <ProtectComponent id='pengelolaan_barang-profil_bmn'>
                <TotalDashboard
                  title='Profil BMN'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'primary'},
                  }}
                  total={100}
                  icon='RiBarcodeLine'
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_barang-pengguna_bmn'>
                <TotalDashboard
                  title='Pengguna BMN'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'info'},
                  }}
                  total={100}
                  icon='RiUserReceivedLine'
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Kepegawaian */}
        <ProtectComponent
          id={[
            'kepegawaian-profil_kepegawaian',
            'kepegawaian-pengajuan_cuti',
            'kepegawaian-pengajuan_izin',
            'kepegawaian-kediklatan',
          ].join()}
        >
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Kepegawaian</div>
            <div className='row g-4'>
              <ProtectComponent id='kepegawaian-profil_kepegawaian'>
                <TotalDashboard
                  title='Profil Kepegawaian'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'primary'},
                  }}
                  total={100}
                  icon='RiUserSettingsLine'
                />
              </ProtectComponent>
              <ProtectComponent id='kepegawaian-pengajuan_cuti'>
                <TotalDashboard
                  title='Proses Pengajuan Cuti'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'warning'},
                  }}
                  total={100}
                  icon='RiCalendarEventLine'
                />
              </ProtectComponent>
              <ProtectComponent id='kepegawaian-pengajuan_izin'>
                <TotalDashboard
                  title='Proses Pengajuan Izin / Tugas Belajar'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'success'},
                  }}
                  total={100}
                  icon='RiBookLine'
                />
              </ProtectComponent>
              <ProtectComponent id='kepegawaian-kediklatan'>
                <TotalDashboard
                  title='Informasi Kediklatan'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'danger'},
                  }}
                  total={100}
                  icon='RiBookOpenLine'
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Tata Usaha */}
        <ProtectComponent id={['tatausaha-surat_memo'].join()}>
          <div className='col-12 overflow-hidden'>
            <div className='fs-3 fw-bold mb-4'>Tata Usaha</div>
            <div className='d-flex flex-wrap border rounded-4 overflow-hidden relative'>
              <ProtectComponent id='tatausaha-surat_memo'>
                {/* <MixedCardDashboard
                  title='Total Surat / Memo'
                  className='col-12 col-md-4'
                  total={100}
                  icon='RiMailLine'
                  data={[
                    {title: 'Masuk', total: 50, className: 'col-6'},
                    {title: 'Keluar', total: 50, className: 'col-6'},
                  ]}
                /> */}
                <TotalDashboardSmall
                  title='Tandatangan Naskah'
                  props={{
                    className: 'col-12 col-md-6 text-center border',
                  }}
                  total={1}
                  isTotal={false}
                  icon='RiMailLine'
                  data={[
                    {
                      title: 'Naskah yang belum diberi Tanda Tangan',
                      total: 0,
                      className: 'col-4',
                      icon: 'RiPenNibFill',
                    },
                    {
                      title: 'Naskah yang belum Dikirim',
                      total: 1,
                      className: 'col-4',
                      icon: 'RiSendPlaneFill',
                      iconColor: 'success',
                    },
                    {
                      title: 'Naskah yang Ditolak',
                      total: 0,
                      className: 'col-4',
                      icon: 'RiFileForbidFill',
                      iconColor: 'danger',
                    },
                  ]}
                />
                <TotalDashboardSmall
                  title='Naskah Keluar'
                  props={{
                    className: 'col-12 col-md-6 text-center border',
                  }}
                  total={0}
                  isTotal={false}
                  icon='RiMailLine'
                  // isIcon={false}
                  data={[
                    {
                      title: 'Naskah yang belum Dikirim',
                      total: 0,
                      className: 'col-6',
                      icon: 'RiSendPlaneFill',
                      iconColor: 'success',
                    },
                    {
                      title: 'Naskah yang Ditolak',
                      total: 0,
                      className: 'col-6',
                      icon: 'RiFileForbidFill',
                      iconColor: 'danger',
                    },
                  ]}
                />
                <TotalDashboardSmall
                  title='Verifikasi Naskah'
                  props={{
                    className: 'col-12 col-md-6 text-center border',
                  }}
                  total={0}
                  isTotal={false}
                  icon='RiMailLine'
                  data={[
                    {
                      title: 'Naskah yang belum Diverifikasi',
                      total: 0,
                      className: 'col-6',
                      icon: 'RiFile3Fill',
                      iconColor: 'warning',
                    },
                    {
                      title: 'Naskah yang Ditolak',
                      total: 0,
                      className: 'col-6',
                      icon: 'RiFileForbidFill',
                      iconColor: 'danger',
                    },
                  ]}
                />
                <TotalDashboardSmall
                  title='Naskah Masuk'
                  props={{
                    className: 'col-12 col-md-6 text-center border',
                  }}
                  total={0}
                  isTotal={false}
                  icon='RiMailLine'
                  // isIcon={false}
                  data={[
                    {
                      title: 'Naskah yang belum Dibaca',
                      total: 0,
                      className: 'col-6',
                      icon: 'RiFileDownloadFill',
                      iconColor: 'primary',
                    },
                    {
                      title: 'Naskah yang belum Ditindaklanjuti',
                      total: 0,
                      className: 'col-6',
                      icon: 'RiArrowGoBackFill',
                      iconColor: 'info',
                    },
                  ]}
                />
                <TotalDashboardSmall
                  title='Naskah Disposisi'
                  props={{
                    className: 'col-12 col-md-6 text-center border',
                  }}
                  total={0}
                  isTotal={false}
                  icon='RiMailLine'
                  // isIcon={false}
                  data={[
                    {
                      title: 'Naskah yang belum Dibaca',
                      total: 0,
                      className: 'col-6',
                      icon: 'RiFileDownloadFill',
                      iconColor: 'primary',
                    },
                    {
                      title: 'Naskah yang belum Ditindaklanjuti',
                      total: 0,
                      className: 'col-6',
                      icon: 'RiArrowGoBackFill',
                      iconColor: 'info',
                    },
                  ]}
                />
                <TotalDashboardSmall
                  title='Naskah Tembusan'
                  props={{
                    className: 'col-12 col-md-6 text-center border',
                  }}
                  total={0}
                  isTotal={false}
                  color='success'
                  icon='RiMailLine'
                  data={[
                    {
                      title: 'Naskah yang belum Dibaca',
                      total: 0,
                      className: 'col-6',
                      icon: 'RiLogoutBoxRLine',
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>

        {/* Naskah Dinas */}
        <ProtectComponent id={['naskahdinas-surat_memo'].join()}>
          <div className='col-12 overflow-hidden'>
            <div className='fs-3 fw-bold mb-4'>Naskah Dinas</div>
            <div
              className='d-flex flex-wrap border rounded-4 overflow-hidden'
              style={{backgroundColor: '#fff'}}
            >
              <ProtectComponent id='tatausaha-surat_memo'>
                <TotalDashboardSmall
                  title='Naskah Keluar'
                  props={{
                    className: 'col-12 text-center border',
                  }}
                  total={1}
                  isTotal={false}
                  icon='RiMailLine'
                  data={[
                    {
                      title: 'Naskah yang belum Diverifikasi',
                      total: 0,
                      className: 'col-4',
                      icon: 'RiFile3Fill',
                      iconColor: 'danger',
                    },
                    {
                      title: 'Naskah yang belum diberi Tanda Tangan',
                      total: 0,
                      className: 'col-4',
                      icon: 'RiPenNibFill',
                    },
                    {
                      title: 'Naskah yang belum Dikirim',
                      total: 0,
                      className: 'col-4',
                      icon: 'RiSendPlaneFill',
                      iconColor: 'primary',
                    },
                  ]}
                />
                <div className='col-12 row' style={{backgroundColor: '#fff'}}>
                  <div
                    className='col-12 col-md-6 d-flex justify-content-between align-items-center px-10 py-5'
                    style={{backgroundColor: '#fff'}}
                  >
                    <div
                      className='d-flex flex-column align-items-start'
                      style={{fontWeight: 'bold'}}
                    >
                      <p className='fs-2 mb-2'>2</p>
                      <p>NASKAH KELUAR YANG BELUM DIBERIKAN</p>
                    </div>
                    <div className='col-4 text-end'>
                      <ReactIcon
                        icon={'RiSendPlaneFill'}
                        props={{className: 'mb-2', style: {fontSize: '40px'}}}
                      />
                    </div>
                  </div>
                  <div
                    className='col-12 col-md-6 d-flex justify-content-between align-items-center px-10 py-5'
                    style={{backgroundColor: '#fff'}}
                  >
                    <div
                      className='d-flex flex-column align-items-start'
                      style={{fontWeight: 'bold'}}
                    >
                      <p className='fs-2 mb-2'>2</p>
                      <p>NASKAH MASUK YANG BELUM DIBERIKAN</p>
                    </div>
                    <div className='col-4 text-end'>
                      <ReactIcon
                        icon={'RiSendPlaneFill'}
                        props={{className: 'mb-2', style: {fontSize: '40px'}}}
                      />
                    </div>
                  </div>
                </div>
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>

        {/* Naskah Log */}
        <ProtectComponent id={['naskahdinas-surat_memo'].join()}>
          <div className='col-12 overflow-hidden'>
            <div className='fs-3 fw-bold mb-4'>Log Naskah</div>
            <div className='d-flex flex-wrap border rounded-4 overflow-hidden relative'>
              <ProtectComponent id='tatausaha-surat_memo'>
                <TotalDashboardSmall
                  title='Naskah Keluar'
                  props={{
                    className: 'col-12 col-md-8 text-center border',
                  }}
                  total={1}
                  isTotal={false}
                  icon='RiMailLine'
                  data={[
                    {
                      title: 'Naskah yang belum Diverifikasi',
                      total: 0,
                      className: 'col-4',
                      icon: 'RiFile3Fill',
                      iconColor: 'danger',
                    },
                    {
                      title: 'Naskah yang belum diberi Tanda Tangan',
                      total: 0,
                      className: 'col-4',
                      icon: 'RiPenNibFill',
                    },
                    {
                      title: 'Naskah yang belum Dikirim',
                      total: 0,
                      className: 'col-4',
                      icon: 'RiSendPlaneFill',
                      iconColor: 'primary',
                    },
                  ]}
                />
                <TotalDashboardSmall
                  title='Naskah Keluar'
                  props={{
                    className: 'col-12 col-md-4 text-center border',
                  }}
                  total={0}
                  isTotal={false}
                  icon='RiMailLine'
                  data={[
                    {
                      title: 'Naskah yang belum Dikirim',
                      total: 0,
                      className: 'col-6',
                      icon: 'RiSendPlaneFill',
                      iconColor: 'success',
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>

        {/* Pengelolaan Kehumasan */}
        <ProtectComponent
          id={[
            'pengelolaan_kehumasan-link_website_itjen',
            'pengelolaan_kehumasan-informasi_kegiatan_itjen',
          ].join()}
        >
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Pengelolaan Kehumasan</div>
            <div className='row row-eq-height g-4'>
              <ProtectComponent id='pengelolaan_kehumasan-link_website_itjen'>
                <TotalDashboard
                  title='Link Website ITJEN'
                  total={20}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  themeConfig={{
                    color: {value: 'danger'},
                  }}
                  icon='RiExternalLinkLine'
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 2,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 3,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 4,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 5,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                    ],
                    slug: {
                      data: '#',
                      id: 'link',
                      external: true,
                      label: 'Lihat URL',
                    },
                    config: [{id: 'nama'}, {id: 'link', className: 'fs-7'}],
                  }}
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_kehumasan-informasi_kegiatan_itjen'>
                <TotalDashboard
                  title='Informasi Kegiatan ITJEN'
                  total={20}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  themeConfig={{
                    color: {value: 'primary'},
                  }}
                  icon='RiNewspaperLine'
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 2,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 3,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 4,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 5,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                    ],
                    slug: {
                      data: '#',
                      id: 'link',
                      external: true,
                    },
                    config: [{id: 'nama'}, {id: 'link', className: 'fs-7'}],
                  }}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Evaluasi Pelaporan */}
        <ProtectComponent id={['evaluasi_pelaporan-jumlah_laporan'].join()}>
          <div className='col-12'>
            <div className='fs-3 fw-bold mb-4'>Evaluasi Pelaporan</div>
            <div className='row g-4'>
              <ProtectComponent id='evaluasi_pelaporan-jumlah_laporan'>
                <TotalDashboard
                  title='Jumlah Laporan'
                  props={{
                    className: 'col-12 col-md-4 text-center',
                  }}
                  themeConfig={{
                    color: {value: 'primary'},
                  }}
                  total={100}
                  icon='RiFilePaperLine'
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
      </div>
    </div>
  )
}

export {MainWrapper}
