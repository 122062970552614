const i18nSidebarAllMessages = {
  "DASHBOARD": 'Dashboard',
  "ORGANIZATION": 'Organization',
  "SAMPLE": 'Sample',
  "SUB_SAMPLE": 'Sub Sample',
  "SUPER_SAMPLE": 'Super Sample',
  "SETTING": "Setting",
  "DATA_MENU": "Data Menu",
  "DATA_ROLES": "Data Roles",
  "DATA_USERS": "Data User",
  "LABEL.OPTION": "Option"
}

export default i18nSidebarAllMessages