import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        params: 'nomor_surat,nama_kegiatan',
        result: '$nomor_surat | $nama_kegiatan',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'kondisi',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Kondisi is required'),
    options: {
      textarea: {
        props: {
          title: 'Kondisi',
          rows: 3,
          placeholder: 'Kondisi',
        },
      },
    },
  },
  {
    className: 'col-6',
    name: 'kondisi_doc',
    type: 'input',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Kondisi (dokumen)',
          placeholder: 'Kondisi (dokumen)',
          type: 'file',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kriteria',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Kriteria is required'),
    options: {
      textarea: {
        props: {
          title: 'Kriteria',
          rows: 3,
          placeholder: 'Kriteria',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'sebab',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Sebab is required'),
    options: {
      textarea: {
        props: {
          title: 'Sebab',
          rows: 3,
          placeholder: 'Sebab',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'akibat',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Akibat is required'),
    options: {
      textarea: {
        props: {
          title: 'Akibat',
          rows: 3,
          placeholder: 'Akibat',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tanggapan_auditi',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Tanggapan Auditi is required'),
    options: {
      textarea: {
        props: {
          title: 'Tanggapan Auditi',
          rows: 3,
          placeholder: 'Tanggapan Auditi',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'rekomendasi',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Rekomendasi is required'),
    options: {
      textarea: {
        props: {
          title: 'Rekomendasi',
          rows: 3,
          placeholder: 'Rekomendasi',
        },
      },
    },
  },
]

export {formInputList}
