import {Form} from 'react-bootstrap'
import {DataRoutesProps} from '../../../../UserRolesValidation'
import {FormEvalusi} from './FormEvaluasi'
import {FormTarget} from './FormTarget'
import {FormImplementasi} from './FormImplementasi'
import {FormLinkEvidence} from './FormLinkEvidence'
const EvaluasiPaktaIntegritas: DataRoutesProps = {
  title: 'Evaluasi Pakta Integritas',
  icon: 'element-10',
  route: 'evaluasi_pakta_integritas',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Form Evaluasi',
      icon: 'element-10',
      route: 'form_evaluasi',
      elements: <></>,
      subroute: FormEvalusi,
    },
    {
      isPage: true,
      title: 'Form Target',
      icon: 'element-10',
      route: 'form_target',
      elements: <></>,
      subroute: FormTarget,
    },
    {
      isPage: true,
      title: 'Form Implementasi',
      icon: 'element-10',
      route: 'form_implementasi',
      elements: <></>,
      subroute: FormImplementasi,
    },
    {
      isPage: true,
      title: 'Form Link Evidence',
      icon: 'element-10',
      route: 'form_link_evidence',
      elements: <></>,
      subroute: FormLinkEvidence,
    },
  ],
}

export default EvaluasiPaktaIntegritas
