import {ProfilSuratTugasWrapper} from '../../../../pages/profil/tugas/surattugas/MainWrapper'
import {DataRoutesProps} from '../../../UserRolesValidation'

const MainRoutes: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <ProfilSuratTugasWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <></>,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <></>,
    subroute: [],
  },
]

export default MainRoutes
