import {FC, useEffect, useState} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import {getData} from './FormAxios'
import {RouterQueryParams} from './AppFunction'
import {useParams} from 'react-router-dom'
import {Divider} from './AppUi'
import Modal from './Modal'
import {ReactPdfViewer} from './ReactPdf'

const API_URL = process.env.REACT_APP_API_URL

interface TableInputDetailProps extends TableInputDetailDataProps {
  className?: string
  onSuccess: any
  onFeedback?: any
  isDummy?: boolean
}

export interface TableInputDetailDataProps {
  route: {
    url: string
    query?: string
  }
}

interface TableInputDetailColumnProps {
  title?: {
    value?: string
    props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  }
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  hideDivider?: boolean
  useGuide?: boolean
  useSub?: boolean
  isResponsive?: 'desktop' | 'mobile' | 'auto'
}

interface TableInputDetailFileProps {
  title?: string
  data: string
}

const GuideUi: FC<WithChildren & {enabled?: boolean}> = ({children, enabled}) => {
  return enabled ? (
    <div className='position-relative border-none border-1 border-start border-primary ms-1 ps-2'>
      {children}
    </div>
  ) : (
    <>{children}</>
  )
}

const TableInputDetailFile: FC<TableInputDetailFileProps> = ({data, title = 'Preview'}) => {
  const [error, setError] = useState<boolean>(false)
  const [modal, setModal] = useState<any>({
    status: false,
  })
  const checkUploadFileType = (url: string) => {
    let type: string = 'image'
    if (url?.indexOf('.pdf') > -1) {
      type = 'pdf'
    }
    return type
  }
  const toggleModal = (fieldsToUpdate: Partial<any>) => {
    console.log(data)
    const updatedData = Object.assign(modal, fieldsToUpdate)
    setModal((p: any) => ({...p, ...updatedData}))
  }
  return (
    <>
      <Modal
        id=''
        title={title}
        isShow={modal.status}
        onClose={() => {
          toggleModal({status: false})
        }}
      >
        {checkUploadFileType(data) === 'pdf' ? (
          <>
            <ReactPdfViewer url={`/${data}`} />
          </>
        ) : (
          <>
            {error ? (
              <>
                <div
                  className='p-4 d-flex gap-2 flex-column justify-content-center align-items-center'
                  style={{
                    minHeight: '200px',
                  }}
                >
                  <div className='text-center'>
                    <div className='fs-3 fw-bold'>Gagal memuat preview</div>
                    <div>Mohon cek koneksi anda</div>
                  </div>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary'
                    onClick={() => {
                      setError(false)
                    }}
                  >
                    Muat Ulang
                  </button>
                </div>
              </>
            ) : (
              <img
                className='ratio ratio-1x1'
                src={`${API_URL}/${data}`}
                alt={title}
                onError={() => {
                  setError(true)
                }}
              />
            )}
          </>
        )}
      </Modal>
      {data ? (
        <button
          type='button'
          className='btn btn-sm btn-primary'
          onClick={() => {
            toggleModal({status: true})
          }}
        >
          {`Lihat ${title}`}
        </button>
      ) : (
        <>-</>
      )}
    </>
  )
}

const TableInputDetailColumn: FC<WithChildren & TableInputDetailColumnProps> = ({
  children,
  title,
  props,
  hideDivider,
  useGuide,
  useSub,
  isResponsive = true,
}) => {
  return (
    <GuideUi enabled={useGuide}>
      <div className={`row g-2`} {...props}>
        {useGuide && (
          <div
            className='position-absolute rounded-circle bg-primary'
            style={{left: '-6px', top: '10px', width: '10px', height: '10px'}}
          ></div>
        )}
        {title?.value && (
          <div
            {...title.props}
            className={`${
              isResponsive === 'desktop'
                ? 'col-12 mb-0 mt-4'
                : isResponsive === 'mobile'
                ? 'col-5 col-md-4'
                : 'col-12 col-md-5 col-lg-4'
            } fw-bold ${title.props?.className || ''}`}
          >
            <div className='d-flex h-100 align-items-center'>{title?.value}</div>
          </div>
        )}
        <div
          className={`${
            isResponsive === 'desktop'
              ? 'col-12 mt-0'
              : isResponsive === 'mobile'
              ? 'col-7 col-md-8'
              : 'col-12 col-md-7 col-lg-8'
          }`}
          style={{
            paddingLeft: useSub ? '10px' : '',
          }}
        >
          <>{children}</>
        </div>
      </div>
      {!hideDivider && <Divider />}
    </GuideUi>
  )
}

const TableInputDetail: FC<WithChildren & TableInputDetailProps> = ({
  isDummy,
  children,
  route,
  className,
  onSuccess,
  onFeedback,
}) => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [refresh, setRefresh] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const loadData = async () => {
    if (route.url) {
      setLoading(true)
      setError(false)
      if (!isDummy) {
        const result: any = await getData(route.query || '', RouterQueryParams(route.url, query))
        if (result.status) {
          setData(result.data)
          onSuccess(result.data)
        } else {
          setError(true)
        }
      }
      setLoading(false)
    }
  }

  const onReload = () => {
    loadData()
    setRefresh(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  console.log()

  return (
    <div className='position-relative d-flex flex-column gap-2'>
      {!error ? (
        <>
          {loading && (
            <div
              className='position-absolute w-100 h-100 top-0 start-0 bg-white bg-opacity-50 d-flex justify-content-center align-items-center'
              style={{
                zIndex: 5,
              }}
            >
              <div>
                <div className='spinner-border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            </div>
          )}
          {children}
        </>
      ) : (
        <>
          <div
            className='position-relative w-100 h-100 top-0 left-0 bg-white bg-opacity-50 d-flex justify-content-center align-items-center'
            style={{
              zIndex: 5,
            }}
          >
            <div
              className='p-4 d-flex flex-column justify-content-center align-items-center'
              style={{
                minHeight: '200px',
              }}
            >
              <div className='fs-3 fw-bold'>Gagal menghubungkan ke server</div>
              <div>Mohon untuk coba lagi nanti</div>
              <button type='button' className='btn btn-sm btn-primary mt-2' onClick={onReload}>
                Muat Ulang
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export {TableInputDetail, TableInputDetailColumn, TableInputDetailFile}
