import {getListYear} from '../../../helper/DateFns'
import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Periode Audit 5 Tahun',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col',
    name: 'periode_start',
    type: 'select',
    listData: getListYear(),
    validator: Yup.number().required('Dari Periode mohon untuk diisi.'),
    options: {
      select: {
        props: {
          title: 'Dari Periode',
          placeholder: 'Dari Periode',
        },
      },
    },
  },
  {
    className: 'col-auto pt-8 mt-6 d-flex align-items-center justify-content-center',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 's/d',
        labelType: 'label',
      },
    },
  },
  {
    className: 'col',
    name: 'periode_end',
    type: 'input',
    validator: Yup.number().required('Sampai Periode mohon untuk diisi.'),
    disabled: true,
    options: {
      input: {
        props: {
          title: 'Sampai Periode',
          placeholder: 'Sampai Periode',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Periode Audit 5 Tahun',
        labelType: 'line',
      },
    },
  },
  {
    className: 'col-12',
    name: 'rencana_strategis_id',
    type: 'datalist',
    validator: Yup.number().required('Rencana Strategis mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Rencana Strategis',
          placeholder: 'Rencana Strategis',
        },
      },
      datalist: {
        api: '/perencanaan_anggaran/rencana-strategis',
        params: 'tujuan,sasaran',
        result: (
          <>
            <div>
              <b>Tujuan: </b>$tujuan
            </div>
            <div>
              <b>Sasaran: </b>$sasaran
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'satker_id',
    type: 'datalist',
    validator: Yup.number().required('Auditi mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Auditi',
          placeholder: 'Auditi',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker-unit',
        params: 'nama_satker',
        result: '$nama_satker',
        id: 'kode',
      },
    },
  },
  {
    className: 'col-12 col-md-3',
    name: 'tgl_lha_terakhir',
    type: 'input',
    validator: Yup.string().required('Tanggal LHA Terakhir mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal LHA Terakhir',
          placeholder: 'Tanggal LHA Terakhir',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-3',
    name: 'frekuensi_audit',
    type: 'input',
    validator: Yup.number().required('Frekuensi Audit mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Frekuensi Audit',
          placeholder: 'Frekuensi Audit',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'jenis_audit',
    type: 'input',
    validator: Yup.string().required('Jenis Audit mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jenis Audit',
          placeholder: 'Jenis Audit',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Tahun',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-auto',
    name: 'cek_1',
    type: 'input',
    validator: Yup.boolean().required('X1 mohon untuk diisi.'),
    options: {
      input: {
        props: {
          placeholder: 'X1',
          type: 'checkbox',
        },
      },
    },
  },
  {
    className: 'col-auto',
    name: 'cek_2',
    type: 'input',
    validator: Yup.boolean().required('X2 mohon untuk diisi.'),
    options: {
      input: {
        props: {
          placeholder: 'X2',
          type: 'checkbox',
        },
      },
    },
  },
  {
    className: 'col-auto',
    name: 'cek_3',
    type: 'input',
    validator: Yup.boolean().required('X3 mohon untuk diisi.'),
    options: {
      input: {
        props: {
          placeholder: 'X3',
          type: 'checkbox',
        },
      },
    },
  },
  {
    className: 'col-auto',
    name: 'cek_4',
    type: 'input',
    validator: Yup.boolean().required('X4 mohon untuk diisi.'),
    options: {
      input: {
        props: {
          placeholder: 'X4',
          type: 'checkbox',
        },
      },
    },
  },
  {
    className: 'col-auto',
    name: 'cek_5',
    type: 'input',
    validator: Yup.boolean().required('X5 mohon untuk diisi.'),
    options: {
      input: {
        props: {
          placeholder: 'X5',
          type: 'checkbox',
        },
      },
    },
  },
]

export {formInputList}
