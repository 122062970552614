import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../helper/template/FormInputTemplate'

const filterFormInputList: FormInputProps[] = [
  ...inputLayoutBuilder({
    input: [{template: 'search'}, {template: 'status'}],
  }),
]

const formInputList: FormInputProps[] = [
   {
    className: 'col-12',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'SETUJU',
        value: 'SETUJU',
      },
      {
        title: 'DITOLAK',
        value: 'DITOLAK',
      },
    ],
    validator: Yup.string().required('Status wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan',
    type: 'textarea',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Catatan wajib diisi bila status: DITOLAK',
      },
    },
  },
]

export {formInputList, filterFormInputList}
