/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, createElement, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {
  DefaultPdfLayoutData,
  DownloadPdf,
  PdfLayoutDataProps,
  PdfViewer,
  ReactPdfViewer,
} from './ReactPdf'
import {getData} from './FormAxios'
import {useNavigate, useParams} from 'react-router-dom'
import {DynamicUrl, NullProof, blobToBase64} from './AppFunction'
import ReactIcon from './ReactIcon'
import {useSelector} from 'react-redux'
import {BlobProvider} from '@react-pdf/renderer'
import {Document} from 'react-pdf'
import download from 'downloadjs'
import {CardUi} from './AppUi'

const API_URL = process.env.REACT_APP_THEME_API_URL

interface OptionProps {
  isRedux?: boolean
  hideBreadcrumb?: boolean
  hideBack?: boolean
  hideToolbar?: boolean
  filename?: {
    params?: string
    result: string
  }
}

type MainPageProps = {
  data?: any
  PageData: {
    title: string
    breadcrumb: PageLink[]
  }
  error: boolean
  loading: boolean
  justPreview?: boolean
  PdfLayout: any
  onRefresh?: any
  downloadExcel?: () => void
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  options?: OptionProps
}

const MainPage: FC<MainPageProps> = ({
  data,
  PageData,
  PdfLayout,
  loading,
  justPreview = false,
  downloadExcel,
  error,
  onRefresh,
  props,
  options,
}) => {
  const [pdfRedirect, setPdfRedirect] = useState<boolean>(false)
  const [pdfBlob, setPdfBlob] = useState<any>()
  const [pdfTte, setPdfTte] = useState<any>()
  const [linkPdfTte, setLinkPdfTte] = useState<string>()
  const [hasPdfTte, setHasPdfTte] = useState<boolean>(false)
  const navigator = useNavigate()
  const queryUrl = new URLSearchParams(window.location.search)
  const directDownload = queryUrl.get('download') ? true : false
  const mentahan64 = queryUrl.get('mentahan') ? true : false
  // `http://103.15.226.167:8383/v1/files/dokumen/undangan-eksternal.pdf`
  useEffect(() => {
    const renderBody = async () => {
      const base64 = await blobToBase64(pdfBlob)
      document.body.innerHTML = base64
    }
    if (pdfRedirect) {
      if (mentahan64) {
        renderBody()
      } else {
        const pdfUrl = URL.createObjectURL(pdfBlob)
        window.location.href = pdfUrl
      }
    }
  }, [pdfRedirect])
  useEffect(() => {
    const pdfUrl = NullProof({input: data.data, params: 'url_pdf_tte', isLabel: false})
      ? `/tte/signed${NullProof({input: data.data, params: 'url_pdf_tte', isLabel: false})}`
      : ''
    const loadPdf = async () => {
      setPdfTte(pdfUrl)
      setHasPdfTte(true)
    }
    pdfUrl.length > 0 && loadPdf()
  }, [data.data])
  return (
    <>
      {(directDownload || mentahan64) && !(loading || error) ? (
        <>
          <BlobProvider document={PdfLayout}>
            {({blob, url, loading, error}) => {
              // Do whatever you need with blob here
              if (blob) {
                setPdfBlob(blob)
                setPdfRedirect(true)
              }
              return <></>
            }}
          </BlobProvider>
        </>
      ) : (
        <>
          <CardUi
            title={PageData.title}
            props={props}
            toolbars={
              <>
                {!options?.hideToolbar && (
                  <div
                    className='d-flex'
                    style={{
                      gap: '5px',
                    }}
                  >
                    {!options?.hideBack && (
                      <button
                        className='btn btn-primary'
                        onClick={() => {
                          navigator(-1)
                        }}
                      >
                        <ReactIcon icon='RiArrowLeftLine' props={{className: 'me-2'}} />
                        Kembali
                      </button>
                    )}
                    {loading || error ? (
                      <button
                        type='button'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        data-kt-indicator={loading ? 'on' : 'off'}
                        disabled={loading || error}
                      >
                        <span className='indicator-label'>
                          {error ? 'Failed to load PDF' : 'Download'}
                        </span>
                        {loading && (
                          <span className='indicator-progress'>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    ) : (
                      <>
                        {hasPdfTte && justPreview === false ? (
                          <>
                            <button
                              type='button'
                              className='btn btn-primary'
                              data-kt-users-modal-action='submit'
                              data-kt-indicator={loading ? 'on' : 'off'}
                              disabled={loading}
                              onClick={async () => {
                                linkPdfTte &&
                                  download(linkPdfTte, 'download.pdf', 'application/pdf')
                              }}
                            >
                              <ReactIcon icon='RiDownloadLine' props={{className: 'me-2'}} />
                              <span className='indicator-label'>Download PDF (Sesudah)</span>
                              {loading && (
                                <span className='indicator-progress'>
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </>
                        ) : null}
                        <DownloadPdf
                          document={PdfLayout}
                          filename={`${data?._filename || 'document'}`}
                          label={`Download PDF${
                            hasPdfTte && justPreview === false ? ' (Sebelum)' : ''
                          }`}
                          props={{className: 'btn btn-primary'}}
                        />
                        {downloadExcel && (
                          <button onClick={downloadExcel} className='btn btn-primary'>
                            <ReactIcon icon='RiDownloadLine' props={{className: 'me-2'}} />
                            <span className='indicator-label'>Download Excel</span>
                          </button>
                        )}
                      </>
                    )}
                    <button
                      disabled={loading || error}
                      className='btn btn-icon btn-primary'
                      onClick={() => {
                        onRefresh && onRefresh()
                      }}
                    >
                      <ReactIcon icon='RiRefreshLine' props={{className: 'fs-2'}} />
                    </button>
                  </div>
                )}
              </>
            }
          >
            {!loading && (
              <>
                {error ? (
                  <div
                    className='d-flex flex-column justify-content-center align-items-center'
                    style={{
                      height: '200px',
                    }}
                  >
                    <div className='fs-4 fw-bold mb-2'>Gagal memuat PDF</div>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary'
                      data-kt-users-modal-action='submit'
                      data-kt-indicator={loading ? 'on' : 'off'}
                      disabled={loading}
                      onClick={() => window.location.reload()}
                    >
                      <span className='indicator-label'>Refresh Halaman</span>
                      {loading && (
                        <span className='indicator-progress'>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                ) : (
                  <>
                    {hasPdfTte && justPreview === false ? (
                      <>
                        <div className='fs-5 fw-bold mb-2'>Sesudah ditanda tangan:</div>
                        <ReactPdfViewer
                          url={pdfTte}
                          onFeedback={(e) => {
                            if (e?.status) {
                              setLinkPdfTte(e?.data)
                            }
                          }}
                        ></ReactPdfViewer>
                        <div className='fs-5 fw-bold mb-2'>Sebelum ditanda tangan:</div>
                      </>
                    ) : null}
                    <PdfViewer props={{showToolbar: false, height: '500px'}}>{PdfLayout}</PdfViewer>
                  </>
                )}
              </>
            )}
          </CardUi>
        </>
      )}
    </>
  )
}

type PdfPageViewerProps = {
  data?: any
  route?: string
  PageData: {
    title: string
    breadcrumb: PageLink[]
  }
  PdfLayout: any
  PdfData?: any
  justPreview?: boolean
  ExcelLayout?: any
  options?: OptionProps
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

const PdfPageViewer: FC<PdfPageViewerProps> = ({
  PdfLayout,
  PdfData,
  justPreview,
  PageData,
  ExcelLayout,
  route,
  options,
  props,
  data,
}) => {
  const reduxData = useSelector((state: any) => state.table.data)
  const [pdfKey, setPdfKey] = useState(0)
  const query = useParams()
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [_data, _setData] = useState<PdfLayoutDataProps>(PdfData ? PdfData : DefaultPdfLayoutData)

  const parseFilename = (data: any) => {
    let result: string = options?.filename?.result || 'document'
    if (options?.filename) {
      const arr: string[] = options?.filename?.params?.split(',') || []
      arr.map((l: string) => {
        result = result.replaceAll(`$${l}`, NullProof({input: data, params: l, isLabel: false}))
      })
    }
    result = result.replaceAll('/', '-')
    return result
  }

  const fetchData = async () => {
    setLoading(true)
    setError(false)
    try {
      let result: {status?: boolean; data?: any}
      if (data) {
        result = {status: true, data: data}
      } else if (options?.isRedux) {
        result = {status: true, data: reduxData?.checkbox?.data || []}
      } else {
        result = await getData('', DynamicUrl({input: route || '', query: query}) || '')
      }
      if (result?.status) {
        let json: PdfLayoutDataProps = {
          ...DefaultPdfLayoutData,
          data: result.data,
        }
        if (PdfData) {
          json = {
            ...PdfData,
            data: {
              ...PdfData.data,
              ...json.data,
            },
          }
        }
        _setData(json)
        setLoading(false)
      }
    } catch (_) {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (route) {
      fetchData()
    } else {
      setLoading(false)
    }
  }, [route])
  return (
    <>
      {!options?.hideBreadcrumb && (
        <PageTitle breadcrumbs={PageData.breadcrumb}>{PageData.title}</PageTitle>
      )}
      <Fragment key={pdfKey}>
        <MainPage
          props={props}
          data={{..._data, _filename: parseFilename(_data)}}
          error={error}
          loading={loading}
          PageData={PageData}
          PdfLayout={createElement(PdfLayout, {data: _data})}
          downloadExcel={ExcelLayout && ExcelLayout({..._data, _filename: parseFilename(_data)})}
          onRefresh={() => {
            setPdfKey((p) => p + 1)
            fetchData()
          }}
          options={options}
          {...{justPreview}}
        />
      </Fragment>
    </>
  )
}

export {PdfPageViewer}
