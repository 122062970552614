import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Surat Tugas wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        params: 'nomor_surat,nama_kegiatan',
        result: '$nomor_surat | $nama_kegiatan',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'spd_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('SPD wajib diisi'),
    options: {
      input: {
        props: {
          title: 'SPD',
          placeholder: 'SPD',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-perjalanan-dinas',
        params: 'nomor_surat',
        result: '$nomor_surat',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal',
          placeholder: 'Tanggal',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'mak',
    type: 'input',
    value: '',
    validator: Yup.string().required('MAK Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'MAK',
          placeholder: 'MAK',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'spm',
    type: 'input',
    value: '',
    validator: Yup.number().required('SPM Wajib diisi.').typeError('SPM Wajib angka.'),
    options: {
      input: {
        props: {
          title: 'SPM',
          placeholder: 'SPM',
          maxLength: 5,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'saldo',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Saldo',
          placeholder: 'Saldo',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'transfer',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Transfer',
          placeholder: 'Transfer',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'jumlah',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah',
          placeholder: 'Jumlah',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'bagian',
    type: 'input',
    value: '',
    validator: Yup.string().required('Bagian Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Bagian',
          placeholder: 'Bagian',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'keterangan',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Keterangan Wajib diisi.'),
    options: {
      textarea: {
        props: {
          title: 'Keterangan',
          placeholder: 'Keterangan',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'cek',
    type: 'input',
    value: '',
    validator: Yup.string().required('Cek Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Cek',
          placeholder: 'Cek',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'cek_billing',
    type: 'input',
    value: '',
    validator: Yup.string().required('Cek Billing Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Cek Billing',
          placeholder: 'Cek Billing',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_potong_um',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Potong UM',
          placeholder: 'Tanggal Potong UM',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_potong_verif',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Potong Verif',
          placeholder: 'Tanggal Potong Verif',
          type: 'date',
        },
      },
    },
  },
]

export {formInputList}
