import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfLayoutDataProps,
  PdfStyle,
  PdfTableColumnBuilder,
} from '../../../../helper/ReactPdf'
import {ConvertCurrency, NullProof} from '../../../../helper/AppFunction'
import {formatDate} from '../../../../helper/DateFns'

const HeaderContent: FC = () => {
  return (
    <>
      <PdfTableColumnBuilder
        borderBottom
        style={{textTransform: 'uppercase', fontWeight: 'semibold', height: '200px'}}
      >
        <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            No.
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '500px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Uraian
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            KEG
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            OUT
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SUB OUT
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            KOM
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            SUB KOM
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            AKUN
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder direction='column' center borderRight style={{width: '800px'}}>
          <PdfTableColumnBuilder center borderBottom style={{width: '800px'}}>
            <Text
              style={{
                padding: '10px 20px',
                textAlign: 'center',
              }}
            >
              SPM
            </Text>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder center>
            <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
              <Text
                style={{
                  padding: '10px 20px',
                  textAlign: 'center',
                }}
              >
                MAK
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
              <Text
                style={{
                  padding: '10px 20px',
                  textAlign: 'center',
                }}
              >
                Nilai
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
              <Text
                style={{
                  padding: '10px 20px',
                  textAlign: 'center',
                }}
              >
                SPM
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center style={{width: '200px'}}>
              <Text
                style={{
                  padding: '10px 20px',
                  textAlign: 'center',
                }}
              >
                Tanggal
              </Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Realisasi
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Selisih
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            {`Realisasi\nTransfer`}
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            {`Selisih\nTransfer`}
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Status
          </Text>
        </PdfTableColumnBuilder>
      </PdfTableColumnBuilder>
    </>
  )
}

const PdfLayoutSemua: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  console.log(data.data)

  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{width: '100%'}}>
          <PdfTableColumnBuilder direction='column' border>
            {/* Header */}
            <HeaderContent />
            {/* Looping Here */}
            {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {i + 1}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '500px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'uraian'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'keg'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'out'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'sub_out'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'kom'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'sub_kom'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'akun'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'spm_mak'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(NullProof({input: l, params: 'nilai', isLabel: false}) || 0)}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'spm'})}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal'}),
                        dateFormat: 'dd/MM/yyyy',
                      })}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(
                        NullProof({input: l, params: 'realisasi', isLabel: false}) || 0
                      )}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(
                        NullProof({input: l, params: 'selisih', isLabel: false}) || 0
                      )}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(
                        NullProof({input: l, params: 'realisasi_transfer', isLabel: false}) || 0
                      )}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {ConvertCurrency(
                        NullProof({input: l, params: 'selisih_trf', isLabel: false}) || 0
                      )}
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <Text
                      style={{
                        padding: '10px 20px',
                        textAlign: 'center',
                      }}
                    >
                      {NullProof({input: l, params: 'status', isLabel: false})
                        ? 'Sudah Dibayar'
                        : 'Belum Dibayar'}
                    </Text>
                  </PdfTableColumnBuilder>
                </PdfTableColumnBuilder>
              </Fragment>
            ))}
          </PdfTableColumnBuilder>
        </View>
      </Page>
    </Document>
  )
}

export {PdfLayoutSemua}
