/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../helper/AppFunction'
import FormInput from '../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import PdfLayout from './PdfLayout'
import {PdfPageViewer} from '../../../helper/PdfPageViewer'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Auditi' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'satker.nama_satker'})}
                  </TableColumn>
                  <TableColumn
                    label='Besaran Risiko Pengawasan'
                    props={{style: {minWidth: '200px'}}}
                  >
                    <b>Risiko Wilayah</b> : {NullProof({input: l, params: 'risiko_wilayah.total'})}{' '}
                    <br /> <b>Risiko Investigasi</b> :{' '}
                    {NullProof({input: l, params: 'risiko_investigasi.total'})}
                  </TableColumn>
                  <TableColumn
                    label='Tenaga Auditor Yang dimiliki'
                    props={{style: {minWidth: '200px'}}}
                  >
                    <b>Daltu</b> : {NullProof({input: l, params: 'daltu_data.nama'})} <br />{' '}
                    <b>Dalnis</b> :{NullProof({input: l, params: 'dalnis_data.nama'})} <br />{' '}
                    <b>K. Team</b> :{NullProof({input: l, params: 'ketua_data.nama'})} <br />{' '}
                    <b>A. Team</b> :
                    {NullProof({input: l, params: 'data_tenaga_data', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div>
                            {`${i1 + 1}. `}{' '}
                            {`${NullProof({
                              input: l1,
                              params: 'nama',
                            })}`}
                          </div>
                        </Fragment>
                      )
                    )}{' '}
                  </TableColumn>
                  <TableColumn label='Tenaga Tata Usaha Unit' props={{style: {minWidth: '200px'}}}>
                    <b>Gol IV</b> : {NullProof({input: l, params: 'gol_iv_data.nama'})} <br />{' '}
                    <b>Gol III</b> :{NullProof({input: l, params: 'gol_iii_data.nama'})} <br />{' '}
                    <b>Gol II</b> :{NullProof({input: l, params: 'gol_i_ii_data.nama'})}{' '}
                  </TableColumn>
                  <TableColumn
                    label='Sarana dan Prasarana Unit'
                    props={{style: {minWidth: '200px'}}}
                  >
                    <b>Komputer</b> : {NullProof({input: l, params: 'spu_komputer'})} <br />{' '}
                    <b>Kendaraan</b> :{NullProof({input: l, params: 'spu_kendaraan'})} <br />{' '}
                    <b>Lainnya</b> :{NullProof({input: l, params: 'spu_lainnya'})}{' '}
                  </TableColumn>
                  <TableColumn label='Lain - Lain' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'lain_lain'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Nama'}}>
            {NullProof({input: data, params: 'nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tahun'}}>
            {NullProof({input: data, params: 'tahun'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Data Unit'}}>
            <TableInputDetailColumn hideDivider title={{value: 'SPPD'}}>
              {NullProof({input: data, params: 'du_sppd'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Lainnya'}}>
              {NullProof({input: data, params: 'du_lainnya'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Sarana dan Prasarana Unit'}}>
            <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Kendaraan'}}>
              {NullProof({input: data, params: 'spu_kendaraan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Komputer'}}>
              {NullProof({input: data, params: 'spu_komputer'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Lainnya'}}>
              {NullProof({input: data, params: 'spu_lainnya'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tenaga Tata Usaha Audit'}}>
            <TableInputDetailColumn hideDivider title={{value: 'Gol. I/II'}}>
              {NullProof({input: data, params: 'gol_i_ii_data.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Gol. III'}}>
              {NullProof({input: data, params: 'gol_iii_data.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Gol. IV'}}>
              {NullProof({input: data, params: 'gol_iv_data.nama'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Besaran Resiko Audit'}}>
            {NullProof({input: data, params: 'besaran_resiko_audit'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Lain - Lain'}}>
            {NullProof({input: data, params: 'lain_lain'})}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper, PdfWrapper}
