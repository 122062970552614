import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../../helper/AppFunction'
import {
  TableAction,
  TableInput,
  TableInputDataProps,
  TableRow,
} from '../../../../../helper/TableInput'
import Modal from '../../../../../helper/Modal'
import {
  TableInputDetail,
  TableInputDetailColumn,
  TableInputDetailDataProps,
} from '../../../../../helper/TableInputDetail'

const dummyDataDaftarPenandatanganan = [
  {
    pengguna:
      'Nurul Badruttamam - Plt. Kepala Bagian Pengelolaan Hasil Pengawasan, Sistem Informasi dan Pengaduan Masyarakat',
    instansi: 'Sekretariat Inspektorat Jenderal Kementerian Agama - Kementerian Agama',
    catatan: 'ancgdere',
    status: 'DITOLAK',
  },
]

const dummyDataRiwayatPerubahanMetadata = [
  {
    id: 1,
    peristiwa: 'PEMBARUAN',
    dilakukan_pada: '2024-01-18T11:02:59Z',
  },
  {
    id: 2,
    peristiwa: 'PEMBARUAN',
    dilakukan_pada: '2024-01-18T11:02:59Z',
  },
]

const dummyDataHistoryNaskah = [
  {
    tanggal: '2024-01-18T11:02:59Z',
    pengirim: 'Kastolan',
    pegawai: {
      nama: 'Kastolan - Plt. Sekretaris Inspektorat Jenderal',
      jabatan: 'Sekretariat Inspektorat Jenderal Kementerian Agama - Kementerian Agama',
      foto: 'files/avatar/avatar_12122023204007_nemeka.jpg',
    },
    jenis_naskah: 'Nota Dinas',
    hal: 'coba tanda tangan konvensional',
    penerima: {
      utama: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
      tebusan: [
        {
          status_baca: 'Belum dibaca',
          pegawai: {
            nama: 'Miftahul Huda',
            jabatan: 'Auditor Muda Inspektorat Wilayah III',
          },
        },
      ],
    },
  },
]

const RouteList = {
  daftar_verifikator: '/tata_usaha/verifikator',
  daftar_penandatanganan: '/tata_usaha/penandatanganan',
  histori_naskah: '/tata_usaha/pengelolaan_naskah_dinas/naskah_masuk',
  naskah_dinas: '/tata_usaha/naskah_keluar',
  lampiran: '/tata_usaha/lampiran',
  tujuan: '/tata_usaha/tujuan',
  verifikator: '/tata_usaha/verifikator',
  penandatanganan: '/tata_usaha/penandatanganan',
  status_kirim: '/tata_usaha/naskah_keluar',
}

export const TableDataDaftarVerifikator: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Pengguna',
    },
    {
      title: 'Instansi / Inut Kerja',
    },
    {
      title: 'Catatan',
    },
    {
      title: 'Status',
    },
  ],
  link: {
    export: [],
    action: [],
  },
  route: {
    url: RouteList.verifikator,
    // query: 'byuser=true',
  },
}

export const TableInputDataDaftarPenandatanganan: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Pengguna',
    },
    {
      title: 'Instansi / Inut Kerja',
    },
    {
      title: 'Catatan',
    },
    {
      title: 'Status',
    },
  ],
  link: {
    export: [],
    action: [
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
    ],
  },
  route: {
    url: RouteList.penandatanganan,
    // query: 'byuser=true',
  },
}

const TableInputDaftarRiwayatPerubahanData = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Peristiwa',
    },
    {
      title: 'Dilakukan Pada',
    },
  ],
}

const TableInputNaskahDinas: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.naskah_dinas,
    // query: 'byuser=true',
  },
}

const TableInputLampiran: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.lampiran,
    // query: 'byuser=true',
  },
}

const TableInputTujuan: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.tujuan,
    // query: 'byuser=true',
  },
}

const TableInputVerifikator: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.verifikator,
    // query: 'byuser=true',
  },
}

const TableInputPenandatanganan: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.penandatanganan,
    // query: 'byuser=true',
  },
}

const TableInputStatusKirim: TableInputDataProps = {
  headerData: TableInputDaftarRiwayatPerubahanData.headerData,
  link: {
    export: [],
    action: [
      {
        id: 'lihat',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
        icon: 'RiEyeLine',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: RouteList.status_kirim,
    // query: 'byuser=true',
  },
}

export const TableInputDataHistoryNaskah: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Tanggal',
    },
    {
      title: 'Pengirim',
    },
    {
      title: 'Asal',
    },
    {
      title: 'Tujuan',
    },
    {
      title: 'Jenis Naskah',
    },
    {
      title: 'Hal',
    },
    {
      title: 'File',
    },
    {
      title: 'Lampiran',
    },
  ],
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: RouteList.histori_naskah,
    // query: 'byuser=true',
  },
}

const TableDaftarVerifikator: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableDataDaftarVerifikator

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      isDummy
      staticData={dummyDataDaftarPenandatanganan}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pengguna'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'instansi'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'catatan'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'status'})}</td>
              <TableAction {...tableDataBuilder} />
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const TablePenandaTanganan: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataDaftarPenandatanganan

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      isDummy
      staticData={dummyDataDaftarPenandatanganan}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pengguna'})}</td>
              <td className='min-w-250px'>{NullProof({input: l, params: 'instansi'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'catatan'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'status'})}</td>
              <TableAction {...tableDataBuilder} />
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const ComponentModalHistory: FC<{
  route: string
  id: number
  isShow: boolean
  setModal: any
}> = ({route, id, isShow, setModal}) => {
  const [dataHistory, setDataHistoryl] = useState<any>([])

  return (
    <Modal
      id='modal_detailhistory'
      isShow={isShow}
      onClose={() => {
        setModal((p: any) => ({...p, statusUbah: false}))
      }}
      title='Detail History'
    >
      <TableInputDetail
        onSuccess={(val: any) => setDataHistoryl(val)}
        route={{
          url: `${route}/${id}`,
        }}
      >
        <div className='row g-0'>
          <div className='row'>
            <TableInputDetailColumn
              props={{className: 'col-12 col-md-4 gy-1'}}
              hideDivider
              title={{value: 'Atribut'}}
            >
              {NullProof({input: dataHistory, params: 'naskah', type: 'date'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              props={{className: 'col-12 col-md-4 gy-1'}}
              hideDivider
              title={{value: 'Isian Lama'}}
            >
              {NullProof({input: dataHistory, params: 'naskah'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              props={{className: 'col-12 col-md-4 gy-1'}}
              hideDivider
              title={{value: 'Isian Baru'}}
            >
              {NullProof({input: dataHistory, params: 'naskah'})}
            </TableInputDetailColumn>
          </div>
        </div>
      </TableInputDetail>
    </Modal>
  )
}

const TableRiwayatPerubahanMetadata: FC<{dataInput: any; title: string}> = ({dataInput, title}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [detailId, setDetailId] = useState<any>(0)
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = dataInput

  return (
    <>
      <ComponentModalHistory
        route={TableInputData.route.url}
        id={detailId}
        isShow={modal.statusUbah}
        setModal={setModal}
      />
      <div className='fs-4 fw-bold mb-4'>{title}</div>
      <TableInput
        modal={modalConfig}
        onSuccess={(val: any) => setData(val)}
        {...TableInputData}
        onFeedback={(e) => {
          if (e?.id === 'lihat') {
            setDetailId(e?.data?.id)
            setModal((p: any) => ({...p, statusUbah: true}))
          }
        }}
        isDummy
        staticData={dummyDataRiwayatPerubahanMetadata}
      >
        {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
          const tableDataBuilder = {
            modal: modalConfig,
            input: {data: l, index: i},
            action: TableInputData.link?.action || [],
          }
          return (
            <Fragment key={i}>
              <TableRow {...tableDataBuilder}>
                <td className='w-100px'>
                  {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                </td>
                <td className='min-w-150px'>{NullProof({input: l, params: 'peristiwa'})}</td>
                <td className='min-w-150px'>
                  {NullProof({input: l, params: 'dilakukan_pada', type: 'date'})}
                </td>
                <TableAction {...tableDataBuilder} />
              </TableRow>
            </Fragment>
          )
        })}
      </TableInput>
    </>
  )
}

const TableHistoriNaskah: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataHistoryNaskah

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      isDummy
      staticData={dummyDataHistoryNaskah}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'tanggal', type: 'date'})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pengirim'})}</td>
              <td className='min-w-250px'>{NullProof({input: l, params: 'pegawai.nama'})}</td>
              <td className='min-w-350px'>
                <div className='mb-2'>
                  <div className='fw-bold mb-2'>Utama</div>
                  <div>
                    {NullProof({input: l, params: 'penerima.utama', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div className='d-flex gap-2'>
                            <div className='min-w-100px'>
                              {NullProof({input: l1, params: 'status_baca'})}
                            </div>
                            <div>{`${NullProof({
                              input: l1,
                              params: 'pegawai.nama',
                            })} - ${NullProof({
                              input: l1,
                              params: 'pegawai.jabatan',
                            })}`}</div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='fw-bold mb-2'>Tembusan</div>
                  <div>
                    {NullProof({input: l, params: 'penerima.tebusan', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div className='d-flex gap-2'>
                            <div className='min-w-100px'>
                              {NullProof({input: l1, params: 'status_baca'})}
                            </div>
                            <div>{`${NullProof({
                              input: l1,
                              params: 'pegawai.nama',
                            })} - ${NullProof({
                              input: l1,
                              params: 'pegawai.jabatan',
                            })}`}</div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'jenis_naskah'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'hal'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'file'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'lampiran'})}</td>
              <TableAction {...tableDataBuilder} />
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

export {
  TableDaftarVerifikator,
  TablePenandaTanganan,
  TableRiwayatPerubahanMetadata,
  TableHistoriNaskah,
  TableInputNaskahDinas,
  TableInputLampiran,
  TableInputTujuan,
  TableInputVerifikator,
  TableInputPenandatanganan,
  TableInputStatusKirim,
}
