import {PageTitle} from '../../../../../_metronic/layout/core'
import {FC, Fragment, useState} from 'react'
import PageData, {InfoData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  formInputListSelector,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import {useParams} from 'react-router-dom'
import {CardUi} from '../../../../helper/AppUi'
import FormInput from '../../../../helper/FormInput'
import {TableInputData} from './PageData'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data)}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          protect='/kebijakan_teknis/risk_register'
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Tujuan' className='min-w-150px'>
                    {NullProof({input: l, params: 'tujuan'})}
                  </TableColumn>
                  <TableColumn label='Proses Bisnis' className='min-w-150px'>
                    {NullProof({input: l, params: 'proses_bisnis'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const query = useParams()
  if (query['id']) {
    const iRiskId = formInputListSelector({formInputList, name: 'risk_register_id'})
    iRiskId.value = query['id']
    iRiskId.disabled = true
  }
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data)}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            type: 'post',
            url: RouterQueryParams(InfoData.api, query),
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.detail?.page.breadcrumb.data)}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Tujuan'}}>
              {NullProof({input: data, params: 'tujuan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Proses Bisnis'}}>
              {NullProof({input: data, params: 'proses_bisnis'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              isResponsive='desktop'
              hideDivider
              title={{value: 'Tema', props: {className: 'mb-4'}}}
            >
              {NullProof({input: data, params: 'risk_category_list', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn title={{value: 'Nama'}}>
                      {NullProof({input: l, params: 'nama'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn title={{value: 'Kode'}}>
                      {NullProof({input: l, params: 'kode'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      hideDivider
                      isResponsive='desktop'
                      title={{value: 'Penyebab Risiko', props: {className: 'mb-4'}}}
                    >
                      {NullProof({input: l, params: 'risk_cause_list', isMap: true})?.map(
                        (ll: any, ii: number) => (
                          <Fragment key={ii}>
                            <TableInputDetailColumn>
                              {ii + 1}. {NullProof({input: ll, params: 'deskripsi'})}
                            </TableInputDetailColumn>
                          </Fragment>
                        )
                      )}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      hideDivider
                      isResponsive='desktop'
                      title={{value: 'Potensi Kerugian', props: {className: 'mb-4'}}}
                    >
                      {NullProof({input: l, params: 'risk_severity_list', isMap: true})?.map(
                        (ll: any, ii: number) => (
                          <Fragment key={ii}>
                            <TableInputDetailColumn>
                              {`${ii + 1}. ${NullProof({
                                input: ll,
                                params: 'deskripsi',
                              })} - ${NullProof({
                                input: ll,
                                params: 'cost',
                                type: 'currency',
                              })}`}
                            </TableInputDetailColumn>
                          </Fragment>
                        )
                      )}
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  if (query['id']) {
    const iRiskId = formInputListSelector({formInputList, name: 'risk_register_id'})
    iRiskId.value = query['id']
    iRiskId.disabled = true
  }
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            type: 'put',
            url: RouterQueryParams(`${InfoData.api}/:subid`, query),
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, DetailWrapper, UbahWrapper}
