import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'

const inputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'detail',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Detail is required'),
    options: {
      textarea: {
        props: {
          title: 'Detail',
          rows: 3,
          placeholder: 'Detail',
        },
      },
    },
  },
]

export {inputList}
