import {TableInputDataProps} from '../../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../../helper/TableInputDetail'

export const InfoData = {
  title: 'Persetujuan',
  url: '/pengawasan/persiapan/daftar_status_usulan/persetujuan',
  api: '/pengawasan_internal/form-usulan',
  api_persetujuan: '/pengawasan_internal/status-usulan/persetujuan',
  api_revisi: '/pengawasan_internal/form-usulan-revisi',
  apiSatker: '/pengawasan_internal/data-satker',
  apiUsulan: '/pengawasan_internal/data-usulan',
  apiST: '/pengawasan_internal/data-st',
}

const BaseBreadcrumb = [
  {
    title: 'Pelaksanaan Pengawasan Internal',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Persiapan Pelaksanaan Pegawasan Internal',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Daftar Status Usulan',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.url,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
    },
    {
      title: 'Nama Pengusul',
    },
    {
      title: 'Kegiatan',
    },
    {
      title: 'Status',
    },
    {
      title: 'Dibuat pada',
    },
  ],
  link: {
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
    ],
  },
  route: {
    url: InfoData.api_persetujuan,
  },
}


export const TableInputUsulan: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
    },
    {
      title: 'Nama Pengusul',
    },
    {
      title: 'Tanggal Usulan',
    },
    {
      title: 'Deskripsi',
    },
    {
      title: 'Tanggal Pelaksanaan',
    },
    {
      title: 'Status Usulan',
    },
  ],
  // filter: filterInputList,
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'id',
        protect: 'ubah/:id',
        title: 'Ubah',
        route: 'ubah/:id',
      },
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: '',
      },
    ],
  },
  route: {
    url: `${InfoData.apiUsulan}/:id`,
  },
}

export const TableInputUsulanSatker: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
    },
    {
      title: 'Nama Pengusul',
    },
    {
      title: 'Tanggal Usulan',
    },
    {
      title: 'Deskripsi',
    },
    {
      title: 'Tanggal Pelaksanaan',
    },
    {
      title: 'Status Usulan',
    },
  ],
  // filter: filterInputList,
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'id',
        protect: 'ubah/:id',
        title: 'Ubah',
        route: 'ubah/:id',
      },
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: '',
      },
    ],
  },
  route: {
    url: `${InfoData.apiSatker}/:id`,
  },
}

export const TableInputST: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
    },
    {
      title: 'Nomor ST',
    },
    {
      title: 'Tanggal ST',
    },
    {
      title: 'Kegiatan Tugas',
    },
    {
      title: 'Tanggal Pelaksanaan',
    },
  ],
  // filter: filterInputList,
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'id',
        protect: 'ubah/:id',
        title: 'Ubah',
        route: 'ubah/:id',
      },
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: '',
      },
    ],
  },
  route: {
    url: `${InfoData.apiST}/:id`,
  },
}

export const TableInputSTSatker: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
    },
    {
      title: 'Nomor ST',
    },
    {
      title: 'Tanggal ST',
    },
    {
      title: 'Kegiatan Tugas',
    },
    {
      title: 'Tanggal Pelaksanaan',
    },
  ],
  // filter: filterInputList,
  link: {
    // tambah: {
    //   id: 'tambah',
    //   protect: 'tambah',
    //   title: 'Tambah',
    //   route: 'tambah',
    // },
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'id',
        protect: 'ubah/:id',
        title: 'Ubah',
        route: 'ubah/:id',
      },
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: '',
      },
    ],
  },
  route: {
    url: `${InfoData.apiSatker}/:id`,
  },
}


export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title:  `${InfoData.title}`,
      breadcrumb: {
        title: `Detail ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
    detailUsulan: {
      title: `Detail Usulan`,
      breadcrumb: {
        title: `Detail ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
    anggotaTim: {
      title: `Anggota Tim`,
      breadcrumb: {
        title: `Detail ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
    anggaranTim: {
      title: `Anggaran Tim`,
      breadcrumb: {
        title: `Detail ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
    review: {
      title: `Review`,
      breadcrumb: {
        title: `Detail ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  revisi: {
    page: {
      title: `Revisi ${InfoData.title}`,
      breadcrumb: {
        title: `Revisi ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: `Anggota ${InfoData.title}`,
      breadcrumb: {
        title: `Anggota ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdfPreviewST: {
    page: {
      title: `Preview PDF ${InfoData.title}`,
      breadcrumb: {
        title: `Preview PDF ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
