import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'jenis',
    type: 'input',
    value: '',
    validator: Yup.string().required('Jenis Naskah wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Jenis Naskah',
          placeholder: '',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'file',
    type: 'input',
    value: '',
    validator: Yup.string().required('File wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Template Naskah',
          placeholder: 'Template Naskah',
          accept: '.pdf, .jpg, .jpeg, .png',
          type: 'file',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
        size: 5
      },
    },
  },
]

export {formInputList}
