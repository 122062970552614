/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../../../../../helper/AppUi'
import {TableAction, TableInput, TableRow} from '../../../../../../../helper/TableInput'
import {
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../../../../helper/AppFunction'
import FormInput from '../../../../../../../helper/FormInput'
import {
  TableInputDetail,
  TableInputDetailColumn,
} from '../../../../../../../helper/TableInputDetail'
import {formatDate} from '../../../../../../../helper/DateFns'

const onChange = (e: any) => {}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td style={{minWidth: '50px'}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'tanggal'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </td>
                  <td style={{minWidth: '200px'}}>{NullProof({input: l, params: 'tahun'})}</td>
                  <td style={{minWidth: '200px'}}>{NullProof({input: l, params: 'tempat'})}</td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'ttd_auditi.nama'})}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'ttd_ketua.nama'})}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'ttd_dalnis.nama'})}
                  </td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Nomor Surat Tugas'}}>
            {NullProof({input: data, params: 'surat_tugas.nomor_surat'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal'}}>
            {formatDate({
              date: NullProof({input: data, params: 'tanggal'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tahun'}}>
            {NullProof({input: data, params: 'tahun'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Lokasi'}}>
            {NullProof({input: data, params: 'tempat'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            hideDivider
            title={{value: 'Tanda Tangan'}}
          ></TableInputDetailColumn>
          <TableInputDetailColumn hideDivider title={{value: 'Auditi'}}>
            <TableInputDetailColumn hideDivider title={{value: 'NIP'}}>
              {NullProof({input: data, params: 'ttd_auditi.nip'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'ttd_auditi.nama'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal'}}>
            {formatDate({
              date: NullProof({input: data, params: 'ttd_auditi_tanggal'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn hideDivider title={{value: 'Ketua'}}>
            <TableInputDetailColumn hideDivider title={{value: 'NIP'}}>
              {NullProof({input: data, params: 'ttd_ketua.nip'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'ttd_ketua.nama'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal'}}>
            {formatDate({
              date: NullProof({input: data, params: 'ttd_ketua_tanggal'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn hideDivider title={{value: 'Auditor Pengendali Teknis'}}>
            <TableInputDetailColumn hideDivider title={{value: 'NIP'}}>
              {NullProof({input: data, params: 'ttd_dalnis.nip'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'ttd_dalnis.nama'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal'}}>
            {formatDate({
              date: NullProof({input: data, params: 'ttd_dalnis_tanggal'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
