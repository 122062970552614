import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../../helper/template/FormInputTemplate'
import {inputList} from '../../klasifikasi-naskah/InputLayout'

const filterFormInputList: FormInputProps[] = [
  ...inputLayoutBuilder({
    input: [{template: 'search'}, {template: 'status'}],
  }),
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'nama',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Penyusutan',
          placeholder: 'Nama Penyusutan',
        },
      },
    },
  },
]

export {formInputList, filterFormInputList}
