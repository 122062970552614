import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../helper/AppFunction'
import {TableAction, TableInput, TableInputDataProps, TableRow} from '../../../../helper/TableInput'
import {getData, putData} from '../../../../helper/FormAxios'
import Modal from '../../../../helper/Modal'
import FormInput from '../../../../helper/FormInput'
import {formInputPenyelesaianNaskah} from './InputLayout'
import {InfoData} from './PageData'
import ReactIcon from '../../../../helper/ReactIcon'

const dummyDataDisposisi = [
  {
    tanggal: '2024-01-18T11:02:59Z',
    batas_waktu: '2024-01-18T11:02:59Z',
    koordinasi: 'koordinasinya',
    instruksi: 'instruksinya',
    status: 'Belum dibaca',
    dilakukan_oleh: {
      nama: 'Miftahul Huda',
      jabatan: 'Auditor Muda Inspektorat Wilayah III',
    },
    kepada: {
      nama: 'Miftahul Huda',
      jabatan: 'Auditor Muda Inspektorat Wilayah III',
    },
  },
]

const RouteList = {
  disposisi: '/tata_usaha/disposisi_naskah',
  balas: '/tata_usaha/disposisi_naskah',
  histori_naskah: '/tata_usaha/disposisi_naskah',
}

export const TableInputDataDisposisi: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Dilakukan Oleh',
    },
    {
      title: 'Kepada',
    },
    {
      title: 'Pesan Disposisi / Koordinasi',
    },
    {
      title: 'Instruksi / Pesan Tambahan',
    },
    {
      title: 'Lampiran',
    },
    {
      title: 'Tanggal Disposisi',
    },
    {
      title: 'Batas Waktu',
    },
    {
      title: 'Status',
    },
  ],
  link: {
    export: [],
    action: [
      {
        id: 'id',
        protect: 'delete',
        title: 'Batalkan Disposisi',
        route: '',
      },
      {
        id: 'id',
        protect: 'selesai',
        title: 'Selesaikan Disposisi',
        route: '',
        type: 'feedback',
      },
    ],
  },
  route: {
    url: `${RouteList.disposisi}/:id/get_disposisi`,
  },
}

export const TableInputDataBalas: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Dilakukan Oleh',
    },
    {
      title: 'Kepada',
    },
    {
      title: 'Tanggal Balas',
    },
  ],
  link: {
    export: [],
    action: [],
  },
  route: {
    url: `${RouteList.balas}/:id/balasan`,
  },
}

export const TableInputDataHistoryNaskah: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Tanggal',
    },
    {
      title: 'Pengirim',
    },
    {
      title: 'Asal',
    },
    {
      title: 'Tujuan',
    },
    {
      title: 'Jenis Naskah',
    },
    {
      title: 'Hal',
    },
    {
      title: 'File',
    },
    {
      title: 'Lampiran',
    },
  ],
  link: {
    export: [],
    action: [
      // {
      //   id: 'id',
      //   protect: 'detail/:id',
      //   title: 'Detail',
      //   route: 'detail/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'ubah/:id',
      //   title: 'Ubah',
      //   route: 'ubah/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'pdf/:id',
      //   title: 'Lihat PDF',
      //   route: 'pdf/:id',
      // },
      // {
      //   id: 'id',
      //   protect: 'delete',
      //   title: 'Hapus',
      //   route: '',
      // },
    ],
  },
  route: {
    url: `${RouteList.histori_naskah}/:id/histori_naskah`,
  },
}

const TableDisposisi: FC<{onFeedback?: any}> = ({onFeedback}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataDisposisi

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      onFeedback={async (e) => {
        if (e?.protect == 'selesai') {
          let disposisiNaskah = await getData(
            '',
            RouterQueryParams('/tata_usaha/disposisi_naskah', query)
          )
          if (disposisiNaskah?.status) {
            await putData(
              e.data,
              RouterQueryParams(`/tata_usaha/disposisi_naskah/${e.data.id.toString()}`, query)
            )
            onFeedback()
          }
        }
      }}
      // isDummy
      // staticData={dummyDataDisposisi}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pembuat_id.nama'})}</td>
              <td className='min-w-150px'>
                <div>
                  {NullProof({input: l, params: 'kepada_id', isMap: true})?.map(
                    (l1: any, i1: number) => (
                      <Fragment key={i1}>
                        <div className='d-flex gap-2'>
                          <div>
                            {NullProof({input: l1, params: 'status_baca'}) === 1 ? (
                              <div
                                className='rounded-circle'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  minWidth: '20px',
                                  backgroundColor: '#2ecc71',
                                }}
                              ></div>
                            ) : (
                              <div
                                className='rounded-circle'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  minWidth: '20px',
                                  backgroundColor: '#e74c3c',
                                }}
                              ></div>
                            )}
                            {NullProof({
                              input: l1,
                              params: 'nama',
                            })}
                          </div>
                        </div>
                      </Fragment>
                    )
                  )}
                </div>
              </td>
              {/* <td className='min-w-250px'>{NullProof({input: l, params: 'koordinasi'})}</td> */}
              <td className='min-w-250px'>
                {' '}
                {l.pesan.map((value: string, index: number) => (
                  <div key={index}>{value}</div>
                ))}
              </td>
              <td className='min-w-350px'>{NullProof({input: l, params: 'intruksi'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'lampiran_fix'})}</td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'createdAt', type: 'date'})}
              </td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'batas_waktu', type: 'date'})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'status'})}</td>
              <TableAction {...tableDataBuilder} />
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const TableBalas: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataBalas

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      // isDummy
      // staticData={dummyDataDisposisi}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pegawai.nama'})}</td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'DisposisiNaskah.kepada_id', isMap: true})?.map(
                  (l1: any, i1: number) => (
                    <Fragment key={i1}>
                      <div className='d-flex gap-2'>
                        <div>
                          {NullProof({
                            input: l1,
                            params: 'pegawai_id.nama',
                          })}
                        </div>
                      </div>
                    </Fragment>
                  )
                )}
              </td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'createdAt', type: 'date'})}
              </td>
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const TableHistoriNaskah: FC = () => {
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  const TableInputData = TableInputDataHistoryNaskah

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => setData(val)}
      {...TableInputData}
      // isDummy
      // staticData={dummyDataHistoryNaskah}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder} key={i}>
              <td className='w-100px'>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </td>
              <td className='min-w-150px'>
                {NullProof({input: l, params: 'createdAt', type: 'date'})}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'pengirim'})}</td>
              <td className='min-w-250px'>{NullProof({input: l, params: 'asal'})}</td>
              <td className='min-w-350px'>
                <div className='mb-2'>
                  <div className='fw-bold mb-2'>Utama</div>
                  <div>
                    {NullProof({input: l, params: 'tujuan', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div className='d-flex gap-2'>
                            <div className='min-w-100px'>
                              {NullProof({input: l1, params: 'status_baca'}) === 1 ? (
                                <div
                                  className='rounded-circle'
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    minWidth: '20px',
                                    backgroundColor: '#2ecc71',
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className='rounded-circle'
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    minWidth: '20px',
                                    backgroundColor: '#e74c3c',
                                  }}
                                ></div>
                              )}
                            </div>
                            <div>{`${NullProof({
                              input: l1,
                              params: 'pegawai_id.nama',
                            })}`}</div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='fw-bold mb-2'>Tembusan</div>
                  <div>
                    {NullProof({input: l, params: 'tembusan', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div className='d-flex gap-2'>
                            <div className='min-w-100px'>
                              {NullProof({input: l1, params: 'status_baca'}) === 1 ? (
                                <div
                                  className='rounded-circle'
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    minWidth: '20px',
                                    backgroundColor: '#2ecc71',
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className='rounded-circle'
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    minWidth: '20px',
                                    backgroundColor: '#e74c3c',
                                  }}
                                ></div>
                              )}
                            </div>
                            <div>{`${NullProof({
                              input: l1,
                              params: 'pegawai_id.nama',
                            })}`}</div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
              </td>
              <td className='min-w-150px'>
                {typeof NullProof({input: l, params: 'jenis_naskah'}) === 'object' ? (
                  <>{NullProof({input: l, params: 'jenis_naskah.text'})}</>
                ) : (
                  NullProof({input: l, params: 'jenis_naskah'})
                )}
              </td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'hal'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'file'})}</td>
              <td className='min-w-150px'>{NullProof({input: l, params: 'lampiran_fix'})}</td>
              <TableAction {...tableDataBuilder} />
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const ModalPenyelesaianDisposisi: FC<{
  url: string
  isShow: boolean
  setModal: any
  query: any
  title: string
  onRefresh: any
}> = ({isShow, setModal, url, query, title, onRefresh}) => {
  return (
    <>
      <Modal
        id='modal_kirim_naskah'
        isShow={isShow}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title={title}
      >
        <FormInput
          input={formInputPenyelesaianNaskah}
          route={{
            url: RouterQueryParams(`${url}`, query),
            isFeedback: true,
            type: 'put',
            onFeedback: async (e: any) => {
              if (e.status == true) {
                await putData(
                  e.data,
                  RouterQueryParams(`${url}/:id`, query)
                );
                if (onRefresh) {
                  onRefresh();
                }
              }
              setModal((p: any) => ({ ...p, statusUbah: false }));
            },
          }}
          isModal
        />
      </Modal>
    </>
  )
}

const ButtonPenyelesaianDisposisi: FC<{ url:string , onRefresh?: () => void }> = ({ onRefresh, url }) => {
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  });
  const query = useParams();
  const TableInputData = TableInputDataDisposisi;

  const handleRefresh = () => {
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <>
      <div className='d-flex flex-row-reverse mb-3'>
        <button
          className='btn btn-success d-flex gap-1 align-items-center'
          onClick={() => {
            setModal({
              statusUbah: true,
            });
          }}
        >
          <ReactIcon icon='RiCornerDownLeftLine' props={{ className: 'fs-3' }} />
          {'Penyelesaian Disposisi'}
        </button>
      </div>
      {modal.statusUbah && (
        <ModalPenyelesaianDisposisi
          isShow={modal.statusUbah}
          setModal={setModal}
          url={url}
          query={query}
          title='Penyelesaian Disposisi'
          onRefresh={handleRefresh}
        />
      )}
    </>
  );
};

export {TableDisposisi, TableBalas, TableHistoriNaskah, ModalPenyelesaianDisposisi, ButtonPenyelesaianDisposisi}
