import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputImportList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Panduan untuk Upload',
        description: (
          <div className='fw-normal'>
            <ol>
              <li>Unduh Template Excel Data Terbaru nya diatas.</li>
              <li>Buka melalui aplikasi Excel pada komputer.</li>
              <li>Ubah data sesuai update terbaru dari anda.</li>
              <li>Upload file yang sudah diubah pada komputer anda dibawah.</li>
            </ol>
          </div>
        ),
      },
    },
  },
  {
    className: 'col-12',
    name: 'csv',
    type: 'csv',
    value: '',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Template Excel Terbaru',
          placeholder: 'Template Excel Terbaru',
        },
      },
    },
  },
]

const formInputDataKegiatan: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Pegawai wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Pegawai',
          placeholder: 'Pegawai',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        params: 'nama,nip',
        result: '$nama | $nip',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama_kegiatan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Kegiatan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nama Kegiatan',
          placeholder: 'Nama Kegiatan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'wilayah_bagian',
    type: 'input',
    value: '',
    validator: Yup.string().required('Wilayah / Bagian wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Wilayah / Bagian',
          placeholder: 'Wilayah / Bagian',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_spm',
    type: 'input',
    value: '',
    validator: Yup.string().required('No SPM (4 Digit) wajib diisi'),
    options: {
      input: {
        props: {
          title: 'No SPM (4 Digit)',
          placeholder: 'No SPM (4 Digit)',
          maxLength: 4,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_spd',
    type: 'input',
    value: '',
    validator: Yup.string().required('SPD (5 Digit) wajib diisi'),
    options: {
      input: {
        props: {
          title: 'SPD (5 Digit)',
          placeholder: 'SPD (5 Digit)',
          maxLength: 5,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tujuan',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Tujuan is required'),
    options: {
      input: {
        props: {
          title: 'Tujuan',
          placeholder: 'Tujuan',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_pelaksanaan_mulai',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Mulai wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Tanggal Mulai',
          placeholder: 'Tanggal Mulai',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_pelaksanaan_selesai',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Selesai wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Tanggal Selesai',
          placeholder: 'Tanggal Selesai',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'hp',
    type: 'input',
    value: '',
    validator: Yup.number().required('HP wajib diisi'),
    options: {
      input: {
        props: {
          title: 'HP',
          placeholder: 'HP',
          maxLength: 3,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'uang_harian',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Uang Harian',
          placeholder: 'Uang Harian',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'uang_representasi',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Representasi',
          placeholder: 'Representasi',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'uang_penginapan',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Penginapan',
          placeholder: 'Penginapan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'uang_transport',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Transport / Tiket',
          placeholder: 'Transport / Tiket',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'uang_bst',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'BST',
          placeholder: 'BST',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'uang_realisasi',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Realisasi',
          placeholder: 'Realisasi',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'uang_jumlah',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah',
          placeholder: 'Jumlah',
        },
      },
      calc: {
        data: ['uang_harian', 'uang_representasi', 'uang_penginapan', 'uang_transport', 'uang_bst'],
        result:
          '$uang_harian + $uang_representasi + $uang_penginapan + $uang_transport + $uang_bst',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'uang_selisih',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Selisih',
          placeholder: 'Selisih',
        },
      },
      calc: {
        data: ['uang_jumlah', 'uang_realisasi'],
        result: '$uang_jumlah - $uang_realisasi',
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Surat Tugas wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        // query: 'id_anggota=$pegawai_id',
        // useOnLoad: true,
        params: 'nama_kegiatan,deskripsi_kegiatan,totalRAB',
        result: '$nama_kegiatan',
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama_kegiatan', type: 'text'},
            {id: 'deskripsi_kegiatan', type: 'text'},
            {id: 'totalRAB', type: 'currency'},
          ],
          protect: '',
          props: {
            route: {
              url: '/pengawasan_internal/surat-tugas',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama Kegiatan'},
              {title: 'Deskripsi Kegiatan'},
              {title: 'Total RAB'},
            ],
            link: {
              action: [
              ],
            },
          },
        },
      },
      // watchInput: {
      //   id: 'pegawai_id'
      // }
    }
  },


  {
    className: 'col-12',
    name: 'data',
    type: 'component',
    value: '',
    validator: Yup.number().min(0, 'Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'List Nominatif',
          placeholder: 'List Nominatif',
        },
      },
    },
    // lockData: true,
    component: [

      {
        className: 'col-12',
        name: 'usulan_id',
        type: 'input',
        value: '',
        hide: true,
        validator: Yup.string().required('Item wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Item',
              placeholder: 'Item',
            },
          },
        },
      },

      {
        className: 'col-12',
        name: 'tanggal_perancangan',
        type: 'input',
        value: '',
        validator: Yup.string().required('Tanggal wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Tanggal',
              placeholder: 'Tanggal',
              type: 'date',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'item',
        type: 'input',
        value: '',
        validator: Yup.string().required('Item wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Item',
              placeholder: 'Item',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'pegawai_id',
        type: 'datalist',
        value: '',
        validator: Yup.number().required('Pegawai wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Pegawai',
              placeholder: 'Pegawai',
              disabled: true,
            },
          },
          datalist: {
            api: '/kepegawaian/data-tenaga-audit',
            useOnLoad:true,
            params: 'nama,nip',
            result: '$nama | $nip',
            id: 'id',
          },
        },
      },
      {
        className: 'col-12',
        name: 'surat_tugas_id',
        type: 'datalist',
        value: '',
        validator: Yup.number().required('Surat Tugas wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Surat Tugas',
              placeholder: 'Surat Tugas',
              disabled: true,
            },
          },
          datalist: {
            api: '/pengawasan_internal/surat-tugas',
            useOnLoad: true,
            params: 'nama_kegiatan,deskripsi_kegiatan,totalRAB',
            result: '$nama_kegiatan',
            id: 'id',
            type: 'table',
            table: {
              data: [
                {id: 'nama_kegiatan', type: 'text'},
                {id: 'deskripsi_kegiatan', type: 'text'},
                {id: 'totalRAB', type: 'currency'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/pengaturan/data_satker_unit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama Kegiatan'},
                  {title: 'Deskripsi Kegiatan'},
                  {title: 'Total RAB'},
                ],
                link: {
                  action: [
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'pegawai_id'
          }
        }
      },
      {
        className: 'col-12',
        name: 'mak',
        type: 'input',
        value: '',
        validator: Yup.string().required('MAK wajib diisi'),
        options: {
          input: {
            props: {
              title: 'MAK',
              placeholder: 'MAK',
              disabled: true,
            },
          },
          watchInput: {
            id: 'surat_tugas_id',
          },
        },
      },
      {
        className: 'col-12',
        name: 'nama_kegiatan',
        type: 'input',
        value: '',
        validator: Yup.string().required('Nama Kegiatan wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Nama Kegiatan',
              placeholder: 'Nama Kegiatan',
              disabled: true,
            },
          },
          watchInput: {
            id: 'surat_tugas_id',
          }
        },
      },
      {
        className: 'col-12',
        name: 'wilayah_bagian',
        type: 'input',
        value: '',
        validator: Yup.string().required('Wilayah / Bagian wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Wilayah / Bagian',
              placeholder: 'Wilayah / Bagian',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'no_spm',
        type: 'input',
        value: '',
        validator: Yup.string().required('No SPM (4 Digit) wajib diisi'),
        options: {
          input: {
            props: {
              title: 'No SPM (4 Digit)',
              placeholder: 'No SPM (4 Digit)',
              maxLength: 4,
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'no_spd',
        type: 'input',
        value: '',
        validator: Yup.string().required('SPD (5 Digit) wajib diisi'),
        options: {
          input: {
            props: {
              title: 'SPD (5 Digit)',
              placeholder: 'SPD (5 Digit)',
              disabled: true,
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'tujuan',
        type: 'datalist',
        value: '',
        validator: Yup.string().required('Tujuan is required'),
        options: {
          input: {
            props: {
              title: 'Tujuan',
              placeholder: 'Tujuan',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-provinsi',
            params: 'nama',
            result: '$nama',
            id: 'nama',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'tanggal_pelaksanaan_mulai',
        type: 'input',
        value: '',
        validator: Yup.string().required('Tanggal Mulai wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Tanggal Mulai',
              placeholder: 'Tanggal Mulai',
              type: 'date',
              // disabled: true,
            },
          },
          watchInput:{
            id: 'surat_tugas_id'
          }
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'tanggal_pelaksanaan_selesai',
        type: 'input',
        value: '',
        validator: Yup.string().required('Tanggal Selesai wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Tanggal Selesai',
              placeholder: 'Tanggal Selesai',
              type: 'date',
              // disabled: true,
            },
          },
          watchInput:{
            id: 'surat_tugas_id'
          }
        },
      },
      {
        className: 'col-12',
        name: 'hp',
        type: 'input',
        value: '',
        validator: Yup.number().required('HP wajib diisi'),
        options: {
          input: {
            props: {
              title: 'HP',
              placeholder: 'HP',
              maxLength: 3,
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_harian',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Uang Harian',
              placeholder: 'Uang Harian',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_representasi',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Representasi',
              placeholder: 'Representasi',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_penginapan',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Penginapan',
              placeholder: 'Penginapan',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_transport',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Transport / Tiket',
              placeholder: 'Transport / Tiket',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_bst',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'BST',
              placeholder: 'BST',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_realisasi',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Realisasi',
              placeholder: 'Realisasi',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_jumlah',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Jumlah',
              placeholder: 'Jumlah',
            },
          },
          calc: {
            data: ['uang_harian', 'uang_representasi', 'uang_penginapan', 'uang_transport', 'uang_bst'],
            result:
              '$uang_harian + $uang_representasi + $uang_penginapan + $uang_transport + $uang_bst',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_selisih',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Selisih',
              placeholder: 'Selisih',
            },
          },
          calc: {
            data: ['uang_jumlah', 'uang_realisasi'],
            result: '$uang_jumlah - $uang_realisasi',
          },
        },
      },
    ]
  }
]

const formUpdateList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'data',
    type: 'component',
    value: '',
    validator: Yup.number().min(0, 'Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'List Nominatif',
          placeholder: 'List Nominatif',
        },
      },
    },
    lockData: true,
    component: [
      {
        className: 'col-12',
        name: 'tanggal_perancangan',
        type: 'input',
        value: '',
        validator: Yup.string().required('Tanggal wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Tanggal',
              placeholder: 'Tanggal',
              type: 'date',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'item',
        type: 'input',
        value: '',
        validator: Yup.string().required('Item wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Item',
              placeholder: 'Item',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'pegawai_id',
        type: 'datalist',
        value: '',
        validator: Yup.number().required('Pegawai wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Pegawai',
              placeholder: 'Pegawai',
              disabled: true,
            },
          },
          datalist: {
            api: '/kepegawaian/data-tenaga-audit',
            params: 'nama,nip',
            result: '$nama | $nip',
            id: 'id',
          },
        },
      },
      {
        className: 'col-12',
        name: 'surat_tugas_id',
        type: 'datalist',
        value: '',
        validator: Yup.number().required('Surat Tugas wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Surat Tugas',
              placeholder: 'Surat Tugas',
              disabled: true,
            },
          },
          datalist: {
            api: '/pengawasan_internal/surat-tugas',
            useOnLoad: true,
            params: 'nama_kegiatan,deskripsi_kegiatan,totalRAB',
            result: '$nama_kegiatan',
            id: 'id',
            type: 'table',
            table: {
              data: [
                {id: 'nama_kegiatan', type: 'text'},
                {id: 'deskripsi_kegiatan', type: 'text'},
                {id: 'totalRAB', type: 'currency'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/pengaturan/data_satker_unit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama Kegiatan'},
                  {title: 'Deskripsi Kegiatan'},
                  {title: 'Total RAB'},
                ],
                link: {
                  action: [
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'pegawai_id'
          }
        }
      },
      {
        className: 'col-12',
        name: 'mak',
        type: 'input',
        value: '',
        validator: Yup.string().required('MAK wajib diisi'),
        options: {
          input: {
            props: {
              title: 'MAK',
              placeholder: 'MAK',
              disabled: true,
            },
          },
          watchInput: {
            id: 'surat_tugas_id',
          },
        },
      },
      {
        className: 'col-12',
        name: 'nama_kegiatan',
        type: 'input',
        value: '',
        validator: Yup.string().required('Nama Kegiatan wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Nama Kegiatan',
              placeholder: 'Nama Kegiatan',
              disabled: true,
            },
          },
          watchInput: {
            id: 'surat_tugas_id',
          }
        },
      },
      {
        className: 'col-12',
        name: 'wilayah_bagian',
        type: 'input',
        value: '',
        validator: Yup.string().required('Wilayah / Bagian wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Wilayah / Bagian',
              placeholder: 'Wilayah / Bagian',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'no_spm',
        type: 'input',
        value: '',
        validator: Yup.string().required('No SPM (4 Digit) wajib diisi'),
        options: {
          input: {
            props: {
              title: 'No SPM (4 Digit)',
              placeholder: 'No SPM (4 Digit)',
              maxLength: 4,
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'no_spd',
        type: 'input',
        value: '',
        validator: Yup.string().required('SPD (5 Digit) wajib diisi'),
        options: {
          input: {
            props: {
              title: 'SPD (5 Digit)',
              placeholder: 'SPD (5 Digit)',
              disabled: true,
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'tujuan',
        type: 'datalist',
        value: '',
        validator: Yup.string().required('Tujuan is required'),
        options: {
          input: {
            props: {
              title: 'Tujuan',
              placeholder: 'Tujuan',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-provinsi',
            params: 'nama',
            result: '$nama',
            id: 'nama',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'tanggal_pelaksanaan_mulai',
        type: 'input',
        value: '',
        validator: Yup.string().required('Tanggal Mulai wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Tanggal Mulai',
              placeholder: 'Tanggal Mulai',
              type: 'date',
              // disabled: true,
            },
          },
          watchInput:{
            id: 'surat_tugas_id'
          }
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'tanggal_pelaksanaan_selesai',
        type: 'input',
        value: '',
        validator: Yup.string().required('Tanggal Selesai wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Tanggal Selesai',
              placeholder: 'Tanggal Selesai',
              type: 'date',
              // disabled: true,
            },
          },
          watchInput:{
            id: 'surat_tugas_id'
          }
        },
      },
      {
        className: 'col-12',
        name: 'hp',
        type: 'input',
        value: '',
        validator: Yup.number().required('HP wajib diisi'),
        options: {
          input: {
            props: {
              title: 'HP',
              placeholder: 'HP',
              maxLength: 3,
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_harian',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Uang Harian',
              placeholder: 'Uang Harian',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_representasi',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Representasi',
              placeholder: 'Representasi',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_penginapan',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Penginapan',
              placeholder: 'Penginapan',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_transport',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Transport / Tiket',
              placeholder: 'Transport / Tiket',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_bst',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'BST',
              placeholder: 'BST',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_realisasi',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Realisasi',
              placeholder: 'Realisasi',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_jumlah',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Jumlah',
              placeholder: 'Jumlah',
            },
          },
          calc: {
            data: ['uang_harian', 'uang_representasi', 'uang_penginapan', 'uang_transport', 'uang_bst'],
            result:
              '$uang_harian + $uang_representasi + $uang_penginapan + $uang_transport + $uang_bst',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'uang_selisih',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Selisih',
              placeholder: 'Selisih',
            },
          },
          calc: {
            data: ['uang_jumlah', 'uang_realisasi'],
            result: '$uang_jumlah - $uang_realisasi',
          },
        },
      },
    ]
  }
 
]

export {formInputList, formUpdateList, formInputDataKegiatan, formInputImportList}
