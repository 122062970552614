import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CounterState {
  value: any;
}

const initialState: CounterState = {
  value: {
    route: [],
    menu: [],
    editor: [],
    icon: {},
  },
};

const routeSlice: any = createSlice({
  name: 'route',
  initialState,
  reducers: {
    post: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
    clear: (state) => {
      state.value = {
        route: [],
        menu: [],
        editor: [],
        icon: {},
      }
    }
  },
});

export const { post, clear } = routeSlice.actions;
export default routeSlice.reducer;
