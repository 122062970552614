/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useParams} from 'react-router-dom'
import {InfoData} from './PageData'
import {formInputList} from './InputLayout'
import {useAuth} from '../../../modules/auth'
import {CardUi} from '../../../helper/AppUi'
import FormInput from '../../../helper/FormInput'
import {RouterQueryParams} from '../../../helper/AppFunction'
import {getData} from '../../../helper/FormAxios'

const MainWrapper: FC = () => {
  const {auth, saveAuth} = useAuth()
  const query = useParams()
  const userData = auth?.data
  return (
    <>
      <CardUi>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/${userData?.id}`, query),
            type: 'put',
            redirect: true,
            onFeedback: async (res: any) => {
              if (res?.status) {
                let rebuildAuth: any = {...auth}
                let newAuth = res?.data
                delete newAuth.password
                if (newAuth?.pegawai_id) {
                  const pegawaiData = await getData(
                    '',
                    `/kepegawaian/data-tenaga-audit/${newAuth.pegawai_id}`
                  )
                  if (pegawaiData?.status) {
                    newAuth.pegawai = pegawaiData?.data
                  }
                }
                rebuildAuth.data = {...rebuildAuth.data, ...newAuth}
                saveAuth(rebuildAuth)
              }
            },
          }}
        />
      </CardUi>
    </>
  )
}

export {MainWrapper}
