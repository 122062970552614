import { FC, Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'
import Modal from '../../../../../helper/Modal'
import FormInput from '../../../../../helper/FormInput'
import { formInputList } from '../InputLayout'
import { NullProof, RouterQueryParams } from '../../../../../helper/AppFunction'
import PageData, { InfoData, TableInputDataDisposisi, TableInputDetailData } from '../PageData'
import { PageTitle } from '../../../../../../_metronic/layout/core'
import { CardUi, ListdataUi, TabsUi, TimelineUi } from '../../../../../helper/AppUi'
import ReactIcon from '../../../../../helper/ReactIcon'
import { formatDate } from '../../../../../helper/DateFns'
import { TableInputDetail, TableInputDetailColumn } from '../../../../../helper/TableInputDetail'
import Accordion from '../../../../../helper/Accordion'
import { ReactPdfViewer } from '../../../../../helper/ReactPdf'
import {
	TableDaftarPenandatanganan,
	TableDaftarVerifikator, TableInputLampiran,
	TableInputNaskahDinas, TableInputPenandatanganan, TableInputStatusKirim, TableInputTujuan, TableInputVerifikator,
	TableRiwayatPerubahanMetadata,
} from '../TableWrapper'
import { putData } from '../../../../../helper/FormAxios'
import Swal from 'sweetalert2'

const DetailWrapper: FC = () => {
	const query = useParams()
	const [data, setData] = useState<any>({})
	const [modal, setModal] = useState<any>({
		statusUbah: false,
	})
	const [key, setKey] = useState<number>(0)
	// Disposisi
	const [dataDisposisi, setDataDisposisi] = useState<any>([])
	const [modalDisposisi, setModalDisposisi] = useState<any>([])
	const modalDisposisiConfig = {
		data: modalDisposisi,
		post: setModalDisposisi,
	}
	// Balas
	const [dataBalas, setDataBalas] = useState<any>([])
	const [modalBalas, setModalBalas] = useState<any>([])
	const modalBalasConfig = {
		data: modalBalas,
		post: setModalBalas,
	}
	// History Naskah
	const [dataHistory, setDataHistory] = useState<any>([])
	const [modalHistory, setModalHistory] = useState<any>([])
	const modalHistoryConfig = {
		data: modalHistory,
		post: setModalHistory,
	}
	return (
		<>
			<PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
				{PageData.detail?.page.breadcrumb.title}
			</PageTitle>

			<Fragment key={key}>
				<CardUi
					title={PageData.detail?.page.breadcrumb.title}
				>
					<TableInputDetail
						// isDummy
						onSuccess={(val: any) => setData(val)}
						{...TableInputDetailData}
					>
						<div className='row'>
							<div className='col-12 col-md-6'>
								<TableInputDetailColumn
									hideDivider
									isResponsive='desktop'
									title={{value: 'Nama'}}
								>
									{NullProof({input: data, params: 'nama'})}
								</TableInputDetailColumn>
							</div>
							<div className='col-12 col-md-6'>
								<TableInputDetailColumn
									hideDivider
									isResponsive='desktop'
									title={{value: 'Tanggal Dibuat'}}
								>
									{NullProof({input: data, params: 'createdAt', type: 'date'})}
								</TableInputDetailColumn>
							</div>
							<div className='col-12 col-md-6'>
								<TableInputDetailColumn
									hideDivider
									isResponsive='desktop'
									title={{value: 'Kode'}}
								>
									{NullProof({input: data, params: 'kode'})}
								</TableInputDetailColumn>
							</div>
							<div className='col-12 col-md-6'>
								<TableInputDetailColumn
									hideDivider
									isResponsive='desktop'
									title={{value: 'Terkahir Diubah'}}
								>
									{NullProof({input: data, params: 'updatedAt', type: 'date'})}
								</TableInputDetailColumn>
							</div>
						</div>
						<div className='row'>
							<div className='col-12 col-md-6'>
								<TableInputDetailColumn
									hideDivider
									isResponsive='desktop'
									title={{value: 'Retensi Aktif'}}
								>
									{NullProof({input: data, params: 'retensi_aktif'})}
								</TableInputDetailColumn>
							</div>
							<div className='col-12 col-md-6'>
								<TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Retensi Inaktif'}}>
									{NullProof({input: data, params: 'retensi_inaktif'})}
								</TableInputDetailColumn>
							</div>
						</div>
						<div className="row">
							<div className='col-12 col-md-6'>
								<TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Penyusutan Akhir'}}>
									{NullProof({input: data, params: 'penyusutan_akhir_nama'})}
								</TableInputDetailColumn>
							</div>
						</div>

						<div className='my-4 d-flex flex-column gap-2'>
							<Accordion title='Tujuan' enabled props={{body: {className: 'p-4 border-bottom'}}}>
								<div className='row'>
									<div className='col-12 col-md-6'>
										<TableInputDetailColumn
											props={{className: 'row mb-4'}}
											hideDivider
											title={{value: 'Utama:'}}
										></TableInputDetailColumn>
										{NullProof({input: data, params: 'utama', isMap: true})?.map(
											(l: any, i: number) => (
												<Fragment key={i}>
													<TableInputDetailColumn
														props={{className: 'row mb-2'}}
														hideDivider
														title={{value: NullProof({input: l, params: 'pegawai.nama'})}}
													>
														{NullProof({input: l, params: 'pegawai.jabatan.tampil_jabatan'})}
													</TableInputDetailColumn>
												</Fragment>
											)
										)}
									</div>
									<div className='col-12 col-md-6'>
										<TableInputDetailColumn
											props={{className: 'row mb-4'}}
											hideDivider
											title={{value: 'Tebusan:'}}
										></TableInputDetailColumn>
										{NullProof({input: data, params: 'tembusan', isMap: true})?.map(
											(l: any, i: number) => (
												<Fragment key={i}>
													<TableInputDetailColumn
														props={{className: 'row mb-2'}}
														hideDivider
														title={{value: NullProof({input: l, params: 'pegawai.nama'})}}
													>
														{NullProof({input: l, params: 'pegawai.tampil_jabatan'})}
													</TableInputDetailColumn>
												</Fragment>
											)
										)}
									</div>
								</div>
							</Accordion>
						</div>
					</TableInputDetail>

					{data.file_naskah && <ReactPdfViewer url={`/${data.file_naskah}`} />}
				</CardUi>
			</Fragment>
		</>
	)
}

export default DetailWrapper