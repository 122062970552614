/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, Fragment, useState} from 'react'
import {useIntl} from 'react-intl'
import {getData} from '../../../helper/FormAxios'
import {ConvertCurrency, NullProof} from '../../../helper/AppFunction'
const MainPage: FC = () => {
  const dummyData = {
    uangmakan: [
      {
        title: 'Uang Makan #1',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Uang Makan #2',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Uang Makan #3',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Uang Makan #4',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Uang Makan #5',
        tanggal: '17 Juni 2023',
        link: '/',
      },
    ],
    tunjangan: [
      {
        title: 'Tunjangan Kinerja #1',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Tunjangan Kinerja #2',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Tunjangan Kinerja #3',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Tunjangan Kinerja #4',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Tunjangan Kinerja #5',
        tanggal: '17 Juni 2023',
        link: '/',
      },
    ],
    perjalanan: [
      {
        title: 'Uang Muka #1',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Uang Muka #2',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Uang Muka #3',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Uang Muka #4',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Uang Muka #5',
        tanggal: '17 Juni 2023',
        link: '/',
      },
    ],
    surat: [
      {
        title: 'Surat #1',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Surat #2',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Surat #3',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Surat #4',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Surat #5',
        tanggal: '17 Juni 2023',
        link: '/',
      },
    ],
    form: [
      {
        title: 'Modul #1',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Modul #2',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Modul #3',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Modul #4',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Modul #5',
        tanggal: '17 Juni 2023',
        link: '/',
      },
    ],
    pinjaman: [
      {
        title: 'Pinjaman #1',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Pinjaman #2',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Pinjaman #3',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Pinjaman #4',
        tanggal: '17 Juni 2023',
        link: '/',
      },
      {
        title: 'Pinjaman #5',
        tanggal: '17 Juni 2023',
        link: '/',
      },
    ],
  }
  const route = {
    trasfer: '/keuangan/transfer',
  }
  const [data, setData] = useState([])
  const [refresh, setRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const loadData = async () => {
    const result: any = await getData('byuser=true', route.trasfer)
    if (result.status) {
      setData(result?.data)
    } else {
      setError(true)
    }
    setLoading(false)
  }
  const onReload = () => {
    loadData()
    setRefresh(false)
  }
  useEffect(() => {
    if (route.trasfer || refresh) {
      onReload()
    }
  }, [route.trasfer, refresh])
  const ListUi: FC<{data: {title: string; link: string; tanggal: string}}> = ({data}) => {
    return (
      <div className='form-control form-control-solid d-flex align-items-center justify-content-between'>
        <div>
          <div className='text-truncate text-dark fw-bold'>{data.title}</div>
          <div className='fs-7'>{data.tanggal}</div>
        </div>
        <button type='button' className='btn btn-sm btn-light text-dark'>
          Lihat
        </button>
      </div>
    )
  }
  return (
    <>
      <div className='row g-5'>
        <div className='col-12'>
          <div className={`card card-flush h-100`}>
            <div className='card-header py-5'>
              <div className='card-title d-flex flex-row justify-content-between w-100'></div>
              <div className='card-body px-0 w-100'>
                <div>
                  <div className='mb-4 fs-5 fw-bold'>Uang Makan</div>
                  <div
                    className='d-flex flex-column'
                    style={{
                      gap: '5px',
                    }}
                  >
                    {dummyData.uangmakan.map((l, i) => (
                      <Fragment key={i}>
                        <ListUi data={l} />
                      </Fragment>
                    ))}
                    <div className='d-flex justify-content-end'>
                      <button type='button' className='btn btn-sm btn-primary mt-4'>
                        Lihat Semua
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='mb-4 fs-5 fw-bold'>Tunjangan Kinerja (Tukin)</div>
                  <div
                    className='d-flex flex-column'
                    style={{
                      gap: '5px',
                    }}
                  >
                    {dummyData.tunjangan.map((l, i) => (
                      <Fragment key={i}>
                        <ListUi data={l} />
                      </Fragment>
                    ))}
                    <div className='d-flex justify-content-end'>
                      <button type='button' className='btn btn-sm btn-primary mt-4'>
                        Lihat Semua
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='mb-4 fs-5 fw-bold'>Uang Muka Perjalanan Dinas (UM)</div>
                  <div
                    className='d-flex flex-column'
                    style={{
                      gap: '5px',
                    }}
                  >
                    {data.map((l, i) => (
                      <Fragment key={i}>
                        <div className='form-control form-control-solid d-flex align-items-center justify-content-between'>
                          <div>
                            <div className='text-truncate text-dark fw-bold'>
                              {NullProof({input: l, params: 'usulan.no_st'})}
                            </div>
                            <div className='fs-7'>
                              {ConvertCurrency(
                                NullProof({
                                  input: l,
                                  params: 'jumlah',
                                  isLabel: false,
                                }) || 0
                              )}
                            </div>
                            <div className='fs-7'>
                              Status : {NullProof({input: l, params: 'status'})}
                            </div>
                          </div>
                        </div>
                        {/* <ListUi data={{...l, link: '/pengawasan/persiapan/form_st'}} /> */}
                      </Fragment>
                    ))}
                    {/* {dummyData.perjalanan.map((l, i) => (
                      <Fragment key={i}>
                        <ListUi data={l} />
                      </Fragment>
                    ))}
                    <div className='d-flex justify-content-end'>
                      <button type='button' className='btn btn-sm btn-primary mt-4'>
                        Lihat Semua
                      </button>
                    </div> */}
                  </div>
                </div>
                <div>
                  <div className='mb-4 fs-5 fw-bold'>Surat Pemberitahuan Tahunan (SPT) Pajak</div>
                  <div
                    className='d-flex flex-column'
                    style={{
                      gap: '5px',
                    }}
                  >
                    {dummyData.surat.map((l, i) => (
                      <Fragment key={i}>
                        <ListUi data={l} />
                      </Fragment>
                    ))}
                    <div className='d-flex justify-content-end'>
                      <button type='button' className='btn btn-sm btn-primary mt-4'>
                        Lihat Semua
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='mb-4 fs-5 fw-bold'>Form 1721 SPT PPH 21</div>
                  <div
                    className='d-flex flex-column'
                    style={{
                      gap: '5px',
                    }}
                  >
                    {dummyData.form.map((l, i) => (
                      <Fragment key={i}>
                        <ListUi data={l} />
                      </Fragment>
                    ))}
                    <div className='d-flex justify-content-end'>
                      <button type='button' className='btn btn-sm btn-primary mt-4'>
                        Lihat Semua
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='mb-4 fs-5 fw-bold'>Status Pinjaman</div>
                  <div
                    className='d-flex flex-column'
                    style={{
                      gap: '5px',
                    }}
                  >
                    {dummyData.pinjaman.map((l, i) => (
                      <Fragment key={i}>
                        <ListUi data={l} />
                      </Fragment>
                    ))}
                    <div className='d-flex justify-content-end'>
                      <button type='button' className='btn btn-sm btn-primary mt-4'>
                        Lihat Semua
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ProfilKeuanganWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <MainPage />
    </>
  )
}

export {ProfilKeuanganWrapper}
