import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'risk_register_identifikasi_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Unit is required'),
    options: {
      input: {
        props: {
          title: 'Unit',
          placeholder: 'Unit',
        },
      },
      datalist: {
        api: '/kebijakan_teknis/risk-register/:id/identifikasi',
        params: 'tujuan,proses_bisnis',
        result: '$tujuan - $proses_bisnis',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'code',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kode Risiko is required'),
    options: {
      input: {
        props: {
          title: 'Kode Risiko',
          placeholder: 'Kode Risiko',
        },
      },
      datalist: {
        api: '/kebijakan_teknis/risk-register/:id/identifikasi',
        apiInputData: {
          input: 'risk_category_list',
          params: 'kode',
        },
        params: 'risk_category_list,id,risk_register_id',
        result: '$risk_register_id',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Score / Nilai Inherent Risk',
          placeholder: 'Score / Nilai Inherent Risk',
        },
      },
      label: {
        labelType: 'both',
        className: 'fs-6',
      },
    },
  },
  {
    className: 'col-4',
    name: 'score_inherent_likelihood',
    type: 'input',
    value: '',
    validator: Yup.string().required('Likelihood wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Likelihood',
          placeholder: 'Likelihood',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'score_inherent_impact',
    type: 'input',
    value: '',
    validator: Yup.string().required('Impact wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Impact',
          placeholder: 'Impact',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'score_inherent_level',
    type: 'input',
    value: '',
    validator: Yup.string().required('Level of Risk / Tingkat Risiko wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Level of Risk / Tingkat Risiko',
          placeholder: 'Level of Risk / Tingkat Risiko',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Existing Control / Pengendalian yang ada',
          placeholder: 'Existing Control / Pengendalian yang ada',
        },
      },
      label: {
        labelType: 'both',
        className: 'fs-6',
      },
    },
  },
  {
    className: 'col-6',
    name: 'existing_control_adatidak',
    type: 'select',
    value: '',
    listData: [
      {title: 'Ada', value: 'Ada'},
      {title: 'Tidak ada', value: 'Tidak ada'},
    ],
    validator: Yup.string().required('Ada / Tidak Ada wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Ada / Tidak Ada',
          placeholder: 'Ada / Tidak Ada',
        },
      },
    },
  },
  {
    className: 'col-6',
    name: 'existing_control_memadai',
    type: 'select',
    value: '',
    listData: [
      {title: 'Memadai', value: 'Memadai'},
      {title: 'Belum memadai', value: 'Belum memadai'},
    ],
    validator: Yup.string().required('Memadai / Belum memadai wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Memadai / Belum memadai',
          placeholder: 'Memadai / Belum memadai',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'existing_control_skala',
    type: 'input',
    value: '',
    validator: Yup.number()
      .min(0, 'Minimal 0%')
      .max(100, 'Maksimal 100%')
      .required('Dijalankan / Belum dijalankan (Skala Persen) wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Dijalankan / Belum dijalankan (Skala Persen)',
          placeholder: 'Dijalankan / Belum dijalankan (Skala Persen)',
          type: 'number',
          max: 100,
          min: 0,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'existing_control_keterangan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Keterangan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Keterangan',
          placeholder: 'Keterangan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Score / Nilai Residual Risk',
          placeholder: 'Score / Nilai Residual Risk',
        },
      },
      label: {
        labelType: 'both',
        className: 'fs-6',
      },
    },
  },
  {
    className: 'col-4',
    name: 'score_residual_likelihood',
    type: 'input',
    value: '',
    validator: Yup.string().required('Likelihood wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Likelihood',
          placeholder: 'Likelihood',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'score_residual_impact',
    type: 'input',
    value: '',
    validator: Yup.string().required('Impact wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Impact',
          placeholder: 'Impact',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'score_residual_level',
    type: 'input',
    value: '',
    validator: Yup.string().required('Level of Risk / Tingkat Risiko wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Level of Risk / Tingkat Risiko',
          placeholder: 'Level of Risk / Tingkat Risiko',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Risk Treatment',
          placeholder: 'Risk Treatment',
        },
      },
      label: {
        labelType: 'both',
        className: 'fs-6',
      },
    },
  },
  {
    className: 'col-12',
    name: 'risk_threadment_opsi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Opsi Perlakuan Risiko wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Opsi Perlakuan Risiko',
          placeholder: 'Opsi Perlakuan Risiko',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'risk_threadment_deskripsi',
    type: 'component',
    value: 0,
    validator: Yup.number(),
    // validator: Yup.number().min(1, 'Sektor wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Deskripsi Tindakan Mitigasi',
          placeholder: 'Deskripsi Tindakan Mitigasi',
        },
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'deskripsi',
        type: 'input',
        value: '',
        validator: Yup.string().required('Deskripsi wajib diisi'),
        options: {
          input: {
            props: {
              title: 'Deskripsi',
              placeholder: 'Deskripsi',
            },
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Score / Nilai Target Risk After Mitigation',
          placeholder: 'Score / Nilai Target Risk After Mitigation',
        },
      },
      label: {
        labelType: 'both',
        className: 'fs-6',
      },
    },
  },
  {
    className: 'col-4',
    name: 'score_after_likelihood',
    type: 'input',
    value: '',
    validator: Yup.string().required('Likelihood wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Likelihood',
          placeholder: 'Likelihood',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'score_after_impact',
    type: 'input',
    value: '',
    validator: Yup.string().required('Impact wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Impact',
          placeholder: 'Impact',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-4',
    name: 'score_after_level',
    type: 'input',
    value: '',
    validator: Yup.string().required('Level of Risk / Tingkat Risiko wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Level of Risk / Tingkat Risiko',
          placeholder: 'Level of Risk / Tingkat Risiko',
          type: 'number',
        },
      },
    },
  },
]

export {formInputList}
