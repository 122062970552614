import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'
import {statusKepegawaianListData} from '../../../helper/KemenagFunction'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'nip',
    type: 'input',
    value: '',
    validator: Yup.string().required('NIP wajib diisi'),
    options: {
      input: {
        props: {
          title: 'NIP',
          placeholder: 'NIP',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'status_kepegawaian',
    type: 'select',
    value: '',
    listData: statusKepegawaianListData,
    validator: Yup.string().required('Status Kepegawaian wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Status Kepegawaian',
          placeholder: 'Status Kepegawaian',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'pangkat_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Pangkat wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Pangkat',
          placeholder: 'Pangkat',
        },
      },
      datalist: {
        api: '/kepegawaian/data-pangkat',
        params: 'golongan,pangkat',
        result: '$golongan | $pangkat',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'jabatan_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Jabatan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Jabatan',
          placeholder: 'Jabatan',
        },
      },
      datalist: {
        api: '/kepegawaian/data-jabatan',
        params: 'fungsional',
        result: '$fungsional',
        id: 'id',
      },
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'instansi_id',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.string().required('Instansi wajib diisi'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Instansi',
  //         placeholder: 'Instansi',
  //       }
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-instansi',
  //       params: 'kode,nama',
  //       result: '$kode | $nama',
  //       id: 'id',
  //     }
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'instansi_id',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Instansi',
  //         placeholder: 'Instansi',
  //       }
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-satuan-kerja',
  //       params: 'kode,nama_satker',
  //       result: '$kode | $nama_satker',
  //       id: 'id',
  //     }
  //   },
  // },
  {
    className: 'col-12',
    name: 'unit_kerja',
    type: 'input',
    value: '',
    validator: Yup.string().required('Unit Kerja wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Unit Kerja',
          placeholder: 'Unit Kerja',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'signature',
    type: 'input',
    value: '',
    validator: Yup.string().required('Signature wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Signature',
          placeholder: 'Signature',
          type: 'file',
          accept: '.jpeg, .jpg, .png',
        },
      },
      upload: {
        url: {
          folder: 'signature',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'wilayah',
    type: 'input',
    value: '',
    validator: Yup.string().required('Wilayah wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Wilayah',
          placeholder: 'Wilayah',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'golongan_perjadin',
    type: 'input',
    value: '',
    validator: Yup.string().required('Golongan Perjadin wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Golongan Perjadin',
          placeholder: 'Golongan Perjadin',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'level_jabatan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Level Jabatan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Level Jabatan',
          placeholder: 'Level Jabatan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_level_jabatan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode Level Jabatan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kode Level Jabatan',
          placeholder: 'Kode Level Jabatan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tipe_jabatan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tipe Jabatan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Tipe Jabatan',
          placeholder: 'Tipe Jabatan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_jabatan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode Jabatan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kode Jabatan',
          placeholder: 'Kode Jabatan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tampil_jabatan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tampil Jabatan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Tampil Jabatan',
          placeholder: 'Tampil Jabatan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'gol_ruang',
    type: 'input',
    value: '',
    validator: Yup.string().required('Golongan Ruang wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Golongan Ruang',
          placeholder: 'Golongan Ruang',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'satker_1',
    type: 'input',
    value: '',
    validator: Yup.string().required('Satker 1 wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Satker 1',
          placeholder: 'Satker 1',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_satker_1',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode satker 1 wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kode satker 1',
          placeholder: 'Kode satker 1',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'satker_2',
    type: 'input',
    value: '',
    validator: Yup.string().required('Satker 2 wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Satker 2',
          placeholder: 'Satker 2',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_satker_2',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode satker 2 wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kode satker 2',
          placeholder: 'Kode satker 2',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'satker_3',
    type: 'input',
    value: '',
    validator: Yup.string().required('Satker 3 wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Satker 3',
          placeholder: 'Satker 3',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_satker_3',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode satker 3 wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kode satker 3',
          placeholder: 'Kode satker 3',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'satker_4',
    type: 'input',
    value: '',
    validator: Yup.string().required('Satker 4 wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Satker 4',
          placeholder: 'Satker 4',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_satker_4',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode satker 4 wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kode satker 4',
          placeholder: 'Kode satker 4',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'satker_5',
    type: 'input',
    value: '',
    validator: Yup.string().required('Satker 5 wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Satker 5',
          placeholder: 'Satker 5',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_satker_5',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode satker 5 wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kode satker 5',
          placeholder: 'Kode satker 5',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'satuan_kerja',
    type: 'input',
    value: '',
    validator: Yup.string().required('Satuan Kerja wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Satuan Kerja',
          placeholder: 'Satuan Kerja',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tmt_cpns',
    type: 'input',
    value: '',
    validator: Yup.string().required('TMT CPNS wajib diisi'),
    options: {
      input: {
        props: {
          title: 'TMT CPNS',
          placeholder: 'TMT CPNS',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tmt_jabatan',
    type: 'input',
    value: '',
    validator: Yup.string().required('TMT Jabatan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'TMT Jabatan',
          placeholder: 'TMT Jabatan',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tmt_pangkat',
    type: 'input',
    value: '',
    validator: Yup.string().required('TMT Pangkat wajib diisi'),
    options: {
      input: {
        props: {
          title: 'TMT Pangkat',
          placeholder: 'TMT Pangkat',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tmt_pangkat_yad',
    type: 'input',
    value: '',
    validator: Yup.string().required('TMT Pangkat YAD wajib diisi'),
    options: {
      input: {
        props: {
          title: 'TMT Pangkat YAD',
          placeholder: 'TMT Pangkat YAD',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tmt_kgb_yad',
    type: 'input',
    value: '',
    validator: Yup.string().required('TMT KGB YAD wajib diisi'),
    options: {
      input: {
        props: {
          title: 'TMT KGB YAD',
          placeholder: 'TMT KGB YAD',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tmt_pensiun',
    type: 'input',
    value: '',
    validator: Yup.string().required('TMT Pensiun wajib diisi'),
    options: {
      input: {
        props: {
          title: 'TMT Pensiun',
          placeholder: 'TMT Pensiun',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'masakerja_tahun',
    type: 'input',
    value: '',
    validator: Yup.number().required('Masa Kerja (Tahun) wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Masa Kerja (Tahun)',
          placeholder: 'Masa Kerja (Tahun)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'masakerja_bulan',
    type: 'input',
    value: '',
    validator: Yup.number()
      .min(1, 'minimal 1')
      .max(12, 'maksimal 12')
      .required('Masa Kerja (Bulan) wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Masa Kerja (Bulan)',
          placeholder: 'Masa Kerja (Bulan)',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kode_kua',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode KUA wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kode KUA',
          placeholder: 'Kode KUA',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nsm',
    type: 'input',
    value: '',
    validator: Yup.number().required('NSM wajib diisi'),
    options: {
      input: {
        props: {
          title: 'NSM',
          placeholder: 'NSM',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'npsn',
    type: 'input',
    value: '',
    validator: Yup.number().required('NPSN wajib diisi'),
    options: {
      input: {
        props: {
          title: 'NPSN',
          placeholder: 'NPSN',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nik',
    type: 'input',
    value: '',
    validator: Yup.number().required('NIK wajib diisi'),
    options: {
      input: {
        props: {
          title: 'NIK',
          placeholder: 'NIK',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'jenis_kelamin',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Laki-Laki',
        value: 'Laki-Laki',
      },
      {
        title: 'Perempuan',
        value: 'Perempuan',
      },
    ],
    validator: Yup.string().required('Jenis Kelamin wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Jenis Kelamin',
          placeholder: 'Jenis Kelamin',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tempat_lahir',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tempat Lahir wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Tempat Lahir',
          placeholder: 'Tempat Lahir',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'tanggal_lahir',
    type: 'input',
    value: '',
    validator: Yup.date().required('Tanggal Lahir wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Tanggal Lahir',
          placeholder: 'Tanggal Lahir',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'pendidikan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Pendidikan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Pendidikan',
          placeholder: 'Pendidikan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'agama',
    type: 'input',
    value: '',
    validator: Yup.string().required('Agama wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Agama',
          placeholder: 'Agama',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'status_kawin',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Kawin',
        value: 'Kawin',
      },
      {
        title: 'Belum Kawin',
        value: 'Belum Kawin',
      },
      {
        title: 'Duda',
        value: 'Duda',
      },
      {
        title: 'Janda',
        value: 'Janda',
      },
    ],
    validator: Yup.string().required('Status Kawin wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Status Kawin',
          placeholder: 'Status Kawin',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'email',
    type: 'input',
    value: '',
    validator: Yup.string().email('Format email harus benar').required('Email wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Email',
          placeholder: 'Email',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'telepon',
    type: 'input',
    value: '',
    validator: Yup.string()
      .matches(/^[0-9]{10,13}$/, 'telepon harus diantara 10 dan 13 digit')
      .required('Telepon wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Telepon',
          placeholder: 'Telepon',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_hp',
    type: 'input',
    value: '',
    validator: Yup.string()
      .matches(/^[0-9]{10,13}$/, 'No HP harus diantara 10 dan 13 digit')
      .required('No HP wajib diisi'),
    options: {
      input: {
        props: {
          title: 'No HP',
          placeholder: 'No HP',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'alamat_1',
    type: 'input',
    value: '',
    validator: Yup.string().required('Alamat wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Alamat',
          placeholder: 'Alamat',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'alamat_2',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kecamatan wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kecamatan',
          placeholder: 'Kecamatan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kab_kota',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kabupaten/Kota wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kabupaten/Kota',
          placeholder: 'Kabupaten/Kota',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'provinsi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Provinsi wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Provinsi',
          placeholder: 'Provinsi',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_pos',
    type: 'input',
    value: '',
    validator: Yup.number().required('Kode Pos wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kode Pos',
          placeholder: 'Kode Pos',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_lokasi',
    type: 'input',
    value: '',
    validator: Yup.number().required('Kode Lokasi wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Kode Lokasi',
          placeholder: 'Kode Lokasi',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'no_rek',
    type: 'input',
    value: '',
    validator: Yup.number().required('Nomer Rekening wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nomer Rekening',
          placeholder: 'Nomer Rekening',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'nama_rek',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Rekening wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nama Rekening',
          placeholder: 'Nama Rekening',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'bank',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Bank wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nama Bank',
          placeholder: 'Nama Bank',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'img_ttd',
    type: 'input',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Tanda Tangan',
          placeholder: 'Tanda Tangan',
          type: 'file',
          accept: '.jpeg, .jpg, .png',
        },
      },
      upload: {
        url: {folder: 'img_ttd'},
      },
    },
  },
]

export {formInputList}
