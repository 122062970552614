import {DataRoutesProps} from "../../../../UserRolesValidation";
import {Katering} from "./Katering";
import {Akomodasi} from "./Akomodasi";
import {Transportasi} from "./Transportasi";
import {Masyair} from "./Masyair";
import {Pengacara} from "./Pengacara";
import {Gelang} from "./Gelang";
import {Penerbangan} from "./Penerbangan";
import {Asuransi} from "./Asuransi";
import {KateringAsrama} from "./KateringAsrama";
import {LayananEmbarkasi} from "./LayananEmbarkasi";
import {PenyiapanDokumen} from "./PenyiapanDokumen";
import {Siskohat} from "./Siskohat";
import {Batik} from "./Batik";
import {KinerjaPetugas} from "./KinerjaPetugas";
import {PengadaanSeragamPetugas} from "./PengadaanSeragamPetugas";
import {Kontrak} from "./Kontrak";

const PelaporanPengawasanHaji: DataRoutesProps = {
    title: 'Pelaporan Pengawasan Haji',
    icon: 'element-10',
    route: 'pelaporan_pengawasan_haji',
    elements: <></>,
    subroute: [
        {
            isPage: true,
            title: 'Katering',
            icon: 'element-10',
            route: 'katering',
            elements: <></>,
            subroute: Katering
        },
        {
            isPage: true,
            title: 'Akomodasi',
            icon: 'element-10',
            route: 'akomodasi',
            elements: <></>,
            subroute: Akomodasi
        },
        {
            isPage: true,
            title: 'Transportasi',
            icon: 'element-10',
            route: 'transportasi',
            elements: <></>,
            subroute: Transportasi
        },
        {
            isPage: true,
            title: 'Masyair',
            icon: 'element-10',
            route: 'masyair',
            elements: <></>,
            subroute: Masyair
        },
        {
            isPage: true,
            title: 'Pengacara',
            icon: 'element-10',
            route: 'pengacara',
            elements: <></>,
            subroute: Pengacara
        },
        {
            isPage: true,
            title: 'Gelang',
            icon: 'element-10',
            route: 'gelang',
            elements: <></>,
            subroute: Gelang
        },
        {
            isPage: true,
            title: 'Penerbangan',
            icon: 'element-10',
            route: 'penerbangan',
            elements: <></>,
            subroute: Penerbangan
        },
        {
            isPage: true,
            title: 'Asuransi',
            icon: 'element-10',
            route: 'asuransi',
            elements: <></>,
            subroute: Asuransi
        },
        {
            isPage: true,
            title: 'Katering Asrama',
            icon: 'element-10',
            route: 'katering-asrama',
            elements: <></>,
            subroute: KateringAsrama
        },
        {
            isPage: true,
            title: 'Layanan Embarkasi',
            icon: 'element-10',
            route: 'layanan-embarkasi',
            elements: <></>,
            subroute: LayananEmbarkasi
        },
        {
            isPage: true,
            title: 'Penyiapan Dokumen',
            icon: 'element-10',
            route: 'penyiapan-dokumen',
            elements: <></>,
            subroute: PenyiapanDokumen
        },
        {
            isPage: true,
            title: 'Siskohat',
            icon: 'element-10',
            route: 'siskohat',
            elements: <></>,
            subroute: Siskohat
        },
        {
            isPage: true,
            title: 'Batik',
            icon: 'element-10',
            route: 'batik',
            elements: <></>,
            subroute: Batik
        },
        {
            isPage: true,
            title: 'Kinerja Petugas',
            icon: 'element-10',
            route: 'kinerja-petugas',
            elements: <></>,
            subroute: KinerjaPetugas
        },
        {
            isPage: true,
            title: 'Pengadaan Seragam Petugas',
            icon: 'element-10',
            route: 'pengadaan-seragam-petugas',
            elements: <></>,
            subroute: PengadaanSeragamPetugas
        },
        {
            isPage: true,
            title: 'Kontrak',
            icon: 'element-10',
            route: 'kontrak',
            elements: <></>,
            subroute: Kontrak
        }
    ]
}

export default PelaporanPengawasanHaji