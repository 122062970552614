import {
  MainWrapper,
  TambahWrapper,
  DetailWrapper,
  PdfWrapper,
  UbahWrapper,
  ImportWrapper,
} from '../../../pages/keuangan/petunjuk-operasional-kegiatan/MainWrapper'
import {
  MainWrapper as MainDetailWrapper,
  TambahWrapper as TambahDetailWrapper,
  DetailWrapper as DetailDetailWrapper,
  UbahWrapper as UbahDetailWrapper,
} from '../../../pages/keuangan/petunjuk-operasional-kegiatan/detail/MainWrapper'
import {DataRoutesProps} from '../../UserRolesValidation'

const DetailPetunjukOperasionalKegiatan: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainDetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahDetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:subid',
    elements: <UbahDetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:subid',
    elements: <DetailDetailWrapper />,
    subroute: [],
  },
]

const PetunjukOperasionalKegiatan: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'pdf/:id',
    elements: <PdfWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'import',
    elements: <ImportWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'data/:id',
    elements: <></>,
    subroute: DetailPetunjukOperasionalKegiatan,
  },
]

export default PetunjukOperasionalKegiatan
