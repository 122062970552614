import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        params: 'nomor_surat,nama_kegiatan',
        result: '$nomor_surat | $nama_kegiatan',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'anggota_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Anggota is required'),
    options: {
      input: {
        props: {
          title: 'Anggota',
          placeholder: 'Anggota',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'id=$surat_tugas_id&type=anggota',
        params: 'jabatan,pegawai.nama',
        result: '$jabatan - $pegawai.nama',
        id: 'pegawai_id',
      },
      watchInput: {
        id: 'surat_tugas_id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'kode_no',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode Nomor is required'),
    options: {
      input: {
        props: {
          title: 'Kode Nomor',
          placeholder: 'Kode Nomor',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nomor_surat',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nomor Surat Perjalanan Dinas is required'),
    options: {
      input: {
        props: {
          title: 'Nomor Surat Perjalanan Dinas',
          placeholder: 'Nomor Surat Perjalanan Dinas',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tanggal',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Surat Perjalanan Dinas is required'),
    options: {
      input: {
        props: {
          title: 'Tanggal Surat Perjalanan Dinas',
          type: 'date',
          placeholder: 'Tanggal Surat Perjalanan Dinas',
        },
      },
    },
  },
]

export {formInputList}
