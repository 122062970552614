import {DataRoutesProps} from '../../UserRolesValidation'

const PemantauanTingkatLanjut: DataRoutesProps = {
  title: 'Pemantauan Tindak Lanjut',
  icon: 'element-10',
  route: 'pemantauan_tindak_lanjut',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Konsep Temuan dan Rencana Tindak Lanjut',
      icon: 'element-10',
      route: 'konsep_temuan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Laporan Tindak Lanjut Temuan Audit',
      icon: 'element-10',
      route: 'laporan_tindak_lanjut',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Laporan Pemantauan Tindak Lanjut',
      icon: 'element-10',
      route: 'laporan_pemantauan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Berita Acara Pemutakhiran Data',
      icon: 'element-10',
      route: 'berita_acara',
      elements: <></>,
      subroute: [],
    },
  ],
}

const MainRoutes: DataRoutesProps = {
  title: 'Pengelolaan Hasil Pengawasan',
  icon: 'element-10',
  route: 'pengelolaan',
  elements: <></>,
  subroute: [
    {...PemantauanTingkatLanjut},
    {
      title: 'Penatausahaan Hasil Pengawasan',
      icon: 'element-10',
      route: 'penatausahaan_hasil_pengawasan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Analisis Laporan Hasil Pengawasan',
      icon: 'element-10',
      route: 'analisis_laporan_hasil_pengawasan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pemutakhiran Data Tindak Lanjut',
      icon: 'element-10',
      route: 'pemutakhiran_data_tindak_lanjut',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Kompilasi Hasil Pengawasan',
      icon: 'element-10',
      route: 'kompilasi_hasil_pengawasan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penerbitan Profiling Kondite Pegawai',
      icon: 'element-10',
      route: 'penerbitan_profilling_kondite_pegawai',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default MainRoutes
