import {CountUp, CountUpOptions} from 'countup.js'
import {FC, useEffect, useRef, useState} from 'react'
import {ConvertCurrency, kmbGenerator} from './AppFunction'

const CountUpUi: FC<{
  value?: number
  options?: CountUpOptions
  type?: 'default' | 'currency' | 'group'
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
}> = ({value = 0, options, type = 'default', props}) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [countUp, setCountUp] = useState<CountUp>()
  const formatting = (n: number) => {
    if (type === 'currency') {
      return ConvertCurrency(n)
    } else if (type === 'group') {
      return kmbGenerator(n)
    } else {
      return n.toString()
    }
  }
  useEffect(() => {
    if (divRef.current) {
      if (countUp) {
        countUp.update(value)
      }
    }
  }, [value])
  useEffect(() => {
    if (divRef.current) {
      if (countUp) {
        countUp.start()
      } else {
        setCountUp(
          new CountUp(divRef.current, value, {
            enableScrollSpy: true,
            decimal: ',',
            separator: '.',
            formattingFn: type !== 'default' ? formatting : undefined,
            ...options,
          })
        )
      }
    }
  }, [divRef.current, countUp])
  return <span {...props} ref={divRef}></span>
}

export default CountUpUi
