import {PageDataContent} from '../../../../../helper/Model'
import {TableInputDataProps} from '../../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Form Target',
  path: '/pengawasan/pelaporan_pengawasan_internal/evalusi_pakta_integritas/form_target',
  api: '/pengawasan_internal/form-evaluasi-target',
}

const BaseBreadcrumb = [
  {
    title: 'Pelaporan Pengawasan Internal',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Form Link Evidence',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Satuan Kerja',
    },
    {
      title: 'Penandatangan Komitmen',
    },
    {
      title: 'PIC Satker',
    },
    {
      title: 'PIC Irjen',
    },
  ],
  link: {
    // tambah: {
    //     id: 'tambah',
    //     protect: 'tambah',
    //     title: 'Tambah',
    //     route: 'tambah'
    // },
    action: [
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      // {
      //     id: 'id',
      //     protect: 'ubah/:id',
      //     title: 'Ubah',
      //     route: 'ubah/:id'
      // },
      // {
      //     id: 'id',
      //     protect: 'delete',
      //     title: 'Hapus',
      //     route: ''
      // }
      {
        id: 'id',
        protect: 'pdf/:id',
        title: 'Lihat PDF',
        route: 'pdf/:id',
      },
    ],
  },
  route: {
    url: InfoData.api,
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
