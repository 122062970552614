import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {checkVerifCode, requestPassword} from '../core/_requests'
import {useDispatch, useSelector} from 'react-redux'
import {generateVerificationCode} from '../../../helper/AppFunction'

const initialValues = {
  kode: '',
}

const verificationCodeSchema = Yup.object().shape({
  kode: Yup.string().min(1, 'Minimum 1 code').max(6, 'Maximum 6 code').required('Kode wajib diisi'),
})

export function VerificationCode() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authData = useSelector((state: any) => state.auth.forgotPw)
  const [verifCode, setVerifCode] = useState<any>()
  const [verifCodeTimeout, setVerifCodeTimeout] = useState<any>({
    timer: undefined,
    value: 60,
  })
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(false)
  const formik = useFormik({
    initialValues,
    validationSchema: verificationCodeSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        // if (values.kode === verifCode.value) {
        //   setHasErrors(false)
        //   navigate('/auth/reset-password', {replace: true})
        // } else {
        //   setHasErrors(true)
        //   console.log('err!')
        // }
        setLoading(false)
        checkVerifCode(authData?.email, values.kode)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
            navigate('/auth/reset-password', {replace: true})
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  const formatTime = (data: number) => {
    const minutes = Math.floor(data / 60)
    const seconds = data % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  const regenerateCode = () => {
    formik.setFieldValue('kode', '')
    setVerifCodeTimeout((p: any) => ({
      ...p,
      value: 60,
    }))
    const kode = generateVerificationCode()
    setVerifCode({
      status: true,
      value: kode,
    })
    console.log(kode)
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (verifCodeTimeout?.value > 0) {
        setVerifCodeTimeout((p: any) => ({
          ...p,
          value: p.value - 1,
        }))
      }
    }, 1000)
    if (authData?.email && !verifCode?.status) {
      regenerateCode()
    } else if (!authData?.email) {
      navigate('/auth', {replace: true})
    }
    return () => {
      clearInterval(timer)
    }
  }, [authData])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Kode Verifikasi</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Masukkan kode verifikasi yang terkirim ke email{'\n'}
          <span className='fw-bold text-dark'>{authData?.email}</span>
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Kode Verifikasi</label>
        <input
          type='text'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('kode')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': (formik.touched.kode && formik.errors.kode) || hasErrors},
            {
              'is-valid': formik.touched.kode && !formik.errors.kode,
            }
          )}
        />
        {((formik.touched.kode && formik.errors.kode) || hasErrors) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{hasErrors ? 'Kode verifikasi salah' : formik.errors.kode}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='d-flex justify-content-end mb-4'>
        <button
          type='button'
          className='btn btn-link'
          disabled={verifCodeTimeout?.value > 0}
          onClick={() => regenerateCode()}
        >
          {verifCodeTimeout?.value > 0
            ? `Kirim Ulang (${formatTime(verifCodeTimeout?.value)})`
            : 'Kirim Ulang'}
        </button>
      </div>

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary me-4'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
