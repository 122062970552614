import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../helpers'

interface KebijakanPrivasiModalProps {
  show: boolean
  onClose: () => void
}

const KebijakanPrivasiModal: FC<KebijakanPrivasiModalProps> = ({show, onClose}) => {
  const handleClose = () => onClose()

  const datas = [
    {
      title: 'Informasi yang Kami Kumpulkan',
      description:
        'Kami dapat mengumpulkan informasi pribadi yang Anda berikan kepada kami, seperti nama, alamat email, nomor telepon, dan informasi kontak lainnya. Kami juga dapat mengumpulkan informasi non-pribadi seperti data demografis dan preferensi pengguna.',
    },
    {
      title: 'Perlindungan Data',
      description:
        'Kami mengambil langkah-langkah keamanan teknis dan organisasi yang sesuai untuk melindungi informasi pribadi Anda dari akses yang tidak sah, perubahan, atau pengungkapan.',
    },
    {
      title: 'Hak Anda',
      description:
        'Anda memiliki hak untuk mengakses, memperbaiki, atau menghapus informasi pribadi Anda yang kami simpan. Jika Anda ingin memperbarui atau menghapus informasi ini, silakan hubungi kami di [alamat email atau kontak lainnya].',
    },
    {
      title: 'Perubahan Kebijakan Privasi',
      description:
        'Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Perubahan tersebut akan diinformasikan melalui situs kami. Kami menyarankan Anda untuk secara berkala mengunjungi halaman ini untuk memeriksa perubahan terbaru.',
    },
    {
      title: 'Kontak Kami',
      description:
        'Jika Anda memiliki pertanyaan atau komentar tentang Kebijakan Privasi kami, jangan ragu untuk menghubungi kami di [alamat email atau kontak lainnya].',
    },
  ]

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-xl'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Kebijakan Privasi</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <div className='d-flex flex-column gap-3'>
            <p>
              Terima kasih telah mengunjungi [Nama Situs atau Aplikasi]. Kami berkomitmen untuk
              melindungi privasi Anda. Kebijakan Privasi ini menjelaskan bagaimana kami
              mengumpulkan, menggunakan, dan melindungi informasi pribadi Anda. Dengan menggunakan
              layanan kami, Anda menyetujui praktik yang dijelaskan dalam Kebijakan Privasi ini.
            </p>
            {datas.map((data, index) => (
              <div key={index}>
                <p className='h3'>{data.title}</p>
                <p>{data.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-light-primary' onClick={handleClose}>
            Tutup
          </button>
        </div>
      </div>
    </Modal>
  )
}

export {KebijakanPrivasiModal}
