import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'

const formInputImportList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Panduan untuk Upload',
        description: (
          <div className='fw-normal'>
            <ol>
              <li>Unduh Template Excel Data Terbaru nya diatas.</li>
              <li>Buka melalui aplikasi Excel pada komputer.</li>
              <li>Ubah data sesuai update terbaru dari anda.</li>
              <li>Upload file yang sudah diubah pada komputer anda dibawah.</li>
            </ol>
          </div>
        ),
      },
    },
  },
  {
    className: 'col-12',
    name: 'csv',
    type: 'csv',
    value: '',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Template Excel Terbaru',
          placeholder: 'Template Excel Terbaru',
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Informasi Debet',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_rek_debet',
    type: 'input',
    value: '',
    validator: Yup.number().required('Nomor Rekening Debet Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Rekening Debet',
          placeholder: 'Nomor Rekening Debet',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'nama_rek_debet',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Rekening Debet Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Rekening Debet',
          placeholder: 'Nama Rekening Debet',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Informasi Tujuan',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_rek_tujuan',
    type: 'input',
    value: '',
    validator: Yup.number().required('Nomor Rekening Tujuan Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Rekening Tujuan',
          placeholder: 'Nomor Rekening Tujuan',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'nama_rek_tujuan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Rekening Tujuan Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Rekening Tujuan',
          placeholder: 'Nama Rekening Tujuan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'jumlah',
    type: 'input',
    value: '',
    validator: Yup.number().required('Jumlah Wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah',
          placeholder: 'Jumlah',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'bank',
    type: 'input',
    value: '',
    validator: Yup.string().required('Bank Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Bank',
          placeholder: 'Bank',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'uang_harian',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Uang Harian',
          placeholder: 'Uang Harian',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'hotel',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Hotel',
          placeholder: 'Hotel',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'transport',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Transport',
          placeholder: 'Transport',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'notifikasi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Notifikasi Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Notifikasi',
          placeholder: 'Notifikasi',
        },
      },
      sidebutton: {
        title: 'Generate',
      },
    },
  },
  {
    className: 'col-12',
    name: 'pelaku_perjadin',
    type: 'input',
    value: '',
    hide: true,
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Pelaku Perjadin',
          placeholder: 'Pelaku Perjadin',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Pelaku Perjadin wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Pelaku Perjadin',
          placeholder: 'Pelaku Perjadin',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        params: 'nama,nip',
        result: '$nama | $nip',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    // disabled: true,
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'id_anggota=$pegawai_id',
        params: 'nomor_surat,nama_kegiatan,tanggal_mulai,tanggal_selesai',
        result: '$nomor_surat | $nama_kegiatan | $tanggal_mulai - $tanggal_selesai',
        id: 'id',
      },
      watchInput: {
        id: 'pegawai_id'
      }
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'surat_tugas_id',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.number().required('Surat Tugas wajib diisi'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Surat Tugas',
  //         placeholder: 'Surat Tugas',
  //       },
  //     },
  //     datalist: {
  //       api: '/pengawasan_internal/surat-tugas',
  //       query: 'id_anggota=$pegawai_id',
  //       params: 'nama_kegiatan,deskripsi_kegiatan,totalRAB',
  //       result: '$nama_kegiatan',
  //       id: 'id',
  //       type: 'table',
  //       table: {
  //         data: [
  //           {id: 'nama_kegiatan', type: 'text'},
  //           {id: 'deskripsi_kegiatan', type: 'text'},
  //           {id: 'totalRAB', type: 'currency'},
  //         ],
  //         protect: '',
  //         props: {
  //           route: {
  //             url: '/pengaturan/data_satker_unit',
  //           },
  //           headerData: [
  //             {title: 'No.', props: {className: 'min-w-50px'}},
  //             {title: 'Nama Kegiatan'},
  //             {title: 'Deskripsi Kegiatan'},
  //             {title: 'Total RAB'},
  //           ],
  //           link: {
  //             action: [
  //             ],
  //           },
  //         },
  //       },
  //     },
  //     watchInput: {
  //       id: 'pegawai_id'
  //     }
  //   }
  // },
  {
    className: 'col-12',
    name: 'tanggal_transfer',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Transfer Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Transfer',
          placeholder: 'Tanggal Transfer',
          type: 'date',
        },
      },
      sidebutton: {
        title: 'Clear',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'spd',
    type: 'input',
    value: '',
    validator: Yup.string().required('SPD (5DIGIT) Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SPD (5DIGIT)',
          placeholder: 'SPD (5DIGIT)',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'spm',
    type: 'input',
    value: '',
    validator: Yup.string().required('SPM (4DIGIT) Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SPM (4DIGIT)',
          placeholder: 'SPM (4DIGIT)',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'um_uf',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'UM',
        value: 'UM',
      },
      {
        title: 'VF',
        value: 'VF',
      },
    ],
    validator: Yup.string().required('UM / VF Wajib diisi.'),
    options: {
      select: {
        props: {
          title: 'UM / VF',
          placeholder: 'UM / VF',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nilai',
    type: 'input',
    value: '',
    validator: Yup.number().required('Nilai Wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Nilai',
          placeholder: 'Nilai',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kontrol_mak',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kontrol MAK Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Kontrol MAK',
          placeholder: 'Kontrol MAK',
        },
      },
    },
  },
]

const formUpdateList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Informasi Debet',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_rek_debet',
    type: 'input',
    value: '',
    validator: Yup.number().required('Nomor Rekening Debet Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Rekening Debet',
          placeholder: 'Nomor Rekening Debet',
          type: 'number',
          disabled: true,
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'nama_rek_debet',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Rekening Debet Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Rekening Debet',
          placeholder: 'Nama Rekening Debet',
          disabled: true,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Informasi Tujuan',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'no_rek_tujuan',
    type: 'input',
    value: '',
    validator: Yup.number().required('Nomor Rekening Tujuan Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Rekening Tujuan',
          placeholder: 'Nomor Rekening Tujuan',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'nama_rek_tujuan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Rekening Tujuan Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Rekening Tujuan',
          placeholder: 'Nama Rekening Tujuan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'jumlah',
    type: 'input',
    value: '',
    validator: Yup.number().required('Jumlah Wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah',
          placeholder: 'Jumlah',
          type: 'number',
          // disabled: true
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'bank',
    type: 'input',
    value: '',
    validator: Yup.string().required('Bank Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Bank',
          placeholder: 'Bank',
          // disabled: true
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'uang_harian',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Uang Harian',
          placeholder: 'Uang Harian',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'hotel',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Hotel',
          placeholder: 'Hotel',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'transport',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Transport',
          placeholder: 'Transport',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'notifikasi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Bank Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Notifikasi',
          placeholder: 'Notifikasi',
        },
      },
      sidebutton: {
        title: 'Generate',
      },
    },
  },
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Pelaku Perjadin wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Pelaku Perjadin',
          placeholder: 'Pelaku Perjadin',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        params: 'nama,nip',
        result: '$nama | $nip',
        id: 'id',
      },
    },
    disabled: true,
  },
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    // disabled: true,
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'id_anggota=$pegawai_id',
        params: 'nomor_surat,nama_kegiatan,tanggal_mulai,tanggal_selesai',
        result: '$nomor_surat | $nama_kegiatan | $tanggal_mulai - $tanggal_selesai',
        id: 'id',
      },
      watchInput: {
        id: 'pegawai_id'
      }
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'surat_tugas_id',
  //   type: 'datalist',
  //   value: '',
  //   disabled: true,
  //   validator: Yup.number().required('Surat Tugas wajib diisi'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Surat Tugas',
  //         placeholder: 'Surat Tugas',
  //       },
  //     },
  //     datalist: {
  //       api: '/pengawasan_internal/surat-tugas',
  //       params: 'nama_kegiatan',
  //       result: '$nama_kegiatan',
  //       id: 'id',
  //     },
  //     watchInput: {
  //       id: 'pegawai_id'
  //     },
  //   },
  // },
  {
    className: 'col-12',
    name: 'tanggal_transfer',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal Transfer Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Transfer',
          placeholder: 'Tanggal Transfer',
          type: 'date',
          // disabled: true,
        },
      },
      sidebutton: {
        title: 'Clear',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'spd',
    type: 'input',
    value: '',
    validator: Yup.string().required('SPD (5DIGIT) Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SPD (5DIGIT)',
          placeholder: 'SPD (5DIGIT)',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'spm',
    type: 'input',
    value: '',
    validator: Yup.string().required('SPM (4DIGIT) Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'SPM (4DIGIT)',
          placeholder: 'SPM (4DIGIT)',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'um_uf',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'UM',
        value: 'UM',
      },
      {
        title: 'VF',
        value: 'VF',
      },
    ],
    validator: Yup.string().required('UM / VF Wajib diisi.'),
    options: {
      select: {
        props: {
          title: 'UM / VF',
          placeholder: 'UM / VF',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nilai',
    type: 'input',
    value: '',
    validator: Yup.number().required('Nilai Wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Nilai',
          placeholder: 'Nilai',
          type: 'number',
          // disabled: true
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kontrol_mak',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kontrol MAK Wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Kontrol MAK',
          placeholder: 'Kontrol MAK',
        },
      },
    },
  },
]

export {formInputList, formInputImportList, formUpdateList}
