import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'golongan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Golongan is required'),
    options: {
      input: {
        props: {
          title: 'Golongan',
          placeholder: 'Golongan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'pangkat',
    type: 'input',
    value: '',
    validator: Yup.string().required('Pangkat is required'),
    options: {
      input: {
        props: {
          title: 'Pangkat',
          placeholder: 'Pangkat',
        },
      },
    },
  },
]

export {formInputList}
