import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfLayoutDataProps,
  PdfStyle,
  PdfStylePallete,
} from '../../../../helper/ReactPdf'
import {NullProof, getColorPallete} from '../../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  const themeColor = {
    high: '#ff0000',
    medium: '#ffc000',
    minor: '#92d050',
    low: '#00b050',
  }
  const codeColor = {
    code1: getColorPallete('#4f81bd', 0.5),
    code2: getColorPallete('#ffc000', 0.5),
    code3: getColorPallete('#ff0000', 0.5),
  }
  const arrowBuilder = (one: [x: number, y: number], two: [x: number, y: number]) => {
    const distanceW = two[0] > one[0] ? two[0] - one[0] : 0
    const distanceH = two[1] > one[1] ? two[1] - one[1] : 0
    return (
      <>
        <View
          style={{
            left: `${20 * one[0] - 1}%`,
            bottom: `${10 + 20 * (one[1] - 1)}%`,
            width: `${10 * (distanceW - 1) + 10 * distanceW + 1}%`,
            height: '5px',
            position: 'absolute',
            backgroundColor: 'black',
            borderRadius: '100%',
          }}
        ></View>
        <View
          style={{
            left: `${20 * (one[0] - 1) + 30}%`,
            bottom: `${10 + 20 * (one[1] - 1)}%`,
            width: '5px',
            height: `${10 * distanceH + 10 * (distanceH - 1) + 1}%`,
            position: 'absolute',
            backgroundColor: 'black',
            borderRadius: '100%',
          }}
        ></View>
        <View
          style={{
            left: `${20 * one[0] - 1}%`,
            bottom: `${10 + 20 * (one[1] - 1)}%`,
            width: '20px',
            height: '5px',
            position: 'absolute',
            backgroundColor: 'black',
            borderRadius: '100%',
            transform: 'rotate(45deg)',
            transformOrigin: '0 0',
          }}
        ></View>
        <View
          style={{
            left: `${20 * one[0] - 1}%`,
            bottom: `${10 + 20 * (one[1] - 1)}%`,
            width: '5px',
            height: '20px',
            position: 'absolute',
            backgroundColor: 'black',
            borderRadius: '100%',
            transform: 'rotate(45deg)',
            transformOrigin: '0 15px',
          }}
        ></View>
      </>
    )
  }
  const generateLevelRisk = (data: any[]) => {
    let arrStorage: {
      storage: string
      data: {kode: string; tipe: number}[]
      x: number
      y: number
    }[] = []
    let arrKode: {storage: string; data: {x: number; y: number; tipe: string}[]}[] = []
    for (const l of data) {
      let tempStorage = arrStorage.filter((ls) => ls?.storage === `xy${l?.x}${l?.y}`)
      let tempArrKode = arrKode.filter((ls) => ls?.storage === l?.kode)
      if (tempArrKode.length === 1) {
        let checkArrDuplicate = tempArrKode.filter(
          (ls) => ls?.data.filter((lss) => lss.tipe === l?.tipe).length === 1
        )
        if (checkArrDuplicate.length === 0) {
          arrKode[arrKode.findIndex((ls) => ls.storage === l?.kode)].data.push({
            y: l?.y,
            x: l?.x,
            tipe: l?.tipe,
          })
        }
      } else {
        arrKode.push({
          storage: l?.kode,
          data: [{y: l?.y, x: l?.x, tipe: l?.tipe}],
        })
      }
      if (tempStorage.length === 1) {
        let checkArrDuplicate = tempStorage.filter(
          (ls) =>
            ls?.data.filter((lss) => lss.tipe === l?.tipe && lss.kode === l?.kode).length === 1
        )
        if (checkArrDuplicate.length === 0) {
          arrStorage[0].data.push({kode: l?.kode, tipe: l?.tipe})
        }
      } else {
        arrStorage.push({
          storage: `xy${l?.x}${l?.y}`,
          x: l?.x,
          y: l?.y,
          data: [{kode: l?.kode, tipe: l?.tipe}],
        })
      }
    }
    const createBox = (data: any[], x: number, y: number) => {
      return (
        <View
          style={{
            position: 'absolute',
            left: `${20 * (x - 1)}%`,
            bottom: `${20 * (y - 1)}%`,
            width: '20%',
            height: '20%',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
            flexDirection: 'row',
            padding: '10px',
          }}
        >
          {data.map((l, i) => (
            <Fragment key={i}>
              <View
                style={{
                  borderRadius: '100%',
                  backgroundColor:
                    l?.tipe === 1
                      ? codeColor.code1.backgroundColor
                      : l?.tipe === 2
                      ? codeColor.code2.backgroundColor
                      : codeColor.code3.backgroundColor,
                  padding: '20px 20px',
                }}
              >
                <Text
                  style={{
                    color:
                      l?.tipe === 1
                        ? codeColor.code1.textColor
                        : l?.tipe === 2
                        ? codeColor.code2.textColor
                        : codeColor.code3.textColor,
                  }}
                >
                  {l?.kode}
                </Text>
              </View>
            </Fragment>
          ))}
        </View>
      )
    }
    return (
      <>
        {arrStorage.map((l, i) => (
          <Fragment key={i}>{createBox(l.data, l.x, l.y)}</Fragment>
        ))}
        {arrKode.map((l, i) => (
          <Fragment key={i}>
            {l.data.map((ls, is) => {
              if (l.data.length - 1 !== is) {
                return (
                  <Fragment key={is}>
                    {arrowBuilder(
                      [l.data[is].x, l.data[is].y],
                      [l.data[is + 1].x, l.data[is + 1].y]
                    )}
                  </Fragment>
                )
              }
            })}
          </Fragment>
        ))}
      </>
    )
  }
  return (
    <Document title='Dokumen'>
      {NullProof({input: data.data, params: 'identifikasi', isMap: true})?.map(
        (li: any, ii: number) => (
          <Fragment key={ii}>
            {NullProof({input: li, params: 'risk_category_list', isMap: true})?.map(
              (li2: any, ii2: number) => {
                const dataRisk = [
                  {
                    x: NullProof({input: li2, params: 'analisis.score_after_likelihood'}),
                    y: NullProof({input: li2, params: 'analisis.score_after_impact'}),
                    kode: NullProof({input: li2, params: 'analisis.code'}),
                    tipe: 1,
                  },
                  {
                    x: NullProof({input: li2, params: 'analisis.score_residual_likelihood'}),
                    y: NullProof({input: li2, params: 'analisis.score_residual_impact'}),
                    kode: NullProof({input: li2, params: 'analisis.code'}),
                    tipe: 2,
                  },
                  {
                    x: NullProof({input: li2, params: 'analisis.score_inherent_likelihood'}),
                    y: NullProof({input: li2, params: 'analisis.score_inherent_impact'}),
                    kode: NullProof({input: li2, params: 'analisis.code'}),
                    tipe: 3,
                  },
                ]
                return (
                  <Fragment key={ii2}>
                    <Page
                      size={[595.28, 1200]}
                      orientation='landscape'
                      wrap={false}
                      style={{
                        ...PdfStyle.page,
                        fontFamily: 'Arial',
                        position: 'relative',
                        gap: '5px',
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                        }}
                      >
                        <View
                          style={{
                            height: '750px',
                            minWidth: '70px',
                          }}
                        >
                          <View
                            style={{
                              fontSize: PdfStylePallete.typography.title.medium,
                              transformOrigin: '0 0',
                              left: 0,
                              bottom: '-70px',
                              transform: 'rotate(270deg)',
                              position: 'absolute',
                              height: '70px',
                              width: '750px',
                              alignItems: 'flex-end',
                              justifyContent: 'center',
                              gap: '10px',
                            }}
                          >
                            <Text
                              style={{
                                width: '100%',
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                borderWidth: '1px',
                                borderColor: 'black',
                                padding: '10px 20px',
                              }}
                            >
                              Impact / Dampak
                            </Text>
                            <Text
                              style={{
                                color: themeColor.high,
                                width: '300px',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                padding: '5px 10px',
                              }}
                            >
                              Garis Toleransi
                            </Text>
                          </View>
                        </View>
                        <View>
                          <View style={{flexDirection: 'row'}}>
                            <View
                              style={{
                                minWidth: '70px',
                                height: '150px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                                borderBottom: '5px',
                                borderBottomColor: themeColor.high,
                                borderBottomStyle: 'dashed',
                              }}
                            >
                              <View
                                style={{
                                  fontSize: PdfStylePallete.typography.title.medium,
                                  transformOrigin: '0 0',
                                  left: 0,
                                  bottom: '-70px',
                                  transform: 'rotate(270deg)',
                                  position: 'absolute',
                                  height: '70px',
                                  width: '150px',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '10px',
                                }}
                              >
                                <Text>Very High / 5</Text>
                                <Text>Sangat Tinggi</Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.medium,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderBottom: '5px',
                                borderBottomColor: themeColor.high,
                                borderBottomStyle: 'dashed',
                                borderLeft: '5px',
                                borderLeftColor: 'black',
                                borderTop: '5px',
                                borderTopColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Medium - 5
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.medium,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderBottom: '5px',
                                borderBottomColor: themeColor.high,
                                borderBottomStyle: 'dashed',
                                borderTop: '5px',
                                borderTopColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Medium - 10
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.medium,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderTop: '5px',
                                borderTopColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Medium - 15
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.high,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderTop: '5px',
                                borderTopColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                High - 20
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.high,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRight: '5px',
                                borderRightColor: 'black',
                                borderTop: '5px',
                                borderTopColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                High
                              </Text>
                            </View>
                          </View>
                          <View style={{flexDirection: 'row'}}>
                            <View
                              style={{
                                minWidth: '70px',
                                height: '150px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                              }}
                            >
                              <View
                                style={{
                                  fontSize: PdfStylePallete.typography.title.medium,
                                  transformOrigin: '0 0',
                                  left: 0,
                                  bottom: '-70px',
                                  transform: 'rotate(270deg)',
                                  position: 'absolute',
                                  height: '70px',
                                  width: '150px',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '10px',
                                }}
                              >
                                <Text>High / 4</Text>
                                <Text>Tinggi</Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.low,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: '5px',
                                borderLeftColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Low - 4
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.minor,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Minor - 8
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.medium,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: '5px',
                                borderLeftColor: themeColor.high,
                                borderLeftStyle: 'dashed',
                                borderBottom: '5px',
                                borderBottomColor: themeColor.high,
                                borderBottomStyle: 'dashed',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Medium - 12
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.high,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                High - 16
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.high,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRight: '5px',
                                borderRightColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                High - 20
                              </Text>
                            </View>
                          </View>
                          <View style={{flexDirection: 'row'}}>
                            <View
                              style={{
                                minWidth: '70px',
                                height: '150px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                              }}
                            >
                              <View
                                style={{
                                  fontSize: PdfStylePallete.typography.title.medium,
                                  transformOrigin: '0 0',
                                  left: 0,
                                  bottom: '-70px',
                                  transform: 'rotate(270deg)',
                                  position: 'absolute',
                                  height: '70px',
                                  width: '150px',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '10px',
                                }}
                              >
                                <Text>Moderate / 3</Text>
                                <Text>Sedang</Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.low,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: '5px',
                                borderLeftColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Low - 3
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.minor,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Minor - 6
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.minor,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Minor - 9
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.medium,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: '5px',
                                borderLeftColor: themeColor.high,
                                borderLeftStyle: 'dashed',
                                borderBottom: '5px',
                                borderBottomColor: themeColor.high,
                                borderBottomStyle: 'dashed',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Medium - 12
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.medium,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRight: '5px',
                                borderRightColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Medium - 15
                              </Text>
                            </View>
                          </View>
                          <View style={{flexDirection: 'row'}}>
                            <View
                              style={{
                                minWidth: '70px',
                                height: '150px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                              }}
                            >
                              <View
                                style={{
                                  fontSize: PdfStylePallete.typography.title.medium,
                                  transformOrigin: '0 0',
                                  left: 0,
                                  bottom: '-70px',
                                  transform: 'rotate(270deg)',
                                  position: 'absolute',
                                  height: '70px',
                                  width: '150px',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '10px',
                                }}
                              >
                                <Text>Minor / 2</Text>
                                <Text>Rendah</Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.low,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: '5px',
                                borderLeftColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Low - 2
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.low,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Low - 4
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.minor,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Minor - 6
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.minor,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Minor - 8
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.medium,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: '5px',
                                borderLeftColor: themeColor.high,
                                borderLeftStyle: 'dashed',
                                borderRight: '5px',
                                borderRightColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Medium - 10
                              </Text>
                            </View>
                          </View>
                          <View style={{flexDirection: 'row'}}>
                            <View
                              style={{
                                minWidth: '70px',
                                height: '150px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                              }}
                            >
                              <View
                                style={{
                                  fontSize: PdfStylePallete.typography.title.medium,
                                  transformOrigin: '0 0',
                                  left: 0,
                                  bottom: '-70px',
                                  transform: 'rotate(270deg)',
                                  position: 'absolute',
                                  height: '70px',
                                  width: '150px',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '10px',
                                }}
                              >
                                <Text>Insignificant / 1</Text>
                                <Text>Sangat Rendah</Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.low,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: '5px',
                                borderLeftColor: 'black',
                                borderBottom: '5px',
                                borderBottomColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Low - 1
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.low,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderBottom: '5px',
                                borderBottomColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Low - 2
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.low,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderBottom: '5px',
                                borderBottomColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Low - 3
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.low,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderBottom: '5px',
                                borderBottomColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Low - 4
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '150px',
                                backgroundColor: themeColor.medium,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: '5px',
                                borderLeftColor: themeColor.high,
                                borderLeftStyle: 'dashed',
                                borderBottom: '5px',
                                borderBottomColor: 'black',
                                borderRight: '5px',
                                borderRightColor: 'black',
                              }}
                            >
                              <Text style={{fontSize: PdfStylePallete.typography.title.large}}>
                                Medium - 5
                              </Text>
                            </View>
                          </View>
                          <View style={{flexDirection: 'row'}}>
                            <View
                              style={{
                                minWidth: '70px',
                                height: '70',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                              }}
                            >
                              <View
                                style={{
                                  fontSize: PdfStylePallete.typography.title.medium,
                                  transformOrigin: '0 0',
                                  left: 0,
                                  bottom: '-70px',
                                  transform: 'rotate(270deg)',
                                  position: 'absolute',
                                  height: '70px',
                                  width: '70',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '10px',
                                }}
                              ></View>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '70px',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text>Sangat Rendah / 1</Text>
                              <Text>Rare</Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '70px',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text>Rendah / 2</Text>
                              <Text>Unlikely</Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '70px',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text>Sedang / 3</Text>
                              <Text>Moderate</Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '70px',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Text>Tinggi / 4</Text>
                              <Text>Likely</Text>
                            </View>
                            <View
                              style={{
                                width: 'calc(100%/5)',
                                height: '70px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: '5px',
                                borderLeftColor: themeColor.high,
                                borderLeftStyle: 'dashed',
                              }}
                            >
                              <Text>Sangat Tinggi / 5</Text>
                              <Text>Almost Certain</Text>
                            </View>
                          </View>
                          <View style={{flexDirection: 'row'}}>
                            <View
                              style={{
                                minWidth: '70px',
                                height: '70',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                              }}
                            >
                              <View
                                style={{
                                  fontSize: PdfStylePallete.typography.title.medium,
                                  transformOrigin: '0 0',
                                  left: 0,
                                  bottom: '-70px',
                                  transform: 'rotate(270deg)',
                                  position: 'absolute',
                                  height: '70px',
                                  width: '70',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '10px',
                                }}
                              ></View>
                            </View>
                            <View
                              style={{
                                width: '100%',
                                height: '70px',
                              }}
                            >
                              <View
                                style={{
                                  width: '100%',
                                  justifyContent: 'center',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <Text
                                  style={{
                                    color: themeColor.high,
                                    width: '40%',
                                    textAlign: 'center',
                                    padding: '5px 10px',
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  Garis Toleransi
                                </Text>
                              </View>
                              <Text
                                style={{
                                  width: '100%',
                                  padding: '10px 20px',
                                  textTransform: 'uppercase',
                                  textAlign: 'center',
                                  borderWidth: '1px',
                                  borderColor: 'black',
                                }}
                              >
                                Likelihood / Kemungkinan / Probabilitas
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          position: 'absolute',
                          left: '190px',
                          top: '10px',
                          width: '960px',
                          height: '750px',
                        }}
                      >
                        <View
                          style={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                          }}
                        >
                          {generateLevelRisk(dataRisk)}
                          {/* {arrowBuilder([1, 1], [2, 2])} */}
                        </View>
                      </View>
                    </Page>
                  </Fragment>
                )
              }
            )}
          </Fragment>
        )
      )}
    </Document>
  )
}

export default PdfLayout
