/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import PdfData from './PdfData'
import {NullProof, RouterQueryParams} from '../../../../helper/AppFunction'
import {CardUi} from '../../../../helper/AppUi'
import {TableAction, TableColumn, TableInput, TableRow} from '../../../../helper/TableInput'
import {formatDate} from '../../../../helper/DateFns'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import FormInput from '../../../../helper/FormInput'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modeResponsive={TableInputData.modeResponsive}
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='No'
                    className='min-w-100px'
                  >
                    {i + 1}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Nomor SPD'
                    className='min-w-150px'
                  >
                    {NullProof({input: l, params: 'nomor_surat'})}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Tanggal SPD'
                    className='min-w-150px'
                  >
                    {formatDate({
                      date: NullProof({input: l, params: 'tanggal'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='SPD Pegawai'
                    className='min-w-150px'
                  >
                    <b>Nama : </b>
                    {NullProof({input: l, params: 'anggota.pegawai.nama'})} <br />
                    <b>Jabatan ST : </b>
                    {NullProof({input: l, params: 'anggota.jabatan'})}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Nomor Surat Tugas'
                    className='min-w-150px'
                  >
                    {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Anggota Surat Tugas'
                    className='min-w-250px'
                  >
                    {NullProof({input: l, params: 'surat_tugas.kepada', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div>{`${i1 + 1}. ${NullProof({
                            input: l1,
                            params: 'pegawai.nama',
                          })}`}</div>
                        </Fragment>
                      )
                    )}
                  </TableColumn>
                  <TableColumn
                    modeResponsive={TableInputData.modeResponsive}
                    label='Tujuan'
                    className='min-w-400px'
                  >
                    {NullProof({input: l, params: 'surat_tugas.tujuan', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div>
                            {`${i1 + 1}. `}
                            <b>Wilayah:</b>{' '}
                            {`${NullProof({
                              input: l1,
                              params: 'provinsi',
                            })} - ${NullProof({
                              input: l1,
                              params: 'kota',
                            })}`}
                          </div>
                          <div>
                            <b>Satuan Kerja:</b>{' '}
                            {`${NullProof({
                              input: l1,
                              params: 'satker.kode',
                            })} - ${NullProof({
                              input: l1,
                              params: 'satker.nama_satker',
                            })}`}
                          </div>
                        </Fragment>
                      )
                    )}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={`${InfoData.api}/${query.id}`}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
        PdfData={PdfData}
      />
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}
const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Nomor SPD'}}>
            {NullProof({input: data, params: 'nomor_surat'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kode Nomor'}}>
            {NullProof({input: data, params: 'kode_no'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nomor Surat Tugas'}}>
            {NullProof({input: data, params: 'surat_tugas.nomor_surat'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Pejabat Pembuat Komitmen'}}>
            <div>{NullProof({input: data, params: 'surat_tugas.ppk.nama'})}</div>
            <div>NIP: {NullProof({input: data, params: 'surat_tugas.ppk.nip'})}</div>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{value: 'Nama pegawai yang diperintah', props: {className: 'mb-2'}}}
          >
            {NullProof({input: data, params: 'surat_tugas.kepada', isMap: true})
              ?.filter((ls: any) => ls?.jabatan == 'Ketua Tim')
              ?.map((l: any, i: number) => (
                <Fragment key={i}>
                  <TableInputDetailColumn hideDivider title={{props: {className: 'mb-2'}}}>
                    <div className='d-flex text-capitalize'>
                      {NullProof({input: l, params: 'pegawai.nama'})?.toLowerCase()}
                    </div>
                    <div className='d-flex text-capitalize'>
                      NIP: {NullProof({input: l, params: 'pegawai.nip'}).toLowerCase()}
                    </div>
                  </TableInputDetailColumn>
                </Fragment>
              ))}
          </TableInputDetailColumn>
          <TableInputDetailColumn isResponsive='desktop' title={{value: ''}}>
            <TableInputDetailColumn hideDivider title={{value: 'Pangkat dan Golongan'}}>
              {NullProof({input: data, params: 'anggota.pegawai.pangkat.pangkat'})} /{' '}
              {NullProof({
                input: data,
                params: 'anggota.pegawai.jabatan.eselon',
                label: 'Golongan',
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Jabatan'}}>
              {NullProof({input: data, params: 'anggota.pegawai.jabatan.fungsional'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Tingkat Biaya Perjalanan Dinas'}}>
              {NullProof({input: data, params: 'tingkat_biaya_dinas'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Maksud Perjalanan Dinas'}}>
            {NullProof({input: data, params: 'surat_tugas.deskripsi_kegiatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Alat angkutan yang dipergunakan'}}>
            {NullProof({input: data, params: 'surat_tugas.alat_angkutan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn isResponsive='desktop' title={{value: ''}}>
            <TableInputDetailColumn hideDivider title={{value: 'Tempat berangkat'}}>
              {NullProof({input: data, params: 'surat_tugas.tempat_berangkat', isLabel: false}) ||
                'Jakarta'}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              title={{value: 'Tempat tujuan', props: {className: 'mb-2'}}}
            >
              {NullProof({input: data, params: 'surat_tugas.tujuan', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn hideDivider title={{props: {className: 'mb-2'}}}>
                      <div className='d-flex gap-2 text-capitalize'>
                        <div>{NullProof({input: l, params: 'provinsi'})?.toLowerCase()}</div>
                        <div>-</div>
                        <div>{NullProof({input: l, params: 'kota'})?.toLowerCase()}</div>
                      </div>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn isResponsive='desktop' title={{value: ''}}>
            <TableInputDetailColumn hideDivider title={{value: 'Lama perjalanan dinas'}}>
              {NullProof({input: data, params: 'anggota.hari', isLabel: false}) || 0} Hari
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Tanggal berangkat'}}>
              {NullProof({input: data, params: 'surat_tugas.tanggal_mulai', type: 'date'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Tanggal harus kembali'}}>
              {NullProof({input: data, params: 'surat_tugas.tanggal_selesai', type: 'date'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Anggota', props: {className: 'mb-2'}}}>
            {NullProof({input: data, params: 'surat_tugas.kepada', isMap: true})
              ?.filter(
                (ls: any) =>
                  ls?.jabatan !== 'Penanggung Jawab' &&
                  ls?.jabatan !== 'Ketua Tim' &&
                  ls?.jabatan !== 'Pengendali Mutu'
              )
              .map((l: any, i: number) => (
                <Fragment key={i}>
                  <TableInputDetailColumn hideDivider title={{props: {className: 'mb-2'}}}>
                    <div className='d-flex'>
                      {i + 1}. {NullProof({input: l, params: 'pegawai.nama'})}
                    </div>
                    <div className='d-flex'>
                      {`Pangkat / Golongan: ${NullProof({
                        input: l,
                        params: 'pegawai.pangkat.pangkat',
                      })}
                      (${NullProof({input: l, params: 'pegawai.pangkat.golongan'})})`}
                    </div>
                    <div className='d-flex'>
                      Jabatan: {NullProof({input: l, params: 'jabatan'})}
                    </div>
                  </TableInputDetailColumn>
                </Fragment>
              ))}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Pembebanan anggaran', props: {className: 'mb-2'}}}
          >
            <TableInputDetailColumn hideDivider title={{value: 'Instansi/Proyek'}}>
              {NullProof({input: data, params: 'instansi'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Mata anggaran'}}>
              {NullProof({input: data, params: 'surat_tugas.mak'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Keterangan Lain-lain'}}>
            {`${
              formatDate({
                date: NullProof({input: data, params: 'surat_tugas.tanggal'}),
                dateFormat: 'dd MMMM yyyy',
              }) || '(Tanggal)'
            } - ${NullProof({input: data, params: 'nomor', label: 'Nomor'})}`}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, UbahWrapper, PdfWrapper, TambahWrapper, DetailWrapper}
