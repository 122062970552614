import {PageDataContent} from "../../../../../helper/Model";
import {TableInputDataProps} from "../../../../../helper/TableInput";
import {TableInputDetailDataProps} from "../../../../../helper/TableInputDetail";

interface PageDataProps {
    [key: string]: {
        page: PageDataContent
    }
}

export const InfoData = {
    title: 'Data Katering Asrama',
    path: '/pengawasan/pelaporan_pengawasan_internal/pelaporan_pengawasan_haji/katering-asrama',
    api: '/pengawasan_internal/katering-asrama'
}

const BaseBreadcrumb = [
    {
        title: 'Pelaporan Pengawasan Internal',
        path: '#',
        isSeparator: false,
        isActive: true
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false
    },
    {
        title: 'Pelaporan Pengawasan Haji',
        path: '#',
        isSeparator: false,
        isActive: true
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false
    }
]

const MainBreadcrumb = [
    {
        title: InfoData.title,
        path: InfoData.path,
        isSeparator: false,
        isActive: true
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false
    }
]

export const TableInputData: TableInputDataProps = {
    headerData: [
        {
            title: 'No',
            props: {
                className: 'mw-100px',
            }
        },
        {
            title: 'Tanggal Pemantauan'
        },
        {
            title: 'Pemantau'
        },
        {
            title: 'Dibuat pada'
        }
    ],
    link: {
        tambah: {
            id: 'tambah',
            protect: 'tambah',
            title: 'Tambah',
            route: 'tambah'
        },
        action: [
            {
                id: 'id',
                protect: 'detail/:id',
                title: 'Detail',
                route: 'detail/:id'
            },
            {
                id: 'id',
                protect: 'ubah/:id',
                title: 'Ubah',
                route: 'ubah/:id'
            },
            {
                id: 'id',
                protect: 'delete',
                title: 'Hapus',
                route: ''
            }
        ]
    },
    route: {
        url: InfoData.api
    }
}

export const TableInputDetailData: TableInputDetailDataProps = {
    route: {
        url: `${InfoData.api}/:id`
    }
}

const PageData: PageDataProps = {
    semua: {
        page: {
            title: `${InfoData.title}`,
            breadcrumb: {
                title: `${InfoData.title}`,
                data: BaseBreadcrumb
            }
        }
    },
    detail: {
        page: {
            title: `${InfoData.title}`,
            breadcrumb: {
                title: 'Detail',
                data: [...BaseBreadcrumb, ...MainBreadcrumb]
            }
        }
    },
    tambah: {
        page: {
            title: `Tambah ${InfoData.title}`,
            breadcrumb: {
                title: `Tambah ${InfoData.title}`,
                data: [...BaseBreadcrumb, ...MainBreadcrumb]
            }
        }
    },
    ubah: {
        page: {
            title: `Ubah ${InfoData.title}`,
            breadcrumb: {
                title: `Ubah ${InfoData.title}`,
                data: [...BaseBreadcrumb, ...MainBreadcrumb]
            }
        }
    }
}

export default PageData