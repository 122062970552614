import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../helper/template/FormInputTemplate'
import {formInputList as inputList} from '../pengaturan/penyusutan-akhir/InputLayout'

const filterFormInputList: FormInputProps[] = [
  ...inputLayoutBuilder({
    input: [{template: 'search'}, {template: 'status'}],
  }),
]

const formInputList: FormInputProps[] = [
  {
    name: 'nama',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Klasifikasi Berkas',
          placeholder: 'Nama Klasifikasi Berkas',
        },
      },
    },
  },
  {
    name: 'kode',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Klasifikasi Berkas',
          placeholder: 'Kode Klasifikasi Berkas',
        },
      },
    },
  },
  {
    name: 'retensi_aktif',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Retensi Aktif',
          placeholder: 'Retensi Aktif',
        },
      },
    },
  },
  {
    name: 'retensi_inaktif',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Retensi Inaktif',
          placeholder: 'Retensi Inaktif',
        },
      },
    },
  },
  {
    name: '',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Penyusutan',
        labelType: 'both',
      },
    },
  },
  {
    name: 'penyusutan_akhir_id',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Penyusutan Akhir',
          placeholder: 'Penyusutan Akhir',
        },
      },
      datalist: {
        api: '/tata_usaha/penyusutan-akhir',
        id: 'id',
        params: 'nama',
        result: '$nama',
        type: 'table',
        table: {
          protect: '',
          data: [
            {
              id: 'nama',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/tata_usaha/penyusutan-akhir',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Nama',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputList,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputList,
                },
              ],
            },
          },
        },
      },
    },
  },
]

export {formInputList, filterFormInputList}
