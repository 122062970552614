import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { PageTitle } from '../../../../../../../_metronic/layout/core'
import PageData, { InfoData } from '../PageData'
import { CardUi } from '../../../../../../helper/AppUi'
import FormInput from '../../../../../../helper/FormInput'
import { formInputList } from '../InputLayout'
import { RouterQueryParams } from '../../../../../../helper/AppFunction'

const UbahWrapper: FC = () => {
	const query = useParams()
	return (
		<>
			<PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
				{PageData.ubah.page.breadcrumb.title}
			</PageTitle>
			<CardUi title={PageData.ubah.page.title}>
				<FormInput
					input={formInputList}
					route={{
						url: RouterQueryParams(`${InfoData.api}/:id`, query),
						type: 'put',
						redirect: true,
					}}
				/>
			</CardUi>
		</>
	)
}

export default UbahWrapper