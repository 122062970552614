import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'nama',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: '',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'deskripsi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Deskripsi wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Deskripsi',
          placeholder: '',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'jumlahtujuan',
    type: 'input',
    value: '',
    validator: Yup.number().required('Jumlah Tujuan wajib diisi'),
    options: {
      input: {
        props: {
          type: 'number',
          title: 'Jumlah Tujuan',
          placeholder: '',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'unit',
    type: 'input',
    value: '',
    validator: Yup.string().required('Unit wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Unit',
          placeholder: '',
        },
      },
    },
  },
]

export {formInputList}
