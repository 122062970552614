import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'
import {createFormObjectList, listVarProps} from '../../../../../../helper/AppFunction'

export const listVar: listVarProps[] = [
  {
    isLabel: true,
    number: 'I',
    name: '',
    title: 'Penugasan Perencanaan',
  },
  {
    number: 'A',
    group: 'A',
    name: 'penugasan',
    title: 'Apakah dibuat Kartu Penugasan?',
  },
  {
    number: 'B',
    group: 'B',
    name: 'penugasan',
    title: 'Apakah dikembangkan Tujuan Pengawasan, Lingkup Pekerjaan, Penaksiran Risiko?',
  },
  {
    isLabel: true,
    number: 'C',
    name: '',
    title: 'Apakah sudah diperoleh',
  },
  {
    number: '1',
    group: 'C',
    name: 'penugasan',
    title: 'Misi, tujuan dan rencana organisasi',
  },
  {
    number: '2',
    group: 'C',
    name: 'penugasan',
    title: 'Informasi organisasi',
  },
  {
    number: '3',
    group: 'C',
    name: 'penugasan',
    title: 'Kertas Kerja Terakhir',
  },
  {
    number: '4',
    group: 'C',
    name: 'penugasan',
    title: 'File Permanen',
  },
  {
    number: '5',
    group: 'C',
    name: 'penugasan',
    title: 'Data pembanding',
  },
  {
    number: '6',
    group: 'C',
    name: 'penugasan',
    title: 'Data Anggaran',
  },
  {
    number: '7',
    group: 'C',
    name: 'penugasan',
    title: 'Literatur teknis',
  },
  {
    number: 'D',
    group: 'D',
    name: 'penugasan',
    title: 'Adakah perubahan pelaksana dari rencana semula?',
  },
  {
    number: 'E',
    group: 'E',
    name: 'penugasan',
    title:
      'Jika ada perubahan apakah sudah dibuat Memo persetujuan dan sudah dilampirkan ke kartu penugasan di Pengendali Mutu dan atau Inspektur Wilayah?',
  },
  {
    number: 'F',
    group: 'F',
    name: 'penugasan',
    title: 'Apakah sudah dibuat rapat koordinasi?',
  },
  {
    number: 'G',
    group: 'G',
    name: 'penugasan',
    title: 'Apakah sudah dibuat ringkasannya dan telah didistribusikan?',
  },
  {
    isLabel: true,
    number: 'H',
    name: '',
    title:
      'Apakah sebelum dibuat program pengawasan, telah dilaksanakan langkah-langkah sebagai berikut',
  },
  {
    number: '1',
    group: 'H',
    name: 'penugasan',
    title: 'Melakukan persiapan survey pendahuluan',
  },
  {
    number: '2',
    group: 'H',
    name: 'penugasan',
    title: 'Melakukan survey pendahuluan',
  },
  {
    number: '3',
    group: 'H',
    name: 'penugasan',
    title: 'Membuat ikhtisar hasil survey',
  },
  {
    number: 'I',
    group: 'I',
    name: 'penugasan',
    title:
      'Apakah program pengawasan telah mengacu pada program baku dan hasil pengumpulan informasi?',
  },
  {
    number: 'J',
    group: 'J',
    name: 'penugasan',
    title: 'Apakah program pengawasan telah mendapatkan persetujuan Pengendali Teknis?',
  },
  {
    number: 'K',
    group: 'K',
    name: 'penugasan',
    title: 'Apakah tahapan pekerjaan KKA telah sesuai dengan rencana waktunya',
  },
  {
    isLabel: true,
    number: 'K',
    name: 'penugasan',
    title: 'Apakah tahapan pekerjaan KKA telah sesuai dengan rencana waktunya',
  },
  {
    number: '1',
    group: 'K',
    name: 'penugasan',
    title: 'Penetapan tujuan, lingkup dan penaksiran risiko',
  },
  {
    number: '2',
    group: 'K',
    name: 'penugasan',
    title: 'Pengumpulan informasi awal',
  },
  {
    number: '3',
    group: 'K',
    name: 'penugasan',
    title: 'Penetapan tim pengawasan',
  },
  {
    number: '4',
    group: 'K',
    name: 'penugasan',
    title: 'Rapat pendahuluan',
  },
  {
    number: '5',
    group: 'K',
    name: 'penugasan',
    title: 'Survey pendahuluan',
  },
  {
    number: '6',
    group: 'K',
    name: 'penugasan',
    title: 'Penulisan program pengawasan',
  },
  {
    number: '7',
    group: 'K',
    name: 'penugasan',
    title: 'Persetujuan program pengawasan',
  },
  {
    number: 'L',
    group: 'L',
    name: 'penugasan',
    title: 'Apakah kertas kerja pengawasan untuk tahap perencanaan telah seleai dikerjakan?',
  },
  {
    isLabel: true,
    number: 'II',
    name: '',
    title: 'Pelaksanaan (Pengujian dan Audit)',
  },
  {
    number: 'A',
    group: 'A',
    name: 'pelaksanaan',
    title: 'Apakah dilakukan penjelasan penugasan kepada Anggota Tim?',
  },
  {
    number: 'B',
    group: 'B',
    name: 'pelaksanaan',
    title: 'Apakah Audit dilakukan sesuai program pengawasan',
  },
  {
    number: 'C',
    group: 'C',
    name: 'pelaksanaan',
    title: 'Apakah dilakukan review terhadap kertas kerja Anggota Tim dan Ketua Tim?',
  },
  {
    number: 'D',
    group: 'D',
    name: 'pelaksanaan',
    title: 'Apakah hasil review ditindaklanjuti oleh Anggota Tim dan Ketua Tim?',
  },
  {
    number: 'E',
    group: 'E',
    name: 'pelaksanaan',
    title: 'Apakah KKA telah didokumentasikan dan disimpan pada tempat yang telah disiapkan?',
  },
  {
    number: 'F',
    group: 'F',
    name: 'pelaksanaan',
    title: 'Apakah KKA direview oleh Pengendali Teknis?',
  },
  {
    isLabel: true,
    number: 'G',
    name: '',
    title: 'Apakah KKA dibahas?',
  },
  {
    group: 'G',
    name: 'pelaksanaan',
    title: 'di tim',
  },
  {
    group: 'G',
    name: 'pelaksanaan',
    title: 'dengan Pengendali Teknis',
  },
  {
    group: 'G',
    name: 'pelaksanaan',
    title: 'dengan Pengendali Mutu dan atau Inspektur Wilayah',
  },
  {
    isLabel: true,
    number: 'H',
    name: '',
    title:
      'Apakah dilakukan penelaah kesesuaian KKA dan isinya dengan standar Audit/Program pengawasan yang berlaku?',
  },
  {
    group: 'H',
    name: 'pelaksanaan',
    title: 'di tim',
  },
  {
    group: 'H',
    name: 'pelaksanaan',
    title: 'dengan Pengendali Teknis',
  },
  {
    group: 'H',
    name: 'pelaksanaan',
    title: 'dengan Pengendali Mutu dan atau Inspektur Wilayah',
  },
  {
    number: 'I',
    group: 'I',
    name: 'pelaksanaan',
    title: 'Apakah disusun temuan hasil pengawasan dan dibuat rekomendasi perbaikan?',
  },
  {
    number: 'J',
    group: 'J',
    name: 'pelaksanaan',
    title:
      'Apakah dilakukan pembahasan simpulan hasil pengawasan di tim dengan Pengendali Teknis dan Pengendali Mutu dan atau Inspektur Wilayah?',
  },
  {
    number: 'K',
    group: 'K',
    name: 'pelaksanaan',
    title:
      'Apakah dilakukan komunikasi temuan dan rekomendasi perbaikan dengan objek pengawasan/mitra kerja?',
  },
  {
    number: 'L',
    group: 'L',
    name: 'pelaksanaan',
    title:
      'Apakah ada komitmen tindak lanjut dari objek pengawasan/mitra kerja yang dituangkan dalam Berita Acara Kesepakatan atas rekomendasi yang diberikan?',
  },
  {
    isLabel: true,
    number: 'III',
    name: '',
    title: 'Penyelesaian Laporan',
  },
  {
    isLabel: true,
    number: 'A',
    name: '',
    title: 'Ringkasan Pimpinan',
  },
  {
    group: 'A',
    name: 'penyelesaian',
    title:
      'Ringkasan pimpinan memuat overview ringkas atas objek pengawasan/mitra kerja/instansi (Kementrian, Lembaga), Pemda, Badan Usaha, Program, Kegiatan, tujuan pengawasan, ruang lingkup, referensi atas kriteria pengawasan, metodologi pengawasan, dan simpulan hasil pengawasan atas setiap tujuan pengawasan',
  },
  {
    isLabel: true,
    number: 'B',
    name: '',
    title: 'Badan Laporan',
  },
  {
    group: 'B',
    name: 'penyelesaian',
    title: 'Kecukupan informasi latar belakang objek',
  },
  {
    number: '1',
    group: 'B',
    name: 'penyelesaian',
    title:
      'Pengawasan/mitra kerja, instansi (Kementrian, Lembaga), Pemda, Badan Usaha, Program, Kegiatan',
  },
  {
    number: '2',
    group: 'B',
    name: 'penyelesaian',
    title: 'Tujuan pengawasan dan kriteria yang berkaitan',
  },
  {
    number: '3',
    group: 'B',
    name: 'penyelesaian',
    title: 'Ruang lingkup Audit sudah dinyatakan secara jelas',
  },
  {
    number: '4',
    group: 'B',
    name: 'penyelesaian',
    title:
      'Jadwal pengawasan, metodologi, standar Audit/pengawasan yang diacu. Jika ada standar yang tidak diikuti, penjelasan yang memadai telah dibuat',
  },
  {
    number: '5',
    group: 'B',
    name: 'penyelesaian',
    title:
      'Hasil temuan berkaitan dengan tujuan dan kriteria pengawasan telah diperoleh untuk mencapai simpulan pengawasan',
  },
  {
    number: '6',
    group: 'B',
    name: 'penyelesaian',
    title: 'Setiap temuan berisi pernyataan kondisi, kriteria, penyebab, dampak dan rekomendasi',
  },
  {
    number: '7',
    group: 'B',
    name: 'penyelesaian',
    title: 'Bukti yang cukup dan relevan telah dikumpulkan untuk mendukung setiap temuan',
  },
  {
    number: '8',
    group: 'B',
    name: 'penyelesaian',
    title: 'Temuan yang bisa dikuantifikasikan telah dihitung secara akurat',
  },
  {
    number: '9',
    group: 'B',
    name: 'penyelesaian',
    title:
      'Rekomendasi yang diberikan telah mengikuti alur logis temuan dan penyebab, jelas dan dapat dilaksanakan (cost-effective), ditujukan kepada pihak yang berkompeten',
  },
  {
    number: '10',
    group: 'B',
    name: 'penyelesaian',
    title:
      'Simpulan telah disajikan untuk setiap tujuan pengawasan dan telah didukung dengan bukti yang cukup dan relevan',
  },
  {
    number: '11',
    group: 'B',
    name: 'penyelesaian',
    title: 'Lampiran-lampiran yang disajikan mendukung laporan',
  },
  {
    isLabel: true,
    number: 'C',
    name: '',
    title: 'Format Laporan',
  },
  {
    number: '1',
    group: 'C',
    name: 'penyelesaian',
    title:
      'Daftar isi yang menggambarkan struktur laporan dan judul yang sama dengan judul pada halaman badan',
  },
  {
    number: '2',
    group: 'C',
    name: 'penyelesaian',
    title: 'Judul dan huruf yang konsisten',
  },
  {
    number: '3',
    group: 'C',
    name: 'penyelesaian',
    title: 'Bagan dan gambar telah dirujuk secara memadai dalam badan laporan',
  },
  {
    number: '4',
    group: 'C',
    name: 'penyelesaian',
    title: 'Struktur kalimat dan paragraf yang mudah dipahami',
  },
  {
    number: '5',
    group: 'C',
    name: 'penyelesaian',
    title: 'Singkatan-singkatan telah didefinisikan',
  },
  {
    number: '6',
    group: 'C',
    name: 'penyelesaian',
    title: 'Bahasa dan terminologi yang mudah dipahami',
  },
  {
    number: '7',
    group: 'C',
    name: 'penyelesaian',
    title: 'Tata bahasa dan penulisan kata yang tepat',
  },
  {
    number: '8',
    group: 'C',
    name: 'penyelesaian',
    title: 'Secara keseluruhan, laporan sudah jelas dan tepat',
  },
  {
    isLabel: true,
    number: 'D',
    name: '',
    title: 'Lain-Lain',
  },
  {
    isLabel: true,
    number: '1',
    name: '',
    title: 'Penyusunan telah melalui proses Audit yang memadai',
  },
  {
    number: 'a',
    group: 'D',
    name: 'penyelesaian',
    title: 'Pengendali Teknis',
  },
  {
    number: 'b',
    group: 'D',
    name: 'penyelesaian',
    title: 'Pengendali Mutu dan atau Inspektur Wilayah',
  },
  {
    number: '2',
    group: 'D',
    name: 'penyelesaian',
    title: 'Distribusi laporan telah sesuai ketentuan',
  },
]

const formInputTableList: FormInputProps[] = [
  {
    className: 'col-12 col-md-4',
    name: 'pilihan_ketua',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Tidak',
        value: 'Tidak',
      },
      {
        title: 'Ya',
        value: 'Ya',
      },
    ],
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      select: {
        props: {
          title: 'Ketua Tim',
          placeholder: 'Ketua Tim',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'pilihan_daltu',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Tidak',
        value: 'Tidak',
      },
      {
        title: 'Ya',
        value: 'Ya',
      },
    ],
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      select: {
        props: {
          title: 'Auditor Pengendali Mutu',
          placeholder: 'Auditor Pengendali Mutu',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'pilihan_dalnis',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Tidak',
        value: 'Tidak',
      },
      {
        title: 'Ya',
        value: 'Ya',
      },
    ],
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      select: {
        props: {
          title: 'Auditor Pengendali Teknis',
          placeholder: 'Auditor Pengendali Teknis',
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'byuser=true',
        params: 'nomor_surat,nama_kegiatan',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nomor Surat : </span>
              <span>$nomor_surat</span>
            </div>
            <div>
              <span className='fw-bold'>Nama Kegiatan : </span>
              <span>$nama_kegiatan</span>
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Auditi',
          placeholder: 'Auditi',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12 px-0',
    name: 'detail',
    type: 'component',
    validator: Yup.string().required('Detail mohon untuk diisi.'),
    value: 1,
    options: {
      component: {
        isObject: true,
      },
    },
    component: createFormObjectList(listVar, formInputTableList),
  },
]

export {formInputList}
