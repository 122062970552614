/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useNavigate, useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {getData, postData} from '../../../helper/FormAxios'
import Swal from 'sweetalert2'
import {ButtonLoading, CardUi} from '../../../helper/AppUi'
import useReduxGlobal from '../../../helper/ReduxGlobal'
import {TableAction, TableColumn, TableInput, TableRow} from '../../../helper/TableInput'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../helper/AppFunction'
import FormInput from '../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'

const MainWrapper: FC = () => {
  const reduxGlobal = useReduxGlobal()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          onFeedback={async(e) => {
            console.log("eeee", e)
            if (e?.protect === ':id/setup_menu') {
              reduxGlobal.post({name: 'pagedata', data: {api: e?.data}})
            }
            // if(e?.protect === 'duplicate') {
            //   const result = await postData(e?.data, `${InfoData.api}/duplicate`)
            //   if(result) {
            //     getData('', InfoData.api)
            //     Swal.fire({
            //       icon: 'success',
            //       title: 'Berhasil menduplikasi data',
            //     })
            //   }

            // }
            
          }}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Nama' className='min-w-150px'>
                    {NullProof({input: l, params: 'name'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Nama'}}>
            {NullProof({input: data, params: 'name'})}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

type SetupMenuUiProps = {
  action?: SetupMenuUiProps[]
  sub?: SetupMenuUiProps[]
  bootstrapIcon?: string
  is_granted: boolean
  status?: boolean
  keenthemesIcon?: string
  menu_id: number
  name: string
  route: string
  route_seo: string
  sectionTitle?: string
  heading?: string
}

const SetupMenuUi: FC<{
  data: SetupMenuUiProps
  dataParent?: SetupMenuUiProps
  index: number
  post?: any
}> = ({data, index, post, dataParent}) => {
  const updatedData = () => {
    post()
  }
  return (
    <>
      <div className='pb-6 border-start border-primary ps-4 position-relative'>
        <div
          className='position-absolute rounded-circle bg-primary'
          style={{left: '-6px', top: '7px', width: '10px', height: '10px'}}
        ></div>
        <div className='fs-4 fw-bold mb-4'>{data.name}</div>
        <div className='row g-4'>
          {data.sub?.length === 0 && (
            <div className='col-6 col-md-4'>
              <div className='form-check d-flex align-items-center gap-2'>
                <input
                  name={`${data.name}_${index}`}
                  defaultChecked={data.is_granted}
                  type='checkbox'
                  className='form-check-input form-check-input-lg mb-3 mb-lg-0 cursor-pointer h-25px w-25px'
                  style={{minWidth: '25px', minHeight: '25px'}}
                  onChange={(val) => {
                    data.is_granted = val.target.checked
                    updatedData()
                  }}
                />
                <label
                  className='form-check-label text-dark cursor-pointer user-select-none'
                  htmlFor={`${data.name}_${index}`}
                >
                  View
                </label>
              </div>
            </div>
          )}
          {(data.action?.length || 0) > 0 && (
            <>
              {data.action?.map((l, i) => (
                <Fragment key={i}>
                  <div className='col-6 col-md-4'>
                    <div className='form-check d-flex align-items-center gap-2'>
                      <input
                        name={`${l.name}_${i}`}
                        defaultChecked={l.is_granted}
                        type='checkbox'
                        className='form-check-input form-check-input-lg mb-3 mb-lg-0 cursor-pointer h-25px w-25px'
                        style={{minWidth: '25px', minHeight: '25px'}}
                        onChange={(val) => {
                          l.is_granted = val.target.checked
                          updatedData()
                        }}
                      />
                      <label
                        className='form-check-label text-dark cursor-pointer user-select-none'
                        htmlFor={`${l.name}_${i}`}
                      >
                        {l.name}
                      </label>
                    </div>
                  </div>
                </Fragment>
              ))}
            </>
          )}
        </div>
        {(data.sub?.length || 0) > 0 && (
          <div className='ps-4 mt-2'>
            {data.sub?.map((l, i) => (
              <Fragment key={i}>
                <SetupMenuUi data={l} index={i} post={post} dataParent={data} />
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

const SetupMenuWrapper: FC = () => {
  const api = InfoData.setupmenu
  const navigate = useNavigate()
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const reduxGlobal = useReduxGlobal()
  const [pageData, setPageData] = useState<any>({
    description: reduxGlobal?.get?.pagedata?.api?.name,
  })
  const loadInfoPage = async () => {
    if (!reduxGlobal?.get?.pagedata?.api?.name) {
      const apiPage = await getData('', RouterQueryParams(`${InfoData.api}/:id`, query))
      if (apiPage?.status) {
        setPageData({description: NullProof({input: apiPage?.data, params: 'name'})})
      }
    }
  }
  const loadData = async () => {
    if (api) {
      setLoading(true)
      setError(false)
      loadInfoPage()
      const result: any = await getData('', RouterQueryParams(`${api}/:id`, query))
      if (result.status) {
        const resultData = result?.data.sort((a: any, b: any) => a?.sorting - b?.sorting)
        setData(resultData)
      } else {
        setError(true)
      }
      setLoading(false)
    }
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      const result: any = await postData(data, `${api}/${query?.id}`)
      if (result.status) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil mengubah data',
        })
        navigate(-1)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Mohon cek kembali data anda.',
        })
      }
    } catch (_) {}
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={PageData.setupmenu?.page.breadcrumb.data}>
        {PageData.setupmenu?.page.breadcrumb.title}
      </PageTitle>
      <>
        <div className='row g-5'>
          <div className='col-12'>
            <div className={`card card-flush h-100`}>
              <div className='card-header py-5'>
                <div className='card-title d-flex flex-row justify-content-between w-100'>
                  <div>
                    {PageData.setupmenu.page.title} ({pageData?.description})
                  </div>
                </div>
                <div className='card-body px-0 w-100 d-flex flex-column'>
                  {!loading &&
                    data.map((l: SetupMenuUiProps, i: number) => (
                      <Fragment key={i}>
                        <SetupMenuUi
                          data={l}
                          index={i}
                          post={() => {
                            setData(data)
                          }}
                        />
                      </Fragment>
                    ))}
                  <div
                    className='mt-4 mx-n2 position-sticky p-4'
                    style={{bottom: '0px', backgroundColor: 'var(--bs-body-bg)'}}
                  >
                    <ButtonLoading
                      loading={loading}
                      title={{button: 'Submit'}}
                      props={{onClick: () => onSubmit()}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper, SetupMenuWrapper}
