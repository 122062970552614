import {FC} from 'react'
import {Document, Page, View, Text, Image} from '@react-pdf/renderer'
import {PdfLayoutDataProps, PdfStyle, PdfStylePallete} from '../../../../../helper/ReactPdf'
import {NullProof, capitalizeEachWord} from '../../../../../helper/AppFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  const TabelKolom = ({name, value}: {name: string; value?: string}) => {
    return (
      <View style={{width: '100%', fontSize: '12pt', display: 'flex', flexDirection: 'row'}}>
        <Text style={{width: value ? '40%' : '100%'}}>{name}</Text>
        {value && (
          <>
            <Text style={{width: '2%'}}>:</Text>
            <Text
              style={{width: '58%'}}
              hyphenationCallback={(e: any) => {
                return [e]
              }}
            >
              {value}
            </Text>
          </>
        )}
      </View>
    )
  }
  return (
    <Document title='Dokumen'>
      <Page wrap={false} style={{padding: '20px', fontFamily: 'Arial'}}>
        <View
          style={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            src={data.perusahaan.logo}
            style={{width: '80px', height: '80px', marginBottom: '30px'}}
          />
          <Text
            style={{textTransform: 'uppercase', fontSize: '15pt', fontWeight: 700}}
          >{`Implementasi\nPakta Integritas Komitmen Kerja\nTahun 2024`}</Text>
        </View>
        <View style={{marginVertical: '10px', gap: '5px', display: 'flex'}}>
          <TabelKolom
            name='Nama Satuan Kerja'
            value={`${NullProof({input: data.data, params: 'satker.nama_satker'})}`}
          />
          <TabelKolom
            name='Penandatangan Komitmen'
            value={`${capitalizeEachWord(
              NullProof({
                input: data.data,
                params: 'penandatangan_komitmen.nama',
              })?.toLowerCase()
            )}`}
          />
          <TabelKolom
            name='Jabatan'
            value={`${NullProof({
              input: data.data,
              params: 'penandatangan_komitmen.jabatan.jabatan',
            })}`}
          />
          <TabelKolom
            name='Nomor Ponsel'
            value={`${NullProof({input: data.data, params: 'penandatangan_komitmen.no_hp'})}`}
          />
          <TabelKolom
            name='Target Waktu Penyelesaian'
            value={`${NullProof({input: data.data, params: 'target_waktu', type: 'date'})}`}
          />
          <TabelKolom
            name='Petugas Pemantauan'
            value={`${capitalizeEachWord(
              NullProof({input: data.data, params: 'petugas_pemantau.nama'})?.toLowerCase()
            )}`}
          />
        </View>
        <View style={{marginVertical: '10px', gap: '5px', display: 'flex'}}>
          <TabelKolom name='PIC Teknis Satuan Kerja' />
          <TabelKolom
            name='Nama'
            value={`${capitalizeEachWord(
              NullProof({input: data.data, params: 'pic_satker.nama'})?.toLowerCase()
            )}`}
          />
          <TabelKolom
            name='Nomor Ponsel'
            value={`${NullProof({input: data.data, params: 'pic_satker.no_hp'})}`}
          />
        </View>
        <View style={{marginVertical: '10px', gap: '5px', display: 'flex'}}>
          <TabelKolom name='PIC Teknis Inspektorat Jenderal' />
          <TabelKolom
            name='Nama'
            value={`${capitalizeEachWord(
              NullProof({input: data.data, params: 'pic_irjen.nama'})?.toLowerCase()
            )}`}
          />
          <TabelKolom
            name='Nomor Ponsel'
            value={`${NullProof({input: data.data, params: 'pic_irjen.no_hp'})}`}
          />
        </View>
      </Page>
    </Document>
  )
}

const ColumnBuilder: FC<{number: number; month: string; link: any; isBorder?: boolean}> = ({
  number,
  month,
  link,
  isBorder = true,
}) => {
  return (
    <View style={{flexDirection: 'row', borderBottom: isBorder ? '1px solid black' : 'none'}}>
      <View
        style={{
          width: '10%',
          borderRight: '1px solid black',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Text style={{width: '100%', textAlign: 'center'}}>{number}</Text>
      </View>
      <View
        style={{
          width: '40%',
          borderRight: '1px solid black',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Text style={{width: '100%'}}>{month}</Text>
      </View>
      <View style={{width: '50%'}}>
        <Text>
          {Array.isArray(link) &&
            link.map(
              (item: any, i: number) => `${item.link_evidence}${i + 1 !== link.length ? ', ' : ''}`
            )}
        </Text>
      </View>
    </View>
  )
}

const PdfLayoutLinkEvidence: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  const feb = data.data.link_evidence?.filter((item: any) => item.bulan === 'Februari')
  const mar = data.data.link_evidence?.filter((item: any) => item.bulan === 'Maret')
  const apr = data.data.link_evidence?.filter((item: any) => item.bulan === 'April')
  const mei = data.data.link_evidence?.filter((item: any) => item.bulan === 'Mei')
  const jun = data.data.link_evidence?.filter((item: any) => item.bulan === 'Juni')
  const jul = data.data.link_evidence?.filter((item: any) => item.bulan === 'Juli')
  const agu = data.data.link_evidence?.filter((item: any) => item.bulan === 'Agustus')
  const sep = data.data.link_evidence?.filter((item: any) => item.bulan === 'September')
  const okt = data.data.link_evidence?.filter((item: any) => item.bulan === 'Oktober')
  return (
    <Document title='Dokumen'>
      <Page
        size={'FOLIO'}
        wrap={false}
        style={{
          ...PdfStyle.page,
          fontFamily: 'Arial',
          position: 'relative',
          gap: '5px',
          paddingVertical: '20px',
        }}
      >
        <View>
          <View style={{width: '100%', marginBottom: '15px'}}>
            <Text
              style={{
                fontSize: PdfStylePallete.typography.title.small,
                fontWeight: 700,
                textTransform: 'uppercase',
              }}
            >
              LINK EVIDENCE IMPLEMENTASI
            </Text>
            <Text
              style={{
                fontSize: PdfStylePallete.typography.title.small,
                fontWeight: 700,
                textTransform: 'uppercase',
              }}
            >
              PAKTA INTEGRITAS KOMITMEN KERJA
            </Text>
            <Text
              style={{
                fontSize: PdfStylePallete.typography.title.small,
                fontWeight: 700,
                textTransform: 'uppercase',
              }}
            >
              TAHUN 2024
            </Text>
          </View>
          <View
            style={{width: '100%', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  width: '25%',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{width: '90%'}}>Nama Satuan Kerja</Text>
                <Text style={{width: '10%'}}>:</Text>
              </View>
              <View
                style={{
                  width: '75%',
                }}
              >
                <Text>{NullProof({input: data.data, params: 'satker.nama_satker'})}</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              borderRight: '1px solid black',
              borderLeft: '1px solid black',
              borderTop: '1px solid black',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                borderBottom: '1px solid black',
                backgroundColor: 'yellow',
              }}
            >
              <View
                style={{
                  width: '10%',
                  borderRight: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text style={{width: '100%', textAlign: 'center', fontWeight: 'bold'}}>No</Text>
              </View>
              <View
                style={{
                  width: '40%',
                  borderRight: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text style={{width: '100%', textAlign: 'center', fontWeight: 'bold'}}>Bulan</Text>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  paddingVertical: '10px',
                  justifyContent: 'center',
                }}
              >
                <Text style={{width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
                  Link Evidence
                </Text>
                <Text style={{width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
                  (Gdrive, OneDrive, Dropbox, dll)
                </Text>
              </View>
            </View>
            <ColumnBuilder number={1} month='Februari' link={feb} />
            <ColumnBuilder number={2} month='Maret' link={mar} />
            <ColumnBuilder number={3} month='April' link={apr} />
            <ColumnBuilder number={4} month='Mei' link={mei} />
            <ColumnBuilder number={5} month='Juni' link={jun} />
            <ColumnBuilder number={6} month='Juli' link={jul} />
            <ColumnBuilder number={7} month='Agustus' link={agu} />
            <ColumnBuilder number={8} month='September' link={sep} />
            <ColumnBuilder number={9} month='Oktober' link={okt} />
          </View>
        </View>
      </Page>
    </Document>
  )
}
export {PdfLayout, PdfLayoutLinkEvidence}
