import {NullProof, capitalizeEachWord} from './AppFunction'

const filterJabatanAnggota = ({
  data,
  jabatan,
  anggota,
}: {
  jabatan: string[]
  data: any[]
  anggota: any
}) => {
  let infoAnggota: any = {}
  let ketuaList: any[] = []
  let anggotaList: any[] = []
  let posisiSemuaKetua: any[] = []
  let adaPilihKetua: boolean = false
  const result = [...data]
  result?.map((l, i) => {
    if (l?.pegawai_id === anggota?.pegawai_id) {
      infoAnggota = {
        index: i,
        data: l,
      }
    }
    if (l?.jabatan?.includes('Ketua')) {
      posisiSemuaKetua.push({
        index: i,
        data: l,
      })
    }
  })
  if (anggota?.jabatan === 'Anggota') {
    let pilihKetua = {}
    posisiSemuaKetua.map((l, i) => {
      if (l?.data?.jabatan === 'Ketua Tim' && l?.index < infoAnggota?.index) {
        pilihKetua = l?.data
        adaPilihKetua = true
      }
    })
    if (adaPilihKetua) {
      ketuaList = [...ketuaList, pilihKetua]
    }
    // } else if (anggota?.jabatan === 'Sub Anggota') {
    //   let pilihKetua = {}
    //   posisiSemuaKetua.map((l, i) => {
    //     if (l?.data?.jabatan === 'Ketua Sub Tim' && l?.index < infoAnggota?.index) {
    //       pilihKetua = l?.data
    //     }
    //   })
    //   ketuaList = [...ketuaList, pilihKetua]
  }
  if (!adaPilihKetua) {
    ketuaList = [
      ...ketuaList,
      ...result.filter(
        (l, i) => jabatan.includes(l?.jabatan) && l?.pegawai_id === anggota?.pegawai_id
      ),
    ]
    if (ketuaList.length === 0) {
      ketuaList = [...ketuaList, infoAnggota?.data]
    }
  }
  let ketuaTim = result.filter((l, i) => jabatan.includes(l?.jabatan))
  anggotaList = result.filter(
    // (item1) => !ketuaList.some((item2) => item1?.jabatan === item2?.jabatan)
    (item1) => !jabatan.includes(item1?.jabatan)
  )
  // console.log(ketuaList)
  // console.log(anggotaList)
  // console.log(ketuaTim)
  return {
    anggota: anggotaList,
    ketua: ketuaList,
    ketuaTim: ketuaTim,
  }
}

const UrutkanAnggotaSt = (data: any[]) => {
  let init = [...data]
  let listJabatan = [
    'Penanggung Jawab',
    'Wakil Penanggung Jawab',
    'Pengendali Mutu',
    'Pengendali Teknis',
    'Ketua Tim',
  ]
  init.sort((a: any, b: any) => {
    const jabatanA: string = a?.jabatan || ''
    const jabatanB: string = b?.jabatan || ''
    const urutanA: number = a?.urutan || 0
    const urutanB: number = b?.urutan || 0
    for (const l of listJabatan) {
      if (jabatanA.toLowerCase() === l.toLowerCase()) return -1
      if (jabatanB.toLowerCase() === l.toLowerCase()) return 1
    }
    if (urutanA !== urutanB) {
      return urutanA - urutanB
    }
    return 0
  })
  return init
}

function getObjectDifferences(original?: any, revisi?: any): any[] {
  const differingProperties: any[] = []

  const listObject = {...original}
  delete listObject.list_revisi

  for (const l of Object.keys(listObject)) {
    const pushData = () => {
      differingProperties.push({id: l, original: original[l], revisi: revisi[l]})
    }
    if (NullProof({input: original, params: l}) !== NullProof({input: revisi, params: l})) {
      if (Array.isArray(original[l]) && typeof original[l] === 'object') {
        if (original[l]?.length !== revisi[l]?.length) {
          pushData()
        }
      } else if (
        !Array.isArray(original[l]) &&
        typeof original[l] === 'object' &&
        original[l] !== null
      ) {
        const result = getObjectDifferences(original[l] || {}, revisi[l] || {})
        result.length > 0 && pushData()
      } else if (original[l] === null) {
        pushData()
      } else {
        pushData()
      }
    }
  }

  return differingProperties
}

const statusKepegawaianListData = [
  {
    title: 'PNS',
    value: 'PNS',
  },
  {
    title: 'PPPK',
    value: 'PPPK',
  },
  {
    title: 'PPNPN',
    value: 'PPNPN',
  },
  {
    title: 'CPNS',
    value: 'CPNS',
  },
]

const filterCapitalizeProvinsi = (provinsi: string) => {
  const listProvinsi = ['DKI', 'D.I', 'UIN']
  if (listProvinsi.some((e) => provinsi.toLowerCase().indexOf(e.toLowerCase()) > -1)) {
    const words = provinsi.split(' ')
    words[1] = capitalizeEachWord(words[1])
    return words.join(' ')
  } else {
    return capitalizeEachWord(provinsi)
  }
}

export {
  UrutkanAnggotaSt,
  getObjectDifferences,
  statusKepegawaianListData,
  filterJabatanAnggota,
  filterCapitalizeProvinsi,
}
