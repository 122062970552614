import {initData} from '../../../../../../../helper/DataList'
import {PageDataContent} from '../../../../../../../helper/Model'
import {TableInputDataProps} from '../../../../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Indikator',
  path: '/pengawasan/pelaporan_pengawasan_internal/evalusi_pakta_integritas/form_evaluasi/:id/komitmen_kerja/:komitmen_id/indikator',
  api: '/pengawasan_internal/komitmen-kerja/:komitmen_id/indikator',
  detail_api: '/pengawasan_internal/indikator',
  uraian_target_bulanan_api: '/pengawasan_internal/indikator/:indikator_id/uraian-target-bulanan',
  uraian_implementasi_bulanan_api:
    '/pengawasan_internal/indikator/:indikator_id/uraian-implementasi-bulanan',
}

const BaseBreadcrumb = [
  {
    title: 'Pelaporan Pengawasan Internal',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Evalusi Pakta Inetgritas',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Form Evaluasi',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Komitmen Kerja',
    path: '/pengawasan/pelaporan_pengawasan_internal/evalusi_pakta_integritas/form_evaluasi/komitmen_kerja',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Indikator',
    },
    {
      title: 'Target',
    },
  ],
  link: {
    tambah: {
      id: 'tambah',
      protect: 'tambah',
      title: 'Tambah',
      route: 'tambah',
    },
    action: [
      {
        id: 'id',
        protect: 'detail/:indikator_id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'id',
        protect: 'ubah/:indikator_id',
        title: 'Ubah',
        route: 'ubah/:id',
      },
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: InfoData.detail_api,
      },
      {
        id: 'id',
        protect: 'uraian-target-bulanan/:indikator_id',
        title: 'Target Bulanan',
        route: 'uraian-target-bulanan/:id',
      },
      {
        id: 'id',
        protect: 'uraian-implementasi-bulanan/:indikator_id',
        title: 'Implementasi Bulanan',
        route: 'uraian-implementasi-bulanan/:id',
      },
    ],
  },
  route: {
    url: InfoData.api,
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.detail_api}/:indikator_id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  target: {
    page: {
      title: `Ubah Target Bulanan`,
      breadcrumb: {
        title: `Ubah Target Bulanan Indi`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  implementasi: {
    page: {
      title: `Ubah Implemenatasi Bulanan`,
      breadcrumb: {
        title: `Ubah Implemenatasi Bulanan`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
