import {DataRoutesProps} from '../../UserRolesValidation'
import {MainRoutes as FormBuktiKehilanganRoutes} from './verifikasi-perjalanan-dinas/FormBuktiKehilangan'
import {MainRoutes as FormBuktiPerjalanan} from './verifikasi-perjalanan-dinas/FormBuktiPerjalanan'
import {MainRoutes as FormDPRRoutes} from './verifikasi-perjalanan-dinas/FormDPR'
import {MainRoutes as FormPembayaranPenagihanRoutes} from './verifikasi-perjalanan-dinas/FormPembayaranPenagihan'
import {MainRoutes as NormatifPerjadin} from './verifikasi-perjalanan-dinas/NormatifPerjadin'
import {MainRoutes as ExportBuktiPerjalanan} from './verifikasi-perjalanan-dinas/ExportBuktiPerjalanan'
import DaftarStatusBuktiPerjalanan from './verifikasi-perjalanan-dinas/DaftarStatusBuktiPerjalanan'

const VerifikasiPerjalananDinas: DataRoutesProps = {
  title: 'Verifikasi Perjalanan Dinas',
  icon: 'element-10',
  route: 'verifikasi_perjalanan_dinas',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Normatif Perjadin',
      icon: 'element-10',
      route: 'normatif_perjadin',
      elements: <></>,
      subroute: NormatifPerjadin,
    },
    {
      isPage: true,
      title: 'Form Bukti Perjalanan',
      icon: 'element-10',
      route: 'form_bukti_perjalanan',
      elements: <></>,
      subroute: FormBuktiPerjalanan,
    },
    DaftarStatusBuktiPerjalanan,
    // {
    //   isPage: true,
    //   title: 'Form Kehilangan Bukti',
    //   icon: 'element-10',
    //   route: 'form_kehilangan_bukti',
    //   elements: <></>,
    //   subroute: FormBuktiKehilanganRoutes,
    // },
    {
      isPage: true,
      title: 'Form DPR (Daftar Pengeluaran Riil)',
      icon: 'element-10',
      route: 'form_dpr',
      elements: <></>,
      subroute: FormDPRRoutes,
    },
    {
      isPage: true,
      title: 'Export Bukti Perjalanan',
      icon: 'element-10',
      route: 'export_bukti_perjalanan',
      elements: <></>,
      subroute: ExportBuktiPerjalanan,
    },
    {
      isPage: true,
      title: 'Form Pembayaran Penagihan',
      icon: 'element-10',
      route: 'form_pembayaran_penagihan',
      elements: <></>,
      subroute: FormPembayaranPenagihanRoutes,
    },
  ],
}

const EvaluasiPelaporan: DataRoutesProps = {
  title: 'Evaluasi Pelaporan',
  icon: 'element-10',
  route: 'evaluasi_pelaporan',
  elements: <></>,
  subroute: [
    {...VerifikasiPerjalananDinas},
    {
      title: 'Monitoring dan Evaluasi Kegiatan',
      icon: 'element-10',
      route: 'monitoring_evaluasi_kegiatan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penyusunan Laporan Capai Kerja',
      icon: 'element-10',
      route: 'penyusunan_laporan_capai_kerja',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penyusunan Laporan Keuangan',
      icon: 'element-10',
      route: 'penyusunan_laporan_keuangan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Evaluasi dan Pelaporan Kinerja Anggaran',
      icon: 'element-10',
      route: 'evaluasi_pelaporan_kinerja_anggaran',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pengarsipan Laporan Keuangan',
      icon: 'element-10',
      route: 'pengarsipan_laporan_keuangan',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default EvaluasiPelaporan
