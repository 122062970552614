const i18nGlobalAllMessages = {
  HELLO: 'Hello',
  SEARCH: 'Search',
  ADD: 'Add',
  DELETE: 'Delete',
  EDIT: 'Edit',
  CREATE: 'Create',
  DATA: 'Data',
  SHOW: 'Show',
  PAGE: 'Page',
  NO_DATA: 'No data',
  NO_DATA_DESCRIPTION: 'Still no data',
  SEARCH_EMPTY: 'Data you are search was not found',
  SEARCH_SHOWING: 'Showing {data} data each page',
  SEARCH_INFO: 'Searching {data} found total {total} data',
}

export default i18nGlobalAllMessages
