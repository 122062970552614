import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'

const inputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'byuser=true',
        params: 'nomor_surat,nama_kegiatan',
        result: '$nomor_surat | $nama_kegiatan',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'tanggal',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal',
          type: 'date',
          placeholder: 'Tanggal',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'komponen',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Komponen',
          placeholder: 'Komponen',
        },
      },
    },
    component: [
      {
        className: 'col-6',
        name: 'halaman_lha',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Halaman LHA',
              placeholder: 'Halaman LHA',
            },
          },
        },
      },
      {
        className: 'col-6',
        name: 'no_kka',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Nomor KKA',
              placeholder: 'Nomor KKA',
            },
          },
        },
      },
      {
        className: 'col-6',
        name: 'masalah_dijumpai',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Masalah Yang Dijumpai',
              placeholder: 'Masalah Yang Dijumpai',
              rows: 3,
            },
          },
        },
      },
      {
        className: 'col-6',
        name: 'penyelesaian_masalah',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Penyelesaian Masalah',
              placeholder: 'Penyelesaian Masalah',
              rows: 3,
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'dilakukan_oleh',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Dilakukan Oleh',
              placeholder: 'Dilakukan Oleh',
            },
          },
        },
      },
      {
        className: 'col-6',
        name: 'keterangan',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Keterangan',
              placeholder: 'Keterangan',
              rows: 3,
            },
          },
        },
      },
      {
        className: 'col-6',
        name: 'tindakan',
        type: 'textarea',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Tindakan',
              placeholder: 'Tindakan',
              rows: 3,
            },
          },
        },
      },
    ],
  },
]

export {inputList}
