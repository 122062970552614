import {FC, Fragment} from 'react'
import {Document, Page, View, Text, Image} from '@react-pdf/renderer'
import {
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfSignatureBuilder,
  PdfStyle,
  PdfStylePallete,
} from '../../../../helper/ReactPdf'
import {formatDate} from '../../../../helper/DateFns'
import {NullProof, capitalizeEachWord} from '../../../../helper/AppFunction'
import {filterJabatanAnggota} from '../../../../helper/KemenagFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  data.data.alat_angkutan = 'BUS / KA / Pesawat'
  const filterProvinsi = (provinsi: string) => {
    if (provinsi.includes('DKI' || 'D.I' || 'UIN')) {
      const words = provinsi.split(' ')
      words[1] = capitalizeEachWord(words[1])
      return words.join(' ')
    }else{
      return capitalizeEachWord(provinsi)
    }
  }

  const HeaderApp = (props: {data: any}) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          src={props.data?.perusahaan?.logo}
          style={{
            width: '80px',
            height: '90px',
            objectFit: 'contain',
            marginRight: '20px',
          }}
        />
        <View style={{width: '100%', flexDirection: 'row'}}>
          <View
            style={{
              width: '55%',
              textAlign: 'center',
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                fontSize: PdfStylePallete.typography.title.medium,
              }}
            >
              {props.data?.perusahaan?.nama}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: PdfStylePallete.typography.label.small,
              }}
            >
              {props.data?.perusahaan?.alamat}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: PdfStylePallete.typography.label.small,
              }}
            >
              Telepon {props.data?.perusahaan?.telepon} Fax {props.data?.perusahaan?.fax}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: PdfStylePallete.typography.label.small,
              }}
            >
              Jakarta 12420
            </Text>
          </View>
          <View
            style={{
              width: '45%',
              fontSize: '10px',
              paddingLeft: '10px',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginVertical: '2px',
              }}
            >
              <View style={{flexDirection: 'row', width: '40%'}}>
                <Text style={{width: '90%', fontWeight: 700}}>Lembar Ke</Text>
                <Text style={{}}>:</Text>
              </View>
              <Text
                style={{fontWeight: 700, borderBottom: '1px dashed black', width: '60%'}}
              ></Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: '2px',
              }}
            >
              <View style={{flexDirection: 'row', width: '40%'}}>
                <Text style={{width: '90%', fontWeight: 700}}>Kode No.</Text>
                <Text style={{}}>:</Text>
              </View>
              <Text style={{fontWeight: 700, borderBottom: '1px dashed black', width: '60%'}}>
                {NullProof({
                  input: data.data,
                  label: '-',
                  params: 'kode_no',
                })}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: '2px',
              }}
            >
              <View style={{flexDirection: 'row', width: '40%'}}>
                <Text style={{width: '90%', fontWeight: 700}}>Nomor</Text>
                <Text style={{}}>:</Text>
              </View>
              <Text style={{fontWeight: 700, borderBottom: '1px dashed black', width: '60%'}}>
                {NullProof({
                  input: data.data,
                  label: 'Nomor',
                  params: 'nomor_surat',
                })}
              </Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  const Kolom: FC<{childrenLeft?: any; childrenRight?: any; number?: number | string}> = ({
    childrenLeft,
    childrenRight,
    number = 1,
  }) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          borderBottom: '1px',
        }}
      >
        <View
          style={{
            width: '45%',
            flexDirection: 'row',
            padding: '5px',
          }}
        >
          <View style={{minWidth: '20px'}}>
            <Text>{number}.</Text>
          </View>
          <View style={{paddingRight: '10px', width: '100%', gap: '10px', letterSpacing: '0.1px'}}>
            {childrenLeft}
          </View>
        </View>
        <View
          style={{
            borderLeft: '1px',
            paddingLeft: '5px',
            width: '55%',
            padding: '5px',
            gap: '10px',
            letterSpacing: '0.1px',
          }}
        >
          {childrenRight}
        </View>
      </View>
    )
  }

  const filterKetuaAnggota = filterJabatanAnggota({
    data: NullProof({
      input: data.data,
      params: 'surat_tugas.kepada',
      isMap: true,
    }),
    jabatan: [
      'Ketua Tim',
      'Penanggung Jawab',
      'Wakil Penanggung Jawab',
      'Pengendali Mutu',
      'Pengendali Teknis',
    ],
    anggota: NullProof({
      input: data.data,
      params: 'anggota',
    }),
  })

  return (
    <Document title='Dokumen'>
      <Page
        size={'A4'}
        wrap={false}
        style={{
          ...PdfStyle.page,
          paddingHorizontal: '6%',
          fontFamily: 'Arial',
          position: 'relative',
          gap: '5px',
          letterSpacing: '0.1px',
        }}
      >
        <PdfHeaderApp data={data} customChild={<HeaderApp data={data} />} />
        <View
          style={{
            height: '2px',
            backgroundColor: '#000000',
          }}
        ></View>
        <View
          style={{
            fontSize: PdfStylePallete.typography.label.small,
          }}
        >
          <Text
            style={{
              fontWeight: 700,
              textDecoration: 'underline',
              textTransform: 'uppercase',
              fontSize: PdfStylePallete.typography.title.small,
              marginVertical: '10px',
              textAlign: 'center',
            }}
          >
            Surat Perjalanan Dinas (SPD)
          </Text>
          <Kolom
            number={1}
            childrenLeft={
              <View>
                <Text>Pejabat Pembuat Komitmen</Text>
              </View>
            }
            childrenRight={
              <View>
                <Text>
                  {capitalizeEachWord(
                    NullProof({input: data.data, label: 'Nama', params: 'instansi'})
                  )}
                </Text>
              </View>
            }
          />
          <Kolom
            number={2}
            childrenLeft={
              <View>
                <Text>Nama pegawai yang diperintah</Text>
              </View>
            }
            childrenRight={
              <View>
                {filterKetuaAnggota.ketua.map((l: any, i: number) => (
                  <Fragment key={i}>
                    <Text>
                      {capitalizeEachWord(
                        NullProof({input: l, label: 'Nama', params: 'pegawai.nama'})
                      )}
                    </Text>
                    <Text>NIP: {NullProof({input: l, label: 'Nama', params: 'pegawai.nip'})}</Text>
                  </Fragment>
                ))}
              </View>
            }
          />
          <Kolom
            number={3}
            childrenLeft={
              <View>
                <Text>a. Pangkat dan golongan</Text>
                <Text>b. Jabatan</Text>
                <Text>c. Tingkat Biaya Perjalanan Dinas</Text>
              </View>
            }
            childrenRight={
              <View>
                {filterKetuaAnggota.ketua.map((l: any, i: number) => (
                  <Fragment key={i}>
                    <Text>
                      {`${NullProof({
                        input: l,
                        label: 'Pangkat',
                        params: 'pegawai.pangkat.pangkat',
                      })} / ${NullProof({
                        input: l,
                        label: 'Golongan',
                        params: 'pegawai.pangkat.golongan',
                      })}`}
                    </Text>
                    <Text>
                      {NullProof({
                        input: l,
                        label: 'Jabatan',
                        params: 'pegawai.jabatan.fungsional',
                      })}
                    </Text>
                    <Text>
                      {NullProof({
                        input: l,
                        params: 'tingkat_biaya_dinas',
                      })}
                    </Text>
                  </Fragment>
                ))}
              </View>
            }
          />
          <Kolom
            number={4}
            childrenLeft={
              <View>
                <Text>Maksud Perjalanan Dinas</Text>
              </View>
            }
            childrenRight={
              <View>
                <Text>
                  {NullProof({
                    input: data.data,
                    label: 'Jabatan',
                    params: 'surat_tugas.deskripsi_kegiatan',
                  })}{' '}
                  {NullProof({input: data.data, params: 'surat_tugas.tujuan', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        {l.tipe_tujuan !== 'Luar negeri' ?
                        <View
                          style={{
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {NullProof({
                                input: l,
                                params: 'satker.nama_satker',
                                label: 'satker',
                              })}
                            {', '}
                            {!l.kota2 && l.satker_2 && Object.keys(l.satker_2).length !== 0&& (
                              <Text style={{width: '95%', textAlign: 'justify'}}>
                                {NullProof({
                                    input: l,
                                    params: 'satker_2.nama_satker',
                                    label: 'satker2',
                                  })}
                                {', '}
                              </Text>
                            )}
                            {!l.kota3 && l.satker_3 && Object.keys(l.satker_3).length !== 0 && (
                              <Text style={{width: '95%', textAlign: 'justify'}}>
                                {NullProof({
                                    input: l,
                                    params: 'satker_3.nama_satker',
                                    label: 'satker3',
                                  })}
                                {', '}
                              </Text>
                            )}
                            {capitalizeEachWord(
                              NullProof({input: l, params: 'kota', label: 'kota'})?.toLowerCase()
                            )}
                          </Text>
                          {l.kota2 && NullProof({input: l, params: 'kota2'}) !== '' && (
                            <Text style={{width: '95%', textAlign: 'justify'}}>
                              {l.kota3 ? ', ' : ' dan '}
                              {NullProof({
                                  input: l,
                                  params: 'satker_2.nama_satker',
                                  label: 'satker2',
                                })}
                              {', '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'kota2',
                                  label: 'kota2',
                                })?.toLowerCase()
                              )}{' '}
                            </Text>
                          )}
                          {l.kota3 && NullProof({input: l, params: 'kota3'}) !== '' && (
                            <Text style={{width: '95%', textAlign: 'justify'}}>
                              dan{' '}
                              {NullProof({
                                  input: l,
                                  params: 'satker_3.nama_satker',
                                  label: 'satker3',
                                })}
                              {', '}
                              {capitalizeEachWord(
                                NullProof({
                                  input: l,
                                  params: 'kota3',
                                  label: 'kota3',
                                })?.toLowerCase()
                              )}
                            </Text>
                          )}
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {', Provinsi '}
                            {filterProvinsi(NullProof({input: l, params: 'provinsi', label: 'kota3'}))}
                          </Text>
                        </View>
                        :
                        <View
                          style={{
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Text style={{width: '95%', textAlign: 'justify'}}>
                            {capitalizeEachWord(NullProof({input: l, params: 'kota', label: 'kota'}))}
                            {', Negara'}
                            {capitalizeEachWord(NullProof({input: l, params: 'negara', label: 'negara'}))}
                          </Text>
                        </View>
                      }
                      </Fragment>
                    )
                  )}
                </Text>
              </View>
            }
          />
          <Kolom
            number={5}
            childrenLeft={
              <View>
                <Text>Alat angkutan yang dipergunakan</Text>
              </View>
            }
            childrenRight={
              <View>
                <Text>
                  {NullProof({
                    input: data.data,
                    label: 'Alat',
                    params: 'alat_angkutan',
                  })}
                </Text>
              </View>
            }
          />
          <Kolom
            number={6}
            childrenLeft={
              <View>
                <Text>a. Tempat berangkat</Text>
                <Text>b. Tempat tujuan</Text>
              </View>
            }
            childrenRight={
              <View>
                <Text>
                  a.{' '}
                  {NullProof({
                    input: data.data,
                    label: 'Berangkat',
                    params: 'tempat_berangkat',
                    isLabel: false,
                  }) || 'Jakarta'}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <Text>b. </Text>
                  <View style={{width: '100%'}}>
                    {NullProof({
                      input: data.data,
                      label: 'Tempat',
                      params: 'surat_tugas.tujuan',
                      isMap: true,
                    })?.map((l: any, i: number) => (
                      <Fragment key={i}>
                        <Text>
                          {NullProof({
                              input: l,
                              params: 'tipe_tujuan',
                            }) === 'Luar negeri'
                            ? capitalizeEachWord(
                              `${NullProof({
                                input: l,
                                label: 'Negara',
                                params: 'negara',
                              })} - ${NullProof({
                                input: l,
                                label: 'Kota',
                                params: 'kota',
                              })}`
                            ) :
                            capitalizeEachWord(
                              `${NullProof({
                                input: l,
                                label: 'Provinsi',
                                params: 'provinsi',
                              })} - ${NullProof({
                                input: l,
                                label: 'Kota',
                                params: 'kota',
                              })}`
                            )
                          }
                        </Text>
                      </Fragment>
                    ))}
                  </View>
                </View>
              </View>
            }
          />
          <Kolom
            number={7}
            childrenLeft={
              <View>
                <Text>a. Lamanya perjalanan dinas</Text>
                <Text>b. Tanggal berangkat</Text>
                <Text>c. Tanggal harus kembali</Text>
              </View>
            }
            childrenRight={
              <View>
                <Text>
                  a.{' '}
                  {`${
                    NullProof({
                      input: data.data,
                      label: 'Lama Perjalanan',
                      params: 'anggota.hari',
                      isLabel: false,
                    }) || 0
                  } Hari`}
                </Text>
                <Text>
                  b.{' '}
                  {NullProof({input: data.data, params: 'surat_tugas.tanggal_mulai', type: 'date'})}
                </Text>
                <Text>
                  c.{' '}
                  {NullProof({
                    input: data.data,
                    params: 'surat_tugas.tanggal_selesai',
                    type: 'date',
                  })}
                </Text>
              </View>
            }
          />
          {NullProof({
            input: data.data,
            label: 'Anggota',
            params: 'anggota.jabatan',
          }) === 'Penanggung Jawab' ||
          NullProof({
            input: data.data,
            label: 'Anggota',
            params: 'anggota.jabatan',
          }) === 'Wakil Penanggung Jawab' ||
          NullProof({
            input: data.data,
            label: 'Anggota',
            params: 'anggota.jabatan',
          }) === 'Pengendali Teknis' ||
          NullProof({
            input: data.data,
            label: 'Anggota',
            params: 'anggota.jabatan',
          }) === 'Pengendali Mutu' ? (
            <Kolom
              number={8}
              childrenLeft={
                <View>
                  <Text>Nama</Text>
                  <Text>-</Text>
                </View>
              }
              childrenRight={
                <View>
                  <View
                    style={{
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    <Text
                      style={{
                        width: '50%',
                      }}
                    >
                      Pangkat/Gol
                    </Text>
                    <Text
                      style={{
                        width: '50%',
                      }}
                    >
                      Jabatan
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    <Text
                      style={{
                        width: '50%',
                      }}
                    >
                      -
                    </Text>
                    <Text
                      style={{
                        width: '50%',
                      }}
                    >
                      -
                    </Text>
                  </View>
                </View>
              }
            />
          ) : (
            <Kolom
              number={8}
              childrenLeft={
                <View>
                  <Text>Nama</Text>
                  {filterKetuaAnggota.anggota.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <Text
                        style={{
                          width: '100%',
                        }}
                      >
                        {i + 1}.{capitalizeEachWord(l?.pegawai?.nama)}
                      </Text>
                    </Fragment>
                  ))}
                </View>
              }
              childrenRight={
                <View>
                  <View
                    style={{
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    <Text
                      style={{
                        width: '50%',
                      }}
                    >
                      Pangkat/Gol
                    </Text>
                    <Text
                      style={{
                        width: '50%',
                      }}
                    >
                      Jabatan
                    </Text>
                  </View>
                  {filterKetuaAnggota.anggota.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <View
                        style={{
                          flexDirection: 'row',
                          gap: '10px',
                        }}
                      >
                        <Text
                          style={{
                            width: '50%',
                          }}
                        >
                          {`${NullProof({
                            input: l,
                            label: 'Pangkat',
                            params: 'pegawai.pangkat.pangkat',
                          })} (${NullProof({
                            input: l,
                            label: 'Gol',
                            params: 'pegawai.pangkat.golongan',
                          })})`}
                        </Text>
                        <Text
                          style={{
                            width: '50%',
                          }}
                        >
                          {NullProof({
                            input: l,
                            label: 'Jabatan',
                            params: 'jabatan',
                          })}
                        </Text>
                      </View>
                    </Fragment>
                  ))}
                </View>
              }
            />
          )}

          <Kolom
            number={9}
            childrenLeft={
              <View>
                <Text>Pembebanan anggaran:</Text>
                <Text>a. Instansi/Proyek</Text>
                <Text>b. Mata anggaran</Text>
              </View>
            }
            childrenRight={
              <View>
                <Text>&nbsp;</Text>
                <Text>
                  a.{' '}
                  {NullProof({
                    input: data.data,
                    label: 'Instansi/Proyek',
                    params: 'instansi',
                  })}
                </Text>
                <Text>
                  b.{' '}
                  {NullProof({
                    input: data.data,
                    label: 'Mata Anggaran',
                    params: 'surat_tugas.mak',
                  })}
                </Text>
              </View>
            }
          />
          <Kolom
            number={10}
            childrenLeft={
              <View>
                <Text>Keterangan Lain-lain</Text>
              </View>
            }
            childrenRight={
              <View>
                <Text>
                  {`${
                    formatDate({
                      date: NullProof({input: data.data, params: 'surat_tugas.tanggal'}),
                      dateFormat: 'dd MMMM yyyy',
                    }) || '(Tanggal)'
                  } - ${data.data?.surat_tugas.nomor_surat}`}
                </Text>
              </View>
            }
          />
          <PdfSignatureBuilder
            data={[
              {
                line1: `Dikeluarkan di: ${NullProof({
                  input: data.data,
                  params: 'lokasi',
                })}\nTanggal: ${NullProof({
                  input: data.data,
                  params: 'createdAt',
                  type: 'date',
                })}`,
                line2: `Pejabat Pembuat Komitmen`,
                typeSignature: 'string',
                signature: '$',
                name: `${capitalizeEachWord(
                  NullProof({
                    input: data.data,
                    label: 'Nama',
                    params: 'surat_tugas.ppk.nama',
                  })
                )}\nNIP: ${NullProof({
                  input: data.data,
                  label: 'NIP',
                  params: 'surat_tugas.ppk.nip',
                })}`,
                align: 'center',
              },
            ]}
            options={{
              align: 'flex-end',
              data: {
                align: 'center',
                signature: {style: {fontSize: '11pt'}},
                nameStyle: {
                  fontSize: '11pt',
                },
                line1Style: {
                  marginTop: '10pt',
                  fontSize: '11pt',
                },
                line2Style: {
                  marginTop: '10pt',
                  fontSize: '11pt',
                },
              },
            }}
            style={{marginBottom: '100pt'}}
          />
        </View>
      </Page>

      <Page
        size={'A4'}
        wrap={false}
        style={{
          ...PdfStyle.page,
          paddingHorizontal: '6%',
          fontFamily: 'Arial',
          position: 'relative',
          gap: '5px',
          letterSpacing: '0.1px',
          fontSize: '11px',
        }}
      >
        <View
          style={{
            fontSize: PdfStylePallete.typography.label.small,
          }}
        >
          <Kolom
            number={''}
            childrenLeft={<View></View>}
            childrenRight={
              <View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '40%'}}>Berangkat dari</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>(tempat kedudukan)</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>ke</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>pada tanggal</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <Text>Kepala</Text>
                <View style={{marginTop: '20px', marginRight: '20px'}}>
                  <Text style={{width: '100%', textAlign: 'right'}}>
                    ............................................
                  </Text>
                  <Text style={{width: '100%', textAlign: 'center'}}>NIP.</Text>
                </View>
              </View>
            }
          />
          <Kolom
            number={'II'}
            childrenLeft={
              <View style={{marginBottom: '20px'}}>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '40%'}}>Tiba di</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Pada Tanggal</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Kepala</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
              </View>
            }
            childrenRight={
              <View style={{marginBottom: '20px'}}>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '40%'}}>Berangkat dari</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>ke</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Pada Tanggal</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Kepala</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
              </View>
            }
          />
          <Kolom
            number={'III'}
            childrenLeft={
              <View style={{marginBottom: '20px'}}>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '40%'}}>Tiba di</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Pada Tanggal</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Kepala</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
              </View>
            }
            childrenRight={
              <View style={{marginBottom: '20px'}}>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '40%'}}>Berangkat dari</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>ke</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Pada Tanggal</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Kepala</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
              </View>
            }
          />
          <Kolom
            number={'IV'}
            childrenLeft={
              <View style={{marginBottom: '20px'}}>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '40%'}}>Tiba di</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Pada Tanggal</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Kepala</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
              </View>
            }
            childrenRight={
              <View style={{marginBottom: '20px'}}>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '40%'}}>Berangkat dari</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>ke</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Pada Tanggal</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Kepala</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
              </View>
            }
          />
          <Kolom
            number={'V'}
            childrenLeft={
              <View style={{marginBottom: '20px'}}>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '40%'}}>Tiba di</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Pada Tanggal</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Kepala</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
              </View>
            }
            childrenRight={
              <View style={{marginBottom: '20px'}}>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '40%'}}>Berangkat dari</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>ke</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Pada Tanggal</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Kepala</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>
                    ...............................................
                  </Text>
                </View>
              </View>
            }
          />
          <Kolom
            number={'VI'}
            childrenLeft={
              <View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '40%'}}>Tiba di</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px'}}>
                  <Text style={{width: '40%'}}>Pada Tanggal</Text>
                  <Text style={{width: '5%'}}>:</Text>
                  <Text style={{width: '10%'}}>.....................................</Text>
                </View>
                <Text>Pejabat yang memberi perintah.</Text>
                <Text style={{marginRight: '10px', marginTop: '22px', textAlign: 'right'}}>
                  Pejabat Pembuat Komitmen
                </Text>
                <View style={{marginLeft: '27%', marginTop: '80pt'}}>
                  <Text style={{width: '100%', textAlign: 'left'}}>
                    {capitalizeEachWord(
                      NullProof({
                        input: data.data,
                        params: 'surat_tugas.ppk.nama',
                      })
                    )}
                  </Text>
                  <Text style={{width: '100%', textAlign: 'left'}}>
                    NIP.{' '}
                    {NullProof({
                      input: data.data,
                      params: 'surat_tugas.ppk.nip',
                    })}
                  </Text>
                </View>
              </View>
            }
            childrenRight={
              <View>
                <View style={{display: 'flex', flexDirection: 'row', gap: '2px', width: '100%'}}>
                  <Text style={{width: '100%'}}>
                    Telah diperiksa, dengan keterangan bahwa perjalanan tersebut di atas benar
                    dilakukan atas perintahnya dan semata-mata untuk kepentingan jabatan dalam waktu
                    yang sesingkat singkatnya.
                  </Text>
                </View>
                <Text style={{marginRight: '40px', marginTop: '10px', textAlign: 'right'}}>
                  Pejabat Pembuat Komitmen
                </Text>
                <View style={{marginLeft: '38%', marginTop: '80pt'}}>
                  <Text style={{width: '100%', textAlign: 'left'}}>
                    {capitalizeEachWord(
                      NullProof({
                        input: data.data,
                        params: 'surat_tugas.ppk.nama',
                      })
                    )}
                  </Text>
                  <Text style={{width: '100%', textAlign: 'left'}}>
                    NIP.{' '}
                    {NullProof({
                      input: data.data,
                      params: 'surat_tugas.ppk.nip',
                    })}
                  </Text>
                </View>
              </View>
            }
          />
          <View
            style={{
              flexDirection: 'row',
              borderBottom: '1px',
            }}
          >
            <Text>VII. CATATAN LAIN-LAIN</Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View style={{display: 'flex', flexDirection: 'column', gap: '2px', width: '95%'}}>
              <Text>VIII. PERHATIAN</Text>
              <View style={{marginLeft: '22px', width: '100%', marginBottom: '80px'}}>
                <Text>
                  PPK yang menerbitkan SPD, pegawai yang melakukan perjalanan dinas, para pejabat
                  yang mengesahkan tanggal berangkat/tiba, serta bendahara pengeluaran bertanggung
                  jawab berdasarkan peraturan-peraturan Keuangan Negara apabila negara menderita
                  rugi akibat kesalahan, kelalaian, dan kealpaannya. (PMK NOMOR 113/PMK.05/2012)
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default PdfLayout
