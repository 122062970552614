import * as Yup from 'yup'
import {FC, Fragment, ReactNode, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useNavigate, useParams} from 'react-router-dom'
import PageData, {
  InfoData,
  TableInputData,
  TableInputDetailData,
  TableInputUsulan,
  TableInputST,
  TableInputSTSatker,
  TableInputUsulanSatker,
} from './PageData'
import {
  formInputPass,
  formInputStatus,
  formInputStatus2,
  formInputStatusIrwil,
  formInputStatusPass,
  formInputStatusdisposisi,
  inputListAll,
} from '../../form-usulan/InputLayout'
import FormInput, {
  BaseInputOnChangeProps,
  FormInputProps,
  onLoadProps as onLoadPropsForm,
} from '../../../../../helper/FormInput'
import {CardUi, ModalButtonUi, TabsUi} from '../../../../../helper/AppUi'
import {
  NullProof,
  RouterQueryParams,
  formInputListSelector,
  ConvertCurrency,
  NullProofProps,
  objectListSelector,
  getStatusColor,
  numberlistPagination,
} from '../../../../../helper/AppFunction'
import {TableAction, TableColumn, TableInput, TableRow} from '../../../../../helper/TableInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../helper/TableInputDetail'
import {formatDate} from '../../../../../helper/DateFns'
import Modal from '../../../../../helper/Modal'
import {useAuth} from '../../../../../modules/auth'
import {PdfPageViewer} from '../../../../../helper/PdfPageViewer'
import {PdfLayout, PdfLayoutPreviewST} from '../../form-usulan/PdfLayout'
import {getData} from '../../../../../helper/FormAxios'
import {getObjectDifferences} from '../../../../../helper/KemenagFunction'
import {WithChildren} from '../../../../../../_metronic/helpers'
import {ReactPdfViewer} from '../../../../../helper/ReactPdf'
import {ValueType} from 'exceljs'
import {
  TableListUsulanByDate,
  TableListSTByDate,
  TableListTujuanSatker,
  TableListStTujuanSatker,
  ButtonKirimNaskah,
} from '../../form-usulan/TableWrapper'
import Accordion from '../../../../../helper/Accordion'

const dynamicInputList = (data?: string, param?: string) => {
  let _inputList: FormInputProps[] = [...inputListAll]
  const form_app_id = formInputListSelector({formInputList: _inputList, name: 'form_app_id'})
  const form_app_detail = formInputListSelector({
    formInputList: _inputList,
    name: 'form_app_detail',
  })
  const pkpt_id = formInputListSelector({formInputList: _inputList, name: 'pkpt_id'})
  const lainnya = formInputListSelector({formInputList: _inputList, name: 'lainnya'})
  const statusReview = formInputListSelector({formInputList: _inputList, name: 'passphrase'})
  const pok_id = formInputListSelector({formInputList: _inputList, name: 'pok_id'})
  const mak = formInputListSelector({formInputList: _inputList, name: 'mak'})
  const kode_mak = formInputListSelector({formInputList: _inputList, name: 'kode_mak'})
  const ppk_id = formInputListSelector({formInputList: _inputList, name: 'ppk_id'})
  if (data === 'Form APP') {
    form_app_id.hide = false
    form_app_detail.hide = false
    pkpt_id.hide = true
    lainnya.hide = true
    form_app_id.validator = Yup.number().required('Mohon untuk diisi.')
    form_app_detail.validator = Yup.string().required('Mohon untuk diisi.')
    pkpt_id.validator = Yup.number()
    lainnya.validator = Yup.string()
  } else if (data === 'Form PKPT') {
    form_app_id.hide = true
    form_app_detail.hide = true
    pkpt_id.hide = false
    lainnya.hide = true
    form_app_id.validator = Yup.number()
    form_app_detail.validator = Yup.string()
    pkpt_id.validator = Yup.number().required('Mohon untuk diisi.')
    lainnya.validator = Yup.string()
  } else if (data === 'Lainnya') {
    form_app_id.hide = true
    form_app_detail.hide = true
    pkpt_id.hide = true
    lainnya.hide = false
    form_app_id.validator = Yup.number()
    form_app_detail.validator = Yup.string()
    pkpt_id.validator = Yup.number()
    lainnya.validator = Yup.string().required('Mohon untuk diisi.')
  } else if (data === 'Ditolak') {
    statusReview.hide = true
    statusReview.validator = Yup.string()
  } else if (data === 'Menunggu') {
    statusReview.hide = true
    statusReview.validator = Yup.string()
  } else if (data === 'Disetujui') {
    statusReview.hide = false
    statusReview.validator = Yup.string().required('Mohon untuk diisi.')
  } else if (param == 'jenis') {
    if (data === 'Non SPD') {
      pok_id.validator = Yup.number()
      pok_id.hide = true
      mak.validator = Yup.string()
      mak.hide = true
      kode_mak.validator = Yup.string()
      kode_mak.hide = true
      ppk_id.validator = Yup.string()
      ppk_id.hide = true
    } else if (data === 'SPD' || data === 'SPD DKI Jakarta') {
      pok_id.validator = Yup.number().required('Mohon untuk diisi.')
      mak.validator = Yup.string().required('Mohon untuk diisi.')
      kode_mak.validator = Yup.string().required('Mohon untuk diisi.')
      ppk_id.validator = Yup.string().required('Mohon untuk diisi.')
      pok_id.hide = false
      mak.hide = false
      kode_mak.hide = false
      ppk_id.hide = false
    } else {
      pok_id.validator = Yup.number().required('Mohon untuk diisi.')
      mak.validator = Yup.string().required('Mohon untuk diisi ya.')
      kode_mak.validator = Yup.string().required('Mohon untuk diisi ya.')
      ppk_id.validator = Yup.string().required('Mohon untuk diisi ya.')
      pok_id.hide = false
      mak.hide = false
      kode_mak.hide = false
      ppk_id.hide = false
    }
  } else {
    form_app_id.hide = true
    form_app_detail.hide = true
    pkpt_id.hide = true
    lainnya.hide = true
    form_app_id.validator = Yup.number().required('Mohon untuk diisi.')
    form_app_detail.validator = Yup.string().required('Mohon untuk diisi.')
    pkpt_id.validator = Yup.number().required('Mohon untuk diisi.')
    lainnya.validator = Yup.string().required('Mohon untuk diisi.')
  }
  return _inputList
}
const onChange = async (e: BaseInputOnChangeProps, lastData: any, _inputList: FormInputProps[]) => {
  const data = {...e._data.get}
  const dataObj = {...e._data.getObject}
  const pok_id = formInputListSelector({formInputList: _inputList, name: 'pok_id'})
  const mak = formInputListSelector({formInputList: _inputList, name: 'mak'})
  const kode_mak = formInputListSelector({formInputList: _inputList, name: 'kode_mak'})
  const ppk_id = formInputListSelector({formInputList: _inputList, name: 'ppk_id'})
  let sumberPenugasan: string = dataObj?.sumber_penugasan || ''
  if (e.type === 'onChange' || e.type === 'onInit') {
    const formAppId = dataObj?.form_app_id
    if (dataObj?.form_app_id && lastData?.form_app_id !== data?.form_app_id) {
      const formInputDetail = formInputListSelector({
        formInputList: _inputList,
        name: 'form_app_detail',
      })
      formInputDetail.disabled = true
      formInputDetail.listData = []
      let listOptionResult: any[] = []
      const result = await getData('optionList=true', `/pengawasan_internal/form-app/${formAppId}`)
      const listOption = [...NullProof({input: result?.data, params: 'listOption', isMap: true})]
      for (const l of listOption) {
        listOptionResult.push({
          title: l?.label,
          value: l?.value,
        })
      }
      formInputDetail.listData = [...listOptionResult]
      formInputDetail.disabled = false
    }

    // NullProof({input: dataObj, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => {
    //   const pNamaKota1 = `usulan_tim_kota_${i + 1}`
    //   const pNamaKota2 = `usulan_tim_kota2_${i + 1}`
    //   const pNamaKota3 = `usulan_tim_kota3_${i + 1}`
    //   if (!data[pNamaKota2] && !data[pNamaKota3]) {
    //     e.setError({field: pNamaKota2, message: ''})
    //     e.setError({field: pNamaKota3, message: ''})
    //   } else if (
    //     data[pNamaKota1] !== data[pNamaKota2] &&
    //     data[pNamaKota1] !== data[pNamaKota3] &&
    //     data[pNamaKota2] !== data[pNamaKota3]
    //   ) {
    //     e.setError({field: pNamaKota1, message: ''})
    //     e.setError({field: pNamaKota2, message: ''})
    //     e.setError({field: pNamaKota3, message: ''})
    //     console.log('!')
    //   } else if (data[pNamaKota1] === data[pNamaKota2]) {
    //     e.setError({field: pNamaKota1, message: 'Kota tidak boleh sama Kota 2'})
    //     e.setError({field: pNamaKota2, message: 'Kota 2 tidak boleh sama Kota'})
    //     e.setError({field: pNamaKota3, message: ''})
    //     console.log('!!')
    //   } else if (data[pNamaKota2] === data[pNamaKota3]) {
    //     e.setError({field: pNamaKota1, message: ''})
    //     e.setError({field: pNamaKota2, message: 'Kota 2 tidak boleh sama Kota 3'})
    //     e.setError({field: pNamaKota3, message: 'Kota 3 tidak boleh sama Kota 2'})
    //     console.log('!!!')
    //   } else {
    //     e.setError({field: pNamaKota1, message: 'Kota tidak boleh sama Kota 3'})
    //     e.setError({field: pNamaKota2, message: ''})
    //     e.setError({field: pNamaKota3, message: 'Kota 3 tidak boleh sama Kota 1'})
    //     console.log('!!!!')
    //   }
    // })
    // console.log(dataObj)
    if (lastData?.pkpt_id !== data?.pkpt_id) {
      const api = e._apiData.get
      const pkpt_detail = api?.pkpt_id_detail
      let kepada: any[] = []
      let kepadaResult: any = {}
      for (let index = 0; index < data?.kepada || 0; index++) {
        let kepadaJson: any = {}
        formInputListSelector({formInputList: _inputList, name: 'kepada'}).component?.map((l) => {
          kepadaJson[l.name] = data[`kepada_${l.name}_${index + 1}`]
          delete data[`kepada_${l.name}_${index + 1}`]
        })
        if (!(kepada.filter((l: any) => l?.pegawai_id === kepadaJson?.pegawai_id)?.length > 0)) {
          kepada.push(kepadaJson)
        }
      }
      NullProof({input: pkpt_detail, params: 'list_auditor', isMap: true})?.map(
        (l: any, i: number) => {
          let kepadaJson: any = {
            pegawai_id: l?.pegawai_id,
            jabatan: l?.jawaban,
            hari: 0,
          }
          if (!(kepada.filter((l: any) => l?.pegawai_id === kepadaJson?.pegawai_id)?.length > 0)) {
            kepada.push(kepadaJson)
          }
        }
      )
      kepada.forEach((l, i) => {
        kepadaResult[`kepada_pegawai_id_${i + 1}`] = l.pegawai_id
        kepadaResult[`kepada_jabatan_${i + 1}`] = l.jabatan
        kepadaResult[`kepada_hari_${i + 1}`] = l.hari
      })
      const result = {
        ...data,
        tanggal_mulai: pkpt_detail?.tgl_mulai,
        tanggal_selesai: pkpt_detail?.tgl_selesai,
        kepada: kepada.length || 0,
        ...kepadaResult,
      }
      e?.resetForm({values: result})
    }
    if (data?.jenis === 'Non SPD') {
      pok_id.validator = Yup.string()
      mak.validator = Yup.string()
      kode_mak.validator = Yup.string()
      ppk_id.validator = Yup.string()
    }
  }
  return sumberPenugasan
}

const onLoadForm = (e: onLoadPropsForm) => {
  const data = e.data?._data.getObject

  // console.log("dataaa", data)
  const loadDataMember = async () => {
    e.initLoading()
    const eQuery = e.query?.get
    eQuery.kodekegiatan = data?.kode_mak
    eQuery.usulan_tim = JSON.stringify(data?.usulan_tim)
    const result = await getData(
      e.query?.post(eQuery) || '',
      e.id === 'pok_id' ? '/keuangan/form-pok' : `/keuangan/form-pok/${data?.pok_id}`
    )
    console.log('result', result)
    e.load({query: eQuery, result: result})
  }

  const loadDataKota = async (data: any) => {
    e.initLoading()
    const eQuery = e.query?.get
    eQuery.provinsi = data
    const result = await getData(e.query?.post(eQuery) || '', '/pengawasan_internal/data-kota')
    e.load({query: eQuery, result: result})
  }

  const loadDataSatker = async (provinsi: any, jenis_tujuan: any) => {
    e.initLoading()
    const eQuery = e.query?.get
    eQuery.provinsi = provinsi
    eQuery.jenis_tujuan = jenis_tujuan.toLowerCase()
    const result = await getData(e.query?.post(eQuery) || '', '/kepegawaian/data-satker-unit')
    e.load({query: eQuery, result: result})
  }

  const loadDataIrwil = async () => {
    e.initLoading()
    const eQuery = e.query?.get
    eQuery.orderby = 'asc'
    eQuery.jabatan = 'inspektur'
    const result = await getData(e.query?.post(eQuery) || '', '/kepegawaian/data-tenaga-audit')
    console.log('result', result)
    e.load({query: eQuery, result: result})
  }

  e.id === 'kode_mak' && loadDataMember()
  e.id === 'mak' && loadDataMember()
  e.id === 'pok_id' && loadDataMember()
  e.id === 'irwil_id' && loadDataIrwil()
  e.id === 'disposisi_id' && loadDataIrwil()
  data?.usulan_tim.map((l: any, i: number) => {
    const kota = `usulan_tim_kota_${i + 1}`
    const kota2 = `usulan_tim_kota2_${i + 1}`
    const kota3 = `usulan_tim_kota3_${i + 1}`

    e.id === kota && loadDataKota(l.provinsi)
    e.id === kota2 && loadDataKota(l.provinsi)
    e.id === kota3 && loadDataKota(l.provinsi)

    const satker = `usulan_tim_satker_id_${i + 1}`
    const satker2 = `usulan_tim_satker_id_2_${i + 1}`
    const satker3 = `usulan_tim_satker_id_3_${i + 1}`

    e.id === satker && loadDataSatker(l.provinsi, l.jenis_tujuan)
    e.id === satker2 && loadDataSatker(l.provinsi, l.jenis_tujuan_2)
    e.id === satker3 && loadDataSatker(l.provinsi, l.jenis_tujuan_3)
  })
  // NullProof({input: data, params: 'custom_api_components', isMap: true})?.map(
  //   (l: any, i: number) => {
  //     const name = `custom_api_components_custom_api_onload_${i + 1}`
  //     e.id === name && loadDataMember()
  //   }
  // )
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => {
            setData(val)
            // console.log("vallutama", val)
          }}
          {...TableInputData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Pengusul' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </TableColumn>
                  <TableColumn label='Kegiatan' props={{style: {minWidth: '200px'}}}>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Jenis Penugasan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                        {NullProof({input: l, params: 'jenis'})}
                      </div>
                    </div>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Sumber Penugasan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                        {NullProof({input: l, params: 'sumber_penugasan'})}
                      </div>
                    </div>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Nama Kegiatan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                        {NullProof({input: l, params: 'nama_kegiatan'})}
                      </div>
                    </div>
                  </TableColumn>
                  <TableColumn label='Status' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'tipe_usulan'}) === 'Draft' ? (
                      'Draft'
                    ) : (
                      <>
                        {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                          <div>
                            <div className='d-flex justify-content-center gap-2 mb-2'>
                              <div
                                className='rounded-circle'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  minWidth: '20px',
                                  backgroundColor: getStatusColor(
                                    NullProof({input: l, params: 'status_irwil', isLabel: false})
                                  ),
                                }}
                              ></div>
                              <div className='w-50'>
                                Riviu irwil :{' '}
                                <span
                                  style={{
                                    color: getStatusColor(
                                      NullProof({input: l, params: 'status_irwil'})
                                    ),
                                  }}
                                >
                                  {'  '} {NullProof({input: l, params: 'status_irwil'})}
                                </span>
                                <br />
                              </div>
                            </div>
                            <div className='d-flex justify-content-center gap-2 mb-2'>
                              <div
                                className='rounded-circle'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  minWidth: '20px',
                                  backgroundColor: getStatusColor(
                                    NullProof({
                                      input: l,
                                      params: 'status_disposisi',
                                      isLabel: false,
                                    })
                                  ),
                                }}
                              ></div>
                              <div className='w-50'>
                                Riviu Irjen :{' '}
                                <span
                                  style={{
                                    color: getStatusColor(
                                      NullProof({input: l, params: 'status_disposisi'})
                                    ),
                                  }}
                                >
                                  {'  '} {NullProof({input: l, params: 'status_disposisi'})}
                                </span>
                                <br />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='d-flex justify-content-center gap-2 mb-2'>
                          <div
                            className='rounded-circle'
                            style={{
                              width: '20px',
                              height: '20px',
                              minWidth: '20px',
                              backgroundColor: getStatusColor(
                                NullProof({input: l, params: 'status', isLabel: false})
                              ),
                            }}
                          ></div>
                          <div className='w-50'>
                            {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                              <span>Riviu Sekretaris : </span>
                            )}
                            {NullProof({input: l, params: 'type_pengusul'}) !== 'auditor' && (
                              <span>Riviu 1 : </span>
                            )}
                            <span
                              style={{
                                color: getStatusColor(NullProof({input: l, params: 'status'})),
                              }}
                            >
                              {'  '} {NullProof({input: l, params: 'status'})}
                            </span>
                            <br />
                          </div>
                        </div>
                        {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                          <div className='d-flex justify-content-center gap-2 mb-2'>
                            <div
                              className='rounded-circle'
                              style={{
                                width: '20px',
                                height: '20px',
                                minWidth: '20px',
                                backgroundColor: getStatusColor(
                                  NullProof({input: l, params: 'status_paraf', isLabel: false}) ===
                                    true
                                    ? 'Disetujui'
                                    : 'Ditolak'
                                ),
                              }}
                            ></div>
                            <div className='w-50'>
                              Paraf Ses :{' '}
                              <span
                                style={{
                                  color: getStatusColor(
                                    NullProof({input: l, params: 'status_paraf'}) === true
                                      ? 'Disetujui'
                                      : 'Ditolak'
                                  ),
                                }}
                              >
                                {'  '}{' '}
                                {NullProof({input: l, params: 'status_paraf'}) === true
                                  ? 'Sudah'
                                  : 'Belum'}
                              </span>
                              <br />
                            </div>
                          </div>
                        )}
                        <div className='d-flex justify-content-center gap-2 mb-2'>
                          <div
                            className='rounded-circle'
                            style={{
                              width: '20px',
                              height: '20px',
                              minWidth: '20px',
                              backgroundColor: getStatusColor(
                                NullProof({input: l, params: 'status_2', isLabel: false})
                              ),
                            }}
                          ></div>
                          <div className='w-50'>
                            {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                              <span>Riviu Irjen : </span>
                            )}
                            {NullProof({input: l, params: 'type_pengusul'}) !== 'auditor' && (
                              <span>Riviu 2 : </span>
                            )}
                            <span
                              style={{
                                color: getStatusColor(NullProof({input: l, params: 'status_2'})),
                              }}
                            >
                              {'  '} {NullProof({input: l, params: 'status_2'})}
                            </span>
                            <br />
                          </div>
                        </div>
                        <div className='d-flex justify-content-center gap-2 mb-2'>
                          <div
                            className='rounded-circle'
                            style={{
                              width: '20px',
                              height: '20px',
                              minWidth: '20px',
                              backgroundColor: getStatusColor(
                                NullProof({input: l, params: 'status_tte', isLabel: false}) === true
                                  ? 'Disetujui'
                                  : 'Ditolak'
                              ),
                            }}
                          ></div>
                          <div className='w-50'>
                            {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                              <span>TTE Irjen : </span>
                            )}
                            {NullProof({input: l, params: 'type_pengusul'}) !== 'auditor' && (
                              <span>TTE : </span>
                            )}
                            <span
                              style={{
                                color: getStatusColor(
                                  NullProof({input: l, params: 'status_tte'}) === true
                                    ? 'Disetujui'
                                    : 'Ditolak'
                                ),
                              }}
                            >
                              {'  '}{' '}
                              {NullProof({input: l, params: 'status_tte'}) === true
                                ? 'Sudah'
                                : 'Belum'}
                            </span>
                            <br />
                          </div>
                        </div>
                        {NullProof({input: l, params: 'status_revisi'}) === 'Revisi' && (
                          <div className='d-flex justify-content-center gap-2 mb-2'>
                            <div
                              className='rounded-circle'
                              style={{
                                width: '20px',
                                height: '20px',
                                minWidth: '20px',
                                backgroundColor: getStatusColor(
                                  NullProof({input: l, params: 'status_revisi'}) === 'Revisi'
                                    ? 'Disetujui'
                                    : 'Ditolak'
                                ),
                              }}
                            ></div>
                            <div className='w-50'>
                              <span>Revisi : </span>
                              <span>
                                {formatDate({
                                  date: NullProof({input: l, params: 'revisi_tgl'}),
                                  dateFormat: 'dd MMMM yyyy',
                                })}{' '}
                                <br />
                                {formatDate({
                                  date: NullProof({input: l, params: 'revisi_tgl'}),
                                  dateFormat: 'HH:mm:ss',
                                })}
                              </span>
                              <br />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </TableColumn>
                  <TableColumn label='Dibuat Pada' props={{style: {minWidth: '200px'}}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'createdAt'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}{' '}
                    <br />
                    {formatDate({
                      date: NullProof({input: l, params: 'createdAt'}),
                      dateFormat: 'HH:mm:ss',
                    })}
                  </TableColumn>
                  {/* <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </td>
                  <td style={{minWidth: '300px'}}>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Jenis Penugasan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                          {NullProof({input: l, params: 'jenis'})}
                      </div>
                    </div>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Sumber Penugasan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                          {NullProof({input: l, params: 'sumber_penugasan'})}
                      </div>
                    </div>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Nama Kegiatan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                          {NullProof({input: l, params: 'nama_kegiatan'})}
                      </div>
                    </div>
                  </td>
                  <td style={{minWidth: '300px'}}>
                      <div className='d-flex justify-content-center gap-2 mb-2'>
                        <div
                          className='rounded-circle'
                          style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            backgroundColor: getStatusColor(
                              NullProof({input: l, params: 'status', isLabel: false})
                            ),
                          }}
                        ></div>
                        <div className='w-50'> Riviu 1 :
                          <span style={{color: getStatusColor(NullProof({input: l, params: 'status'}))}}>
                           {'  '} {NullProof({input: l, params: 'status'})}
                          </span>
                        <br /></div>
                      </div>
                      <div className='d-flex justify-content-center gap-2 mb-2'>
                        <div
                          className='rounded-circle'
                          style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            backgroundColor: getStatusColor(
                              NullProof({input: l, params: 'status_2', isLabel: false})
                            ),
                          }}
                        ></div>
                        <div className='w-50'> Riviu 2 :
                          <span style={{color: getStatusColor(NullProof({input: l, params: 'status_2'}))}}>
                           {'  '} {NullProof({input: l, params: 'status_2'})}
                          </span>
                        <br /></div>
                      </div> */}
                  {/* Riviu 1 : {NullProof({input: l, params: 'status'})} <br />
                    Riviu 2 : {NullProof({input: l, params: 'status_2'})} */}
                  {/* </td> */}
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

interface HighlightRevisiOptions extends Omit<NullProofProps, 'input' | 'params'> {}

const HighlightRevisi: FC<
  {
    id: string
    list?: any[]
    options?: HighlightRevisiOptions
    revisiChild?: ReactNode
  } & WithChildren
> = ({id, list = [], children, options, revisiChild}) => {
  const [_list, _setList] = useState<any[]>([])
  useEffect(() => {
    _setList([...list])
  }, [list])
  const _id = id.split('.')
  const data = {..._list.find((e: any) => e?.id === _id[0])}
  return (
    <div className={`d-flex flex-column gap-2`}>
      <div
        className={`${
          _list?.some((e: any) => e?.id === _id[0]) ? 'bg-success text-white px-4 py-2' : ''
        }`}
      >
        {children}
      </div>
      {_list?.some((e: any) => e?.id === _id[0]) && (
        <div className='bg-danger text-white px-4 py-2'>
          {revisiChild ? (
            revisiChild
          ) : (
            <>{NullProof({input: data, params: 'revisi', ...options})}</>
          )}
        </div>
      )}
    </div>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>({})
  // const [modal, setModal] = useState<boolean>(false)
  const [_inputStatus, _setInputStatus] = useState<FormInputProps[]>(inputListAll)
  const [modal_irwil, setModal_irwil] = useState<boolean>(false)
  const [modal_disposisi, setModal_disposisi] = useState<boolean>(false)
  const [modal_status, setModal_status] = useState<boolean>(false)
  const [modal_status_2, setModal_status_2] = useState<boolean>(false)
  const [modal_pass, setModal_pass] = useState<boolean>(false)
  const [modal_pass_tte, setModal_pass_tte] = useState<boolean>(false)
  const [key, setKey] = useState<number>(0)
  const {auth} = useAuth()
  const idPegawai = NullProof({input: auth?.data, params: 'pegawai_id'})
  const idUser = NullProof({input: auth?.data, params: 'id'})
  const [dataRevisi, setDataRevisi] = useState<any[]>([])

  return (
    <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
      <Fragment key={key}>
        <Modal
          id='modal_status_pass'
          isShow={modal_pass}
          onClose={() => {
            setModal_pass(false)
          }}
          title='Ubah Status'
        >
          <FormInput
            input={formInputPass}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
              type: 'put',
              useServerMessage: true,
              onFeedback: (e: any) => {
                if (e?.status) {
                  setKey((p) => p + 1)
                }
                setModal_pass(false)
              },
            }}
            isModal
          />
        </Modal>
        <Modal
          id='modal_status_pass_tte'
          isShow={modal_pass_tte}
          onClose={() => {
            setModal_pass_tte(false)
          }}
          title='Ubah Status'
        >
          <FormInput
            input={formInputPass}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id/ubah-status-2`, query),
              type: 'put',
              useServerMessage: true,
              onFeedback: (e: any) => {
                if (e?.status) {
                  setKey((p) => p + 1)
                }
                setModal_pass_tte(false)
              },
            }}
            isModal
          />
        </Modal>
        <Modal
          id='modal_status_irwil'
          isShow={modal_irwil}
          onClose={() => {
            setModal_irwil(false)
          }}
          title='Ubah Status'
        >
          <FormInput
            input={formInputStatusIrwil}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
              type: 'put',
              onFeedback: (e: any) => {
                if (e?.status) {
                  setKey((p) => p + 1)
                }
                setModal_irwil(false)
              },
            }}
            isModal
          />
        </Modal>
        <Modal
          id='modal_status_disposisi'
          isShow={modal_disposisi}
          onClose={() => {
            setModal_disposisi(false)
          }}
          title='Ubah Status'
        >
          <FormInput
            input={formInputStatusdisposisi}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
              type: 'put',
              onFeedback: (e: any) => {
                if (e?.status) {
                  setKey((p) => p + 1)
                }
                setModal_disposisi(false)
              },
            }}
            isModal
          />
        </Modal>
        <Modal
          id='modal_status'
          isShow={modal_status}
          onClose={() => {
            setModal_status(false)
          }}
          title='Ubah Status'
        >
          <FormInput
            input={
              NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                ? formInputStatusPass
                : formInputStatus
            }
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
              type: 'put',
              onFeedback: (e: any) => {
                if (e?.status) {
                  setKey((p) => p + 1)
                }
                setModal_status(false)
              },
            }}
            onChange={async (e) => {
              let getStatus = e._data.getObject?.status
              _setInputStatus(dynamicInputList(getStatus))
            }}
            isModal
          />
        </Modal>
        <Modal
          id='modal_status_2'
          isShow={modal_status_2}
          onClose={() => {
            setModal_status_2(false)
          }}
          title='Ubah Status'
        >
          <FormInput
            input={formInputStatus2}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id/ubah-status-2`, query),
              type: 'put',
              useServerMessage: true,
              onFeedback: (e: any) => {
                if (e?.status) {
                  setKey((p) => p + 1)
                }
                setModal_status_2(false)
              },
            }}
            onChange={async (e) => {
              let getStatus = e._data.getObject?.status_2
              _setInputStatus(dynamicInputList(getStatus))
            }}
            isModal
          />
        </Modal>
        <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
          {PageData.detail?.page.breadcrumb.title}
        </PageTitle>
        <div className='d-flex flex-column gap-4'>
          <CardUi
            title={PageData.detail?.page.breadcrumb.title}
            toolbars={
              <>
                {NullProof({input: data, params: 'tipe_usulan'}) === 'Draft' ? (
                  <ButtonKirimNaskah data={data} />
                ) : null}
                {NullProof({input: data, params: 'list_revisi', isMap: true}).length ? (
                  <div className='d-flex gap-2'>
                    <select
                      className='form-select'
                      onChange={(e) => {
                        let result: any[] = []
                        if (Number(e.target.value || -1) !== -1) {
                          result = [...result, ...getObjectDifferences(data, data?.list_revisi)]
                        }
                        setDataRevisi(result)
                      }}
                    >
                      <option value={''}>Pilih Versi...</option>
                      {NullProof({input: data, params: 'list_revisi', isMap: true})?.map(
                        (l: any, i: number) => (
                          <Fragment key={i}>
                            <option value={i}>{`Versi ${i + 1}.0`}</option>
                          </Fragment>
                        )
                      )}
                    </select>
                  </div>
                ) : null}
              </>
            }
          >
            <CardUi>
              <TableInputDetailColumn title={{value: 'Pengusul'}}>
                <HighlightRevisi id='pengusul.nama' list={dataRevisi}>
                  {NullProof({input: data, params: 'pengusul.nama'})}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Role Pengusul'}}>
                <HighlightRevisi id='pengusul.user.roles.name' list={dataRevisi}>
                  {NullProof({input: data, params: 'pengusul.user.roles.name'})}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Jenis Penugasan'}}>
                <HighlightRevisi id='jenis' list={dataRevisi}>
                  {NullProof({input: data, params: 'jenis'})}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Sumber Penugasan'}}>
                <HighlightRevisi id='sumber_penugasan' list={dataRevisi}>
                  {NullProof({input: data, params: 'sumber_penugasan'})}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Nama Kegiatan'}}>
                <HighlightRevisi id='nama_kegiatan' list={dataRevisi}>
                  {NullProof({input: data, params: 'nama_kegiatan'})}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Deskripsi Kegiatan'}}>
                <HighlightRevisi id='deskripsi_kegiatan' list={dataRevisi}>
                  {NullProof({input: data, params: 'deskripsi_kegiatan'})}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Tujuan Usulan Tim'}}>
                <ol className='m-0 ps-6'>
                  {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => {
                      const listKota = (): string[] => {
                        let kota: string[] = []
                        if (l?.kota) {
                          kota.push(l?.kota)
                        }
                        if (l?.kota2) {
                          kota.push(l?.kota2)
                        }
                        if (l?.kota3) {
                          kota.push(l?.kota3)
                        }
                        return kota
                      }
                      return (
                        <Fragment key={i}>
                          <li>
                            <div className='d-flex flex-column gap-1'>
                              <div className='d-flex gap-2'>
                                <div className='text-capitalize'>
                                  {`${NullProof({
                                    input: l,
                                    params: 'provinsi',
                                  })} - ${listKota().join(' - ')}`.toLowerCase()}
                                </div>
                              </div>
                              <div className='d-flex flex-column'>
                                <div className='text-capitalize fw-bold'>Jenis Tujuan : </div>
                                {NullProof({input: l, params: 'jenis_tujuan', isLabel: false}) && (
                                  <div className='text-capitalize'>
                                    - {NullProof({input: l, params: 'jenis_tujuan'})}
                                  </div>
                                )}
                                {NullProof({
                                  input: l,
                                  params: 'jenis_tujuan_2',
                                  isLabel: false,
                                }) && (
                                  <div className='text-capitalize'>
                                    - {NullProof({input: l, params: 'jenis_tujuan_2'})}
                                  </div>
                                )}
                                {NullProof({
                                  input: l,
                                  params: 'jenis_tujuan_3',
                                  isLabel: false,
                                }) && (
                                  <div className='text-capitalize'>
                                    - {NullProof({input: l, params: 'jenis_tujuan_3'})}
                                  </div>
                                )}
                              </div>
                              <div className='d-flex flex-column'>
                                <div className='text-capitalize fw-bold'>Satuan Kerja : </div>
                                <div className='text-capitalize'>
                                  {`${NullProof({input: l, params: 'satker.kode'})} - ${NullProof({
                                    input: l,
                                    params: 'satker.nama_satker',
                                  })}`}
                                </div>
                              </div>
                              <div className='d-flex flex-column'>
                                <div className='text-capitalize fw-bold'>Tanggal : </div>
                                <div>
                                  {`${NullProof({
                                    input: l,
                                    params: 'tanggal_mulai',
                                    type: 'date',
                                  })} s/d ${NullProof({
                                    input: l,
                                    params: 'tanggal_selesai',
                                    type: 'date',
                                  })}`}
                                </div>
                              </div>
                            </div>
                          </li>
                        </Fragment>
                      )
                    }
                  )}
                </ol>
              </TableInputDetailColumn>
              {NullProof({input: data, params: 'file_pendukung_json', isMap: true}).map(
                (l: any, i: number) => {
                  return (
                    <Fragment key={i}>
                      <TableInputDetailColumn title={{value: `File Pendukung ${i + 1}`}}>
                        {NullProof({input: l, params: 'file_pendukung', isLabel: false}) ? (
                          <ModalButtonUi
                            options={{
                              modal: {
                                id: `modal_file_pendukung ${i + 1}`,
                                title: `File Pendukung ${i + 1}`,
                              },
                              button: {title: 'Lihat Dokumen'},
                            }}
                          >
                            <ReactPdfViewer
                              url={`/${NullProof({
                                input: l,
                                params: 'file_pendukung',
                                isLabel: false,
                              })}`}
                            />
                          </ModalButtonUi>
                        ) : (
                          '_'
                        )}
                      </TableInputDetailColumn>
                    </Fragment>
                  )
                }
              )}
              {NullProof({input: data, params: 'file_pendukung_json', isMap: true}).map(
                (l: any, i: number) => {
                  return (
                    <Fragment key={i}>
                      <TableInputDetailColumn title={{value: `Keterangan File Pendukung ${i + 1}`}}>
                        <HighlightRevisi id='file_pendukung_keterangan' list={dataRevisi}>
                          {NullProof({input: l, params: 'file_pendukung_keterangan'})}
                        </HighlightRevisi>
                      </TableInputDetailColumn>
                    </Fragment>
                  )
                }
              )}
              <TableInputDetailColumn title={{value: 'Dasar'}}>
                <HighlightRevisi
                  id='dasarnya'
                  list={dataRevisi}
                  revisiChild={
                    <>
                      {NullProof({
                        input: objectListSelector({
                          data: dataRevisi,
                          name: 'id',
                          search: 'dasarnya',
                        }),
                        params: 'dasarnya',
                        isMap: true,
                      })?.map((l: any, i: number) => (
                        <Fragment key={i}>
                          <div>
                            <span className='fw-bold'>- </span>
                            {NullProof({input: l, params: 'detail'})}
                          </div>
                        </Fragment>
                      ))}
                    </>
                  }
                >
                  {NullProof({input: data, params: 'dasarnya', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <div>
                          <span className='fw-bold'>- </span>
                          {NullProof({input: l, params: 'detail'})}
                        </div>
                      </Fragment>
                    )
                  )}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Menimbang'}}>
                {NullProof({input: data, params: 'menimbang', isMap: true})?.map(
                  (l: any, i: number) => (
                    <Fragment key={i}>
                      <div>
                        <span className='fw-bold'>- </span>
                        {NullProof({input: l, params: 'detailMenimbang'})}
                      </div>
                    </Fragment>
                  )
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Preview ST'}}>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => navigate(RouterQueryParams(`./previewst-pdf`, query))}
                >
                  Lihat Preview
                </button>
              </TableInputDetailColumn>
            </CardUi>
          </CardUi>

          <CardUi title={PageData.detail?.anggotaTim.title}>
            <TableInputDetailColumn title={{value: 'Anggota'}}>
              <button
                className='btn btn-primary btn-sm'
                onClick={() => navigate(RouterQueryParams(`./anggota-pdf`, query))}
              >
                Lihat Dokumen
              </button>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Adjustment RAB'}}>
              <button
                className='btn btn-primary btn-sm'
                onClick={() => navigate(RouterQueryParams(`./adjustment-rab`, query))}
              >
                Adjustment RAB
              </button>
            </TableInputDetailColumn>
          </CardUi>
          {/* <CardUi title='List Usulan dan ST dengan Nama dan tujuan yang Sama'>
            <Accordion
              enabled
              title='List Usulan Pegawai Sama'
              props={{
                body: {
                  className: 'pb-4',
                },
              }}
            >
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <div className='mt-4'>
                      <TableListUsulanByDate
                        dataInput={l}
                        pegawaiId={data?.pegawai_id}
                        indexs={i}
                      />
                    </div>
                  </Fragment>
                )
              )}
            </Accordion>
            <Accordion
              enabled
              title='List Usulan Tujuan Satker Sama'
              props={{
                body: {
                  className: 'pb-4',
                },
              }}
            >
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableListTujuanSatker dataInput={l} indexs={i} />
                  </Fragment>
                )
              )}
            </Accordion>
            <Accordion
              enabled
              title='List Surat Tugas Pegawai Sama'
              props={{
                body: {
                  className: 'pb-4',
                },
              }}
            >
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableListSTByDate dataInput={l} pegawaiId={data?.pegawai_id} indexs={i} />
                  </Fragment>
                )
              )}
            </Accordion>
            <Accordion
              enabled
              title='List Surat Tugas Tujuan Satker Sama'
              props={{
                body: {
                  className: 'pb-4',
                },
              }}
            >
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableListStTujuanSatker dataInput={l} indexs={i} />
                  </Fragment>
                )
              )}
            </Accordion>
          </CardUi> */}
          {/* <TabsUi
          title='List Usulan dan ST dengan Nama dan tujuan yang Sama'
          tabs={[
            {
              id: 'list_usulan_pegaawai_sama',
              title: 'List Usulan Pegawai Sama',
              children: (
                <>
                  {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <div className='mt-4'>
                          <TableListUsulanByDate
                            dataInput={l}
                            pegawaiId={data?.pegawai_id}
                            indexs={i}
                          />
                        </div>
                      </Fragment>
                    )
                  )}
                </>
              ),
            },
            {
              id: 'list_usulan_tujuan_satker_sama',
              title: 'List Usulan Tujuan Satker Sama',
              children: (
                <>
                  {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableListTujuanSatker dataInput={l} indexs={i} />
                      </Fragment>
                    )
                  )}
                </>
              ),
            },
            {
              id: 'list_st_pegawai_sama',
              title: 'List Surat Tugas Pegawai Sama',
              children: (
                <>
                  {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableListSTByDate dataInput={l} pegawaiId={data?.pegawai_id} indexs={i} />
                      </Fragment>
                    )
                  )}
                </>
              ),
            },
            {
              id: 'list_st_tujuan_satker_sama',
              title: 'List Surat Tugas Tujuan Satker Sama',
              children: (
                <>
                  {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableListStTujuanSatker dataInput={l} indexs={i} />
                      </Fragment>
                    )
                  )}
                </>
              ),
            },
          ]}
        /> */}
          {/* <CardUi title='List Usulan Pegawai Sama'>
            {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <TableListUsulanByDate dataInput={l} pegawaiId={data?.pegawai_id} indexs={i} />
              </Fragment>
            ))}
        </CardUi>
        <CardUi title='List Usulan Tujuan Satker Sama'>
          {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => (
            <Fragment key={i}>
              <TableListTujuanSatker dataInput={l} indexs={i} />
            </Fragment>
          ))}
        </CardUi>
        <CardUi title='List Surat Tugas Pegawai Sama'>
            {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <TableListSTByDate dataInput={l} pegawaiId={data?.pegawai_id} indexs={i} />
              </Fragment>
              ))}
        </CardUi>
        <CardUi title='List Surat Tugas Tujuan Satker Sama'>
          {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => (
            <Fragment key={i}>
              <TableListStTujuanSatker dataInput={l} indexs={i} />
            </Fragment>
          ))}
        </CardUi> */}

          <CardUi title={PageData.detail?.anggaranTim.title}>
            <TableInputDetailColumn title={{value: 'Anggaran Tim'}}>
              {ConvertCurrency(
                NullProof({input: data, params: 'usulan_tim', isMap: true})
                  ?.map((l: any, i: number) => l.totalRAB)
                  ?.reduce((acc: any, curr: any) => acc + curr, 0)
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'MAK'}}>
              <HighlightRevisi id='mak' list={dataRevisi}>
                {NullProof({input: data, params: 'mak'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nama Program'}}>
              {NullProof({input: data, params: 'detail_mak.namaProgram'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nama Kegiatan'}}>
              {NullProof({input: data, params: 'detail_mak.namaKegiatan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Sisa Anggaran'}}>
              {ConvertCurrency(
                NullProof({input: data, params: 'detail_mak.anggaran'}) -
                  NullProof({input: data, params: 'usulan_tim', isMap: true})
                    ?.map((l: any, i: number) => l.totalRAB)
                    ?.reduce((acc: any, curr: any) => acc + curr, 0)
              )}
            </TableInputDetailColumn>
            {/* <TableInputDetailColumn title={{value: 'Sisa Anggaran'}}>
                {ConvertCurrency(
                  NullProof({input: data, params: 'anggaran'}) -
                    NullProof({input: data, params: 'usulan_tim', isMap: true})
                      ?.map((l: any, i: number) => l.totalRAB)
                      ?.reduce((acc: any, curr: any) => acc + curr, 0)
                )}
              </TableInputDetailColumn> */}
          </CardUi>

          <CardUi title={PageData.detail?.review.title}>
            {NullProof({input: data, params: 'type_pengusul'}) === 'auditor' && (
              <div>
                <TableInputDetailColumn title={{value: 'Status Inspektur Wilayah'}}>
                  <div className='d-flex align-items-center gap-2'>
                    <HighlightRevisi id='status_irwil' list={dataRevisi}>
                      <div>{NullProof({input: data, params: 'status_irwil'})}</div>
                    </HighlightRevisi>
                    <div>
                      {(idPegawai === NullProof({input: data, params: 'irwil_id'}) ||
                        idUser === 1) && (
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            setModal_irwil(true)
                          }}
                        >
                          Ubah Status
                        </button>
                      )}
                    </div>
                  </div>
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'PenandaTangan Inspektur Wilayah'}}>
                  <HighlightRevisi id='irwil.nama' list={dataRevisi}>
                    {NullProof({input: data, params: 'irwil.nama'})}
                  </HighlightRevisi>
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  Inspektur Wilayah'}}>
                  <HighlightRevisi
                    id='tgl_ttd_irwil'
                    list={dataRevisi}
                    options={{type: 'date', option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}}}}
                  >
                    {formatDate({
                      date: NullProof({input: data, params: 'tgl_ttd_irwil'}),
                      dateFormat: 'dd MMMM yyyy, HH:mm:ss',
                    })}
                  </HighlightRevisi>
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Catatan Inspektur Wilayah'}}>
                  <HighlightRevisi id='catatan' list={dataRevisi}>
                    {NullProof({input: data, params: 'catatan_irwil'})}
                  </HighlightRevisi>
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Status Disposisi'}}>
                  <div className='d-flex align-items-center gap-2'>
                    <HighlightRevisi id='status_disposisi' list={dataRevisi}>
                      <div>{NullProof({input: data, params: 'status_disposisi'})}</div>
                    </HighlightRevisi>
                    {(idPegawai === NullProof({input: data, params: 'disposisi_id'}) ||
                      idUser === 1) &&
                      NullProof({input: data, params: 'status_irwil', isLabel: false}) &&
                      data.status_irwil !== 'Ditolak' && (
                        <div>
                          <button
                            className='btn btn-primary btn-sm'
                            onClick={() => {
                              setModal_disposisi(true)
                            }}
                          >
                            Ubah Status
                          </button>
                        </div>
                      )}
                  </div>
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'PenandaTangan Disposisi'}}>
                  <HighlightRevisi id='disposisi.nama' list={dataRevisi}>
                    {NullProof({input: data, params: 'disposisi.nama'})}
                  </HighlightRevisi>
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan Disposisi'}}>
                  <HighlightRevisi
                    id='tgl_ttd_disposisi'
                    list={dataRevisi}
                    options={{type: 'date', option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}}}}
                  >
                    {formatDate({
                      date: NullProof({input: data, params: 'tgl_ttd_disposisi'}),
                      dateFormat: 'dd MMMM yyyy, HH:mm:ss',
                    })}
                  </HighlightRevisi>
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Catatan Disposisi'}}>
                  <HighlightRevisi id='catatan_disposisi' list={dataRevisi}>
                    {NullProof({input: data, params: 'catatan_disposisi'})}
                  </HighlightRevisi>
                </TableInputDetailColumn>
              </div>
            )}
            <TableInputDetailColumn
              title={{
                value: `${
                  NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                    ? 'Status Reviu Sekretaris'
                    : 'Status Reviu 1'
                }`,
              }}
            >
              <div className='d-flex align-items-center gap-2'>
                <HighlightRevisi id='status' list={dataRevisi}>
                  <div>{NullProof({input: data, params: 'status'})}</div>
                </HighlightRevisi>
                <div>
                  {(idPegawai === NullProof({input: data, params: 'penandatangan_st'}) ||
                    idUser === 1) && (
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal_status(true)
                      }}
                    >
                      Ubah Status
                    </button>
                  )}
                </div>
              </div>
            </TableInputDetailColumn>
            {NullProof({input: data, params: 'status', isLabel: false}) &&
              data.status !== 'Ditolak' &&
              NullProof({input: data, params: 'type_pengusul'}) === 'auditor' && (
                <TableInputDetailColumn title={{value: 'Paraf Usulan'}}>
                  <div className='d-flex align-items-center gap-2'>
                    <HighlightRevisi id='status' list={dataRevisi}>
                      <div>
                        {NullProof({input: data, params: 'status_paraf'}) === true
                          ? 'Sudah'
                          : 'Belum'}
                      </div>
                    </HighlightRevisi>
                    <div>
                      {(idPegawai === NullProof({input: data, params: 'penandatangan_st'}) ||
                        idUser === 1) && (
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            setModal_pass(true)
                          }}
                        >
                          Ubah
                        </button>
                      )}
                    </div>
                  </div>
                </TableInputDetailColumn>
              )}
            <TableInputDetailColumn
              title={{
                value: `${
                  NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                    ? 'PenandaTangan Sekretaris'
                    : 'PenandaTangan Reviu 1'
                }`,
              }}
            >
              <HighlightRevisi id='penandatangan.nama' list={dataRevisi}>
                {NullProof({input: data, params: 'penandatangan.nama'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: `${
                  NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                    ? 'Tanggal Tanda Tangan  Sekretaris'
                    : 'Tanggal Tanda Tangan  Reviu 1'
                }`,
              }}
            >
              <HighlightRevisi
                id='tgl_ttd'
                list={dataRevisi}
                options={{type: 'date', option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}}}}
              >
                {formatDate({
                  date: NullProof({input: data, params: 'tgl_ttd'}),
                  dateFormat: 'dd MMMM yyyy, HH:mm:ss',
                })}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: `${
                  NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                    ? 'Catatan Reviu 1 Sekretaris'
                    : 'Catatan Reviu 1'
                }`,
              }}
            >
              <HighlightRevisi id='catatan' list={dataRevisi}>
                {NullProof({input: data, params: 'catatan'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: `${
                  NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                    ? 'Status Irjen'
                    : 'Status Reviu 2'
                }`,
              }}
            >
              <div className='d-flex align-items-center gap-2'>
                <HighlightRevisi id='status_2' list={dataRevisi}>
                  <div>{NullProof({input: data, params: 'status_2'})}</div>
                </HighlightRevisi>
                {(idPegawai === NullProof({input: data, params: 'penandatangan_st_2'}) ||
                  idUser === 1) &&
                  data.status === 'Disetujui' && (
                    <div>
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                          setModal_status_2(true)
                        }}
                      >
                        Ubah Status
                      </button>
                    </div>
                  )}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: `${
                  NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                    ? 'TTE Irjen'
                    : 'TTE Usulan'
                }`,
              }}
            >
              <div className='d-flex align-items-center gap-2'>
                <HighlightRevisi id='status' list={dataRevisi}>
                  <div>
                    {NullProof({input: data, params: 'status_tte'}) === true ? 'Sudah' : 'Belum'}
                  </div>
                </HighlightRevisi>
                <div>
                  {(idPegawai === NullProof({input: data, params: 'penandatangan_st_2'}) ||
                    idUser === 1) &&
                    data.status_2 === 'Disetujui' && (
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                          setModal_pass_tte(true)
                        }}
                      >
                        Ubah
                      </button>
                    )}
                </div>
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: `${
                  NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                    ? 'PenandaTangan Irjen'
                    : 'PenandaTangan Reviu 2'
                }`,
              }}
            >
              <HighlightRevisi id='penandatangan_2.nama' list={dataRevisi}>
                {NullProof({input: data, params: 'penandatangan_2.nama'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: `${
                  NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                    ? 'Tanggal Tanda Tangan Irjen'
                    : 'Tanggal Tanda Tangan Reviu 2'
                }`,
              }}
            >
              <HighlightRevisi
                id='tgl_ttd_2'
                list={dataRevisi}
                options={{type: 'date', option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}}}}
              >
                {formatDate({
                  date: NullProof({input: data, params: 'tgl_ttd_2'}),
                  dateFormat: 'dd MMMM yyyy, HH:mm:ss',
                })}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: `${
                  NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                    ? 'Catatan Irjen'
                    : 'Catatan Reviu 2'
                }`,
              }}
            >
              <HighlightRevisi id='catatan_2' list={dataRevisi}>
                {NullProof({input: data, params: 'catatan_2'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
          </CardUi>
        </div>
      </Fragment>
    </TableInputDetail>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

const PdfWrapperPreviewST: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [result, setResult] = useState<any>([])
  // let resultArray: any[] = []
  console.log('result', result)

  return (
    <>
      <CardUi title={''}>
        <TableInputDetail
          onSuccess={(val: any) => {
            setData(val)
            // console.log("vallPDFFF", val)
            const resultArray = NullProof({input: val, params: 'usulan_tim', isMap: true})?.map(
              (l: any, i: number) => ({
                ...val,
                usulan_tim: l,
              })
            )
            setResult(resultArray)
            console.log('result', result)
          }}
          {...TableInputDetailData}
        >
          {result?.map((l: any, i: number) => {
            console.log('isi l', l)
            return (
              <div key={i}>
                <PdfPageViewer
                  route={RouterQueryParams(`${InfoData.api}/:id`, query)}
                  PageData={{
                    breadcrumb: PageData.pdfPreviewST?.page.breadcrumb.data || [],
                    title: PageData.pdfPreviewST?.page.breadcrumb.title || '',
                  }}
                  PdfLayout={PdfLayoutPreviewST}
                  PdfData={l}
                />
              </div>
            )
          })}
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, DetailWrapper, PdfWrapper, PdfWrapperPreviewST}
