import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../helper/template/FormInputTemplate'

const filterFormInputList: FormInputProps[] = [
  ...inputLayoutBuilder({
    input: [{template: 'search'}, {template: 'status'}],
  }),
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'SETUJU',
        value: 'SETUJU',
      },
      {
        title: 'BELUM',
        value: 'BELUM',
      },
      {
        title: 'TOLAK',
        value: 'TOLAK',
      },
    ],
    validator: Yup.string().required('Status wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Catatan untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Catatan wajib diisi bila status: DITOLAK',
      },
    },
  },
]

const formTandaTanganElektronik: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'nomor_naskah',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Nomor Naskah wajib diisi'),
    options: {
      input: {
        props: {
          title: 'Nomor Naskah',
          placeholder: 'Nomor Naskah',
        },
      },
      datalist: {
        api: '/tata_usaha/penomoran',
        params: 'variabel_1,variabel_2,variabel_3,variabel_4,variabel_5',
        result: '$variabel_1/$variabel_2/$variabel_3/$variabel_4/$variabel_5',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'label',
    type: 'label',
    value: '',
    validator: Yup.string().required('Catatan untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-8',
    name: 'nik',
    type: 'input',
    validator: Yup.string().required('Nomor Induk Kependudukan wajib untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor Induk Kependudukan',
          placeholder: 'Nomor Induk Kependudukan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'key_phrase',
    type: 'input',
    validator: Yup.string().required('Key Phrase wajib untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Key Phrase',
          placeholder: 'Masukkan Key Phrase',
        },
      },
    },
  },
]

export {formInputList, formTandaTanganElektronik, filterFormInputList}