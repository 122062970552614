/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../helper/AppFunction'
import FormInput from '../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import {formatDate} from '../../../helper/DateFns'
import {PdfPageViewer} from '../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'

const onChange = (e: any) => {
  const periodeStart = Number(e?._data?.get?.periode_start)
  const result = {...e?._data?.get, periode_end: periodeStart + 5}
  e?.resetForm({values: result})
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Auditi' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'satker.nama_satker'})}
                  </TableColumn>
                  <TableColumn label='Periode Audit 5 Tahun' props={{style: {minWidth: '200px'}}}>
                    {`${NullProof({input: l, params: 'periode_start'})} - ${NullProof({
                      input: l,
                      params: 'periode_end',
                    })}`}
                  </TableColumn>
                  <TableColumn label='Tanggal LHA Terakhir' props={{style: {minWidth: '200px'}}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'tgl_lha_terakhir'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableColumn label='Jenis Audit' props={{style: {minWidth: '300px'}}}>
                    {NullProof({input: l, params: 'jenis_audit'})}
                  </TableColumn>
                  <TableColumn label='Frekuensi Audit' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'frekuensi_audit'})}
                  </TableColumn>
                  <TableColumn label='Tahun' props={{style: {minWidth: '200px'}}}>
                    <div>
                      <span className='fw-bold'>X1 : </span>
                      <span>{NullProof({input: l, params: 'cek_1'}) ? 'Ya' : 'Tidak'}</span>
                    </div>
                    <div>
                      <span className='fw-bold'>X2 : </span>
                      <span>{NullProof({input: l, params: 'cek_2'}) ? 'Ya' : 'Tidak'}</span>
                    </div>
                    <div>
                      <span className='fw-bold'>X3 : </span>
                      <span>{NullProof({input: l, params: 'cek_3'}) ? 'Ya' : 'Tidak'}</span>
                    </div>
                    <div>
                      <span className='fw-bold'>X4 : </span>
                      <span>{NullProof({input: l, params: 'cek_4'}) ? 'Ya' : 'Tidak'}</span>
                    </div>
                    <div>
                      <span className='fw-bold'>X5 : </span>
                      <span>{NullProof({input: l, params: 'cek_5'}) ? 'Ya' : 'Tidak'}</span>
                    </div>
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Periode Audit 5 Tahun'}}>
            {`${NullProof({input: data, params: 'periode_start'})} - ${NullProof({
              input: data,
              params: 'periode_end',
            })}`}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal LHA Terakhir'}}>
            {formatDate({
              date: NullProof({input: data, params: 'tgl_lha_terakhir'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jenis Audit'}}>
            {NullProof({input: data, params: 'jenis_audit'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Frekuensi Audit'}}>
            {NullProof({input: data, params: 'frekuensi_audit'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tahun'}}>
            <TableInputDetailColumn hideDivider title={{value: 'X1'}}>
              {NullProof({input: data, params: 'cek_1'}) ? 'Ya' : 'Tidak'}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'X2'}}>
              {NullProof({input: data, params: 'cek_2'}) ? 'Ya' : 'Tidak'}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'X3'}}>
              {NullProof({input: data, params: 'cek_3'}) ? 'Ya' : 'Tidak'}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'X4'}}>
              {NullProof({input: data, params: 'cek_4'}) ? 'Ya' : 'Tidak'}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'X5'}}>
              {NullProof({input: data, params: 'cek_5'}) ? 'Ya' : 'Tidak'}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper, PdfWrapper}
