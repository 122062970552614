import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {InfoData, PageData, TableInputData, TableInputDetailData} from './PageData'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {CardUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import FormInput from '../../../../helper/FormInput'
import {formInputList} from './InputLayout'
import {
  TableInputDetail,
  TableInputDetailColumn,
  TableInputDetailFile,
} from '../../../../helper/TableInputDetail'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import {PdfLayoutSemua} from './PdfLayout'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Jenis Naskah' className='min-w-150px'>
                    {NullProof({input: l, params: 'jenis'})}
                  </TableColumn>
                  <TableColumn label='Ukuran' className='min-w-150px'>
                    {NullProof({input: l, params: 'file'})}
                  </TableColumn>
                  <TableColumn label='Nama File' className='min-w-150px'>
                    {NullProof({input: l, params: 'file'})}
                  </TableColumn>
                  <TableColumn label='File' className='min-w-150px'>
                    {NullProof({input: l, params: 'file'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Jenis Naskah'}}>
            {NullProof({input: data, params: 'jenis'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn hideDivider title={{value: 'File'}}>
            <TableInputDetailFile data={NullProof({input: data, params: 'file', isLabel: false})} />
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  let InitPdfLayout = PdfLayoutSemua
  if (query?.id !== 'semua') {
    // InfoData.api = `${InfoData.api}/:id`
    // InitPdfLayout = PdfLayout
  }
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={InitPdfLayout}
      />
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper, PdfWrapper}
