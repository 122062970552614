import {NullProof} from '../../../../helper/AppFunction'
import {BaseInputOnChangeProps} from '../../../../helper/FormInput'
import {filterCapitalizeProvinsi} from '../../../../helper/KemenagFunction'

const onChange = (e: BaseInputOnChangeProps) => {
  const data = e._data.get
  const dataObj = e._data.getObject
  const serverData = e._apiData.server
  const calcWatchId = 'uang_harian,hotel,transport'.split(',')
  if (
    !(Number(serverData?.jumlah || 0) > 0) ||
    (e.type === 'onChange' && calcWatchId.includes(e.clickId || ''))
  ) {
    const uang_harian = Number(dataObj?.uang_harian || 0)
    const hotel = Number(dataObj?.hotel || 0)
    const transport = Number(dataObj?.transport || 0)
    const jumlah = uang_harian + hotel + transport

    const result = {...data, jumlah: jumlah, nilai: jumlah}
    e.resetForm({values: result})
  }
  if (e.clickId === 'notifikasi' && e.type === 'onClick') {
    const spd: string = ((dataObj?.spd || '').split('/')[0] || '_').replaceAll('-', '')
    const um_uf = dataObj?.um_uf || '_'
    const lokasiMap = NullProof({input: serverData, params: 'surat_tugas.tujuan', isMap: true})
    const lokasiMap2 = NullProof({
      input: e._apiData.get,
      params: 'surat_tugas_id_detail.tujuan',
      isMap: true,
    })
    const lokasi = filterCapitalizeProvinsi(
      lokasiMap[0]?.provinsi || lokasiMap2[0]?.provinsi || '_'
    )
    const notifikasi = `SPD ${spd} ${lokasi} ${um_uf}`
    const result = {...data, notifikasi: notifikasi}
    e.resetForm({values: result})
  }
  if (e.clickId === 'tanggal_transfer' && e.type === 'onClick') {
    const result = {...data, tanggal_transfer: null}
    e.resetForm({values: result})
  }
}

export {onChange}
