import * as Yup from 'yup'
import { FormInputProps } from '../../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../../helper/template/FormInputTemplate'

const filterFormInputList: FormInputProps[] = [
  ...inputLayoutBuilder({
    input: [{template: 'search'}, {template: 'status'}],
  }),
]

const formInputList: FormInputProps[] = [
  {
    name: 'kode_klasifikasi',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Kode Klasifikasi Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Klasifikasi',
          placeholder: 'Kode Klasifikasi',
        },
      },
    },
  },
  {
    name: 'berkas',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Berkas Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Berkas',
          placeholder: 'Berkas',
        },
      },
    },
  },
  {
    name: 'tanggal_peminjaman',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.date().required('Tanggal Peminjaman Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Peminjaman',
          placeholder: 'Tanggal Peminjaman',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'tanggal_pengembalian',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.date().required('Tanggal pengembalian Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal pengembalian',
          placeholder: 'Tanggal pengembalian',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'jumlah_item',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Jumlah Item Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jumlah Item',
          placeholder: 'Jumlah Item',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'akhir_retensi_aktif',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Akhir Retensi Aktif Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Akhir Retensi Aktif',
          placeholder: 'Akhir Retensi Aktif',
          type: 'date',
        },
      },
    },
  },
]

export {formInputList, filterFormInputList}
