/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {WithChildren, toAbsoluteUrl} from '../../../helpers'
import ReactIcon from '../../../../app/helper/ReactIcon'
import Modal from '../../../../app/helper/Modal'
import {PdfPageViewer} from '../../../../app/helper/PdfPageViewer'
import {ReactPdfViewer} from '../../../../app/helper/ReactPdf'
import Accordion from '../../../../app/helper/Accordion'
import {getData} from '../../../../app/helper/FormAxios'

const ModalPerModul: FC<{data: string; title: string} & WithChildren> = ({data, title}) => {
  const [modal, setModal] = useState<boolean>(false)
  return (
    <>
      <Modal
        id={`modal-${title}`}
        title={`Panduan Penggunaan`}
        isShow={modal}
        onClose={() => setModal(false)}
      >
        <ReactPdfViewer title={title} url={data} options={{isLocalFile: false, hasDownload: true}} />
      </Modal>
      <button className='btn btn-outline text-start fs-4' onClick={() => setModal(true)}>
        {title}
      </button>
    </>
  )
}

const HeaderUserMenu: FC = () => {
  const [modalPanduan, setModalPanduan] = useState<{status: boolean; data: string}>({
    status: false,
    data: '',
  })
  const {currentUser, logout, auth} = useAuth()
  const userData = auth?.data
  const [modulData, setModulData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getData('', '/account/data_panduan_penggunaan', {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${auth?.data.api_token}`
          },
        });
        if (response.status === true) {
          const data = response.data;
          setModulData(data);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Modal
        id='modal-panduan'
        title='Panduan Penggunaan'
        isShow={modalPanduan.status}
        onClose={() => setModalPanduan((p) => ({...p, status: false}))}
      >
        <div className='d-flex flex-column gap-4'>
          {modulData.map((modul, index) => (
            <ModalPerModul
              key={index}
              data={'/' + modul.fileurl}
              title={modul.title}
            />
          ))}
        </div>
      </Modal>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold pb-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        {/* <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.first_name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div> */}

        {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}
        <div className='px-5 py-3 fs-4 text-capitalize'>
          <div className='px-5'>Halo, {userData?.fullname}</div>
          <div className='fs-7 fw-normal px-5'>
            Jabatan: {userData?.pegawai?.jabatan?.fungsional || '-'}
          </div>
          <div className='fs-7 fw-normal px-5'>NIP: {userData?.pegawai?.nip || '-'}</div>
        </div>
        <div className='border-bottom mb-2'></div>
        <div className='menu-item px-5'>
          <Link to='/profil/info' className='menu-link px-5 d-flex justify-content-between'>
            Profil
            <ReactIcon icon='RiUserLine' props={{className: 'fs-3'}} />
          </Link>
        </div>
        <div className='menu-item px-5'>
          <div
            onClick={() => setModalPanduan({data: '/pdf/panduan-penggunaan.pdf', status: true})}
            className='menu-link px-5 d-flex justify-content-between'
          >
            Panduan Penggunaan
            <ReactIcon icon='RiBook2Line' props={{className: 'fs-3'}} />
          </div>
        </div>
        {/* <div className='menu-item px-5'>
        <Link to='/chat' className='menu-link px-5 d-flex justify-content-between'>
          Pesan
          <ReactIcon icon='RiMessage2Line' props={{className: 'fs-3'}} />
        </Link>
      </div> */}
        <div className='menu-item px-5'>
          <Link to='/profil/edit' className='menu-link px-5 d-flex justify-content-between'>
            Ubah Profil
            <ReactIcon icon='RiEditLine' props={{className: 'fs-3'}} />
          </Link>
        </div>
        <div className='menu-item px-5'>
          <a
            onClick={logout}
            className='menu-link bg-danger text-light px-5 d-flex justify-content-between'
          >
            Keluar
            <ReactIcon icon='RiLogoutBoxRLine' props={{className: 'fs-3'}} />
          </a>
        </div>
      </div>
    </>
  )
}

export {HeaderUserMenu}
