import {FC} from 'react'
import {useAuth} from '../modules/auth'
import {WithChildren} from '../../_metronic/helpers'
import {useLocation, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {post as postAuth} from '../redux/authSlice'
import {getData} from './FormAxios'
import {Routes as DataRoutes} from '../helper/routes/index'
import {NullProof} from './AppFunction'

export type DataRoutesProps = {
  title: string
  route: string
  icon: string
  elements?: any
  isLabel?: boolean
  isPage?: boolean
  onlyRoute?: boolean
  hasHeader?: boolean
  subroute: DataRoutesProps[]
}

const useDataAuth = () => {
  const {auth} = useAuth()
  const data: any = auth?.data
  const json: any = {
    access: data?.access,
    access_seo: data?.access_seo,
    roles: data?.roles,
    role_id: data?.role_id,
  }
  return json
}

const ProtectComponent: FC<{id: string; route?: string; disabled?: boolean} & WithChildren> = ({
  id,
  route,
  disabled = false,
  children,
}) => {
  let hasAccess: boolean = disabled
  const query = useParams()
  const data: any = useDataAuth()
  const routeData: any = useSelector((state: any) => state.route.value)
  const _route = useLocation()
  const currentRoute = route || _route.pathname
  const listId = id ? id.split(',') : ['']
  for (const l of listId) {
    const permissionRequest = `${currentRoute}${l.length > 0 ? `/${l}` : `${l}`}`
    hasAccess = hasAccessRoutes(
      routeData?.route,
      permissionRequest.substring(1),
      data?.role_id === 1,
      true,
      query
    )
    if (hasAccess) {
      break
    }
  }
  if (hasAccess) {
    return <>{children}</>
  } else {
    return <></>
  }
}

const ProtectComponentRoles: FC<{id?: string} & WithChildren> = ({id, children}) => {
  let hasAccess: boolean = false
  let listrole: string = ''
  const data: any = useDataAuth()
  if (data?.role_id === 1) {
    hasAccess = true
  } else {
    // console.log('id', id)
    if (data.roles && data.roles.name.includes('Kepala Bagian PHP, SI, dan Dumas')) {
      listrole = 'PHP Internal,PHP Eksternal,Dumas dan UPG,Sistem Informasi'
    }
    if (data.roles && data.roles.name.includes('Kepala Umum dan Kepegawaian')) {
      listrole =
        'Perencanaan dan Anggaran,Keuangan,Ortala,Hukum,Kepegawaian,Humas,Tata Usaha,RT&BMN'
    }
    if (data.roles && data.roles.name.includes('Sekretaris')) {
      listrole = 'Strategi Kebijak Pengawasan, Kasubbag TU'
    }
    // console.log('listrole', listrole)
    if (listrole != '') {
      for (const l of listrole.split(',')) {
        if (id?.includes(l)) {
          hasAccess = true
          break
        }
      }
    }
  }
  if (hasAccess) {
    return <>{children}</>
  } else {
    return <></>
  }
}

const ProtectComponentRoles2: FC<{id?: string} & WithChildren> = ({id, children}) => {
  let hasAccess: boolean = false
  let listrole: string = ''
  // console.log('id', id)
  const data: any = useDataAuth()
  if (data?.role_id === 1) {
    hasAccess = true
  } else {
    if (data.roles && data.roles.name.includes('Sekretaris')) {
      listrole =
        'PHP Internal,PHP Eksternal,Dumas dan UPG,Sistem Informasi,Perencanaan dan Anggaran,Keuangan,Ortala,Hukum,Kepegawaian,Humas,Tata Usaha,RT&BMN'
    }
    if (data.roles && data.roles.name.includes('Irjen')) {
      listrole = 'Strategi Kebijak Pengawasan, Kasubbag TU'
    }
    // console.log('listrole', listrole)
    if (listrole != '') {
      for (const l of listrole.split(',')) {
        if (id?.includes(l)) {
          hasAccess = true
          break
        }
      }
    }
    // console.log('hasAccess', hasAccess)
  }
  if (hasAccess) {
    return <>{children}</>
  } else {
    return <></>
  }
}

const ParseRouteData = (data: string, type: 'route' | 'menu') => {
  let json = data
    .replaceAll('*', 'view')
    .replaceAll('ubah', 'edit')
    .replaceAll('hapus', 'delete')
    .replaceAll('tambah', 'add')
  if (type === 'menu') {
    json = json.replaceAll('/', '-').replaceAll(':', '')
  }
  return json
}

interface ParseRouteMenuV2List {
  title: string
  icon: string
  route: string
}

interface ParseRouteMenuV2Group extends ParseRouteMenuV2List {
  sub: any[]
  action: any[]
}

export const ParseRouteMenuV2 = ({
  api,
  options,
}: {
  api: any
  options?: {
    isAdmin?: boolean
  }
}): {
  route: {
    [key: string]: {
      title: string
      icon: string
    }
  }
  list: ParseRouteMenuV2List[]
  group: ParseRouteMenuV2Group[]
} => {
  const routeBuilder = ({
    data,
    parent,
    options,
  }: {
    data: any[]
    parent?: {
      data?: {
        icon: string
        title: string
        route: string
      }
      isAction?: boolean
    }
    options?: {
      params?: {
        icon?: string
        title?: string
        sub?: string
        action?: string
        route?: string
        granted?: string
      }
      isAdmin?: boolean
    }
  }): {
    isGranted: boolean
    list: ParseRouteMenuV2List[]
    group: ParseRouteMenuV2Group[]
  } => {
    let resultList: any[] = []
    let resultGroup: any[] = []
    let hasGranted: boolean = options?.isAdmin || false
    let hasAction: boolean = parent?.isAction || false
    data?.map((l: any, i: number) => {
      let subMenu: any[] = NullProof({input: l, params: options?.params?.sub || 'sub', isMap: true})
      let actionMenu: any[] = NullProof({
        input: l,
        params: options?.params?.action || 'action',
        isMap: true,
      })
      let isGranted =
        subMenu.length === 0
          ? NullProof({
              input: l,
              params: options?.params?.granted || 'is_granted',
              isLabel: false,
            })
          : false
      let resultBuilder: ParseRouteMenuV2List = {
        icon:
          NullProof({input: l, params: options?.params?.icon || 'bootstrapIcon', isLabel: false}) ||
          'RiLayout2Line',
        title:
          NullProof({input: l, params: options?.params?.title || 'heading', isLabel: false}) ||
          NullProof({input: l, params: options?.params?.title || 'name', isLabel: false}) ||
          '',
        route: `${parent?.data ? `${parent?.data?.route}/` : ``}${NullProof({
          input: l,
          params: options?.params?.route || 'route_seo',
        })}`,
      }
      let resultBuilderGroup: ParseRouteMenuV2Group = {
        ...resultBuilder,
        sub: [],
        action: [],
      }
      if (actionMenu.length > 0) {
        let actionRoute = routeBuilder({
          data: actionMenu,
          parent: {
            data: resultBuilder,
            isAction: true,
          },
          options,
        })
        isGranted = isGranted || actionRoute.isGranted
        if (isGranted) {
          resultList = [...resultList, ...actionRoute.list]
          resultBuilderGroup.action = [...resultBuilderGroup.action, ...actionRoute.group]
        }
      }
      if (subMenu.length > 0) {
        let subRoute = routeBuilder({
          data: subMenu,
          parent: {data: resultBuilder},
          options,
        })
        isGranted = isGranted || subRoute.isGranted
        if (isGranted) {
          resultList = [...resultList, ...subRoute.list]
          resultBuilderGroup.sub = [...resultBuilderGroup.sub, ...subRoute.group]
        }
      }
      isGranted && resultList.push(resultBuilder)
      isGranted && resultGroup.push(resultBuilderGroup)
      hasGranted = hasGranted || isGranted
    })
    return {
      isGranted: hasGranted,
      list: resultList,
      group: resultGroup,
    }
  }
  let routeList: any = {
    icon: {},
    label: {},
    route: [],
    menu: [],
  }
  const routeAccess = routeBuilder({data: api, options: options})
  for (const l of routeAccess.list) {
    routeList.icon[l.route] = l.icon
    routeList.label[l.route] = l.title
    routeList.route.push(l.route)
    routeList.menu.push(l.route.replaceAll('/', '-').replaceAll(':', ''))
  }
  return {...routeAccess, route: routeList}
}

export const ParseRoutesMenu = (
  data: DataRoutesProps[],
  subrouteId: string = 'subroute',
  routeId: string = 'route',
  isServer: boolean = false
) => {
  let dataIcon: any = {}
  let dataName: any = {}
  let dataRoute: any = []
  let dataMenu: any = []
  let dataEditor: any = []
  const enableMenu = (l: any) => {
    return isServer ? l?.is_granted || false : true
  }
  const parseDataLoop = ({
    datas,
    titleParent,
  }: {
    datas: DataRoutesProps[]
    titleParent?: string
  }) => {
    datas.forEach((l: any) => {
      let title = titleParent || ''
      title += l[routeId] + (l[subrouteId]?.length > 0 ? '/' : '')
      title = ParseRouteData(title, 'route')
      // console.log('--')
      // console.log(l[routeId])
      // console.log(title)
      // console.log(titleParent)
      // console.log('--')
      if (l?.action?.length > 0) {
        for (const laction of l?.action) {
          if (enableMenu(laction)) {
            dataIcon[`${title}/${laction[routeId]}`] = laction?.bootstrapIcon || 'RiLayout2Line'
            dataName[`${title}/${laction[routeId]}`] = laction?.name || null
            dataRoute.push(`${title}/${laction[routeId]}`)
            dataEditor.push(ParseRouteData(`${title}/${laction[routeId]}`, 'route'))
            dataMenu.push(ParseRouteData(`${title}/${laction[routeId]}`, 'menu'))
            if (titleParent && !dataRoute.some((e: string) => e === titleParent?.slice(0, -1))) {
              dataIcon[titleParent?.slice(0, -1)] = l?.bootstrapIcon || 'RiLayout2Line'
              dataRoute.push(titleParent?.slice(0, -1))
            }
          }
        }
      }
      if (l[subrouteId]?.length > 0) {
        if (enableMenu(l)) {
          dataIcon[l[routeId].replaceAll('/', '')] = l?.bootstrapIcon || 'RiLayout2Line'
          dataName[l[routeId].replaceAll('/', '')] = l?.name || null
          dataName[`${titleParent || ''}${l[routeId].replaceAll('/', '')}`] = l?.name || null
          if (titleParent && !dataRoute.some((e: string) => e === titleParent?.slice(0, -1))) {
            dataIcon[titleParent?.slice(0, -1)] = l?.bootstrapIcon || 'RiLayout2Line'
            dataRoute.push(titleParent?.slice(0, -1))
          }
        }
        parseDataLoop({datas: l[subrouteId], titleParent: title})
      } else {
        if (l[routeId] === '*') {
          if (enableMenu(l)) {
            dataIcon[title.replaceAll('view', 'delete')] = l?.bootstrapIcon || 'RiLayout2Line'
            dataName[title.replaceAll('view', 'delete')] = l?.name || null
            dataRoute.push(title.replaceAll('view', 'delete'))
            dataMenu.push(title.replaceAll('view', 'delete').replaceAll('/', '-'))
            if (titleParent && !dataRoute.some((e: string) => e === titleParent?.slice(0, -1))) {
              dataIcon[titleParent?.slice(0, -1)] = l?.bootstrapIcon || 'RiLayout2Line'
              dataRoute.push(titleParent?.slice(0, -1))
            }
          }
        }
        if (enableMenu(l)) {
          dataIcon[title] = l?.bootstrapIcon || 'RiLayout2Line'
          dataName[title] = l?.name || null
          dataRoute.push(title)
          if (titleParent && !dataRoute.some((e: string) => e === titleParent?.slice(0, -1))) {
            dataIcon[titleParent?.slice(0, -1)] = l?.bootstrapIcon || 'RiLayout2Line'
            dataRoute.push(titleParent?.slice(0, -1))
          }
          dataMenu.push(ParseRouteData(title, 'menu'))
        }
        if (
          l[routeId] === '*' ||
          l[routeId] === 'ubah/:id' ||
          l[routeId] === 'hapus' ||
          l[routeId] === 'tambah' ||
          l[routeId] === 'pdf/:id' ||
          l[routeId] === 'detail'
        ) {
        } else {
          if (titleParent) {
            if (enableMenu(l)) {
              dataEditor.push(titleParent + l[routeId])
              if (titleParent && !dataRoute.some((e: string) => e === titleParent?.slice(0, -1))) {
                dataEditor.push(titleParent?.slice(0, -1))
              }
            }
          } else {
            if (enableMenu(l)) {
              dataEditor.push(l[routeId])
            }
          }
        }
      }
    })
  }
  parseDataLoop({datas: data})
  const json = {
    route: dataRoute,
    menu: dataMenu,
    editor: dataEditor,
    icon: dataIcon,
    label: dataName,
  }
  return json
}

const hasAccessRoutes = (
  data: any[],
  search: string,
  isSuperadmin: boolean = false,
  strict: boolean = false,
  query: any = {}
) => {
  return isSuperadmin
    ? isSuperadmin
    : data?.some((e: string) => {
        let replacedQuery = e
        let replacedSearch = search
        for (const l of Object.keys(query)) {
          replacedQuery = replacedQuery.replaceAll(`:${l}`, query[l])
          replacedSearch = replacedSearch.replaceAll(`:${l}`, query[l])
        }
        return strict ? replacedQuery === replacedSearch : replacedQuery.includes(replacedSearch)
      })
}

const RoutesMenuBuilder = (data: DataRoutesProps[]) => {
  return data
}

const SidebarMenuBuilder = (data: DataRoutesProps[]) => {
  return data
}

const UserLoginData = () => {
  const {auth} = useAuth()
  return auth?.data || {}
}

export {
  UserLoginData,
  RoutesMenuBuilder,
  SidebarMenuBuilder,
  ProtectComponent,
  hasAccessRoutes,
  ProtectComponentRoles,
  ProtectComponentRoles2,
}
