import React,{useState, FC } from 'react';
import { Calendar, dateFnsLocalizer, Event } from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { enGB, id } from 'date-fns/locale'
import addHours from 'date-fns/addHours'
import startOfHour from 'date-fns/startOfHour'
import { NullProof } from './AppFunction';
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'

interface EventProps {
    title: string | null
    start: Date | null
    end: Date | null
    data: any
    allDay?: boolean
    resource?: any,
}
  

const ReactBigCalendar = (props:any) => {

    
      return (
        <DnDCalendar
          defaultView='month'
          events={filterEvent(props.params, props.data)}
          localizer={localizer}
        //   onEventDrop={onEventDrop}
        //   onEventResize={onEventResize}
          resizable
          style={{ height: '100vh' }}
        />
      )
}

const filterEvent = (params:any ,data:any ,inputAllDay?: boolean, inputResource?: any) => {
    let param = {
        title: params.title,
        inputStart: params.start,
        inputEnd: params.end,
        inputData: params.data,
    }

    let result:any = []
    NullProof({input: data, params: params.data, isMap: true}).map((item:any) => {
        console.log("item", item)
        param.title = NullProof({input: item, params: params.title})
        param.inputStart = new Date(NullProof({input: item, params: params.start}))
        param.inputEnd = new Date(NullProof({input: item, params: params.end}))
        console.log("param", param)
        result.push({
            title: param.title,
            start: param.inputStart,
            end: param.inputEnd,
        })
    })

    return result
}
    
    const locales = {
      'en-GB': enGB,
    }
    const endOfHour = (date: Date): Date => addHours(startOfHour(date), 1)
    const now = new Date()
    const start = endOfHour(now)
    const end = addHours(start, 2)
    // The types here are `object`. Strongly consider making them better as removing `locales` caused a fatal error
    const localizer = dateFnsLocalizer({
      format,
      parse,
      startOfWeek,
      getDay,
      locales,
    })
    //@ts-ignore
    const DnDCalendar = withDragAndDrop(Calendar)

export { ReactBigCalendar, filterEvent }