import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {InfoData, PageData, TableInputData, TableInputDetailData} from './PageData'
import {
  BreadcrumbQueryParams,
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  formInputListSelector,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {CardUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import FormInput from '../../../../helper/FormInput'
import {formInputList, formUpdateList, formInputImportList} from './InputLayout'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {formatDate} from '../../../../helper/DateFns'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import {PdfLayoutSemua} from './PdfLayout'
import * as Yup from 'yup'
import {getData} from '../../../../helper/FormAxios'
import {onLoadProps as onLoadPropsForm} from '../../../../helper/FormInput'

const onChange = async (e: any) => {
  if (e?.type === 'onChange') {
    const data = e?._data?.get

    // const uang_harian = Number(data?.uang_harian || 0)
    // const inputUangRepresentasi = formInputListSelector({formInputList, name: 'uang_representasi'})
    // if (uang_harian > 0) {
    //   inputUangRepresentasi.validator = Yup.number().required('Mohon untuk diisi.')
    // } else {
    //   inputUangRepresentasi.validator = Yup.number()
    // }
    e?.formik?.resetForm({values: data})
    let surat_tugas_id: number = data?.surat_tugas_id || 0
    if (surat_tugas_id > 0) {
      const response = await getData('', `/pengawasan_internal/surat-tugas/${surat_tugas_id}`)
      const st = response.data || {}
      // const dataPegawai = st.kepada.forEach((l: any, i: number) => {
      //   return {label: l.pegawai.nama, value: l.pegawai_id}
      // })
      // const inputPegawai = formInputListSelector({formInputList, name: 'pegawai_id'})

      let spd: string = st?.kepada[data?.data - 1]?.spd?.nomor_surat || ''
      const jabatan: string = st?.kepada[data?.data - 1]?.jabatan || ''
      if (jabatan && ['Anggota'].includes(jabatan)) {
        spd = st?.kepada?.find((l: any) => l?.jabatan === 'Ketua Tim')?.spd?.nomor_surat || ''
      }

      if (
        (data?.data > 0 && data[`data_surat_tugas_id_${data?.data}`] === '') ||
        data[`data_mak_${data?.data}`] === '' ||
        (data[`data_nama_kegiatan_${data?.data}`] === '' &&
          data[`data_no_spd_${data?.data}`] === '' &&
          data[`data_pegawai_id_${data?.data}`] === '' &&
          data[`data_tanggal_pelaksanaan_mulai_${data?.data}`] === '' &&
          data[`data_tanggal_pelaksanaan_selesai_${data?.data}`] === '' &&
          data[`data_tujuan_${data?.data}`] === '' &&
          data[`data_uang_harian_${data?.data}`] === '' &&
          data[`data_uang_representasi_${data?.data}`] === '' &&
          data[`data_uang_penginapan_${data?.data}`] === '' &&
          data[`data_uang_transport_${data?.data}`] === '' &&
          data[`data_uang_bst_${data?.data}`] === '' &&
          data[`data_uang_jumlah_${data?.data}`] === '' &&
          data[`data_usulan_id_${data?.data}`] === '')
      ) {
        // inputPegawai.value = st?.kepada[data?.data - 1]?.pegawai_id
        e.resetForm({
          values: {
            ...data,
            [`data_surat_tugas_id_${data?.data}`]: st?.id,
            [`data_usulan_id_${data?.data}`]: st?.usulan_id,
            [`data_mak_${data?.data}`]: st?.mak,
            [`data_nama_kegiatan_${data?.data}`]: st?.nama_kegiatan,
            [`data_no_spd_${data?.data}`]:
              st?.kepada?.find((l: any) => l?.pegawai_id === data?.pegawai_id)?.spd?.nomor_surat ||
              '',
            [`data_pegawai_id_${data?.data}`]: st?.kepada[data?.data - 1]?.pegawai_id,
            [`data_tanggal_pelaksanaan_mulai_${data?.data}`]: st?.tanggal_mulai,
            [`data_tanggal_pelaksanaan_selesai_${data?.data}`]: st?.tanggal_selesai,
            [`data_no_spd_${data?.data}`]: spd,
            [`data_tujuan_${data?.data}`]: st?.tujuan[0].provinsi,
            [`data_uang_harian_${data?.data}`]: st?.kepada[data?.data - 1]?.uang_harian,
            [`data_uang_representasi_${data?.data}`]: st?.kepada[data?.data - 1]?.uang_representasi,
            [`data_uang_penginapan_${data?.data}`]: st?.kepada[data?.data - 1]?.uang_penginapan,
            [`data_uang_transport_${data?.data}`]: st?.kepada[data?.data - 1]?.uang_transportasi,
            [`data_uang_bst_${data?.data}`]: st?.kepada[data?.data - 1]?.bst_full,
            [`data_uang_jumlah_${data?.data}`]: st?.kepada[data?.data - 1]?.total_uang,
          },
        })
      }

      // let spd: string = st?.kepada?.find((l: any) => l?.pegawai_id === st?.pegawai_id)?.spd?.nomor_surat || ''
      // const jabatan: string = st?.kepada?.find((l: any) => l?.pegawai_id === data?.pegawai_id).jabatan || ''
      // if(jabatan && jabatan === 'Anggota'){
      //   spd = st?.kepada?.find((l: any) => l?.jabatan === 'Ketua Tim')?.spd?.nomor_surat || ''
      // }
      // if(response){
      //   e.resetForm({values: {
      //     ...data,
      //     mak: st?.mak,
      //     nama_kegiatan: st?.nama_kegiatan,
      //     no_spd: spd,
      //     // tanggal_pelaksanaan_mulai: st?.tanggal_mulai,
      //     // tanggal_pelaksanaan_selesai: st?.tanggal_selesai,
      //   }})
      // }
    }
  }
}

const onLoadForm = (e: onLoadPropsForm) => {
  const data = e.data?._data.getObject
  // console.log("dataaa", data)
  const loadDataMember = async () => {
    e.initLoading()
    const eQuery = e.query?.get
    eQuery.id_anggota = data?.pegawai_id
    console.log('eQuery', eQuery)

    let result = await getData(e.query?.post(eQuery) || '', '/pengawasan_internal/surat-tugas')
    console.log('result', result)
    e.load({query: eQuery, result: result})
  }

  const loadDataST = async (pegawai_id: number) => {
    e.initLoading()
    const eQuery = e.query?.get
    eQuery.id_anggota = pegawai_id
    console.log('eQuery', eQuery)

    let result = await getData(e.query?.post(eQuery) || '', '/pengawasan_internal/surat-tugas')
    console.log('result', result)
    e.load({query: eQuery, result: result})
  }

  const loadDataPegawai = async (surat_tugas_id: number) => {
    e.initLoading()
    const eQuery = e.query?.get
    console.log('surat_tugas_id', surat_tugas_id)
    const getNamaPegawai = await getData('', `/pengawasan_internal/surat-tugas/${surat_tugas_id}`)
    console.log('getNamaPegawai', getNamaPegawai)
    eQuery.search = getNamaPegawai?.data?.kepada[data.data.length - 1]?.pegawai.nama
    console.log('eQuery', eQuery)

    let result = await getData(e.query?.post(eQuery) || '', '/kepegawaian/data-tenaga-audit')
    // console.log("result", result)
    e.load({query: eQuery, result: result})
  }

  console.log('e.id', e.id)
  console.log('data', data)

  e.id === 'surat_tugas_id' && loadDataMember()
  data?.data.map((l: any, i: number) => {
    const name = `data_surat_tugas_id_${i + 1}`
    e.id === name && loadDataST(l.pegawai_id)

    const namaPegawai = `data_pegawai_id_${i + 1}`
    e.id === namaPegawai && loadDataPegawai(data.surat_tugas_id)
  })

  // NullProof({input: data, params: 'custom_api_components', isMap: true})?.map(
  //   (l: any, i: number) => {
  //     const name = `custom_api_components_custom_api_onload_${i + 1}`
  //     e.id === name && loadDataMember()
  //   }
  // )
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          options={{
            checkbox: [
              {
                title: 'Lihat PDF',
                name: 'lihat-pdf',
                icon: 'RiFilePdfLine',
                type: 'link',
                url: `${InfoData.path}/pdf/pilihan`,
                api: '',
              },
            ],
          }}
          {...TableInputData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Nomor SPD' props={{style: {minWidth: '150px'}}}>
                    {NullProof({input: l, params: 'no_spd'})}
                  </TableColumn>
                  <TableColumn label='MAK' props={{style: {minWidth: '150px'}}}>
                    {NullProof({input: l, params: 'mak'})}
                  </TableColumn>
                  <TableColumn label='Tanggal Pencairan' props={{style: {minWidth: '150px'}}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'tanggal_perancangan'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableColumn label='Nominal Pencairan' props={{style: {minWidth: '150px'}}}>
                    {ConvertCurrency(
                      NullProof({input: l, params: 'nominal_pencairan', isLabel: false}) || 0
                    )}
                  </TableColumn>
                  <TableColumn label='Kegiatan' props={{style: {minWidth: '150px'}}}>
                    {NullProof({input: l, params: 'nama_kegiatan'})}
                  </TableColumn>
                  <TableColumn label='Uang Harian' props={{style: {minWidth: '150px'}}}>
                    {ConvertCurrency(NullProof({input: l, params: 'uang_harian'}))}
                  </TableColumn>
                  <TableColumn label='Uang Representasi' props={{style: {minWidth: '150px'}}}>
                    {ConvertCurrency(NullProof({input: l, params: 'uang_representasi'}))}
                  </TableColumn>
                  <TableColumn label='Uang Penginapan' props={{style: {minWidth: '150px'}}}>
                    {ConvertCurrency(NullProof({input: l, params: 'uang_penginapan'}))}
                  </TableColumn>
                  <TableColumn label='Uang Transport' props={{style: {minWidth: '150px'}}}>
                    {ConvertCurrency(NullProof({input: l, params: 'uang_transport'}))}
                  </TableColumn>
                  <TableColumn label='Jumlah' props={{style: {minWidth: '150px'}}}>
                    {ConvertCurrency(
                      NullProof({input: l, params: 'uang_jumlah', isLabel: false}) || 0
                    )}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
          onLoad={onLoadForm}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formUpdateList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChange}
          onLoad={onLoadForm}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  let totalJumlah: number = 0
  let totalRealisasi: number = 0
  let totalSelisih: number = 0
  let listItem: any = []

  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tanggal Pencairan'}}>
            {formatDate({
              date: NullProof({input: data.detail, params: 'tanggal_perancangan'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'MAK'}}>
            {data?.detail?.mak ? data?.detail?.mak : '-'}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Item'}}>
            {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
              console.log("lllllll", l)
              listItem.push(l.item)
              return (
                <Fragment key={i}>
                  <div className='flex'>
                      {NullProof({input: l, params: 'item'})},{' '}
                  </div>
                </Fragment>
              )
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            title={{value: 'Data Kegiatan', props: {className: 'fs-5'}}}
          >
            {NullProof({input: data, params: 'data', isMap: true})?.map(
              (l: any, i: number) => {
                totalJumlah += parseInt(NullProof({input: l, params: 'uang_jumlah'}) || 0)
                totalRealisasi += parseInt(NullProof({input: l, params: 'uang_realisasi'}) || 0)
                totalSelisih += parseInt(NullProof({input: l, params: 'uang_selisih'}) || 0)
                return (
                  <Fragment key={i}>
                    <div className='my-4'>
                      <TableInputDetailColumn hideDivider title={{value: 'Nama Kegiatan'}}>
                        {NullProof({input: l, params: 'nama_kegiatan'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Wilayah / Bagian'}}>
                        {NullProof({input: l, params: 'wilayah_bagian'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'No. SPM'}}>
                        {NullProof({input: l, params: 'no_spm'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Nama Pegawai'}}>
                        {NullProof({input: l, params: 'pegawai.nama'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'NIP Pegawai'}}>
                        {NullProof({input: l, params: 'pegawai.nip'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'No. SPD'}}>
                        {NullProof({input: l, params: 'no_spd'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Tujuan'}}>
                        {NullProof({input: l, params: 'tujuan'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Tanggal Pelaksanaan'}}>
                        {formatDate({
                          date: NullProof({input: l, params: 'tanggal_pelaksanaan_mulai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })}{' '}
                        -{' '}
                        {formatDate({
                          date: NullProof({input: l, params: 'tanggal_pelaksanaan_selesai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Uang Harian'}}>
                        {ConvertCurrency(NullProof({input: l, params: 'uang_harian'}) || 0)}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Representasi'}}>
                        {ConvertCurrency(NullProof({input: l, params: 'uang_representasi'}) || 0)}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Penginapan'}}>
                        {ConvertCurrency(NullProof({input: l, params: 'uang_penginapan'}) || 0)}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Transport / Tiket'}}>
                        {ConvertCurrency(NullProof({input: l, params: 'uang_transport'}) || 0)}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'BST'}}>
                        {ConvertCurrency(NullProof({input: l, params: 'uang_bst'}) || 0)}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Jumlah'}}>
                        {ConvertCurrency(NullProof({input: l, params: 'uang_jumlah'}) || 0)}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Realisasi'}}>
                        {ConvertCurrency(NullProof({input: l, params: 'uang_realisasi'}) || 0)}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Selisih'}}>
                        {ConvertCurrency(NullProof({input: l, params: 'uang_selisih'}) || 0)}
                      </TableInputDetailColumn>
                    </div>
                  </Fragment>
                )
              }
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn hideDivider title={{value: 'Total Jumlah'}}>
            {ConvertCurrency(totalJumlah)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Total Realisasi'}}>
            {ConvertCurrency(totalRealisasi)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Total Selisih'}}>
            {ConvertCurrency(totalSelisih)}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  let InitPdfLayout = PdfLayoutSemua
  if (query?.id !== 'semua') {
    // InfoData.api = `${InfoData.api}/:id`
    // InitPdfLayout = PdfLayout
  }
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={InitPdfLayout}
        options={{
          isRedux: query?.id === 'pilihan',
        }}
      />
    </>
  )
}

const ImportWrapper: FC = () => {
  const titlePage = 'Import Data Excel'
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>{titlePage}</PageTitle>
      <CardUi title={titlePage}>
        <FormInput
          input={formInputImportList}
          route={{
            url: InfoData.api + '-import',
            type: 'post',
            isFeedback: false,
            onFeedback: (e: any) => {
              console.log(e)
            },
          }}
        />
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper, PdfWrapper, ImportWrapper}
