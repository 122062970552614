/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Table} from '../../modules/apps/global/Table'
import {KTIcon} from '../../../_metronic/helpers'

const dummyData = [
  {
    id: 1,
    tujuan: `Sasaran Strategis : Meningkatnya kualitas tata kelola pemerintahan yang efektif, transparan dan akuntabel Sasaran Program : Meningkatnya akuntabilitas keuangan Kementerian Aqama
    Sasaran Kegiatan : Meningkatnya pengawasan atas pengelolaan keuangan pada satuan kerja Pendidikan pada lnspektorat Wilayah IJV`,
    penanggung: `INSPEKTUR JENDRAL KEMENAG`,
    misi: '-',
    keterangan: '-',
  },
]

const DashboardPage: FC = () => (
  <>
    <div className='row g-5'>
      <div className='col-12'>
        <div className={`card card-flush h-100`}>
          <div className='card-header py-5'>
            <div className='card-title d-flex flex-row justify-content-between w-100'>
              <div>Rencana Strategis</div>
              <div>
                <a className='btn btn-sm btn-primary'>
                  <KTIcon iconName='plus' className='fs-3' />
                  Tambah
                </a>
              </div>
            </div>
            <div className='card-title d-flex flex-row justify-content-between w-100'>
              <div>
                <input type='text' className='form-control fs-6' placeholder='Cari...' />
              </div>
              <div>
                <a
                  className='btn btn-sm btn-primary'
                  data-kt-menu-trigger={"{default: 'click', lg: 'hover'}"}
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'
                >
                  Cetak
                </a>
                <div
                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px mt-2'
                  data-kt-menu='true'
                >
                  <div className='menu-item px-3 my-0'>
                    <a className='btn btn-sm btn-primary w-100'>Sebagai PDF</a>
                  </div>
                  <div className='menu-item px-3 my-0'>
                    <a className='btn btn-sm btn-primary w-100'>Sebagai Excel</a>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body px-0 w-100'>
              <Table
                className=''
                header={
                  <>
                    <tr>
                      <th>No</th>
                      <th className='min-w-200px'>Tujuan, Sasaran dan Strategi</th>
                      <th className='min-w-200px'>Penanggung Jawab Sasaran & Strategi</th>
                      <th className='min-w-200px'>Misi</th>
                      <th className='min-w-200px'>Keterangan</th>
                      <th className='min-w-200px'>Tindakan</th>
                    </tr>
                  </>
                }
                data={dummyData}
                layout={{id: 'id', column: ['tujuan', 'penanggung', 'misi', 'keterangan']}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

const RencanaStrategisWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {RencanaStrategisWrapper}
