import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import ReactIcon, {ReactIconList} from '../../../../../app/helper/ReactIcon'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isActive?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  isActive,
}) => {
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {ReactIconList.includes(icon || '') && (
          <ReactIcon
            icon={icon || 'RiLayout2Line'}
            props={{className: 'fs-1 me-2', style: {minWidth: '25px'}}}
          />
        )}
        {/* {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )} */}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
