import {DataRoutesProps} from '../../../UserRolesValidation'
import {MainRoutes as KetuaTim} from './daftar-status-bukti-perjalanan/KetuaTim'
import {MainRoutes as Evalap} from './daftar-status-bukti-perjalanan/Evalap'
import {MainRoutes as Ppk} from './daftar-status-bukti-perjalanan/Ppk'
import {MainRoutes as Bendahara} from './daftar-status-bukti-perjalanan/Bendahara'
import {MainRoutes as Selesai} from './daftar-status-bukti-perjalanan/Selesai'

const DaftarStatusBuktiPerjalanan: DataRoutesProps = {
  title: 'Daftar Status Bukti Perjalanan',
  icon: 'element-10',
  route: 'daftar_status_bukti_perjalanan',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Ketua Tim',
      icon: 'element-10',
      route: 'ketua_tim',
      elements: <></>,
      subroute: KetuaTim,
    },
    {
      isPage: true,
      title: 'Evalap',
      icon: 'element-10',
      route: 'evalap',
      elements: <></>,
      subroute: Evalap,
    },
    {
      isPage: true,
      title: 'PPK',
      icon: 'element-10',
      route: 'ppk',
      elements: <></>,
      subroute: Ppk,
    },
    {
      isPage: true,
      title: 'Bendahara',
      icon: 'element-10',
      route: 'bendahara',
      elements: <></>,
      subroute: Bendahara,
    },
    {
      isPage: true,
      title: 'Selesai',
      icon: 'element-10',
      route: 'selesai',
      elements: <></>,
      subroute: Selesai,
    },
  ],
}

export default DaftarStatusBuktiPerjalanan
