import {
  MainWrapper,
  TambahWrapper,
  DetailWrapper,
  UbahWrapper,
  PdfWrapper,
} from '../../../../pages/kebijakan-teknis/risk-register/monitoring/MainWrapper'
import {DataRoutesProps} from '../../../UserRolesValidation'

const RiskRegisterMonitoringRoutes: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:subid',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:subid',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'pdf/:subid',
    elements: <PdfWrapper />,
    subroute: [],
  },
]

export {RiskRegisterMonitoringRoutes}
