import {
    MainWrapper,
    TambahWrapper,
    UbahWrapper,
    DetailWrapper,
  } from '../../../pages/pengaturan/data-panduan-penggunaan/MainWrapper'
  import {DataRoutesProps} from '../../UserRolesValidation'
  
  const DataPanduanPenggunaanRoutes: DataRoutesProps[] = [
    {
      icon: '',
      title: '',
      route: '*',
      elements: <MainWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'tambah',
      elements: <TambahWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'ubah/:id',
      elements: <UbahWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'detail/:id',
      elements: <DetailWrapper />,
      subroute: [],
    },
  ]
  
  export {DataPanduanPenggunaanRoutes}
  