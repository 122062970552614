import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Auditi',
          placeholder: 'Nama Auditi',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'byuser=true',
        params: 'nomor_surat,nama_kegiatan',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nomor Surat : </span>
              <span>$nomor_surat</span>
            </div>
            <div>
              <span className='fw-bold'>Nama Kegiatan : </span>
              <span>$nama_kegiatan</span>
            </div>
          </>
        ),
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'satker_id',
    type: 'datalist',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Satuan Kerja',
          placeholder: 'Satuan Kerja',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker-unit',
        params: 'nama_satker',
        result: '$nama_satker',
        id: 'kode',
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'tanggal',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal',
          placeholder: 'Tanggal',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'batas_akhir',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Batas Akhir Tanggal',
          placeholder: 'Batas Akhir Tanggal',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-4',
    name: 'tempat',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Lokasi',
          placeholder: 'Lokasi',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'list_pegawai_auditi',
    type: 'component',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Anggota',
        },
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'pegawai_id',
        type: 'datalist',
        value: '',
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Nama Auditi',
              placeholder: 'Nama Auditi',
            },
          },
          datalist: {
            api: '/kepegawaian/data-tenaga-audit',
            query: 'orderby=asc',
            params: 'nama,nip',
            result: (
              <>
                <div>
                  <span className='fw-bold'>Nama : </span>
                  <span>$nama</span>
                </div>
                <div>
                  <span className='fw-bold'>NIP : </span>
                  <span>$nip</span>
                </div>
              </>
            ),
            id: 'id',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'nip', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/kepegawaian/data-tenaga-audit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'NIP'},
                ],
              },
            },
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'list_kesepakatan',
    type: 'component',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Daftar Kesepakatan',
        },
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'deskripsi',
        type: 'textarea',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          textarea: {
            props: {
              title: 'Deskripsi',
              placeholder: 'Deskripsi',
              rows: 3,
            },
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'total_tim',
    type: 'input',
    validator: Yup.number(),
    disabled: true,
    hide: true,
    options: {
      input: {
        props: {
          title: 'Total Tim',
          placeholder: 'Total Tim',
          type: 'number',
        },
      },
    },
  },
]

export {formInputList}
