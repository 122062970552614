/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {TableInputData, TableInputDetailData} from './PageData'
import {CardUi} from '../../../helper/AppUi'
import {TableAction, TableInput, TableRow} from '../../../helper/TableInput'
import {ConvertCurrency, NullProof} from '../../../helper/AppFunction'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import {formatDate} from '../../../helper/DateFns'
import ReactIcon from '../../../helper/ReactIcon'

const MainWrapper: FC = () => {
  const buatDummyMinggu = () => {
    const total = 12
    let result: any = {}
    for (let index = 0; index < total; index++) {
      const min = 1
      const max = 100
      const randomInt = Math.floor(Math.random() * (max - min + 1)) + min
      const name = `bulan${index}`
      result[name] = randomInt > 50 ? true : false
    }
    return result
  }
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          isDummy
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td style={{minWidth: '50px'}}>
                    {i +
                      1 +
                      ((NullProof({input: data, params: 'page', isLabel: false}) || 1) - 1) * 10}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({
                      input: l,
                      params: 'auditi.nama_satker',
                    })}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    {NullProof({
                      input: l,
                      params: 'sasaran_audit',
                    })}
                  </td>
                  <td style={{minWidth: '200px'}}>
                    <div className='row gap-2 justify-content-center w-100'>
                      {NullProof({input: l, params: 'month', isMap: true})?.map(
                        (l1: any, i1: number) => (
                          <Fragment key={i1}>
                            <div className='col-3 col-md-2 border border-secondary'>
                              <div className='mb-2'>Ke-{i1 + 1}</div>
                              <div className='fw-bold fs-4'>
                                {NullProof({
                                  input: l1,
                                  params: `status`,
                                  isLabel: false,
                                }) ? (
                                  <div className='mx-auto' style={{width: '40px', height: '40px'}}>
                                    <ReactIcon icon='RiCheckLine' props={{className: 'fs-2'}} />
                                  </div>
                                ) : (
                                  <div
                                    className='mx-auto'
                                    style={{width: '40px', height: '40px'}}
                                  ></div>
                                )}
                              </div>
                            </div>
                          </Fragment>
                        )
                      )}
                    </div>
                  </td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Unit Auditori'}}>
            {NullProof({input: data, params: 'unit_auditori'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tahun'}}>
            {NullProof({input: data, params: 'upkat.tahun'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Biaya'}}>
            {ConvertCurrency(NullProof({input: data, params: 'upkat.biaya', isLabel: false}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Keterangan'}}>
            {NullProof({input: data, params: 'upkat.keterangan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal'}}>
            {`${formatDate({
              date: NullProof({input: data, params: 'upkat.tgl_mulai'}),
              dateFormat: 'dd MMMM yyyy',
            })} - ${formatDate({
              date: NullProof({input: data, params: 'upkat.tgl_selesai'}),
              dateFormat: 'dd MMMM yyyy',
            })}`}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'LHA'}}>
            {NullProof({input: data, params: 'upkat.lha'})}
          </TableInputDetailColumn>
          {NullProof({
            input: data,
            params: 'upkat.rencana_audit_jangka_menengah.periode_start',
          }) && (
            <>
              <TableInputDetailColumn title={{value: 'Periode Audit 5 Tahun'}}>
                {`${NullProof({
                  input: data,
                  params: 'upkat.rencana_audit_jangka_menengah.periode_start',
                })} - ${NullProof({
                  input: data,
                  params: 'upkat.rencana_audit_jangka_menengah.periode_end',
                })}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Tanggal LHA Terakhir'}}>
                {formatDate({
                  date: NullProof({
                    input: data,
                    params: 'upkat.rencana_audit_jangka_menengah.tgl_lha_terakhir',
                  }),
                  dateFormat: 'dd MMMM yyyy',
                })}
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Jenis Audit'}}>
                {NullProof({
                  input: data,
                  params: 'upkat.rencana_audit_jangka_menengah.jenis_audit',
                })}
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Frekuensi Audit'}}>
                {NullProof({
                  input: data,
                  params: 'upkat.rencana_audit_jangka_menengah.frekuensi_audit',
                })}
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Tahun'}}>
                <TableInputDetailColumn hideDivider title={{value: 'X1'}}>
                  {NullProof({input: data, params: 'upkat.rencana_audit_jangka_menengah.cek_1'})
                    ? 'Ya'
                    : 'Tidak'}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'X2'}}>
                  {NullProof({input: data, params: 'upkat.rencana_audit_jangka_menengah.cek_2'})
                    ? 'Ya'
                    : 'Tidak'}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'X3'}}>
                  {NullProof({input: data, params: 'upkat.rencana_audit_jangka_menengah.cek_3'})
                    ? 'Ya'
                    : 'Tidak'}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'X4'}}>
                  {NullProof({input: data, params: 'upkat.rencana_audit_jangka_menengah.cek_4'})
                    ? 'Ya'
                    : 'Tidak'}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'X5'}}>
                  {NullProof({input: data, params: 'upkat.rencana_audit_jangka_menengah.cek_5'})
                    ? 'Ya'
                    : 'Tidak'}
                </TableInputDetailColumn>
              </TableInputDetailColumn>
            </>
          )}
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {MainWrapper, DetailWrapper}
