import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'evaluasi_depan_id',
    type: 'input',
    validator: Yup.number(),
    hide: true,
    options: {
      input: {
        props: {
          title: 'Kode',
          placeholder: 'Kode',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kode',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode',
          placeholder: 'Kode',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kegiatan',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Rincian',
          placeholder: 'Rincian',
        },
      },
    },
  },
]


export {formInputList}
