import {FC, useEffect, useState} from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import Cropper, {Area, Point} from 'react-easy-crop'

interface ReactCropperProps {
  onSave(file: File): void
  data?: File
  options?: {aspect?: number; shape?: 'rect' | 'round'; filename: string}
}

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export function getRadianAngle(degreeValue: number): number {
  return (degreeValue * Math.PI) / 180
}

export function rotateSize(
  width: number,
  height: number,
  rotation: number
): {width: number; height: number} {
  const rotRad = getRadianAngle(rotation)

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

async function getCroppedImg(
  imageSrc: string,
  pixelCrop: {x: number; y: number; width: number; height: number},
  rotation: number = 0,
  flip: {horizontal: boolean; vertical: boolean} = {horizontal: false, vertical: false}
): Promise<any> {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const {width: bBoxWidth, height: bBoxHeight} = rotateSize(image.width, image.height, rotation)

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement('canvas')

  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((blob) => {
      resolve(blob)
    }, 'image/jpeg')
  })
}

const ReactCropper: FC<ReactCropperProps> = ({onSave, data, options}) => {
  const [imageCache, setImageCache] = useState<any>()
  const [crop, setCrop] = useState<Point>({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [dataCrop, setDataCrop] = useState<{croppedArea: Area; croppedAreaPixels: Area}>()
  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setDataCrop({croppedArea, croppedAreaPixels})
  }
  const saveCrop = async () => {
    if (dataCrop?.croppedAreaPixels) {
      const croppedImage = await getCroppedImg(imageCache, dataCrop?.croppedAreaPixels, 0)
      if (croppedImage) {
        const file = new File([croppedImage], `${options?.filename || 'filename'}.jpg`, {
          type: 'image/jpg',
        })
        onSave && onSave(file)
      }
    }
  }
  useEffect(() => {
    if (data) {
      setImageCache(URL.createObjectURL(data))
    }
  }, [data])
  return (
    <div className='d-flex flex-column gap-4'>
      <div>
        <div className='position-relative' style={{height: '300px'}}>
          <Cropper
            image={imageCache}
            // image='https://picsum.photos/id/1/1000/1000'
            crop={crop}
            zoom={zoom}
            aspect={options?.aspect || 1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape={options?.shape || 'rect'}
          />
        </div>
        <div className='my-4 mx-auto' style={{maxWidth: '300px'}}>
          <input
            type='range'
            className='form-range'
            id='zoom'
            step={0.1}
            min={1}
            max={3}
            value={zoom}
            onChange={(e) => setZoom(Number(e.target.value))}
          />
        </div>
      </div>
      {/* Footer */}
      <div className='mt-2 fs-5 text-center'>Pilih area yang akan dijadikan foto</div>
      <div className='d-flex justify-content-end'>
        <button className='btn btn-primary w-100 w-md-auto' onClick={saveCrop}>
          Simpan
        </button>
      </div>
    </div>
  )
}

export default ReactCropper
