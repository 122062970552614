import * as Yup from 'yup'
import {FormInputProps} from '../../../../../../helper/FormInput'
import {createFormObjectList, listVarProps} from '../../../../../../helper/AppFunction'

export const listVar: listVarProps[] = [
  {
    number: 'A',
    group: 'A',
    name: 'rs',
    title: 'Selesai disusun dan diserahkan oleh Ketua Tim',
  },
  {
    number: 'B',
    group: 'B',
    name: 'rs',
    title: 'Selesai dibahas dan diserahkan oleh Pengendali Teknis',
  },
  {
    number: 'C',
    group: 'C',
    name: 'rs',
    title: 'Selesai di Audit oleh Pengendali Mutu dan atau Inspektur Wilayah',
  },
  {
    number: 'D',
    group: 'D',
    name: 'rs',
    title: 'Diserahkan kepada Inspektur Jenderal',
  },
  {
    number: 'E',
    group: 'E',
    name: 'rs',
    title: 'Diterima oleh petugas/tim untuk diketik',
  },
  {
    number: 'F',
    group: 'F',
    name: 'rs',
    title:
      'Diterima oleh Subbagian Tata Usaha Inspektorat Wilayah/Investigasi untuk diperbaiki dan atau dijilid',
  },
  {
    number: 'G',
    group: 'G',
    name: 'rs',
    title: 'Diterima oleh Pengendali Mutu dan atau Inspektur Wilayah atau Inspektur Jenderal',
  },
  {
    number: 'H',
    group: 'H',
    name: 'rs',
    title:
      'Diterima oleh Subbagian Tata Usaha Inspektorat Wilayah/Investigasi untuk dikirim (Ketua Tim menyerahkan kelengkapan kepada Subbagian Tata Usaha, Subbagian Tata Usaha mengecek kelengkapan laporan hasil pengawasan)',
  },
  {
    isLabel: true,
    number: 'I',
    group: 'I',
    name: '',
    title: 'Didistribusikan kepada:',
  },
  {
    number: '1',
    group: 'I',
    name: 'rs',
    title: 'Objek Pengawasan',
  },
  {
    number: '2',
    group: 'I',
    name: 'rs',
    title: 'Inspektur Jenderal',
  },
  {
    number: '3',
    group: 'I',
    name: 'rs',
    title: 'Arsip',
  },
]

const formInputKomponenList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Nama Auditor',
          placeholder: 'Nama Auditor',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md',
    name: 'tanggal_1',
    type: 'input',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Tanggal I',
          placeholder: 'Tanggal I',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md',
    name: 'tanggal_2',
    type: 'input',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Tanggal II',
          placeholder: 'Tanggal II',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md',
    name: 'tanggal_3',
    type: 'input',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Tanggal III',
          placeholder: 'Tanggal III',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md',
    name: 'tanggal_4',
    type: 'input',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Tanggal IV',
          placeholder: 'Tanggal IV',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-12 col-md',
    name: 'tanggal_5',
    type: 'input',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Tanggal V',
          placeholder: 'Tanggal V',
          type: 'date',
        },
      },
    },
  },
]

const inputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'byuser=true',
        params: 'nomor_surat,nama_kegiatan',
        result: '$nomor_surat | $nama_kegiatan',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'detail',
    type: 'component',
    value: 1,
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Komponen',
          placeholder: 'Komponen',
        },
      },
      component: {
        isObject: true,
      },
    },
    component: createFormObjectList(listVar, formInputKomponenList),
  },
]

export {inputList}
